import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import { printPrice } from '@niaratech/niara-js-commons';
import React from 'react';
export default fillCrudMetadataGaps({
  label: 'Voucher',
  labelPlural: 'Vouchers',
  name: 'Voucher',
  deletable: false,
  updatable: false,
  insertable: false,
  authorization: authorization('consultant', 'admin'),
  'custom:sortFields': ['date desc'],
  dynamoDB: {
    tableName: 'SpearVoucher',
    indexName: 'tenantId-date-index',
    indexedProp: 'date',
    scanIndexBackwards: true,
    limit: 500
  },
  columnNames: ['id', 'locator', 'date', 'productName', 'status', 'b2p_status', 'buyer_cpf', 'buyer_email', 'buyer_name', 'b2p_payment_amount', 'currency', 'reservation_id', 'reservation_locator'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    render: false,
    id: true
  }, {
    label: 'Locator',
    name: 'locator',
    type: 'text',
    updatable: false
  }, {
    label: 'Data',
    name: 'date',
    type: 'datetime',
    updatable: false,
    insertable: false
  }, {
    name: 'clientId',
    label: 'Id do cliente',
    updatable: false
  }, {
    name: 'productId',
    label: 'Hotel',
    type: 'text',
    updatable: false
  }, {
    name: 'productType',
    label: 'Tipo de produto',
    type: 'text',
    default: 'hotel',
    updatable: false
  }, {
    name: 'productName',
    label: 'Nome do hotel',
    type: 'text',
    updatable: false
  },
  // {
  //     name: 'city',
  //     label: 'Cidade',
  //     type: 'text',
  //     updatable: false,
  // },
  // {
  //     name: 'cityId',
  //     label: 'Cidade Id',
  //     type: 'text',
  //     updatable: false,
  // },
  // {
  //     name: 'state',
  //     label: 'Estado',
  //     type: 'text',
  //     updatable: false,
  // },
  // {
  //     name: 'country',
  //     label: 'País',
  //     type: 'text',
  //     updatable: false,
  // },
  {
    name: 'meal',
    label: 'Regime de alimentação',
    type: 'text',
    options: [{
      label: 'Somente hospedagem',
      value: 'RO'
    }, {
      label: 'Café da manhã',
      value: 'BB'
    }, {
      label: 'Meia pensão',
      value: 'HB'
    }, {
      label: 'Pensão completa',
      value: 'FB'
    }, {
      label: 'All inclusive',
      value: 'AI'
    }],
    updatable: false
  }, {
    name: 'roomType',
    label: 'Tipo de quarto',
    type: 'text',
    updatable: false
  }, {
    name: 'travelWindowStartDate',
    label: 'Início travel window',
    type: 'date',
    updatable: false
  }, {
    name: 'travelWindowEndDate',
    label: 'Fim travel window',
    type: 'date',
    updatable: false
  }, {
    name: 'daysInAdvance',
    label: 'Dias de antecedência do check-in',
    hint: 'A contar da data da reserva',
    type: 'int',
    updatable: false
  }, {
    name: 'nights',
    label: 'Noites',
    type: 'int',
    updatable: false
  }, {
    name: 'guests',
    label: 'Hóspedes',
    type: 'int',
    updatable: false
  }, {
    name: 'value',
    label: 'Valor total',
    type: 'float',
    updatable: false,
    render: i => i.value != null ? printPrice(i.value, i.currency) : '-'
  }, {
    name: 'b2p_payment_amount',
    label: 'Valor pago',
    type: 'float',
    updatable: false,
    render: i => i.b2p_payment_amount != null ? printPrice(i.b2p_payment_amount, i.currency) : '-'
  }, {
    name: 'currency',
    label: 'Moeda',
    type: 'text',
    default: 'BRL',
    updatable: false,
    render: false
  }, {
    name: 'b2p_payment_installments',
    label: 'Parcelas',
    type: 'int',
    updatable: false
  }, {
    name: 'buyer_email',
    type: 'text',
    updatable: false
  }, {
    name: 'buyer_name',
    type: 'text',
    updatable: false
  }, {
    name: 'buyer_cpf',
    type: 'text',
    updatable: false
  }, {
    name: 'buyer_dob',
    type: 'date',
    updatable: false
  }, {
    name: 'buyer_phone',
    type: 'text',
    updatable: false
  }, {
    name: 'status',
    type: 'text',
    updatable: false,
    options: [{
      label: 'Rejeitado',
      value: 'REJECTED'
    }, {
      label: 'Pendente',
      value: 'PENDING'
    }, {
      label: 'Confirmado',
      value: 'CONFIRMED'
    }, {
      label: 'Cancelado',
      value: 'CANCELLED'
    }],
    renderStyle(i, fieldName = 'status') {
      if (i[fieldName]) {
        switch (i[fieldName]) {
          case 'CANCELLED':
          case 'REJECTED':
            return 'danger';
          case 'CONFIRMED':
            return 'success';
        }
      }
      return 'warning';
    }
  }, {
    name: 'errorMessage',
    type: 'text',
    updatable: false
  }, {
    name: 'b2p_status',
    type: 'text',
    updatable: false
  }, {
    name: 'b2p_payment_message',
    updatable: false
  }, {
    name: 'b2p_payment_messageCode',
    updatable: false
  }, {
    name: 'b2p_payment_messageDetails',
    updatable: false
  }, {
    name: 'b2p_payment_provider',
    updatable: false
  }, {
    name: 'b2p_payment_status',
    updatable: false
  }, {
    name: 'konduto_status',
    updatable: false
  }, {
    name: 'konduto_order',
    updatable: false,
    type: 'object',
    graphQLType: 'KondutoOrder',
    graphQLInputType: 'KondutoOrderInput',
    fields: [{
      name: 'recommendation'
    }, {
      name: 'score',
      type: 'float'
    }]
  }, {
    label: 'Resgatado',
    name: 'redeemed',
    type: 'boolean'
  }, {
    label: 'Data de resgate',
    name: 'redeemedDate',
    type: 'datetime'
  }, {
    label: 'Reserva',
    name: 'reservation_id',
    render: i => i.reservation_id != null ? <a target="_blank" href={'/hotelReservations/' + i.reservation_id}>
            {i.reservation_locator}
          </a> : '-'
  }, {
    label: 'Localizador',
    name: 'reservation_locator'
  }, {
    name: 'policies',
    updatable: false
  }, {
    name: 'redeemPolicies',
    updatable: false
  },
  /**
   * OrderId é carregado a partir de SpearOrderItem
   */
  {
    name: 'orderId',
    label: 'Viagem',
    updatable: false,
    renderForm: false
  }],
  filter: [{
    name: 'client_id_eq',
    field: 'clientId',
    op: 'eq'
  }, {
    field: 'productId',
    op: 'eq'
  }, {
    field: 'productType',
    op: 'eq'
  }, {
    field: 'roomType',
    op: 'eq'
  }, {
    field: 'status',
    op: 'eq'
  }, {
    field: 'status',
    op: 'in'
  }, {
    field: 'date',
    op: 'ge'
  }, {
    field: 'date',
    op: 'le'
  }]
});