import { Button, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { Text } from '../../microComponents';
import * as S from './styles';
type DropCartProps = {
  setOpenModalCart?: React.Dispatch<React.SetStateAction<boolean>>;
  setOnCartSummary?: React.Dispatch<React.SetStateAction<boolean>>;
  handleClickCheckout?: () => void;
  tooltipMode?: boolean;
};
const DropCart: React.FC<DropCartProps> = props => {
  const {
    setOpenModalCart,
    setOnCartSummary,
    handleClickCheckout,
    tooltipMode
  } = props;
  const [activeMode, setActiveMode] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (tooltipMode) {
        setActiveMode(false);
      }
    }, 5000);
  }, [tooltipMode]);
  return <ClickAwayListener onClickAway={() => setOpenModalCart(false)}>
      <S.ContainerCart beforeBackground={activeMode ? '#53ac2a' : 'transparent'}>
        {!tooltipMode ? <Stack p="20px">
            <Text marginBottom={16} fontSize={13} fontWeight={700} as="p">
              Quarto adicionado ao seu carrinho
            </Text>

            <Text as="p" fontSize={12} fontWeight={400}>
              Reserve agora ou continue a adicionar Itens ao carrinho.
            </Text>

            <S.BaseButton>
              <Button fullWidth variant="outlined" onClick={() => setOnCartSummary(true)}>
                Ver carrinho
              </Button>

              <Button fullWidth variant="contained" onClick={handleClickCheckout}>
                Reservar Agora
              </Button>
            </S.BaseButton>
          </Stack> : <>
            {activeMode && <S.Tooltip>
                <p>
                  <IoCheckmarkCircleOutline size={20} /> 1 Quarto adicionado ao carrinho
                </p>
              </S.Tooltip>}
          </>}
      </S.ContainerCart>
    </ClickAwayListener>;
};
export default DropCart;