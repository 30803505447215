import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Grupo de marcadores',
  labelPlural: 'Grupos de marcadores',
  name: 'TagGroup',
  insertable: false,
  updatable: true,
  deletable: false,
  authorization: authorization('consultant'),
  columnNames: ['group'],
  dynamoDB: {
    tableName: 'SpearTagGroup',
    sortKey: 'group'
  },
  fields: [{
    name: 'group',
    label: 'Grupo',
    id: true,
    updatable: false
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: true,
    type: 'boolean'
  }],
  filter: [{
    name: 'group_beginsWith',
    field: 'group',
    op: 'beginsWith',
    label: 'Nome do Grupo'
  }, {
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq',
    label: 'Ativo'
  }]
});