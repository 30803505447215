export const Services = [{
  title: 'Serviços Gerais',
  id: 1,
  service: [{
    description: 'Pet',
    code: ''
  }, {
    description: 'Pagamento direto no hotel',
    code: ''
  }, {
    description: 'Pagamento faturado',
    code: ''
  }, {
    description: 'Depósito bancário',
    code: ''
  }, {
    description: 'Paypal',
    code: ''
  }, {
    description: '120AC',
    code: '113'
  }, {
    description: 'Acesso a internet',
    code: '54'
  }, {
    description: ' 120DC',
    code: '114'
  }, {
    description: '220AC',
    code: '115'
  }, {
    description: '220DC',
    code: '117'
  }, {
    description: 'Máscara',
    code: ''
  }, {
    description: 'Álcool gel',
    code: ''
  }, {
    description: 'Lareira',
    code: '41'
  }, {
    description: 'Computador',
    code: '21'
  }, {
    description: 'Telefone com linha directa',
    code: '31'
  }, {
    description: 'Impressora',
    code: '84'
  }, {
    description: 'Ar Condicionado',
    code: '2'
  }, {
    description: 'Equipe Poliglota',
    code: '103'
  }, {
    description: 'Jornais diários',
    code: '73'
  }, {
    description: 'Bagageiro/Carregador',
    code: ''
  }, {
    description: 'Cambio',
    code: ''
  }, {
    description: 'Cartões de Crédito',
    code: ''
  }, {
    description: 'CyberPoint',
    code: ''
  }, {
    description: 'Beach Access',
    code: ''
  }, {
    description: 'Bussiness Center',
    code: ''
  }, {
    description: 'Berço',
    code: '39'
  }, {
    description: 'Babá',
    code: '8'
  }, {
    description: 'Serviços/Sala de Bagagem',
    code: '201'
  }, {
    description: 'Berço disponível a pedido',
    code: '10003'
  }, {
    description: 'Cambios',
    code: '26'
  }, {
    description: 'Loja de Lembranças',
    code: '45'
  }, {
    description: 'Cabeleireiro',
    code: '46'
  }, {
    description: 'Cabeleireiro a Pedido',
    code: '10008'
  }, {
    description: 'Internet Banda Larga',
    code: '259'
  }, {
    description: 'Quartos para Deficientes',
    code: '258'
  }, {
    description: 'Lavanderia/Limpeza a seco',
    code: '58'
  }, {
    description: 'Aceita os principais cartões de crédito',
    code: '10009'
  }, {
    description: 'Equipe Poliglota',
    code: '103'
  }, {
    description: 'Serviço de limpeza diário',
    code: '50'
  }, {
    description: 'Business Center',
    code: '228'
  }, {
    description: 'Recepção 24 horas',
    code: '1'
  }, {
    description: 'Estacionamento',
    code: '68'
  }, {
    description: 'Garagem',
    code: '53'
  }, {
    description: 'Serviço de Despertar',
    code: ''
  }, {
    description: 'Aluguel Auto',
    code: ''
  }, {
    description: 'Fotocópias',
    code: '267'
  }, {
    description: 'Fax/Print',
    code: ''
  }, {
    description: 'Estacionamento com manobrista',
    code: '97'
  }, {
    description: 'Estacionamento Gratuito',
    code: '42'
  }, {
    description: 'Garagem paga',
    code: '171'
  }, {
    description: 'Estacionamento de ônibus',
    code: '192'
  }, {
    description: 'Elevador',
    code: '33'
  }, {
    description: 'Free Wifi',
    code: '10007'
  }, {
    description: 'Cyber Point',
    code: '10004'
  }, {
    description: 'Quartos p/ Deficientes',
    code: ''
  }, {
    description: 'Acesso direto à praia',
    code: '10005'
  }, {
    description: 'Serviços de Aluguel de automóveis',
    code: '15'
  }, {
    description: 'Serviço de Valet',
    code: ''
  }, {
    description: 'Acessibilidade para Cadeira de Rodas',
    code: '44'
  }, {
    description: 'Sala de Jogos',
    code: '101'
  }, {
    description: 'Área reservada a fumantes',
    code: '10016'
  }, {
    description: 'Babá a Pedido',
    code: '10000'
  }, {
    description: 'Internet Banda Larga',
    code: '259'
  }, {
    description: 'Fotocópias',
    code: '267'
  }, {
    description: 'Mordomo',
    code: '22'
  }, {
    description: 'Multibanco',
    code: '7'
  }, {
    description: 'Serviço de limpeza diário',
    code: '50'
  }, {
    description: 'Fax',
    code: '274'
  }, {
    description: 'Posto de Turismo',
    code: '10018'
  }, {
    description: 'Sala de tv',
    code: '10020'
  }, {
    description: 'Lojas/Shopping',
    code: '12'
  }, {
    description: 'Papelaria',
    code: '10017'
  }, {
    description: 'Wireless Internet',
    code: '10021'
  }, {
    description: 'Casino',
    code: '16'
  }, {
    description: 'Cofre para Computadores',
    code: '262'
  }, {
    description: 'Tábua de Passar',
    code: '56'
  }, {
    description: 'Secretária',
    code: '28'
  }, {
    description: 'Área de Estar',
    code: '99'
  }, {
    description: 'Terraço',
    code: '7'
  }, {
    description: 'Telefone',
    code: '107'
  }, {
    description: 'Menu de travesseiros',
    code: '269'
  }, {
    description: 'Apartamento para não Fumantes',
    code: '74'
  }, {
    description: 'Apartamento para fumantes',
    code: '101'
  }, {
    description: 'Internet Wireless',
    code: '123'
  }, {
    description: 'Varanda',
    code: ''
  }]
}, {
  title: 'Restaurantes e Bares',
  id: 2,
  service: [{
    description: 'Serviço de Quarto 24 horas',
    code: '2'
  }, {
    description: 'Bar',
    code: '62'
  }, {
    description: 'Restaurante',
    code: '76'
  }, {
    description: 'Bar de Piscina',
    code: '72'
  }, {
    description: 'Restaurante Buffet',
    code: '10002'
  }, {
    description: 'Serviços de Cocktails',
    code: '19'
  }, {
    description: 'Restaurante Publico',
    code: '10015'
  }, {
    description: 'Restaurante Privado',
    code: '10014'
  }]
}, {
  title: 'Bem-estar e Esportes',
  id: 3,
  service: [{
    description: 'SPA',
    code: '84'
  }, {
    description: 'Serviço de Massagens',
    code: '10002'
  }, {
    description: 'Serviço de Massagens',
    code: '61'
  }, {
    description: 'Ginásio',
    code: '23'
  }, {
    description: 'Salão de Beleza',
    code: '10001'
  }, {
    description: 'Personal Trainer a pedido',
    code: '207'
  }, {
    description: 'Piscina',
    code: '71'
  }, {
    description: 'Piscina Exterior',
    code: '66'
  }, {
    description: 'Piscina Interior',
    code: '54'
  }, {
    description: 'Putting Green',
    code: '5'
  }, {
    description: 'Mini Golfe',
    code: '67'
  }, {
    description: 'Mesa de Bilhar',
    code: '111'
  }, {
    description: 'Sauna',
    code: '79'
  }, {
    description: 'Jacuzzi',
    code: '55'
  }, {
    description: 'Banho Turco',
    code: '10019'
  }, {
    description: 'Esportes aquáticos',
    code: '234'
  }, {
    description: 'Treinador Pessoal a pedido',
    code: '207'
  }, {
    description: 'Mesa de Ping-Pong',
    code: ''
  }, {
    description: 'Kids Club',
    code: '125'
  }, {
    description: 'Massagens a Pedido',
    code: '10010'
  }, {
    description: 'Campo de Tênis',
    code: '233'
  }, {
    description: 'Quadra de Padel',
    code: '10013'
  }, {
    description: 'Parquinho',
    code: '193'
  }, {
    description: 'Aluguel de bicicletas',
    code: '202'
  }, {
    description: 'Piscina externa de água salgada',
    code: '10012'
  }, {
    description: 'Piscina externa infantil de água salgada',
    code: '10011'
  }, {
    description: 'Academia de ginástica gratuita',
    code: '10006'
  }, {
    description: 'Aluguel de bicicletas',
    code: '202'
  }]
}, {
  title: 'Eventos e Conferências',
  id: 4,
  service: [{
    description: 'Salas de Reuniões',
    code: '24'
  }, {
    description: 'Centro de Conferências',
    code: '17'
  }, {
    description: ' Sala de Reuniões',
    code: '10022'
  }, {
    description: 'Auditório',
    code: '4'
  }, {
    description: 'Serviço de Casamentos',
    code: '103'
  }, {
    description: 'Palco',
    code: '18'
  }, {
    description: 'Sala para Concertos',
    code: '15'
  }]
}, {
  title: 'Comidas e bebidas',
  id: 5,
  service: [{
    description: 'Mini-bar',
    code: '69'
  }, {
    description: 'Pratos',
    code: '81'
  }, {
    description: 'Copos',
    code: '183'
  }, {
    description: 'Talheres',
    code: '98'
  }, {
    description: 'Chaleira Eléctrica',
    code: '19'
  }, {
    description: 'Frigobar',
    code: '88'
  }, {
    description: 'Micro-ondas',
    code: '68'
  }, {
    description: 'Cozinha',
    code: '59'
  }, {
    description: 'Kitchenette',
    code: '61'
  }, {
    description: 'Comodidades para café / chá',
    code: '19'
  }, {
    description: 'Torradeira',
    code: '167'
  }]
}, {
  title: 'Entretenimento e multimidia',
  id: 6,
  service: [{
    description: 'TV a Cabo',
    code: '18'
  }, {
    description: 'Leitor de DVD',
    code: '163'
  }, {
    description: 'Leitor de DVD',
    code: '163'
  }, {
    description: 'Televisão',
    code: '251'
  }, {
    description: 'Tv de tela Plano / Plasma / LCD',
    code: '246'
  }, {
    description: 'Leitor de CD',
    code: '129'
  }, {
    description: 'Dock para MP3/4',
    code: '214'
  }, {
    description: 'Pay-TV',
    code: '78'
  }, {
    description: 'Smartv',
    code: ''
  }, {
    description: 'Tv com controle Remoto',
    code: '90'
  }, {
    description: 'Jogos de Vídeo',
    code: '117'
  }, {
    description: 'Video Clube',
    code: '218'
  }, {
    description: 'Wii',
    code: ''
  }]
}, {
  title: 'Produtos de casa e banho',
  id: 7,
  service: [{
    description: 'Secador de cabelo',
    code: '50'
  }, {
    description: 'Secador de cabelo',
    code: '11'
  }, {
    description: 'Banheira ou Duche',
    code: '276'
  }, {
    description: 'Jacuzzi / hidromassagem',
    code: ''
  }, {
    description: 'Jacuzzi',
    code: '55'
  }, {
    description: 'Banheira',
    code: '13'
  }, {
    description: 'Banheiro Compartilhado',
    code: '193'
  }, {
    description: 'Roupão de Banho',
    code: ''
  }, {
    description: 'Bidet',
    code: '16'
  }, {
    description: 'Telefone WC',
    code: '12'
  }, {
    description: 'Ducha',
    code: '12'
  }, {
    description: 'Chinelos de quarto',
    code: '228'
  }]
}];