import type { EntitiesDataProps, HotelResult, Occupancy, OriginalHotelResult, RoomRate } from '@niarab2c/frontend-commons/src/types/hotels';
import shallowEqual from 'fbjs/lib/shallowEqual';
import _pick from 'lodash/pick';
import { prepareHotelResult } from './prepareHotelResult';
export { buildAvailableFilterOptions, filterResults } from './filterResults';
export type { AvailableFilterOptions } from './filterResults';
export { generateQueryString, parseQueryString, stringifyOccupancy } from './queryStringUtils';
export const calculateRemuneration = (roomRate: RoomRate, isClientUser: boolean) => {
  if (!roomRate.priceComposition) return undefined;
  const value = isClientUser ? roomRate.priceComposition.clientCommission && roomRate.priceComposition.clientCommission.value || 0 : (roomRate.priceComposition.commission && roomRate.priceComposition.commission.value || 0) + (roomRate.priceComposition.markup && roomRate.priceComposition.markup.value || 0) - (roomRate.priceComposition.clientCommission && roomRate.priceComposition.clientCommission.value || 0);
  const currency = roomRate.priceComposition.productPrice.currency;
  const percentage = roomRate.priceComposition.productPrice && roomRate.priceComposition.productPrice.value && value / roomRate.priceComposition.productPrice.value || 0;
  return {
    value,
    percentage,
    currency
  };
};
export const isSame = (a, b): boolean => {
  if (a === b) return true;
  if (a == null && b == null) return true;
  if (a == null && b != null) return false;
  if (a != null && b == null) return false;
  return shallowEqual(a, b);
};
const createKey = (roomRate: RoomRate) => JSON.stringify({
  occupancy: roomRate?.occupancy,
  priceComposition: roomRate?.priceComposition && _pick(roomRate?.priceComposition, ['total', 'productPrice', 'commission', 'markup']),
  meal: roomRate?.meal,
  cancelPolicy: roomRate?.cancelPolicy && _pick(roomRate?.cancelPolicy, ['refundable', 'inPenalty']),
  roomType: roomRate?.roomType?.name,
  commission: roomRate?.distribution?.commission?.value,
  public: roomRate?.ratePlan?.public || undefined,
  offers: roomRate?.offers || undefined,
  time: roomRate?.time
});
export const groupRoomRates = roomRates => {
  if (!roomRates) return null;
  const map: {
      [key: string]: RoomRate[];
    } = {},
    keys: string[] = [];
  roomRates.forEach(rr => {
    const key = createKey(rr);
    if (!map[key]) {
      keys.push(key);
      map[key] = [];
    }
    map[key].push(rr);
  });

  // return keys.map((k) => map[k])

  const results = keys.map(k => map[k]);
  return results;
};
export const roomRateComparers = ([(rr): number => rr?.priceSortValue ?? Number.NaN,
// Usa a normalização de comparação de acordo com o câmbio. Se veio nulo, ignorar na comparação.(NIT-1426)
(rr): number => rr?.priceComposition?.total?.value, (rr): number => -1 * rr?._remuneration?.value,
// Ordena pela remuneração, de acordo com o usuário (ex: usuário do cliente usa a comissão do cliente)
(rr): number => rr?.cancelPolicy?.nonRefundable || rr?.cancelPolicy?.inPenalty ? 1 : 0] as const);
export const roomRateSorter = (a: RoomRate, b: RoomRate): number => {
  return roomRateComparers.reduce((acc, comparer) => {
    if (!Number.isNaN(acc) && Math.abs(acc) > 0.01) return acc;
    return comparer(a) - comparer(b) || 0;
  }, 0);
};
export function mergeHotelResults(newResults: Array<Partial<OriginalHotelResult> | Partial<HotelResult>>, results: Record<string, HotelResult> = {}, params?: {
  occupancy?: Occupancy;
  referencePoint?: {
    latitude;
    longitude;
  };
  entitiesData?: EntitiesDataProps;
  promoCode?: string;
  clientId?: string;
  selfBooking: boolean;
  b2c: boolean;
  engineRuleVersion: string;
  quotationToken?: string;
  quotationIndex?: number;
}): Record<string, HotelResult> {
  if (!newResults) return results;
  newResults.filter(x => x).forEach(_result => {
    const soupIds = '_soupIds' in _result && _result._soupIds ? _result._soupIds : _result.hotel?.soupIds;
    const id = '_id' in _result && _result._id ? _result._id : _result.hotel?.id;
    let oldHR: HotelResult;
    if (results?.[id]) {
      oldHR = results?.[id];
    } else if (soupIds) {
      for (const id of soupIds) {
        if (results?.[id]) {
          oldHR = results?.[id];
          break;
        }
      }
    }
    const mergedResult = prepareHotelResult(_result, oldHR, params);
    results[mergedResult._id] = mergedResult;
  });
  return {
    ...results
  };
}