import encrypt from '@niaratech/safe-js-client/dist/browser/encrypt';
import callApi from '@niarab2c/frontend-commons/src/util/callApi';
import { CreditCard } from '@niarab2c/frontend-commons/src/types/checkout';
export type PublicKeyData = {
  keyId: string;
  publicKey: string;
  format: 'spki';
  alg: 'RSA-OAEP';
  hash: 'SHA-256';
  spec: 'RSA_2048';
  symmetricAlg: 'AES-GCM';
};
async function getSafePublicKey(): Promise<PublicKeyData> {
  return await callApi('safe-api', '/spki', 'get').catch(err => null);
}
let safePublickKeyPromise: ReturnType<typeof getSafePublicKey>;
export default async function encryptCreditCard(creditCard: CreditCard): Promise<CreditCard> {
  try {
    const safePublicKeyData = await (safePublickKeyPromise || getSafePublicKey());
    if (safePublicKeyData) {
      const tokenData = [creditCard.cvv, creditCard.number, creditCard.expiryDate?.slice(0, 2), creditCard.expiryDate?.slice(2, 4)];
      const token = await encrypt(tokenData, safePublicKeyData).catch(() => null);
      if (token) {
        creditCard['safeToken'] = token;
        // TODO: mascarar/apagar dados sensíveis
      }
    }
  } catch {
    //ignore
  }
  return creditCard;
}