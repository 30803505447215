import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import React from 'react';
export default fillCrudMetadataGaps({
  label: 'Landing Page',
  labelPlural: 'Landing Pages',
  name: 'LandingPage',
  deletable: false,
  authorization: authorization('admin'),
  columnNames: ['id', 'pageTitle', 'locator', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    render: false
  }, {
    name: 'clientId',
    label: 'Id do cliente',
    render: false,
    required: true,
    updatable: false
  }, {
    name: 'locator',
    label: 'URL da Landing Page',
    custom_prepend: 'https://eventos.niara.tech/',
    required: true,
    updatable: false,
    maxLength: 40,
    minLength: 8,
    pattern: '[a-z][a-z0-9.\\-_]{7,40}',
    hint: 'Este valor não pode ser alterado posteriormente. Entre 8 e 40 caracteres. Apenas letras minúsculas, números, ponto (.) e traço (-), começando por uma letra.',
    render: i => i.locator ? 'https://eventos.niara.tech/' + i.locator : ''
  }, {
    name: 'pageTitle',
    label: 'Título',
    required: true,
    hint: 'Título que será exibido na aba do navegador. Se não tiver logotipo, será exibido na barra de menu.'
  }, {
    label: 'Gerenciada pelo OTA Builder',
    name: 'isOtaBuilderManaged',
    type: 'boolean',
    default: false,
    insertable: false,
    hint: 'Não modifique esta flag se não souber para que ela serve!',
    renderForm: i => !i.enabled
  }, {
    name: 'favicon',
    label: 'Favicon',
    inputType: 'inputPublicFile',
    render: i => i.favicon && <img src={i.favicon}></img>,
    custom_gerarPrefix: i => '/landingPage/' + i.id + '/favicon/',
    insertable: false
  }, {
    name: 'startDate',
    label: 'Data de check-in',
    type: 'date'
  }, {
    name: 'endDate',
    label: 'Data de check-out',
    type: 'date'
  }, {
    name: 'allowedCities',
    label: 'Cidades permitidas',
    type: 'objectarray',
    inputType: 'inputCityToken',
    graphQLType: '[NestedDestination]',
    graphQLInputType: '[NestedDestinationInput]',
    fields: [{
      name: 'id',
      label: 'Id',
      required: true
    }, {
      name: 'name',
      label: 'Nome',
      required: true
    }, {
      name: 'type',
      label: 'Tipo',
      required: true
    }, {
      name: 'city',
      label: 'Cidade'
    }, {
      name: 'state',
      label: 'Estado'
    }, {
      name: 'country',
      label: 'País'
    }]
  }, {
    name: 'allowedHotels',
    label: 'Hotéis permitidos',
    type: 'objectarray',
    inputType: 'inputHotelToken',
    graphQLType: '[NestedDestination]',
    graphQLInputType: '[NestedDestinationInput]',
    fields: [{
      name: 'id',
      label: 'Id',
      required: true
    }, {
      name: 'name',
      label: 'Nome',
      required: true
    }, {
      name: 'type',
      label: 'Tipo',
      required: true
    }, {
      name: 'city',
      label: 'Cidade'
    }, {
      name: 'state',
      label: 'Estado'
    }, {
      name: 'country',
      label: 'País'
    }]
  }, {
    name: 'highlightedHotels',
    label: 'Hotéis em destaque',
    type: 'objectarray',
    inputType: 'inputHotelToken',
    graphQLType: '[NestedDestination]',
    graphQLInputType: '[NestedDestinationInput]',
    fields: [{
      name: 'id',
      label: 'Id',
      required: true
    }, {
      name: 'name',
      label: 'Nome',
      required: true
    }, {
      name: 'type',
      label: 'Tipo',
      required: true
    }, {
      name: 'city',
      label: 'Cidade'
    }, {
      name: 'state',
      label: 'Estado'
    }, {
      name: 'country',
      label: 'País'
    }],
    hint: 'Os hotéis acima são apresentados na homepage em destaque - dê preferência para uma quantidade múltipla de 3 (3, 6, 9...)'
  }, {
    name: 'pointsOfInterest',
    label: 'Pontos de interesse',
    type: 'objectarray',
    inputType: 'googlePlaces',
    graphQLType: '[NestedPointOfInterest]',
    graphQLInputType: '[NestedPointOfInterestInput]',
    hint: 'Coloque o local do evento como o primeiro ponto de interesse',
    fields: [{
      name: 'name',
      label: 'Nome'
    }, {
      name: 'address',
      label: 'Endereço'
    }, {
      name: 'description',
      label: 'Descrição'
    }, {
      name: 'place_id',
      label: 'placeId'
    }, {
      name: 'latitude',
      label: 'Latitude',
      type: 'float'
    }, {
      name: 'longitude',
      label: 'Longitude',
      type: 'float'
    }]
  }, {
    label: 'Calcular distância',
    name: 'rawDistanceEnabled',
    hint: 'Calcula a distância (linha reta) entre o hotel e o primeiro ponto de interesse da lista acima. Ordena os resultados a partir da distância calculada.',
    type: 'boolean'
  }, {
    label: 'Ativo',
    name: 'enabled',
    type: 'boolean',
    default: false,
    insertable: true
  }, {
    label: 'Busca por destinos customizados',
    name: 'destinationGroupEnabled',
    type: 'boolean',
    default: false,
    insertable: true,
    deprecated: true,
    render: false
  }, {
    label: 'Aéreo',
    name: 'flight_b2c',
    default: false,
    type: 'boolean',
    custom_checkTenant: t => t && t.modules_flight
  }, {
    label: 'Vouchers',
    name: 'vouchers_b2c',
    default: false,
    type: 'boolean',
    custom_checkTenant: t => t && t.modules_vouchers
  }, {
    label: 'Anti-fraude',
    name: 'fraudDetectionEnabled',
    default: null,
    type: 'boolean',
    custom_checkTenant: t => t && t.modules_fraudDetection,
    hint: 'Atenção: também é necessário habilitar e configurar o anti-fraude para o cliente'
  }, {
    label: 'Link "Fazer login/ cadastre-se"',
    name: 'userlogin',
    type: 'boolean'
  }, {
    label: 'Link "Minhas reservas"',
    name: 'myReservations',
    default: false,
    type: 'boolean'
  }, {
    label: 'Exibir Homepage',
    name: 'homepageEnabled',
    default: true,
    type: 'boolean'
  }, {
    label: 'Formulário de Busca',
    name: 'homepageSearchFormType',
    options: [{
      value: 'HORIZONTAL',
      label: 'Horizontal'
    }, {
      value: 'VERTICAL',
      label: 'Vertical'
    }],
    default: 'HORIZONTAL',
    hint: 'Estilo do formulário de critérios de busca na homepage',
    renderForm: i => i.homepageEnabled == true
  }, {
    label: 'Barra de Autenticação sempre visível',
    name: 'authenticationBarEnabled',
    default: false,
    type: 'boolean',
    hint: 'Se ativo, uma barra sugerindo que o usuário inicie a sessão será exibida nas telas de busca e checkout.'
  }, {
    label: 'Descrição da Barra de Autenticação',
    name: 'authenticationBarDescription',
    renderForm: i => i.authenticationBarEnabled == true
  }, {
    custom_groupName: "Fundo",
    name: 'bodyBackgroundColor',
    label: 'Cor de fundo da página',
    inputType: 'color',
    hint: 'Valor hexadecimal da cor de fundo da página.',
    placeholder: 'ex: #Eb3030',
    mask: '#______'
  }, {
    custom_groupName: "Fundo",
    name: 'bodyPrimaryColor',
    label: 'Cor do botão principal',
    inputType: 'color',
    hint: 'Valor hexadecimal da cor dos botões e links primários',
    placeholder: 'ex: #eb3030',
    mask: '#______',
    insertable: false
  }, {
    custom_groupName: "Fundo",
    name: 'bodySecondaryColor',
    label: 'Cor do botão secundário',
    inputType: 'color',
    hint: 'Valor hexadecimal da cor dos botões e links secundários',
    placeholder: 'ex: #eb3030',
    mask: '#______',
    insertable: false
  }, {
    custom_groupName: "Cabeçalho e filtro de busca",
    name: 'headerBackgroundColor',
    label: 'Cor de fundo do cabeçalho',
    inputType: 'color',
    hint: 'Valor hexadecimal da cor de fundo do cabeçalho.',
    placeholder: 'ex: #Eb3030',
    mask: '#______'
  }, {
    custom_groupName: "Cabeçalho e filtro de busca",
    name: 'primaryColor',
    label: 'Cor do botão principal',
    inputType: 'color',
    hint: 'Valor hexadecimal da cor de fundo do botão Reserva',
    placeholder: 'ex: #eb3030',
    mask: '#______',
    insertable: false
  }, {
    custom_groupName: "Cabeçalho e filtro de busca",
    name: 'secondaryColor',
    label: 'Cor do botão secundário',
    inputType: 'color',
    hint: 'Valor hexadecimal da cor do link Tenho código promocional',
    placeholder: 'ex: #eb3030',
    mask: '#______',
    insertable: false
  }, {
    custom_groupName: "Customização Barra de Menu",
    name: 'navbarBackgroundColor',
    label: 'Cor de fundo do menu',
    inputType: 'color',
    hint: 'Valor hexadecimal da cor de fundo do menu.',
    placeholder: 'ex: #eb3030',
    default: '#ffffff',
    mask: '#______',
    insertable: false
  }, {
    custom_groupName: "Customização Barra de Menu",
    // Deprecated
    name: 'navbarContrast',
    label: '(DEPRECATED) Cor de texto do menu',
    options: [{
      value: 'dark',
      label: 'Preto'
    }, {
      value: 'light',
      label: 'Branco'
    }],
    default: 'dark',
    deprecated: true,
    render: false,
    insertable: false
  }, {
    custom_groupName: "Customização Barra de Menu",
    name: 'navbarBrandLogoUrl',
    label: 'Logotipo do menu',
    inputType: 'inputPublicFile',
    custom_gerarPrefix: i => i.id + '/navbarbrand/',
    hint: 'Dica: dê preferência a imagens transparentes, sem margens',
    insertable: false
  }, {
    custom_groupName: "Customização Barra de Menu",
    name: 'navbarContainerWidth',
    label: 'Largura do menu',
    options: [{
      value: 'FULL',
      label: 'Ocupar a tela inteira'
    }, {
      value: 'CRITERIA',
      label: 'Alinhar com os critérios de busca'
    }],
    default: 'FULL',
    hint: 'Escolha "Alinhar" para alinhar o logotipo com os campos do formulário de critérios de busca',
    insertable: false
  }, {
    custom_groupName: "Customização Barra de Menu",
    name: 'navbarBrandText',
    label: 'Título do menu',
    hint: 'Título que será exibido na barra do menu.',
    insertable: false
  }, {
    custom_groupName: "Customização Barra de Menu",
    name: 'navbarBrandLogoHeight',
    label: 'Altura do logotipo do menu (em pt)',
    type: 'int',
    insertable: false
  }, {
    custom_groupName: "Customização Barra de Menu",
    name: 'overrideBrandLink',
    label: 'Sobrescrever o link do logotipo',
    type: 'text',
    inputType: 'url',
    insertable: false,
    hint: 'Sobrescrever o comportamento ao clicar o link do logotipo do menu'
  }, {
    custom_groupName: "Customização Barra de Menu",
    label: 'Links customizados do menu',
    name: 'navbarCustomLinks',
    type: 'objectarray',
    graphQLType: '[LandingPageCustomLink]',
    graphQLInputType: '[LandingPageCustomLinkInput]',
    fields: [{
      label: 'Texto',
      name: 'label',
      type: 'text',
      required: true
    }, {
      label: 'Link',
      name: 'ref',
      type: 'text',
      required: true
    }, {
      label: 'É sub-item?',
      name: 'isSubItem',
      type: 'boolean',
      inputType: 'checkbox'
    }]
  }, {
    custom_groupName: "Customização Banner",
    name: 'bannerBackgroundImage',
    label: 'Imagem de fundo do banner',
    inputType: 'inputPublicFile',
    render: i => i.bannerBackgroundImage && <img src={i.bannerBackgroundImage}></img>,
    custom_gerarPrefix: i => '/landingPage/' + i.id + '/bannerBackground/',
    insertable: false
  }, {
    custom_groupName: "Customização Banner",
    name: 'bannerBackgroundColor',
    label: 'Cor de fundo do banner',
    inputType: 'color',
    hint: 'Valor hexadecimal da cor de fundo do banner.',
    placeholder: 'ex: #eb3030',
    mask: '#______',
    insertable: false
  }, {
    custom_groupName: "Customização Banner",
    name: 'bannerHeight',
    label: 'Altura do banner (em pt)',
    type: 'int',
    insertable: false
  }, {
    custom_groupName: "Customização Banner",
    name: 'bannerText',
    label: 'Texto do banner',
    type: 'textarray',
    inputType: 'textarea',
    insertable: false
  }, {
    custom_groupName: "Customização Banner",
    name: 'bannerTextColor',
    label: 'Cor de texto do banner',
    inputType: 'color',
    hint: 'Valor hexadecimal da cor do texto do banner.',
    placeholder: 'ex: #eb3030',
    mask: '#______',
    insertable: false
  }, {
    custom_groupName: "Customização Banner",
    name: 'bannerSize',
    label: 'Alinhamento do tamanho do banner',
    inputType: 'select',
    insertable: false,
    default: 'cover',
    options: [{
      label: 'Cobrir todo o espaço - pode cortar parte da imagem',
      value: 'cover'
    }, {
      label: 'Mostrar a imagem inteira na tela - pode sobrar espaço em branco ao redor da imagem',
      value: 'contain'
    }, {
      label: 'Altura 100% - pode cortar as laterais da imagem',
      value: 'auto 100%'
    }, {
      label: 'Largura 100% - pode cortar o topo e a parte de baixo da imagem',
      value: '100% auto'
    }]
  }, {
    custom_groupName: "Customização Banner (mobile)",
    name: 'bannerBackgroundImageMobile',
    label: 'Imagem de fundo do banner em dispositivos móveis',
    inputType: 'inputPublicFile',
    render: i => i.bannerBackgroundImageMobile && <img src={i.bannerBackgroundImageMobile}></img>,
    custom_gerarPrefix: i => '/landingPage/' + i.id + '/bannerBackgroundMobile/',
    insertable: false
  }, {
    custom_groupName: "Customização Banner (mobile)",
    name: 'bannerHeightMobile',
    label: 'Altura do banner em dispositivos móveis (em pt)',
    type: 'int',
    insertable: false
  }, {
    custom_groupName: "Customização Banner (mobile)",
    name: 'bannerSizeMobile',
    label: 'Alinhamento do tamanho do banner em dispositivos móveis',
    inputType: 'select',
    default: 'cover',
    insertable: false,
    options: [{
      label: 'Cobrir todo o espaço - pode cortar parte da imagem',
      value: 'cover'
    }, {
      label: 'Não cortar a imagem - pode sobrar espaço em branco ao redor da imagem',
      value: 'contain'
    }, {
      label: 'Altura 100% - pode cortar as laterais da imagem',
      value: 'auto 100%'
    }, {
      label: 'Largura 100% - pode cortar o topo e a parte de baixo da imagem',
      value: '100% auto'
    }]
  }, {
    custom_groupName: "Rodapé",
    name: 'footerText',
    label: 'Texto de rodapé',
    type: 'text',
    inputType: 'textarea',
    hint: 'O rodapé será exibido em todas as telas'
  }, {
    custom_groupName: "Rodapé",
    name: 'footerBackgroundColor',
    label: 'Cor de fundo do rodapé',
    inputType: 'color',
    hint: 'Valor hexadecimal da cor de fundo do rodapé.',
    placeholder: 'ex: #Eb3030',
    mask: '#______'
  }, {
    custom_groupName: "Rodapé",
    name: 'footerTextColor',
    label: 'Cor de texto do rodapé',
    inputType: 'color',
    hint: 'Valor hexadecimal da cor de texto do rodapé.',
    placeholder: 'ex: #Eb3030',
    mask: '#______'
  }, {
    custom_groupName: "Rodapé",
    name: 'footerIframeUrl',
    label: 'URL de footer',
    inputType: 'url',
    hint: 'URL de footer em html - será aberto em um iframe. Obrigatório HTTPS',
    pattern: `^https:\\/\\/[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)`,
    insertable: false
  }, {
    custom_groupName: "Rodapé",
    name: 'footerIframeHeight',
    label: 'Altura do footer',
    insertable: false,
    renderForm: i => (i.footerIframeUrl?.length > 0 && i.footerIframeUrl?.startsWith('https://')) == true
  }, {
    custom_groupName: "Age Gate",
    name: 'ageGateEnabled',
    label: 'Habilitar age gate',
    type: 'boolean',
    insertable: false,
    hint: 'Ao entrar na página, o usuário precisará confirmar que possui mais de 18 anos. Pode ser pulado com o parâmetro ?skipAgeGate'
  }, {
    custom_groupName: "Age Gate",
    name: 'ageGateFailureUrl',
    label: 'Endereço de falha do age gate',
    type: 'text',
    inputType: 'url',
    insertable: false,
    hint: 'Endereço ao qual o usuário menor de idade será redirecionado.',
    renderForm: i => i.ageGateEnabled == true
  }, {
    custom_groupName: "Age Gate",
    name: 'ageGateIframeUrl',
    label: 'Endereço para tela de age gate',
    type: 'text',
    inputType: 'url',
    insertable: false,
    hint: 'Endereço da página que contém o HTML do age gate.',
    renderForm: i => i.ageGateEnabled == true
  }, {
    custom_groupName: "Customização Avançada",
    name: 'cssRules',
    label: 'Regras CSS customizadas',
    type: 'textarray',
    inputType: 'textarea',
    insertable: false,
    hint: 'Uma regra por linha. Exemplo: .niara-brand-logo { height: 60px !important }'
  }, {
    custom_groupName: "Customização Avançada",
    name: 'cssRulesMobile',
    label: 'Regras CSS customizadas (mobile)',
    type: 'textarray',
    inputType: 'textarea',
    insertable: false,
    hint: 'Uma regra por linha. Exemplo: .niara-brand-logo { height: 60px !important }'
  }, {
    custom_groupName: "Customização Avançada",
    name: 'navbarCSS',
    label: 'CSS da barra de menu',
    type: 'textarray',
    inputType: 'textarea',
    insertable: false,
    hint: 'Uma regra por linha. Exemplo: .niara-brand-logo { height: 60px !important }'
  }, {
    custom_groupName: "Customização Avançada",
    name: 'bannerCSS',
    label: 'CSS do banner',
    type: 'textarray',
    inputType: 'textarea',
    insertable: false,
    hint: 'Uma regra por linha. Exemplo: .niara-brand-logo { height: 60px !important }'
  }, {
    custom_groupName: "Customização Avançada",
    name: 'bannerCSSMobile',
    label: 'CSS do banner em dispositivos móveis',
    type: 'textarray',
    inputType: 'textarea',
    insertable: false,
    hint: 'Uma regra por linha. Exemplo: .niara-brand-logo { height: 60px !important }'
  }, {
    custom_groupName: "Customização Avançada",
    name: 'footerCSSMobile',
    label: 'CSS do rodapé em dispositivos móveis',
    type: 'text',
    inputType: 'textarea',
    insertable: false,
    hint: 'Uma regra por linha. Exemplo: .niara-brand-logo { height: 60px !important }'
  }, {
    custom_groupName: "Google Tag Manager",
    name: 'gtmId',
    label: 'ID do Container do Google Tag Manager',
    hint: 'Formato: GTM-XXXXX',
    placeholder: 'ex: GTM-XXXXX',
    pattern: '^GTM-[A-Z0-9]{1,7}$'
  }, {
    custom_groupName: "Termos e condições",
    name: 'terms_text',
    label: 'Descrição dos termos e condições',
    type: 'text',
    inputType: 'wysiwyg',
    hint: 'O texto será exibido na tela de reserva.'
  }, {
    name: 'terms_file',
    label: 'Arquivo dos termos e condições',
    inputType: 'inputPublicFile',
    fileType: 'pdf',
    custom_gerarPrefix: i => '/landingPage/' + i.id + '/terms/',
    insertable: false,
    hint: 'Será exibido um link para acesso ao arquivo na tela de reserva.'
  }, {
    custom_groupName: "Barra de Mensagem",
    name: 'messageBarEnabled',
    label: 'Habilitar barra de mensagem',
    type: 'boolean',
    default: false,
    hint: 'Será exibida uma barra no topo com a mensagem configurada, sempre que a página for carregada'
  }, {
    custom_groupName: "Barra de Mensagem",
    name: 'messageBarTitle',
    label: 'Título da barra de mensagem',
    type: 'text',
    renderForm: i => i.messageBarEnabled == true
  }, {
    custom_groupName: "Barra de Mensagem",
    name: 'messageBarDescription',
    label: 'Descrição da barra de mensagem',
    type: 'text',
    renderForm: i => i.messageBarEnabled == true
  }, {
    custom_groupName: "Destaque da Busca",
    name: 'searchHighlightEnabled',
    label: 'Habilitar destaque da busca',
    type: 'boolean',
    default: false,
    hint: 'Será exibido um painel de destaque na busca, abaixo do carrinho.'
  }, {
    custom_groupName: "Destaque da Busca",
    name: 'searchHighlightImage',
    label: 'Imagem',
    inputType: 'inputPublicFile',
    render: i => i.searchHighlightImage && <img src={i.searchHighlightImage}></img>,
    custom_gerarPrefix: i => '/landingPage/' + i.id + '/searchHighlightImage/',
    insertable: false,
    renderForm: i => i.searchHighlightEnabled == true
  }, {
    custom_groupName: "Destaque da Busca",
    name: 'searchHighlightTitle',
    label: 'Título',
    type: 'text',
    renderForm: i => i.searchHighlightEnabled == true
  }, {
    custom_groupName: "Destaque da Busca",
    name: 'searchHighlightDescription',
    label: 'Descrição',
    type: 'text',
    renderForm: i => i.searchHighlightEnabled == true
  }, {
    custom_groupName: "Destaque da Busca",
    name: 'searchHighlightHint',
    label: 'Tooltip',
    type: 'text',
    renderForm: i => i.searchHighlightEnabled == true
  }, {
    custom_groupName: "Destaque do Checkout",
    name: 'checkoutHighlightEnabled',
    label: 'Habilitar destaque do checkout',
    type: 'boolean',
    default: false,
    hint: 'Será exibido um painel de destaque na tela de checkout'
  }, {
    custom_groupName: "Destaque do Checkout",
    name: 'checkoutHighlightTitle',
    label: 'Título',
    type: 'text',
    renderForm: i => i.checkoutHighlightEnabled == true
  }, {
    custom_groupName: "Destaque do Checkout",
    name: 'checkoutHighlightDescription',
    label: 'Descrição',
    type: 'text',
    renderForm: i => i.checkoutHighlightEnabled == true
  }, {
    custom_groupName: "Destaque do Checkout",
    name: 'checkoutHighlightHint',
    label: 'Tooltip',
    type: 'text',
    renderForm: i => i.checkoutHighlightEnabled == true
  }],
  filter: [{
    name: 'client_id_eq',
    field: 'clientId',
    op: 'eq',
    label: 'Client Id'
  }]
});