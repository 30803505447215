import fillCrudMetadata from '@niaratech/devcase-crud/dist/fillCrudMetadataGaps';
import storefront from '../storefront';
export default fillCrudMetadata({
  label: 'Página publicada',
  labelPlural: 'Storefronts',
  name: 'PublishedStorefront',
  insertable: false,
  updatable: false,
  deletable: false,
  authorization: {
    read: ['unauthenticated']
  },
  dynamoDB: {
    tableName: process.env.OTABUILDER_PUBLISHED_STOREFRONT_TABLE,
    partitionKey: 'tenantId',
    indexedProp: 'id'
  },
  columnNames: ['id', 'name', 'locator', 'enabled'],
  fields: storefront.fields.map(r => ({
    ...r,
    updatable: false
  }))
});