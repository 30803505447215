import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _isEqual from 'lodash/isEqual';
import type { HotelResult, RoomRate } from '@niarab2c/frontend-commons/src/types/hotels';

/**
 * Criteria usado no niara-spear
 */
export type NiaraCriteria = {
  time: {
    startDate: string;
    endDate: string;
  };
  occupancy: {
    adults: number;
    children?: number;
    childrenAges?: number[];
    quantity: number;
  };
  tags?: Array<string>;
  destinations?: {
    cityIds?: Array<string>;
    hotelIds?: Array<string>;
    propertyId?: string;
    contentType?: string;
    position?: {
      latitude: number;
      longitude: number;
    };
  };
  landingPage?: string;
  clientId: string;
  clientName?: string;
  searchId?: string;
  selfBooking?: boolean;
  selfBookingTraveller?: boolean;
  b2c?: boolean;
  bestOnly?: boolean;
  travellerId?: string;
  promoCode?: string;
  destinationName?: string;
  propertyId?: string;
  contentType?: string;
  descriptions?: boolean;
  credentialIds?: string[];
  paymentTypes?: string[];
  modification?: {
    reservationIdOnSource;
    roomIdOnSource;
  };
  currencyCode?;
  splitRooms?: boolean;
  availRatesOnly?: boolean;
  distributionIds?: string[];
  roomRateIds?: string[];
  limit?: number;
  offset?: number;
  propertyCnt?: number;
};
export type MailableResult = {
  id: string;
  label: string;
  result: Pick<HotelResult, 'hotel' | '_id'>;
  roomRates: RoomRate[];
  criteria: NiaraCriteria;
  person?: {
    personName: string;
    personId: string;
  };
};
export type QuotationRoomRatesState = {
  selectedRoomRates: MailableResult[];
  openModal?: boolean;
};
const initialState: QuotationRoomRatesState = {
  selectedRoomRates: []
};
export const quotationRoomRatesSlice = createSlice({
  name: 'quotationRoomRates',
  initialState,
  reducers: {
    add: (state, action: PayloadAction<MailableResult>) => {
      const roomRate = state.selectedRoomRates?.find(r => r.id === action.payload.id);
      if (!roomRate) {
        const engineRuleVersion = action.payload.roomRates[0].engineRuleVersion;
        const selectedResultsForMail = (state.selectedRoomRates ?? []).concat(action.payload).filter(
        // não deixar misturar tarifas de engineRuleVersion diferentes
        r => r.roomRates[0].engineRuleVersion == engineRuleVersion);
        state.selectedRoomRates = selectedResultsForMail;
        state.openModal = undefined;
      }
    },
    remove: (state, action: PayloadAction<MailableResult>) => {
      const roomRates = state.selectedRoomRates?.filter(r => r.id !== action.payload.id);
      state.selectedRoomRates = roomRates;
      state.openModal = undefined;
    },
    updateSelectedRoomRates: (state, action: PayloadAction<MailableResult[]>) => {
      if (!_isEqual(state.selectedRoomRates, action.payload)) {
        state.selectedRoomRates = action.payload;
        state.openModal = undefined;
      }
    },
    updateSelectedRoomRatesAndOpenModal: (state, action: PayloadAction<MailableResult[]>) => {
      if (!_isEqual(state.selectedRoomRates, action.payload)) {
        state.selectedRoomRates = action.payload;
        state.openModal = true;
      }
    },
    updateOpenModal: (state, action: PayloadAction<boolean | undefined>) => {
      state.openModal = action.payload;
    }
  }
});
export const {
  add,
  remove,
  updateSelectedRoomRates,
  updateSelectedRoomRatesAndOpenModal,
  updateOpenModal
} = quotationRoomRatesSlice.actions;
export default quotationRoomRatesSlice.reducer;