import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import { currencies } from '../currencyExchangeRule';
export default fillCrudMetadataGaps({
  label: 'Comissão Cliente',
  labelPlural: 'Comissão Cliente',
  name: 'CommissionRule',
  namePlural: 'CommissionRules',
  authorization: authorization('consultant', 'admin'),
  custom_withAudit: true,
  deletable: false,
  dynamoDB: {
    tableName: 'SpearBuyerCommissionRule'
  },
  columns: ['id', 'name', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Nome da Regra',
    required: true
  }, {
    name: 'enabled',
    label: 'Ativo',
    type: 'boolean'
  }, {
    name: 'commissionReference',
    label: 'Cálculo da commisão',
    custom_groupName: "Comissão",
    options: [{
      label: '% Preço de Venda',
      value: 'TOTAL'
    }, {
      label: '% Revenue Share',
      value: 'REVENUE_SHARE'
    }, {
      label: 'Valor Fixo',
      value: 'FEE'
    }]
  }, {
    name: 'commissionPercentage',
    label: 'Valor',
    // Quando commissionReference for TOTAL, mostrar "Valor", quando for REVENUE_SHARE, mostrar "Dividir a receita em %"
    type: 'float',
    inputType: 'percentage',
    renderForm: i => i.commissionReference === 'TOTAL' || i.commissionReference === 'REVENUE_SHARE',
    custom_groupName: "Comissão"
  }, {
    name: 'commissionAppliesOnTaxes',
    label: 'Calcular comissão sobre diárias + taxas',
    type: 'boolean',
    renderForm: i => i.commissionReference === 'TOTAL',
    custom_groupName: "Comissão"
  }, {
    name: 'commissionReductionAllowed',
    label: 'Permite reduzir comissão',
    type: 'boolean',
    hint: 'Apenas se comissão é aplicada sobre o total',
    renderForm: i => i.commissionReference === 'TOTAL',
    custom_groupName: "Comissão",
    onEntityChange(oldEntity, newEntity) {
      //Validação necessária para evitar rerender infinito #192127
      if (oldEntity?.commissionReference === newEntity?.commissionReference) {
        return newEntity;
      }
      if (newEntity?.commissionReference !== 'TOTAL') {
        return {
          ...newEntity,
          commissionReductionAllowed: null
        };
      }
      return newEntity;
    }
  }, {
    name: 'feeType',
    label: 'Tipo de fee',
    type: 'text',
    inputType: 'select',
    options: [{
      label: 'Por diária',
      value: 'DAILY'
    }, {
      label: 'Por reserva',
      value: 'TOTAL'
    }],
    custom_groupName: "Valor Fixo",
    renderForm: i => i.commissionReference === 'FEE'
  }, {
    name: 'feeValue',
    label: 'Valor',
    type: 'float',
    renderForm: i => i.commissionReference === 'FEE',
    custom_groupName: "Valor Fixo"
  }, {
    name: 'feeCurrency',
    label: 'Moeda',
    type: 'text',
    inputType: 'select',
    options: currencies,
    default: 'BRL',
    renderForm: i => i.commissionReference === 'FEE',
    custom_groupName: "Valor Fixo"
  }, {
    name: 'commissionPercentageDisplayAppliesOnTaxes',
    label: 'Cálculo de apresentação de porcentagem inclui taxas',
    type: 'boolean',
    hint: 'Se habilitado calcula porcentagem da comissão sobre total; senão sobre diárias',
    custom_groupName: "Apresentação"
  }],
  filter: []
});