import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import { indirect_reservation_fields, reservation_fields } from '../credential';
import { quotation_fields } from '../credential';
import { person_fields } from '../credential';
import { lostReservation_fields } from '../credential';
export default fillCrudMetadataGaps({
  label: 'Configuração de Setup',
  labelPlural: 'Configurações de Setup',
  name: 'SetupConfiguration',
  namePlural: 'SetupConfigurations',
  authorization: authorization('admin'),
  deletable: false,
  dynamoDB: {
    tableName: 'SpearSetupConfiguration'
  },
  columns: ['id', 'name'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Nome',
    type: 'text',
    required: true
  }, {
    custom_groupName: "Campos Customizados",
    name: 'mapeamento',
    label: 'Mapeamento de campos',
    type: 'objectarray',
    graphQLType: '[NestedMappingSetupSharp]',
    graphQLInputType: '[NestedMappingSetupSharpInput]',
    fields: [{
      name: 'label',
      label: 'Nome',
      type: 'text',
      required: true
    }, {
      name: 'dataType',
      label: 'Tipo do Dado',
      required: true,
      inputType: 'select',
      options: [{
        value: 'string',
        label: 'Text Input'
      }, {
        value: 'textarea',
        label: 'Área de texto'
      }, {
        value: 'picklist',
        label: 'Drop-Down Select'
      }, {
        value: 'url',
        label: 'URL'
      }, {
        value: 'datetime',
        label: 'DateTime Picker'
      }, {
        value: 'date',
        label: 'Date Picker'
      }, {
        value: 'Grupo de caixa de seleção',
        label: 'checkbox'
      }]
    }, {
      name: 'isAvailableInContactManager',
      label: 'Visible',
      type: 'boolean',
      default: true,
      required: true
    }, {
      name: 'isAvailableInForms',
      label: 'Disp. Forms',
      type: 'boolean',
      default: false,
      required: true
    }, {
      name: 'isEditableInContactManager',
      label: 'Editável',
      type: 'boolean',
      default: false,
      required: true
    }, {
      name: 'niara',
      label: 'Campo Niara',
      withNullSelector: true,
      inputType: 'select',
      options: [...person_fields, ...quotation_fields, ...reservation_fields, ...lostReservation_fields, ...indirect_reservation_fields]
    }]
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: false,
    required: true,
    type: 'boolean'
  }],
  filter: [{
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq'
  }]
});