import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Regra',
  labelPlural: 'Regras',
  name: 'Rule',
  deletable: false,
  authorization: authorization('admin'),
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    render: false
  }, {
    name: 'name',
    label: 'Nome',
    custom_createMarkup: true
  }, {
    name: 'observation',
    label: 'Observação',
    custom_createMarkup: true
  }, {
    name: 'priority',
    label: 'Prioridade',
    type: 'float',
    required: true,
    default: 0
  }, {
    name: 'enabled',
    label: 'Ativo',
    type: 'boolean',
    default: true
  }, {
    name: 'hidden',
    label: 'Escondido',
    type: 'boolean',
    default: false
  }, {
    name: 'label',
    label: 'Categoria',
    options: [{
      value: 'Markup',
      label: 'Markup'
    }, {
      value: 'Promotion',
      label: 'Promoção'
    }, {
      value: 'Blacklist',
      label: 'Blacklist'
    }, {
      value: 'Preference',
      label: 'Preferência'
    }],
    updatable: false
  }, {
    name: 'actionType',
    label: 'Tipo da Ação',
    options: [{
      value: 'Number',
      label: 'Número',
      custom_labels: ['Markup', 'Promotion', 'Penalty']
    }, {
      value: 'String',
      label: 'Texto',
      custom_labels: []
    }, {
      value: 'Percentage',
      label: 'Porcentagem',
      custom_labels: ['Markup', 'Promotion', 'Penalty']
    }]
  }, {
    name: 'actionValue',
    label: 'Valor da Ação'
  }, {
    name: 'when',
    label: 'Quando',
    options: [{
      value: '',
      label: '-'
    }, {
      value: 'CheckInDateBetween',
      label: 'Check-in entre'
    }, {
      value: 'ReservationDateBetween',
      label: 'Reserva entre'
    }, {
      value: 'CheckinReservationDateBetween',
      label: 'Reserva e Check-in nos períodos'
    }, {
      value: 'AntecedenceGt',
      label: 'Antecedência maior que'
    }, {
      value: 'AntecedenceLt',
      label: 'Antecedência menor que'
    }, {
      value: 'NightsGt',
      label: 'Duração maior que'
    }]
  }, {
    name: 'whenValue',
    label: 'Valor de Quando'
  }, {
    name: 'where',
    label: 'Onde',
    options: [{
      value: '',
      label: '-'
    }, {
      value: 'CountryIdEq',
      label: 'País é',
      remove_types: ['Preference', 'VCN']
    }, {
      value: 'CountryIdNe',
      label: 'País não é',
      remove_types: ['Preference', 'VCN']
    }, {
      value: 'CityIdEq',
      label: 'Cidade é',
      remove_types: ['Preference', 'VCN']
    }, {
      value: 'CityIdNe',
      label: 'Cidade não é',
      remove_types: ['Preference', 'VCN']
    }, {
      value: 'HotelIdEq',
      label: 'Hotel é'
    }, {
      value: 'HotelIdNe',
      label: 'Hotel não é'
    }, {
      value: 'ChainIdEq',
      label: 'Rede de hotéis é'
    }, {
      value: 'ChainIdNe',
      label: 'Rede de hotéis não é'
    }, {
      value: 'RatingGt',
      label: 'Categoria maior que',
      remove_types: ['Markup', 'VCN']
    }, {
      value: 'RatingLt',
      label: 'Categoria menor que',
      remove_types: ['Markup', 'VCN']
    }, {
      value: 'CredentialEq',
      label: 'Credencial é'
    }, {
      value: 'CredentialNe',
      label: 'Credencial não é'
    }, {
      value: 'RatePlanTypeEq',
      label: 'Tarifa é'
    }, {
      value: 'RatePlanTypeNe',
      label: 'Tarifa não é'
    }]
  }, {
    name: 'whereValue',
    label: 'Valor de Onde'
  }, {
    name: 'who',
    label: 'Quem',
    options: [{
      value: '',
      label: '-'
    }, {
      value: 'TagIdEq',
      label: 'Marcador é'
    }, {
      value: 'TagIdNe',
      label: 'Marcador não é'
    }, {
      value: 'ClientIdEq',
      label: 'Cliente é'
    }, {
      value: 'ClientIdNe',
      label: 'Cliente não é'
    }]
  }, {
    name: 'whoValue',
    label: 'Valor de Quem'
  }, {
    name: 'whoValueDescription',
    label: 'Quem (desc)'
  }, {
    name: 'whereValueDescription',
    label: 'Onde (desc valor)'
  }, {
    name: 'whenValueDescription',
    label: 'Quando (desc valor)'
  }],
  filter: [{
    name: 'label_eq',
    field: 'label',
    op: 'eq',
    label: 'Tipo'
  }, {
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq',
    label: 'Ativo'
  }, {
    name: 'hidden_eq',
    field: 'hidden',
    op: 'eq',
    label: 'Escondido'
  }, {
    name: 'hidden_ne',
    field: 'hidden',
    op: 'ne',
    label: '!Escondido'
  }]
});