import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import tenant from '../tenant';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Instalação',
  labelPlural: 'Instalações',
  name: 'CurrentTenant',
  insertable: false,
  updatable: true,
  deletable: false,
  searchable: false,
  authorization: authorization('unauthenticated', 'master'),
  dynamoDB: {
    tableName: 'SpearTenant'
  },
  columnNames: ['tenantId', 'id', 'name', 'url'],
  fields: [...tenant.fields].filter(f => !f['custom:excludeForCurrentTenant']).map(r => ({
    ...r,
    updatable: r['custom:updatableCurrentTenant'] === true
  }))
});