import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
declare module '@niaratech/devcase-crud/dist/types' {
  interface Field<T = any, E = any> {
    'custom:selfPersonUpdatable'?: boolean;
    custom_obfuscateValue?: boolean;
  }
}
export default fillCrudMetadataGaps({
  label: 'Pessoa',
  labelPlural: 'Pessoas',
  name: 'Person',
  namePlural: 'People',
  custom_withAudit: true,
  authorization: authorization('clientConsultant', 'admin'),
  dynamoDB: {
    tableName: 'SpearPerson',
    indexes: [{
      indexName: 'tenantId-clientId-index',
      indexedProp: 'clientId',
      scanIndexBackwards: true
    }, {
      indexName: 'tenantId-niaraAccountStatus-index',
      indexedProp: 'niaraAccountStatus',
      scanIndexBackwards: true
    }]
  },
  insertable: true,
  updatable: true,
  deletable: false,
  columnNames: ['id', 'firstName', 'lastName', 'cpf', 'email', 'clientName', 'enabled', 'niaraAccountStatus', 'passport'],
  importColumnNames: ['clientName', 'firstName', 'lastName', 'phone', 'email', 'enabled', 'dob', 'registrationSource', 'country', 'addressStreetName', 'addressNumber', 'addressZipCode', 'addressAdditionalInformation', 'addressDistrict', 'addressCity', 'addressState', 'cpf', 'rg', 'passport', 'corporateName', 'corporateBrandName', 'corporatePhone', 'corporateCnpj', 'corporateDepartment', 'corporateRegistration', 'corporatePosition', 'corporateEmail'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'clientId',
    label: 'Id do Cliente'
  }, {
    name: 'clientName',
    label: 'Cliente'
  }, {
    name: 'firstName',
    label: 'Nome',
    pattern: '^[A-Za-zÀ-ÖØ-öø-ÿ ]*$',
    'custom:selfPersonUpdatable': true
  }, {
    name: 'lastName',
    label: 'Sobrenome',
    pattern: '^[A-Za-zÀ-ÖØ-öø-ÿ ]*$',
    'custom:selfPersonUpdatable': true
  }, {
    name: 'email',
    label: 'Email',
    'custom:selfPersonUpdatable': false,
    inputType: 'email'
  }, {
    name: 'phone',
    label: 'Telefone',
    mask: '99 99 99999-9999',
    'custom:selfPersonUpdatable': true
  }, {
    name: 'locale',
    label: 'Idioma',
    options: [{
      label: 'Português (Brasil)',
      value: 'pt-BR'
    }, {
      label: 'Inglês',
      value: 'en'
    }, {
      label: 'Espanhol',
      value: 'es'
    }, {
      label: 'Espanhol (MX)',
      value: 'es-MX'
    }, {
      label: 'Espanhol (CO)',
      value: 'es-CO'
    }],
    'custom:selfPersonUpdatable': true
  }, {
    name: 'ageGroup',
    label: 'Adulto ou criança'
  }, {
    name: 'birthDate',
    label: '-IGNORAR-',
    type: 'date',
    deprecated: true
  }, {
    name: 'age',
    label: '-IGNORAR-',
    deprecated: true
  }, {
    name: 'dob',
    label: 'Data de nascimento',
    type: 'date',
    'custom:selfPersonUpdatable': true
  }, {
    name: 'nationality',
    label: 'Nacionalidade',
    'custom:selfPersonUpdatable': true
  }, {
    name: 'country',
    label: 'País de residência',
    pattern: '[A-Z]{2}',
    'custom:selfPersonUpdatable': true
  }, {
    name: 'registrationSource',
    label: 'Origem do Cadastro'
  }, {
    name: 'addressStreetName',
    label: 'Rua',
    'custom:selfPersonUpdatable': true
  }, {
    name: 'addressNumber',
    label: 'numero',
    'custom:selfPersonUpdatable': true
  }, {
    name: 'addressZipCode',
    label: 'CEP',
    'custom:selfPersonUpdatable': true
  }, {
    name: 'addressAdditionalInformation',
    label: 'Complemento',
    'custom:selfPersonUpdatable': true
  }, {
    name: 'addressDistrict',
    label: 'Bairro',
    'custom:selfPersonUpdatable': true
  }, {
    name: 'addressCity',
    label: 'Cidade',
    'custom:selfPersonUpdatable': true
  }, {
    name: 'addressState',
    label: 'Estado',
    'custom:selfPersonUpdatable': true
  }, {
    name: 'cpf',
    type: 'text',
    mask: '999.999.999-99',
    pattern: '^[0-9]{3}\\.?[0-9]{3}\\.?[0-9]{3}-?[0-9]{2}$'
  }, {
    name: 'rg',
    type: 'text',
    maxLength: 20
  }, {
    name: 'birthCertificate',
    type: 'text'
  }, {
    name: 'passport',
    label: 'Passaporte',
    type: 'text',
    'custom:selfPersonUpdatable': false
  }, {
    name: 'enabled',
    label: 'Status',
    type: 'boolean',
    default: true
  }, {
    name: 'corporateName',
    label: 'Razão Social',
    type: 'text'
  }, {
    name: 'corporateBrandName',
    label: 'Nome Fantasia',
    type: 'text'
  }, {
    name: 'corporatePhone',
    label: 'Telefone',
    type: 'text'
  }, {
    name: 'corporateCnpj',
    label: 'cnpj',
    type: 'text',
    mask: '99.999.999/9999-99'
  }, {
    name: 'corporateDepartment',
    label: 'Departamento',
    type: 'text'
  }, {
    name: 'corporateRegistration',
    label: 'Matrícula',
    type: 'text'
  }, {
    name: 'corporatePosition',
    label: 'Cargo',
    type: 'text'
  }, {
    name: 'corporateEmail',
    label: 'E-mail',
    type: 'text',
    inputType: 'email'
  }, {
    name: 'corporateZipCode',
    label: 'CEP',
    type: 'text'
  }, {
    name: 'corporateState',
    label: 'Estado',
    type: 'text'
  }, {
    name: 'corporateCity',
    label: 'Cidade',
    type: 'text'
  }, {
    name: 'corporateStreetName',
    label: 'Logradouro',
    type: 'text'
  }, {
    name: 'corporateNumber',
    label: 'Número',
    type: 'text'
  }, {
    name: 'corporateAdditionalInformation',
    label: 'Complemento',
    type: 'text'
  }, {
    name: 'corporateDistrict',
    label: 'Bairro',
    type: 'text'
  }, {
    name: 'corporateCountry',
    label: 'País',
    type: 'text'
  }, {
    name: 'niaraAccountStatus',
    label: 'Conta niara',
    type: 'text',
    updatable: false,
    insertable: false,
    options: [{
      label: 'Processando',
      value: 'PENDING'
    }, {
      label: 'Processando',
      value: 'PROCESSING'
    }, {
      label: 'Criado',
      value: 'CONFIRMED'
    }, {
      label: 'Erro',
      value: 'ERROR'
    }],
    renderStyle(i, fieldName = 'niaraAccountStatus') {
      switch (i[fieldName]) {
        case 'PENDING':
        case 'PROCESSING':
          return 'warning';
        case 'CONFIRMED':
          return 'success';
        case 'ERROR':
          return 'danger';
      }
      return null;
    }
  }, {
    name: 'niaraAccountStatusError',
    label: 'Erro ao criar conta niara automaticamente',
    type: 'text',
    updatable: false,
    insertable: false
  }, {
    name: 'picture',
    label: 'Foto',
    type: 'text',
    inputType: 'inputPublicFile',
    custom_gerarPrefix: i => {
      return 'people/' + i.id + '/picture/';
    },
    'custom:selfPersonUpdatable': true
  }, {
    name: 'crmId',
    label: 'Id no CRM',
    type: 'text',
    updatable: false,
    insertable: false
  }, {
    name: 'crmStatus',
    label: 'Informação sobre integração CRM',
    type: 'text',
    updatable: false,
    insertable: false
  }, {
    name: 'loyaltyLevel',
    label: 'Nível de Fidelidade',
    type: 'text'
  }, {
    name: 'loyaltyOptIn',
    label: 'Opt-In no Programa de Fidelidade',
    type: 'boolean'
  }],
  createParams: [{
    name: 'createAccount_create',
    label: 'Conta niara',
    type: 'boolean'
  }, {
    name: 'createAccount_username',
    label: 'Username',
    type: 'text',
    minLength: 8,
    maxLength: 20,
    pattern: '^(?=[a-z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$'
  }, {
    name: 'createAccount_initialPassword',
    hint: 'Senha inicial (vazio para criar novo)',
    type: 'text',
    inputType: 'password'
  }, {
    name: 'createAccount_sendMail',
    label: 'Enviar e-mail de cadastro',
    type: 'boolean'
  }],
  searchParams: [{
    name: 'query',
    type: 'text'
  }, {
    name: 'autocomplete',
    type: 'text'
  }, {
    name: 'opensearch',
    type: 'boolean'
  }, {
    name: 'pageSize',
    type: 'int'
  }],
  filter: [{
    field: 'firstName',
    op: 'contains'
  }, {
    field: 'lastName',
    op: 'contains'
  }, {
    field: 'email',
    op: 'eq'
  }, {
    field: 'email',
    op: 'contains'
  }, {
    field: 'clientId',
    op: 'eq'
  }, {
    field: 'clientId',
    op: 'in'
  }, {
    field: 'niaraAccountStatus',
    op: 'ge'
  }, {
    field: 'cpf',
    op: 'beginsWith'
  }, {
    field: 'enabled',
    op: 'eq'
  }, {
    field: 'phone',
    op: 'contains'
  }, {
    field: 'passport',
    op: 'beginsWith'
  }]
});