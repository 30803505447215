import { darken } from 'polished'
import styled from 'styled-components'

const darkenValue = 0.2

interface ButtonColor {
  color: string
  textColorButton: string
  block?: boolean
}

export type RoomAndPeoplesProps = {
  isCoreReservation?: boolean
  themeType?: 'admin' | 'ota'
}

export const Container = styled.div<{ block?: boolean }>`
  &&& {
    p,
    h6 {
      margin-bottom: 0;
      margin-top: 0;
    }
    width: 100%;
  }
`

export const Peoples = styled.div<RoomAndPeoplesProps>`
  &&& {
    height: ${({ themeType }) => (themeType === 'admin' ? '32px' : 'auto')} !important;
    position: relative;
    width: 100%;
  }
`

export const BtnPosition = styled.div`
  &&& {
    display: flex;
    justify-content: flex-end;
    margin-top: 14px;
    min-width: 85px;
  }
`

export const Content = styled.div`
  &&& {
    background-color: ${({ theme }) => theme?.colors?.white};
    color: ${({ theme }) => theme.colors.text} !important;
    box-shadow: 0px 0px 25px #00000026;
    border-radius: 4px;
    padding: 20px;
    position: absolute;
    width: 330px;
    z-index: 99999;
  }
`
export const Button = styled.button<ButtonColor>`
  &&& {
    background: unset !important;
    background-color: ${({ color }) => color} !important;
    border: 0 !important;
    border-radius: 4px !important;
    color: ${({ textColorButton }) => textColorButton} !important;
    font-family: 'Manrope', sans-serif;
    font-size: 16px !important;
    font-weight: 600 !important;
    height: 52px !important;
    line-height: ${({ block }) => (block ? '4px' : '22px')} !important;
    padding: ${({ block }) => (block ? '12px' : '8px')} !important;
    text-align: center !important;
    width: 100% !important;
    small {
      color: ${({ textColorButton }) => textColorButton} !important;
      display: block !important;
      font-size: 11px !important;
      font-weight: 300 !important;
      line-height: 1.5em !important;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: ${({ color }) =>
        color ? darken(darkenValue, color) : darken(darkenValue, `${({ theme }) => theme?.colors?.black}`)} !important;
      transition: 0.25s ease-in-out !important;
    }
  }
`

export const Btn = styled.button<RoomAndPeoplesProps>`
  &&& {
    align-items: center !important;
    background: transparent !important;
    border: 1px solid ${({ theme }) => theme?.colors?.gray4};
    border-radius: ${({ isCoreReservation }) => (isCoreReservation ? '2px' : '3px')} !important;
    color: ${({ theme }) => theme.colors?.text} !important;
    display: flex !important;
    font-family: 'Manrope', sans-serif;
    font-size: 14px !important;
    font-weight: 400 !important;
    height: ${({ themeType }) => (themeType === 'admin' ? '32px' : '')} !important;
    max-width: 100% !important;
    padding: 0 !important;
    text-overflow: ellipsis !important;
    transition: ease-in-out 0.2s !important;
    white-space: nowrap !important;
    width: 100% !important;

    &.hasError {
      border-color: ${({ theme }) => theme?.colors?.error} !important;
    }

    &:active,
    &:focus-visible,
    &:hover,
    &:focus {
      border: 1px solid ${({ theme }) => theme?.colors?.text}!important;
      outline: none;
    }
  }
`

export const RoomAndPeoples = styled.div<RoomAndPeoplesProps>`
  &&& {
    align-items: center;
    background-color: ${({ theme }) => theme?.colors?.white};
    border-radius: ${({ isCoreReservation }) => (isCoreReservation ? '2px' : '3px')} !important;
    display: flex;
    font-size: ${({ themeType }) => (themeType === 'admin' ? 11 : 14)}px;
    height: ${({ themeType, isCoreReservation }) =>
      themeType === 'admin' ? '' : isCoreReservation ? '46px' : '40px'} !important;
    overflow: hidden !important;
    padding: 0 8px !important;
    text-align: center !important;
    width: 100% !important;

    &.hasError {
      border-color: ${({ theme }) => theme?.colors?.error} !important;
    }

    &:active,
    &:focus-visible,
    &:focus {
      border: none;
      outline: none;
    }
  }
`
export const Img = styled.img<RoomAndPeoplesProps>`
  &&& {
    width: ${({ themeType }) => (themeType === 'admin' ? '16' : '24')}px !important;
    height: ${({ themeType }) => (themeType === 'admin' ? '16' : '24')}px !important;
    margin-right: 5px !important;
  }
`
