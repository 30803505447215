import { useEffect, useRef, useCallback } from 'react';

/**
 * Usage
 *
 * @example
 * class MyComponent extends React.Component {
 *   constructor(props) {
 *     super(props)
 *     this.addPromise = _addPromise.bind(this)
 *     this.cleanup = _cleanup.bind(this)
 *   }
 *   componentWillUnmount() {
 *     this.cleanup(this)
 *   }
 *   componentDidMount() {
 *     this.addPromise(doSomething())
 *       .then(r => this.setState({done: r}))
 *       .catch(err => { this.setState({ error: true }) })
 *   }
 * }
 *
 * @param promise
 */
export const _addPromise = function <T>(promise: Promise<T> | T): Promise<T> {
  const cleanup = this._cleanupFuncs = this._cleanupFuncs || [];
  let hasCanceled_ = false;
  const size = cleanup.push(() => {
    hasCanceled_ = true;
  });
  const wrappedPromise = new Promise<T>(async (resolve, reject) => {
    try {
      const val = await promise;
      if (!hasCanceled_) resolve(val);
    } catch (error) {
      if (!hasCanceled_) reject(error);
    }
  });
  wrappedPromise.catch(() => {}).then(() => {
    delete cleanup[size - 1];
  });
  return wrappedPromise;
};

/**
 * @example
 * class MyComponent extends React.Component {
 *   constructor(props) {
 *     super(props)
 *     this.addPromise = _addPromise.bind(this)
 *     this.cleanup = _cleanup.bind(this)
 *   }
 *   componentWillUnmount() {
 *     this.cleanup(this)
 *   }
 *   componentDidMount() {
 *     this.addPromise(doSomething())
 *       .then(r => this.setState({done: r}))
 *       .catch(err => { this.setState({ error: true }) })
 *   }
 * }
 */
export const _cleanup = function (): void {
  this?._cleanupFuncs?.filter(x => x).forEach(f => f?.());
};

/**
 * @example
 * const MyFunctionComponent = props => {
 *   const addPromise = useAddPromise()
 *   const onClick = (e) => addPromise(doSomething()).then(r => {})
 *   return null
 * }
 */
export const useAddPromise = function (): {
  <T>(promise: Promise<T> | T): Promise<T>;
} {
  const didUnmountRef = useRef<boolean>();
  useEffect(() => {
    didUnmountRef.current = false;
    return () => {
      didUnmountRef.current = true;
    };
  }, []);
  const addPromise = useCallback(function <T>(promise: Promise<T> | T): Promise<T> {
    return new Promise<T>(async (resolve, reject) => {
      try {
        const val = await promise;
        if (!didUnmountRef.current) resolve(val);
      } catch (error) {
        if (!didUnmountRef.current) reject(error);
      }
    });
  }, []);
  return addPromise;
};