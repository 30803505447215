import React, { useState, useEffect } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useTheme } from '../..';
import ThemeProvider from '../../theme/ThemeProvider';
import { IcGroupBlack } from '../../Icons';
import * as S from './styles';
export type CounterPeopleProps = React.PropsWithChildren<{
  qtdeRooms?: number;
  guests: number;
  block?: boolean;
  isOpen?: (isOpen: boolean) => void;
  errors?: string[];
  isCoreReservation?: boolean;
  dataTestId?: string;
  themeType?: 'admin' | 'ota';
}>;
const CounterPeoples: React.FC<CounterPeopleProps> = ({
  block,
  children,
  guests,
  qtdeRooms,
  isOpen,
  errors,
  isCoreReservation,
  dataTestId,
  themeType
}) => {
  const [openCount, setOpenCount] = useState(false);
  const theme = useTheme();

  /*Usando o disable-next-line, pois a prop isOpen pode nao existir e a ação depende exclusivamente do openCount*/
  useEffect(() => {
    if (isOpen) {
      isOpen(openCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCount]);
  return <ThemeProvider themeType={themeType}>
      <S.Container block={block} data-testid={dataTestId}>
        <S.Peoples themeType={themeType}>
          <S.Btn isCoreReservation={isCoreReservation} type="button" themeType={themeType} onClick={() => setOpenCount(!openCount)} className={errors?.length > 0 ? 'hasError' : undefined} aria-errormessage={errors?.length > 0 ? errors.join(', ') : undefined}>
            <S.RoomAndPeoples themeType={themeType} isCoreReservation={isCoreReservation}>
              <S.Img themeType={themeType} src={IcGroupBlack} alt="person" />
              {qtdeRooms > 1 ? `${qtdeRooms} Quartos, ` : ``}
              {guests > 1 ? ` ${guests} Hóspedes` : ` ${guests} Hóspede`}
            </S.RoomAndPeoples>
          </S.Btn>

          {openCount && <ClickAwayListener onClickAway={() => setOpenCount(false)}>
              <S.Content>
                {children}

                <S.Button color={theme?.colors?.primary} textColorButton={theme?.colors?.primaryReadable} onClick={() => setOpenCount(!openCount)} type="button" block={block}>
                  Aplicar
                  <small>
                    {qtdeRooms > 1 ? <>{qtdeRooms} quartos </> : <>{qtdeRooms} quarto</>},{' '}
                    {guests > 1 ? <>{guests} hóspedes </> : <> {guests} hóspede </>}
                  </small>
                </S.Button>
              </S.Content>
            </ClickAwayListener>}
        </S.Peoples>
      </S.Container>
    </ThemeProvider>;
};
export default CounterPeoples;