import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import client from '../client';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Cliente',
  labelPlural: 'Clientes',
  name: 'CurrentClient',
  insertable: false,
  updatable: false,
  deletable: false,
  searchable: false,
  authorization: authorization('clientConsultant', false),
  dynamoDB: {
    tableName: 'SpearClient'
  },
  fields: [...client.fields].filter(f => !f['custom:excludeForCurrentClient'] && (f.name as string) !== 'audit').map(f => ({
    ...f,
    id: false
  }))
});