import React from 'react';
import fillCrudMetadataGaps, { PartialField } from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import { currencies } from '../currencyExchangeRule';
import { niaraPaymentOptions } from '../supplierPaymentRule';
import type { Tenant } from '../../types';
const BASIC_OPTIONS = (['INT_ON_CC', 'INT_ON_PIX', 'EXT_FAT'] as const);
type PAYMENT_TYPE = (typeof niaraPaymentOptions)[number]['value'];
const allPaymentOptions = niaraPaymentOptions.filter(option => !['INT_LATER', 'EXT_LATER'].includes(option.value));
const isInternal = option => option.value.startsWith('INT');
const isAdvanced = option => !BASIC_OPTIONS.includes(option.value);
const createDescriptionFields = (value: PAYMENT_TYPE, group: string, renderForm: (i: any) => boolean): Array<PartialField> => {
  return [{
    name: value + '_name',
    label: 'Título',
    required: true,
    hint: 'Esse texto vai aparecer no momento da reserva como opção de recebimento.',
    custom_groupName: group,
    renderForm: renderForm,
    custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme
  }, {
    name: value + '_description',
    label: 'Instruções/ descrição',
    required: true,
    hint: 'Esse texto vai aparecer no momento da reserva se essa forma de recebimento for selecionada. Também é exibido na tela de sucesso e minhas reservas.',
    custom_groupName: group,
    renderForm: renderForm,
    custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme
  }];
};
const createEnableField = (value: PAYMENT_TYPE, label: string, hint: string, renderForm?: (i: any) => boolean): PartialField => ({
  name: `${value}_enabled`,
  label: label,
  required: true,
  type: 'boolean',
  hint: hint,
  custom_groupName: label,
  renderForm: renderForm,
  custom_checkTenant: t => t && (t.modules_engineRuleOptIn_deleteme || value.startsWith('INT_'))
});
const createCurrecyField = (value: PAYMENT_TYPE, label: string, renderForm?: (i: any) => boolean): PartialField => ({
  name: `${value}_paymentCurrency`,
  label: 'Moeda de recebimento',
  inputType: 'select',
  options: currencies.filter(option => value !== 'INT_ON_PIX' || option.value === 'BRL'),
  required: false,
  default: null,
  withNullSelector: true,
  nullSelectorLabel: 'Manter moeda da reserva',
  custom_groupName: label,
  renderForm: renderForm
});
const createUseSupplierDescriptionsField = (value: PAYMENT_TYPE, label: string, renderForm?: (i: any) => boolean): PartialField => ({
  name: `${value}_useSupplierDescriptions`,
  label: 'Utilizar as descrições do fornecedor',
  required: true,
  type: 'boolean',
  custom_groupName: label,
  default: true,
  renderForm: renderForm,
  custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme
});
const createCreditCardFields = (value: PAYMENT_TYPE, label: string, includeAdditionalFields = true): Array<PartialField> => {
  const additionalFields: Array<PartialField> = [{
    custom_groupName: label,
    name: `${value}_smartInstallment`,
    label: 'Parcelamento inteligente',
    required: true,
    default: 0,
    type: 'boolean',
    renderForm: i => i[`${value}_enabled`] == true,
    hint: 'Define automaticamente o número de parcelas entre a data do recebimento e a data do serviço',
    custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme
  }, {
    custom_groupName: label,
    name: `${value}_softDescriptor`,
    label: 'Soft Descriptor',
    type: 'text',
    renderForm: i => i.INT_ON_CC_enabled == true,
    custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme
  }];
  const minSupplierCommissionField: Array<PartialField> = [{
    custom_groupName: label,
    name: `${value}_minSupplierCommission`,
    label: 'Valor mínimo da comissão do fornecedor',
    type: 'float',
    inputType: 'percentage',
    renderForm: i => i[`${value}_enabled`] == true,
    custom_checkTenant: (t: Tenant) => t && !t.modules_crs
  }];
  const conditions: Array<PartialField> = [{
    custom_groupName: label,
    name: `${value}_conditions`,
    label: 'Condições',
    type: 'objectarray',
    custom_renderFieldsRecursively: true,
    fields: [{
      name: 'cardBrands',
      label: 'Bandeiras',
      required: true,
      type: 'textarray',
      options: [{
        label: 'Amex',
        value: 'AMEX'
      }, {
        label: 'Aura',
        value: 'AURA'
      }, {
        label: 'Diners',
        value: 'DINERS'
      }, {
        label: 'Elo',
        value: 'ELO'
      }, {
        label: 'Hiper',
        value: 'HIPER'
      }, {
        label: 'Hipercard',
        value: 'HIPERCARD'
      }, {
        label: 'JCB',
        value: 'JCB'
      }, {
        label: 'MasterCard',
        value: 'MASTERCARD'
      }, {
        label: 'Visa',
        value: 'VISA'
      }, {
        label: 'Discover',
        value: 'DISCOVER'
      }],
      render: e => {
        const entity: {
          cardBrands?: {
            label?: string;
            brands?: string[];
          };
        } = {};
        Object.keys(e)?.forEach(key => {
          if (key === 'cardBrands') {
            entity.cardBrands = {
              label: 'Cartões',
              brands: e[key] ?? []
            };
          }
        });
        return <div>
                <p>
                  {entity?.cardBrands?.label}: {entity?.cardBrands?.brands?.map((b: string) => b)?.join(', ')}
                </p>
              </div>;
      }
    }, {
      name: 'cardMaxInst',
      label: 'Número máximo de parcelas',
      required: true,
      default: 1,
      type: 'int'
    }, {
      name: 'cardMinInstValue',
      label: 'Valor mínimo da parcela',
      required: true,
      type: 'float'
    }],
    renderForm: i => i[`${value}_enabled`] == true,
    custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme
  }];
  return [...(value == 'EXT_DIR' ? minSupplierCommissionField : []), ...conditions, ...(includeAdditionalFields ? additionalFields : [])];
};

/**
 * @deprecated
 * @param value
 * @param label
 * @returns
 */
const createFatExtraFields = (value: PAYMENT_TYPE, label: string): Array<PartialField> => [{
  custom_groupName: label,
  name: `${value}_extras`,
  label: 'Extras',
  type: 'textarray',
  options: [{
    label: 'Apenas Diárias',
    value: 'NO_EXTRA',
    hint: 'Faturar apenas diárias.'
  }, {
    label: 'Incluir Extras',
    value: 'EXTRA',
    hint: 'Faturar com extras.'
  }, {
    label: 'Incluir Extras (exceto álcool)',
    value: 'EXTRA_ALCOHOL',
    hint: 'Faturar com extras (exceto álcool).'
  }, {
    label: 'Incluir Extras (exceto álcool e cigarros)',
    value: 'EXTRA_ALCOHOL_CIGAR',
    hint: 'Faturar com extrar (exceto álcool e cigarros).'
  }, {
    label: 'Não incluir Taxa de Turismo',
    value: 'NO_TURISM_TAX',
    hint: 'Faturar sem a taxa de turismo.'
  }, {
    label: 'Incluir apenas Lavanderia',
    value: 'LAUNDRY',
    hint: 'Faturar e adicionar apenas lavanderia.'
  }],
  custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme,
  render: false,
  renderForm: false,
  deprecated: true
}];
const createMarkupDiscountFields = (value: PAYMENT_TYPE, custom_groupName: string): Array<PartialField> => [{
  custom_groupName,
  name: `${value}_markupDiscount_enabled`,
  label: 'Habilitar desconto',
  type: 'boolean',
  renderForm: i => i[`${value}_enabled`] == true,
  custom_checkTenant: (t: Tenant) => t && t.modules_engineRuleOptIn_deleteme && !t.modules_crs
}, {
  custom_groupName,
  name: `${value}_markupDiscount_type`,
  label: 'Tipo do desconto',
  options: [{
    value: 'TOTAL_RESERVATION_PERCENTAGE',
    label: 'Percentual do valor da reserva'
  }],
  conditionalRequired: i => i[`${value}_markupDiscount_enabled`],
  renderForm: i => i[`${value}_enabled`] == true && i[`${value}_markupDiscount_enabled`],
  custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme && !t.modules_crs
}, {
  custom_groupName,
  name: `${value}_markupDiscount_valuePercentage`,
  label: 'Porcentagem do total de reserva',
  type: 'float',
  inputType: 'percentage',
  conditionalRequired: i => i[`${value}_markupDiscount_enabled`] && i[`${value}_markupDiscount_type`] === 'TOTAL_RESERVATION_PERCENTAGE',
  renderForm: i => i[`${value}_enabled`] == true && i[`${value}_markupDiscount_enabled`] && i[`${value}_markupDiscount_type`] === 'TOTAL_RESERVATION_PERCENTAGE',
  custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme && !t.modules_crs
}, {
  custom_groupName,
  name: `${value}_markupDiscount_limitType`,
  label: 'Limite de valor do desconto',
  options: [{
    value: 'NO_LIMIT',
    label: 'Sem limite de desconto'
  }, {
    value: 'MARKUP',
    label: 'Limitar até o valor do markup'
  }, {
    value: 'CLIENT_COMMISSION',
    label: 'Limitar até o valor da comissão do cliente'
  }],
  conditionalRequired: i => i[`${value}_markupDiscount_enabled`] && i[`${value}_markupDiscount_type`] === 'TOTAL_RESERVATION_PERCENTAGE',
  renderForm: i => i[`${value}_enabled`] == true && i[`${value}_markupDiscount_enabled`] && i[`${value}_markupDiscount_type`] === 'TOTAL_RESERVATION_PERCENTAGE',
  custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme && !t.modules_crs
}, {
  custom_groupName,
  name: `${value}_markupDiscount_limitBehavior`,
  label: 'Tratamento de desconto acima do limite',
  required: true,
  options: [{
    value: 'DONT_ACCEPT',
    label: 'Não aceitar o cupom'
  }, {
    value: 'UP_TO_LIMIT',
    label: 'Aplicar o desconto até o limite permitido'
  }],
  conditionalRequired: i => i[`${value}_markupDiscount_enabled`] && i[`${value}_markupDiscount_type`] === 'TOTAL_RESERVATION_PERCENTAGE',
  renderForm: i => i[`${value}_enabled`] == true && i[`${value}_markupDiscount_enabled`] && i[`${value}_markupDiscount_type`] === 'TOTAL_RESERVATION_PERCENTAGE',
  custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme && !t.modules_crs
}];
const allPaymentOptionsField: Array<PartialField> = allPaymentOptions.map(option => [createEnableField(option.value, option.label, option.hint, i => !isAdvanced(option) || i.advancedConfigEnabled), createCurrecyField(option.value, option.label, i => isInternal(option) && i[`${option.value}_enabled`])].concat(!isInternal(option) ? createUseSupplierDescriptionsField(option.value, option.label, i => i[`${option.value}_enabled`]) : null).concat(createDescriptionFields(option.value, option.label, i => i[`${option.value}_enabled`] && (isInternal(option) || !i[`${option.value}_useSupplierDescriptions`]))).concat(['INT_ON_CC', 'EXT_CC', 'EXT_DIR'].includes(option.value) ? createCreditCardFields(option.value, option.label, option.value == 'INT_ON_CC') : null).concat(['INT_OFF_FAT'].includes(option.value) ? createFatExtraFields(option.value, option.label) : null).concat(['INT_ON_PIX', 'INT_OFF_FAT', 'INT_OFF_OFF'].includes(option.value) ? createMarkupDiscountFields(option.value, option.label) : null).filter(Boolean)).flat(1);
export default fillCrudMetadataGaps({
  label: 'Formas de Recebimento',
  labelPlural: 'Formas de Recebimento',
  name: 'BuyerPaymentRule',
  namePlural: 'BuyerPaymentRules',
  custom_withAudit: true,
  authorization: authorization('consultant', 'admin'),
  deletable: false,
  dynamoDB: {
    tableName: 'SpearBuyerPaymentRule'
  },
  columns: ['id', 'name', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Nome da Regra',
    required: true
  }, {
    name: 'enabled',
    label: 'Ativo',
    required: true,
    type: 'boolean'
  }, {
    name: 'partialPaymentEnabled',
    label: 'Recebimento Parcial',
    required: true,
    type: 'boolean',
    hint: 'Permite definir o valor a ser cobrado no momento da geração do link de pagamento e cotação'
  }, {
    name: 'multiplePaymentsEnabled',
    label: 'Múltiplas formas de pagamento',
    required: true,
    type: 'boolean',
    hint: 'Permite realizar a reserva utilizando duas formas de pagamento'
  }, {
    name: 'multiplePayments_minPaymentValue',
    label: 'Valor mínimo de cada pagamento',
    required: true,
    type: 'float',
    default: 0,
    renderForm: entity => entity && entity.multiplePaymentsEnabled,
    conditionalRequired: entity => entity && entity.multiplePaymentsEnabled
  }, {
    name: 'advancedConfigEnabled',
    label: 'Configurações Avançadas',
    required: true,
    type: 'boolean',
    custom_checkTenant: t => t,
    // Se desativar as Configurações Avançadas, garantir que os tipo de pagamentos avançados sejam desativados
    onEntityChange: function (oldVersion, newVersion) {
      if (newVersion?.advancedConfigEnabled === oldVersion?.advancedConfigEnabled || newVersion?.advancedConfigEnabled === true) return newVersion;else {
        const advFields = allPaymentOptions.filter(o => isAdvanced(o)).reduce((acc, cur) => ({
          ...acc,
          [`${cur.value}_enabled`]: false
        }), {});
        return {
          ...newVersion,
          ...advFields
        };
      }
    }
  }, ...allPaymentOptionsField],
  filter: []
});