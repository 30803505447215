import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'ContentDev',
  labelPlural: 'ContentDev',
  name: 'ContentDev',
  namePlural: 'ContentDevs',
  authorization: authorization('master', 'superuser'),
  custom_withAudit: true,
  deletable: false,
  insertable: false,
  searchable: false,
  dynamoDB: {
    tableName: 'SpearContentDev',
    partitionKey: 'id',
    sortKey: null
  },
  columns: ['id', 'name'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'address',
    label: 'Endereço',
    updatable: false,
    readOnly: true
  }, {
    name: 'broken_city_id',
    label: 'id da cidade quebrada',
    updatable: false,
    readOnly: true
  }, {
    name: 'city',
    label: 'Cidade',
    updatable: false,
    readOnly: true
  }, {
    label: 'Coordenadas',
    name: 'coordinates',
    type: 'object',
    updatable: false,
    fields: [{
      label: 'Latitude',
      name: 'lat',
      updatable: false,
      readOnly: true,
      type: 'float'
    }, {
      label: 'Longitude',
      name: 'lon',
      updatable: false,
      readOnly: true,
      type: 'float'
    }]
  }, {
    name: 'country',
    label: 'País',
    updatable: false,
    readOnly: true
  }, {
    name: 'countryCode',
    label: 'Código do país',
    updatable: false,
    readOnly: true
  }, {
    name: 'name',
    label: 'Nome do Hotel',
    updatable: false,
    readOnly: true
  }, {
    name: 'omniCityId',
    label: 'omniCityId',
    updatable: false,
    readOnly: true
  }, {
    name: 'omniId',
    label: 'omniCityId',
    readOnly: true,
    updatable: false
  }, {
    name: 'rating',
    label: 'Estrelas',
    updatable: false,
    readOnly: true
  }, {
    label: 'Regiões',
    name: 'regions',
    type: 'objectarray',
    updatable: false,
    readOnly: true,
    fields: [{
      name: 'type',
      label: 'text'
    }, {
      name: 'id',
      label: 'Id'
    }]
  }, {
    name: 'search',
    label: 'Pesquisar',
    updatable: false,
    readOnly: true
  }, {
    name: 'state',
    label: 'Estado',
    readOnly: true,
    updatable: false
  }, {
    name: 'suppliers',
    label: 'Fornecedores',
    type: 'textarray',
    updatable: false,
    readOnly: true
  }, {
    name: 'type',
    label: 'Tipo',
    updatable: false,
    readOnly: true
  }, {
    name: 'zip',
    label: 'Cep',
    updatable: false,
    readOnly: true
  }, {
    name: 'searchable',
    label: 'searchable',
    type: 'boolean',
    updatable: true
  }],
  filter: []
});