import React from 'react';
import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import { printPrice } from '@niaratech/niara-js-commons';
export default fillCrudMetadataGaps({
  name: 'PaymentRequest',
  label: 'Solicitação de pagamento',
  labelPlural: 'Solicitações de pagamento',
  insertable: true,
  deletable: false,
  authorization: authorization('admin'),
  dynamoDB: {
    tableName: 'SpearPaymentRequest',
    indexName: 'tenantId-createdAt-index',
    scanIndexBackwards: true
  },
  appsync: {
    useNiaraIdGenerator: true
  },
  columns: ['id', 'description', 'createdByName', 'paymentStatus', 'paymentDate', 'totalPrice', 'currencyCode', 'enabled', 'createdAt'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    id: true,
    updatable: false,
    insertable: false,
    renderForm: false,
    renderColumn: false
  }, {
    label: 'Descrição',
    name: 'description',
    type: 'text',
    required: true,
    hint: 'Para uso interno'
  }, {
    label: 'Nome do produto',
    name: 'productName',
    inputType: 'text',
    required: true
  }, {
    label: 'Descrição do produto',
    name: 'productDescription',
    required: true,
    inputType: 'textarea'
  }, {
    label: 'Termos e condições',
    name: 'productConditions',
    required: false,
    type: 'textarray',
    inputType: 'textarea',
    renderForm: i => i?.productConditions?.map((text, i) => text && <p key={i}>{text}</p>)
  }, {
    label: 'Cliente',
    name: 'clientId',
    required: false,
    updatable: false,
    type: 'text',
    hint: 'Campo opcional. Não é possível alterar posteriormente este valor.',
    render: i => i.clientName || i.clientId,
    renderForm: false
  }, {
    label: 'Cliente',
    name: 'clientName',
    required: false,
    updatable: false,
    type: 'text',
    hint: 'Não é possível alterar posteriormente este valor.',
    renderForm: false
  }, {
    label: 'Credencial',
    name: 'credentialId',
    required: false,
    updatable: true,
    type: 'text',
    credentialType: 'BRPG',
    renderForm: false
    // render: (i) => i.credentialName || i.credentialId,
  }, {
    label: 'Credencial',
    name: 'credentialName',
    required: false,
    updatable: true,
    type: 'text',
    renderForm: false
  }, {
    label: 'Adquirente',
    name: 'provider',
    type: 'text',
    options: [{
      value: 'CIELO',
      label: 'Cielo 3.0'
    }, {
      value: 'CIELO15',
      label: 'Cielo 1.5'
    }, {
      value: 'REDE',
      label: 'Rede'
    }, {
      value: 'GETNET',
      label: 'GetNet'
    }, {
      value: 'GLOBALPAY',
      label: 'Global Payments'
    }, {
      value: 'STONE',
      label: 'Stone'
    }, {
      value: 'SAFRA',
      label: 'Safra'
    }, {
      value: 'FIRSTDATA',
      label: 'First Data'
    }, {
      value: 'TEST',
      label: 'Simulado'
    }],
    required: false,
    renderForm: false,
    inputType: 'select'
  }, {
    label: 'Número de parcelas',
    name: 'installments',
    type: 'int',
    default: 1,
    required: true
  }, {
    name: 'totalPrice',
    label: 'Valor',
    type: 'float',
    updatable: true,
    required: true,
    render: i => i.totalPrice ? printPrice(i.totalPrice, i.currencyCode) : '-'
  }, {
    name: 'currencyCode',
    label: 'Moeda de recebimento',
    type: 'text',
    default: 'BRL',
    options: [{
      label: 'Real brasileiro (BRL) [Cielo]',
      value: 'BRL'
    }, {
      label: 'Dólar americano (USD) [EBANX]',
      value: 'USD'
    }, {
      label: 'Peso argentino  (ARS) [EBANX]',
      value: 'ARS'
    }, {
      label: 'Peso chileno    (CLP) [EBANX]',
      value: 'CLP'
    }, {
      label: 'Peso colombiano (COP) [EBANX]',
      value: 'COP'
    }, {
      label: 'Peso mexicano   (MXN) [EBANX]',
      value: 'MXN'
    }],
    updatable: false,
    renderColumn: false,
    required: true,
    inputType: 'select'
  }, {
    name: 'targetCurrencyCode',
    label: 'Moeda de cobrança (local)',
    type: 'text',
    default: null,
    options: [{
      label: 'Peso argentino  (ARS) [EBANX]',
      value: 'ARS'
    }, {
      label: 'Peso chileno    (CLP) [EBANX]',
      value: 'CLP'
    }, {
      label: 'Peso colombiano (COP) [EBANX]',
      value: 'COP'
    }, {
      label: 'Peso mexicano   (MXN) [EBANX]',
      value: 'MXN'
    }],
    updatable: false,
    renderColumn: false,
    inputType: 'select',
    renderForm: function (i) {
      return i.currencyCode === 'USD' ? this.render(i) : false;
    }
  }, {
    name: 'targetCurrencyAmount',
    label: 'Valor em moeda local ',
    type: 'float',
    insertable: false,
    updatable: false,
    renderForm: function (i) {
      return i.targetCurrencyCode ? this.render(i) : false;
    },
    render: i => i.targetCurrencyCode && i.targetCurrencyAmount ? printPrice(i.targetCurrencyAmount, i.targetCurrencyCode) : ''
  }, {
    name: 'targetCurrencyTaxes',
    label: 'Impostos',
    type: 'float',
    insertable: false,
    updatable: false,
    renderForm: function (i) {
      return i.targetCurrencyCode ? this.render(i) : false;
    },
    render: i => i.targetCurrencyCode && i.targetCurrencyTaxes ? printPrice(i.targetCurrencyTaxes, i.targetCurrencyCode) : ''
  }, {
    name: 'currencyRate',
    label: 'Taxa de câmbio',
    type: 'float',
    insertable: false,
    updatable: false,
    renderForm: function (i) {
      return i.targetCurrencyCode ? this.render(i) : false;
    },
    render: i => i.targetCurrencyCode && i.currencyRate
  },
  // {
  //     name: 'validityDate',
  //     label: 'Data de validade',
  //     type: 'datetime',
  //     updatable: true,
  //     render: false
  // },
  {
    name: 'paymentStatus',
    label: 'Situação do pagamento',
    type: 'text',
    options: [{
      value: 'PAID',
      label: 'Pago'
    }, {
      value: 'ERROR',
      label: 'Erro no pagamento'
    }],
    insertable: false,
    updatable: false,
    renderStyle: (i, fieldName = 'paymentStatus') => {
      if (i[fieldName]) {
        switch (i[fieldName]) {
          case 'PAID':
            return 'success';
        }
      }
      return 'danger';
    }
  }, {
    name: 'paymentStatusDate',
    label: 'Data da situação do pagamento',
    type: 'datetime',
    insertable: false,
    updatable: false
  }, {
    name: 'paymentDate',
    label: 'Data do pagamento',
    type: 'datetime',
    insertable: false,
    updatable: false
  }, {
    name: 'createdByName',
    label: 'Criado por',
    updatable: false,
    insertable: false
  }, {
    name: 'createdAt',
    label: 'Criado em',
    updatable: false,
    type: 'datetime',
    insertable: false
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: true,
    type: 'boolean'
  }]
});