import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import { CRM_TYPES } from '../credential';
export default fillCrudMetadataGaps({
  label: 'Regra de notificação',
  labelPlural: 'Regras de notificações',
  name: 'NotificationRule',
  namePlural: 'NotificationRules',
  custom_withAudit: true,
  authorization: authorization('consultant', 'admin'),
  deletable: false,
  dynamoDB: {
    tableName: 'SpearNotificationRule'
  },
  columns: ['id', 'name', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Nome da Regra',
    type: 'text',
    required: true
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: false,
    required: true,
    type: 'boolean'
  }, {
    custom_groupName: "Webhook Niara",
    name: 'lostReservationNotificationEnabled',
    label: 'Notificação de Reservas perdidas',
    default: false,
    required: true,
    type: 'boolean'
  }, {
    custom_groupName: "Webhook Niara",
    name: 'reservationNotificationEnabled',
    label: 'Notificação de Reservas',
    default: true,
    required: true,
    type: 'boolean'
  }, {
    custom_groupName: "Webhook Niara",
    name: 'configurations',
    label: 'Endpoints para notificação',
    type: 'objectarray',
    graphQLType: '[NestedClientNotificationEndpoint]',
    graphQLInputType: '[NestedClientNotificationEndpointInput]',
    insertable: true,
    updatable: true,
    fields: [{
      name: 'endpoint',
      label: 'Endpoint',
      required: true,
      type: 'text'
    }, {
      name: 'headers',
      label: 'Headers',
      type: 'objectarray',
      graphQLType: '[NestedClientNotificationEndpointHeader]',
      graphQLInputType: '[NestedClientNotificationEndpointHeaderInput]',
      fields: [{
        name: 'key',
        label: 'Chave',
        required: true,
        type: 'text'
      }, {
        name: 'value',
        label: 'Valor',
        required: true,
        type: 'text'
      }]
    }],
    hint: 'Serão notificados eventos de criação e alteração de viagens.'
  }, {
    custom_groupName: "CRM",
    name: 'crmCredentialId',
    label: 'Credencial de CRM',
    inputType: 'credential',
    credentialTypeList: CRM_TYPES
  }, {
    custom_groupName: "PMS",
    name: 'sendPaymentsToPMS',
    label: 'Enviar pagamentos para PMS',
    default: false,
    type: 'boolean',
    custom_checkTenant: t => t?.sendPaymentsToPMS
  }, {
    custom_groupName: "PMS",
    name: 'timedeltaMinutesToPMS',
    label: 'Tempo de envio para PMS (minutos)',
    default: 20,
    type: 'int',
    custom_checkTenant: t => t?.sendPaymentsToPMS
  }],
  filter: [{
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq'
  }]
});