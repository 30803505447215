import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import hotelReservation from '../hotelReservation';
const SelfHotelReservation_RoomStay_Descriptions_Description = {
  graphQLType: '[SelfHotelReservation_RoomStay_Descriptions_Description]',
  fields: [{
    name: 'description'
  }, {
    name: 'name'
  }]
};
export default fillCrudMetadataGaps({
  label: 'Reserva de Hotel',
  labelPlural: 'Minhas reservas',
  name: 'SelfHotelReservation',
  deletable: false,
  updatable: false,
  insertable: false,
  searchable: true,
  authorization: authorization('unauthenticated', false),
  dynamoDB: {
    tableName: 'SpearHotelReservation2',
    indexName: 'tenantId-date-index',
    indexes: [{
      indexName: 'tenantId-locator-index',
      indexedProp: 'locator'
    }]
  },
  'custom:sortFields': ['date desc'],
  columnNames: ['id', 'locator', 'date', 'startDate', 'endDate', 'hotelName', 'hotelCityName', 'totalPrice', 'currencyCode', 'status', 'hiddenFields'],
  fields: [...hotelReservation.fields.filter(f => f['custom:includeSelfHotelReservation']), {
    name: 'roomStays',
    label: 'Quartos',
    type: 'objectarray',
    graphQLType: '[SelfHotelReservation_RoomStay]',
    fields: [{
      name: 'roomIdOnSource'
    }, {
      name: 'endDate',
      type: 'date'
    }, {
      name: 'startDate',
      type: 'date'
    }, {
      name: 'modificationDate',
      type: 'datetime'
    }, {
      name: 'cancelDate',
      type: 'datetime'
    }, {
      name: 'cancelled',
      type: 'boolean'
    }, {
      name: 'cancelledInPenalty',
      type: 'boolean'
    }, {
      name: 'meal',
      type: 'object',
      graphQLType: 'SelfHotelReservation_RoomStay_Meal',
      fields: [{
        name: 'name'
      }]
    }, {
      name: 'cancelPolicy',
      type: 'object',
      fields: [{
        name: 'dropDate',
        type: 'datetime'
      }, {
        name: 'inPenalty',
        type: 'boolean'
      }, {
        name: 'nonRefundable',
        type: 'boolean'
      }, {
        name: 'penaltyDate',
        type: 'datetime'
      }, {
        name: 'priceComposition',
        type: 'object',
        fields: [{
          name: 'total',
          type: 'object',
          graphQLType: 'SelfHotelReservation_RoomStay_Price_Component',
          fields: [{
            name: 'currency',
            required: true
          }, {
            name: 'value',
            type: 'float',
            required: true
          }]
        }, {
          name: 'penalty',
          type: 'object',
          graphQLType: 'SelfHotelReservation_RoomStay_Price_Component',
          fields: [{
            name: 'currency',
            required: true
          }, {
            name: 'value',
            type: 'float',
            required: true
          }]
        }]
      }]
    }, {
      name: 'roomDescription'
    }, {
      name: 'price',
      type: 'object',
      fields: [{
        name: 'total',
        type: 'object',
        graphQLType: 'SelfHotelReservation_RoomStay_Price_Component',
        fields: [{
          name: 'currency',
          required: true
        }, {
          name: 'value',
          type: 'float',
          required: true
        }]
      }, {
        name: 'fee',
        type: 'object',
        graphQLType: 'SelfHotelReservation_RoomStay_Price_Component',
        fields: [{
          name: 'currency',
          required: true
        }, {
          name: 'value',
          type: 'float',
          required: true
        }]
      }, {
        name: 'hotelFees',
        type: 'object',
        graphQLType: 'SelfHotelReservation_RoomStay_Price_HotelFees',
        fields: [{
          name: 'currency',
          required: true
        }, {
          name: 'value',
          type: 'float',
          required: true
        }, {
          name: 'fees',
          type: 'objectarray',
          graphQLType: '[SelfHotelReservation_RoomStay_Price_HotelFees_Fee]',
          fields: [{
            name: 'description'
          }, {
            name: 'value',
            type: 'float',
            required: true
          }, {
            name: 'currency',
            required: true
          }, {
            name: 'billableValue',
            type: 'float'
          }, {
            name: 'billableCurrency'
          }]
        }]
      }, {
        name: 'taxes',
        type: 'object',
        graphQLType: 'HotelReservation_RoomStay_Price_Taxes',
        fields: [{
          name: 'currency',
          required: true
        }, {
          name: 'value',
          type: 'float',
          required: true
        }]
      }, {
        name: 'nights',
        type: 'objectarray',
        graphQLType: '[HotelReservation_RoomStay_Price_Night]',
        fields: [{
          name: 'currency',
          required: true
        }, {
          name: 'value',
          type: 'float',
          required: true
        }, {
          name: 'units',
          type: 'int'
        }, {
          name: 'date',
          type: 'date'
        }]
      }, {
        name: 'discountBreakdown',
        type: 'objectarray',
        fields: [{
          name: 'currency'
        }, {
          name: 'value',
          type: 'float'
        }, {
          name: 'description'
        }, {
          name: 'code',
          label: 'Código',
          hint: 'Aplicável se for promocode ou cupom de desconto'
        }, {
          name: 'originType',
          options: [{
            value: 'COUPON'
          }, {
            value: 'PROMOCODE'
          }, {
            value: 'OFFER'
          }]
        }]
      }]
    }, {
      name: 'ratePlanName'
    }, {
      name: 'guests',
      type: 'objectarray',
      graphQLType: '[HotelReservation_RoomStay_Guest]',
      fields: [{
        name: 'firstName'
      }, {
        name: 'lastName'
      }, {
        name: 'namePrefix'
      }, {
        name: 'email'
      }, {
        name: 'phone'
      }, {
        name: 'cpf'
      }, {
        name: 'dob'
      }, {
        name: 'passport'
      }, {
        name: 'ageQualifying',
        required: false,
        options: [{
          value: 'ADULT',
          label: 'Adultos'
        }, {
          value: 'CHILD',
          label: 'Crianças'
        }]
      }, {
        name: 'age',
        required: false,
        type: 'int'
      }]
    }, {
      name: 'descriptions',
      type: 'object',
      graphQLType: 'SelfHotelReservation_RoomStay_Descriptions',
      fields: [{
        name: 'cancelPolicy',
        type: 'objectarray',
        ...SelfHotelReservation_RoomStay_Descriptions_Description
      }, {
        name: 'generalPolicies',
        type: 'objectarray',
        ...SelfHotelReservation_RoomStay_Descriptions_Description
      }, {
        name: 'meal',
        type: 'objectarray',
        ...SelfHotelReservation_RoomStay_Descriptions_Description
      }, {
        name: 'payment',
        type: 'objectarray',
        ...SelfHotelReservation_RoomStay_Descriptions_Description
      }, {
        name: 'roomType',
        type: 'objectarray',
        ...SelfHotelReservation_RoomStay_Descriptions_Description
      }]
    }, {
      name: 'occupancy',
      label: 'Ocupação',
      type: 'object',
      graphQLType: 'HotelReservation_RoomStay_Occupancy',
      custom_auditConfig: {
        show: 'ALWAYS',
        sortOrder: 8
      },
      fields: [{
        name: 'adults',
        label: 'Adultos',
        type: 'int',
        required: true
      }, {
        name: 'children',
        label: 'Crianças',
        type: 'int'
      }, {
        name: 'quantity',
        type: 'int'
      }, {
        name: 'childrenAges',
        type: 'intarray'
      }],
      render: entity => {
        const occupancy = [];
        if (entity?.occupancy?.adults) occupancy.push(`${entity?.occupancy?.adults} adulto(s)`);
        if (entity?.occupancy?.children) occupancy.push(`${entity?.occupancy?.children} criança(s)`);
        const childrenAges = entity?.occupancy?.childrenAges?.lenght > 0 ? ' (' + entity?.occupancy?.childrenAges?.join(' ano(s), ')?.concat(' ano(s))') : '';
        return occupancy?.join(', ')?.concat(childrenAges);
      }
    }, {
      name: 'ratePlanInclusions',
      label: 'Inclusões da tarifa',
      type: 'objectarray',
      graphQLType: '[HotelReservation_RoomStay_RatePlanInclusion]',
      custom_auditConfig: {
        show: 'ALWAYS',
        sortOrder: 14
      },
      fields: [{
        name: 'id',
        type: 'text'
      }, {
        name: 'name',
        type: 'text'
      }, {
        name: 'description',
        type: 'text'
      }],
      render: entity => {
        return entity?.ratePlanInclusions?.length > 0 ? entity?.ratePlanInclusions?.map(item => item?.name ?? item?.id)?.join(', ') : undefined;
      }
    }],
    render: i => JSON.stringify(i.roomStays)
  }, {
    name: 'payment',
    label: 'Pagamento',
    type: 'object',
    graphQLType: 'SelfHotelReservation_Payment',
    fields: [{
      name: 'paymentOption',
      type: 'object',
      fields: [{
        name: 'alias'
      }, {
        name: 'external',
        type: 'boolean'
      }, {
        name: 'type'
      }, {
        name: 'card',
        type: 'boolean'
      }]
    }, {
      name: 'creditCard',
      type: 'object',
      fields: [{
        name: 'cardHolderName',
        type: 'text'
      }, {
        name: 'cardTypeCode',
        type: 'text'
      }, {
        name: 'cardNumber',
        type: 'text'
      }]
    }]
  }],
  filter: [{
    name: 'status_eq',
    field: 'status',
    op: 'eq'
  }, {
    name: 'date_ge',
    field: 'date',
    op: 'ge',
    label: 'Data de Reserva Início'
  }, {
    name: 'date_le',
    field: 'date',
    op: 'le',
    label: 'Data de Reserva Fim'
  }, {
    name: 'clientId_eq',
    field: 'clientId',
    op: 'eq'
  }, {
    name: 'locator_eq',
    field: 'locator',
    op: 'eq',
    label: 'Código da reserva'
  }, {
    field: 'buyer_email',
    op: 'eq'
  }],
  searchParams: [{
    name: 'hf',
    type: 'text'
  }],
  getParams: [{
    name: 'email',
    type: 'text'
  }, {
    name: 'hf',
    type: 'text'
  }]
});