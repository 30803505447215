import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import personAssociation from '../personAssociation';
export default fillCrudMetadataGaps({
  label: 'Associação de Pessoas',
  labelPlural: 'Associação de Pessoas',
  name: 'SelfPersonAssociation',
  namePlural: 'SelfPersonAssociations',
  authorization: authorization('unauthenticated', false),
  insertable: false,
  updatable: false,
  deletable: false,
  searchable: true,
  dynamoDB: {
    tableName: 'SpearPersonAssociation',
    scanIndexBackwards: false
  },
  fields: [...personAssociation.fields]
});