import type * as NiaraCrudModel from '@niarab2c/niara-spear-crudmodel/src'
import niaraCrudmodel from '@niarab2c/niara-spear-crudmodel/src'
import type * as CrudModel from '@niarab2c/otabuilder-crudmodel/src'
import crudmodel from '@niarab2c/otabuilder-crudmodel/src'
import type { DAO, GraphQLFunction } from '@niaratech/devcase-crud/dist/GraphQLDAO'
import GraphQLDAO from '@niaratech/devcase-crud/dist/GraphQLDAO'
import callApi, { ApiName } from '../util/callApi'
import type { HotelDetails } from '../types/hotels'
import hotelDetailsDAO from './hotelDetailsDAO'
export type { DAO }
export { hotelDetailsDAO }

type DAOS = {
  clientDAO: DAO<NiaraCrudModel.Client>
  selfOrderDAO: DAO<NiaraCrudModel.SelfOrder>
  selfHotelReservationDAO: DAO<NiaraCrudModel.SelfHotelReservation>
  storefrontDAO: DAO<CrudModel.Storefront>
  storefrontUrlDAO: DAO<CrudModel.StorefrontUrl>
  hotelDetailsDAO: DAO<HotelDetails>
  uiCustomizationDAO: DAO<NiaraCrudModel.UiCustomization>
  shallowClientDAO: DAO<NiaraCrudModel.ShallowClient>
  currentTenantDAO: DAO<NiaraCrudModel.CurrentTenant>
  shallowCredentialDAO: DAO<NiaraCrudModel.ShallowCredential>
  paymentLinkDAO: DAO<NiaraCrudModel.PaymentLink>
  customDomainDAO: DAO<CrudModel.CustomDomain>
  selfFlightReservationDAO: DAO<NiaraCrudModel.SelfFlightReservation>
  currentPersonDAO: DAO<NiaraCrudModel.CurrentPerson>
  personDAO: DAO<NiaraCrudModel.Person>
  orderDAO: DAO<NiaraCrudModel.Order>
  selfLedgerTransactionDAO: DAO<NiaraCrudModel.SelfLedgerTransaction>
  quotationDAO: DAO<NiaraCrudModel.Quotation>
  manualReservationDAO: DAO<NiaraCrudModel.ManualReservation>
  userDAO: DAO<NiaraCrudModel.User>
  selfPersonAssociationDAO: DAO<NiaraCrudModel.SelfPersonAssociation>
  currentUserDAO: DAO<NiaraCrudModel.CurrentUser>
  creditCardDAO: DAO<NiaraCrudModel.CreditCard>
  paymentDAO: DAO<NiaraCrudModel.Payment>
  [k: string]: DAO
}

export const createExecuteQuery =
  (api: ApiName): GraphQLFunction<any> =>
  async (query, variables?) => {
    return await callApi(api, '/graphql', 'post', {
      body: {
        query,
        variables,
      },
    }).catch((err) => {
      throw err
    })
  }
const otabuilderExecuteQuery = createExecuteQuery('otabuilder-backend')
GraphQLDAO.defaultExecuteQuery = otabuilderExecuteQuery

const otabuilderDaos = Object.entries(crudmodel).reduce((acc, [key, crudMetadata]) => {
  acc[key + 'DAO'] = new GraphQLDAO({ crudMetadata })
  return acc
}, {}) as DAOS

const niaraSpearExecuteQuery = createExecuteQuery('niara-spear-graphql')
const niaraSpearDaos = Object.entries(niaraCrudmodel).reduce((acc, [key, crudMetadata]) => {
  acc[key + 'DAO'] = new GraphQLDAO({ crudMetadata, executeQuery: niaraSpearExecuteQuery })
  return acc
}, {}) as DAOS

const daos: DAOS = {
  ...niaraSpearDaos,
  ...otabuilderDaos,
  hotelDetailsDAO: hotelDetailsDAO as DAO<HotelDetails>,
}

export default daos
export const {
  clientDAO,
  selfOrderDAO,
  selfHotelReservationDAO,
  storefrontDAO,
  storefrontUrlDAO,
  uiCustomizationDAO,
  shallowClientDAO,
  currentTenantDAO,
  shallowCredentialDAO,
  paymentLinkDAO,
  customDomainDAO,
  selfFlightReservationDAO,
  currentPersonDAO,
  personDAO,
  orderDAO,
  selfLedgerTransactionDAO,
  quotationDAO,
  manualReservationDAO,
  userDAO,
  selfPersonAssociationDAO,
  currentUserDAO,
  creditCardDAO,
  paymentDAO,
} = daos
