import { useContext } from 'react';
import HotelSearchContext, { type HotelSearchContextValue } from '../HotelSearchContext';
const DEFAULT = ({
  b2c: true,
  isClientUser: true,
  checkoutPath: '/checkout',
  engineRuleVersion: '0',
  hotelsPath: '/hotels',
  showRatePlanName: true,
  showRatePlanPublic: true,
  tenant: null
} as const);
const useHotelSearchContext = (): HotelSearchContextValue => useContext(HotelSearchContext) ?? DEFAULT;
export default useHotelSearchContext;