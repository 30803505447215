import { formatDate, printPercentage, printPrice, t } from '@niaratech/niara-js-commons';
import React from 'react';
import type { PartialField } from '../../fillCrudMetadataGaps';
import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import type { HotelReservation } from 'src/types';
import { currencies } from '../currencyExchangeRule';
declare module '@niaratech/devcase-crud/dist/types' {
  interface Field<T = any, E = any> {
    /**
     * Usado pela tela de edição de campos da reserva
     */
    custom_editHotelReservation_hide?: boolean;
    custom_editHotelReservation_calculate?: {
      (hotelReservation: Partial<HotelReservation>, roomStayIndex?: number, nightIndex?: number): {
        expectedValue?: T;
        description?: string;
      };
    };
  }
}
type BuildPriceFieldParams = Pick<PartialField, 'required'> & {
  custom_editHotelReservation_calculate_value?: PartialField<number>['custom_editHotelReservation_calculate'];
  custom_editHotelReservation_calculate_currency?: PartialField<string>['custom_editHotelReservation_calculate'];
};
type BuildPriceFieldFunction = {
  (params?: BuildPriceFieldParams): Partial<PartialField>;
};
const build_HotelReservation_RoomStay_CurrencyTypes: BuildPriceFieldFunction = params => {
  const {
    required = false,
    custom_editHotelReservation_calculate_currency,
    custom_editHotelReservation_calculate_value
  } = params ?? {};
  return {
    type: 'object',
    graphQLType: 'HotelReservation_RoomStay_CurrencyTypes',
    fields: [{
      name: 'currency',
      options: currencies,
      inputType: 'niara-react-form/inputText',
      required,
      label: 'Moeda',
      custom_editHotelReservation_calculate: custom_editHotelReservation_calculate_currency
    }, {
      name: 'value',
      type: 'float',
      required,
      label: 'Valor',
      inputType: 'niara-react-form/inputFloat',
      fractionDigits: 2,
      startAdornment: '$',
      custom_editHotelReservation_calculate: custom_editHotelReservation_calculate_value
    }]
  };
};
const build_HotelReservation_RoomStay_PriceComposition_MarkupOrClientCommission_CommonFields = (params?: BuildPriceFieldParams & {
  custom_editHotelReservation_calculate_percentage?: PartialField<number>['custom_editHotelReservation_calculate'];
}): PartialField['fields'] => {
  const {
    required = false,
    custom_editHotelReservation_calculate_currency,
    custom_editHotelReservation_calculate_value,
    custom_editHotelReservation_calculate_percentage
  } = params ?? {};
  return [{
    name: 'currency',
    required,
    label: 'Moeda',
    options: currencies,
    inputType: 'niara-react-form/inputText',
    custom_editHotelReservation_calculate: custom_editHotelReservation_calculate_currency
  }, {
    name: 'value',
    type: 'float',
    required,
    label: 'Valor',
    inputType: 'niara-react-form/inputFloat',
    fractionDigits: 2,
    startAdornment: '$',
    custom_editHotelReservation_calculate: custom_editHotelReservation_calculate_value
  }, {
    name: 'percentage',
    type: 'float',
    label: 'Porcentagem',
    inputType: 'percentage',
    min: -1,
    required,
    custom_editHotelReservation_calculate: custom_editHotelReservation_calculate_percentage
  }, {
    name: 'originalValue',
    type: 'float',
    label: 'Valor original',
    inputType: 'niara-react-form/inputFloat',
    fractionDigits: 2,
    startAdornment: '$',
    custom_editHotelReservation_hide: true
  }, {
    name: 'originalPercentage',
    type: 'float',
    label: 'Porcentagem original',
    inputType: 'percentage',
    custom_editHotelReservation_hide: true
  }];
};
type BuildPriceWithPercentageFieldParams = BuildPriceFieldParams & {
  custom_editHotelReservation_calculate_percentage?: PartialField<number>['custom_editHotelReservation_calculate'];
};
const build_HotelReservation_RoomStay_CurrencyTypes_WithPercentage = (params?: BuildPriceWithPercentageFieldParams) => {
  const {
    custom_editHotelReservation_calculate_percentage
  } = params ?? {};
  return {
    type: 'object',
    graphQLType: 'HotelReservation_RoomStay_CurrencyTypes_WithPercentage',
    fields: [...build_HotelReservation_RoomStay_CurrencyTypes(params).fields, {
      name: 'percentage',
      type: 'float',
      label: 'Porcentagem',
      inputType: 'percentage',
      min: -1,
      custom_editHotelReservation_calculate: custom_editHotelReservation_calculate_percentage
    }]
  };
};
const HotelReservation_RoomStay_Descriptions_Description = ({
  type: 'objectarray',
  graphQLType: '[HotelReservation_RoomStay_Descriptions_Description]',
  fields: [{
    name: 'description'
  }, {
    name: 'name'
  }]
} as const);
export default fillCrudMetadataGaps({
  label: 'Reserva de Hotel',
  labelPlural: 'Reservas de Hotel',
  name: 'HotelReservation',
  faIcon: 'book',
  deletable: false,
  updatable: true,
  insertable: false,
  'custom:automaticSortDisabled': true,
  custom_withAudit: true,
  custom_actionAuditOnUpdate: true,
  authorization: authorization('clientConsultant'),
  dynamoDB: {
    tableName: 'SpearHotelReservation2'
  },
  // 'custom:sortFields': ['date desc'],
  columnNames: ['id', 'locator', 'date', 'startDate', 'hotelName', 'hotelCityName', 'hotelId', 'allGuests', 'clientName', 'clientId', 'travellerId', 'totalPrice', 'totalReservationReceived', 'currencyCode', 'source', 'status', 'CreatedBy', 'responsible', 'responsibleName', 'importStatus', 'primaryGuest', 'primaryGuestEmail', 'primaryGuestPhone', 'automaticCancellationDate', 'createdByName', 'orderId', 'isManual', 'hotelCountryCode', 'deviceType', 'hiddenFields', 'PMSTimestamp'],
  fields: ([{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false,
    'custom:includeSelfHotelReservation': true
  }, {
    name: 'credentialAlias',
    label: 'Credencial',
    updatable: false,
    'custom:includeSelfHotelReservation': false,
    custom_auditConfig: {
      sortOrder: 1
    }
  }, {
    name: 'credentialId',
    label: 'Id da credencial',
    updatable: false,
    'custom:includeSelfHotelReservation': false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'distributionId',
    label: 'Id da distribuição',
    updatable: false,
    'custom:includeSelfHotelReservation': false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'date',
    label: 'Data da reserva',
    type: 'datetime',
    updatable: false,
    'custom:includeSelfHotelReservation': true,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'lastUpdateDate',
    label: 'Data da Última Modificação',
    type: 'datetime',
    updatable: false,
    'custom:includeSelfHotelReservation': true,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'endDate',
    label: 'Data de check-out',
    type: 'date',
    updatable: false,
    'custom:includeSelfHotelReservation': true,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'startDate',
    label: 'Data de check-in',
    type: 'date',
    updatable: false,
    'custom:includeSelfHotelReservation': true,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'importDate',
    label: 'Data de importação',
    type: 'date',
    updatable: false,
    'custom:includeSelfHotelReservation': false
  }, {
    name: 'promoCode',
    label: 'Código Promocional',
    updatable: false,
    'custom:includeSelfHotelReservation': true
  }, {
    name: 'hotelName',
    label: 'Hotel',
    // deprecated: true,
    updatable: false,
    'custom:includeSelfHotelReservation': true,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'productName',
    label: 'Hotel',
    updatable: false,
    'custom:includeSelfHotelReservation': true,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'hotelCityName',
    label: 'Cidade',
    updatable: false,
    'custom:notInAutomaticCanellationDateIndex': true,
    'custom:includeSelfHotelReservation': true,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'hotelCountryCode',
    label: 'País',
    updatable: false,
    'custom:includeSelfHotelReservation': true,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'obtRef',
    label: 'Ref OBT',
    updatable: true,
    'custom:includeSelfHotelReservation': false
  }, {
    name: 'extObtApprover',
    label: 'Aprovador (OBT externo)',
    updatable: true
  }, {
    name: 'extObtEmployeeId',
    label: 'Matrícula do viajante (OBT externo)',
    updatable: true
  }, {
    name: 'orderId',
    label: 'Viagem',
    updatable: false,
    'custom:includeSelfHotelReservation': true,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'primaryGuest',
    label: 'Pax',
    updatable: false,
    renderColumn: false,
    'custom:includeSelfHotelReservation': true
  }, {
    name: 'source',
    label: 'Conteúdo',
    updatable: false,
    'custom:includeSelfHotelReservation': false,
    custom_auditConfig: {
      sortOrder: 3
    }
  }, {
    name: 'idOnSource',
    label: 'Localizador (conteúdo)',
    updatable: false,
    'custom:includeSelfHotelReservation': false,
    custom_auditConfig: {
      show: 'ON_CHANGE'
    }
  }, {
    name: 'locator',
    label: 'Localizador',
    updatable: false,
    'custom:includeSelfHotelReservation': true,
    custom_auditConfig: {
      show: 'ON_CHANGE'
    }
  }, {
    name: 'refCode',
    label: 'Código de referência externa',
    updatable: true,
    'custom:includeSelfHotelReservation': false
  }, {
    name: 'hotelId',
    label: 'Id do hotel',
    // deprecated: true,
    updatable: false,
    renderColumn: false,
    'custom:notInAutomaticCanellationDateIndex': true,
    'custom:includeSelfHotelReservation': true,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'productId',
    label: 'Id do hotel',
    updatable: false,
    renderColumn: false,
    'custom:notInAutomaticCanellationDateIndex': true,
    'custom:includeSelfHotelReservation': true,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'paymentLink',
    label: 'Link de pagamento',
    updatable: false,
    insertable: false,
    custom_notInSearch: true /** não é incluído na pesquisa */,
    graphQLType: 'HotelReservation_PaymentLink',
    type: 'object',
    fields: [{
      label: 'Code',
      name: 'code',
      type: 'text',
      id: true
    }, {
      label: 'Id',
      name: 'id',
      type: 'text'
    }, {
      label: 'Type',
      name: 'type',
      type: 'text',
      options: [{
        value: 'hotelReservation',
        label: 'Reserva de hotel'
      }, {
        value: 'paymentRequest',
        label: 'Solicitação de pagamento'
      }, {
        value: 'order',
        label: 'Viagem'
      }]
    }],
    'custom:includeSelfHotelReservation': true,
    custom_renderFieldsRecursively: true,
    custom_auditConfig: {
      groupName: 'Link de pagamento'
    }
  }, {
    name: 'roomStays',
    label: 'Quartos',
    type: 'objectarray',
    updatable: false,
    insertable: false,
    'custom:includeSelfHotelReservation': false,
    // é recriado direto no selfHotelReservation
    custom_renderFieldsRecursively: true,
    graphQLType: '[HotelReservation_RoomStay]',
    fields: [{
      name: 'roomIdOnSource',
      label: 'Localizador',
      custom_auditConfig: {
        show: 'ALWAYS',
        sortOrder: 1
      }
    }, {
      name: 'endDate',
      type: 'date',
      label: 'Check-out',
      custom_auditConfig: {
        show: 'ALWAYS',
        sortOrder: 5
      }
    }, {
      name: 'startDate',
      type: 'date',
      label: 'Check-in',
      custom_auditConfig: {
        show: 'ALWAYS',
        sortOrder: 4
      }
    }, {
      name: 'modificationDate',
      label: 'Data de modificação',
      type: 'datetime',
      updatable: false
    }, {
      name: 'cancelDate',
      label: 'Data de cancelamento',
      type: 'datetime',
      custom_auditConfig: {
        show: 'ON_CHANGE',
        sortOrder: 3
      }
    }, {
      name: 'cancelled',
      label: 'Cancelado?',
      type: 'boolean',
      custom_auditConfig: {
        show: 'ALWAYS',
        sortOrder: 2
      }
    }, {
      name: 'cancelledInPenalty',
      label: 'Cancelado com penalidade?',
      type: 'boolean',
      custom_auditConfig: {
        sortOrder: 2.1
      }
    }, {
      name: 'meal',
      label: 'Regime',
      type: 'object',
      graphQLType: 'HotelReservation_RoomStay_Meal',
      // custom_renderFieldsRecursively: true,
      custom_auditConfig: {
        show: 'ALWAYS',
        sortOrder: 10
      },
      fields: [{
        name: 'name'
      }, {
        name: 'breakfast',
        type: 'boolean'
      }, {
        name: 'dinner',
        type: 'boolean'
      }, {
        name: 'extra',
        type: 'boolean'
      }, {
        name: 'lunch',
        type: 'boolean'
      }],
      render: entity => {
        const meal = [];
        const mealName = String(entity?.meal?.name)?.toLowerCase();
        if (entity?.meal?.name) meal.push(mealName.charAt(0).toUpperCase() + mealName.slice(1));
        if (!entity?.meal?.name && entity?.meal?.breakfast == true) meal.push('Café da Manhã');
        if (!entity?.meal?.name && entity?.meal?.lunch == true) meal.push('Almoço');
        if (!entity?.meal?.name && entity?.meal?.dinner == true) meal.push('Jantar');
        if (!entity?.meal?.name && entity?.meal?.extra == true) meal.push('Extra');
        return meal.join(', ');
      }
    }, {
      name: 'cancelPolicy',
      type: 'object',
      label: 'Política de cancelamento',
      graphQLType: 'HotelReservation_RoomStay_CancelPolicy',
      custom_renderFieldsRecursively: true,
      custom_auditConfig: {
        sortOrder: 11
      },
      fields: [{
        name: 'dropDate',
        label: 'Data do cancelamento automático',
        type: 'datetime',
        custom_auditConfig: {
          show: 'ALWAYS',
          sortOrder: 11.01
        }
      }, {
        name: 'inPenalty',
        label: 'Em penalidade?',
        type: 'boolean',
        custom_auditConfig: {
          show: 'ON_CHANGE',
          sortOrder: 11.02
        }
      }, {
        name: 'nonRefundable',
        label: 'Não reembolsável?',
        type: 'boolean',
        custom_auditConfig: {
          show: 'ON_CHANGE',
          sortOrder: 11.03
        }
      }, {
        name: 'penaltyDate',
        label: 'Multa a partir de',
        type: 'datetime',
        custom_auditConfig: {
          show: 'ALWAYS',
          sortOrder: 11.04
        }
      }, {
        name: 'priceComposition',
        type: 'object',
        label: 'Valor da multa',
        graphQLType: 'HotelReservation_RoomStay_CancelPolicy_Price',
        hint: `Valores de multa em caso de cancelamento.`,
        custom_renderFieldsRecursively: true,
        fields: [{
          name: 'total',
          label: 'Multa a cobrar',
          hint: `Valor da multa de cancelamento a cobrar do cliente (em geral, preço do fornecedor + markup). Deve estar na moeda de venda.`,
          custom_auditConfig: {
            show: 'ALWAYS',
            sortOrder: 11.04
          },
          ...build_HotelReservation_RoomStay_CurrencyTypes({
            custom_editHotelReservation_calculate_currency(hotelReservation) {
              const description = "Moeda de venda";
              return {
                description,
                expectedValue: hotelReservation?.currencyCode
              };
            }
          }),
          render: entity => entity?.total?.value ? printPrice(entity?.total?.value, entity?.total?.currency) : undefined
        }, {
          name: 'penalty',
          label: 'Multa no fornecedor',
          hint: `Valor da multa de cancelamento no fornecedor. Moeda de venda.`,
          ...build_HotelReservation_RoomStay_CurrencyTypes({
            custom_editHotelReservation_calculate_currency(hotelReservation) {
              const description = "Moeda de venda";
              return {
                description,
                expectedValue: hotelReservation?.currencyCode
              };
            }
          }),
          custom_auditConfig: {
            show: 'NEVER'
          }
        }, {
          name: 'markup',
          label: 'Markup sobre a multa',
          hint: `Valor do markup sobre multa. Possui campo percentage, que é a porcentagem usada para cálculo do markup.`,
          custom_auditConfig: {
            show: 'NEVER'
          },
          ...build_HotelReservation_RoomStay_CurrencyTypes_WithPercentage({
            custom_editHotelReservation_calculate_currency(hotelReservation, roomStayIndex) {
              const roomStay = hotelReservation?.roomStays?.[roomStayIndex];
              if (!roomStay?.cancelPolicy?.priceComposition?.markup) {
                return null;
              }
              const description = "Moeda de venda";
              return {
                description,
                expectedValue: hotelReservation?.currencyCode
              };
            }
          }),
          render: entity => entity?.markup?.value ? printPrice(entity?.markup?.value, entity?.markup?.currency) + ' / ' + printPercentage(entity?.markup?.percentage) : undefined
        }]
      }, {
        name: 'supplierPriceComposition',
        type: 'object',
        label: `Multa do fornecedor`,
        custom_renderFieldsRecursively: true,
        fields: [{
          name: 'penalty',
          label: 'Multa',
          hint: `Valor da multa de cancelamento no fornecedor. Moeda do fornecedor.`,
          ...build_HotelReservation_RoomStay_CurrencyTypes({
            custom_editHotelReservation_calculate_currency(hotelReservation, roomStayIndex) {
              const description = "Moeda do fornecedor";
              const roomStay = hotelReservation?.roomStays?.[roomStayIndex];
              return {
                description,
                expectedValue: roomStay?.supplierPrice?.net?.currency
              };
            }
          }),
          custom_auditConfig: {
            show: 'NEVER'
          }
        }]
      }]
    }, {
      name: 'roomDescription',
      label: 'Quarto',
      custom_auditConfig: {
        show: 'ALWAYS',
        sortOrder: 6
      }
    }, {
      name: 'supplierPrice',
      label: 'Preço no fornecedor',
      type: 'object',
      graphQLType: 'HotelReservation_RoomStay_SupplierPrice',
      hint: 'Valores que compõem o preço do quarto no fornecedor',
      custom_renderFieldsRecursively: true,
      custom_auditConfig: {
        sortOrder: 15
      },
      fields: [{
        name: 'taxes',
        label: 'Taxas e impostos',
        type: 'object',
        custom_auditConfig: {
          sortOrder: 23
        },
        graphQLType: 'HotelReservation_RoomStay_SupplierPrice_Taxes',
        fields: [...build_HotelReservation_RoomStay_CurrencyTypes().fields],
        render: entity => {
          const value = entity?.taxes?.value ?? 0;
          const currency = entity?.taxes?.currency ?? 'BRL';
          return printPrice(value, currency);
        }
      }, {
        name: 'commission',
        label: 'Comissão do fornecedor',
        custom_auditConfig: {
          show: 'ALWAYS',
          sortOrder: 18
        },
        ...build_HotelReservation_RoomStay_CurrencyTypes_WithPercentage({
          custom_editHotelReservation_calculate_value(hotelReservation, roomStayIndex, nightIndex) {
            const description = "Soma dos preços das noites";
            const roomStay = hotelReservation?.roomStays?.[roomStayIndex];
            const expectedValue = roomStay?.supplierPrice?.nights?.reduce((acc, night) => acc + night?.value, 0);
            return {
              expectedValue,
              description
            };
          }
        }),
        render: entity => entity?.commission?.value ? printPrice(entity?.commission?.value, entity?.commission?.currency) : undefined
      }, {
        name: 'net',
        label: 'Tarifa do fornecedor',
        custom_auditConfig: {
          sortOrder: 21
        },
        hint: 'Preço original no fornecedor. Inclui comissão do fornecedor e descontos.',
        ...build_HotelReservation_RoomStay_CurrencyTypes({
          custom_editHotelReservation_calculate_value(hotelReservation, roomStayIndex, nightIndex) {
            const description = "Soma dos preços das noites";
            const roomStay = hotelReservation?.roomStays?.[roomStayIndex];
            const expectedValue = roomStay?.supplierPrice?.nights?.reduce((acc, night) => acc + night?.value, 0);
            return {
              expectedValue,
              description
            };
          }
        }),
        render: entity => entity?.net?.value ? printPrice(entity?.net?.value, entity?.net?.currency) : undefined
      }, {
        name: 'discounts',
        label: 'Descontos',
        hint: 'Descontos no fornecedor. Para obter o preço sem desconto, somar tarifa do fornecedor.',
        ...build_HotelReservation_RoomStay_CurrencyTypes({
          required: false
        }),
        custom_auditConfig: {
          show: 'ALWAYS',
          sortOrder: 16
        },
        render: entity => entity?.discounts?.value ? printPrice(entity?.discounts?.value, entity?.discounts?.currency) : undefined
      }, {
        name: 'nights',
        label: 'Valor por noite',
        type: 'objectarray',
        hint: 'Valor de cada noite da tarifa. Valor do fornecedor.',
        custom_auditConfig: {
          sortOrder: 24
        },
        graphQLType: '[HotelReservation_RoomStay_SupplierPrice_Night]',
        fields: [{
          name: 'date',
          type: 'date',
          label: 'Data',
          custom_editHotelReservation_hide: true
        }, ...build_HotelReservation_RoomStay_CurrencyTypes().fields],
        render: entity => {
          return entity?.nights?.length > 0 ? <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px'
          }}>
                      {entity?.nights?.map(night => <span key={night?.date}>{`${formatDate(night?.date)}: ${printPrice(night?.value, night?.currency)}`}</span>)}
                    </div> : undefined;
        }
      }]
    }, {
      name: 'price',
      type: 'object',
      graphQLType: 'HotelReservation_RoomStay_Price',
      label: 'Componentes do preço',
      hint: 'Preço de venda',
      custom_renderFieldsRecursively: true,
      custom_auditConfig: {
        sortOrder: 15
      },
      fields: [{
        name: 'commission',
        label: 'Comissão do fornecedor',
        hint: 'Comissão do fornecedor na moeda do fornecedor (igual ao supplierPrice.commission)',
        ...build_HotelReservation_RoomStay_CurrencyTypes_WithPercentage({
          required: false,
          custom_editHotelReservation_calculate_value(hotelReservation, roomStayIndex) {
            const description = "Igual ao valor do fornecedor";
            const roomStay = hotelReservation?.roomStays?.[roomStayIndex];
            const expectedValue = roomStay?.supplierPrice?.commission?.value;
            return {
              expectedValue,
              description
            };
          },
          custom_editHotelReservation_calculate_currency(hotelReservation, roomStayIndex) {
            const description = "Igual ao valor do fornecedor";
            const roomStay = hotelReservation?.roomStays?.[roomStayIndex];
            const expectedValue = roomStay?.supplierPrice?.commission?.currency;
            return {
              expectedValue,
              description
            };
          },
          custom_editHotelReservation_calculate_percentage(hotelReservation, roomStayIndex, nightIndex) {
            const description = "Igual ao valor do fornecedor";
            const roomStay = hotelReservation?.roomStays?.[roomStayIndex];
            const expectedValue = roomStay?.supplierPrice?.commission?.percentage;
            return {
              expectedValue,
              description
            };
          }
        }),
        custom_auditConfig: {
          show: 'ALWAYS',
          sortOrder: 18
        },
        render: entity => entity?.commission?.value ? printPrice(entity?.commission?.value, entity?.commission?.currency) : undefined
      }, {
        name: 'clientCommission',
        label: 'Comissão Cliente',
        hint: 'Comissão do cliente - pode ser um valor com desconto estabelecido no checkout ou editado após a criação.',
        custom_auditConfig: {
          show: 'ALWAYS',
          sortOrder: 19
        },
        type: 'object',
        fields: [...build_HotelReservation_RoomStay_PriceComposition_MarkupOrClientCommission_CommonFields(), {
          name: 'commissionReductionAllowed',
          type: 'boolean',
          label: 'Permitir redução de comissão',
          custom_editHotelReservation_hide: true
        }],
        render: entity => entity?.clientCommission?.value ? printPrice(entity?.clientCommission?.value, entity?.clientCommission?.currency) : undefined
      }, {
        ...build_HotelReservation_RoomStay_CurrencyTypes(),
        name: 'clientCommissionOriginal',
        label: 'Comissão Cliente Original',
        deprecated: true,
        render: entity => entity?.clientCommissionOriginal?.value ? printPrice(entity?.clientCommissionOriginal?.value, entity?.clientCommissionOriginal?.currency) : undefined
      }, {
        name: 'total',
        label: 'Valor total',
        hint: 'Preço de venda total, incluindo taxas, impostos, descontos, markup.',
        custom_auditConfig: {
          sortOrder: 25
        },
        ...build_HotelReservation_RoomStay_CurrencyTypes({
          required: true,
          custom_editHotelReservation_calculate_value(hotelReservation, roomStayIndex, nightIndex) {
            const description = "Preço no fornecedor + markup";
            const roomStay = hotelReservation?.roomStays?.[roomStayIndex];
            const expectedValue = roomStay?.price?.net?.value + roomStay?.price?.taxes?.value + (roomStay?.price?.markup?.value ?? 0);
            return {
              expectedValue,
              description
            };
          },
          custom_editHotelReservation_calculate_currency(hotelReservation) {
            const description = "Moeda de venda";
            return {
              description,
              expectedValue: hotelReservation?.currencyCode
            };
          }
        }),
        render: entity => entity?.total?.value ? printPrice(entity?.total?.value, entity?.total?.currency) : undefined
      }, {
        name: 'fee',
        label: 'Fee',
        hint: 'Fee é um componente de preço que é exibido separadamente da tarifa. (adiciona no total?)',
        graphQLType: 'HotelReservation_RoomStay_Price_Fee',
        custom_auditConfig: {
          sortOrder: 20
        },
        type: 'object',
        deprecated: true,
        fields: [...build_HotelReservation_RoomStay_CurrencyTypes({
          custom_editHotelReservation_calculate_currency(hotelReservation) {
            const description = "Moeda de venda";
            return {
              description,
              expectedValue: hotelReservation?.currencyCode
            };
          }
        }).fields, {
          name: 'display',
          hint: '?'
        }, {
          name: 'label',
          hint: 'Descrição do fee'
        }],
        render: entity => {
          const display = entity?.fee?.display ?? entity?.fee?.label;
          return entity?.fee?.value ? display + ' ' + printPrice(entity?.fee?.value, entity?.fee?.currency) : undefined;
        }
      }, {
        name: 'hotelFees',
        label: 'Fees que serão cobrados posteriormente no hotel, como taxa de turismo.',
        type: 'object',
        graphQLType: 'HotelReservation_RoomStay_Price_HotelFees',
        custom_renderFieldsRecursively: true,
        custom_auditConfig: {
          groupName: 'Hotel Fees'
        },
        fields: [{
          name: 'billableCurrency'
        }, {
          name: 'billableValue',
          type: 'float'
        }, ...build_HotelReservation_RoomStay_CurrencyTypes().fields, {
          name: 'fees',
          type: 'objectarray',
          graphQLType: '[HotelReservation_RoomStay_Price_HotelFees_Fee]',
          custom_renderFieldsRecursively: true,
          fields: [{
            name: 'description'
          }, {
            name: 'billableValue',
            type: 'float'
          }, {
            name: 'billableCurrency'
          }, ...build_HotelReservation_RoomStay_CurrencyTypes().fields]
        }]
      }, {
        name: 'discounts',
        label: 'Descontos',
        ...build_HotelReservation_RoomStay_CurrencyTypes(),
        custom_auditConfig: {
          show: 'ALWAYS',
          sortOrder: 16
        },
        render: entity => entity?.discounts?.value ? printPrice(entity?.discounts?.value, entity?.discounts?.currency) : undefined
      }, {
        name: 'markup',
        type: 'object',
        custom_auditConfig: {
          sortOrder: 22
        },
        render: entity => {
          return entity?.markup?.value ? printPrice(entity?.markup?.value, entity?.markup?.currency) + ' / ' + printPercentage(entity?.markup?.percentage) : undefined;
        },
        fields: [...build_HotelReservation_RoomStay_PriceComposition_MarkupOrClientCommission_CommonFields({
          custom_editHotelReservation_calculate_value(hotelReservation, roomStayIndex, nightIndex) {
            const description = "Preço da tarifa - tarifa no fornecedor";
            const roomStay = hotelReservation?.roomStays?.[roomStayIndex];
            const expectedValue = roomStay?.price?.productPrice?.value - roomStay?.price?.net?.value;
            return {
              expectedValue,
              description
            };
          },
          custom_editHotelReservation_calculate_currency(hotelReservation) {
            const description = "Moeda de venda";
            return {
              description,
              expectedValue: hotelReservation?.currencyCode
            };
          },
          custom_editHotelReservation_calculate_percentage(hotelReservation, roomStayIndex, nightIndex) {
            const description = "Valor do markup / valor do produto";
            const roomStay = hotelReservation?.roomStays?.[roomStayIndex];
            const expectedValue = roomStay?.price?.markup?.value / (roomStay?.price?.productPrice?.value || 1);
            return {
              expectedValue,
              description
            };
          }
        }), {
          name: 'markupAppliesOnTaxes',
          type: 'boolean',
          custom_editHotelReservation_hide: true,
          label: 'Aplicar markup nas taxas'
        }, {
          name: 'markupReductionAllowed',
          type: 'boolean',
          custom_editHotelReservation_hide: true,
          label: 'Permitir redução de markup'
        }]
      }, {
        name: 'net',
        label: 'Preço no fornecedor (convertido)',
        custom_auditConfig: {
          sortOrder: 21
        },
        hint: 'Preço original no fornecedor, sem taxas. Inclui a comissão do fornecedor. Convertido para a moeda de venda.',
        render: entity => entity?.net?.value ? printPrice(entity?.net?.value, entity?.net?.currency) : undefined,
        ...build_HotelReservation_RoomStay_CurrencyTypes({
          required: true,
          custom_editHotelReservation_calculate_value(hotelReservation, roomStayIndex, nightIndex) {
            const description = "Preço da tarifa + taxas - markup";
            const roomStay = hotelReservation?.roomStays?.[roomStayIndex];
            const expectedValue = roomStay?.price?.productPrice?.value - (roomStay?.price?.markup?.value ?? 0);
            return {
              expectedValue,
              description
            };
          },
          custom_editHotelReservation_calculate_currency(hotelReservation) {
            const description = "Moeda de venda";
            return {
              description,
              expectedValue: hotelReservation?.currencyCode
            };
          }
        })
      }, {
        name: 'nights',
        label: 'Valor por noite',
        type: 'objectarray',
        hint: 'Valores por noite. Inclui o markup. Valor de venda (soma igual ao productPrice)',
        custom_auditConfig: {
          sortOrder: 24
        },
        graphQLType: '[HotelReservation_RoomStay_Price_Night]',
        fields: [{
          name: 'date',
          type: 'date',
          label: 'Data',
          custom_editHotelReservation_hide: true
        }, ...build_HotelReservation_RoomStay_CurrencyTypes({
          required: true
        }).fields],
        render: entity => {
          return entity?.nights?.length > 0 ? <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px'
          }}>
                      {entity?.nights?.map(night => <span key={night?.date}>{`${formatDate(night?.date)}: ${printPrice(night?.value, night?.currency)}`}</span>)}
                    </div> : undefined;
        }
      }, {
        name: 'taxes',
        label: 'Taxas',
        hint: 'Impostos e taxas. Convertido para a moeda de venda.',
        type: 'object',
        custom_auditConfig: {
          sortOrder: 23
        },
        graphQLType: 'HotelReservation_RoomStay_Price_Taxes',
        fields: [...build_HotelReservation_RoomStay_CurrencyTypes({
          required: true
        }).fields],
        render: entity => {
          const value = entity?.taxes?.value ?? 0;
          const currency = entity?.taxes?.currency ?? 'BRL';
          return printPrice(value, currency);
        }
      }, {
        name: 'discountBreakdown',
        type: 'objectarray',
        hint: 'Descontos do fornecedor são convertidos para a moeda de venda.',
        custom_renderFieldsRecursively: true,
        custom_auditConfig: {
          groupName: 'Desconto',
          sortOrder: 17
        },
        fields: [{
          name: 'currency',
          label: 'Moeda',
          custom_auditConfig: {
            sortOrder: 17.01
          },
          required: true
        }, {
          name: 'value',
          label: 'Valor',
          type: 'float',
          custom_auditConfig: {
            sortOrder: 17.02
          },
          required: true
        }, {
          name: 'description',
          label: 'Descrição',
          custom_auditConfig: {
            sortOrder: 17.03
          }
        }, {
          name: 'code',
          label: 'Código',
          hint: 'Aplicável se for promocode ou cupom de desconto',
          custom_auditConfig: {
            sortOrder: 17.04
          }
        }, {
          name: 'originType',
          label: 'Tipo de desconto',
          custom_auditConfig: {
            sortOrder: 17.05
          },
          options: [{
            value: 'COUPON'
          }, {
            value: 'PROMOCODE'
          }, {
            value: 'OFFER'
          }]
        }]
      }, {
        name: 'exchangeCurrency',
        label: 'Moeda original',
        hint: 'Apenas em caso de câmbio no preço de venda',
        options: currencies,
        inputType: 'niara-react-form/inputText'
      }, {
        name: 'exchangeRate',
        label: 'Câmbio',
        hint: 'Apenas em caso de câmbio no preço de venda',
        type: 'float',
        inputType: 'niara-react-form/inputFloat',
        fractionDigits: 2,
        startAdornment: '$'
      }, {
        name: 'productPrice',
        label: 'Preço da tarifa',
        hint: 'Preço da tarifa de venda, excluindo os impostos.',
        custom_auditConfig: {
          sortOrder: 17.06
        },
        ...build_HotelReservation_RoomStay_CurrencyTypes({
          required: true,
          custom_editHotelReservation_calculate_value(hotelReservation, roomStayIndex, nightIndex) {
            const description = "Preço da tarifa + taxas - markup";
            const roomStay = hotelReservation?.roomStays?.[roomStayIndex];
            const expectedValue = roomStay?.price?.net?.value + (roomStay?.price?.markup?.value ?? 0);
            return {
              expectedValue,
              description
            };
          },
          custom_editHotelReservation_calculate_currency(hotelReservation) {
            const description = "Moeda de venda";
            return {
              description,
              expectedValue: hotelReservation?.currencyCode
            };
          }
        })
      }, {
        name: 'totalReceivable',
        label: 'Valor a receber',
        hint: 'Valor a receber',
        custom_auditConfig: {
          sortOrder: 17.07
        },
        ...build_HotelReservation_RoomStay_CurrencyTypes({
          custom_editHotelReservation_calculate_value(hotelReservation, roomStayIndex, nightIndex) {
            const description = "Preço total";
            const roomStay = hotelReservation?.roomStays?.[roomStayIndex];
            const expectedValue = roomStay?.price?.totalReceivable?.value > 0 ? roomStay?.price?.total?.value : 0;
            return {
              expectedValue,
              description
            };
          },
          custom_editHotelReservation_calculate_currency(hotelReservation) {
            const description = "Moeda de venda";
            return {
              description,
              expectedValue: hotelReservation?.currencyCode
            };
          }
        })
      }]
    }, {
      name: 'ratePlanName',
      label: 'Tarifário',
      custom_auditConfig: {
        show: 'ALWAYS',
        sortOrder: 12
      }
    }, {
      name: 'ratePlanPublic',
      label: 'Tarifa Pública?',
      type: 'boolean',
      custom_auditConfig: {
        show: 'ALWAYS',
        sortOrder: 13
      }
    }, {
      name: 'ratePlanPackage',
      label: 'Tarifa Pacote?',
      type: 'boolean',
      custom_auditConfig: {
        show: 'ALWAYS',
        sortOrder: 13
      }
    }, {
      name: 'ratePlanInclusions',
      label: 'Inclusões da tarifa',
      type: 'objectarray',
      graphQLType: '[HotelReservation_RoomStay_RatePlanInclusion]',
      custom_auditConfig: {
        show: 'ALWAYS',
        sortOrder: 14
      },
      fields: [{
        name: 'id',
        type: 'text'
      }, {
        name: 'name',
        type: 'text'
      }, {
        name: 'description',
        type: 'text'
      }],
      render: entity => {
        return entity?.ratePlanInclusions?.length > 0 ? entity?.ratePlanInclusions?.map(item => item?.name ?? item?.id)?.join(', ') : undefined;
      }
    }, {
      name: 'roomRateId',
      custom_auditConfig: {
        show: 'NEVER'
      }
    }, {
      name: 'roomTypeId',
      type: 'text',
      custom_auditConfig: {
        show: 'NEVER'
      }
    }, {
      name: 'payment',
      type: 'object',
      deprecated: true,
      hint: 'Fluxo com distribuição',
      graphQLType: 'HotelReservation_RoomStay_Payment',
      custom_renderFieldsRecursively: true,
      custom_auditConfig: {
        show: 'NEVER'
      },
      fields: [{
        name: 'pay',
        type: 'object',
        graphQLType: 'HotelReservation_RoomStay_Payment_Pay',
        custom_renderFieldsRecursively: true,
        fields: [{
          name: 'commission',
          label: 'Comissão paga',
          ...build_HotelReservation_RoomStay_CurrencyTypes_WithPercentage({
            required: false
          }),
          render: entity => {
            return entity?.commission?.value ? printPrice(entity?.commission?.value, entity?.commission?.currency) + ' / ' + printPercentage(entity?.commission?.percentage) : undefined;
          }
        }, {
          name: 'total',
          label: 'Total pago',
          custom_auditConfig: {
            show: 'ALWAYS'
          },
          ...build_HotelReservation_RoomStay_CurrencyTypes(),
          render: entity => entity?.total?.value ? printPrice(entity?.total?.value, entity?.total?.currency) : undefined
        }]
      }, {
        name: 'receive',
        type: 'object',
        graphQLType: 'HotelReservation_RoomStay_Payment_Receive',
        custom_renderFieldsRecursively: true,
        fields: [{
          name: 'commission',
          label: 'Comissão recebida',
          ...build_HotelReservation_RoomStay_CurrencyTypes_WithPercentage({
            required: false
          }),
          render: entity => {
            return entity?.commission?.value ? printPrice(entity?.commission?.value, entity?.commission?.currency) + ' / ' + printPercentage(entity?.commission?.percentage) : undefined;
          }
        }, {
          name: 'total',
          label: 'Total recebido',
          custom_auditConfig: {
            show: 'ALWAYS'
          },
          ...build_HotelReservation_RoomStay_CurrencyTypes(),
          render: entity => entity?.total?.value ? printPrice(entity?.total?.value, entity?.total?.currency) : undefined
        }, {
          name: 'fee',
          label: 'Fee recebido',
          ...build_HotelReservation_RoomStay_CurrencyTypes(),
          render: entity => entity?.fee?.value ? printPrice(entity?.fee?.value, entity?.fee?.currency) : undefined
        }, {
          name: 'markup',
          ...build_HotelReservation_RoomStay_CurrencyTypes_WithPercentage({
            required: false
          }),
          render: entity => {
            return entity?.markup?.value ? printPrice(entity?.markup?.value, entity?.markup?.currency) + ' / ' + printPercentage(entity?.markup?.percentage) : undefined;
          }
        }]
      }, {
        name: 'fee',
        label: 'Pagamento Fee',
        type: 'object',
        graphQLType: 'HotelReservation_RoomStay_Payment_Fee',
        fields: [...build_HotelReservation_RoomStay_CurrencyTypes_WithPercentage({
          required: false
        }).fields, {
          name: 'display'
        }, {
          name: 'label'
        }],
        render: entity => {
          const display = entity?.fee?.display ?? entity?.fee?.label;
          return entity?.fee?.value ? display + ' ' + printPrice(entity?.fee?.value, entity?.fee?.currency) : undefined;
        }
      }]
    },
    // {
    //   name: 'rules',
    //   type: 'objectarray',
    // },
    // {
    //   name: 'specialRequest',
    //   type: 'object',
    // },
    {
      name: 'vcn',
      label: 'VCN',
      type: 'object',
      graphQLType: 'HotelReservation_RoomStay_Vcn',
      custom_auditConfig: {
        show: 'NEVER'
      },
      fields: [{
        name: 'activationDate',
        type: 'date'
      }, {
        name: 'expirationDate',
        type: 'date'
      }, {
        name: 'id'
      }, {
        name: 'trigger'
      }],
      render: entity => {
        const activationDate = formatDate(entity?.vcn?.activationDate);
        const expirationDate = formatDate(entity?.vcn?.expirationDate);
        return `Data ativação: ${activationDate}, Data expiração: ${expirationDate}`;
      }
    }, {
      name: 'guests',
      type: 'objectarray',
      graphQLType: '[HotelReservation_RoomStay_Guest]',
      custom_renderFieldsRecursively: true,
      custom_auditConfig: {
        show: 'ALWAYS',
        sortOrder: 9,
        groupName: 'Hóspede'
      },
      fields: [{
        name: 'firstName',
        label: 'Nome do Hóspede',
        custom_auditConfig: {
          show: 'ALWAYS',
          sortOrder: 9.01
        }
      }, {
        name: 'lastName',
        label: 'Sobrenome do Hóspede',
        custom_auditConfig: {
          show: 'ALWAYS',
          sortOrder: 9.02
        }
      }, {
        name: 'namePrefix',
        label: 'Prefixo',
        custom_auditConfig: {
          show: 'ON_CHANGE',
          sortOrder: 9.0
        },
        custom_obfuscateValue: true
      }, {
        name: 'email',
        label: 'E-mail Hóspede',
        custom_auditConfig: {
          show: 'ON_CHANGE',
          sortOrder: 9.03
        },
        custom_obfuscateValue: true
      }, {
        name: 'phone',
        label: 'Telefone Hóspede',
        custom_auditConfig: {
          show: 'ON_CHANGE',
          sortOrder: 9.04
        },
        custom_obfuscateValue: true
      }, {
        name: 'cpf',
        label: 'CPF Hóspede',
        custom_auditConfig: {
          show: 'ON_CHANGE',
          sortOrder: 9.05
        },
        custom_obfuscateValue: true
      }, {
        name: 'rg',
        label: 'RG Hóspede',
        custom_auditConfig: {
          show: 'ON_CHANGE',
          sortOrder: 9.06
        },
        custom_obfuscateValue: true
      }, {
        name: 'passport',
        label: 'Passaporte Hóspede',
        custom_auditConfig: {
          show: 'ON_CHANGE',
          sortOrder: 9.07
        },
        custom_obfuscateValue: true
      }, {
        name: 'birthCertificate',
        label: 'Certificado de Nascimento Hóspede',
        custom_auditConfig: {
          show: 'ON_CHANGE',
          sortOrder: 9.08
        },
        custom_obfuscateValue: true
      }, {
        name: 'dob',
        label: 'Data de Nascimento Hóspede',
        custom_auditConfig: {
          show: 'ON_CHANGE',
          sortOrder: 9.09
        },
        custom_obfuscateValue: true
      }, {
        name: 'ageQualifying',
        required: false,
        options: [{
          value: 'ADULT',
          label: 'Adultos'
        }, {
          value: 'CHILD',
          label: 'Crianças'
        }]
      }, {
        name: 'age',
        label: 'Idade Hóspede',
        required: false,
        type: 'int',
        custom_auditConfig: {
          show: 'ON_CHANGE',
          sortOrder: 9.1
        },
        custom_obfuscateValue: true
      }, {
        name: 'primary',
        type: 'boolean'
      }, {
        name: 'country',
        label: 'País',
        custom_auditConfig: {
          show: 'ON_CHANGE',
          sortOrder: 23
        },
        custom_obfuscateValue: true
      }, {
        name: 'nationality',
        label: 'Nacionalidade',
        custom_auditConfig: {
          show: 'ON_CHANGE',
          sortOrder: 23
        },
        custom_obfuscateValue: true
      }, {
        name: 'addressCity',
        label: 'Cidade',
        custom_auditConfig: {
          show: 'ON_CHANGE',
          sortOrder: 9.15
        },
        custom_obfuscateValue: true
      }, {
        name: 'addressState',
        label: 'Estado',
        custom_auditConfig: {
          show: 'ON_CHANGE',
          sortOrder: 9.16
        },
        custom_obfuscateValue: true
      }, {
        name: 'addressDistrict',
        label: 'Bairro',
        custom_auditConfig: {
          show: 'ON_CHANGE',
          sortOrder: 9.14
        },
        custom_obfuscateValue: true
      }, {
        name: 'addressNumber',
        label: 'Número',
        custom_auditConfig: {
          show: 'ON_CHANGE',
          sortOrder: 9.12
        },
        custom_obfuscateValue: true
      }, {
        name: 'addressZipCode',
        label: 'CEP',
        custom_auditConfig: {
          show: 'ON_CHANGE',
          sortOrder: 9.17
        },
        custom_obfuscateValue: true
      }, {
        name: 'addressStreet',
        label: 'Rua',
        custom_auditConfig: {
          show: 'ON_CHANGE',
          sortOrder: 9.11
        },
        custom_obfuscateValue: true
      }, {
        name: 'addressComplement',
        label: 'Complemento',
        custom_auditConfig: {
          show: 'ON_CHANGE',
          sortOrder: 9.13
        },
        custom_obfuscateValue: true
      }]
    }, {
      name: 'descriptions',
      type: 'object',
      graphQLType: 'HotelReservation_RoomStay_Descriptions',
      custom_auditConfig: {
        show: 'NEVER'
      },
      fields: [{
        name: 'cancelPolicy',
        ...HotelReservation_RoomStay_Descriptions_Description
      }, {
        name: 'generalPolicies',
        ...HotelReservation_RoomStay_Descriptions_Description
      }, {
        name: 'meal',
        ...HotelReservation_RoomStay_Descriptions_Description
      }, {
        name: 'payment',
        ...HotelReservation_RoomStay_Descriptions_Description
      }, {
        name: 'roomType',
        ...HotelReservation_RoomStay_Descriptions_Description
      }]
    }, {
      name: 'occupancy',
      label: 'Ocupação',
      type: 'object',
      graphQLType: 'HotelReservation_RoomStay_Occupancy',
      custom_auditConfig: {
        show: 'ALWAYS',
        sortOrder: 8
      },
      fields: [{
        name: 'adults',
        label: 'Adultos',
        type: 'int',
        required: true
      }, {
        name: 'children',
        label: 'Crianças',
        type: 'int'
      }, {
        name: 'quantity',
        type: 'int'
      }, {
        name: 'childrenAges',
        type: 'intarray'
      }],
      render: entity => {
        const occupancy = [];
        if (entity?.occupancy?.adults) occupancy.push(`${entity?.occupancy?.adults} adulto(s)`);
        if (entity?.occupancy?.children) occupancy.push(`${entity?.occupancy?.children} criança(s)`);
        const childrenAges = entity?.occupancy?.childrenAges?.lenght > 0 ? ' (' + entity?.occupancy?.childrenAges?.join(' ano(s), ')?.concat(' ano(s))') : '';
        return occupancy?.join(', ')?.concat(childrenAges);
      }
    }]
  }, {
    name: 'status',
    label: 'Status',
    hint: 'Status da reserva',
    'custom:includeSelfHotelReservation': true,
    required: true,
    updatable: false,
    custom_auditConfig: {
      sortOrder: 3.01
    },
    // render: (i) => i.ImportedBy ? <div><span className="badge badge-pill badge-warning">I</span>{i.status}</div> : i.status
    options: [{
      label: 'Rejeitado',
      color: '#FF0000',
      value: 'REJECTED'
    }, {
      label: 'Confirmado',
      color: '#53AC2A',
      value: 'CONFIRMED'
    }, {
      label: 'Cancelado',
      color: '#FF0000',
      value: 'CANCELLED'
    },
    // { label: 'Modificado', color: '#FDBA01', value: 'MODIFIED', disabled: true },
    {
      // label:
      //   'Problema na confirmação - Entrar em contato com o fornecedor para verificar se a reserva foi confirmada',
      label: 'Não Confirmado',
      color: '#FF0000',
      value: 'UNCONFIRMED'
    }, {
      label: 'Em Processamento',
      color: '#FDBA01',
      value: 'PROCESSING',
      disabled: true
    }, {
      label: 'Solicitado',
      color: '#FDBA01',
      value: 'PRE_RESERVATION'
    }, {
      label: 'Pagamento pendente',
      color: '#FDBA01',
      value: 'PENDING_PAYMENT',
      disabled: true
    }, {
      // ver #193569
      label: 'Problema no cancelamento',
      color: '#FF0000',
      value: 'CANCEL_ISSUE',
      disabled: true
    }],
    renderColumn: function (i) {
      return <React.Fragment>
              {this.render && this.render(i)}
              {i.importStatus && i.importStatus != 'NOT_IMPORTED' && <i className="fas fa-cloud-download-alt ml-2" style={i.importStatus === 'CONFLICT' ? {
          color: 'red'
        } : null}></i>}
            </React.Fragment>;
    },
    renderStyle(i, fieldName = 'status') {
      if (i[fieldName]) {
        switch (i[fieldName]) {
          case 'CANCELLED':
          case 'REJECTED':
          case 'UNCONFIRMED':
            return 'danger';
          case 'PRE_RESERVATION':
          case 'PROCESSING':
          case 'MODIFIED':
          case 'PENDING_PAYMENT':
            return 'warning';
          case 'CONFIRMED':
            return 'success';
        }
      }
      return 'danger';
    }
  }, {
    name: 'totalPrice',
    'custom:includeSelfHotelReservation': true,
    label: 'Preço total da reserva',
    type: 'float',
    inputType: 'niara-react-form/inputFloat',
    required: true,
    fractionDigits: 2,
    startAdornment: '$',
    updatable: false,
    render: i => i.totalPrice ? printPrice(i.totalPrice, i.currencyCode) : '-',
    custom_auditConfig: {
      show: 'NEVER'
    },
    custom_editHotelReservation_calculate: (hotelReservation: HotelReservation) => {
      const expectedValue = hotelReservation?.roomStays?.reduce((acc, roomStay) => {
        return acc + roomStay?.price?.total?.value;
      }, 0);
      return {
        expectedValue,
        description: 'Soma do preço total dos quartos'
      };
    }
  }, {
    name: 'averagePrice',
    'custom:includeSelfHotelReservation': true,
    label: 'Valor médio da reserva',
    type: 'float',
    inputType: 'niara-react-form/inputFloat',
    fractionDigits: 2,
    startAdornment: '$',
    updatable: false,
    render: i => i.averagePrice ? printPrice(i.averagePrice, i.currencyCode) : '-',
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'clientId',
    'custom:includeSelfHotelReservation': true,
    label: 'Id Cliente',
    updatable: false,
    renderColumn: false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'clientName',
    label: 'Nome do cliente',
    updatable: false,
    'custom:includeSelfHotelReservation': false,
    custom_auditConfig: {
      sortOrder: 2
    }
  }, {
    name: 'travellerName',
    'custom:includeSelfHotelReservation': true,
    label: 'Nome do colaborador',
    updatable: false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'travellerId',
    'custom:includeSelfHotelReservation': true,
    label: 'Id do colaborador/viajante',
    updatable: false,
    renderColumn: false,
    'custom:notInAutomaticCanellationDateIndex': true,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'payment',
    label: 'Pagamento',
    type: 'object',
    updatable: false,
    'custom:includeSelfHotelReservation': false,
    // é recriado direto no selfHotelReservation
    graphQLType: 'NestedHotelReservationPayment',
    custom_auditConfig: {
      show: 'NEVER'
    },
    fields: [{
      name: 'vcn',
      type: 'text'
    }, {
      name: 'installments',
      type: 'int',
      required: false
    }, {
      name: 'paymentType'
    }, {
      name: 'paymentOption',
      type: 'object',
      graphQLType: 'NestedHotelReservationPaymentOption',
      custom_renderFieldsRecursively: true,
      fields: [{
        name: 'output',
        type: 'textarray'
      }, {
        name: 'external',
        type: 'boolean'
      }, {
        name: 'alias',
        type: 'text'
      }, {
        name: 'description',
        type: 'text'
      }, {
        name: 'id',
        type: 'text'
      }, {
        name: 'source',
        type: 'object',
        graphQLType: 'NestedHotelReservationPaymentOptionSource',
        custom_renderFieldsRecursively: true,
        fields: [{
          name: 'name',
          type: 'text'
        }, {
          name: 'fullName',
          type: 'text'
        }, {
          name: 'id',
          type: 'text'
        }]
      }, {
        name: 'type',
        type: 'text'
      }, {
        name: 'card',
        type: 'boolean'
      }, {
        name: 'enabled',
        type: 'boolean'
      }]
    }, {
      name: 'vcnCredentialId',
      type: 'text',
      custom_auditConfig: {
        show: 'NEVER'
      }
    }, {
      name: 'outputPaymentOption',
      type: 'object',
      graphQLType: 'NestedHotelReservationPaymentOption',
      custom_auditConfig: {
        show: 'NEVER'
      },
      fields: [{
        name: 'output',
        type: 'textarray'
      }, {
        name: 'external',
        type: 'boolean'
      }, {
        name: 'alias',
        type: 'text'
      }, {
        name: 'description',
        type: 'text'
      }, {
        name: 'id',
        type: 'text'
      }, {
        name: 'source',
        type: 'object',
        graphQLType: 'NestedHotelReservationPaymentOptionSource',
        custom_renderFieldsRecursively: true,
        fields: [{
          name: 'name',
          type: 'text'
        }, {
          name: 'fullName',
          type: 'text'
        }, {
          name: 'id',
          type: 'text'
        }]
      }, {
        name: 'type',
        type: 'text'
      }, {
        name: 'card',
        type: 'boolean'
      }, {
        name: 'enabled',
        type: 'boolean'
      }]
    }, {
      name: 'trigger',
      type: 'text',
      custom_auditConfig: {
        show: 'NEVER'
      }
    }, {
      name: 'creditCard',
      type: 'object',
      graphQLType: 'NestedHotelReservationCreditCard',
      custom_renderFieldsRecursively: true,
      custom_auditConfig: {
        show: 'NEVER'
      },
      fields: [{
        name: 'cardHolderName',
        type: 'text'
      }, {
        name: 'cardTypeCode',
        type: 'text'
      }, {
        name: 'expireDate',
        type: 'text'
      }, {
        name: 'cardNumber',
        type: 'text'
      }]
    }]
  }, {
    name: 'tags',
    label: 'Marcadores',
    type: 'textarray',
    updatable: true,
    'custom:includeSelfHotelReservation': false,
    onlyClientTags: true
  }, {
    name: 'comment',
    'custom:includeSelfHotelReservation': false,
    label: 'Observações ao hotel',
    updatable: false
  }, {
    name: 'userComment',
    'custom:includeSelfHotelReservation': true,
    label: 'Comentários do usuário',
    updatable: false
  }, {
    name: 'user',
    label: 'Usuário de Criação',
    updatable: false,
    renderColumn: false,
    custom_hiddenSelfBooking: true,
    'custom:includeSelfHotelReservation': false
  }, {
    name: 'createdByName',
    label: 'Criado por',
    updatable: false,
    renderColumn: false,
    'custom:includeSelfHotelReservation': false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'responsible',
    label: 'Responsável',
    updatable: true,
    renderColumn: false,
    custom_hiddenSelfBooking: true,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'responsibleEmail',
    label: 'Responsável (e-mail)',
    updatable: true,
    renderColumn: false,
    custom_hiddenSelfBooking: true,
    custom_auditConfig: {
      sortOrder: 5
    }
  }, {
    name: 'responsibleName',
    label: 'Responsável',
    updatable: true,
    custom_hiddenSelfBooking: true,
    'custom:includeSelfHotelReservation': false,
    renderColumn: i => i.responsibleName || i.createdByName,
    custom_auditConfig: {
      sortOrder: 4
    }
  }, {
    name: 'lastUpdateUser',
    label: 'Usuário de Atualização',
    updatable: false,
    'custom:includeSelfHotelReservation': false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'updatedByName',
    label: 'Atualizado por',
    updatable: false,
    renderColumn: false,
    'custom:includeSelfHotelReservation': false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'CreatedBy',
    label: 'Criado por',
    updatable: false,
    renderColumn: false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'UpdatedBy',
    label: 'Editado por',
    updatable: false,
    'custom:includeSelfHotelReservation': false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'ImportedBy',
    label: 'Importado por',
    updatable: false,
    renderColumn: false,
    'custom:includeSelfHotelReservation': false
  }, {
    name: 'importStatus',
    label: 'Situação Importação',
    updatable: false,
    renderColumn: false,
    'custom:includeSelfHotelReservation': false
  }, {
    name: 'isManual',
    label: 'É reserva manual',
    updatable: false,
    'custom:includeSelfHotelReservation': false,
    type: 'boolean'
  }, {
    name: 'currencyCode',
    label: 'Moeda da reserva',
    options: currencies,
    inputType: 'niara-react-form/inputText',
    updatable: false,
    renderColumn: false,
    required: true,
    'custom:includeSelfHotelReservation': true,
    custom_auditConfig: {
      show: 'NEVER'
    },
    custom_editHotelReservation_calculate: (hotelReservation: HotelReservation) => {
      const expectedValue = hotelReservation?.roomStays?.[0]?.price?.productPrice?.currency;
      return {
        expectedValue,
        description: 'Moeda do primeiro quarto'
      };
    }
  }, {
    name: 'allGuests',
    label: 'Hóspedes',
    type: 'textarray',
    updatable: false,
    'custom:includeSelfHotelReservation': true,
    custom_auditConfig: {
      show: 'NEVER'
    },
    renderColumn: i => i.allGuests ? <div>
              <span>{i.allGuests[0]}</span>
              {i.allGuests && i.allGuests.length > 1 && <div>
                  <small>{i.allGuests.length} hóspedes</small>
                  <span data-toggle="tooltip" className="ml-2" title={i.allGuests.join(', ')}>
                    <i className="fa fa-info-circle"></i>
                  </span>
                </div>}
            </div> : <span>{i.primaryGuest}</span>
  }, {
    label: 'Status da aprovação',
    name: 'approvalStatus',
    updatable: false,
    deprecated: true,
    options: [{
      label: 'Carregando aprovadores',
      value: 'LOADING_APPROVERS'
    }, {
      label: 'Aprovação pendente',
      value: 'AWAITING_APPROVAL'
    }, {
      label: 'Aprovado',
      value: 'APPROVED'
    }, {
      label: 'Não aprovado no prazo',
      value: 'CANCELLED_TIMEOUT'
    }, {
      label: 'Aprovação desnecessária',
      value: 'APPROVED_AUTOMATICALLY'
    }, {
      label: 'Erro! Não foi encontrado grupo de avaliação',
      value: 'ERROR_APPROVAL_RULE_NOT_FOUND'
    }, {
      label: 'Cancelado',
      value: 'CANCELLED'
    }, {
      label: 'Rejeitado',
      value: 'REJECTED'
    }],
    renderStyle(i, fieldName = 'approvalStatus') {
      if (!i[fieldName]) return null;
      switch (i[fieldName]) {
        case 'REJECTED':
        case 'CANCELLED':
        case 'CANCELLED_TIMEOUT':
        case 'ERROR_APPROVAL_RULE_NOT_FOUND':
          return 'danger';
        case 'APPROVED':
        case 'APPROVED_AUTOMATICALLY':
          return 'success';
        case 'AWAITING_APPROVAL':
          return 'warning';
      }
      return 'warning';
    }
  }, {
    name: 'requestId',
    label: 'Id da requisição',
    render: false,
    updatable: false,
    insertable: false,
    deprecated: true,
    'custom:includeSelfHotelReservation': false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'errors',
    label: 'Erros',
    type: 'objectarray',
    render: false,
    updatable: false,
    insertable: false,
    fields: [{
      name: 'code'
    }, {
      name: 'message'
    }],
    'custom:includeSelfHotelReservation': false
  }, {
    name: 'automaticCancellationEnabled',
    label: 'Cancelamento automático ligado',
    updatable: false,
    type: 'boolean',
    'custom:includeSelfHotelReservation': true,
    custom_auditConfig: {
      show: 'ALWAYS',
      sortOrder: 7
    }
  }, {
    name: 'automaticCancellationDate',
    label: 'Data esperada do cancelamento',
    type: 'datetime',
    'custom:includeSelfHotelReservation': false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'primaryGuestPhone',
    label: 'Telefone do viajante',
    type: 'text',
    insertable: false,
    updatable: false,
    renderColumn: false,
    'custom:notInAutomaticCanellationDateIndex': true,
    'custom:includeSelfHotelReservation': true,
    custom_obfuscateValue: true
  }, {
    name: 'primaryGuestEmail',
    label: 'E-mail do viajante',
    type: 'text',
    inputType: 'email',
    insertable: false,
    updatable: false,
    renderColumn: false,
    'custom:notInAutomaticCanellationDateIndex': true,
    'custom:includeSelfHotelReservation': true,
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Comprador",
    name: 'buyer_name',
    label: 'Nome do comprador',
    updatable: false,
    'custom:includeSelfHotelReservation': true,
    custom_auditConfig: {
      sortOrder: 6.0
    }
  }, {
    custom_groupName: "Comprador",
    name: 'buyer_email',
    label: 'E-mail do comprador',
    updatable: false,
    'custom:includeSelfHotelReservation': true,
    custom_auditConfig: {
      sortOrder: 6.01
    },
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Comprador",
    name: 'buyer_cpf',
    label: 'CPF do comprador',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 6.02
    },
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Comprador",
    name: 'buyer_dob',
    label: 'Data de nascimento do comprador',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 6.04
    }
  }, {
    custom_groupName: "Comprador",
    name: 'buyer_phone',
    label: 'Número de telefone do comprador',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 6.03
    },
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Comprador",
    name: 'buyer_username',
    label: 'Login do comprador',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 6.05
    },
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Endereço do comprador",
    name: 'buyer_country',
    label: 'País',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 6.06
    },
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Comprador",
    name: 'buyer_nationality',
    label: 'Nacionalidade',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 6.06
    },
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Endereço do comprador",
    name: 'buyer_addressCity',
    label: 'Cidade',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 6.08
    },
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Endereço do comprador",
    name: 'buyer_addressState',
    label: 'Estado',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 6.07
    },
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Endereço do comprador",
    name: 'buyer_addressDistrict',
    label: 'Bairro',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 6.09
    },
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Endereço do comprador",
    name: 'buyer_addressNumber',
    label: 'Número',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 6.12
    },
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Endereço do comprador",
    name: 'buyer_addressZipCode',
    label: 'CEP',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 6.1
    },
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Endereço do comprador",
    name: 'buyer_addressStreet',
    label: 'Rua',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 6.11
    },
    custom_obfuscateValue: true
  }, {
    custom_groupName: "Endereço do comprador",
    name: 'buyer_addressComplement',
    label: 'Complemento',
    updatable: false,
    custom_auditConfig: {
      sortOrder: 6.13
    },
    custom_obfuscateValue: true
  }, {
    name: 'buyer_personId',
    updatable: false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'reviewEndDate',
    label: 'Revisão Data de check-out',
    type: 'date',
    updatable: false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'reviewStartDate',
    label: 'Revisão Data de check-in',
    type: 'date',
    updatable: false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'reviewTotalHotelPrice',
    label: 'Revisão de preço',
    type: 'float',
    updatable: false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'reviewTotalExtrasPrice',
    label: 'Revisão de extras',
    type: 'float',
    updatable: false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'reviewTotalTaxesPrice',
    label: 'Revisão de impostos',
    type: 'float',
    updatable: false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'reviewTotalPenaltyPrice',
    type: 'float',
    updatable: false,
    render: i => i.reviewTotalPenaltyPrice ? printPrice(i.reviewTotalPenaltyPrice, i.currencyCode) : '-',
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'reviewComments',
    label: 'Comentários',
    updatable: false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'reviewStatusDate',
    label: 'Data do status da alteração',
    type: 'datetime',
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'reviewStatus',
    label: 'Status da alteração',
    options: [{
      label: 'Rejeitado',
      value: 'REJECTED'
    }, {
      label: 'Aprovado',
      value: 'APPROVED'
    }, {
      label: 'Alterado',
      value: 'PENDING'
    }],
    renderStyle(i, fieldName = 'reviewStatus') {
      if (i[fieldName]) {
        switch (i[fieldName]) {
          case 'REJECTED':
            return 'danger';
          case 'PENDING':
            return 'warning';
          case 'APPROVED':
            return 'success';
        }
      }
      return 'danger';
    },
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'reviewDate',
    label: 'Data da alteração',
    type: 'datetime',
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'invoiceStatus',
    label: 'Status da nota fiscal',
    options: [{
      label: 'Rejeitado',
      value: 'REJECTED'
    }, {
      label: 'Aprovado',
      value: 'APPROVED'
    }, {
      label: 'Aguardando aprovação',
      value: 'PENDING_APPROVAL'
    }, {
      label: 'Envio pendente',
      value: 'PENDING_SENDING'
    }, {
      label: 'Envio não obrigatório',
      value: 'NOT_NECESSARY'
    }],
    renderStyle(i, fieldName = 'invoiceStatus') {
      if (i[fieldName]) {
        switch (i[fieldName]) {
          case 'REJECTED':
          case 'PENDING_SENDING':
            return 'danger';
          case 'PENDING_APPROVAL':
            return 'warning';
          case 'APPROVED':
            return 'success';
          case 'NOT_NECESSARY':
            return 'muted';
        }
      }
      return 'danger';
    },
    renderColumn(i) {
      if (!i.invoiceStatus) return 'Envio pendente';
      return this.render && this.render(i);
    },
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'invoiceStatusDate',
    label: 'Data alteração do status nota fiscal',
    type: 'datetime'
  }, {
    name: 'invoiceDate',
    label: 'Data do envio da nota fiscal',
    type: 'datetime'
  }, {
    name: 'outputCreditCardPaymentStatus',
    label: 'Status do pagamento',
    custom_auditConfig: {
      show: 'NEVER'
    },
    options: [{
      label: 'Cartão com problema',
      value: 'CAPTURE_FORBIDDEN'
    }, {
      label: 'Aguardando pagamento',
      value: 'CAPTURE_ALLOWED'
    }, {
      label: 'Pagamento realizado',
      value: 'CAPTURED'
    }],
    renderStyle(i, fieldName = 'outputCreditCardPaymentStatus') {
      if (i[fieldName]) {
        switch (i[fieldName]) {
          case 'CAPTURE_FORBIDDEN':
            return 'danger';
          case 'CAPTURE_ALLOWED':
            return 'warning';
          case 'CAPTURED':
            return 'success';
        }
      }
      return 'danger';
    }
  }, {
    name: 'invoice',
    label: 'Nota Fiscal',
    type: 'object',
    graphQLType: 'SupplierReservationInvoice',
    graphQLInputType: 'SupplierReservationInvoiceInput',
    custom_auditConfig: {
      show: 'NEVER'
    },
    fields: [{
      name: 'vatSupplier',
      updatable: false
    }, {
      name: 'vatConsumer',
      updatable: false
    }, {
      name: 'invoiceValue',
      type: 'float',
      updatable: false
    }, {
      name: 'invoiceFile',
      updatable: false
    }, {
      name: 'vatSupplierChecked',
      type: 'boolean',
      updatable: false
    }, {
      name: 'vatConsumerChecked',
      type: 'boolean',
      updatable: false
    }, {
      name: 'invoiceValueChecked',
      type: 'boolean',
      updatable: false
    }, {
      name: 'invoiceScore',
      type: 'float',
      updatable: false
    }]
  }, {
    name: 'totalCommission',
    label: 'Total da comissão',
    type: 'float',
    inputType: 'niara-react-form/inputFloat',
    fractionDigits: 2,
    startAdornment: '$',
    updatable: false,
    'custom:includeSelfHotelReservation': false
  }, {
    name: 'totalCommissionCurrency',
    label: 'Moeda total da comissão',
    options: currencies,
    inputType: 'niara-react-form/inputText',
    updatable: false,
    'custom:includeSelfHotelReservation': false
  }, {
    name: 'totalCommissionPayable',
    label: 'Comissão total a pagar',
    type: 'float',
    inputType: 'niara-react-form/inputFloat',
    fractionDigits: 2,
    startAdornment: '$',
    updatable: false,
    'custom:includeSelfHotelReservation': false
  }, {
    name: 'totalCommissionPayCurrency',
    label: 'Moeda comissão total a pagar',
    options: currencies,
    inputType: 'niara-react-form/inputText',
    updatable: false,
    'custom:includeSelfHotelReservation': false
  }, {
    name: 'totalCommissionReceivable',
    label: 'Comissão total a receber',
    type: 'float',
    inputType: 'niara-react-form/inputFloat',
    fractionDigits: 2,
    startAdornment: '$',
    updatable: false,
    'custom:includeSelfHotelReservation': false
  }, {
    name: 'totalCommissionReceiveCurrency',
    label: 'Moeda comissão total a receber',
    options: currencies,
    inputType: 'niara-react-form/inputText',
    updatable: false,
    'custom:includeSelfHotelReservation': false
  }, {
    name: 'totalHotelPrice',
    label: 'Total no fornecedor',
    hint: 'Valor da tarifa com taxas do fornecedor, mas convertido para a moeda de venda',
    type: 'float',
    inputType: 'niara-react-form/inputFloat',
    fractionDigits: 2,
    startAdornment: '$',
    updatable: false,
    render: i => i.totalHotelPrice != null ? printPrice(i.totalHotelPrice, i.currencyCode) : '-',
    custom_auditConfig: {
      show: 'NEVER'
    },
    required: true,
    custom_editHotelReservation_calculate: (hotelReservation: HotelReservation) => {
      const expectedValue = hotelReservation?.roomStays?.reduce((acc, roomStay) => acc + roomStay.price.net?.value + roomStay.price.taxes?.value, 0);
      return {
        expectedValue,
        description: 'Soma da tarifa no fornecedor + taxas dos quartos'
      };
    }
  }, {
    name: 'totalOriginalPrice',
    label: 'Preço no fornecedor',
    hint: 'Preço no fornecedor, na moeda original, com taxas',
    type: 'float',
    inputType: 'niara-react-form/inputFloat',
    fractionDigits: 2,
    startAdornment: '$',
    updatable: false,
    required: true,
    'custom:includeSelfHotelReservation': false
  }, {
    name: 'totalOriginalPriceCurrency',
    label: 'Moeda no fornecedor',
    options: currencies,
    inputType: 'niara-react-form/inputText',
    updatable: false,
    required: true,
    'custom:includeSelfHotelReservation': false
  }, {
    name: 'totalReservation',
    label: 'Total da tarifa',
    type: 'float',
    inputType: 'niara-react-form/inputFloat',
    fractionDigits: 2,
    startAdornment: '$',
    updatable: false,
    required: true,
    'custom:includeSelfHotelReservation': false,
    custom_editHotelReservation_calculate: (hotelReservation: HotelReservation) => {
      const expectedValue = hotelReservation?.roomStays?.reduce((acc, roomStay) => acc + roomStay.price.productPrice?.value, 0);
      return {
        expectedValue,
        description: 'Soma das tarifas dos quartos'
      };
    }
  }, {
    name: 'totalReservationCurrency',
    label: 'Moeda da reserva',
    options: currencies,
    inputType: 'niara-react-form/inputText',
    updatable: false,
    required: true,
    'custom:includeSelfHotelReservation': false,
    custom_editHotelReservation_calculate: (hotelReservation: HotelReservation) => {
      const expectedValue = hotelReservation?.currencyCode;
      return {
        expectedValue,
        description: 'Moeda da reserva'
      };
    }
  }, {
    name: 'totalReservationPayable',
    'custom:includeSelfHotelReservation': false,
    label: 'Total a pagar pela reserva',
    type: 'float',
    inputType: 'niara-react-form/inputFloat',
    fractionDigits: 2,
    startAdornment: '$',
    updatable: false,
    render: i => i.totalReservationPayable ? printPrice(i.totalReservationPayable, i.totalReservationPayCurrency) : '-',
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'totalReservationPayCurrency',
    'custom:includeSelfHotelReservation': false,
    label: 'Moeda total a pagar pela reserva',
    options: currencies,
    inputType: 'niara-react-form/inputText',
    updatable: false,
    renderColumn: false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'totalReservationPreConfirmed',
    label: 'Total Pré Confirmado',
    type: 'float',
    inputType: 'niara-react-form/inputFloat',
    fractionDigits: 2,
    startAdornment: '$',
    updatable: false,
    'custom:includeSelfHotelReservation': true,
    render: i => i.totalReservationPreConfirmed ? printPrice(i.totalReservationPreConfirmed, i.currencyCode) : '-'
  }, {
    name: 'totalReservationReceivable',
    label: 'Total a receber',
    type: 'float',
    inputType: 'niara-react-form/inputFloat',
    fractionDigits: 2,
    startAdornment: '$',
    updatable: false,
    required: false,
    'custom:includeSelfHotelReservation': true,
    custom_editHotelReservation_calculate: (hotelReservation: HotelReservation) => {
      const expectedValue = hotelReservation?.roomStays?.reduce((acc, roomStay) => acc + roomStay.price?.totalReceivable?.value, 0);
      return {
        expectedValue,
        description: 'Soma do valor a receber dos quartos'
      };
    }
  }, {
    name: 'totalReservationReceiveCurrency',
    label: 'Moeda total a receber',
    options: currencies,
    inputType: 'niara-react-form/inputText',
    updatable: false,
    'custom:includeSelfHotelReservation': false
  }, {
    name: 'totalReservationReceived',
    hint: 'Total Recebido',
    label: 'Valor',
    type: 'float',
    updatable: false,
    'custom:includeSelfHotelReservation': true,
    render: i => i.totalReservationReceived ? printPrice(i.totalReservationReceived, i.currencyCode) : '-'
  }, {
    name: 'totalReservationTaxes',
    label: 'Taxas',
    type: 'float',
    inputType: 'niara-react-form/inputFloat',
    fractionDigits: 2,
    startAdornment: '$',
    updatable: false,
    required: true,
    'custom:includeSelfHotelReservation': false
  }, {
    name: 'totalTaxesPrice',
    label: 'Taxas',
    type: 'float',
    inputType: 'niara-react-form/inputFloat',
    fractionDigits: 2,
    startAdornment: '$',
    updatable: false,
    required: true,
    render: i => i.totalTaxesPrice != null ? printPrice(i.totalTaxesPrice, i.currencyCode) : '-',
    'custom:includeSelfHotelReservation': true,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'outputCreditCardPaymentDate',
    label: 'Data de pagamento Bee2Pay',
    type: 'datetime',
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'voucher_id',
    label: 'Voucher',
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'currencyExchange',
    updatable: false,
    insertable: false,
    type: 'object',
    graphQLType: 'NestedHotelReservationCurrencyExchange',
    custom_renderFieldsRecursively: true,
    custom_auditConfig: {
      show: 'NEVER'
    },
    fields: [{
      name: 'currencyTo',
      options: currencies,
      inputType: 'niara-react-form/inputText'
    }, {
      name: 'exchangeRate',
      type: 'float'
    }]
  }, {
    name: 'otherTenantName',
    label: 'Outra instalação',
    updatable: false,
    insertable: false,
    type: 'text',
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'modificationAllowed',
    label: 'Permite alteração',
    type: 'boolean',
    updatable: false,
    insertable: false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'cancellationAllowed',
    label: 'Permite cancelamento',
    type: 'boolean',
    updatable: false,
    insertable: false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'commissionReductionAllowed',
    label: 'Permite reduzir comissão',
    type: 'boolean',
    custom_groupName: "Comissão",
    updatable: false,
    insertable: false,
    deprecated: true,
    hint: 'Configuração lida da regra de comissão no momento da reserva. Olhar em reservation.roomStays[x].price.clientCommission.commissionReductionAllowed',
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'engineRuleVersion',
    label: 'Versão do motor de regras',
    type: 'text',
    updatable: false,
    'custom:includeSelfHotelReservation': true,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'partialPaymentEnabled',
    label: 'Permite pagamento parcial',
    required: true,
    type: 'boolean',
    custom_groupName: 'Pagamento Parcial',
    hint: 'Permite definir um valor parcial para um link de pagamento.',
    'custom:includeSelfHotelReservation': false,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'pmsNumber',
    label: 'Código da reserva no PMS',
    type: 'text',
    updatable: true,
    'custom:includeSelfHotelReservation': false
  }, {
    name: 'checkoutStrategy',
    type: 'text',
    options: [{
      label: 'Reserva após o pagamento',
      value: 'PAYMENT_THEN_BOOKING'
    }, {
      label: 'Pagamento após reserva',
      value: 'BOOKING_THEN_PAYMENT'
    }],
    label: 'Fluxo de Checkout',
    custom_groupName: 'Fluxo de Checkout',
    'custom:includeSelfHotelReservation': true,
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'crmId',
    label: 'Id no CRM',
    type: 'text',
    updatable: false,
    insertable: false
  }, {
    name: 'crmStatus',
    label: 'Informação sobre integração CRM',
    type: 'text',
    updatable: false,
    insertable: false
  }, {
    name: 'numberOfNights',
    label: 'Número de noites',
    type: 'int',
    updatable: false,
    insertable: false
  }, {
    name: 'couponCode',
    label: 'Cupom',
    type: 'text',
    hint: 'Código de cupom usado no checkout'
  }, {
    name: 'discountedClientCommissionPercentage',
    type: 'float',
    hint: 'Porcentagem de comissão com desconto',
    custom_auditConfig: {
      show: 'NEVER'
    }
  }, {
    name: 'extraFields',
    label: 'Campos Extras',
    type: 'objectarray',
    custom_renderFieldsRecursively: true,
    custom_auditConfig: {
      groupName: 'Campos extras',
      sortOrder: 1000
    },
    fields: [{
      name: 'label',
      label: 'Nome de exibição',
      type: 'text'
    }, {
      name: 'name',
      label: 'Name',
      type: 'text',
      custom_auditConfig: {
        show: 'NEVER'
      }
    }, {
      name: 'valueStr',
      label: 'Valor',
      type: 'text'
    }, {
      name: 'valueInt',
      label: 'Valor',
      type: 'int'
    }]
  }, {
    name: 'guestAttendanceStatus',
    label: 'Status de checkout da reserva',
    updatable: false,
    options: [{
      label: 'Fez o Checkin',
      value: 'CHECKED_IN'
    }, {
      label: 'Fez o Checkout',
      value: 'CHECKED_OUT'
    }, {
      label: 'No Show',
      value: 'NO_SHOW'
    }]
  }, {
    name: 'hideOnDashboard',
    label: 'Não mostrar no dashboard',
    type: 'boolean',
    updatable: true
  }, {
    name: 'hotelDetails',
    custom_excludeFromDocumentation: true,
    label: 'Detalhes do hotel',
    type: 'object',
    updatable: false,
    'custom:includeSelfHotelReservation': true,
    custom_auditConfig: {
      show: 'NEVER'
    },
    fields: [{
      name: 'name',
      label: 'Nome do hotel',
      type: 'text',
      updatable: false,
      insertable: false
    }, {
      name: 'address',
      label: 'Endereço',
      type: 'object',
      updatable: false,
      insertable: false,
      fields: [{
        name: 'cityName'
      }, {
        name: 'country'
      }, {
        name: 'countryCode'
      }, {
        name: 'postalCode'
      }, {
        name: 'state'
      }, {
        name: 'street'
      }, {
        name: 'timeZone'
      }]
    }, {
      name: 'chainId'
    }, {
      name: 'chainName'
    }, {
      name: 'cityId'
    }, {
      name: 'email'
    }, {
      name: 'financeEmail'
    }, {
      name: 'lastUpdate'
    }, {
      name: 'phone'
    }, {
      name: 'rating',
      type: 'int'
    }, {
      name: 'requestedId'
    }, {
      name: 'requestId'
    }, {
      name: 'location',
      type: 'object',
      fields: [{
        name: 'latitude',
        type: 'float'
      }, {
        name: 'longitude',
        type: 'float'
      }]
    }, {
      name: 'texts',
      type: 'objectarray',
      fields: [{
        name: 'text'
      }, {
        name: 'title'
      }]
    }, {
      name: 'images',
      type: 'textarray'
    }, {
      name: 'amenities',
      type: 'objectarray',
      fields: [{
        name: 'code'
      }, {
        name: 'description'
      }]
    }, {
      name: 'guestRooms',
      type: 'objectarray',
      fields: [{
        name: 'id'
      }, {
        name: 'description'
      }, {
        name: 'maxOccupancy',
        type: 'int'
      }, {
        name: 'quantity',
        type: 'int'
      }, {
        name: 'size',
        type: 'float'
      }, {
        name: 'sizeMeasurement'
      }, {
        name: 'supplier'
      }, {
        name: 'amenities',
        type: 'objectarray',
        fields: [{
          name: 'code'
        }, {
          name: 'description'
        }]
      }, {
        name: 'medias',
        type: 'objectarray',
        fields: [{
          name: 'code'
        }, {
          name: 'images',
          type: 'objectarray',
          fields: [{
            name: 'url'
          }, {
            name: 'name'
          }]
        }, {
          name: 'texts',
          type: 'objectarray',
          fields: [{
            name: 'Description'
          }]
        }]
      }]
    }]
  }, {
    custom_groupName: "Termos e Condições",
    name: 'terms_files',
    label: 'Termos, políticas, regulamentos (B2C)',
    type: 'objectarray',
    graphQLType: '[NestedTermsFile]',
    hint: 'Visíveis pelo viajante em cotação, voucher, link de pagamento, Motor Niara, OTA Builder.',
    'custom:includeSelfHotelReservation': true,
    fields: [{
      name: 'name',
      label: 'Nome',
      hint: 'Termos, condições gerais, regulamento, política de privacidade, etc',
      type: 'text'
    }, {
      name: 'file',
      label: 'Arquivo (PDF)',
      type: 'text'
    }, {
      name: 'url',
      label: 'Link',
      hint: '(ignorado se existir arquivo)',
      type: 'text'
    }]
  }, {
    custom_groupName: "Termos e Condições",
    name: 'terms_files_b2b',
    label: 'Termos, políticas, regulamentos B2B',
    type: 'objectarray',
    graphQLType: '[NestedTermsFile]',
    hint: 'Visíveis para usuários do cliente B2B, mas nunca ao viajante.',
    fields: [{
      name: 'name',
      label: 'Nome',
      hint: 'Termos, condições gerais, regulamento, política de privacidade, etc',
      type: 'text'
    }, {
      name: 'file',
      label: 'Arquivo (PDF)',
      type: 'text'
    }, {
      name: 'url',
      label: 'Link',
      hint: '(ignorado se existir arquivo)',
      type: 'text'
    }]
  }, {
    name: 'rejectReason',
    label: 'Motivo da rejeição',
    updatable: false,
    'custom:includeSelfHotelReservation': true
  }, {
    name: 'deviceType',
    label: 'Tipo de dispositivo',
    updatable: false,
    options: [{
      label: 'Mobile',
      value: 'mobile'
    }, {
      label: 'Desktop',
      value: 'desktop'
    }]
  }, {
    name: 'hiddenFields',
    // usado para exibir ou ocultar informações de valores da reserva no voucher, compartilhar reserva, minhas viagens e tela de sucesso #191537.
    label: 'Exibir/ocultar valores nas reservas',
    'custom:includeSelfHotelReservation': true,
    updatable: true,
    withNullSelector: true,
    options: [{
      label: 'Exibir valores',
      value: 'NONE'
    }, {
      label: 'Ocultar valores',
      value: 'VALUES'
    }]
  }, {
    name: 'hasIncludeExtras',
    label: 'Tem Extras incluídos?',
    type: 'boolean',
    updatable: true
  }, {
    name: 'includeExtras',
    label: 'Extras incluídos',
    type: 'objectarray',
    updatable: true,
    custom_renderFieldsRecursively: true,
    custom_auditConfig: {
      groupName: 'Extras incluídos',
      show: 'ON_CHANGE',
      sortOrder: 1001
    },
    'custom:includeSelfHotelReservation': true,
    fields: [{
      name: 'id',
      label: 'Código',
      type: 'text'
    }, {
      name: 'label',
      label: 'Nome',
      type: 'text'
    }]
  }, {
    name: 'PMSTimestamp',
    label: 'Data do envio de dados ao PMS',
    type: 'datetime',
    'custom:includeSelfHotelReservation': false
  }, {
    name: 'supplierId',
    label: 'Fornecedor',
    type: 'text'
  }, {
    name: 'supplierCorporateName',
    label: 'Nome do Fornecedor',
    type: 'text'
  }] as PartialField[]).map(r => ({
    ...r,
    updatable: r.updatable == null ? false : r.updatable
  })),
  //padrão updatable false para evitar esquecimento
  searchParams: [{
    name: 'orderBy',
    type: 'text'
  }, {
    name: 'hf',
    type: 'text'
  }],
  getParams: [{
    name: 'hf',
    type: 'text'
  }],
  filter: [{
    name: 'automaticCancellationDate_ge',
    field: 'automaticCancellationDate',
    op: 'ge'
  }, {
    name: 'automaticCancellationDate_le',
    field: 'automaticCancellationDate',
    op: 'le'
  }, {
    name: 'automaticCancellationEnabled_eq',
    field: 'automaticCancellationEnabled',
    op: 'eq'
  }, {
    name: 'status_eq',
    field: 'status',
    op: 'eq'
  }, {
    field: 'status',
    op: 'in'
  }, {
    name: 'date_ge',
    field: 'date',
    op: 'ge',
    label: 'Data de Reserva Início'
  }, {
    name: 'date_le',
    field: 'date',
    op: 'le',
    label: 'Data de Reserva Fim'
  }, {
    name: 'idOnSource_eq',
    field: 'idOnSource',
    op: 'eq',
    label: 'Código da reserva'
  }, {
    name: 'locator_eq',
    field: 'locator',
    op: 'eq',
    label: 'Código da reserva'
  }, {
    name: 'clientId_eq',
    field: 'clientId',
    op: 'eq',
    label: 'Id do cliente'
  }, {
    name: 'requestId_eq',
    field: 'requestId',
    op: 'eq',
    label: 'Id da requisição'
  }, {
    field: 'invoiceStatus',
    op: 'eq'
  }, {
    field: 'invoiceStatus',
    op: 'in'
  }, {
    field: 'reviewStatus',
    op: 'eq'
  }, {
    field: 'reviewStatus',
    op: 'in'
  }, {
    field: 'outputCreditCardPaymentStatus',
    op: 'eq'
  }, {
    field: 'outputCreditCardPaymentStatus',
    op: 'in'
  }, {
    field: 'startDate',
    op: 'ge'
  }, {
    field: 'startDate',
    op: 'le'
  }, {
    field: 'startDate',
    op: 'gt'
  }, {
    field: 'startDate',
    op: 'lt'
  }, {
    field: 'endDate',
    op: 'ge'
  }, {
    field: 'endDate',
    op: 'le'
  }, {
    field: 'endDate',
    op: 'gt'
  }, {
    field: 'endDate',
    op: 'lt'
  }, {
    field: 'outputCreditCardPaymentDate',
    op: 'ge'
  }, {
    field: 'outputCreditCardPaymentDate',
    op: 'le'
  }, {
    field: 'responsible',
    op: 'eq'
  }, {
    field: 'user',
    op: 'eq'
  }, {
    field: 'hotelName',
    op: 'eq'
  }, {
    field: 'source',
    op: 'eq'
  }, {
    field: 'orderId',
    op: 'eq'
  }, {
    field: 'isManual',
    op: 'ne'
  }, {
    field: 'isManual',
    op: 'eq'
  }, {
    field: 'importStatus',
    op: 'isNull'
  }, {
    field: 'importStatus',
    op: 'ne'
  }, {
    field: 'importStatus',
    op: 'eq'
  }, {
    field: 'hotelCountryCode',
    op: 'ne'
  }, {
    field: 'hotelCountryCode',
    op: 'eq'
  }, {
    field: 'hotelCountryCode',
    op: 'isNull'
  }, {
    field: 'userComment',
    op: 'isNull'
  }, {
    field: 'id',
    op: 'eq'
  }, {
    field: 'hasIncludeExtras',
    op: 'eq'
  }, {
    field: 'hasIncludeExtras',
    op: 'ne'
  }, {
    field: 'engineRuleVersion',
    op: 'eq'
  }]
});