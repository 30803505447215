import fillCrudMetadata from '@niaratech/devcase-crud/dist/fillCrudMetadataGaps';
export default fillCrudMetadata({
  label: 'URL de Storefront',
  labelPlural: 'URLs de Storefront',
  name: 'StorefrontUrl',
  insertable: true,
  updatable: true,
  deletable: false,
  authorization: {
    read: ['consultant', 'admin'],
    write: ['admin']
  },
  dynamoDB: {
    tableName: process.env.OTABUILDER_STOREFRONT_URL_TABLE,
    partitionKey: 'tenantId',
    indexedProp: 'id',
    indexes: [{
      indexedProp: 'storefrontId',
      indexName: 'tenantId-storefrontId-index'
    }, {
      partitionKey: 'url',
      indexedProp: 'url',
      indexName: 'url-index'
    }]
  },
  columnNames: ['id', 'url', 'storefrontId', 'storefrontName', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    render: false
  }, {
    label: 'Url',
    name: 'url',
    required: true,
    updatable: false,
    pattern: `^https:\\/\\/[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)`,
    type: 'text'
  }, {
    name: 'storefrontId',
    required: true,
    updatable: false,
    type: 'text',
    renderForm: false
  }, {
    name: 'storefrontName',
    label: 'Storefront',
    required: true,
    updatable: false,
    type: 'text',
    inputType: 'storefront'
  }, {
    insertable: true,
    name: 'enabled',
    label: 'Ativo',
    default: true,
    required: true,
    type: 'boolean'
  }],
  filter: [{
    field: 'enabled',
    op: 'eq'
  }, {
    field: 'storefrontId',
    op: 'eq'
  }, {
    field: 'url',
    op: 'eq'
  }]
});