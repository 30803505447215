import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Conta de pessoa',
  labelPlural: 'Contas de pessoa',
  name: 'PersonAccount',
  namePlural: 'PersonAccounts',
  authorization: authorization('consultant', 'admin'),
  dynamoDB: {
    tableName: 'SpearPersonAccount',
    indexes: [{
      indexName: 'tenantId-personId-index',
      indexedProp: 'personId',
      scanIndexBackwards: true
    }]
  },
  insertable: false,
  updatable: false,
  deletable: false,
  columns: ['id', 'personId', 'identityProvider_type', 'identityProvider_name'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'personId',
    label: 'Id da pessoa',
    updatable: false,
    insertable: false
  }, {
    name: 'identityProvider_type',
    updatable: false,
    insertable: false
  }, {
    name: 'identityProvider_name',
    updatable: false,
    insertable: false
  }, {
    name: 'identityProvider_id',
    updatable: false,
    insertable: false
  }, {
    name: 'loginFields',
    label: 'Campos de login',
    updatable: false,
    insertable: false,
    options: ['username', 'email', 'corporateEmail', 'cpf'].map(r => ({
      label: r,
      value: r
    })),
    type: 'textarray'
  }, {
    name: 'username',
    updatable: false,
    insertable: false
  }, {
    name: 'email',
    updatable: false,
    insertable: false
  }, {
    name: 'corporateEmail',
    updatable: false,
    insertable: false
  }, {
    name: 'cpf',
    updatable: false,
    insertable: false
  }],
  filter: [{
    field: 'personId',
    op: 'eq'
  }, {
    field: 'id',
    op: 'beginsWith'
  }, {
    field: 'identityProvider_id',
    op: 'eq'
  }]
});