import fillCrudMetadataGaps, {
  CrudMetadata,
  PartialCrudMetadata,
} from '@niaratech/devcase-crud/dist/fillCrudMetadataGaps'
import type { Tenant } from './types'
export type { CrudMetadata, PartialCrudMetadata, PartialField } from '@niaratech/devcase-crud'
import type { CSSProperties } from 'react'
import type {} from '@niaratech/niara-react-components/src/controls/typeAugmentation'

declare module '@niaratech/devcase-crud/dist/types' {
  interface Field<T = any, E = any> {
    /**
     * @deprecated
     */
    custom_withNullSelector?: boolean
    /**
     * @deprecated
     */
    custom_nullSelectorLabel?
    /**
     * @deprecated
     */
    custom_nullSelectorDeselectValue?
    nullSelectorBreakLine?: boolean
    custom_groupName?: string
    custom_group?: string
    custom_apply?: { (): boolean }
    custom_test?: { (params, value): boolean }
    custom_checkTenant?: (tenant: any) => boolean
    custom_gerarPrefix?: { (entity): string }
    custom_unit?: string
    custom_prepend?
    'custom:XlsxImportView.shown.default'?
    custom_nameField?
    renderCustomColumnText?: {
      title?: {
        content: (instance: any) => string
        style: any
      }
      subTitle?: {
        content: (instance: any) => string
        style: any
      }
    }
    min?: T
    max?: T
    custom_cognito?: boolean
    searchableText?: boolean
    'custom:shallowClient'?: boolean
    clientEnabled?: boolean
    travellerEnabled?: boolean
    reservationEnabled?: boolean
    custom_hiddenFromCreateView?: boolean
    custom_hiddenFromEmbededView?: boolean
    'custom:excludeForCurrentClient'?: boolean
    placeholder?: string
    fileType?: string
    'custom:shallowCredential'?: boolean
    step?: string | number
    tagGroup?: string
    'custom:excludeForSelfFlightReservation'?: boolean
    /**
     * @deprecated
     */
    renderCustomColumn?: any
    renderCustomColumnStyle?: string | CSSProperties
    credentialTypeList?: any
    credentialType?: string
    custom_createMarkup?: boolean
    readOnly?: boolean
    custom_hiddenSelfBooking?: boolean
    /**
     * Exclusivo para tenant - marcar como true para dados que não devem ser expostos para usuários do sistema (pessoas não autenticadas do OTA Builder, por exemplo).
     * Colocar como false apenas itens que são lidos pelo front, como habilitar itens de menu, por exemplo.
     */
    'custom:excludeForCurrentTenant'?: boolean
    /**
     * Exclusivo para tenant - campos editáveis pelo master do dono da instalação, pela tela Dados cadastrais
     */
    'custom:updatableCurrentTenant'?: boolean
    /**
     * @deprecated
     */
    motiveEnabled?: boolean
    'custom:importColumn'?: boolean
    custom_hasFieldRequired?: boolean
    custom_fieldRequired?: boolean
    'custom:excludeForSelfOrder'?: boolean
    /**
     * Usar com inputType=niara-react-form/inputMultiSelect
     */
    withOptionSorter?: boolean
    formGroupDisposition?: 'VERTICAL' | 'HORIZONTAL'
    /**
     * Mostra field apenas para usuário superuser
     * ATENÇÃO: só é usado na tela de tenant!
     */
    'custom:tenantSuperuserOnly'?: boolean
    /**
     * Usuário central de reserva não pode ver field
     * ATENÇÃO: só é usado na tela de tenant!
     */
    'custom:tenantHideToCrs'?: boolean
    /**
     * Usuário buyer não pode ver field
     * ATENÇÃO: só é usado na tela de tenant!
     */
    'custom:tenantHideToBuyer'?: boolean
    /**
     * Usado por componente InputMultiSelect do niara-react-form
     */
    withChips?: boolean
    /**
     * Usado por InputIdentityProviderArray
     */
    custom_daoFilter?: any
    showNullOption?: boolean
    inputMode?
    allCaps?: boolean
  }

  interface CrudMetadata<E = any> {
    faIcon?: string
    custom_niaraapi?
    'custom:sortFields'?: string[]
    'custom:automaticSortDisabled'?: boolean
    custom_groupNames?: any
    exportColumns?: string[]
    insertButtonText?: string
    initialFilters?: Record<string, any>
    /**
     * @deprecated
     */
    renderFilters?: (props: any) => JSX.Element
    /**
     * @deprecated
     */
    renderFilterComponents?: { componentName: string; component?: any }[]
    /**
     * @deprecated
     */
    renderDetails?: (props: any) => JSX.Element
    deprecated?: boolean
    /**
     * Cria field "audit", para montar o histórico.
     * TODO: fazer automaticamente a consulta no appsync a partir desta flag. Ver appsyncModel.currencyExchangeRule.
     */
    custom_withAudit?: boolean
  }
}

export type AuditItem<T> = Partial<T> & { audit_datetime?: string; audit_username?: string; audit_name?: string }
type WithAudit<E> = E & {
  audit: AuditItem<E>[]
}

export default function extendedFillCrudMetadataGaps<E>(
  partialCrudMetadata: PartialCrudMetadata<E>
): PartialCrudMetadata<E> extends { custom_withAudit: true } ? CrudMetadata<WithAudit<E>> : CrudMetadata<E> {
  if (partialCrudMetadata.custom_withAudit == true) {
    const castenPartialCrudMetadata: PartialCrudMetadata<WithAudit<E>> = partialCrudMetadata as PartialCrudMetadata<
      WithAudit<E>
    >
    castenPartialCrudMetadata.fields = castenPartialCrudMetadata.fields.concat({
      name: 'audit',
      label: 'Histórico de alteração',
      updatable: false,
      insertable: false,
      fetchByDefault: false,
      renderForm: false,
      type: 'objectarray',
      fields: [
        {
          name: 'audit_datetime',
          type: 'datetime',
          updatable: false,
          insertable: false,
        },
        {
          name: 'audit_username',
          type: 'text',
          label: 'Atualizado por',
          updatable: false,
          insertable: false,
        },
        {
          name: 'audit_fullname',
          type: 'text',
          label: 'Atualizado por',
          updatable: false,
          insertable: false,
        },
        {
          name: 'new',
          type: 'object',
          label: 'Valores novos',
          graphQLType: partialCrudMetadata.name + '_AuditValue',
          updatable: false,
          insertable: false,
          fields: partialCrudMetadata.fields.map((f) => ({
            ...f,
            updatable: false,
            insertable: false,
            required: false,
          })),
        },
        {
          name: 'old',
          type: 'object',
          label: 'Valores anteriores',
          graphQLType: partialCrudMetadata.name + '_AuditValue',
          updatable: false,
          insertable: false,
          fields: partialCrudMetadata.fields.map((f) => ({
            ...f,
            updatable: false,
            insertable: false,
            required: false,
          })),
        },
      ],
    })
    return fillCrudMetadataGaps(castenPartialCrudMetadata) as CrudMetadata<E> // deveria aceitar CrudMetadata<WithAudit<E>>...
  }
  return fillCrudMetadataGaps(partialCrudMetadata)
}
