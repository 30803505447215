import React from 'react';
import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import { CurrentTenant, Tenant } from '..';
export default fillCrudMetadataGaps({
  label: 'Hotéis favoritos',
  labelPlural: 'Hotéis favoritos',
  name: 'FavoriteHotelsRule',
  namePlural: 'FavoriteHotelsRules',
  custom_withAudit: true,
  authorization: authorization('consultant', 'admin'),
  deletable: false,
  dynamoDB: {
    tableName: 'SpearFavoriteHotelsRule'
  },
  columns: ['id', 'name', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Nome',
    type: 'text',
    required: true
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: false,
    required: true,
    type: 'boolean'
  }, {
    name: 'properties',
    label: 'Hotéis',
    type: 'objectarray',
    fields: [{
      name: 'id',
      label: 'ID'
    }, {
      name: 'name',
      label: 'Nome'
    }],
    inputType: 'InputMultiHotelAutoComplete',
    render: e => {
      return e?.properties?.map(p => p?.name)?.join('\n');
    }
  }, {
    name: 'propertyGroups',
    label: 'Redes de Hotéis',
    type: 'objectarray',
    hint: 'Case sensitive (ou seja, diferencia letras maiúsculas e minúsculas)',
    fields: [{
      name: 'id',
      label: 'ID'
    }, {
      name: 'name',
      label: 'Nome'
    }],
    inputType: 'InputMultiPropertyGroupAutoComplete',
    render: e => {
      return e?.propertyGroups?.map(p => p?.name)?.join('\n');
    },
    custom_checkTenant: (tenant: CurrentTenant) => {
      return !tenant?.modules_crs;
    }
  }, {
    label: 'Texto de favorito',
    name: 'recommendedText',
    type: 'text',
    hint: 'Para exibição junto aos resultados. Não exibido quando existe imagem de favorito'
  }, {
    label: 'Imagem de favorito',
    inputType: 'inputPublicFile',
    name: 'recommendedBadge',
    fileType: 'images',
    custom_gerarPrefix: i => {
      return 'favoriteHotelsRules/' + i.id + '/recommendedBadge/';
    },
    render: e => {
      return <img className="img-responsive border max-width-200" src={e?.recommendedBadge} />;
    },
    hint: 'Para exibição junto aos resultados. Tamanho sugerido: 80px x 80px.'
  }],
  filter: [{
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq'
  }]
});