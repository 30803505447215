import type { Tenant } from '@niarab2c/niara-spear-crudmodel';
import React from 'react';
export type HotelSearchContextValue = {
  hotelsPath: string;
  checkoutPath: string;
  engineRuleVersion: '1' | '0';
  isClientUser: boolean;
  b2c: boolean;
  tenant: Tenant | null;
  RoomRateDebugTool?: React.FC<{
    roomRate: any;
  }>;
  showRatePlanName: boolean;
  showRatePlanPublic: boolean;
  realContext?: HotelSearchContextValue;
};
export const HotelSearchContext = React.createContext<HotelSearchContextValue>({
  b2c: true,
  isClientUser: true,
  checkoutPath: '/checkout',
  engineRuleVersion: '0',
  hotelsPath: '/hotels',
  tenant: null,
  showRatePlanName: false,
  showRatePlanPublic: false
});
export default HotelSearchContext;