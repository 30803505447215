import { printPrice } from '@niaratech/niara-js-commons';
import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import paymentCondition from '../paymentCondition';
import selfHotelReservation from '../selfHotelReservation';
export default fillCrudMetadataGaps({
  name: 'PaymentLink',
  deletable: false,
  updatable: false,
  insertable: false,
  searchable: false,
  authorization: authorization('unauthenticated', false),
  dynamoDB: {
    tableName: 'SpearPaymentLink'
  },
  fields: [{
    label: 'Data de expiração',
    name: 'expirationDatetime',
    type: 'datetime'
  }, {
    label: 'Code',
    name: 'code',
    type: 'text',
    id: true
  }, {
    label: 'Id',
    name: 'id',
    type: 'text'
  }, {
    label: 'client id',
    name: 'clientId',
    type: 'text'
  }, {
    label: 'Type',
    name: 'type',
    type: 'text',
    options: [{
      value: 'hotelReservation',
      label: 'Reserva de hotel'
    }, {
      value: 'paymentRequest',
      label: 'Solicitação de pagamento'
    }, {
      value: 'order',
      label: 'Viagem'
    }]
  }, {
    name: 'operation',
    type: 'text'
  }, {
    label: 'Localizador',
    name: 'locator',
    type: 'text'
  }, {
    label: 'Distribution Id',
    name: 'distributionId',
    type: 'text',
    render: false
  }, {
    name: 'totalPrice',
    label: 'Valor',
    type: 'float',
    render: i => i.totalPrice ? printPrice(i.totalPrice, i.currencyCode) : '-'
  }, {
    name: 'currencyCode',
    label: 'Moeda',
    type: 'text',
    renderColumn: false
  }, {
    name: 'targetCurrencyCode',
    label: 'Moeda Cobrança',
    type: 'text',
    renderColumn: false
  }, {
    name: 'installments',
    label: 'Número de parcelas',
    type: 'int',
    renderColumn: false
  }, {
    name: 'paymentConditions',
    type: 'objectarray',
    graphQLType: '[SelfPaymentPaymentCondition]',
    fields: [...paymentCondition.fields.filter(f => ['type', 'id', 'alias'].indexOf(f.name) >= 0), {
      name: 'oncc_conditions',
      label: 'Condições',
      type: 'objectarray',
      graphQLType: '[PaymentLinkOnCcCondition]',
      fields: paymentCondition.fields.find(f => f.name === 'oncc_conditions').fields.filter(f => ['cardBrands', 'maxInst', 'minInstValue', 'provider'].indexOf(f.name) >= 0)
    }]
  }, {
    name: 'hotelReservations',
    type: 'objectarray',
    fields: selfHotelReservation.fields.filter(f => !f['custom:excludeForSelfOrder'])
  }, {
    deprecated: true,
    name: 'items',
    type: 'objectarray',
    graphQLType: '[SelfPaymentItem]',
    fields: [{
      name: 'id',
      id: true,
      updatable: false
    }, {
      label: 'Tipo',
      name: 'type',
      id: true,
      updatable: false
    }, {
      name: 'locator',
      label: 'Localizador',
      updatable: false
    }, {
      label: 'Produto',
      name: 'productName',
      updatable: false
    }, {
      label: 'Produto',
      name: 'productId',
      updatable: false,
      render: false
    }, {
      label: 'Cidade',
      name: 'cityName',
      updatable: false
    }, {
      label: 'Viajante',
      name: 'travellerName',
      updatable: false
    }, {
      label: 'Viajante',
      name: 'travellerId',
      updatable: false
    }, {
      label: 'Moeda',
      name: 'currencyCode',
      updatable: false
    }, {
      label: 'Preço total',
      name: 'totalPrice',
      type: 'float',
      updatable: false,
      render: (i, f = 'totalPrice') => i[f] ? printPrice(i[f], i.currencyCode) : null
    }, {
      label: 'Data de início',
      name: 'startDate',
      type: 'date',
      updatable: false
    }, {
      label: 'Data de fim',
      name: 'endDate',
      type: 'date',
      updatable: false
    }, {
      label: 'Quantidade',
      name: 'quantity',
      type: 'float',
      updatable: false
    }, {
      label: 'Tipo de quarto',
      name: 'roomType',
      type: 'text',
      updatable: false
    }, {
      label: 'Included meal',
      name: 'includedMeal',
      type: 'text',
      updatable: false
    }, {
      label: 'Cancelamento automático habilitado',
      name: 'automaticCancellationEnabled',
      type: 'boolean',
      updatable: false
    }, {
      label: 'Data do cancelamento automático',
      name: 'automaticCancellationDate',
      type: 'datetime',
      updatable: false
    }]
  }, {
    label: 'Nome do produto',
    name: 'productName'
  }, {
    label: 'Order',
    name: 'orderId'
  }, {
    label: 'Descrição do produto',
    name: 'productDescription'
  }, {
    label: 'Termos e condições',
    name: 'productConditions',
    type: 'textarray'
  }, {
    label: 'Autenticação EMV 3DS',
    name: 'emv3dsEnabled',
    type: 'boolean'
  }, {
    // Carregado pelo appsync
    name: 'client_systemLocale',
    label: 'Idioma do cliente',
    updatable: false,
    insertable: false,
    type: 'text'
  }, {
    custom_groupName: "Comprador",
    name: 'buyer_name',
    label: 'Nome do comprador',
    updatable: false,
    insertable: false
  }, {
    custom_groupName: "Comprador",
    name: 'buyer_email',
    label: 'E-mail do comprador',
    updatable: false,
    insertable: false
  }, {
    custom_groupName: "Comprador",
    name: 'buyer_cpf',
    label: 'CPF do comprador',
    updatable: false,
    insertable: false
  }, {
    custom_groupName: "Comprador",
    name: 'buyer_dob',
    label: 'Data de nascimento do comprador',
    updatable: false,
    insertable: false
  }, {
    custom_groupName: "Comprador",
    name: 'buyer_phone',
    label: 'Número de telefone do comprador',
    updatable: false,
    insertable: false
  }, {
    name: 'engineRuleVersion',
    hint: 'carregado do hotel reservation',
    updatable: false,
    insertable: false
  }, {
    name: 'receivableAmount',
    type: 'float',
    updatable: false,
    insertable: false
  }, {
    name: 'receivablePercentage',
    type: 'float',
    updatable: false,
    insertable: false
  }]
});