import { Button as ButtonMaterial } from '@mui/material'
import styled, { css } from 'styled-components'
import { BREAKPOINT_MD, BREAKPOINT_SM } from '../../theme/ota'

export type ModalProps = {
  isOpen?: boolean
}

export type Double = {
  doubleInput?: 'left' | 'right'
}

export const Content = styled.div`
  width: 100%;

  @media (max-width: calc(${BREAKPOINT_SM})) {
    max-height: 100vh;
  }
`

export const IconInput = styled.div`
  position: relative;
`

export const Icon = styled.img<Double>`
  &&& {
    left: ${({ doubleInput }) => (doubleInput === 'right' ? '20px' : '8px')};
    height: 22px;
    pointer-events: none;
    position: absolute;
    top: 9px;
    user-select: none;
    width: 22px;
    z-index: 1;

    @media (max-width: calc(${BREAKPOINT_MD})) {
      top: 16px !important;
    }
  }
`

type InputCompleteProps = {
  doubleInput?: 'left' | 'right'
  noIcon?: boolean
}
export const InputComplete = styled.input<InputCompleteProps>`
  &&&& {
    transition: 0.15s ease-in-out;
    display: block;
    border: ${({ doubleInput, theme }) => (doubleInput ? 'none' : `1px solid ${theme?.colors?.gray4}`)};
    ${({ doubleInput, theme }) =>
      doubleInput === 'left'
        ? 'border-right: none;'
        : doubleInput === 'right'
        ? `border-left: 1px solid ${theme?.colors?.gray5};`
        : ''}
    border-radius: ${({ doubleInput }) =>
      doubleInput === 'left' ? '4px 0 0  4px' : doubleInput === 'right' ? '0 4px 4px 0' : '4px'};
    color: ${({ theme }) => theme?.colors?.text};
    flex: 1;
    font-size: 14px;
    height: 42px;
    overflow: hidden;
    margin: 0 20px 0 0;
    padding-left: ${({ doubleInput, noIcon }) => (noIcon ? '10px' : doubleInput === 'right' ? '44px' : '36px')};
    padding-right: ${({ doubleInput, noIcon }) => (noIcon ? '10px' : doubleInput === 'left' ? '20px' : '10px')};
    position: relative;
    text-shadow: none;
    width: 100%;
    background-color: ${({ theme }) => theme?.colors?.white || '#ffffff'};

    @media (max-width: ${BREAKPOINT_MD}) {
      font-size: 16px !important;
      height: 52px !important;
    }

    &.hasError {
      border-color: ${({ theme }) => theme?.colors?.error};
    }

    &:active,
    &:focus,
    &:hover {
      border: ${({ doubleInput, theme }) => (doubleInput ? 'none' : `1px solid ${theme?.colors?.gray5}`)};
      border: 1px solid ${({ theme }) => theme?.colors?.text};
    }
  }

  &:focus-visible {
    outline: none;
  }
`

export const Modal = styled.div`
  &&& {
    input {
      display: none !important;
    }

    button {
      display: none !important;
    }

    @media (max-width: calc(${BREAKPOINT_MD})) {
      background-color: ${({ theme }) => theme?.colors?.white} !important;
      display: block !important;
      height: 100vh !important;
      left: 0 !important;
      padding: 8px !important;
      position: relative !important;
      top: 0 !important;
      width: 100% !important;
      z-index: 1999 !important;

      input {
        border: 1px solid ${({ theme }) => theme?.colors?.gray5} !important;
        border-radius: 4px !important;
        color: ${({ theme }) => theme?.colors?.black} !important;
        display: block !important;
        flex: 1 !important;
        font-size: 14px !important;
        padding: 8px !important;
        position: relative !important;
        transition: 0.15s ease-in-out !important;
        width: 100% !important;
      }

      button {
        display: block !important;
      }
    }

    @media (max-width: calc(${BREAKPOINT_MD})) {
      font-size: 16px !important;
    }
  }
`

export const HideOnMobile = styled.div`
  @media (max-width: calc(${BREAKPOINT_SM})) {
    /** é pra mostrar no tablet */
    display: none !important;
  }
`
type HeroProps = {
  marginTop?: string
}

export const Hero = styled.div<HeroProps>`
  &&& {
    display: flex !important;
    gap: 30px !important;
    padding: 10px;

    @media (max-width: 500px) {
      padding: 10px 0;
      margin-top: 10px !important;
    }
  }
`

type ListDataProps = {
  width?: string
}

export const ListData = styled.div<ListDataProps>`
  &&& {
    ${({ width }) => css`
      border-radius: 5px !important;
      background-color: ${({ theme }) => theme?.colors?.white} !important;
      width: ${width || '346px'} !important;
      max-height: 350px;
      height: auto;
      overflow-y: auto;

      a {
        background-color: ${({ theme }) => theme?.colors?.white} !important;
        &.selected {
          background-color: ${({ theme }) => theme?.colors?.gray3} !important;
        }
        border: none !important;
        color: ${({ theme }) => theme?.colors?.text} !important;
        cursor: pointer !important;
        display: block !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 19px !important;
        padding: 10px !important;
        text-align: left !important;
        text-decoration: none !important;
        text-shadow: none !important;
        transition: ease-in-out 0.2s !important;
        width: 100% !important;

        &:first-child {
          border-radius: 5px 5px 0 0 !important;
        }
        &:last-child {
          border-radius: 0 0 5px 5px !important;
        }
        & + a {
          border-top: 1px solid ${({ theme }) => theme?.colors?.gray3} !important;
        }

        &:hover {
          background-color: ${({ theme }) => theme?.colors?.gray1} !important;
          color: ${({ theme }) => theme?.colors?.black} !important;
        }

        &.is-group-option {
          background-color: ${({ theme }) => theme?.colors?.gray3} !important;
          box-shadow: inset 0px -1px 0px 0px ${({ theme }) => theme?.colors?.white || '#ffffff'} !important;
        }
      }

      &::-webkit-scrollbar {
        border-width: 5px;
        width: 5px;
      }

      &::-webkit-scrollbar-track-piece {
        background-color: transparent;
        border-color: #ffffff;
        border-width: 2px 2px 2px 2px;
        border-style: solid;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #c5c5c5;
        border-color: #c5c5c5;
        border-style: solid;
        border-width: 1px 8px 1px 8px;
      }

      @media (max-width: calc(${BREAKPOINT_MD})) {
        border: none !important;
        box-shadow: none !important;
        width: 100% !important;
      }
      @media (max-width: calc(${BREAKPOINT_MD})) {
        width: 100% !important;
      }
    `}
  }
`

export const NotFound = styled.div`
  &&& {
    height: 60px !important;
    padding: 6px !important;
    width: 100% !important;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      font-size: 12px !important;
      margin: 0 !important;
    }

    @media (max-width: 500px) {
      height: 54px !important;
      padding: 4px 8px !important;
    }
  }
`

export const GroupOptionDivider = styled.div`
  align-items: center;
  display: flex;
  gap: 5px;
  width: 100%;
`

export const Button = styled(ButtonMaterial)`
  padding: 8px !important;
`
