import React from 'react';
import type { LegacyPaymentOption, PaymentOption } from '@niarab2c/frontend-commons/src/types/hotels';
import { HotelCartProps, useTheme } from '../../..';
import Price from '../../Price';
import { PaymentConditions } from '../../../HotelDetails';
import * as S from './styles';
type CartTotal = {
  value: number;
  currency: string;
  taxes: number;
};
export type CartTotalProps = {
  cartTotal: CartTotal;
  paymentInfo?: string;
  comparison?: HotelCartProps['comparison'];
  paymentOptions?: LegacyPaymentOption[] | PaymentOption[];
  onCheckout: () => void;
  onCreateQuotation?: () => void;
};
const CartTotal: React.FC<CartTotalProps> = ({
  cartTotal,
  paymentOptions,
  onCheckout,
  onCreateQuotation
}) => {
  const theme = useTheme();
  const emptyCart = Object.keys(cartTotal).length === 0;
  return <S.Content>
      {cartTotal && <S.Info>
          <S.Total>
            <h5>Total</h5>
            <S.ContainerPriceInTotal>
              <Price value={cartTotal.value} currency={cartTotal.currency} currencyFontWeight="normal" priceCoinFontWeight="bold" fractionFontWeight="bold" currencySize={13} priceCoinSize={20} decimalSize={13} fractionSize={13} color={theme.colors?.priceColor} dataTestId="cart-total-price" />
            </S.ContainerPriceInTotal>
          </S.Total>
          <S.Taxes>Inclui impostos e taxas</S.Taxes>
          <S.Conditions>
            {paymentOptions && <PaymentConditions paymentOptions={paymentOptions} textSize={12} textColor={theme.colors.text} fontWeight={'600'} showStartIcon maxPaymentToDisplay={1}
        //No carrinho do hotel coloca o tooltip na esquerda para não criar um overflow
        placementTooltip="left" />}
          </S.Conditions>
        </S.Info>}

      {onCheckout && <S.Button color="success" variant="contained" disabled={emptyCart} onClick={onCheckout} data-testid="cart-button-reservation">
          Reservar Agora
        </S.Button>}

      {onCreateQuotation && <S.Link as="button" onClick={onCreateQuotation}>
          Gerar cotação
        </S.Link>}
    </S.Content>;
};
export default CartTotal;