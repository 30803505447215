import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Erro de integração',
  labelPlural: 'Erros de integração',
  name: 'IntegrationEvent',
  insertable: false,
  updatable: false,
  deletable: false,
  searchable: true,
  authorization: authorization('consultant'),
  dynamoDB: {
    tableName: 'SpearIntegrationEvent',
    indexName: 'tenantId-date-index',
    indexedProp: 'date',
    scanIndexBackwards: true
  },
  columnNames: ['id', 'credentialType', 'credentialAlias', 'date', 'status', 'clientId', 'typeId'],
  fields: [{
    name: 'id',
    id: true,
    updatable: false,
    renderColumn: false
  }, {
    label: 'Tipo de credencial',
    name: 'credentialType',
    updatable: false
  }, {
    name: 'clientId',
    label: 'Id Cliente',
    updatable: false
  }, {
    name: 'typeId',
    label: 'Registro de referência',
    updatable: false
  }, {
    name: 'orderId',
    label: 'Código da Viagem',
    updatable: false
  }, {
    name: 'date',
    label: 'Data do erro',
    type: 'datetime',
    updatable: false
  }, {
    name: 'credentialId',
    label: 'id da credencial',
    updatable: false
  }, {
    name: 'credentialAlias',
    label: 'Nome Credencial',
    updatable: false
  }, {
    name: 'errorMessages',
    label: 'Mensagens de erro',
    type: 'textarray',
    updatable: false
  }, {
    name: 'status',
    label: 'Status',
    updatable: false,
    options: [{
      label: 'Falha',
      value: 'ERROR'
    }, {
      label: 'Resolvido',
      value: 'RESOLVED'
    }]
  }, {
    name: 'type',
    label: 'Tipo',
    updatable: false,
    options: [{
      label: 'CRM',
      value: 'CRM'
    }, {
      label: 'Backoffice',
      value: 'BACKOFFICE'
    }, {
      label: 'Fidelidade',
      value: 'LOYALTY'
    }, {
      label: 'Proxy',
      value: 'PROXY'
    }]
  }],
  filter: [{
    field: 'date',
    op: 'ge'
  }, {
    field: 'date',
    op: 'le'
  }, {
    field: 'type',
    op: 'eq'
  }]
});