import { currentPersonDAO, currentUserDAO } from '@niarab2c/frontend-commons/src/daos';
import niaraAuth, { setNiaraAuth } from '@niarab2c/frontend-commons/src/security/niaraAuth';
import { trackUserId } from '@niarab2c/frontend-commons/src/util/analyticsEvents';
import callApi from '@niarab2c/frontend-commons/src/util/callApi';
import type { AuthenticationRule, CurrentUser, IdentityProvider, Person, ShallowClient, Tenant, User as UserCrudmodel } from '@niarab2c/niara-spear-crudmodel';
import { PayloadAction, createAsyncThunk, createSlice, isAnyOf, unwrapResult } from '@reduxjs/toolkit';
import type { CognitoUserSession } from 'amazon-cognito-identity-js';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { BaseRootState, CompleteRootState } from '../base';
import * as Loyalty from './loyalty';
import * as Core from './core';
export type User = {
  sub: string;
  lastName: string;
  firstName: string;
  email: string;
  cpf?: string;
  phoneNumber?: string;
  clientId: string;
  username: string;
  personId?: string;
  userId?: string;
  allowedHotels?: UserCrudmodel['allowedHotels'];
} & Partial<Omit<Person, 'id'>>;
type CompleteAuthenticationResponse = {
  niaraAuthAccessToken: string;
  user: User;
  b2c: boolean;
};
type NewPasswordChallengeCallback = (newPassword, confirmationCode?: string) => Promise<CompleteAuthenticationResponse | void>;
type RequiredIdentityProviderWithAuthenticationRuleId = Pick<IdentityProvider, 'oauthAuthorizationEndpoint' | 'oauthClientId' | 'oauthAuthorizedScopes' | 'type' | 'userInputType' | 'id' | 'passwordRecoveryUrl' | 'signUpUrl' | 'signUpEnabled' | 'signUpSuccessTitle' | 'signUpSuccessMessage' | 'name'> & {
  authenticationRuleId: string;
};
export interface AuthenticationState {
  init?: boolean;
  niaraAuthAccessToken?: string;
  user?: User;
  showSignInForm?: boolean;
  showSignUpForm?: boolean;
  authenticationRule?: Omit<AuthenticationRule, 'identityProviders'>;
  identityProviders?: Array<RequiredIdentityProviderWithAuthenticationRuleId>;
  b2c?: boolean;
  isClientUser?: boolean;
  hasWeakPersonId?: boolean;
  /**
   * Função armazenada no state após tentativa de login que exige a troca de senha.
   * Usada apenas para NIARA_ACCOUNT.
   * @param newPassword
   * @returns
   */
  newPasswordChallengeCallback?: NewPasswordChallengeCallback;
  newPasswordNeedConfirmationCode?: boolean;
}
const initialState: AuthenticationState = {};
export const completeAuthenticationWithNiaraAuthAccessToken = createAsyncThunk<CompleteAuthenticationResponse, {
  niaraAuthAccessToken: string;
}, {
  state: CompleteRootState;
}>('authentication/_completeAuthenticationWithNiaraAuthAccessToken', async ({
  niaraAuthAccessToken
}, {
  dispatch,
  getState
}) => {
  const b2c = getState().authentication.b2c;
  await niaraAuth.setAccessToken(niaraAuthAccessToken);
  const parsedToken = niaraAuth.getParsedToken();
  const identityId = parsedToken?.identityId;
  if (identityId) {
    trackUserId(identityId);
  }
  const personId = parsedToken.personId || parsedToken.weakPersonId;
  const person = personId ? await currentPersonDAO.load({
    id: personId
  }).catch(() => null) : undefined;
  const user: User = parsedToken.username || parsedToken.personId || parsedToken.weakPersonId ? {
    sub: parsedToken?.sub,
    firstName: parsedToken.given_name || parsedToken.name || parsedToken.username || parsedToken.email,
    lastName: parsedToken.family_name,
    email: parsedToken.email,
    phoneNumber: parsedToken.phone_number,
    cpf: parsedToken.cpf,
    username: parsedToken.username,
    clientId: parsedToken.clientId,
    personId,
    ...(person || {})
  } : null;
  if (user) {
    dispatch(Loyalty.refreshBalance());
  }
  return {
    niaraAuthAccessToken,
    user,
    b2c
  };
});
type INiaraAuth = typeof niaraAuth;
export const setupEmbeddedApp = createAsyncThunk<CompleteAuthenticationResponse, {
  niaraAuth: INiaraAuth;
  tenant: Tenant;
  client: ShallowClient;
}, {
  state: CompleteRootState;
}>('authentication/embeddedAppSetup', async ({
  niaraAuth,
  tenant,
  client
}, {
  getState,
  dispatch
}) => {
  const b2c = getState().authentication.b2c;
  // assume que está autenticado
  setNiaraAuth(niaraAuth);
  dispatch(Core.setState({
    tenant: tenant,
    tenantId: tenant?.tenantId,
    clientId: client?.id,
    client
  }));
  const parsedToken = niaraAuth.getParsedToken();
  const existingAccessToken = niaraAuth.getAccessToken();
  const currentUser: CurrentUser | null = await currentUserDAO.load({
    id: parsedToken?.username
  }).catch(() => null);
  const user: User = parsedToken.username || parsedToken.personId || parsedToken.weakPersonId ? {
    sub: parsedToken?.sub,
    firstName: parsedToken.given_name || parsedToken.name || parsedToken.username || parsedToken.email,
    lastName: parsedToken.family_name,
    email: parsedToken.email,
    phoneNumber: parsedToken.phone_number,
    cpf: parsedToken.cpf,
    username: parsedToken.username,
    clientId: parsedToken.clientId,
    personId: null,
    allowedHotels: currentUser?.allowedHotels
  } : null;
  return {
    niaraAuthAccessToken: existingAccessToken,
    user,
    b2c
  };
});

/**
 * Restaura o token gravado no browser ou gera token não autenticado
 */
export const initAuthentication = createAsyncThunk<CompleteAuthenticationResponse, void | {
  clientId?: string;
  landingPageLocator?: string;
}, {
  state: CompleteRootState;
}>('authentication/initAuthentication', async (params, {
  getState,
  dispatch
}) => {
  const forcedClientId = params && params.clientId;
  const landingPageLocator = params && params.landingPageLocator;
  const rootState: CompleteRootState = getState();
  const b2c = rootState.authentication?.b2c;
  const tenantId = rootState.core.tenantId;
  // verifica se já tem token do niaraAuth válido gravado no localStorage
  const existingAccessToken = await niaraAuth.getAccessToken();
  if (existingAccessToken && niaraAuth.authenticationParams?.tenantId == tenantId) {
    // aproveita o access token gravado

    const parsedToken = niaraAuth.getParsedToken();
    // faz tracking do userId
    const identityId = niaraAuth.authenticationState?.authenticationResponse?.identityId;
    if (identityId) {
      trackUserId(identityId);
    }
    const personId = parsedToken.personId || parsedToken.weakPersonId;
    const person = personId ? await currentPersonDAO.load({
      id: personId
    }).catch(() => null) : undefined;
    const user: User = parsedToken.username || parsedToken.personId || parsedToken.weakPersonId ? {
      sub: parsedToken?.sub,
      firstName: parsedToken.given_name || parsedToken.name || parsedToken.username || parsedToken.email,
      lastName: parsedToken.family_name,
      email: parsedToken.email,
      phoneNumber: parsedToken.phone_number,
      cpf: parsedToken.cpf,
      username: parsedToken.username,
      clientId: forcedClientId ?? parsedToken.clientId,
      personId,
      ...(person || {})
    } : null;
    if (user && b2c) {
      dispatch(Loyalty.refreshBalance());
    }
    return {
      niaraAuthAccessToken: existingAccessToken,
      user,
      b2c
    };
  } else {
    // gera access token não autenticado
    const niaraAuthResponse = landingPageLocator ? await niaraAuth.authenticateAsUnauthenticatedForLandingPage(landingPageLocator) : tenantId ? await niaraAuth.authenticateAsUnauthenticated(tenantId) : undefined;

    // faz tracking do userId
    const identityId = niaraAuthResponse?.identityId;
    if (identityId) {
      trackUserId(identityId);
    }
    return {
      niaraAuthAccessToken: niaraAuthResponse.accessToken,
      user: null,
      b2c
    };
  }
});

/**
 * Carrega a regra de autenticação para o contexto (state.identityProviders e state.authenticationRule).
 */
export const loadAuthenticationRule = createAsyncThunk<Pick<AuthenticationState, 'authenticationRule' | 'identityProviders'>, void, {
  state: CompleteRootState;
}>('authentication/loadAuthenticationRule', async (ignore, {
  getState
}) => {
  const rootState = getState();
  const clientId = rootState.core.clientId;
  const landingPageId = rootState.storefrontConfig?.storefront?.landingPageId;
  const storefrontId = rootState.storefrontConfig?.storefront?.id;
  return await callApi('niara-spear-commons', `/genericRuleResolver/authenticationRule/details`, 'get', {
    params: {
      clientId,
      landingPageId,
      storefrontId
    } // TODO complementar o contexto com mais dados (hotel, data, etc)
  }).then(r => {
    const {
      identityProviders,
      ...authenticationRule
    } = r?.authenticationRule ?? r; // rever com mudança do endpoint para versão após 13/10/2022
    const authenticationRuleId = authenticationRule.id ?? authenticationRule.authenticationRuleId; // rever com mudança do entpoint
    authenticationRule.id = authenticationRuleId; // rever com mudança do entpoint
    return {
      identityProviders: identityProviders?.map(provider => ({
        ...provider,
        authenticationRuleId: authenticationRule.id
      })),
      authenticationRule
    };
  });
});

/**
 * Autentica o sistema a partir de credenciais recebidas do parceiro (Allpoints, HotTravel)
 */
export const authenticateWithIdentityProvider = createAsyncThunk<CompleteAuthenticationResponse | {
  newPasswordChallengeCallback?: NewPasswordChallengeCallback;
  newPasswordNeedConfirmationCode: boolean;
}, {
  code?: string;
  username?: string;
  password?: string;
  accessToken?: string;
  identityProvider: Pick<IdentityProvider, 'id' | 'type'>;
  authenticationRuleId;
}, {
  state: CompleteRootState;
}>('authentication/authenticateWithIdentityProvider', async ({
  code,
  identityProvider,
  authenticationRuleId,
  username,
  password,
  accessToken
}, {
  getState,
  dispatch
}) => {
  const rootState = getState();
  const clientId = rootState.core.clientId;
  const landingPageId = rootState.storefrontConfig?.storefront?.landingPageId;
  const basePath = rootState.core.basePath;
  if (!identityProvider?.id) {
    throw new Error('Invalid call without identityProvider');
  }
  if (!authenticationRuleId) {
    throw new Error('Invalid call without authenticationRuleId');
  }
  if (identityProvider?.type == 'NIARA_ACCOUNT') {
    // Niara Account - fazer login usando o fluxo SRP direto
    // no user pool do niara-account

    // Passo 1: Buscar o username real do usuário
    const cognitoUsername = await callApi('niara-spear-niara-account', '/getRealUsername', 'post', {
      body: {
        login: username
      }
    }).then(r => r.username);
    if (!cognitoUsername) {
      throw new Error('Erro');
    }

    // Passo 2: Faz o login direto
    const userPoolId = process.env.NIARA_SPEAR_NIARA_ACCOUNT_USERPOOL_ID;
    const userPoolClientId = process.env.NIARA_SPEAR_NIARA_ACCOUNT_USERPOOL_CLIENT_ID;
    const userPool = new CognitoUserPool({
      ClientId: userPoolClientId,
      UserPoolId: userPoolId
    });
    const cognitoUser = new CognitoUser({
      Pool: userPool,
      Username: cognitoUsername
    });
    const {
      cognitoUserSession,
      newPasswordChallengeCallback
    } = await new Promise<{
      cognitoUserSession?: CognitoUserSession;
      newPasswordChallengeCallback?: NewPasswordChallengeCallback;
    }>((res, rej) => {
      cognitoUser.authenticateUser(new AuthenticationDetails({
        Username: cognitoUsername,
        Password: password
      }), {
        onSuccess: cognitoUserSession => {
          res({
            cognitoUserSession
          });
        },
        onFailure: err => {
          rej(err);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          // usuário precisa trocar a senha

          if (requiredAttributes?.length > 0) {
            // Cognito configurado para exigir atributos. Cognito do niara-account está configurado para não precisar
            rej(new Error('Not implemented: requiredAttributes?.length > 0'));
            return;
          }

          // Função que será guardada no state e será chamada ao dar dispatch em authentication/completeNewPassword
          const newPasswordChallengeCallback: NewPasswordChallengeCallback = async newPassword => {
            const cognitoUserSession = await new Promise<CognitoUserSession>((res, rej) => {
              cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
                onSuccess: (cognitoUserSession, userConfirmationNecessary) => {
                  if (userConfirmationNecessary) {
                    rej(new Error('Not implemented: userConfirmationNecessary'));
                  }
                  res(cognitoUserSession);
                },
                onFailure: err => {
                  rej(err);
                }
              });
            });

            // fecha o modal de troca de password
            dispatch(dismissNewPassword());

            // Passo 3: gera o token com o cognitoUserSession
            const payload = {
              clientId,
              landingPageId,
              identityProviderId: identityProvider?.id,
              authenticationRuleId,
              secretAccessToken: cognitoUserSession.getAccessToken().getJwtToken(),
              secretIdToken: cognitoUserSession.getIdToken().getJwtToken()
            };
            const niaraAuthAccessToken = await callApi('niara-spear-single-sign-on', '/generateToken', 'post', {
              body: payload
            }).then(r => r?.accessToken);
            return await dispatch(completeAuthenticationWithNiaraAuthAccessToken({
              niaraAuthAccessToken
            })).then(unwrapResult);
          };
          res({
            newPasswordChallengeCallback
          });
        }
      });
    });
    if (newPasswordChallengeCallback) {
      return {
        newPasswordChallengeCallback,
        newPasswordNeedConfirmationCode: false
      };
    }
    // Passo 3: gera o token com o cognitoUserSession
    const payload = {
      clientId,
      landingPageId,
      identityProviderId: identityProvider.id,
      authenticationRuleId,
      secretAccessToken: cognitoUserSession.getAccessToken().getJwtToken(),
      secretIdToken: cognitoUserSession.getIdToken().getJwtToken()
    };
    const niaraAuthAccessToken = await callApi('niara-spear-single-sign-on', '/generateToken', 'post', {
      body: payload
    }).then(r => r?.accessToken);
    return await dispatch(completeAuthenticationWithNiaraAuthAccessToken({
      niaraAuthAccessToken
    })).then(unwrapResult);
  } else {
    const redirectUri = [window.location.protocol, `//`, window.location.hostname, window.location.port ? ':' + window.location.port : '', basePath ? '/' + basePath : '', '/auth'].filter(Boolean).join('');
    const payload = {
      clientId,
      landingPageId,
      identityProviderId: identityProvider?.id,
      authenticationRuleId,
      secretRedirectURI: redirectUri,
      secretCode: code,
      secretAccessToken: accessToken
    };
    if (code) {
      Object.assign(payload, {
        secretCode: code
      });
    } else {
      Object.assign(payload, {
        username,
        secretPassword: password
      });
    }
    const niaraAuthAccessToken = await callApi('niara-spear-single-sign-on', '/generateToken', 'post', {
      body: payload
    }).then(r => r?.accessToken);
    return await dispatch(completeAuthenticationWithNiaraAuthAccessToken({
      niaraAuthAccessToken
    })).then(unwrapResult);
  }
});

/**
 *
 */
export const niaraAccountResetPassword = createAsyncThunk<{
  newPasswordChallengeCallback?: NewPasswordChallengeCallback;
  newPasswordNeedConfirmationCode: boolean;
}, {
  identityProvider: Pick<IdentityProvider, 'id' | 'type'>;
  username: string;
}, {
  state: CompleteRootState;
}>('authentication/niaraAccountResetPassword', async ({
  identityProvider,
  username
}, {
  getState,
  dispatch
}) => {
  if (identityProvider?.type == 'NIARA_ACCOUNT') {
    // Passo 1: Buscar o username real do usuário
    const cognitoUsername = await callApi('niara-spear-niara-account', '/getRealUsername', 'post', {
      body: {
        login: username
      }
    }).then(r => r.username);
    if (!cognitoUsername) {
      throw new Error('Erro');
    }

    // Passo 2: Faz o login direto
    const userPoolId = process.env.NIARA_SPEAR_NIARA_ACCOUNT_USERPOOL_ID;
    const userPoolClientId = process.env.NIARA_SPEAR_NIARA_ACCOUNT_USERPOOL_CLIENT_ID;
    const userPool = new CognitoUserPool({
      ClientId: userPoolClientId,
      UserPoolId: userPoolId
    });
    const cognitoUser = new CognitoUser({
      Pool: userPool,
      Username: cognitoUsername
    });
    return await new Promise((res, rej) => {
      cognitoUser.forgotPassword({
        onSuccess: data => {},
        onFailure: error => {
          rej(error);
        },
        inputVerificationCode: data => {
          const newPasswordChallengeCallback: NewPasswordChallengeCallback = async (newPassword, confirmationCode) => {
            return new Promise((res, rej) => {
              cognitoUser.confirmPassword(confirmationCode, newPassword, {
                onSuccess: success => {
                  // fecha o modal de troca de password
                  dispatch(dismissNewPassword());
                  res();
                },
                onFailure: err => {
                  rej(err);
                }
              });
            });
          };
          res({
            newPasswordChallengeCallback,
            newPasswordNeedConfirmationCode: true
          });
        }
      });
    });
  }
});

/**
 * Usado para completar a troca de password - seja por solicitação do usuário, seja por fazer o
 * login pela primeira vez.
 * Também usado para resetar senha
 */
export const completeNewPasswordChallenge = createAsyncThunk<CompleteAuthenticationResponse | void, {
  newPassword: string;
  confirmationCode?: string;
}, {
  state: CompleteRootState;
}>('authentication/completeNewPasswordChallenge', async ({
  newPassword,
  confirmationCode
}, {
  getState
}) => {
  const rootState = getState();
  const newPasswordChallengeCallback = rootState?.authentication?.newPasswordChallengeCallback;
  if (newPasswordChallengeCallback) {
    return await newPasswordChallengeCallback(newPassword, confirmationCode);
  }
  return null;
});
export const signOut = createAsyncThunk<Partial<AuthenticationState>, void, {
  state: BaseRootState;
}>('authentication/signOut', async (ignore, {
  getState,
  dispatch
}) => {
  const tenantId = getState().core?.tenant?.tenantId;
  // gera access token não autenticado
  const niaraAuthResponse = await niaraAuth.authenticateAsUnauthenticated(tenantId);
  // faz tracking do userId
  const identityId = niaraAuthResponse?.identityId;
  if (identityId) {
    trackUserId(identityId);
  }
  return {
    niaraAuthAccessToken: niaraAuthResponse.accessToken,
    user: null
  };
});
const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setShowSignInForm: function (state, action: PayloadAction<boolean>) {
      state.showSignInForm = action.payload;
    },
    setShowSignUpForm: function (state, action: PayloadAction<boolean>) {
      state.showSignUpForm = action.payload;
    },
    dismissNewPassword: function (state) {
      state.newPasswordChallengeCallback = null;
    },
    forceB2C: function (state, action: PayloadAction<boolean>) {
      state.b2c = action.payload;
    },
    forceClientUser: function (state, action: PayloadAction<boolean>) {
      state.isClientUser = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(initAuthentication.pending, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          init: false
        };
      } else {
        return state;
      }
    });
    builder.addCase(signOut.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          ...action.payload
        };
      } else {
        return state;
      }
    });
    builder.addCase(loadAuthenticationRule.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          identityProviders: action.payload.identityProviders,
          authenticationRule: action.payload.authenticationRule
        };
      }
      return state;
    });
    builder.addCase(authenticateWithIdentityProvider.pending, state => {
      return {
        ...state
      };
    });
    builder.addCase(authenticateWithIdentityProvider.fulfilled, (state, action) => {
      if ('newPasswordChallengeCallback' in action.payload) {
        state.newPasswordChallengeCallback = action.payload.newPasswordChallengeCallback;
        state.newPasswordNeedConfirmationCode = action.payload.newPasswordNeedConfirmationCode;
        return state;
      }
    });
    builder.addCase(niaraAccountResetPassword.fulfilled, (state, action) => {
      if ('newPasswordChallengeCallback' in action.payload) {
        state.newPasswordChallengeCallback = action.payload.newPasswordChallengeCallback;
        state.newPasswordNeedConfirmationCode = action.payload.newPasswordNeedConfirmationCode;
        return state;
      }
    });
    builder.addMatcher(isAnyOf(completeAuthenticationWithNiaraAuthAccessToken.fulfilled, initAuthentication.fulfilled, setupEmbeddedApp.fulfilled), (state, action) => {
      if (action.payload) {
        const user = action.payload.user;
        const isClientUser = user?.clientId != null;
        const parsedToken = niaraAuth.getParsedToken();
        return {
          ...state,
          ...action.payload,
          init: true,
          isClientUser,
          showSignInForm: false,
          showSignUpForm: false,
          hasWeakPersonId: Boolean(parsedToken?.weakPersonId)
        };
      } else {
        return state;
      }
    });
  }
});
export default authenticationSlice.reducer;
export const {
  setShowSignInForm,
  setShowSignUpForm,
  dismissNewPassword,
  forceB2C,
  forceClientUser
} = authenticationSlice.actions;