const loadScript = function (src) {
  const tag = document.createElement('script');
  tag.async = false;
  tag.src = src;
  document.getElementsByTagName('body')[0].appendChild(tag);
};
let recaptchaScriptPromise;
let __recaptchaScriptPromise_res;
function loadRecaptchaScript() {
  if (!window['grecaptcha'] && !recaptchaScriptPromise) {
    // loadScript(`//www.google.com/recaptcha/api.js?onload=_recaptchaScriptLoaded`)
    // recaptchaScriptPromise = new Promise((res, rej) => {
    //     __recaptchaScriptPromise_res = res;
    // })
    loadScript(`//www.google.com/recaptcha/api.js?onload=_recaptchaScriptLoaded&render=${process.env.NIARA_APP_RECAPTCHA_SITE_KEY}`);
    recaptchaScriptPromise = new Promise((res, rej) => {
      __recaptchaScriptPromise_res = res;
    });
  }
  return recaptchaScriptPromise;
}
const recaptchaWidgetId = process.env.NIARA_APP_RECAPTCHA_SITE_KEY;
function _recaptchaScriptLoaded() {
  // let div = document.createElement('div');
  // div.classList.add('d-print-none')
  // div.classList.add('mt-5')
  // div.classList.add('ml-2')
  // let container = document.getElementsByTagName('footer')[0] || document.getElementsByTagName('body')[0]
  // container.appendChild(div)

  // recaptchaWidgetId = window.grecaptcha.render(
  //     div,
  //     {
  //         "sitekey": process.env.NIARA_APP_RECAPTCHA_SITE_KEY,
  //         "size": "invisible",
  //         "badge": "inline"
  //     })
  __recaptchaScriptPromise_res(recaptchaWidgetId);
}
window['_recaptchaScriptLoaded'] = _recaptchaScriptLoaded;
async function getRecaptchaToken(action): Promise<string> {
  if (!window['grecaptcha']) {
    await loadRecaptchaScript();
  }
  const grecaptcha = window['grecaptcha'];
  return await new Promise((res, rej) => {
    grecaptcha.ready(async () => {
      const token = await grecaptcha.execute(recaptchaWidgetId, action);
      res(token);
    });
  });
}
export { loadRecaptchaScript, getRecaptchaToken };
export default getRecaptchaToken;