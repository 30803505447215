import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { nanoid } from 'nanoid';
import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import paymentCondition from '../paymentCondition';
import { formatDate } from '@niaratech/niara-js-commons';
export default fillCrudMetadataGaps({
  label: "Cotação",
  labelPlural: "Cotações",
  name: 'Quotation',
  updatable: true,
  insertable: true,
  insertButtonText: "Nova Cotação",
  faIcon: 'search-dollar',
  authorization: authorization('clientConsultant', 'clientConsultant'),
  'custom:sortFields': ['date desc'],
  dynamoDB: {
    tableName: 'SpearQuotation',
    indexName: 'tenantId-date-index-5',
    indexedProp: 'date',
    scanIndexBackwards: true,
    indexes: [{
      indexName: 'tenantId-lostReservationId-index-2',
      // ver packages\niara-spear-serverless\dynamodb-tables\quotation\template.yml
      indexedProp: 'lostReservationId'
    }, {
      indexName: 'tenantId-quotationToken-index',
      indexedProp: 'quotationToken'
    }]
  },
  columns: ['id', 'date', 'buyerName', 'buyerEmail', 'clientName', 'clientId', 'responsibleName', 'responsible', 'expirationDatetime', 'originalExpirationDatetime', 'orderDate',
  // 'orderId',
  'status', 'lastAction', 'lastActionDatetime', 'hotelIds'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    insertable: false,
    updatable: false,
    id: true
  }, {
    name: 'quotationToken',
    label: 'Token para conversão da reserva',
    required: true,
    updatable: false,
    initialize: () => nanoid(),
    render: false
  }, {
    name: 'tokenExpirationTime',
    label: 'Data de expiração do link',
    type: 'datetime',
    updatable: false,
    render: false,
    initialize: () => addDays(new Date(), 3).toISOString()
  }, {
    name: 'orderDate',
    label: 'Data de conversão da cotação',
    type: 'datetime',
    insertable: false,
    updatable: false,
    render: false
  }, {
    name: 'orderId',
    label: 'Viagem'
    // deprecated: true,
  }, {
    name: 'reservationDate',
    label: 'Data de conversão da cotação',
    type: 'datetime',
    insertable: false,
    updatable: false,
    render: false
  }, {
    name: 'reservationId',
    label: 'Id da reserva'
  }, {
    name: 'reservationLocator',
    label: 'Localizador da reserva'
  }, {
    name: 'toEmail',
    // DEPRECATED
    label: 'Destinatário',
    updatable: false,
    render: false,
    type: 'text'
  }, {
    name: 'sentEmails',
    label: 'Destinatários',
    updatable: false,
    render: false,
    type: 'textarray',
    initialize: () => []
  }, {
    name: 'clientId',
    label: 'Id do cliente',
    renderColumn: false,
    required: true,
    updatable: false,
    render: false
  }, {
    name: 'quotationDescription',
    label: 'Descrição',
    renderColumn: false,
    required: false,
    updatable: true,
    type: 'text',
    inputType: 'textarea'
  }, {
    name: 'additionalInformations',
    label: 'Informações adicionais',
    renderColumn: false,
    required: false,
    updatable: true,
    type: 'objectarray',
    graphQLType: '[NestedQuotationAdditionalInformations]',
    graphQLInputType: '[NestedQuotationAdditionalInformationsInput]',
    fields: [{
      name: 'title',
      label: 'Título',
      type: 'text',
      required: true
    }, {
      name: 'description',
      label: 'Descrição',
      type: 'text',
      required: true,
      inputType: 'wysiwyg'
    }]
  }, {
    name: 'clientName',
    label: 'Cliente',
    required: true,
    updatable: false
  }, {
    name: 'hotelGuestPhone',
    label: 'Telefone do cliente',
    required: false,
    updatable: true,
    render: false,
    inputType: 'country-phone-selector'
  }, {
    name: 'hotelReservationId',
    label: 'Reserva gerada',
    type: 'textarray',
    insertable: false,
    updatable: false
  }, {
    name: 'date',
    label: 'Data da cotação',
    insertable: false,
    updatable: false,
    type: 'datetime',
    renderCustomColumnText: {
      title: {
        content: props => `${formatDate(props?.row?.date, 'dd/MM/yyyy HH:mm')}`,
        style: {}
      },
      subTitle: {
        content: props => props?.row?.expirationDatetime || props?.row?.originalExpirationDatetime ? `${"Expira em "}${formatDate(props?.row?.expirationDatetime || props?.row?.originalExpirationDatetime, 'dd/MM/yyyy HH:mm')}` : '',
        style: {}
      }
    }
  }, {
    name: 'responsible',
    label: 'Responsável',
    updatable: true,
    renderColumn: false,
    renderForm: false
  }, {
    name: 'responsibleEmail',
    label: 'Responsável (e-mail)',
    updatable: true,
    renderColumn: false,
    renderForm: false,
    render: false
  }, {
    name: 'responsibleName',
    label: 'Responsável',
    updatable: true,
    renderColumn: i => i.responsibleName || i.createdByName,
    renderForm: false,
    render: false
  }, {
    name: 'createdByName',
    label: 'Criado por',
    updatable: false,
    render: false
  }, {
    name: 'createdBy',
    label: 'Criado por',
    updatable: false,
    render: false
  }, {
    name: 'startDate',
    label: 'Data de início da viagem',
    type: 'date',
    updatable: false,
    render: false
  }, {
    name: 'endDate',
    label: 'Data de fim da viagem',
    type: 'date',
    updatable: false,
    render: false
  }, {
    name: 'buyerName',
    label: 'Hóspede',
    renderCustomColumnText: {
      title: {
        content: props => props?.row?.buyerName,
        style: {}
      },
      subTitle: {
        content: props => props?.row?.buyerEmail,
        style: {}
      }
    }
  }, {
    name: 'buyerFirstName',
    label: 'Nome do hóspede'
  }, {
    name: 'buyerLastName',
    label: 'Sobrenome do hóspede'
  }, {
    name: 'buyerPhone',
    label: 'Telefone do hóspede',
    type: 'text',
    renderColumn: false,
    inputType: 'country-phone-selector'
  }, {
    name: 'buyerEmail',
    label: 'Email do hóspede',
    renderColumn: false
  }, {
    name: 'buyerCpf',
    label: 'CPF do hóspede'
  }, {
    name: 'buyerPassport',
    label: 'Passaporte do hóspede'
  }, {
    name: 'buyerDob',
    label: 'Data de nascimento do hóspede'
  }, {
    name: 'buyerCountry',
    label: 'País'
  }, {
    name: 'buyerAddressCity',
    label: 'Cidade'
  }, {
    name: 'buyerAddressState',
    label: 'Estado'
  }, {
    name: 'buyerAddressDistrict',
    label: 'Bairro'
  }, {
    name: 'buyerAddressNumber',
    label: 'Número'
  }, {
    name: 'buyerAddressZipCode',
    label: 'CEP'
  }, {
    name: 'buyerAddressStreet',
    label: 'Rua'
  }, {
    name: 'buyerAddressComplement',
    label: 'Complemento'
  }, {
    name: 'buyerNationality',
    label: 'Nacionalidade',
    render: false
  }, {
    name: 'personId',
    label: 'Cliente B2C'
  }, {
    name: 'lostReservationId',
    label: 'Id da Reserva perdida'
  }, {
    name: 'enabled',
    label: 'Ativo',
    type: 'boolean',
    render: false
  }, {
    name: 'items',
    label: 'Itens',
    type: 'objectarray',
    render: false,
    graphQLType: '[QuotationItem]',
    graphQLInputType: '[QuotationItemInput]',
    fields: [{
      name: 'type',
      options: [{
        value: 'roomRate',
        label: 'Quarto de hotel'
      }]
    }, {
      name: 'hotel_id'
    }, {
      name: 'hotel_name'
    }, {
      name: 'hotel_extra_thumbnail'
    }, {
      name: 'hotel_address'
    }, {
      name: 'hotel_cityName'
    }, {
      name: 'hotel_award',
      type: 'float'
    }, {
      name: 'meal_breakfast',
      type: 'boolean'
    }, {
      name: 'meal_lunch',
      type: 'boolean'
    }, {
      name: 'meal_dinner',
      type: 'boolean'
    }, {
      name: 'meal_name'
    }, {
      name: 'time_startDate',
      type: 'date'
    }, {
      name: 'time_endDate',
      type: 'date'
    }, {
      name: 'time_duration',
      type: 'int'
    }, {
      name: 'roomType_name'
    }, {
      name: 'roomType_id',
      type: 'text'
    }, {
      name: 'ratePlan_name'
    }, {
      name: 'ratePlan_id',
      type: 'text'
    }, {
      name: 'roomRate_id'
    }, {
      name: 'priceComposition_total_value',
      type: 'float'
    }, {
      name: 'priceComposition_total_currency'
    }, {
      name: 'priceComposition_taxes_value',
      type: 'float'
    }, {
      name: 'priceComposition_taxes_currency'
    }, {
      name: 'priceComposition_commission_value',
      type: 'float'
    }, {
      name: 'priceComposition_commission_currency'
    }, {
      name: 'priceComposition_productPrice_value',
      type: 'float'
    }, {
      name: 'priceComposition_productPrice_currency'
    }, {
      name: 'cancelPolicy_penaltyDate',
      type: 'date'
    }, {
      name: 'cancelPolicy_nonRefundable',
      type: 'boolean'
    }, {
      name: 'occupancy_quantity',
      type: 'int'
    }, {
      name: 'occupancy_adults',
      type: 'int'
    }, {
      name: 'occupancy_children',
      type: 'int'
    }, {
      name: 'occupancy_childrenAges',
      type: 'intarray'
    }, {
      name: 'distribution_id',
      type: 'text'
    }, {
      name: 'promoCode'
    }]
  }, {
    name: 'expirationDatetime',
    label: 'Data de expiração da cotação',
    type: 'datetime',
    renderColumn: false
  }, {
    name: 'originalExpirationDatetime',
    label: 'Data de expiração da cotação',
    type: 'datetime',
    renderColumn: false
  }, {
    name: 'sourceChannel',
    label: 'Canal onde a solicitação foi gerada'
  }, {
    name: 'externalId',
    label: 'Id da solicitação no integrador'
  }, {
    name: 'crmId',
    label: 'Id no CRM',
    type: 'text',
    updatable: false,
    insertable: false
  }, {
    name: 'crmStatus',
    label: 'Informação sobre integração CRM',
    type: 'text',
    updatable: false,
    insertable: false
  }, {
    name: 'reasonCode',
    label: 'XXXX',
    renderColumn: false,
    options: [{
      value: 'PRICE_TOO_HIGH',
      label: "Preço muito alto"
    }, {
      value: 'PAYMENT_OPTION_UNAVAILABLE',
      label: "Forma de pagamento indisponível"
    }, {
      value: 'ROOM_UNAVAILABLE',
      label: "Quarto esgotado"
    }, {
      value: 'SEARCH_ERROR',
      label: "Problema na pesquisa"
    }, {
      value: 'SYSTEM_ERROR',
      label: "Problema no sistema"
    }, {
      value: 'AVAILABLE_PRICE_CHANGE',
      label: "Alteração na disponibilidade/preço"
    }, {
      value: 'CLIENT_GAVE_UP',
      label: "Cliente desistiu de viajar/se hospedar"
    }, {
      value: 'OTHER',
      label: "Outro"
    }]
  }, {
    name: 'reasonSubCode',
    label: 'XXXXX',
    renderColumn: false,
    options: [{
      pages: ['EMAIL', 'LINK'],
      groups: ['SEARCH_ERROR'],
      value: 'PRICE',
      label: "Preço"
    }, {
      pages: ['EMAIL', 'LINK'],
      groups: ['SEARCH_ERROR'],
      value: 'DATE',
      label: "Data"
    }, {
      pages: ['EMAIL', 'LINK'],
      groups: ['SEARCH_ERROR'],
      value: 'GUEST_QUANTITY',
      label: "Quantidade de pessoas"
    }, {
      pages: ['EMAIL', 'LINK'],
      groups: ['SEARCH_ERROR'],
      value: 'ROOM_QUANTITY',
      label: "Quantidade de quartos"
    }, {
      pages: ['EMAIL', 'LINK'],
      groups: ['SEARCH_ERROR'],
      value: 'CHILDREN_AGES',
      label: "Idade das crianças"
    }, {
      pages: ['LINK', 'CHECKOUT'],
      groups: ['SEARCH_ERROR'],
      value: 'PAYMENT_CONDITIONS',
      label: "Formas de pagamento"
    }, {
      pages: ['EMAIL', 'LINK'],
      groups: ['SEARCH_ERROR'],
      value: 'HOTEL',
      label: "Hotel"
    }, {
      pages: ['EMAIL', 'LINK', 'CHECKOUT'],
      groups: ['SEARCH_ERROR'],
      value: 'ROOM_RATE_TYPE',
      label: "Tipo tarifário"
    }, {
      pages: ['EMAIL', 'LINK'],
      groups: ['SEARCH_ERROR'],
      value: 'ACCOMMODATION_TYPE',
      label: "Tipo de acomodação"
    }, {
      pages: ['LINK', 'CHECKOUT'],
      groups: ['SYSTEM_ERROR'],
      value: 'MISSING_INFORMATIONS',
      label: "Não vejo uma informação \"X\""
    }, {
      pages: ['CHECKOUT'],
      groups: ['SYSTEM_ERROR'],
      value: 'PAYMENT_ERROR',
      label: "Problema na transação do pagamento"
    }, {
      pages: ['CHECKOUT'],
      groups: ['SYSTEM_ERROR'],
      value: 'CANT_FINISH_PURCHASE',
      label: "Não consigo concluir a compra"
    }, {
      pages: ['LINK', 'EMAIL'],
      groups: ['SYSTEM_ERROR'],
      value: 'CANT_OPEN_LINK',
      label: "Não consigo abrir o link"
    }, {
      pages: ['LINK', 'CHECKOUT'],
      groups: ['AVAILABLE_PRICE_CHANGE'],
      value: 'NOT_AVAILABLE',
      label: "Não têm mais acomodações na data \"X\""
    }]
  }, {
    name: 'reasonComment',
    label: 'XXXXX',
    renderColumn: false
  }, {
    name: 'bookBlockedEnable',
    label: 'xxxx',
    type: 'boolean'
  }, {
    name: 'lastAction',
    label: 'XXXX',
    renderColumn: false,
    options: [{
      accentColor: '#0C83D9',
      label: "Cotação criada",
      value: 'CREATED',
      description: data => `Cotação criada${data?.lastActionDatetime ? ` em ${format(parseISO(data?.lastActionDatetime), "dd/MM/yyyy 'às' HH:mm")}` : ''}`
    }, {
      accentColor: '#0C83D9',
      label: "Email Enviado",
      value: 'EMAIL_SENT',
      description: data => `Cotação enviada${data?.lastActionDatetime ? ` em ${format(parseISO(data?.lastActionDatetime), "dd/MM/yyyy 'às' HH:mm")}` : ''}`
    }, {
      accentColor: '#FDBA01',
      label: "Email Aberto",
      value: 'EMAIL_OPENED',
      description: data => `Abriu o e-mail${data?.lastActionDatetime ? ` em ${format(parseISO(data?.lastActionDatetime), "dd/MM/yyyy 'às' HH:mm")}` : ''}`
    }, {
      accentColor: '#FDBA01',
      label: "Site Visitado",
      value: 'CONVERSION_VISITED',
      description: data => `Visitou a pagina de conversão${data?.lastActionDatetime ? ` em ${format(parseISO(data?.lastActionDatetime), "dd/MM/yyyy 'às' HH:mm")}` : ''}`
    }, {
      accentColor: '#FDBA01',
      label: "Checkout Visitado",
      value: 'CHECKOUT_VISITED',
      description: data => `Passou para check-out${data?.lastActionDatetime ? ` em ${format(parseISO(data?.lastActionDatetime), "dd/MM/yyyy 'às' HH:mm")}` : ''}`
    }, {
      accentColor: '#53AC2A',
      label: "Feedback enviado",
      value: 'FEEDBACK_SENT',
      description: data => `Cliente enviou um feedback${data?.lastActionDatetime ? ` em ${format(parseISO(data?.lastActionDatetime), "dd/MM/yyyy 'às' HH:mm")}` : ''}`
    }, {
      accentColor: '#FDBA01',
      label: "Cotação editada",
      value: 'UPDATED',
      description: data => `Cotação editada${data?.lastActionDatetime ? ` em ${format(parseISO(data?.lastActionDatetime), "dd/MM/yyyy 'às' HH:mm")}` : ''}`
    }, {
      accentColor: '#FF0000',
      label: "Cotação expirada",
      value: 'EXPIRED',
      description: data => `A cotação expirou${data?.lastActionDatetime ? ` em ${format(parseISO(data?.lastActionDatetime), "dd/MM/yyyy 'às' HH:mm")}` : ''}`
    }, {
      accentColor: '#53AC2A',
      label: "Reserva criada",
      value: 'ORDER_CREATED',
      description: data => `Reserva criada${data?.lastActionDatetime ? ` em ${format(parseISO(data?.lastActionDatetime), "dd/MM/yyyy 'às' HH:mm")}` : ''}`
    }, {
      accentColor: '#53AC2A',
      label: "Reservado",
      value: 'ORDER_FINISHED',
      description: data => `Reservado${data?.lastActionDatetime ? ` em ${format(parseISO(data?.lastActionDatetime), "dd/MM/yyyy 'às' HH:mm")}` : ''}`
    }, {
      accentColor: '#53AC2A',
      label: "Status de pagamento alterado",
      value: 'ORDER_PAYMENT_CHANGED',
      description: data => `Status de pagamento alterado${data?.lastActionDatetime ? ` em ${format(parseISO(data?.lastActionDatetime), "dd/MM/yyyy 'às' HH:mm")}` : ''}`
    }]
  }, {
    name: 'lastActionDatetime',
    label: 'XXXXX',
    renderColumn: false
  }, {
    name: 'templateId',
    label: 'XXXXX',
    renderColumn: false
  }, {
    name: 'status',
    label: 'Estado da cotação',
    type: 'text',
    initialize: () => 'NEW',
    options: [{
      description: "Nova cotação",
      label: "Novo",
      value: 'NEW',
      accentColor: '#0C83D9'
    }, {
      description: "Cotação em andamento",
      label: "Em Andamento",
      value: 'IN_PROGRESS',
      accentColor: '#FDBA01'
    }, {
      description: "Esta cotação foi ganha!",
      label: "Ganho",
      value: 'WON',
      accentColor: '#53AC2A'
    }, {
      description: "Esta cotação foi perdida!",
      label: "Perdido",
      value: 'LOST',
      accentColor: '#FF0000'
    }]
  }, {
    name: 'paymentOptions',
    label: 'Formas de pagamento',
    required: false,
    updatable: true,
    type: 'objectarray',
    graphQLType: '[NestedQuotationPaymentOptions]',
    graphQLInputType: '[NestedQuotationPaymentOptionsInput]',
    fields: [{
      name: 'alias'
    }, {
      name: 'card',
      type: 'boolean'
    }, {
      name: 'description'
    }, {
      name: 'external',
      type: 'boolean'
    }, {
      name: 'id'
    }, {
      name: 'label'
    }, {
      name: 'type'
    }, {
      name: 'oncc_conditions',
      label: 'Condições',
      type: 'objectarray',
      graphQLType: '[NestedQuotationPaymentOptionsOnCcCondition]',
      graphQLInputType: '[NestedQuotationPaymentOptionsOnCcConditionInput]',
      fields: paymentCondition.fields.find(f => f.name === 'oncc_conditions').fields.filter(f => ['cardBrands', 'maxInst', 'minInstValue', 'provider'].indexOf(f.name) >= 0).map(f => ({
        ...f,
        required: false
      }))
    }]
  }, {
    name: 'engineRuleVersion',
    label: 'Versão do motor de regras',
    type: 'text',
    updatable: false
  }, {
    name: 'hotelIds',
    label: 'Hotéis',
    updatable: false,
    render: false,
    renderColumn: false,
    type: 'textarray',
    initialize: () => []
  }, {
    name: 'receivablePercentage',
    label: 'Porcentagem a receber',
    updatable: false,
    renderColumn: false,
    type: 'float'
  }],
  filter: [{
    field: 'id',
    op: 'eq'
  }, {
    field: 'date',
    op: 'le'
  }, {
    field: 'date',
    op: 'ge'
  }, {
    field: 'expirationDatetime',
    op: 'le'
  }, {
    field: 'expirationDatetime',
    op: 'ge'
  }, {
    field: 'clientId',
    op: 'eq'
  }, {
    field: 'responsibleEmail',
    op: 'eq'
  }, {
    field: 'responsible',
    op: 'eq'
  }, {
    field: 'quotationToken',
    op: 'eq'
  }, {
    field: 'status',
    op: 'eq'
  }, {
    field: 'status',
    op: 'in'
  }, {
    field: 'lostReservationId',
    op: 'eq'
  }, {
    field: 'buyerName',
    op: 'contains'
  }, {
    field: 'hotelIds',
    op: 'contains',
    graphQLType: 'String',
    type: 'text'
  }, {
    field: 'hotelIds',
    op: 'intersects'
  }]
});