import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Colaborador (deprecated)',
  labelPlural: 'Colaboradores (deprecated)',
  name: 'ClientTraveller',
  deletable: false,
  authorization: authorization('clientConsultant', 'admin'),
  columnNames: ['id', 'firstName', 'lastName', 'position', 'enabled'],
  'custom:sortFields': ['firstName'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    render: false
  }, {
    name: 'clientId',
    label: 'Id do cliente',
    render: false,
    required: true,
    updatable: false
  }, {
    name: 'firstName',
    label: 'Primeiro nome',
    required: true,
    searchableText: true,
    'custom:XlsxImportView.shown.default': true
  }, {
    name: 'lastName',
    label: 'Sobrenome',
    required: true,
    searchableText: true,
    'custom:XlsxImportView.shown.default': true
  }, {
    name: 'position',
    label: 'Cargo',
    'custom:XlsxImportView.shown.default': true
  }, {
    name: 'employeeIdentification',
    label: 'Matrícula',
    'custom:XlsxImportView.shown.default': true
  }, {
    name: 'phone',
    label: 'Telefone'
  }, {
    name: 'cellphone',
    label: 'Celular'
  }, {
    name: 'email',
    required: false,
    label: 'E-mail',
    inputType: 'email',
    'custom:XlsxImportView.shown.default': true
  }, {
    name: 'cpf',
    label: 'CPF',
    mask: '999.999.999-99'
  }, {
    name: 'rg',
    label: 'RG',
    mask: '99.999.999-_'
  }, {
    name: 'dateOfBirth',
    label: 'Data de nascimento',
    type: 'date'
  }, {
    custom_groupName: "Gestão de viagens",
    name: 'tags',
    label: 'Marcadores',
    type: 'textarray',
    travellerEnabled: true
  }, {
    custom_groupName: "Hotelaria",
    name: 'observation',
    label: 'Observação padrão para hotel',
    inputType: 'textarea'
  }, {
    custom_groupName: "Aéreo",
    name: 'smiles',
    label: 'Smiles Gol'
  }, {
    custom_groupName: "Aéreo",
    name: 'tam',
    label: 'Multiplus'
  }, {
    custom_groupName: "Aéreo",
    name: 'azul',
    label: 'Milhas Azul'
  }, {
    custom_groupName: "Aéreo",
    name: 'avianca',
    label: 'Amigo Avianca',
    render: false
  }, {
    custom_groupName: "Aéreo",
    name: 'seatPreference',
    label: 'Preferência de assentos',
    options: [{
      value: 'frontWindow',
      label: 'Janela Frontal'
    }, {
      value: 'backWindow',
      label: 'Janela Traseira'
    }, {
      value: 'frontCorridor',
      label: 'Corredor Frontal'
    }, {
      value: 'backCorridor',
      label: 'Corredor Traseiro'
    }],
    inputType: 'select'
  }, {
    name: 'cognitoStatus',
    label: 'Situação usuário (cognito)',
    updatable: false,
    insertable: false,
    render: false
  }, {
    name: 'sub',
    label: 'Id usuário (cognito)',
    updatable: false,
    insertable: false,
    render: false
  }, {
    name: 'cognitoEnabled',
    label: 'Usuário ativo (cognito)',
    updatable: false,
    insertable: false,
    render: false
  }, {
    custom_groupName: "Acesso",
    name: 'group',
    label: 'Permissão',
    type: 'text',
    default: 'Regular',
    required: true,
    options: [{
      label: 'Viajante/ Aprovador',
      value: 'Regular',
      hint: 'Criação de solicitações de viagens, visualização de suas próprias viagens, aprovação'
    }, {
      label: 'Assistente',
      value: 'Assistant',
      hint: 'Criação de solicitações de viagens para outras pessoas'
    }, {
      label: 'Administrador',
      value: 'Admin',
      hint: 'Criação de solicitações de viagens para outras pessoas, cadastro de funcionários, políticas e regras de aprovação'
    }]
  }, {
    custom_groupName: "Acesso",
    label: 'Usar e-mail como login',
    name: 'loginWithEmail',
    type: 'boolean',
    default: true,
    updatable: true,
    insertable: true,
    renderForm: function (i) {
      return i.email && i.email.length > 0 && this.render != false ? this.render(i) : false;
    }
  }, {
    custom_groupName: "Acesso",
    label: 'Ativo',
    name: 'enabled',
    default: true,
    type: 'boolean',
    'custom:XlsxImportView.shown.default': true
  }],
  filter: [{
    name: 'client_id_eq',
    field: 'clientId',
    op: 'eq',
    label: 'Client Id'
  }, {
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq'
  }, {
    name: 'firstName_beginsWith',
    field: 'firstName',
    op: 'beginsWith'
  }, {
    name: 'lastName_beginsWith',
    field: 'lastName',
    op: 'beginsWith'
  }, {
    name: 'email_eq',
    field: 'email',
    op: 'eq'
  }, {
    name: 'employeeIdentification_eq',
    field: 'employeeIdentification',
    op: 'eq'
  }, {
    name: 'cpf_eq',
    field: 'cpf',
    op: 'eq'
  }]
});