import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Viajante (Eventos)',
  labelPlural: 'Viajantes (Eventos)',
  name: 'LandingPageTraveller',
  deletable: false,
  insertable: false,
  updatable: false,
  authorization: authorization('admin', false),
  columnNames: ['id', 'firstName', 'lastName', 'email', 'cellphone', 'enabled'],
  'custom:sortFields': ['firstName'],
  dynamoDB: {
    tableName: 'SpearClientTraveller'
  },
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    render: false
  }, {
    name: 'clientId',
    label: 'Id do cliente',
    render: false,
    required: true,
    updatable: false
  }, {
    name: 'firstName',
    label: 'Primeiro nome',
    required: true,
    searchableText: true
  }, {
    name: 'lastName',
    label: 'Sobrenome',
    required: true,
    searchableText: true
  }, {
    name: 'cellphone',
    required: true,
    label: 'Celular'
  }, {
    name: 'email',
    required: true,
    label: 'E-mail',
    inputType: 'email'
  }, {
    name: 'cpf',
    label: 'CPF',
    mask: '999.999.999-99',
    render: false
  }, {
    name: 'rg',
    label: 'RG',
    mask: '99.999.999-_',
    render: false
  }, {
    name: 'dateOfBirth',
    label: 'Data de nascimento',
    type: 'date',
    render: false
  }, {
    label: 'Ativo',
    name: 'enabled',
    default: true,
    type: 'boolean',
    render: false
  }],
  filter: [{
    name: 'client_id_eq',
    field: 'clientId',
    op: 'eq',
    label: 'Client Id'
  }, {
    name: 'firstName_beginsWith',
    field: 'firstName',
    op: 'beginsWith'
  }, {
    name: 'lastName_beginsWith',
    field: 'lastName',
    op: 'beginsWith'
  }]
});