import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Configuração de Relatório automático',
  labelPlural: 'Configurações de Relatório automático',
  name: 'AutomaticReportConfig',
  path: '/automaticReportConfig',
  deletable: false,
  updatable: true,
  insertable: true,
  authorization: authorization('clientConsultant', 'admin'),
  dynamoDB: {
    tableName: 'SpearAutomaticReportConfig',
    indexes: [{
      indexName: 'tenantId-clientId-index',
      indexedProp: 'clientId'
    }]
  },
  columnNames: ['id', 'name', 'lastReportDatetime', 'lastReportLink', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    render: false,
    id: true
  }, {
    name: 'date',
    label: 'Data',
    type: 'text',
    render: false
  }, {
    name: 'name',
    label: 'Nome',
    type: 'text'
  }, {
    label: 'clientId',
    name: 'clientId',
    type: 'text'
  }, {
    name: 'overprice',
    label: 'Margem de Preço',
    type: 'float',
    inputType: 'percentage'
  }, {
    name: 'deltaDate',
    label: 'Dias de Referencia',
    type: 'int',
    hint: 'Dias depois de hoje'
  }, {
    name: 'adults',
    label: 'Adultos',
    type: 'int'
  }, {
    name: 'meal',
    label: 'Regime de Alimentação Mínimo',
    options: [{
      label: 'Qualquer',
      value: 'NOTHING'
    }, {
      label: 'Café da manhã',
      value: 'BREAKFAST'
    }],
    inputType: 'select'
  }, {
    name: 'nonRefundableEnabled',
    label: 'Adicionar reservas não reembolsáveis',
    type: 'boolean'
  }, {
    name: 'rateSelection',
    label: 'Configuração de seleção de tarifa',
    options: [{
      label: 'Mais barata',
      value: 'MIN_PRICE'
    }, {
      label: 'Melhor remuneração',
      value: 'MAX_REVENUE'
    }, {
      label: 'Maior quarto',
      value: 'BIGGEST_ROOM'
    }, {
      label: 'Todos',
      value: 'ALL'
    }],
    inputType: 'select'
  }, {
    name: 'roomRateType',
    label: 'Tipo de Tarifa',
    options: [{
      label: 'Comissisionado',
      value: 'COMMISSION'
    }, {
      label: 'NET',
      value: 'NET'
    }, {
      label: 'Ambos',
      value: 'ALL'
    }],
    inputType: 'select'
  }, {
    name: 'roomRateScope',
    label: 'Tipo de Acordo',
    options: [{
      label: 'Publico',
      value: 'PUBLIC'
    }, {
      label: 'Acordo',
      value: 'PRIVATE'
    }, {
      label: 'Ambos',
      value: 'ALL'
    }],
    inputType: 'select'
  }, {
    name: 'lastReportDatetime',
    label: 'Horário do último relatório',
    renderForm: false,
    type: 'datetime'
  }, {
    name: 'lastReportLink',
    label: 'Link último relatório',
    renderForm: false
  }, {
    name: 'allowedHotels',
    label: 'Hotéis permitidos',
    type: 'objectarray',
    inputType: 'inputHotelToken',
    graphQLType: '[NestedDestination]',
    graphQLInputType: '[NestedDestinationInput]',
    fields: [{
      name: 'id',
      label: 'Id',
      required: true
    }, {
      name: 'name',
      label: 'Nome',
      required: true
    }, {
      name: 'type',
      label: 'Tipo',
      required: true
    }, {
      name: 'city',
      label: 'Cidade'
    }, {
      name: 'state',
      label: 'Estado'
    }, {
      name: 'country',
      label: 'País'
    }],
    renderForm: i => {
      return !i.allowedSuppliers?.length;
    },
    render: function (i) {
      if (i.allowedHotels) return i.allowedHotels.filter(x => x).map(x => x && x.name).join(', ');
      return '-';
    }
  }, {
    name: 'allowedSuppliers',
    label: 'Todos os Hotéis dos Fornecedores',
    options: [{
      label: 'Omnibees',
      value: 'OMNI'
    }],
    type: 'textarray',
    hint: 'Todos os hotéis dos fornecedores selecionados serão considerados no relatório. Ao selecionar alguma das opções, a configuração acima será ignorada.'
  }, {
    name: 'paymentOption',
    label: 'Forma de pagamento',
    options: [{
      label: 'Cartão',
      value: 'OFF_CC'
    }, {
      label: 'Faturado',
      value: 'OFF_FAT'
    }, {
      label: 'Direto',
      value: 'DIR'
    }],
    type: 'textarray'
  }, {
    name: 'fileFormat',
    label: 'Formato Exportação',
    options: [{
      label: 'CSV',
      value: 'CSV'
    }, {
      label: 'XLXS',
      value: 'XLXS'
    }],
    inputType: 'select'
  }, {
    name: 'template',
    label: 'Template',
    options: [{
      label: 'CONCUR',
      value: 'CONCUR'
    }, {
      label: 'PADRAO',
      value: 'DEFAULT'
    }, {
      label: 'CUSTOMIZADO',
      value: 'CUSTOM'
    }],
    inputType: 'select'
  }, {
    name: 'mappingFields',
    label: 'Campos Planilha',
    type: 'objectarray',
    graphQLType: '[NestedMappingFields]',
    graphQLInputType: '[NestedMappingFieldsInput]',
    fields: [{
      name: 'field',
      label: 'Campo',
      type: 'text',
      required: true
    }, {
      name: 'value',
      label: 'Valor',
      inputType: 'select',
      required: true,
      options: [{
        label: 'Nome do Hotel',
        value: 'hotelName'
      }, {
        label: 'ID do Hotel',
        value: 'hotelId'
      }, {
        label: 'Nome e ID do Hotel',
        value: 'productNameId'
      }, {
        label: 'Endereço do Hotel',
        value: 'hotelAddress'
      }, {
        label: 'Moeda da Tarifa',
        value: 'roomRatePriceCurrencyCode'
      }, {
        label: 'Preço da Tarifa',
        value: 'roomRatePriceValue'
      }, {
        label: 'Tipo da Tarifa',
        value: 'roomRateType'
      }, {
        label: 'Tipo Quarto',
        value: 'roomRateRoomType'
      }, {
        label: 'Campo Vazio',
        value: 'empty'
      }, {
        label: 'Constante',
        value: 'constant'
      }, {
        label: 'Data de pesquisa',
        value: 'searchDate'
      }, {
        label: 'Descrição da credencial',
        value: 'credentialAlias'
      }, {
        label: 'Latitude',
        value: 'hotelLatitude'
      }, {
        label: 'Longiture',
        value: 'hotelLongitude'
      }, {
        label: 'Nome da rede de hoteis',
        value: 'hotelChainName'
      }, {
        label: 'Estado',
        value: 'hotelStateName'
      }, {
        label: 'Código Cidade',
        value: 'cityName'
      }, {
        label: 'Nome da Cidade',
        value: 'hotelCityName'
      }, {
        label: 'Nome do País',
        value: 'hotelCountryName'
      }, {
        label: 'CEP',
        value: 'hotelZip'
      }, {
        label: 'Página do Hotel',
        value: 'hotelSite'
      }, {
        label: 'Telefone do Hotel',
        value: 'hotelPhone'
      }, {
        label: 'Email do Hotel',
        value: 'hotelEmail'
      }, {
        label: 'Categoria do Hotel',
        value: 'hotelAward'
      }, {
        label: 'Quantidade de Adultos',
        value: 'adults'
      }, {
        label: 'Data de Início da Reserva',
        value: 'startDate'
      }, {
        label: 'Alimentação',
        value: 'roomRateMeal'
      }, {
        label: 'Tarifário',
        value: 'roomRatePlanName'
      }, {
        label: 'Tipo da Tarifa',
        value: 'roomRateType'
      }, {
        label: 'Percential de Comissão',
        value: 'roomRateComissionPercentage'
      }, {
        label: 'Valor de Comissão',
        value: 'roomRateComissionValue'
      }, {
        label: 'Valor de Markup',
        value: 'roomRateMarkupValue'
      }, {
        label: 'Valor das Taxas',
        value: 'roomRateTaxesValue'
      }, {
        label: 'Valor Total',
        value: 'roomRateTotalPrice'
      }, {
        label: 'Pagamento ao fornecedor',
        value: 'roomRateOutputPayment'
      }]
    }, {
      name: 'constantValue',
      label: 'Valor fixo',
      type: 'text'
    }]
  }, {
    name: 'enabled',
    label: 'Habilitado',
    type: 'boolean'
  }],
  filter: [{
    name: 'client_id_eq',
    field: 'clientId',
    op: 'eq',
    label: 'Client Id'
  }]
});