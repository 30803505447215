import styled from 'styled-components'
import { BREAKPOINT_MD } from '../../theme/ota'

export const Container = styled.div`
  &&&& {
    position: relative;
    max-width: 100%;

    @media (max-width: calc(${BREAKPOINT_MD})) {
      max-width: 100%;
    }
  }
`

export const InputText = styled.input`
  &&&& {
    background-color: ${({ theme }) => theme?.colors?.white};
    border: 1px solid ${({ theme }) => theme?.colors?.gray4};
    border-radius: 4px;
    display: flex;
    font-size: 14px;
    height: 42px !important;
    margin: 0;
    overflow: hidden;
    transition: ease-in-out 0.15s;
    padding-left: 36px;
    position: relative;
    width: 100%;

    &:focus,
    &:focus-visible,
    &:hover,
    &:active {
      border: 1px solid ${({ theme }) => theme?.colors?.text};
      outline: none;
    }

    @media (max-width: ${BREAKPOINT_MD}) {
      font-size: 16px;
      height: 52px !important;
    }
  }
`

export const Icon = styled.div`
  &&&& {
    left: 10px;
    height: 22px;
    pointer-events: none;
    position: absolute;
    top: 13px;
    user-select: none;
    width: 18px;
    z-index: 1;

    @media (max-width: ${BREAKPOINT_MD}) {
      top: 18px;
    }
  }
`
