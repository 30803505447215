import _times from 'lodash/times';
import { nanoid } from 'nanoid';
import React, { useCallback } from 'react';
import { SelectInt } from '@niaratech/niara-react-form';
import { Divider, Grid } from '@mui/material';
import { Count } from '../..';
import * as S from './styles';
const RENDER_AGE = i => i > 1 ? `${i} anos` : `${i} ano`;
type Room = {
  adults: number;
  children?: number;
  childrenAges?: number[];
  _id?: string;
};
type Props = {
  value: Room[];
  setValue: React.Dispatch<React.SetStateAction<Room[]>>;
  minChildCount: number;
  maxChildCount: number;
  minAdultCount: number;
  maxAdultCount: number;
  childrenAllowed: boolean;
  minChildAge: number;
  maxChildAge: number;
  minRoomCount: number;
  maxRoomCount: number;
  dataTestId?: string;
};
const OccupancyInput: React.FC<Props> = ({
  value: valueRooms,
  setValue: setValueRooms,
  minChildCount,
  maxChildCount,
  minAdultCount,
  maxAdultCount,
  childrenAllowed,
  minChildAge,
  maxChildAge,
  minRoomCount,
  maxRoomCount,
  dataTestId
}) => {
  const setChildren = useCallback((roomIndex: number, children: number) => {
    setValueRooms(rooms => {
      return rooms.map((r, i) => {
        if (i === roomIndex) {
          let newChildrenAges = r.childrenAges ? [...r.childrenAges] : [];
          if (children == 0) {
            newChildrenAges = undefined;
          } else if (newChildrenAges?.length > children) {
            newChildrenAges = newChildrenAges.slice(0, children);
          } else if (newChildrenAges?.length < children) {
            newChildrenAges.push(..._times(children - newChildrenAges?.length, () => undefined));
          }
          return {
            ...r,
            children,
            childrenAges: newChildrenAges
          };
        } else return r;
      });
    });
  }, [setValueRooms]);
  const setChildrenAge = useCallback((roomIndex: number, childIndex: number, age: number) => {
    setValueRooms(rooms => {
      return rooms.map((r, i) => {
        if (i === roomIndex) {
          const newChildrenAges = r.childrenAges ? [...r.childrenAges] : [];
          newChildrenAges[childIndex] = age;
          return {
            ...r,
            childrenAges: newChildrenAges
          };
        } else return r;
      });
    });
  }, [setValueRooms]);
  const setAdults = useCallback((roomIndex, adults) => {
    setValueRooms(rooms => {
      return rooms.map((r, i) => {
        if (i === roomIndex) return {
          ...r,
          adults
        };else return r;
      });
    });
  }, [setValueRooms]);
  const roomCount = valueRooms?.length ?? 0;
  const setRoomCount = useCallback((count: number) => {
    setValueRooms(_r => {
      const r = _r ?? [];
      return [...r, ..._times<Room>(count - r?.length, () => ({
        adults: minAdultCount ?? 1,
        children: minChildCount ?? 0,
        childrenAges: _times(minChildCount ?? 0, null),
        _id: nanoid()
      }))].slice(0, count).slice(0, maxRoomCount);
    });
  }, [maxRoomCount, minAdultCount, minChildCount, setValueRooms]);
  return <S.BoxOccupancy data-testid={dataTestId}>
      {maxRoomCount > 1 && <>
          <div key="selectRoomCount" className="guest-count">
            <S.TitleRoom>Quartos(s)</S.TitleRoom>

            <Count name="roomCount" value={roomCount} min={Math.max(minRoomCount, 1)} max={maxRoomCount} onChange={v => setRoomCount(v)} />
          </div>
          <Divider sx={{
        marginBottom: '15px'
      }} />
        </>}

      {valueRooms?.map((room, index) => {
      const qtdChildren = {
        min: childrenAllowed ? minChildCount ?? 0 : 0,
        max: childrenAllowed ? maxChildCount ?? 3 : 0
      };
      const qtdAdults = {
        min: minAdultCount ?? 1,
        max: maxAdultCount ?? 10
      };
      const renderChildren = (children: number, roomIndex: number) => {
        const ageChildren = {
          min: minChildAge > maxChildAge ? maxChildAge : minChildAge < 0 ? 0 : minChildAge ?? 0,
          max: maxChildAge > 17 ? 17 : maxChildAge ?? 17
        };
        const errorsForChildAge = (i): string[] => {
          return [valueRooms[roomIndex]?.childrenAges?.[i] == null && "Escolha a idade", valueRooms[roomIndex]?.childrenAges?.[i] > ageChildren.max && "Idade inválida para criança", valueRooms[roomIndex]?.childrenAges?.[i] < ageChildren.min && "Idade menor que o permitido"].filter(Boolean);
        };
        return <Grid container spacing={2}>
              {_times(children, i => <Grid item xs={6} key={'child_' + i}>
                  <SelectInt placeholder="Idade" renderOption={RENDER_AGE} label={<>Criança {i + 1}</>} max={ageChildren.max} min={ageChildren.min} required={true} value={valueRooms[roomIndex]?.childrenAges?.[i]} setValue={v => {
              const newV = typeof v == 'function' ? v(valueRooms[roomIndex]?.childrenAges?.[i]) : v;
              setChildrenAge(roomIndex, i, newV);
            }} errors={errorsForChildAge(i)} />
                </Grid>)}
            </Grid>;
      };
      return <S.BoxRoom key={room._id || index}>
            <span>{roomCount > 1 && <h3>Quarto {index + 1}</h3>}</span>

            {qtdAdults.max > 0 && <div className="guest-count">
                <S.TitleInputs>Adultos </S.TitleInputs>
                <Count name="adults" value={room.adults} min={qtdAdults.min} max={qtdAdults.max} onChange={v => setAdults(index, v)} />
              </div>}

            {childrenAllowed !== false && qtdChildren.max > 0 && <div className="guest-count">
                <S.TitleInputs>Crianças</S.TitleInputs>
                <Count name="children" value={room.children} onChange={v => setChildren(index, v)} min={qtdChildren.min} max={qtdChildren.max} />
              </div>}

            {room.children > 0 && <S.BoxChildren>{renderChildren(room.children, index)}</S.BoxChildren>}
          </S.BoxRoom>;
    })}
    </S.BoxOccupancy>;
};
export default OccupancyInput;