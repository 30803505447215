import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Canal',
  labelPlural: 'Canais',
  name: 'Channel',
  insertable: false,
  updatable: false,
  deletable: false,
  authorization: authorization('admin'),
  dynamoDB: {
    tableName: 'SpearChannel'
  },
  columnNames: ['id', 'name', 'product'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    render: false
  }, {
    name: 'name',
    label: 'Nome'
  }, {
    name: 'product',
    label: 'Produto'
  }],
  filter: []
});