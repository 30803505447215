import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Nota fiscal',
  labelPlural: 'Notas fiscais',
  name: 'Invoice',
  deletable: false,
  updatable: false,
  insertable: false,
  authorization: authorization('consultant', false),
  'custom:sortFields': ['date desc'],
  dynamoDB: {
    tableName: 'SpearInvoice',
    partitionKey: 'tenantId',
    sortKey: 'id',
    indexedProp: 'originTypeId',
    indexName: 'tenantId-originTypeId-index'
  },
  columnNames: ['id', 'invoiceFile', 'invoiceScore', 'vatSupplierChecked', 'vatSupplier', 'vatConsumer', 'vatConsumerChecked', 'invoiceValueChecked', 'invoiceValue'],
  fields: [{
    name: 'tenantId',
    id: true,
    insertable: false,
    render: false
  }, {
    name: 'originTypeId',
    insertable: false,
    render: false
  }, {
    name: 'vatSupplier',
    updatable: false
  }, {
    name: 'vatConsumer',
    updatable: false
  }, {
    name: 'invoiceValue',
    type: 'float',
    updatable: false
  }, {
    name: 'invoiceFile',
    updatable: false
  }, {
    name: 'vatSupplierChecked',
    type: 'boolean',
    updatable: false
  }, {
    name: 'vatConsumerChecked',
    type: 'boolean',
    updatable: false
  }, {
    name: 'invoiceValueChecked',
    type: 'boolean',
    updatable: false
  }, {
    name: 'invoiceScore',
    type: 'float',
    updatable: false
  }],
  filter: [{
    field: 'originTypeId',
    op: 'eq'
  }]
});