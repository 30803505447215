import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
const TEMPLATE_TYPES = ['PaymentStatus', 'Base', 'VoucherHotel', 'ReservationStatus', 'Quotation', 'VoucherSale', 'PaymentLink', 'PaymentNotification', 'PaymentInformation', 'QuotationFeedbackNotification'].map(r => ({
  value: r,
  label: r
}));
export default fillCrudMetadataGaps({
  label: 'Template de e-mail',
  labelPlural: 'Templates de e-mail',
  name: 'MailTemplate',
  namePlural: 'MailTemplates',
  deletable: false,
  updatable: true,
  authorization: authorization('master', 'master'),
  dynamoDB: {
    tableName: 'SpearMailTemplate'
  },
  columns: ['id', 'templateType', 'subject', 'locale', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'clientId',
    label: 'Id do cliente',
    required: false,
    updatable: false
  }, {
    name: 'templateType',
    label: 'Tipo do template',
    required: true,
    updatable: false,
    options: TEMPLATE_TYPES
  }, {
    name: 'locale',
    label: 'Idioma',
    options: [{
      value: 'pt-BR',
      label: 'Português (Brasil)'
    }, {
      value: 'en',
      label: 'Inglês'
    }, {
      value: 'es-MX',
      label: 'Espanhol (MX)'
    }],
    required: true,
    updatable: false
  }, {
    label: 'Assunto',
    name: 'subject',
    type: 'text',
    inputType: 'textarea',
    insertable: true,
    updatable: true,
    required: false,
    withNullSelector: true,
    default: null,
    nullSelectorLabel: 'Usar padrão',
    nullSelectorBreakLine: true
  }, {
    label: 'Template',
    name: 'template',
    type: 'text',
    inputType: 'textarea',
    insertable: true,
    updatable: true,
    required: false,
    withNullSelector: true,
    default: null,
    nullSelectorLabel: 'Usar padrão',
    nullSelectorBreakLine: true
  }, {
    label: 'Remetente (e-mail)',
    name: 'fromEmail',
    type: 'text',
    insertable: true,
    updatable: true,
    required: false,
    render: false,
    hint: 'Só vai funcionar para remetentes configurados no SES',
    withNullSelector: true,
    default: null,
    nullSelectorLabel: 'Usar padrão'
  }, {
    label: 'Remetente (Nome)',
    name: 'fromName',
    type: 'text',
    insertable: true,
    updatable: true,
    required: false,
    withNullSelector: true,
    default: null,
    nullSelectorLabel: 'Usar padrão'
  }, {
    label: 'Ativo',
    name: 'enabled',
    type: 'boolean',
    default: true,
    insertable: true,
    updatable: true,
    required: true
  }],
  filter: [{
    field: 'clientId',
    op: 'eq'
  }, {
    field: 'enabled',
    op: 'eq'
  }]
});