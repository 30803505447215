import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import { customAlphabet } from 'nanoid';
const nanoid = customAlphabet && customAlphabet('2346789ABCDEFGHJKLMNPQRTUVWXYZ', 10);
export default fillCrudMetadataGaps({
  name: 'SecuredLink',
  label: 'Link seguro',
  labelPlural: 'Links seguros',
  deprecated: true,
  authorization: authorization('master'),
  dynamoDB: {
    tableName: 'SpearSecuredLink'
  },
  columns: ['id', 'description', 'code', 'validityDate', 'createdByName'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    id: true,
    insertable: false,
    updatable: false,
    renderForm: false,
    renderColumn: false
  }, {
    label: 'Descrição',
    name: 'description',
    type: 'text',
    hint: 'Para uso interno'
  }, {
    label: 'Token',
    name: 'code',
    type: 'text',
    updatable: false,
    required: true,
    readOnly: true,
    initialize: () => nanoid && nanoid()
  }, {
    label: 'URL segura',
    name: 'url',
    type: 'text',
    updatable: true,
    required: true
  }, {
    name: 'hotel',
    label: 'Hotel associado',
    type: 'object',
    updatable: false,
    inputType: 'inputHotel',
    graphQLType: 'NestedDestination',
    graphQLInputType: 'NestedDestinationInput',
    fields: [{
      name: 'id',
      label: 'Id'
    }, {
      name: 'name',
      label: 'Nome'
    }, {
      name: 'type',
      label: 'Tipo'
    }, {
      name: 'city',
      label: 'Cidade'
    }, {
      name: 'state',
      label: 'Estado'
    }, {
      name: 'country',
      label: 'País'
    }],
    render: function (i) {
      return i && i.hotel && i.hotel.name || '';
    }
  }, {
    name: 'validityDate',
    label: 'Data de validade',
    type: 'date',
    required: true,
    updatable: true
  }, {
    name: 'createdByName',
    label: 'Criado por',
    updatable: false,
    insertable: false
  }]
});