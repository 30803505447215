import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import React from 'react';
import { credentialTypesOptions } from '../credential';
import { formatDate } from '@niaratech/niara-js-commons';
export default fillCrudMetadataGaps({
  label: 'Sub Conta',
  labelPlural: 'Sub Contas',
  name: 'Tenant',
  insertable: false,
  updatable: true,
  deletable: false,
  authorization: authorization('admin', 'superuser'),
  columnNames: ['tenantId', 'id', 'name', 'url', 'alias'],
  custom_withAudit: true,
  fields: [{
    label: 'TenantId',
    name: 'tenantId',
    type: 'text',
    id: true,
    updatable: false,
    'custom:excludeForCurrentTenant': false
  }, {
    name: 'id',
    label: 'ID',
    id: true,
    updatable: false,
    'custom:excludeForCurrentTenant': false
  }, {
    name: 'alias',
    label: 'Alias',
    updatable: true,
    hint: 'Por favor, verificar antes se não existe outra instalação com este mesmo alias',
    'custom:excludeForCurrentTenant': false,
    pattern: '[a-z]{3,}'
  }, {
    name: 'alias_replaced',
    label: 'Alias substituído',
    updatable: true,
    hint: 'Por favor, verificar antes se não existe outra instalação com este mesmo alias',
    'custom:excludeForCurrentTenant': false,
    pattern: '[a-z]{3,}'
  }, {
    name: 'name',
    label: 'Nome',
    updatable: true,
    'custom:excludeForCurrentTenant': false
  }, {
    name: 'disabled',
    label: 'Desabilitar temporariamente',
    updatable: true,
    'custom:excludeForCurrentTenant': false
  }, {
    name: 'systemLocale',
    label: 'Idioma/ Região do sistema',
    options: [{
      label: 'Português (Brasil)',
      value: 'pt-BR'
    }, {
      label: 'Inglês',
      value: 'en'
    }, {
      label: 'Espanhol',
      value: 'es'
    }, {
      label: 'Espanhol (MX)',
      value: 'es-MX'
    }, {
      label: 'Espanhol (CO)',
      value: 'es-CO'
    }],
    inputType: 'select',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    name: 'inactivityTimeoutMinutes',
    label: 'Tempo máximo de inatividade (minutos)',
    type: 'int',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    name: 'url',
    label: 'Url',
    updatable: false,
    'custom:excludeForCurrentTenant': false
  }, {
    name: 'canary',
    label: 'Versão Canary',
    updatable: false,
    deprecated: true,
    type: 'datetime',
    hint: 'ATENÇÃO!!! Após mudar, este valor está cacheado no generic-trigger e task-schedule (uma hora). Necessário fazer redeploy, pode perder eventos.',
    'custom:excludeForCurrentTenant': false,
    render: false
  }, {
    name: 'canaryStartDate',
    label: 'Versão Canary - Data de Início',
    updatable: true,
    inputType: 'datetime-local',
    hint: 'ATENÇÃO!!! Após mudar, este valor está cacheado no generic-trigger e task-schedule (uma hora). Necessário fazer redeploy, pode perder eventos.',
    'custom:excludeForCurrentTenant': false,
    min: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    render: e => formatDate(e?.canaryStartDate)
  }, {
    name: 'canaryEndDate',
    label: 'Versão Canary - Data de Término',
    updatable: true,
    inputType: 'datetime-local',
    hint: 'ATENÇÃO!!! Após mudar, este valor está cacheado no generic-trigger e task-schedule (uma hora). Necessário fazer redeploy, pode perder eventos.',
    'custom:excludeForCurrentTenant': false,
    min: new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000),
    render: e => formatDate(e?.canaryEndDate)
  }, {
    custom_groupName: "Central de reservas",
    name: 'modules_crs',
    label: 'Central de reservas',
    type: 'boolean',
    'custom:excludeForCurrentTenant': false,
    updatable: false
  }, {
    custom_groupName: "Central de reservas",
    name: 'allowedHotels',
    label: 'Hotéis permitidos - (editar via regra de pesquisa)',
    type: 'objectarray',
    inputType: 'inputHotelToken',
    graphQLType: '[NestedDestination]',
    graphQLInputType: '[NestedDestinationInput]',
    updatable: false,
    hint: 'Os Hoteis nessa lista vão ser atualizados via OBAPI (se ligado) e vão ser importadas as reservas perdidas do motor Omnibees.',
    fields: [{
      name: 'id',
      label: 'Id',
      required: true
    }, {
      name: 'name',
      label: 'Nome',
      required: true
    }, {
      name: 'type',
      label: 'Tipo',
      required: true
    }, {
      name: 'city',
      label: 'Cidade'
    }, {
      name: 'state',
      label: 'Estado'
    }, {
      name: 'country',
      label: 'País'
    }],
    'custom:excludeForCurrentTenant': false,
    render: function (i) {
      if (i?.allowedHotels) return i?.allowedHotels?.filter(x => x)?.map(x => x && x?.name)?.length > 0;
      return false;
    }
  }, {
    custom_groupName: "Bee2Pay",
    name: 'supplierId',
    updatable: false,
    label: 'Código do hotel para o Bee2Pay',
    'custom:excludeForCurrentTenant': false
  }, {
    custom_groupName: "Bee2Pay",
    name: 'isBee2Pay',
    updatable: false,
    label: 'Instalação do tipo Bee2Pay',
    type: 'boolean',
    'custom:excludeForCurrentTenant': false
  }, {
    custom_groupName: "Dados da empresa",
    name: 'corporate_name',
    label: 'Razão Social',
    hint: 'Esta informação é visível por usuários do sistema (inclusive self booking e link de pagamento)',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Dados da empresa",
    name: 'brand_name',
    label: 'Nome Fantasia',
    hint: 'Esta informação é visível por usuários do sistema (inclusive self booking e link de pagamento)',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Dados da empresa",
    name: 'vat',
    label: 'CNPJ',
    mask: '99.999.999/9999-99',
    hint: 'Esta informação é visível por usuários do sistema (inclusive self booking e link de pagamento)',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Dados da empresa",
    name: 'email',
    label: 'E-mail',
    hint: 'Esta informação é visível por usuários do sistema (inclusive self booking e link de pagamento)',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Dados da empresa",
    name: 'phone',
    label: 'Telefone de contato',
    inputType: 'phoneNumber',
    hint: 'Esta informação é visível por usuários do sistema (inclusive self booking e link de pagamento)',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Dados da empresa",
    name: 'fax',
    label: 'Fax',
    inputType: 'phoneNumber',
    hint: 'Esta informação é visível por usuários do sistema (inclusive self booking e link de pagamento)',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Dados da empresa",
    name: 'address',
    label: 'Endereço',
    hint: 'Esta informação é visível por usuários do sistema (inclusive self booking e link de pagamento)',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Dados da empresa",
    name: 'address_number',
    label: 'Número',
    hint: 'Esta informação é visível por usuários do sistema (inclusive self booking e link de pagamento)',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Dados da empresa",
    name: 'address_apartment',
    label: 'Complemento',
    hint: 'Esta informação é visível por usuários do sistema (inclusive self booking e link de pagamento)',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Dados da empresa",
    name: 'city',
    label: 'Cidade',
    renderColumn: i => i.city && i.city + ' - ' + i.state_acronym,
    hint: 'Esta informação é visível por usuários do sistema (inclusive self booking e link de pagamento)',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Dados da empresa",
    name: 'state_acronym',
    label: 'Estado',
    renderColumn: false,
    hint: 'Esta informação é visível por usuários do sistema (inclusive self booking e link de pagamento)',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Dados da empresa",
    name: 'country_code',
    label: 'País',
    hint: 'Esta informação é visível por usuários do sistema (inclusive self booking e link de pagamento)',
    inputType: 'countryCode',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Dados da empresa",
    name: 'zip_code',
    label: 'CEP',
    mask: '_____-___',
    hint: 'Esta informação é visível por usuários do sistema (inclusive self booking e link de pagamento)',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Dados de contato",
    name: 'website',
    label: 'Website',
    hint: 'Esta informação é visível por usuários do sistema (inclusive self booking e link de pagamento)',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Dados de contato",
    name: 'facebook',
    label: 'Id Facebook',
    custom_prepend: 'https://www.facebook.com/',
    hint: 'Esta informação é visível por usuários do sistema (inclusive self booking e link de pagamento)',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Dados de contato",
    name: 'instagram',
    label: 'Id Instagram',
    custom_prepend: '@',
    hint: 'Esta informação é visível por usuários do sistema (inclusive self booking e link de pagamento)',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Dados de notificação",
    name: 'notificationEmails',
    label: 'E-mail para avisos',
    hint: 'E-mails de criação de reserva, cancelamento de reserva e upload de nota fiscal será enviado para essses e-mails. Cadastrar multiplos usando , para separação.',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Dados de notificação",
    name: 'sendMail_fromNameDefaultOption',
    label: 'Nome do remetente de e-mails enviados',
    type: 'text',
    options: [{
      value: 'TENANT_NAME',
      label: 'Nome da instalação (exceto selfbooking)'
    }, {
      value: 'CUSTOM',
      label: 'Nome customizado (exceto selfbooking)'
    }, {
      value: 'ALWAYS_CLIENT_CONFIG',
      label: 'Cliente (ver configuração)'
    }, {
      value: 'ALWAYS_TENANT_NAME',
      label: 'Nome da instalação (sempre, mesmo selfbooking)'
    }, {
      value: 'ALWAYS_CUSTOM',
      label: 'Nome customizado (sempre, mesmo selfbooking)'
    }],
    inputType: 'select',
    hint: 'Padrão do nome do remetente nos e-mails enviados. Se existir remetente configurado no template de e-mail, esta configuração será ignorada.',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Dados de notificação",
    name: 'sendMail_customFromName',
    label: 'Nome do remetente de e-mails enviados customizado',
    type: 'text',
    renderForm: i => i?.sendMail_fromNameDefaultOption == 'CUSTOM' || i?.sendMail_fromNameDefaultOption == 'ALWAYS_CUSTOM',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Dados de notificação",
    name: 'sendMail_replyToOption',
    label: 'E-mail de resposta',
    type: 'text',
    options: [{
      value: 'CURRENT_USER',
      label: 'E-mail do usuário logado (exceto selfbooking)'
    }, {
      value: 'ALWAYS_CURRENT_USER',
      label: 'E-mail do usuário logado (mesmo selfbooking)'
    }, {
      value: 'ALWAYS_CLIENT_CONFIG',
      label: 'Usar configuração do cliente'
    }, {
      value: 'CUSTOM',
      label: 'E-mail cadastrado (exceto selfbooking)'
    }, {
      value: 'ALWAYS_CUSTOM',
      label: 'E-mail cadastrado (mesmo selfbooking)'
    }],
    inputType: 'select',
    hint: 'Endereço de e-mail que receberá respostas de e-mails de cotação e link de pagamento',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Dados de notificação",
    name: 'sendMail_customReplyTo',
    label: 'E-mail de resposta cadastrado',
    type: 'text',
    inputType: 'email',
    renderForm: i => i?.sendMail_replyToOption == 'CUSTOM' || i?.sendMail_replyToOption == 'ALWAYS_CUSTOM',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Personalização da tela de login",
    name: 'mainLogo',
    label: 'Logotipo',
    inputType: 'inputPublicFile',
    insertable: false,
    hint: 'Logotipo da empresa. Usado na página de login e no voucher. Utilize imagem com fundo transparente (.png). (Resolução ideal: 200 x 100 pixels) O logotipo será redimensionado proporcionalmente: ● Página de login: Máximo de 200px de largura e 100px de altura. ● E-mail: Máximo de 250px de largura e 60px de altura',
    render: i => i.mainLogo && <img src={i.mainLogo}></img>,
    custom_gerarPrefix: i => i.id + '/mainlogo/',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Personalização da tela de login",
    name: 'bannerLogin',
    label: 'Banner do login',
    inputType: 'inputPublicFile',
    insertable: false,
    hint: 'Aparece na tela de login ao lado esquerdo.  (Resolução ideal: 960 x 1080 pixels)',
    render: i => i.mainLogo && <img src={i.mainLogo}></img>,
    custom_gerarPrefix: i => i.id + '/bannerLogin/',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Personalização da tela de login",
    name: 'loginWelcomeText',
    label: 'Texto de boas-vindas',
    hint: 'Texto de boas-vindas exibido na tela de login. Se não definido, trará o texto padrão.',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Configurações do Voucher",
    name: 'voucherText',
    label: 'Texto do voucher',
    type: 'textarray',
    inputType: 'textarea',
    hint: 'Inserir texto com dados de contato da agência que será incluído nos vouchers emitidos pelo sistema',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Configurações do Voucher",
    name: 'voucher_sendOnPaymentLinkConfirmation',
    label: 'Envio automático de voucher a partir de pagamento (link de pagamento online)',
    type: 'boolean',
    hint: 'Envia ou não o voucher para o e-mail informado no link de pagamento, após a confirmação do pagamento.',
    default: false,
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Configurações do Voucher",
    name: 'voucher_hidePrices',
    label: 'Ocultar preços no voucher',
    type: 'boolean',
    hint: 'Oculta valores da reserva no e-mail de voucher.',
    default: false,
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_groupName: "Configurações do Voucher",
    name: 'voucher_hideComments',
    label: 'Ocultar comentários da reserva',
    type: 'boolean',
    hint: 'Ocultaos comentários da reserva no e-mail de voucher.',
    default: false,
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }, {
    custom_group: 'terms',
    custom_groupName: "Termos e Condições",
    name: 'terms_files',
    label: 'Termos, políticas, regulamentos (B2C)',
    type: 'objectarray',
    graphQLType: '[NestedTermsFile]',
    graphQLInputType: '[NestedTermsFileInput]',
    insertable: false,
    updatable: true,
    hint: 'Visíveis pelo viajante em cotação, Motor Niara, OTABuilder, voucher, compartilhamento de reserva. Possível sobrescrever no cadastro do cliente.',
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true,
    fields: [{
      name: 'name',
      label: 'Nome',
      hint: 'Termos, condições gerais, regulamento, política de privacidade, etc',
      required: true,
      type: 'text'
    }, {
      name: 'file',
      label: 'Arquivo (PDF)',
      required: false,
      type: 'text',
      inputType: 'inputPublicFile',
      fileType: 'pdf',
      custom_gerarPrefix: i => {
        return 'clients/' + i.id + '/terms/';
      }
    }, {
      name: 'url',
      label: 'Link',
      hint: '(ignorado se existir arquivo)',
      required: false,
      type: 'text',
      fileType: 'pdf',
      custom_gerarPrefix: i => {
        return 'clients/' + i.id + '/terms/';
      }
    }]
  }, {
    custom_group: 'terms',
    custom_groupName: "Termos e Condições",
    name: 'terms_files_b2b',
    label: 'Termos, políticas, regulamentos (B2B)',
    type: 'objectarray',
    graphQLType: '[NestedTermsFile]',
    graphQLInputType: '[NestedTermsFileInput]',
    insertable: false,
    updatable: true,
    hint: 'Visíveis para usuários do cliente B2B, mas nunca ao viajante. Possível sobrescrever no cadastro do cliente.',
    'custom:updatableCurrentTenant': true,
    'custom:excludeForCurrentTenant': false,
    'custom:excludeForCurrentTenantB2C': true,
    fields: [{
      name: 'name',
      label: 'Nome',
      hint: 'Termos, condições gerais, regulamento, política de privacidade, etc',
      required: true,
      type: 'text'
    }, {
      name: 'file',
      label: 'Arquivo (PDF)',
      required: false,
      type: 'text',
      inputType: 'inputPublicFile',
      fileType: 'pdf',
      custom_gerarPrefix: i => {
        return 'clients/' + i.id + '/terms/';
      }
    }, {
      name: 'url',
      label: 'Link',
      hint: '(ignorado se existir arquivo)',
      required: false,
      type: 'text',
      fileType: 'pdf',
      custom_gerarPrefix: i => {
        return 'clients/' + i.id + '/terms/';
      }
    }]
  }, {
    custom_groupName: "Fornecedores",
    name: 'credentialTypes',
    label: 'Fornecedores',
    type: 'textarray',
    options: credentialTypesOptions,
    default: ['OMNI'],
    hint: 'Credenciais habilitadas',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': false
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_events',
    label: 'Eventos (NÃO HABILITAR!)',
    type: 'boolean',
    hint: 'Atenção: Módulos Gestão Corporativa e Eventos não funcionam bem juntos',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': true
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_flight',
    label: 'Aéreo',
    type: 'boolean',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantHideToCrs': true
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_multiaccount',
    label: 'Múltiplas contas',
    type: 'boolean',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': true
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_sellerSelfBooking',
    label: 'Self Booking de Agências',
    type: 'boolean',
    'custom:excludeForCurrentTenant': false
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_forGroups',
    label: 'Reservas para grupos',
    type: 'boolean',
    'custom:excludeForCurrentTenant': false
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_reconciliation',
    label: 'Conciliação',
    type: 'boolean',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantHideToCrs': true
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_marketplacePayment',
    label: 'Pagamento marketplace (NÃO HABILITAR NUNCA!!!)',
    type: 'boolean',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantHideToBuyer': true,
    'custom:tenantSuperuserOnly': true
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_fraudDetection',
    label: 'Anti-fraude',
    type: 'boolean',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': true
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_bee2payVcn',
    label: 'Bee2Pay VCN (NÃO HABILITAR!)',
    type: 'boolean',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': true
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_otabuilder',
    label: 'OTA Builder/Motor Niara',
    type: 'boolean',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': true
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_otabuilder_analytics',
    label: 'OTA Builder/Motor Niara - Analytics',
    type: 'boolean',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': true
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_experimental',
    label: 'Uso de funções experimentais (CUIDADO)',
    type: 'boolean',
    hint: 'Habilita auto-complete novo. Não funciona com Landing Pages. Necessário para OTA Builder.',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': true
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_checkout_experimental',
    label: 'Uso de checkout experimental',
    type: 'boolean',
    hint: 'Uso de checkout experimental (checkout V2). Necessário para OTA Builder.',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': true
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_master_credential_BOOKING_ENGINE',
    label: 'Permite uso da credencial MASTER do BE.',
    type: 'boolean',
    hint: 'Uso restrito! Não ativar sem consultar equipe NIARA.',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantHideToCrs': true,
    'custom:tenantSuperuserOnly': true
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_unified_hotel_booking_deleteme',
    label: 'Fluxo unificado de reserva (provisório)',
    type: 'boolean',
    hint: 'Uso do novo front de reservas. Só habilitar para checkout experimental V2. (flag provisória, no futuro todos devem usar)',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': true
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_unified_hotel_booking_clientUserOnly_deleteme',
    label: 'Fluxo unificado de reserva - apenas usuário do cliente (provisório)',
    type: 'boolean',
    hint: 'Uso do novo front de reservas. Só habilitar para checkout experimental V2. (flag provisória, no futuro todos devem usar)',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': true
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_vcn',
    label: 'VCN',
    type: 'boolean',
    hint: 'Opções de VCN',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantHideToCrs': true,
    'custom:tenantSuperuserOnly': true
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_hotel_availability_mapview',
    label: 'Mapa de Disponibilidade',
    type: 'boolean',
    hint: 'Ativa mapa de disponibilidade experimental V2, que utiliza OBAPI. (flag provisória, no futuro deve substituir e ser controlado pela flag do v1) ',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantHideToCrs': true,
    'custom:tenantSuperuserOnly': true
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_personSearch_deleteme',
    label: 'Habilitar lupa de busca de pessoa cadastrada (provisório)',
    type: 'boolean',
    hint: 'Habilita busca de pessoas na pesquisa de disponibilidade e cotação. (flag provisória, no futuro todos devem usar)',
    'custom:excludeForCurrentTenant': false
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_niaraAccount',
    label: 'Conta Niara',
    type: 'boolean',
    hint: 'Habilita criação de conta Niara para clientes B2C',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantHideToCrs': false
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_wallet',
    label: 'Wallet',
    type: 'boolean',
    hint: 'Wallet',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantHideToCrs': false,
    'custom:tenantSuperuserOnly': true
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_new_dashboard_deleteme',
    label: 'Novo dashboard (flag provisória)',
    type: 'boolean',
    hint: 'Soft release do dashboard novo',
    'custom:tenantSuperuserOnly': true
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_discountCoupon',
    label: 'Cupom de Desconto',
    type: 'boolean',
    hint: 'Habilita a utilização de cupom de desconto',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': false
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_manualReservation',
    label: 'Reserva Manual',
    type: 'boolean',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': false
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'distribution_disabled',
    label: 'Desabilitar motor antigo',
    hint: 'Desabilitar cadastro de distribuição, favoritos (antigo) e blacklist (antigo)',
    type: 'boolean',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': false
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_engineRuleOptIn_deleteme',
    label: 'Reserva com motor de regras',
    hint: 'Fluxo de reservas sem distribuição',
    type: 'boolean',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': false
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_room_type_grid_deleteme',
    label: 'Novo layout de grid do front (provisório)',
    type: 'boolean',
    hint: 'Habilita a visualização nova de grid de quartos. (flag provisória, no futuro todos devem usar)'
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_checkoutMultiplePayments_deleteme',
    label: 'Checkout de múltiplos pagamentos',
    type: 'boolean',
    hint: 'Novo fluxo de checkout com múltiplos pagamentos. (flag provisória, no futuro todos devem usar)'
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_creditLimit',
    label: 'Limite de crédito',
    type: 'boolean',
    hint: 'Habilita as funções de controle de crédito e bloqueio de reservas',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': false
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_enableMFA',
    label: 'Criar novos usuários com MFA',
    type: 'boolean',
    hint: 'Habilita criação do MFA automático para novos usuários',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': false
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_requireMFA',
    label: 'Exige uso de MFA para entrar no sistema',
    type: 'boolean',
    hint: 'Habilita o uso de autenticação multifator',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': false
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_requireMFATenantUsers',
    label: 'Exige uso de MFA para usuários da instalação',
    type: 'boolean',
    default: true,
    hint: 'Habilita o uso de autenticação multifator para usuários da instalação',
    'custom:excludeForCurrentTenant': true,
    'custom:tenantSuperuserOnly': false
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_crm',
    label: 'Habilitar CRM',
    type: 'boolean',
    hint: 'Habilita o envio para CRM',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': false
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_obapi_import',
    label: 'Habilitar Importação Via OBAPI',
    type: 'boolean',
    hint: 'Habilita importação via OBAPI',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': true,
    'custom:tenantHideToBuyer': true
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_obapi_import_channels',
    label: 'Canais da Importação Via OBAPI',
    type: 'textarray',
    options: [{
      value: 'BE',
      label: 'Booking Engine'
    }, {
      value: 'EXP',
      label: 'Expedia'
    }, {
      value: 'B',
      label: 'Booking'
    }, {
      value: 'DSPAM',
      label: 'Despegar-PAM'
    }],
    default: [1],
    hint: 'Habilita importação via OBAPI',
    renderForm: i => i?.modules_obapi_import ? i.modules_obapi_import_channels : false,
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': true,
    'custom:tenantHideToBuyer': true
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'sendPaymentsToPMS',
    label: 'Enviar pagamentos para PMS',
    type: 'boolean',
    hint: 'Dados da transação que ocorrerem no Niara devem ser enviados para o PMS',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': true,
    'custom:tenantHideToBuyer': true
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_hideHotels2',
    label: 'Desabilitar o front antigo',
    type: 'boolean',
    hint: 'Desabilita o hotels2',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': false
  }, {
    custom_groupName: "Módulos opcionais",
    name: 'modules_editHotelReservation',
    label: 'Edição de valores e status de reserva',
    type: 'boolean',
    hint: 'Edição manual de valores da reserva',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantSuperuserOnly': false
  }, {
    custom_groupName: "Outras configurações",
    name: 'automaticImportEnabled',
    label: 'Habilitar importação automática',
    type: 'boolean',
    hint: 'Habilita a configuração de importação automática no cadastro de credencial',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantHideToCrs': true
  }, {
    custom_groupName: "Outras configurações",
    name: 'omnibeesProxyEnabled',
    label: 'Usa o proxy pull',
    type: 'boolean',
    hint: 'Habilita a configuração de credencial para uso do proxy',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantHideToCrs': true
  }, {
    custom_groupName: "Outras configurações",
    name: 'useHotelV1',
    label: 'Usar o hotel v1',
    type: 'boolean',
    'custom:excludeForCurrentTenant': false,
    deprecated: true,
    render: false
  }, {
    custom_groupName: "Outras configurações",
    name: 'mandatoryResponsible',
    label: 'Responsável obrigatório ao reservar',
    type: 'boolean',
    hint: 'Obs: não funciona para landing pages e self-booking. Só é usado no fluxo de reserva versão "/hotels2" - usar regra de campos da reserva para as telas novas.',
    'custom:excludeForCurrentTenant': false,
    'custom:tenantHideToCrs': true
  }, {
    custom_groupName: "Outras configurações",
    name: 'msClarityEnabled',
    label: 'Habilita o MS Clarity',
    type: 'boolean',
    'custom:excludeForCurrentTenant': true,
    'custom:tenantHideToCrs': true
  }, {
    custom_groupName: "Outros dados",
    label: 'UserPoolId',
    name: 'userPoolId',
    type: 'text',
    render: false,
    updatable: false,
    'custom:excludeForCurrentTenant': false
  }, {
    custom_groupName: "Outros dados",
    label: 'UserPoolId',
    name: 'userPoolClientId',
    type: 'text',
    render: false,
    updatable: false,
    'custom:excludeForCurrentTenant': true
  }, {
    custom_groupName: "Outros dados",
    name: 'AWSStackId',
    render: false,
    updatable: false,
    'custom:excludeForCurrentTenant': true
  }, {
    custom_groupName: "Outros dados",
    name: 'DevOrHomologOrProd',
    updatable: false,
    'custom:excludeForCurrentTenant': false
  }, {
    custom_groupName: 'Produto',
    name: 'productName',
    label: 'Produto',
    updatable: false,
    'custom:excludeForCurrentTenant': false
  }, {
    custom_groupName: 'Produto',
    name: 'subscriptionPlanName',
    label: 'Plano',
    updatable: false,
    'custom:excludeForCurrentTenant': false
  }, {
    custom_groupName: 'Produto',
    name: 'subscriptionPlanPurchaseDate',
    label: 'Data de Contratação',
    type: 'date',
    updatable: false,
    'custom:excludeForCurrentTenant': false
  }, {
    custom_groupName: 'Integração MyPortal (Omnibees)',
    name: 'omniChannelId',
    label: 'ID do cliente',
    hint: 'Identificador do cliente (operadora) na base da Omnibees',
    type: 'text',
    updatable: true,
    'custom:excludeForCurrentTenant': false
  }, {
    custom_groupName: "Configurações do Voucher",
    label: 'Desabilitar na Cotação botão que não permite fazer reservas',
    hint: 'Este botão habilitado, na cotação irá permitir que o link esteja disponível sempre para ser reservado. Retirando a opção de apenas visualização.',
    name: 'DisableButtonBookBlockedEnable',
    type: 'boolean',
    updatable: true,
    'custom:excludeForCurrentTenant': false,
    'custom:updatableCurrentTenant': true
  }]
});