import React from 'react';
import { NiaraIcon, NiaraIconNameType } from '@niaratech/niara-icons';
import * as S from './styles';
export type PromoCodeProps = {
  name: string;
  statIcon?: NiaraIconNameType;
  value: string;
  placeholder: string;
  setValue: (e: string) => void;
  dataTestId?: string;
};
const SimpleTextInput: React.FC<PromoCodeProps> = ({
  name,
  statIcon,
  value,
  placeholder,
  setValue,
  dataTestId
}) => {
  return <S.Container>
      <S.Icon>
        <NiaraIcon icon={statIcon ?? 'sysCoupon'} size={18} />
      </S.Icon>

      <S.InputText type="text" name={name} value={value} placeholder={placeholder} onChange={e => setValue(e.target.value)} data-testid={dataTestId} />
    </S.Container>;
};
export default SimpleTextInput;