import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import { credentialTypesOptions } from '../credential';
declare module '@niaratech/devcase-crud/dist/types' {
  interface Field<T = any, E = any> {
    conditions?;
  }
}
const conditionsOptions = [{
  value: 'clientIs',
  label: 'Cliente é'
}, {
  value: 'clientIsNot',
  label: 'Cliente não é'
}, {
  value: 'tagIs',
  label: 'Marcador é'
}, {
  value: 'tagIsNot',
  label: 'Marcador não é'
}, {
  value: 'landingPageIs',
  label: 'Landing Page é'
}, {
  value: 'landingPageIsNot',
  label: 'Landig Page não é'
}];
const itemConditionsOptions = [{
  value: 'reservationDate',
  label: 'Data da compra'
}, {
  value: 'startDate',
  label: 'Início do serviço'
}, {
  value: 'endDate',
  label: 'Fim do serviço'
}, {
  value: 'duration',
  label: 'Duração'
}, {
  value: 'antecedencia',
  label: 'Antecedencia'
}, {
  value: 'credential',
  label: 'Credencial'
}, {
  value: 'ratePlan',
  label: 'Tipo de tarifa'
}, {
  value: 'refundable',
  label: 'Política de Cancelamento'
}];
const credentialTypeList = ['B2BR', 'SHOP', 'TRND', 'OMNI', 'EXPD', 'NIAR'];
export default fillCrudMetadataGaps({
  label: 'Motor de regras de resultado de hotel',
  labelPlural: 'Motores de regras de resultado de hotel',
  name: 'BusinessConfiguration',
  path: '/businessConfiguration',
  deletable: false,
  updatable: true,
  insertable: true,
  authorization: authorization('clientConsultant', 'admin'),
  dynamoDB: {
    tableName: 'SpearBusinessConfiguration'
  },
  columnNames: ['id', 'name', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    render: false,
    id: true
  }, {
    label: 'Nome',
    name: 'name',
    type: 'text'
  }, {
    label: 'Ativo',
    name: 'enabled',
    type: 'boolean',
    default: true
  }, {
    label: 'Nova versão',
    name: 'new',
    type: 'boolean',
    default: true,
    render: false
  }, {
    label: 'Tipo de configuração',
    name: 'type',
    type: 'text',
    default: 'POST',
    updatable: false,
    options: [{
      value: 'PRE',
      label: 'Antes da busca'
    }, {
      value: 'POST',
      label: 'Após a busca'
    }],
    render: false
  }, {
    label: 'Prioridade',
    name: 'priority',
    type: 'int',
    inputType: 'select',
    options: [{
      label: 'Muito baixa',
      value: 0
    }, {
      label: 'Baixa',
      value: 1
    }, {
      label: 'Média',
      value: 2
    }, {
      label: 'Alta',
      value: 3
    }, {
      label: 'Muito alta',
      value: 4
    }],
    default: 0
  }, {
    custom_groupName: 'Contexto',
    label: 'Condições',
    name: 'contextTriggers',
    type: 'objectarray',
    graphQLType: '[BCTriggerList]',
    graphQLInputType: '[BCTriggerListInput]',
    fields: [{
      name: 'triggers',
      label: 'Codições de disparo',
      type: 'objectarray',
      graphQLType: '[BCTriggerListItem]',
      graphQLInputType: '[BCTriggerListItemInput]',
      fields: [{
        name: 'context',
        label: 'Tipo de contexto',
        inputType: 'inputTrigger',
        type: 'object',
        graphQLType: 'BCTriggerItem',
        graphQLInputType: 'BCTriggerItemInput',
        fields: [{
          name: 'option',
          label: 'Opção'
        }, {
          name: 'value',
          label: 'Valor'
        }],
        conditions: [{
          label: 'Landing Page é',
          option: 'landingPageIs',
          valueType: 'InputLandingPage'
        }, {
          label: 'Cliente é',
          option: 'clientIs',
          valueType: 'InputClient'
        }, {
          label: 'Credencial é',
          option: 'credentialIs',
          valueType: 'InputCredential',
          credentialTypeList: credentialTypeList
        }, {
          label: 'Fornecedor é',
          option: 'supplierIs',
          valueType: 'InputGeneric',
          inputType: 'select',
          type: 'text',
          options: credentialTypesOptions.filter(el => credentialTypeList.indexOf(el.value) !== -1),
          default: 'OMNI'
        }, {
          label: 'Data maior ou igual a',
          option: 'dateGte',
          valueType: 'InputDate'
        }, {
          label: 'Data menor ou igual a',
          option: 'dateLte',
          valueType: 'InputDate'
        }, {
          label: 'Data de check-in maior ou igual a',
          option: 'checkinDateGte',
          valueType: 'InputDate'
        }, {
          label: 'Data check-in menor ou igual a',
          option: 'checkinDateLte',
          valueType: 'InputDate'
        }, {
          label: 'Quantidade de noites maior ou igual a',
          option: 'nigthsCountGte',
          type: 'int',
          valueType: 'InputGeneric',
          min: 1,
          default: 1
        }, {
          label: 'Quantidade de noites menor ou igual a',
          option: 'nigthsCountLte',
          type: 'int',
          valueType: 'InputGeneric',
          min: 1,
          default: 1
        }, {
          label: 'Antecedência maior ou igual a',
          option: 'antecedenceGte',
          type: 'int',
          valueType: 'InputGeneric',
          min: 1,
          default: 1
        }, {
          label: 'Antecedência menor ou igual a',
          option: 'antecedenceLte',
          type: 'int',
          valueType: 'InputGeneric',
          min: 1,
          default: 1
        }]
      }]
    }]
  }, {
    custom_groupName: 'Ações',
    label: 'Ações',
    name: 'actions',
    type: 'objectarray',
    graphQLType: '[BCActionListItem]',
    graphQLInputType: '[BCActionListItemInput]',
    fields: [{
      name: 'paymentConditions',
      label: 'Forma de pagamentos',
      type: 'textarray',
      inputType: 'paymentCondition',
      required: true
    }, {
      name: 'loyaltyReward',
      label: 'Fidelidade - Recompensa',
      type: 'text',
      inputType: 'loyaltyReward',
      required: false,
      showNullOption: true,
      default: null
    }, {
      name: 'loyaltyRedeem',
      label: 'Fidelidade - Resgate',
      type: 'text',
      inputType: 'loyaltyRedeem',
      required: false,
      showNullOption: true,
      default: null
    }]
  }]
});