import { HotelCalendarPayload } from '@niarab2c/frontend-commons/src/types/hotels';
import { useImmutableFunction, usePreviousIfEquals } from '@niaratech/niara-react-components/src';
import { addMonths, endOfMonth, format, isBefore, parseISO, startOfMonth } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import type { DatePickerProps } from '../../microComponents';
import type { DetailCalendarDatePickerItems } from './HotelCriteria';
export type DetailsToCalendarParams = {
  propertyId: string;
  contentType: 'property' | 'region';
  hotelId: string;
  adults: number;
  children?: number;
  childrenAges?: number[];
  promoCode?: string;
  clientId: string;
  maxDate: string | Date;
  minDate: string | Date;
};
const useDetailsToCalendar = (_params: DetailsToCalendarParams, propsDetailCalendarStatusToDatePicker?: (c: HotelCalendarPayload['criteria']) => Promise<DetailCalendarDatePickerItems[]>): [DatePickerProps['detailsToCalendar'], (date: Date | string) => void, boolean] => {
  //States que controlam a busca de detalhes das datas do calendário
  const [detailsToCalendar, setDetailsToCalendar] = useState<DatePickerProps['detailsToCalendar']>([]);
  const [loadingCalendar, setLoadingCalendar] = useState<boolean>(false);

  // a instância de stableParams só muda quando algum parâmetro alterar
  const stableParams = usePreviousIfEquals(_params);

  // ref que vai conter o onShownDateChange atualizado. Instância muda junto com stableParams.
  const onShownDateChangeRef = useRef<DatePickerProps['onShownDateChange']>();

  // não atualiza se mudar a instância de propsDetailCalendarStatusToDatePicker
  const detailCalendarStatusToDatePicker = useImmutableFunction(propsDetailCalendarStatusToDatePicker);
  const hasHotel = stableParams?.contentType == 'property' && stableParams?.propertyId && true || stableParams?.hotelId && true || false;
  const searchEnabled = detailCalendarStatusToDatePicker != null && stableParams?.clientId && hasHotel && true;
  useEffect(() => {
    // estes parâmetros são resetados quando muda a instância de stableParams
    let ignore = false;
    const {
      promoCode,
      propertyId,
      adults,
      children,
      childrenAges,
      clientId,
      contentType,
      hotelId
    } = stableParams;
    const maxDate = typeof stableParams?.maxDate === 'string' ? parseISO(stableParams?.maxDate) : stableParams?.maxDate;
    const minDate = typeof stableParams?.minDate === 'string' ? parseISO(stableParams?.minDate) : stableParams?.minDate;
    const allMonthsSearched: number[] = [];
    let loadingCount = 0;
    const searchMonth = async (startOfMonthDate: Date) => {
      if (!detailCalendarStatusToDatePicker) {
        return;
      }
      if (allMonthsSearched.indexOf(startOfMonthDate.getTime()) >= 0) {
        // já buscou este mês
        return;
      }
      // marca mês como buscado, para não repetir a busca
      allMonthsSearched.push(startOfMonthDate.getTime());
      const startRangeSearch: string = (() => {
        if (minDate && isBefore(startOfMonthDate, minDate)) {
          return format(minDate, 'yyyy-MM-dd');
        }
        return format(startOfMonthDate, 'yyyy-MM-dd');
      })();
      const endRangeSearch: string = (() => {
        const endOfMonthDate = endOfMonth(startOfMonthDate);
        if (maxDate && isBefore(maxDate, endOfMonthDate)) {
          return format(maxDate, 'yyyy-MM-dd');
        }
        return format(endOfMonthDate, 'yyyy-MM-dd');
      })();
      if (startRangeSearch >= endRangeSearch) {
        return;
      }
      const datesToSearch = {
        startDate: startRangeSearch,
        endDate: endRangeSearch
      };
      const quantity = 1;
      const payload: HotelCalendarPayload['criteria'] = {
        time: datesToSearch,
        destinations: {
          propertyId,
          contentType,
          hotelIds: [hotelId] // é obrigatório, mas não deveria se tivesse propertyId
        },
        promoCode,
        occupancy: {
          adults: adults ?? 1,
          children: children ?? 0,
          childrenAges: childrenAges || [],
          quantity: quantity
        },
        clientId: clientId
      };
      setLoadingCalendar(true);
      loadingCount++;
      detailCalendarStatusToDatePicker(payload).then(results => {
        if (ignore) return;
        if (!results || !results?.length) return; // Caso não tenha retorno, não segue com o processamento

        setDetailsToCalendar(oldDetails => oldDetails.concat(results.filter(k => oldDetails.findIndex(i => i.date == k.date) < 0)));
      }).finally(() => {
        if (ignore) return;
        loadingCount--;
        if (loadingCount == 0) {
          setLoadingCalendar(false);
        }
      });
    };
    onShownDateChangeRef.current = (dateOrStr: Date | string) => {
      const leftMonth = typeof dateOrStr == 'string' ? parseISO(dateOrStr) : dateOrStr;
      if (leftMonth) {
        const leftMonthStart = startOfMonth(leftMonth);
        const rightMonthStart = startOfMonth(addMonths(leftMonth, 1));
        searchMonth(leftMonthStart);
        searchMonth(rightMonthStart);
      }
    };
    return () => {
      setDetailsToCalendar([]);
      setLoadingCalendar(false);
      onShownDateChangeRef.current = undefined;
      ignore = true;
    };
  }, [detailCalendarStatusToDatePicker, stableParams]);
  return [detailsToCalendar, searchEnabled ? onShownDateChangeRef.current : undefined, loadingCalendar];
};
export default useDetailsToCalendar;