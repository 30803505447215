import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Audit',
  labelPlural: 'Audit',
  name: 'Audit2',
  insertable: false,
  updatable: false,
  deletable: false,
  authorization: authorization('admin'),
  columnNames: ['id'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    render: false
  }],
  filter: []
});