import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Cartão de Crédito',
  labelPlural: 'Cartões de Crédito',
  name: 'CreditCard',
  deletable: true,
  updatable: true,
  authorization: authorization('consultant', 'admin'),
  dynamoDB: {
    tableName: 'SpearDebugData'
  },
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    render: false
  }, {
    name: 'clientId',
    label: 'Id do cliente',
    render: false,
    required: true,
    updatable: false
  }, {
    label: 'Descrição',
    name: 'alias',
    type: 'text',
    insertable: true,
    updatable: false,
    required: true
  }, {
    label: 'Data de expiração',
    name: 'expiryDate',
    type: 'text',
    insertable: false,
    updatable: false,
    required: true,
    render: i => {
      return i.expiryDate?.length === 4 ? i.expiryDate.substring(0, 2) + '/' + i.expiryDate.substring(2) : i.expiryDate;
    }
  }, {
    label: 'Número',
    name: 'maskedNumber',
    type: 'text',
    insertable: false,
    updatable: false
  }, {
    label: 'Ativo',
    name: 'enabled',
    type: 'boolean',
    insertable: true,
    updatable: true,
    default: true
  }],
  createParams: [{
    name: 'creditCard',
    label: 'Dados do cartão',
    graphQLType: 'CreditCard',
    graphQLInputType: 'CreditCardInput',
    type: 'object',
    fields: [{
      name: 'brand',
      label: 'Bandeira do cartão',
      required: true,
      inputType: 'select',
      options: [{
        label: 'Amex',
        value: 'AMEX'
      }, {
        label: 'Aura',
        value: 'AURA'
      }, {
        label: 'Diners',
        value: 'DINERS'
      }, {
        label: 'Elo',
        value: 'ELO'
      }, {
        label: 'Hiper',
        value: 'HIPER'
      }, {
        label: 'Hipercard',
        value: 'HIPERCARD'
      }, {
        label: 'JCB',
        value: 'JCB'
      }, {
        label: 'MasterCard',
        value: 'MASTERCARD'
      }, {
        label: 'Visa',
        value: 'VISA'
      }]
    }, {
      name: 'number',
      label: 'Número do cartão',
      required: true,
      minLength: 13
    }, {
      name: 'holder',
      label: 'Nome no cartão',
      required: true
    }, {
      name: 'expiryDate',
      label: 'Data de expiração (MMAA)',
      required: true,
      placeholder: 'MMAA',
      mask: '9999',
      pattern: '^(0?[1-9]|1[012])([2-3][0-9])$',
      inputMode: 'numeric',
      minLength: 4,
      step: 1
    }, {
      name: 'cvv',
      label: 'Código de segurança',
      mask: '999999',
      inputMode: 'numeric',
      required: true
    }]
  }, {
    name: 'secretCode',
    minLength: 6,
    label: 'Chave secreta',
    placeholder: 'Mínimo de 6 caracteres',
    required: true,
    inputType: 'password',
    hint: 'Esta senha de será solicitada a cada compra em que o cartão é utilizado. Ela não pode ser modificada.'
  }],
  filter: [{
    name: 'client_id_eq',
    field: 'clientId',
    op: 'eq'
  }]
});