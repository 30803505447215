import styled, { keyframes, css } from 'styled-components'
import { BREAKPOINT_MD, BREAKPOINT_SM } from '../../theme/ota'
import type { Variant } from './Modal'

interface Props {
  mobileVariant?: Variant
  tabletVariant?: Variant
  desktopVariant?: Variant
  sidePosition?: 'left' | 'right'
  hasTitle?: boolean
  headerSize?: string
  zIndex?: number
  transitionReverse?: boolean
  padding?: string
  margin?: string
  maxWidth?: string
  paddingZero?: boolean
}

const _mediaQueryForStyle =
  (variant: Variant) =>
  ({ mobileVariant = 'modal', tabletVariant, desktopVariant = 'modal' }: Props) => {
    if (!tabletVariant) tabletVariant = desktopVariant
    if (mobileVariant === variant && tabletVariant === variant && desktopVariant === variant) {
      return 'all'
    } else if (mobileVariant === variant && mobileVariant === tabletVariant) {
      return `(max-width: calc(calc(${BREAKPOINT_MD}) - 1px))`
    } else if (mobileVariant === variant) {
      return `(max-width: calc(calc(${BREAKPOINT_SM}) - 1px))`
    } else if (tabletVariant === variant && tabletVariant === desktopVariant) {
      return `(min-width: calc(${BREAKPOINT_SM}))`
    } else if (tabletVariant === variant) {
      return `(min-width: calc(${BREAKPOINT_SM})) and (max-width: calc(calc(${BREAKPOINT_SM}) - 1px))`
    } else if (desktopVariant === variant) {
      return `(min-width: calc(${BREAKPOINT_MD}))`
    } else {
      return 'not all'
    }
  }

export const ModalCloseButton = styled.button<Props>`
  &&& {
    border: none;
    display: none;

    @media ${_mediaQueryForStyle('modal')} {
      background-color: transparent;
      display: block;
      padding: 10px;
      position: absolute;
      right: -3px;
      top: 10px;

      @media (max-width: calc(calc(${BREAKPOINT_SM}) - 1px)) {
        .close-icon {
          line {
            stroke: ${({ theme }) => theme.colors?.text};
          }
        }
      }
      @media (min-width: calc(${BREAKPOINT_SM})) {
        top: -35px;
        transform: scale3d(1.5, 1.5, 1);
        .close-icon {
          line {
            stroke: ${({ theme }) => {
              return theme.colors?.white
            }};
          }
        }
      }
    }
    @media ${_mediaQueryForStyle('full')} {
      background-color: transparent;
      display: block;
      height: 32px;
      position: fixed;
      right: 10px;
      top: 9px;
      width: 32px;
      /* position: absolute; */
      z-index: 9999999999999;
      /* transform: scale3d(2, 2, 1); */
      .close-icon {
        line {
          stroke: ${({ theme }) => theme.colors?.text};
        }
      }
      /* &.hidden, //hack para a animação de show/hide
    &.appearing {
      position: absolute;
    } */
    }
    @media ${_mediaQueryForStyle('side')} {
      background-color: transparent;
      display: block;
      height: 32px;
      position: fixed;

      left: ${({ sidePosition, maxWidth }) =>
        sidePosition === 'left' ? `calc(${maxWidth ?? '640px'} - 45px)` : 'unset'};
      right: ${({ sidePosition }) => (sidePosition === 'right' ? `13px` : 'unset')};

      top: 9px;
      width: 32px;
      z-index: 9999999999999;
      .close-icon {
        line {
          stroke: ${({ theme }) => theme.colors?.text};
        }
      }
    }
  }
`

export const ModalDialog = styled.div<Props>`
  &&& {
    display: none;

    @media ${_mediaQueryForStyle('modal')} {
      align-items: center;
      display: flex;
      justify-content: center;
      min-height: 100%;
    }

    @media ${_mediaQueryForStyle('full')} {
      display: block;
      min-height: 100%;
    }

    @media ${_mediaQueryForStyle('side')} {
      display: block;
      min-height: 100%;
    }

    @media ${_mediaQueryForStyle('block')} {
      display: block;
    }
  }
`

const modalContentKeyframes = keyframes`
  from{
    opacity: 0;
    transform: translate3d(0, -30%, 0);
  }
  to{
    opacity:1;
  }
`

export const ModalContent = styled.div<Props>`
  &&& {
    display: none;

    @media ${_mediaQueryForStyle('modal')} {
      animation: ${modalContentKeyframes} 0.4s ease-in-out;
      background-color: white;
      display: block;
      min-height: 200px;
      position: relative;

      @media (max-width: calc(calc(${BREAKPOINT_SM}) - 1px)) {
        margin: ${({ margin }) => margin ?? '20px 0'};
        padding: ${({ padding }) => padding ?? '40px 30px 20px'};
        width: 296px;
      }

      @media (min-width: calc(${BREAKPOINT_SM})) {
        border-radius: 4px;
        margin: ${({ margin }) => margin ?? '50px'};
        max-width: 1000px;
        padding: ${({ padding, paddingZero }) => (padding && !paddingZero ? '20px 20px' : '0px')};
      }
      &.hidden {
        opacity: 0;
        transform: translate3d(0, -30%, 0);
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
      }
    }
    @media ${_mediaQueryForStyle('full')} {
      display: block;
      padding: ${({ padding }) => padding ?? '20px'};
      padding-top: ${({ hasTitle }) => (hasTitle ? `70px` : `0`)};
      position: relative;
    }
    @media ${_mediaQueryForStyle('side')} {
      display: block;
      padding: ${({ padding }) => padding ?? '20px'};
      padding-top: ${({ hasTitle }) => (hasTitle ? `70px` : `20px`)};
      position: relative;
    }
    @media ${_mediaQueryForStyle('block')} {
      display: block;
    }
  }
`

const fullContainerKeyframes = keyframes`
  from{
    transform: translate3d(0, 100%, 0);
    /* opacity:0; */
  }
  to{
    /* opacity:1; */
  }
`
const fullContainerKeyframesReverse = keyframes`
  from{
    transform: translate3d(0, -100%, 0);
    /* opacity:0; */
  }
  to{
    /* opacity:1; */
  }
`
const sideContainerKeyframes = ({ sidePosition }: Pick<Props, 'sidePosition'>) => keyframes`
  from{
    opacity:0;
    transform: translate3d(${sidePosition === 'left' ? '-100%' : '100%'}, 0, 0);
  }
  to{
    opacity:1;
  }
`

const blockContainerKeyframes = keyframes`
  from{
    opacity:0;
  }
  to{
    opacity:1;
  }
`

export const ModalContainer = styled.div<Props>`
  &&& {
    &.appearing {
      pointer-events: none;
    }

    display: none;

    @media ${_mediaQueryForStyle('modal')} {
      align-items: center;
      bottom: 0;
      display: block;
      justify-content: center;
      left: 0;
      outline: 0;
      overflow-x: hidden;
      overflow-y: auto;
      position: fixed;
      right: 0;
      top: 0;
      z-index: ${({ zIndex }) => zIndex + 1};
    }

    @media ${_mediaQueryForStyle('full')} {
      animation: ${({ transitionReverse }) =>
          transitionReverse ? fullContainerKeyframesReverse : fullContainerKeyframes}
        0.4s ease-in-out;
      background-color: white;
      bottom: 0;
      display: block;
      left: 0;
      outline: 0;
      overflow-x: hidden;
      overflow-y: auto;
      position: fixed;
      right: 0;
      top: ${({ headerSize }) => headerSize ?? `0`};
      z-index: ${({ zIndex }) => zIndex + 1};

      /* &.appearing, //hack para a animação de show/hide
    &.hidden {
      overflow-y: hidden;
      top: 0;
    } */

      &.hidden {
        transition: transform 0.3s ease-in-out;
        transform: translate3d(0, ${({ transitionReverse }) => (transitionReverse ? '-100%' : '100%')}, 0);
        /* opacity: 0; */
      }
    }
    @media ${_mediaQueryForStyle('side')} {
      animation: ${({ sidePosition }) => sideContainerKeyframes({ sidePosition })} 0.4s ease-in-out;
      background-color: white;
      bottom: 0;
      display: block;
      ${({ maxWidth }) =>
        maxWidth
          ? css`
              width: ${maxWidth};
            `
          : css`
              width: 640px;
            `}
      outline: 0;
      overflow-x: hidden;
      overflow-y: auto;
      position: fixed;
      right: ${({ sidePosition }) => (sidePosition === 'right' ? `0` : ``)};
      left: ${({ sidePosition }) => (sidePosition === 'left' ? `0` : ``)};
      top: 0;
      z-index: ${({ zIndex }) => zIndex + 1};

      &.hidden {
        opacity: 0;
        transform: translate3d(${({ sidePosition }) => (sidePosition === 'left' ? '-100%' : '100%')}, 0, 0);
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
      }
    }

    @media ${_mediaQueryForStyle('block')} {
      display: block;
      &.hidden {
        display: none;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

const modalBackdropKeyframes = keyframes`
  from{
    opacity:0;
  }
  to{
    opacity:.5;
  }
`

export const ModalBackdrop = styled.div<Props>`
  &&& {
    display: none;

    @media ${_mediaQueryForStyle('modal')}, ${_mediaQueryForStyle('side')} {
      animation: ${modalBackdropKeyframes} 0.2s ease-in-out;
      background-color: #000;
      bottom: 0;
      display: block;
      left: 0;
      opacity: 0.5;
      position: fixed;
      right: 0;
      top: 0;
      z-index: ${({ zIndex }) => zIndex};

      &.hidden {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }
    }
  }
`

export const ModalTitle = styled.div<Props>`
  display: none;

  @media ${_mediaQueryForStyle('modal')} {
    border-bottom: 1px solid ${({ theme }) => theme?.colors?.primary};
    display: block;
    margin-bottom: 20px;
    margin-top: -10px;
    padding: 20px 20px 10px 20px;
  }
  @media ${_mediaQueryForStyle('full')} {
    align-items: center;
    background-color: white;
    border-bottom: 1px solid #f4f4f4;
    box-sizing: border-box;
    display: flex;
    font-weight: 700;
    height: 50px;
    left: 0;
    padding-left: 20px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: ${({ zIndex }) => zIndex};

    /* &.hidden, //hack para a animação de show/hide
    &.appearing {
      position: absolute;
    } */
  }
  @media ${_mediaQueryForStyle('side')} {
    z-index: ${({ zIndex }) => zIndex + 2};
    align-items: center;
    background-color: white;
    border-bottom: 1px solid #f4f4f4;
    box-sizing: border-box;
    display: flex;
    font-weight: 700;
    height: 50px;
    ${({ maxWidth }) =>
      css`
        width: ${maxWidth ?? '640px'};
      `};
    padding-left: 20px;
    position: fixed;
    right: ${({ sidePosition }) => (sidePosition === 'right' ? `0` : `unset`)};
    left: ${({ sidePosition }) => (sidePosition === 'left' ? `0` : `unset`)};
    top: 0;

    /* &.hidden, //hack para a animação de show/hide
    &.appearing {
      position: absolute;
    } */
  }
`

export const CustomTitleWrapper = styled.div<Props>`
  &&& {
    @media ${_mediaQueryForStyle('full')} {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      ${({ paddingZero }) =>
        paddingZero
          ? css`
              margin-bottom: 20px;
            `
          : css``};
    }
    @media ${_mediaQueryForStyle('side')} {
      min-height: 50px;
      z-index: ${({ zIndex }) => zIndex + 2};
      position: fixed;
      top: 0;
      background-color: white;
      border-bottom: 1px solid #f4f4f4;
      ${({ maxWidth }) =>
        css`
          width: ${maxWidth ?? '640px'};
        `};

      ${({ paddingZero }) =>
        !paddingZero &&
        css`
          margin-left: -20px;
          margin-right: -20px;
        `};
    }
  }
`
