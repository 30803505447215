import callApi from '@niarab2c/frontend-commons/src/util/callApi';
import _pick from 'lodash/pick';
import { useCallback, useMemo } from 'react';
import { useAsync } from '@niaratech/niara-react-components/src';
import fetchHotelDetails from '@niarab2c/frontend-commons/src/util/fetchHotelDetails';
import matchSorter from 'match-sorter';
import { useSelector } from '../app/base';
const FIELDS_TO_PICK = (['id', 'address', 'city', 'state', 'country', 'name', 'type'] as const);
type FieldsKey = (typeof FIELDS_TO_PICK)[number];
type DestinationSearchItem = {
  id: string;
  label: string;
  value: {
    hotelIds?: string[];
    propertyId: string;
    contentType: 'property' | 'region';
  };
} & Record<FieldsKey, string>;
const destinationSearchV2 = (clientId, tenantId, landingPageId) => async (query): Promise<DestinationSearchItem[]> => {
  let response = [];
  response = await callApi('niara-spear-content-integrations', '/content/search', 'get', {
    params: {
      q: query,
      clientId: clientId ?? undefined,
      tenantId: tenantId ?? undefined,
      landingPageId: landingPageId ?? undefined
    }
  });
  const results = response['map'] ? response : response['results'];
  return results?.map(r => {
    const hotelIds = r.type === 'hotel' && r.omniId ? [`HOTEL_OMNI_${r.omniId}`] : undefined;
    return {
      ..._pick(r, FIELDS_TO_PICK),
      value: {
        hotelIds,
        propertyId: r.id,
        contentType: r.contentType
      },
      label: r.name
    };
  });
};
type UseDestinationSearchProps = {
  clientId?: string;
};
const useDestinationSearch = ({
  clientId: clientIdCriteria
}: UseDestinationSearchProps): ReturnType<typeof destinationSearchV2> => {
  const {
    tenantId,
    clientId,
    landingPageId,
    user
  } = useSelector(root => {
    return {
      tenantId: root.core?.tenantId,
      clientId: root.core?.clientId || clientIdCriteria,
      landingPageId: root.storefrontConfig?.storefront?.landingPageId,
      user: root?.authentication?.user
    };
  });
  const [allowedHotels] = useAsync(() => user?.allowedHotels?.length > 0 && Promise.all(user?.allowedHotels?.map(async hotel => {
    const omniId = `HOTEL_OMNI_${hotel?.omniId}`;
    return fetchHotelDetails(omniId, undefined, tenantId, undefined).then(r => ({
      id: hotel?.id,
      address: [r?.address?.street, r?.address?.cityName, r?.address?.state, r?.address?.country].filter(Boolean).join(', '),
      city: r?.address?.cityName,
      state: r?.address?.state,
      country: r?.address?.country,
      name: r?.name,
      type: 'hotel',
      label: r.name,
      value: {
        hotelIds: [omniId],
        propertyId: hotel?.id,
        contentType: 'property'
      }
    } as DestinationSearchItem));
  })).then(response => response), [tenantId, user?.allowedHotels]);
  const allowedDestinationSearch = useCallback(async (query?: string): Promise<DestinationSearchItem[]> => {
    const results = matchSorter(allowedHotels ?? [], query, {
      keys: ['name']
    });
    return results;
  }, [allowedHotels]);
  return useMemo(() => {
    if (user?.allowedHotels?.length > 0) {
      return allowedDestinationSearch;
    }
    return destinationSearchV2(clientId || clientIdCriteria, tenantId, landingPageId);
  }, [allowedDestinationSearch, clientId, clientIdCriteria, landingPageId, tenantId, user?.allowedHotels?.length]);
};
export default useDestinationSearch;