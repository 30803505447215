import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Propriedade',
  labelPlural: 'Propriedades',
  name: 'Property',
  deletable: false,
  updatable: true,
  insertable: false,
  searchable: true,
  dynamoDB: {
    tableName: 'SpearProperty',
    partitionKey: 'id',
    sortKey: null,
    indexes: [{
      indexName: 'expdId-index',
      indexedProp: 'expdId'
    }, {
      indexName: 'omniId-index',
      indexedProp: 'omniId'
    }, {
      indexName: 'b2brId-index',
      indexedProp: 'b2brId'
    }, {
      indexName: 'trndId-index',
      indexedProp: 'trndId'
    }, {
      indexName: 'googId-index',
      indexedProp: 'googId'
    }, {
      indexName: 'hotbId-index',
      indexedProp: 'hotbId'
    }]
  },
  authorization: authorization('unauthenticated', false),
  columnNames: ['id', 'name', 'address'],
  fields: [{
    label: 'Id',
    name: 'id',
    id: true
  }, {
    label: 'Id Expedia',
    name: 'expdId'
  }, {
    label: 'Id Omnibees',
    name: 'omniId'
  }, {
    label: 'Id Trend',
    name: 'trndId'
  }, {
    label: 'Id B2B',
    name: 'b2brId'
  }, {
    label: 'Id Google',
    name: 'googId'
  }, {
    label: 'Id HotelBeds',
    name: 'hotbId'
  }, {
    label: 'Nome',
    name: 'name',
    required: true
  }, {
    label: 'Tipo',
    name: 'type',
    required: true
  }, {
    label: 'Endereço',
    name: 'address',
    required: true
  }, {
    label: 'Cidade',
    name: 'city',
    required: true
  }, {
    label: 'Estado',
    name: 'state',
    required: true
  }, {
    label: 'País',
    name: 'country',
    required: true
  }, {
    label: 'Códido do País',
    name: 'countryCode',
    required: true
  }, {
    label: 'Códido do Estado',
    name: 'stateCode',
    required: true
  }, {
    label: 'Coordenadas',
    name: 'coordinates',
    type: 'object',
    graphQLType: 'NestedPropertyCoordinates',
    graphQLInputType: 'NestedPropertyCoordinatesInput',
    fields: [{
      label: 'Latitude',
      name: 'lat',
      type: 'float'
    }, {
      label: 'Longitude',
      name: 'lon',
      type: 'float'
    }]
  }, {
    label: 'Código IATA para área metropolitada',
    name: 'iata',
    type: 'text'
  }]
});