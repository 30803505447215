import { RoomRate } from '@niarab2c/frontend-commons/src/types/hotels';
import { NiaraIcon } from '@niaratech/niara-icons';
import { printPercentage } from '@niaratech/niara-js-commons';
import { Tooltip } from '@niaratech/niara-react-components';
import React from 'react';
import { IconButton } from '@niaratech/niara-react-components/src/controls/AppWrapper/styles';
import { TaxIncluded } from '../..';
import { SelectButton, Text, useHotelSearchContext, useTheme } from '../../..';
import Badge from '../../../microComponents/Badge';
import exhibitionChildrensLabel from '../../../util/Occupation/exhibitionChildrensLabel';
import Policy, { PolicyProps } from '../../Policy';
import Price from '../../Price';
import * as S from './styles';
export type CartRoomProps = {
  hotelName: string;
  cityName: string;
  stateName: string;
  roomTitle: string;
  guests: number;
  totalValue: number;
  currency: string;
  nights: number;
  taxes: number;
  policy?: PolicyProps;
  children?: number;
  childrenAges?: number[];
  roomRates: [RoomRate];
  roomImage?: string;
  hotelId?: string;
  startDate?: string;
  endDate?: string;
  onRemove?: () => void;
  setQuantity?: (value: number) => void;
  quantity?: number;
  maxQuantity?: number;
  badge?: string;
  badgeHighlighted?: boolean;
  policyDescription: Description[];
  /** @deprecated*/
  promotion?: {
    code: string;
    description: string;
    total: number;
    currency: string;
  };
  showComissionAndMarkup?: 'tenant' | 'client' | false | null;
  showOnlyTotal?: boolean;
  disableTax?: boolean;
};
type Description = {
  name?: string;
  description?: string;
};
const CartRoom: React.FC<CartRoomProps> = props => {
  const {
    roomTitle,
    policy,
    guests,
    currency,
    totalValue,
    nights = 1,
    taxes,
    children,
    childrenAges,
    onRemove,
    badge,
    roomRates,
    showComissionAndMarkup,
    maxQuantity,
    setQuantity,
    quantity,
    showOnlyTotal,
    disableTax,
    policyDescription,
    badgeHighlighted
  } = props;
  const theme = useTheme();
  const roomRate = roomRates?.[0];
  const inclusions = roomRate?.ratePlan?.inclusions;
  const {
    showRatePlanName
  } = useHotelSearchContext();
  return <S.ItemStyle>
      <S.ContentItemlStyle>
        <S.HotelCartTitle>{roomTitle}</S.HotelCartTitle>

        <Tooltip title="Delete" placement="top" arrow>
          <IconButton onClick={onRemove}>
            <NiaraIcon icon="sysDelete" />
          </IconButton>
        </Tooltip>
      </S.ContentItemlStyle>
      {showRatePlanName && <Text as="p" fontWeight={500} fontSize={12}>
          {roomRate?.ratePlan?.name}
        </Text>}
      {roomRate?.meal?.name ? <S.MealName>
          <NiaraIcon icon="htlBreakfastOutlined" />
          <Text as="p" fontSize={12} fontWeight={600}>
            {roomRate?.meal?.name}
          </Text>
        </S.MealName> : null}
      <S.Amenities>
        {inclusions?.map((item, idx) => {
        const renderDivider = idx < inclusions?.length - 1;
        return <React.Fragment key={item?.id}>
              <Text as="p" fontSize={12}>
                {item?.name}
              </Text>{' '}
              {renderDivider ? <S.Divider /> : null}
            </React.Fragment>;
      })}
      </S.Amenities>
      <S.DescStyle>
        {policy && <Policy policyDescription={policyDescription} {...policy} textSize={12} fontWeight="normal" showPricePolicy />}

        {(badge || roomRate?.priceComposition?.discountBreakdown) && <S.Badges>
            {badge && <S.BadgeTitle badgeHighlighted={badgeHighlighted}>
                <span>{badge}</span>
              </S.BadgeTitle>}
            {roomRate?.priceComposition?.discountBreakdown?.map((discount, idx) => <Badge key={idx} color={theme?.colors?.gray3} colorText={theme?.colors?.textColor} borderRadius="15px" borderRadiusMb="15px" height="20px">
                {discount?.description}
              </Badge>)}
            {/* {promotion && (
             <Badge
              color={theme?.colors?.gray3}
              colorText={theme?.colors?.textColor}
              borderRadius="15px"
              borderRadiusMb="15px"
              height="20px"
             >
              {promotion?.description}
             </Badge>
             )} */}
          </S.Badges>}
      </S.DescStyle>
      {showOnlyTotal ? <S.ValStyle>
          <Price value={totalValue} currency={currency} color={theme.colors?.textColor} currencySize={12} priceCoinSize={13} fractionSize={13} decimalSize={13} fontWeightAll="700" dataTestId="cart-room-per-night-total" />
        </S.ValStyle> : <S.ValStyle>
          <div>
            <Price value={totalValue / nights} currency={currency} color={theme.colors?.gray6} currencySize={12} priceCoinSize={13} fractionSize={13} decimalSize={13} currencyFontWeight={'bold'} priceCoinFontWeight={'bold'} fractionFontWeight={'bold'} dataTestId="cart-room-price-per-night" />
            <span id="nights">{' /noite'}</span>
          </div>
          <div>
            <Price value={totalValue} currency={currency} color={theme.colors?.textColor} currencySize={12} priceCoinSize={14} fractionSize={12} decimalSize={12} currencyFontWeight={'normal'} priceCoinFontWeight={'bold'} fractionFontWeight={'bold'} dataTestId="cart-room-per-night-total" />
          </div>
        </S.ValStyle>}
      {disableTax ? <></> : <>
          {!!taxes && taxes > 0 && <TaxIncluded value={taxes} text="Impostos" currency={currency} valueSize={11} dataTestId="cartroom-taxes" />}
        </>}
      <S.PersonStyle>
        <NiaraIcon icon="sysPersonalInformation" />
        <Text as="p" fontSize={12}>{` ${guests} ${guests > 1 ? "Adultos" : "Adulto"} ${children > 0 && childrenAges?.length > 0 ? `, ${children} ${children > 1 ? "Crianças" : "Criança"} ${exhibitionChildrensLabel({
          childrenAges
        })}` : ''}`}</Text>
      </S.PersonStyle>
      {roomRate?.priceComposition?.fee && <TaxIncluded value={roomRate?.priceComposition?.fee?.value} text={roomRate?.priceComposition?.fee?.label} currency={roomRate?.priceComposition?.fee?.currency} valueSize={11} />}
      {showComissionAndMarkup == 'tenant' && <>
          {roomRate?.priceComposition?.markup && <div>
              <TaxIncluded value={roomRate?.priceComposition?.markup?.value} text={`Markup (${printPercentage(roomRate?.priceComposition?.markup?.value / totalValue)})`} currency={roomRate?.priceComposition?.markup?.currency} valueSize={11} dataTestId="cartroom-markup" />
            </div>}
          {roomRate?.priceComposition?.commission && <div>
              <TaxIncluded value={roomRate?.priceComposition?.commission?.value} text={`Comissão (${printPercentage(roomRate?.priceComposition?.commission?.value / totalValue)})`} currency={roomRate?.priceComposition?.commission?.currency} valueSize={11} dataTestId="cartroom-commission" />
            </div>}

          {roomRate?.priceComposition?.clientCommission && <div>
              <TaxIncluded value={roomRate?.priceComposition?.clientCommission?.value} text={`Comissão cliente (${printPercentage(roomRate?.priceComposition?.clientCommission?.value / totalValue)})`} currency={roomRate?.priceComposition?.clientCommission?.currency} valueSize={11} dataTestId="cartroom-client-commission" />
            </div>}
        </>}
      {showComissionAndMarkup == 'client' && roomRate?.priceComposition?.clientCommission && <div>
          <TaxIncluded value={roomRate?.priceComposition?.clientCommission?.value} text={`Comissão cliente (${printPercentage(roomRate?.priceComposition?.clientCommission?.value / totalValue)})`} currency={roomRate?.priceComposition?.clientCommission?.currency} valueSize={11} dataTestId="cartroom-client-commission" />
        </div>}
      <S.StyleQuantity>
        <S.SelectStyle>
          {maxQuantity > 0 && setQuantity && <SelectButton maxValue={maxQuantity} onValue={setQuantity} value={quantity} />}
        </S.SelectStyle>
      </S.StyleQuantity>
    </S.ItemStyle>;
};
export default CartRoom;