import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Setup SharpScpring',
  labelPlural: 'Setup SharpScpring',
  name: 'CrmSetup',
  namePlural: 'CrmSetups',
  authorization: authorization('consultant', 'admin'),
  deletable: false,
  dynamoDB: {
    tableName: 'SpearCRMSetup'
  },
  columns: ['id', 'name', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Nome',
    type: 'text',
    required: true
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: false,
    required: true,
    type: 'boolean'
  }],
  filter: [{
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq'
  }]
});