import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Theme, ThemeColors } from '@niarab2c/ota-components/src/theme';
export interface ThemeState {
  theme?: Partial<Theme>;
}
const initialTheme: ThemeState = {
  theme: {
    colors: {}
  }
};
const themeSlice = createSlice({
  name: 'theme',
  initialState: initialTheme,
  reducers: {
    setThemeColors: function (state, action: PayloadAction<Partial<ThemeColors>>) {
      state.theme = {
        ...state.theme,
        colors: Object.assign({}, state.theme?.colors || {}, action.payload)
      };
    }
  }
});
export default themeSlice.reducer;
export const {
  setThemeColors
} = themeSlice.actions;