import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Notificação Webhook',
  labelPlural: 'Notificações Webhook',
  name: 'WebhookNotification',
  namePlural: 'WebhookNotifications',
  deletable: false,
  updatable: false,
  authorization: authorization('consultant', 'admin'),
  'custom:sortFields': ['updatedAt desc'],
  dynamoDB: {
    tableName: 'SpearWebhookNotification',
    indexes: [{
      indexedProp: 'orderId',
      indexName: 'tenantId-orderId-index'
    }, {
      indexedProp: 'lostReservationId',
      indexName: 'tenantId-lostReservationId-index'
    }]
  },
  columns: ['endpoint', 'status', 'retries', 'updatedAt', 'clientId'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'clientId',
    label: 'Id do cliente',
    renderColumn: false,
    required: true,
    updatable: false
  }, {
    name: 'createdAt',
    label: 'Data da primeira execução',
    type: 'datetime',
    required: true,
    updatable: false
  }, {
    name: 'updatedAt',
    label: 'Data da última execução',
    type: 'datetime',
    required: true,
    updatable: false
  }, {
    name: 'endpoint',
    label: 'Rota',
    type: 'text',
    required: true,
    updatable: false
  }, {
    name: 'orderId',
    label: 'Número do pedido',
    type: 'text',
    required: false,
    updatable: false
  }, {
    name: 'lostReservationId',
    label: 'Id da reserva perdida',
    type: 'text',
    required: false,
    updatable: false
  }, {
    name: 'status',
    label: 'Status',
    type: 'text',
    options: [{
      label: 'Sucesso',
      value: 'SUCCESS'
    }, {
      label: 'Falha',
      value: 'FAILED'
    }],
    required: false,
    updatable: false,
    renderStyle(i, fieldName = 'status') {
      if (!i[fieldName]) return null;
      switch (i[fieldName]) {
        case 'FAILED':
          return 'danger';
        case 'SUCCESS':
          return 'success';
      }
      return null;
    }
  }, {
    name: 'retries',
    label: 'Número de tentativas',
    type: 'int',
    default: 1,
    min: 1,
    required: true,
    updatable: false
  }],
  filter: [{
    field: 'orderId',
    op: 'eq'
  }, {
    field: 'lostReservationId',
    op: 'eq'
  }, {
    field: 'status',
    op: 'eq'
  }, {
    field: 'id',
    op: 'eq'
  }]
});