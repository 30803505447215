import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Solicitação',
  labelPlural: 'Solicitações',
  name: 'Requisition',
  faIcon: 'clipboard',
  insertable: true,
  updatable: true,
  deletable: false,
  authorization: authorization('consultant'),
  dynamoDB: {
    tableName: 'SpearRequisition',
    partitionKey: 'tenantId',
    sortKey: 'id'
  },
  columnNames: ['id', 'clientId', 'clientName', 'productTypes', 'destinationName', 'travellerName', 'startDate', 'endDate', 'date', 'status'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    render: false,
    id: true
  }, {
    name: 'clientId',
    label: 'Id do Cliente',
    render: false,
    required: true,
    insertable: true,
    updatable: false,
    id: false
  }, {
    name: 'productTypes',
    label: 'Tipos de produto',
    type: 'textarray',
    updatable: false,
    required: true,
    options: [{
      value: 'hotelReservation',
      label: 'Reserva de Hotel'
    }, {
      value: 'flightReservation',
      label: 'Reserva de Aéreo'
    }]
  }, {
    name: 'date',
    label: 'Data da solicitação',
    insertable: false,
    updatable: false,
    type: 'datetime'
  }, {
    name: 'startDate',
    label: 'Data de início',
    type: 'date'
  }, {
    name: 'endDate',
    label: 'Data de fim',
    type: 'date'
  }, {
    label: 'Viajante',
    name: 'travellerName',
    updatable: false
  }, {
    label: 'Viajante',
    name: 'travellerId',
    updatable: false
  }, {
    name: 'occupancy',
    label: 'Ocupação',
    type: 'objectarray',
    graphQLType: '[NestedRequisitionOccupancy]',
    graphQLInputType: '[NestedRequisitionOccupancyInput]',
    fields: [{
      name: 'quantity',
      label: 'Quantidade',
      type: 'int'
    }, {
      name: 'adults',
      label: 'Adultos',
      type: 'int'
    }, {
      name: 'children',
      label: 'Crianças',
      type: 'int'
    }, {
      name: 'infantsSeated',
      label: 'Bebês',
      type: 'int'
    }, {
      name: 'infantsOnLap',
      label: 'Bebês (no colo)',
      type: 'int'
    }, {
      name: 'childrenAges',
      label: 'Idades das crianças',
      type: 'intarray'
    }]
  }, {
    name: 'pax',
    label: 'Viajantes',
    type: 'objectarray',
    graphQLType: '[NestedRequisitionPax]',
    graphQLInputType: '[NestedRequisitionPaxInput]',
    fields: [{
      name: 'firstName',
      label: 'Nome'
    }, {
      name: 'lastName',
      label: 'Sobrenome'
    }, {
      name: 'email',
      label: 'E-mail'
    }, {
      name: 'dateOfBirth',
      label: 'Data de nascimento',
      type: 'date'
    }]
  }, {
    name: 'originId',
    label: 'Origem',
    type: 'text'
  }, {
    name: 'originType',
    label: 'Tipo de origem',
    type: 'text',
    options: [{
      label: 'Cidade',
      value: 'CITY'
    }, {
      label: 'Hotel',
      value: 'HOTEL'
    }, {
      label: 'Aeroporto',
      value: 'AIRPORT'
    }]
  }, {
    name: 'originName',
    label: 'Origem'
  }, {
    name: 'destinationId',
    label: 'Destino',
    type: 'text'
  }, {
    name: 'destinationType',
    label: 'Tipo do destino',
    type: 'text',
    options: [{
      label: 'Cidade',
      value: 'CITY'
    }, {
      label: 'Hotel',
      value: 'HOTEL'
    }, {
      label: 'Aeroporto',
      value: 'AIRPORT'
    }]
  }, {
    name: 'destinationName',
    label: 'Nome do destino',
    type: 'text'
  }, {
    name: 'preferences',
    label: 'Preferências',
    type: 'textarray'
  }, {
    name: 'tags',
    label: 'Marcadores',
    type: 'textarray'
  }, {
    name: 'comments',
    label: 'Comentários',
    type: 'textarray'
  }, {
    name: 'status',
    label: 'Situação',
    required: true,
    default: 'PENDING',
    updatable: false,
    options: [{
      label: 'Solicitado',
      value: 'PENDING'
    }, {
      label: 'Reservado',
      value: 'DONE'
    }, {
      label: 'Rejeitado',
      value: 'REJECTED'
    }, {
      label: 'Cancelado',
      value: 'CANCELLED'
    }],
    renderStyle(i, fieldName = 'status') {
      if (i[fieldName]) {
        switch (i[fieldName]) {
          case 'CANCELLED':
          case 'REJECTED':
            return 'danger';
          case 'PENDING':
            return 'warning';
          case 'DONE':
            return 'success';
        }
      }
      return 'danger';
    }
  }, {
    name: 'cancelled',
    label: 'Cancelado',
    updatable: false,
    type: 'boolean'
  }],
  filter: [{
    name: 'date_ge',
    field: 'date',
    op: 'ge',
    label: 'Data de Reserva Início'
  }, {
    name: 'date_le',
    field: 'date',
    op: 'le',
    label: 'Data de Reserva Fim'
  }, {
    name: 'clientId_eq',
    field: 'clientId',
    op: 'eq',
    label: 'Id do cliente'
  }]
});