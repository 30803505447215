import { shallowClientDAO } from '@niarab2c/frontend-commons/src/daos';
import { Client, HotelSearchRule } from '@niarab2c/niara-spear-crudmodel';
import { useAsync } from '@niaratech/niara-react-components';
import { useMemo } from 'react';
import type { CriteriaForm } from '../app/base';
import { useSelector } from '../app/base';
import useStartDateValues from './useStartDateValues';
const DEFAULT_CLIENT_ID = '00000000-0000-0000-0000-000000000001';

/**
 * Este código estava originalmente em CriteriaComponent
 * @param hotelSearchRule
 * @returns
 */
const useDefaultHotelCriteria = (hotelSearchRule: HotelSearchRule): Partial<CriteriaForm> => {
  const {
    fixedClient,
    previousOrderClient
  } = useSelector(r => ({
    fixedClient: r.core?.client ?? r.authentication?.user?.clientId ? {
      id: r.authentication?.user?.clientId,
      brand_name: r.authentication?.user?.clientName
    } : null,
    previousOrderClient: r.shoppingCart?.previousOrderClient
  }));
  const nullFixedClient = fixedClient == null;
  const [defaultClient] = useAsync(() => !nullFixedClient ? null : shallowClientDAO.findById(DEFAULT_CLIENT_ID).then(_client => _client?.enabled ? _client : null).catch(() => null), [nullFixedClient]);
  const {
    defaultStartDate,
    defaultEndDate
  } = useStartDateValues(hotelSearchRule);
  const defaultDestination = useMemo(() => {
    return hotelSearchRule?.defaultCriteria_destination?.id && !hotelSearchRule?.defaultCriteria_all_destinations ? {
      destinationName: hotelSearchRule?.defaultCriteria_destination?.name,
      destinations: {
        propertyId: hotelSearchRule?.defaultCriteria_destination?.id,
        contentType: (hotelSearchRule?.defaultCriteria_destination.contentType as CriteriaForm['destinations']['contentType'])
      }
    } : hotelSearchRule?.defaultCriteria_all_destinations && hotelSearchRule?.showAllOption ? {
      destinations: {
        propertyId: 'ALL',
        contentType: ('all' as CriteriaForm['destinations']['contentType'])
      },
      destinationName: 'Todos'
    } : undefined;
  }, [hotelSearchRule?.defaultCriteria_destination?.id, hotelSearchRule?.defaultCriteria_destination?.name, hotelSearchRule?.defaultCriteria_destination?.contentType, hotelSearchRule?.defaultCriteria_all_destinations, hotelSearchRule?.showAllOption]);
  const defaultCriteria: Partial<CriteriaForm> = useMemo(() => {
    const client: Client = fixedClient ?? previousOrderClient ?? defaultClient;
    const defaultCriteria: Partial<CriteriaForm> = {
      destinations: defaultDestination?.destinations ?? undefined,
      destinationName: defaultDestination?.destinationName,
      startDate: hotelSearchRule?.defaultCriteria_startDate ?? defaultStartDate,
      endDate: hotelSearchRule?.defaultCriteria_endDate ?? defaultEndDate,
      clientId: client?.id,
      clientName: client?.brand_name,
      enablePromoCode: client?.promoCodeEnabled,
      promoCode: hotelSearchRule?.defaultCriteria_promoCode
    };
    defaultCriteria.rooms = [{
      adults: hotelSearchRule?.defaultCriteria_adults ?? 1,
      children: 0,
      childrenAges: []
    }];
    return defaultCriteria;
  }, [fixedClient, previousOrderClient, defaultClient, defaultDestination?.destinations, defaultDestination?.destinationName, hotelSearchRule?.defaultCriteria_startDate, hotelSearchRule?.defaultCriteria_endDate, hotelSearchRule?.defaultCriteria_promoCode, hotelSearchRule?.defaultCriteria_adults, defaultStartDate, defaultEndDate]);
  return defaultCriteria;
};
export default useDefaultHotelCriteria;