import { personDAO } from '@niarab2c/frontend-commons/src/daos';
import callApi from '@niarab2c/frontend-commons/src/util/callApi';
import type { Client, HotelSearchRule } from '@niarab2c/niara-spear-crudmodel';
import type { CriteriaForm as FlightCriteriaForm } from '@niarab2c/niarab2c-core/src/types/flight';
import { HotelCriteria, useHotelSearchContext } from '@niarab2c/ota-components/src';
import FlightCriteria from '@niarab2c/ota-components/src/FlightSearch/FlightCriteria';
import HomepageSearchBar from '@niarab2c/ota-components/src/Home/HomepageSearchBar/HomepageSearchBar';
import type { FormValueType } from '@niarab2c/ota-components/src/HotelSearch/Criteria';
import type { DetailCalendarDatePickerItems, HotelCriteriaProps, LoyaltyOption } from '@niarab2c/ota-components/src/HotelSearch/Criteria/HotelCriteria';
import { addDays, formatDate } from '@niaratech/niara-js-commons';
import { useAsync, useImmutableFunction } from '@niaratech/niara-react-components/src';
import _isEqual from 'lodash/isEqual';
import { nanoid } from 'nanoid/non-secure';
import { default as React, useCallback, useEffect, useMemo, useState } from 'react';
import type { CriteriaForm } from '../../app/base';
import { ShoppingCart, useDispatch, useSelector } from '../../app/base';
import useCartProps from '../../hooks/useCartProps';
import useClientSearch from '../../hooks/useClientSearch';
import useDefaultHotelCriteria from '../../hooks/useDefaultHotelCriteria';
import useDestinationSearch from '../../hooks/useDestinationSearch';
import useIataSearch from '../../hooks/useIataSearch';
import useParentClient from '../../hooks/useParentClient';
import useStartDateValues from '../../hooks/useStartDateValues';
type ClientOption = Pick<Client, 'id' | 'brand_name' | 'promoCodeEnabled'>;
interface Props {
  headerSize?: string;
  onHotelSearch?: (c: CriteriaForm) => any;
  searchEngine_orientation?: 'VERTICAL' | 'HORIZONTAL';
  autoFocus?: boolean;
  initialCriteria?: Partial<CriteriaForm>;
  isSearchPage?: boolean;
  modifyDates?: boolean;
  enableClientList?: boolean;
  onFlightSearch?: (c: FlightCriteriaForm) => unknown;
  isPersonSearch?: boolean;
}
const Criteria: React.FC<Props> = ({
  onHotelSearch,
  headerSize,
  searchEngine_orientation: propsSearchEngine_orientation,
  autoFocus,
  initialCriteria: _propsInitialCriteria,
  isSearchPage,
  modifyDates,
  onFlightSearch,
  isPersonSearch
}) => {
  const {
    searchEngine_orientation,
    storefrontLocator,
    fixedClientId,
    coreClientPromoCodeEnabled,
    flightsEnabled,
    previousFlightCriteria,
    b2c,
    tenant,
    landingPageId,
    userClient,
    previousOrderId,
    engineRuleVersionStorefront
  } = useSelector(r => ({
    searchEngine_orientation: r.storefrontConfig?.storefront.searchEngine_orientation,
    storefrontLocator: r.storefrontConfig?.storefront?.locator,
    fixedClientId: r.core?.clientId ?? r.authentication?.user?.clientId,
    coreClientPromoCodeEnabled: r.core?.client?.promoCodeEnabled,
    flightsEnabled: r.storefrontConfig?.storefront?.flights_enabled,
    previousFlightCriteria: r.flight?.criteria,
    b2c: r.authentication?.b2c,
    orderId: r.shoppingCart?.orderId,
    tenant: r.core.tenant,
    landingPageId: r.storefrontConfig?.storefront?.landingPageId || undefined,
    userClient: r.core?.client,
    previousOrderId: r?.shoppingCart?.previousOrderId,
    engineRuleVersionStorefront: r.storefrontConfig?.engineRuleVersion
  }));
  const dispatch = useDispatch();

  // guarda hotelSearchRule localmente, não no redux. O rootState.hotels.hotelSearchRule
  // só será atualizado no momento da pesquisa
  const [hotelSearchRule, setHotelSearchRule] = useState<HotelSearchRule>();
  const {
    minStartDate,
    maxStartDate
  } = useStartDateValues(hotelSearchRule);
  const {
    engineRuleVersion: _engineRuleVersion
  } = useHotelSearchContext();
  const engineRuleVersion = useMemo(() => !storefrontLocator ? _engineRuleVersion : engineRuleVersionStorefront, [_engineRuleVersion, engineRuleVersionStorefront, storefrontLocator]);
  const defaultCriteria = useDefaultHotelCriteria(hotelSearchRule);
  const initialCriteria = _propsInitialCriteria ?? defaultCriteria;
  const {
    minAdultCount,
    childrenAllowed = true,
    minChildCount,
    minNightCount
  } = hotelSearchRule || {};
  const _initialFormValue = useMemo<FormValueType>(() => {
    const destination: FormValueType['destination'] = initialCriteria?.destinationName && initialCriteria?.destinations ? {
      label: initialCriteria?.destinationName,
      value: initialCriteria?.destinations
    } : undefined;
    const person: FormValueType['person'] = initialCriteria?.personId ? {
      id: initialCriteria?.personId,
      firstName: initialCriteria?.personName,
      clientId: initialCriteria?.clientId
    } : null;
    const initialClient: ClientOption = userClient ? {
      ...userClient
    } : fixedClientId ? {
      id: fixedClientId,
      brand_name: undefined
    } : initialCriteria?.clientId ? {
      id: initialCriteria?.clientId,
      brand_name: initialCriteria?.clientName,
      promoCodeEnabled: initialCriteria?.enablePromoCode
    } : null;
    const promoCode = initialCriteria?.promoCode;
    const rooms = initialCriteria?.rooms?.length > 0 ? initialCriteria?.rooms?.map(r => ({
      ...r,
      _id: nanoid()
    })) : [{
      adults: minAdultCount ?? 1,
      children: childrenAllowed ? minChildCount ?? 0 : 0,
      _id: nanoid()
    }];
    const startDate = initialCriteria?.startDate ?? minStartDate ?? formatDate(new Date(), undefined, 'yyyy-MM-dd');
    const endDate = initialCriteria?.endDate ?? addDays(startDate, minNightCount ?? 1);
    const personTSER_idContrato = initialCriteria?.personTSER_idContrato ? Number(initialCriteria?.personTSER_idContrato) : undefined;
    return {
      destination,
      person,
      client: initialClient,
      promoCode,
      rooms,
      startDate,
      endDate,
      loyaltyOption: {
        TSER_idContrato: personTSER_idContrato
      }
    };
  }, [initialCriteria?.destinationName, initialCriteria?.destinations, initialCriteria?.personId, initialCriteria?.personName, initialCriteria?.clientId, initialCriteria?.clientName, initialCriteria?.enablePromoCode, initialCriteria?.promoCode, initialCriteria?.rooms, initialCriteria?.startDate, initialCriteria?.endDate, initialCriteria?.personTSER_idContrato, userClient, fixedClientId, minAdultCount, childrenAllowed, minChildCount, minStartDate, minNightCount]);
  const [_formValue, _setFormValue] = useState<FormValueType>(null);
  const formValue = useMemo(() => {
    if (_formValue && /* precisa checar com undefined, para diferenciar do caso em que o usuário limpou o destino padrão */
    _formValue?.destination === undefined && _initialFormValue?.destination) {
      // substitui o destino do formulário pelo destino configurado na regra ao trocar cliente - mas só se o campo destino não for trocado #184215
      return {
        ..._formValue,
        destination: _initialFormValue?.destination
      };
    } else {
      return _formValue ?? _initialFormValue;
    }
  }, [_formValue, _initialFormValue]);
  const setFormValue = useImmutableFunction<React.Dispatch<React.SetStateAction<FormValueType>>>(fnOrValue => {
    _setFormValue(_formValue => {
      const currValue = _formValue ?? formValue;
      const newValue = typeof fnOrValue === 'function' ? fnOrValue(currValue) : fnOrValue;
      if (!_isEqual(newValue, currValue)) {
        return newValue;
      } else {
        return _formValue;
      }
    });
  });
  const {
    client,
    destination
  } = formValue;
  const clientId = client?.id || undefined;
  const [mode, setMode] = useState<'HOTEL' | 'FLIGHT'>('HOTEL');
  const iataDestinationSearch = useIataSearch();
  const destinationSearch = useDestinationSearch({
    clientId: client?.id
  });
  const {
    clientSearch
  } = useClientSearch(fixedClientId);
  const shoppingCartDropdownProps = useCartProps();
  const {
    allClientIds
  } = useParentClient({
    clientId: client?.id
  });
  const personSearch: HotelCriteriaProps['personSearch'] = useMemo(() => {
    return s => personDAO.search({
      fields: ['id', 'firstName', 'lastName', 'email', 'cpf', 'phone', 'clientId', 'passport'],
      filter: {
        clientId_in: [client?.id, ...allClientIds],
        enabled_eq: true,
        autocomplete: s
      }
    }).then(r => r?.items);
  }, [allClientIds, client?.id]);
  const detailCalendarStatusToDatePicker = useCallback<HotelCriteriaProps['detailCalendarStatusToDatePicker']>(async criteria => {
    const body = {
      criteria,
      engineRuleVersion
    };
    if (!storefrontLocator && criteria?.clientId !== undefined) {
      const response = await callApi<{
        results?: DetailCalendarDatePickerItems[];
      }>('niara-spear-booking', `hotels/calendar`, 'post', {
        body
      });
      return response?.results;
    }
    if (storefrontLocator) {
      const response = await callApi<{
        results?: DetailCalendarDatePickerItems[];
      }>('niara-spear-booking', `otabuilder/${storefrontLocator}/hotels/calendar`, 'post', {
        body
      });
      return response?.results;
    }
  }, [engineRuleVersion, storefrontLocator]);
  const enableCalendarDetails = hotelSearchRule?.showCalendarAvailability || false;
  const personSearchEnabled = !b2c && tenant?.modules_personSearch_deleteme;
  const propertyId = destination?.value?.propertyId || undefined;
  const [loyaltyOptions,, isLoadingLoyaltyOptions] = useAsync<LoyaltyOption[]>(async () => {
    if ((isPersonSearch ?? personSearchEnabled) && formValue?.person?.id) {
      try {
        return await callApi('niara-spear-loyalty-programs', `loyalty-programs/${formValue?.person?.id}/balance`, 'get', {
          authenticationType: 'NIARA_AUTH',
          params: {
            clientId
          }
        })?.then(res => res?.loyaltyOptions);
      } catch {
        return null;
      }
    }
    return null;
  }, [clientId, formValue?.person?.id, isPersonSearch, personSearchEnabled]);

  // atualiza a regra de pesquisa a partir de mudança de cliente/hotel
  useEffect(() => {
    if (clientId || landingPageId) {
      callApi('niara-spear-commons', `/genericRuleResolver/hotelSearchRule/details`, 'get', {
        params: {
          clientId,
          landingPageId,
          propertyId: propertyId
        } // TODO complementar o contexto com mais dados (hotel, data, etc)
      }).then(r => setHotelSearchRule(r?.hotelSearchRule));
    }
  }, [clientId, landingPageId, propertyId]);

  // Adicionando serviço à viagem, caso o cliente seja alterado, remove id da reserva existe (só pode para o cliente da reserva).
  useEffect(() => {
    if (!previousOrderId) {
      return;
    }
    if (formValue?.client?.id && initialCriteria?.clientId !== formValue?.client?.id) {
      dispatch(ShoppingCart.setPreviousOrderId(undefined));
      dispatch(ShoppingCart.setOrderId(undefined));
    }
  }, [dispatch, formValue?.client?.id, initialCriteria?.clientId, previousOrderId]);
  return <>
      {onFlightSearch != null && flightsEnabled && <HomepageSearchBar mode={mode} setMode={setMode} />}
      {mode == 'FLIGHT' && onFlightSearch && flightsEnabled ? <FlightCriteria searchEngine_orientation={propsSearchEngine_orientation ?? searchEngine_orientation}
    // initialCriteria={INITIAL_FLIGHT_CRITERIA}
    // flightPreSearchRule={flightPreSearchRule}
    currentCriteria={previousFlightCriteria} onFlightSearch={onFlightSearch} destinationSearch={iataDestinationSearch} /> : <HotelCriteria personSearch={personSearch} personSearchEnabled={isPersonSearch ?? personSearchEnabled} autoFocus={autoFocus} enablePromoCode={coreClientPromoCodeEnabled} minStartDate={minStartDate} maxStartDate={maxStartDate} headerSize={headerSize} searchEngine_orientation={propsSearchEngine_orientation ?? searchEngine_orientation} hotelSearchRule={hotelSearchRule} destinationSearch={destinationSearch} clientSearch={clientSearch} onHotelSearch={onHotelSearch} isSearchPage={isSearchPage} modifyDates={modifyDates} detailCalendarStatusToDatePicker={enableCalendarDetails ? detailCalendarStatusToDatePicker : undefined} enableClientList={fixedClientId || userClient ? false : clientSearch != null} shoppingCartDropdownProps={shoppingCartDropdownProps} value={formValue} setValue={setFormValue} loyaltyOptions={loyaltyOptions} isLoadingLoyaltyOptions={isLoadingLoyaltyOptions}
    //importPerson={importPerson} // Prop para finalizar o card #158329. Esperando correção no endpoint do card #158326 , para finalizar.
    />}
    </>;
};
export default Criteria;