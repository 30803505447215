import React, { useEffect, useState } from 'react';
import * as S from './styles';
type Mode = 'ROUNDTRIP' | 'ONEWAY' | 'MULTICITY';
type FlightCriteriaModeProps = {
  value: Mode;
  setValue?: (mode: Mode) => void;
};
const FLIGHT_MODES: {
  text: string;
  mode: Mode;
}[] = [{
  text: 'Ida e volta',
  mode: 'ROUNDTRIP'
}, {
  text: 'Somente ida',
  mode: 'ONEWAY'
}
// {
//   text: 'Multidestino',
//   mode: 'MULTICITY',
// },
];
const FlightCriteriaMode: React.FC<FlightCriteriaModeProps> = ({
  value: flightMode,
  setValue: setFlightCriteriaMode
}) => {
  const [mode, setMode] = useState(flightMode);
  useEffect(() => {
    if (setFlightCriteriaMode) setFlightCriteriaMode(mode);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);
  return <S.CriteriaModeBox>
      {FLIGHT_MODES.map(MODE => {
      const active = MODE.mode === mode;
      return <S.CriteriaModeButton active={active} onClick={() => setMode(MODE.mode)} type="button">
            {MODE.text}
          </S.CriteriaModeButton>;
    })}
    </S.CriteriaModeBox>;
};
export default FlightCriteriaMode;