import React from 'react';
import { formatDate } from '@niaratech/niara-js-commons';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { IcArrow } from '../../../Icons';
import { useTheme } from '../../..';
import { Dates, Separator } from './styles';
export type PeriodProps = {
  startDate: Date;
  endDate: Date;
  locale?: string;
  dataTestId?: string;
  disableTotalNight?: boolean;
};
const Period: React.FC<PeriodProps> = ({
  startDate,
  endDate,
  locale = undefined,
  dataTestId,
  disableTotalNight
}) => {
  const theme = useTheme();
  const nights = differenceInCalendarDays(endDate, startDate);
  return <Dates data-testid={dataTestId}>
      <span className="date">{formatDate(startDate, locale, 'dd MMM, yy')}</span>

      <Separator>
        <IcArrow color={theme.colors?.primary} direction="right" size={18} />
      </Separator>

      <span className="date">{formatDate(endDate, locale, 'dd MMM, yy')}</span>

      {!disableTotalNight && <span className="nightCount">{nights > 1 ? `(${nights} noites)` : `(${nights} noite)`}</span>}
    </Dates>;
};
export default Period;