import type { Filter, HotelResult, OrderHotelsBy, RoomRate } from '@niarab2c/frontend-commons/src/types/hotels';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import { buildAvailableFilterOptions, filterResults, roomRateComparers, stringifyOccupancy } from '../../../util/hotels';
import { AvailableFilterOptions, AvailableSortOptions, buildAvailableSortOptions } from '../../../util/hotels/filterResults';
import { CriteriaForm } from './hotelSearch';
export type HotelPaginationState = {
  page: number;
  pageSize: number;
  results: {
    [hotelId: string]: HotelResult;
  };
  filter?: Filter;
  sortHoteListBy?: OrderHotelsBy;
  list: HotelResult[];
  count: number;
  unfilteredCount: number;
  availableFilterOptions: AvailableFilterOptions;
  availableSortOptions: AvailableSortOptions;
};
export const initialState: HotelPaginationState = {
  page: 0,
  pageSize: 10,
  results: {},
  list: [],
  count: 0,
  unfilteredCount: 0,
  availableFilterOptions: [],
  availableSortOptions: []
};
const hotelPaginationSlice = createSlice({
  name: 'hotelPagination',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<{
      page?: number;
      pageSize?: number;
    }>) => {
      state.page = action.payload.page !== undefined ? action.payload.page : state.page || 0;
      state.pageSize = action.payload.pageSize !== undefined ? action.payload.pageSize : state.pageSize || 10;
    },
    showMoreResults: (state, action: PayloadAction<{
      count?: number;
    }>) => {
      state.pageSize += action.payload.count || 10;
    },
    setResults: (state, action: PayloadAction<{
      results: {
        [h: string]: HotelResult;
      };
      hotelSearch?: any;
    }>) => {
      if (state.results === action.payload.results) {
        return state;
      }
      state.results = action.payload.results || {};

      // Pega o criteria da pesquisa

      const _availableFilterOptions = buildAvailableFilterOptions([], Object.values(action.payload.results || []));
      state.availableFilterOptions = _availableFilterOptions?.map(sortAlphabeticalAvailableFilterOptions);
      const availableSortOptions = buildAvailableSortOptions([], Object.values(action.payload.results || []));
      state.availableSortOptions = availableSortOptions;
      state.sortHoteListBy = state.sortHoteListBy ? state.sortHoteListBy : availableSortOptions.some(option => option.value === 'METASEARCH_DISCOUNT_PERCENT') ? 'METASEARCH_DISCOUNT_PERCENT' : availableSortOptions?.[0]?.value;
      const list = Object.values(action.payload.results || {});
      const unfilteredCount = list.filter(hotel => hotel?._bestPriceRoomRate?.priceComposition?.productPrice?.value > 0);
      state.unfilteredCount = unfilteredCount?.length || 0;
      const filteredList = filterResults(list, state.filter);
      const sortedList = Object.values(filteredList).sort(buildHotelResultSorter(state.filter, state.sortHoteListBy, action?.payload?.hotelSearch?.criteria));
      state.list = sortedList;
      state.count = sortedList.length;
    },
    setFilter: (state, action: PayloadAction<{
      filter: Filter;
    }>) => {
      if (!isEqual(state.filter, action.payload.filter)) {
        state.filter = action.payload.filter;
        const list = Object.values(state.results || {});
        const filteredList = filterResults(list, state.filter);
        const sortedList = Object.values(filteredList).sort(buildHotelResultSorter(state.filter, state.sortHoteListBy));
        state.list = sortedList;
        state.count = sortedList.length;
      } else {
        return state;
      }
    },
    setHotelsOrderBy: (state, action: PayloadAction<OrderHotelsBy>) => {
      state.sortHoteListBy = action.payload;
      const filteredList = filterResults(state.list, state.filter);
      const sortedList = Object.values(filteredList).sort(buildHotelResultSorter(state.filter, state.sortHoteListBy));
      state.list = sortedList;
    }
  }
});
export const {
  setPage,
  showMoreResults,
  setResults,
  setFilter,
  setHotelsOrderBy
} = hotelPaginationSlice.actions;
export const {
  reducer
} = hotelPaginationSlice;
export default reducer;
const sortAlphabeticalAvailableFilterOptions = (availableFilterOption: AvailableFilterOptions[0]) => {
  if (['meal', 'hotelAmenities', 'cityName'].includes(availableFilterOption?.filterKey)) {
    return {
      ...availableFilterOption,
      filterOptions: availableFilterOption?.filterOptions?.sort((a, b) => a?.label?.trim().localeCompare(b?.label?.trim()))
    };
  }
  return availableFilterOption;
};
type HotelResultComparer = (a: HotelResult) => number | string;

/**
 * Comparação inicial:
 * - disponíveis antes de indisponíveis
 * - favoritos antes
 */
const HAS_ROOM_RATES_COMPARER: HotelResultComparer = a => a.roomRates?.length > 0 ? -1 : 1;
const IS_FAVORITE_COMPARER: HotelResultComparer = a => a._boost != null ? -1 * a._boost : 99999;

/**
 * Compara o melhor room rate
 */
const BEST_ROOM_RATE_COMPARERS: HotelResultComparer[] = roomRateComparers.map(roomRateComparer => (a: HotelResult) => roomRateComparer(a._bestPriceRoomRate));
const NAME_HOTEL_RESULT_COMPARER: HotelResultComparer = a => a?.hotel?.name || '';
const buildHotelResultSorter = (filter: Filter, sortHoteListBy?: OrderHotelsBy, criteria?: CriteriaForm) => (a: HotelResult, b: HotelResult): number => {
  const orderByMetasearchDiscountPercentage: HotelResultComparer = a => {
    // tem metabuscador no resultado, e tem mais que um slot
    if (a._bestPriceRoomRate?.metasearch?.slots?.length > 1) {
      const percentage = a._bestPriceRoomRate?.metasearch?.slots.find(slot => slot.comparison?.percentage != null)?.comparison?.percentage;

      // ordena pelo percentual do desconto
      if (percentage > 0.995 && percentage <= 1.005) {
        return percentage * 100 ?? 100;
      }
      return Math.round(percentage * 100 ?? 100);
    }
    return 100;
  };
  const orderByMissingOccupation: HotelResultComparer = (hotelResult: HotelResult) => {
    // se foi pesquisa de múltiplos tipos de quarto, coloca na frente hotéis
    // que possuem tarifas adequadas para todos os quartos solicitados.

    if (criteria?.rooms?.length > 0) {
      const allRoomRates = hotelResult?._roomRates ?? hotelResult.roomRates;
      //Para cada ocupação, verifica se existe tarifa
      const roomRatesForOccupancy = criteria?.rooms?.map(occupancy => stringifyOccupancy(occupancy)).map(stringifiedOccupancy => {
        // se não tem tarifa para a ocupação

        return allRoomRates.find(roomRate => stringifyOccupancy(roomRate?.occupancy) === stringifiedOccupancy);
      });
      const hasMissingRoom = roomRatesForOccupancy.some(roomRate => roomRate == null);
      return !hasMissingRoom ? -1 : 0;
    } else {
      return 0;
    }
  };
  const fns: Array<HotelResultComparer> = [HAS_ROOM_RATES_COMPARER, orderByMissingOccupation, IS_FAVORITE_COMPARER];
  switch (sortHoteListBy) {
    case 'METASEARCH_DISCOUNT_PERCENT':
      {
        fns.push(orderByMetasearchDiscountPercentage);
        fns.push(...BEST_ROOM_RATE_COMPARERS);
        break;
      }
    default:
      fns.push(...BEST_ROOM_RATE_COMPARERS);
      fns.push(orderByMetasearchDiscountPercentage);
      break;
  }
  fns.push(NAME_HOTEL_RESULT_COMPARER);
  return fns.reduce((acc, fn) => {
    if (Math.abs(acc) >= 0.01) return acc;
    const v1 = fn(a);
    const v2 = fn(b);
    return typeof v1 === 'string' ? v1.localeCompare((v2 as string)) : (v1 as number) - (v2 as number);
  }, 0);
};