import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'E-Mail',
  labelPlural: 'E-Mails',
  name: 'Mail',
  insertable: false,
  updatable: false,
  deletable: false,
  authorization: authorization('consultant'),
  columnNames: ['id', 'deliveryStatus', 'lastUpdate'],
  fields: [{
    label: 'id',
    name: 'id',
    type: 'text',
    updatable: false,
    render: false,
    insertable: false,
    id: true
  }, {
    label: 'Status',
    name: 'deliveryStatus',
    type: 'text',
    updatable: false,
    insertable: false
  }, {
    label: 'Última Atualização',
    name: 'lastUpdate',
    type: 'datetime',
    updatable: false,
    insertable: false,
    required: false
  }]
});