import styled from 'styled-components'
import { BREAKPOINT_SM } from '../../theme/ota'

export type CountPropsColor = {
  bgColor?: string
  colorText?: string
}

export const Container = styled.div<CountPropsColor>`
  &&& {
    display: flex;

    button {
      background: unset !important;
      text-transform: unset !important;
      background-color: ${({ bgColor }) => bgColor ?? 'transparent'} !important;
      border: 1px solid ${({ theme }) => theme.colors?.gray5} !important;
      border-radius: 4px !important;
      display: block !important;
      height: 36px !important;
      position: relative !important;
      width: 36px !important;

      &::before {
        background-color: ${({ theme }) => theme.colors?.text} !important;
        content: '';
        height: 2px !important;
        position: absolute !important;
        right: calc(50% - 7px) !important;
        top: 17px !important;
        width: 14px !important;
      }

      &:disabled {
        background-color: ${({ theme }) => theme.colors?.gray1} !important;
        border: 1px solid ${({ theme }) => theme.colors?.gray4} !important;

        &::before {
          background-color: ${({ theme }) => theme.colors?.gray4} !important;
        }
      }
    }

    button:last-of-type {
      &::after {
        background-color: ${({ theme }) => theme.colors?.text} !important;
        content: '' !important;
        height: 14px !important;
        left: calc(50% - 1px) !important;
        position: absolute !important;
        top: calc(50% - 6px) !important;
        width: 2px !important;
      }

      &:disabled {
        &::after {
          background-color: ${({ theme }) => theme.colors?.gray4} !important;
        }
      }
    }

    @media (min-width: calc(${BREAKPOINT_SM})) {
      button {
        height: 31px !important;
        width: 31px !important;

        &::before {
          content: '';
          top: 14px !important;
        }
      }

      button:last-of-type {
        &::after {
          top: calc(50% - 7px) !important;
        }
      }
    }
  }
`

export const InputCount = styled.input`
  &&& {
    background-color: transparent !important;
    border: transparent !important;
    text-align: center !important;
    width: 30px !important;
    padding: 0px !important;
    color: ${({ theme }) => theme.colors?.text};
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`
