import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export const PAYMENT_TYPES = [{
  value: 'DIR',
  label: 'Pagamento direto'
}, {
  value: 'DIR_IATA',
  label: 'Pagamento direto IATA'
}, {
  value: 'OFF_CC',
  label: 'Cartão de crédito Offline'
}, {
  value: 'ON_CC',
  label: 'Cartão de crédito Online'
}, {
  value: 'OFF_ANT',
  label: 'Antecipado'
}, {
  value: 'OFF_FAT',
  label: 'Faturado'
}, {
  value: 'ON_PIX',
  label: 'Pix'
}];
export default fillCrudMetadataGaps({
  label: 'Configuração de Saldo',
  labelPlural: 'Configurações de Saldo',
  name: 'LedgerConfiguration',
  namePlural: 'LedgerConfigurations',
  custom_withAudit: true,
  authorization: authorization('consultant', 'admin'),
  deletable: false,
  dynamoDB: {
    tableName: 'SpearLedgerConfiguration'
  },
  columns: ['id', 'name', 'enabled', 'clientType', 'description', 'unitText'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Nome da configuração',
    required: true
  }, {
    name: 'description',
    label: 'Descrição longa',
    required: true
  }, {
    name: 'unitText',
    label: 'Unidade',
    required: true,
    hint: 'Unidade do saldo.'
  }, {
    name: 'decimalPlaces',
    label: 'Quantidade de casas decimais',
    required: true,
    updatable: false,
    insertable: true,
    type: 'int',
    min: 0,
    step: 1
  }, {
    name: 'initialBalanceInt',
    label: 'Saldo Inicial',
    required: true,
    type: 'int',
    hint: 'Valor inicial que vai ser criado a carteira.'
  }, {
    custom_groupName: 'Fidelidade',
    name: 'loyaltyEnabled',
    label: 'Módulo de Fidelidade - Avançado',
    default: false,
    type: 'boolean',
    hint: 'Valor inicial que vai ser criado a carteira.'
  }, {
    custom_groupName: 'Fidelidade',
    name: 'loyaltyInitialTier',
    label: 'Nível Inicial de Fidelidade',
    type: 'text',
    hint: 'Valor inicial que vai ser criado a carteira.',
    renderForm: function (i) {
      return i.loyaltyEnabled ? this.render && this.render(i) : false;
    }
  }, {
    custom_groupName: 'Fidelidade',
    name: 'loyaltyTiers',
    label: 'Níveis de Fidelidade',
    type: 'objectarray',
    renderForm: function (i) {
      return i.loyaltyEnabled ? this.render && this.render(i) : false;
    },
    graphQLType: '[NestedLoyaltyTier]',
    graphQLInputType: '[NestedNestedLoyaltyTierInput]',
    fields: [{
      name: 'name',
      label: 'Nome',
      type: 'text',
      required: true
    }, {
      name: 'lowerLimit',
      label: 'Limite Inferior',
      type: 'int',
      required: true
    }, {
      name: 'upperLimit',
      label: 'Limite Superior',
      type: 'int',
      required: true
    }]
  }, {
    custom_groupName: 'Fidelidade',
    name: 'loyaltyTierExpirationType',
    label: 'Validade do Nível',
    type: 'text',
    renderForm: function (i) {
      return i.loyaltyEnabled ? this.render && this.render(i) : false;
    },
    options: [{
      label: 'Dias',
      value: 'DAYS'
    }, {
      label: 'Meses',
      value: 'MONTHS'
    }, {
      label: 'Anos',
      value: 'YEARS'
    }],
    hint: 'Valor inicial que vai ser criado a carteira.'
  }, {
    custom_groupName: 'Fidelidade',
    name: 'loyaltyTierExpirationValue',
    label: 'Validade do Nível',
    type: 'int',
    hint: 'Valor inicial que vai ser criado a carteira.',
    renderForm: function (i) {
      return i.loyaltyEnabled ? this.render && this.render(i) : false;
    }
  }, {
    custom_groupName: 'Fidelidade',
    name: 'loyaltyRewardExpirationType',
    label: 'Validade do Benefício',
    type: 'text',
    options: [{
      label: 'Dias',
      value: 'DAYS'
    }, {
      label: 'Meses',
      value: 'MONTHS'
    }, {
      label: 'Anos',
      value: 'YEARS'
    }],
    hint: 'Valor inicial que vai ser criado a carteira.',
    renderForm: function (i) {
      return i.loyaltyEnabled ? this.render && this.render(i) : false;
    }
  }, {
    custom_groupName: 'Fidelidade',
    name: 'loyaltyRewardExpiration',
    label: 'Validade do Benefício',
    type: 'int',
    hint: 'Valor inicial que vai ser criado a carteira.',
    renderForm: function (i) {
      return i.loyaltyEnabled ? this.render && this.render(i) : false;
    }
  }, {
    custom_groupName: 'Fidelidade',
    name: 'loyaltyTermFiles',
    label: 'Termos, políticas, regulamentos',
    type: 'objectarray',
    graphQLType: '[NestedLoyaltyTermsFile]',
    graphQLInputType: '[NestedLoyaltyTermsFileInput]',
    hint: 'Links/arquivos que serão colocados na tela de registro de pessoas',
    fields: [{
      name: 'name',
      label: 'Nome',
      hint: 'Termos, condições gerais, regulamento, política de privacidade, etc',
      required: true,
      type: 'text'
    }, {
      name: 'file',
      label: 'Arquivo (PDF)',
      required: false,
      type: 'text',
      inputType: 'inputPublicFile',
      fileType: 'pdf',
      custom_gerarPrefix: i => {
        return 'loyalty/' + i.id + '/terms/';
      }
    }, {
      name: 'url',
      label: 'Link',
      hint: '(ignorado se existir arquivo)',
      required: false,
      type: 'text',
      fileType: 'pdf',
      custom_gerarPrefix: i => {
        return 'loyalty/' + i.id + '/terms/';
      }
    }],
    renderForm: function (i) {
      return i.loyaltyEnabled ? this.render && this.render(i) : false;
    }
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: true,
    required: true,
    type: 'boolean'
  }, {
    name: 'clientType',
    label: 'Tipo de cliente',
    required: true,
    updatable: false,
    type: 'text',
    options: [{
      label: 'Pessoa física (cliente b2c)',
      value: 'PERSON'
    }, {
      label: 'Pessoa jurídica (cliente b2b)',
      value: 'CLIENT'
    }]
  }],
  filter: [{
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq'
  }]
});