import type { HotelSearchRule } from '@niarab2c/niara-spear-crudmodel';
import { addDays, getToday } from '@niaratech/niara-js-commons/dist/dateUtils';
import { useSelector } from '../app/base';
const DATE_SORT = (a: string, b: string) => b.localeCompare(a);
const INVERTED_DATE_SORT = (a: string, b: string) => a.localeCompare(b);
type Return = {
  minStartDate: string | null;
  maxStartDate: string | null;
  defaultStartDate: string;
  defaultEndDate: string;
};
const maxDate = (...dates: string[]) => dates?.filter(Boolean).sort(DATE_SORT)[0];
const minDate = (...dates: string[]) => dates?.filter(Boolean).sort(INVERTED_DATE_SORT)[0];

/**
 *
 * @param _hotelSearchRule pode passar um hotelSearchRule como parâmetro (ex: CriteriaComponent)
 * @returns
 */
const useStartDateValues = (_hotelSearchRule?: Partial<HotelSearchRule>): Return => {
  const TODAY = getToday();
  return useSelector(rootSelector => {
    const hotelSearchRule = /* trata caso em que a pesquisa anterior tem hotelSearchRule, mas usuário trocou para cliente sem hotelSearchRule */
    _hotelSearchRule === undefined ? rootSelector.hotel?.hotelSearchRule : _hotelSearchRule;
    const minNightCount = hotelSearchRule?.minNightCount ?? 1;
    const minStartDate = maxDate(...[TODAY, rootSelector.storefrontConfig?.storefront?.minStartDate, hotelSearchRule?.minTimeBetweenBookingAndCheckin != null ? addDays(TODAY, hotelSearchRule?.minTimeBetweenBookingAndCheckin) : null, hotelSearchRule?.minStartDate].filter(Boolean));
    const minMaxStartDate = addDays(minStartDate, minNightCount);
    let maxStartDate = minDate(...[rootSelector.storefrontConfig?.storefront?.maxStartDate, hotelSearchRule?.maxTimeBetweenBookingAndCheckin != null ? addDays(TODAY, hotelSearchRule?.maxTimeBetweenBookingAndCheckin) : null, hotelSearchRule?.maxEndDate].filter(Boolean));
    maxStartDate = maxStartDate ? maxDate(maxStartDate, minMaxStartDate) : undefined;
    const defaultNights = (rootSelector.storefrontConfig?.storefront?.searchEngine_enableCriteria && rootSelector.storefrontConfig?.storefront?.searchEngine_defaultCriteria?.nights) ?? 1;
    const defaultStartDate = minStartDate;
    const defaultEndDate = minDate(addDays(defaultStartDate, Math.max(defaultNights, 1, minNightCount)), maxStartDate);
    return {
      minStartDate,
      maxStartDate,
      defaultStartDate,
      defaultEndDate
    };
  });
};
export default useStartDateValues;