import React from 'react';
import type { PortalContext } from '@niarab2c/ota-components/src/portal/DefaultPortalContext';
import * as S from './styles';
type Props = {
  setDefaultPortalContext: React.Dispatch<React.SetStateAction<PortalContext>>;
};
const WidgetPortalContainer: React.FC<React.PropsWithChildren<Props>> = props => {
  const {
    setDefaultPortalContext
  } = props;
  const portalContainerRef = React.useRef<HTMLDivElement>();
  React.useEffect(() => {
    if (portalContainerRef.current) setDefaultPortalContext({
      container: portalContainerRef.current
    });
  }, [setDefaultPortalContext]);
  return <S.Container className="otabuilder-widget-container">
      <S.Reset>
        <div className="otabuilder-widget-portal-container" ref={portalContainerRef}></div>
      </S.Reset>
    </S.Container>;
};
export default WidgetPortalContainer;