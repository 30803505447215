import React, { Fragment } from 'react';
import { formatPriceToParts } from '@niaratech/niara-js-commons';
import * as S from './styles';
export type PriceProps = {
  value?: number;
  currency?: string;
  color?: string;
  strikethrough?: boolean;
  fontWeightAll?: string;
  fontSizeAll?: number;
  currencySize?: number;
  currencyFontWeight?: string;
  currencyLineHeight?: string;
  decimalSize?: number;
  decimalLineHeight?: string;
  priceCoinSize?: number;
  priceCoinFontWeight?: string;
  priceCoinLineHeight?: string;
  fractionSize?: number;
  fractionFontWeight?: string;
  fractionLineHeight?: string;
  highlighted?: boolean;
  dataTestId?: string;
};
const Price: React.FC<PriceProps> = ({
  value,
  color,
  currency,
  strikethrough,
  fontWeightAll,
  fontSizeAll,
  currencySize,
  currencyFontWeight,
  currencyLineHeight,
  decimalSize,
  decimalLineHeight,
  priceCoinSize,
  priceCoinFontWeight,
  priceCoinLineHeight,
  fractionSize,
  fractionFontWeight,
  fractionLineHeight,
  dataTestId
}) => {
  const totalPriceInParts = formatPriceToParts(value, currency);
  return <S.Container data-testid={dataTestId}>
      {totalPriceInParts.map((value, index) => {
      let currency = '';
      let decimal = '';
      let fraction = '';
      let priceCoin = '';
      switch (value.type) {
        case 'currency':
          currency = value.value;
          break;
        case 'decimal':
          decimal = value.value;
          break;
        case 'fraction':
          fraction = value.value;
          break;
        default:
          priceCoin = value.value;
          break;
      }
      return <Fragment key={index}>
            <S.Coin color={color} fontSize={currencySize || fontSizeAll} fontWeight={currencyFontWeight || fontWeightAll} lineHeight={currencyLineHeight} strikethrough={strikethrough}>
              {currency}
            </S.Coin>

            <S.Coin color={color} fontSize={decimalSize || fontSizeAll} lineHeight={decimalLineHeight} strikethrough={strikethrough}>
              {decimal}
            </S.Coin>

            <S.Coin color={color} fontSize={priceCoinSize || fontSizeAll} fontWeight={priceCoinFontWeight || fontWeightAll} lineHeight={priceCoinLineHeight} strikethrough={strikethrough}>
              {priceCoin}
            </S.Coin>
            <S.Coin color={color} fontSize={fractionSize || fontSizeAll} fontWeight={fractionFontWeight || fontWeightAll} lineHeight={fractionLineHeight} strikethrough={strikethrough}>
              {fraction}
            </S.Coin>
          </Fragment>;
    })}
    </S.Container>;
};
export default Price;