import type { AnyAction, ConfigureStoreOptions, EnhancedStore, MiddlewareArray, Store, ThunkMiddleware } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as _useDispatch, useSelector as _useSelector, shallowEqual, useStore } from 'react-redux';
import { sentryReduxEnhancer } from '../sentry/redux';
import type { AuthenticationState } from './reducers/authentication';
import type { CoreState } from './reducers/core';
import type { FlightState } from './reducers/flight';
import type { HotelState } from './reducers/hotel/index';
import type { LoyaltyState } from './reducers/loyalty';
import type { QuotationState } from './reducers/quotation';
import type { QuotationRoomRatesState } from './reducers/quotationRoomRates';
import type { ShoppingCartState } from './reducers/shoppingCart';
import type { StorefrontConfigState } from './reducers/storefrontConfig';
import type { ThemeState } from './reducers/theme';
export * as Authentication from './reducers/authentication';
export * as Core from './reducers/core';
export * as Flight from './reducers/flight/index';
export type { CriteriaForm } from './reducers/hotel/hotelSearch';
export * as Hotel from './reducers/hotel/index';
export * as Loyalty from './reducers/loyalty';
export * as ShoppingCart from './reducers/shoppingCart';
export type { PaymentOption } from './reducers/shoppingCart';
export type { StorefrontWithTenantId } from './reducers/storefrontConfig';
export * as Theme from './reducers/theme';
export * as QuotationRoomRates from './reducers/quotationRoomRates';
export interface BaseRootState {
  authentication: AuthenticationState;
  hotel: HotelState;
  flight?: FlightState;
  theme: ThemeState;
  shoppingCart?: ShoppingCartState;
  loyalty?: LoyaltyState;
  core: CoreState;
}
export interface CompleteRootState extends BaseRootState {
  storefrontConfig?: StorefrontConfigState;
  quotation?: QuotationState;
  quotationRoomRates?: QuotationRoomRatesState;
}
export type UseSelectorFunction<S extends BaseRootState> = <T>(selector: {
  (state: S): T;
}, equalityFn?: {
  (left: T, right: T): boolean;
}) => T;
export const useSelector: UseSelectorFunction<CompleteRootState> = function (selector, equalityFn?) {
  return _useSelector(selector, equalityFn ?? shallowEqual);
};
type Thunk<T> = {
  (...any): Promise<T>;
} | T;
export interface Dispatch {
  <T>(thunk: Thunk<T>): Promise<T>;
}
interface UseDispatchType {
  (): Dispatch;
}
export const useDispatch: UseDispatchType = (_useDispatch as UseDispatchType);
export type BaseStore<S> = EnhancedStore<S, AnyAction, MiddlewareArray<[ThunkMiddleware<S, AnyAction, undefined>]>>;
export type Listener<S> = (previousState: S, currentState: S, dispatch: Dispatch) => void;
export const useGetState = <S extends BaseRootState = BaseRootState,>(): Store<S>['getState'] => {
  const store = useStore();
  return store.getState;
};
export const baseCreateStore = function <S>(options: Pick<ConfigureStoreOptions<S>, 'preloadedState' | 'reducer'> & {
  listeners?: Array<Listener<S>>;
}): BaseStore<S> {
  const store: BaseStore<S> = configureStore({
    reducer: options.reducer,
    preloadedState: options.preloadedState,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    }),
    enhancers: [sentryReduxEnhancer]
  });
  let currentState: S = store.getState();
  if (options?.listeners?.length > 0) {
    store.subscribe(() => {
      const previousState = currentState;
      currentState = store.getState();
      if (previousState !== currentState) {
        options?.listeners.forEach(listener => listener(previousState, currentState, store.dispatch));
      }
    });
  }
  return store;
};