import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Regra Bee2Pay',
  labelPlural: 'Regras Bee2Pay',
  name: 'SupplierConfiguration',
  insertable: true,
  updatable: true,
  deletable: false,
  authorization: authorization('admin'),
  columnNames: ['id', 'type', 'alias', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    render: false,
    id: true
  }, {
    name: 'alias',
    label: 'Nome',
    required: true
  }, {
    custom_groupName: "Contexto",
    name: 'context_channels',
    label: 'Canais',
    type: 'objectarray',
    inputType: 'inputChannelToken',
    graphQLType: '[NestedChannel]',
    graphQLInputType: '[NestedChannelInput]',
    fields: [{
      name: 'id',
      label: 'Id'
    }, {
      name: 'name',
      label: 'Nome'
    }, {
      name: 'product',
      label: 'Produto'
    }]
  }, {
    custom_groupName: "Contexto",
    name: 'context_penalty',
    label: 'Política de cancelamento',
    hint: 'Período na política de cancelamento no momento da reserva',
    options: [{
      label: 'Cancelamento grátis',
      value: 'NORMAL'
    }, {
      label: 'Cancelamento com multa',
      value: 'PENALTY'
    }, {
      label: 'Não Reembolsável',
      value: 'NONREFUNDABLE'
    }]
  }, {
    custom_groupName: "Pré autorização",
    name: 'validation_automatic',
    label: 'Automático',
    type: 'boolean',
    default: false,
    hint: 'O sistema vai considerar a data mais proxima'
  }, {
    custom_groupName: "Pré autorização",
    name: 'validation_reservationDays',
    label: 'Dias após efetivação da reserva (0 até 365 dias)',
    type: 'int',
    default: 1,
    min: 0,
    max: 365
  }, {
    custom_groupName: "Pré autorização",
    name: 'validation_penaltyDays',
    label: 'Dias antes do início da multa (0 até 7 dias)',
    type: 'int',
    default: 1,
    min: 0,
    max: 30
  }, {
    custom_groupName: "Pré autorização",
    name: 'validation_checkinDays',
    label: 'Dias antes do check-in (0 até 7 dias)',
    type: 'int',
    default: 1,
    min: 0,
    max: 7
  }, {
    custom_groupName: "Pré autorização",
    name: 'validation_checkoutDays',
    label: 'Dias depois do check-out (0 até 7 dias)',
    type: 'int',
    default: 1,
    min: 0,
    max: 7
  }, {
    custom_groupName: "Pré autorização",
    name: 'validation_value',
    label: 'Valor',
    default: 'ALL',
    options: [{
      label: 'Total',
      value: 'ALL',
      hint: 'Valor total da reserva.'
    }, {
      label: 'Multa',
      value: 'PENALTY',
      hint: 'Apenas valor da multa'
    }]
  }, {
    custom_groupName: "Cobrança",
    name: 'capture_automatic',
    label: 'Automático',
    type: 'boolean',
    default: false
  }, {
    custom_groupName: "Cobrança",
    name: 'capture_reservationDays',
    label: 'Dias após efetivação da reserva (0 até 365 dias)',
    type: 'int',
    default: 1,
    min: 0,
    max: 365
  }, {
    custom_groupName: "Cobrança",
    name: 'capture_penaltyDays',
    label: 'Dias antes do início da multa (0 até 7 dias)',
    type: 'int',
    default: 1,
    min: 0,
    max: 30
  }, {
    custom_groupName: "Cobrança",
    name: 'capture_checkinDays',
    label: 'Dias antes do check-in (0 até 7 dias)',
    type: 'int',
    default: 1,
    min: 0,
    max: 7
  }, {
    custom_groupName: "Cobrança",
    name: 'capture_checkoutDays',
    label: 'Dias depois do check-out (0 até 7 dias)',
    type: 'int',
    default: 1,
    min: 0,
    max: 7
  }, {
    custom_groupName: "Cobrança",
    name: 'capture_value',
    label: 'Valor',
    default: 'ALL',
    options: [{
      label: 'Total',
      value: 'ALL',
      hint: 'Valor total da reserva.'
    }, {
      label: 'Multa',
      value: 'PENALTY',
      hint: 'Apenas valor da multa'
    }]
  }, {
    custom_groupName: "Cobrança",
    name: 'capture_useGuarantee',
    label: 'Depósito',
    default: false,
    type: 'boolean',
    hint: 'Usar os valores de depósito/garantia quando disponíveis.'
  }, {
    custom_groupName: "Cobrança",
    name: 'capture_paymentConditionId',
    label: 'Condição de pagamento',
    inputType: 'paymentConditionSingle'
  }, {
    custom_groupName: "Cobrança",
    name: 'capture_paymentMaxInstallments',
    label: 'Parcelamento',
    default: false,
    type: 'boolean',
    hint: 'Vai cobrar com o máximo parcelamento permitido para aquela condição de pagamento.'
  }, {
    custom_groupName: "Outros",
    name: 'enabled',
    label: 'Ativo',
    default: true,
    type: 'boolean'
  }]
});