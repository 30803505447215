import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import React from 'react';
import { printPrice } from '@niaratech/niara-js-commons';
import { Field, PartialField } from '@niaratech/devcase-crud';
export default fillCrudMetadataGaps({
  label: 'Reserva de Aéreo',
  labelPlural: 'Reservas de Aéreo',
  name: 'FlightReservation',
  faIcon: 'journal-whills',
  insertable: false,
  updatable: true,
  deletable: false,
  authorization: authorization('clientConsultant'),
  'custom:sortFields': ['createDate desc'],
  columnNames: ['id', 'locator', 'airlineName', 'createDate', 'startDate', 'primaryPax', 'clientName', 'totalPrice', 'currencyCode', 'status', 'CreatedBy', 'responsible', 'responsibleName', 'createUser', 'allPax'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'credentialAlias',
    label: 'Credencial',
    updatable: false,
    'custom:excludeForSelfFlightReservation': true
  }, {
    name: 'credentialId',
    label: 'Id da credencial',
    updatable: false,
    'custom:excludeForSelfFlightReservation': true
  }, {
    name: 'createDate',
    label: 'Data da reserva',
    type: 'datetime',
    updatable: false
  }, {
    name: 'lastUpdateDate',
    label: 'Data da Última Modificação',
    type: 'datetime',
    updatable: false,
    'custom:excludeForSelfFlightReservation': true
  }, {
    name: 'endDate',
    label: 'Volta',
    type: 'date',
    updatable: false
  }, {
    name: 'startDate',
    label: 'Ida',
    type: 'date',
    updatable: false
  }, {
    name: 'issuanceDeadlineDate',
    label: 'Prazo',
    type: 'date',
    updatable: false
  }, {
    name: 'airlineName',
    label: 'Companhia',
    updatable: false
  }, {
    name: 'obtRef',
    label: 'Ref OBT',
    updatable: false
  }, {
    custom_groupName: "Comprador",
    name: 'buyer_email',
    label: 'E-mail do comprador',
    updatable: false
  }, {
    name: 'primaryPax',
    label: 'Pax',
    updatable: false,
    render: i => i.primaryPax ? <div>
            <span>{i.primaryPax}</span>
            {i.allPax && i.allPax.length > 1 && <div>
                <small>{i.allPax.length} passageiros</small>
                <span data-toggle="tooltip" className="ml-2" title={i.allPax.join(', ')}>
                  <i className="fa fa-info-circle" />
                </span>
              </div>}
          </div> : '-'
  }, {
    name: 'primaryPax_email',
    label: 'E-mail do passageiro principal',
    updatable: false
  }, {
    name: 'source',
    label: 'Conteúdo',
    updatable: false,
    'custom:excludeForSelfFlightReservation': true
  }, {
    name: 'idOnSource',
    label: 'Localizador (conteúdo)',
    updatable: false,
    'custom:excludeForSelfFlightReservation': true
  }, {
    name: 'locator',
    label: 'Localizador (cia)',
    updatable: false
  }, {
    name: 'pax',
    label: 'Passageiros',
    type: 'objectarray',
    updatable: false,
    fields: [{
      name: 'ageQualifying',
      options: ['ADT', 'INF', 'CHD'].map(r => ({
        label: r,
        value: r
      }))
    }, {
      name: 'firstName'
    }, {
      name: 'lastName'
    }, {
      name: 'gender',
      options: ['M', 'F'].map(r => ({
        label: r,
        value: r
      }))
    }, {
      name: 'dob',
      type: 'date'
    }, {
      name: 'email'
    }, {
      name: 'phone'
    }, {
      name: 'cellphone',
      deprecated: true
    }, {
      name: 'fidelity',
      type: 'object',
      fields: [{
        name: 'tam'
      }, {
        name: 'azul'
      }, {
        name: 'gol'
      }, {
        name: 'avianca'
      }]
    }]
  }, {
    name: 'slices',
    label: 'Trechos',
    type: 'object',
    updatable: false
  }, {
    name: 'status',
    label: 'Situação',
    updatable: false,
    options: [{
      label: 'Rejeitado',
      value: 'REJECTED'
    }, {
      label: 'Reservado',
      value: 'RESERVED'
    }, {
      label: 'Cancelado',
      value: 'CANCELLED'
    }, {
      label: 'Não Confirmado',
      value: 'UNCONFIRMED'
    }, {
      label: 'Em Processamento',
      value: 'PROCESSING'
    }, {
      label: 'Emitido',
      value: 'ISSUED'
    }],
    renderStyle(i, fieldName = 'status') {
      if (i[fieldName]) {
        switch (i[fieldName]) {
          case 'CANCELLED':
          case 'REJECTED':
          case 'UNCONFIRMED':
            return 'danger';
          case 'PROCESSING':
            return 'warning';
          case 'RESERVED':
            return 'success';
          case 'ISSUED':
            return 'success';
        }
      }
      return 'warning';
    }
  }, {
    name: 'errors',
    label: 'Erros',
    type: 'object',
    render: false,
    updatable: false,
    insertable: false
  }, {
    name: 'issuanceStatus',
    label: 'Situação da emissão',
    updatable: false
  }, {
    name: 'issuanceError',
    label: 'Erro de emissão',
    updatable: false
  }, {
    name: 'priceComposition',
    label: 'Valores',
    type: 'object',
    updatable: false,
    'custom:excludeForSelfFlightReservation': false,
    fields: [...['total', 'service', 'net', 'boarding'].map(name => ({
      name,
      type: 'object',
      graphQLType: 'flightReservation_price_value',
      fields: [{
        name: 'currency',
        type: 'text'
      }, {
        name: 'value',
        type: 'float'
      }]
    } as PartialField<any>)), {
      name: 'markup',
      type: 'object',
      graphQLType: 'flightReservation_price_value_percentage',
      fields: [{
        name: 'currency',
        type: 'text'
      }, {
        name: 'value',
        type: 'float'
      }, {
        name: 'percentage',
        type: 'float'
      }]
    }, {
      name: 'pax',
      type: 'objectarray',
      graphQLType: '[flightReservation_price_pax]',
      fields: [{
        name: 'type',
        type: 'text',
        options: ['ADT', 'INF', 'CHD'].map(r => ({
          label: r,
          value: r
        }))
      }, {
        name: 'quantity',
        type: 'int'
      }, ...['total', 'service', 'net', 'boarding'].map(name => ({
        name,
        type: 'object',
        graphQLType: 'flightReservation_price_value',
        fields: [{
          name: 'currency',
          type: 'text'
        }, {
          name: 'value',
          type: 'float'
        }]
      } as PartialField<any>)), {
        name: 'markup',
        type: 'object',
        graphQLType: 'flightReservation_price_value_percentage',
        fields: [{
          name: 'currency',
          type: 'text'
        }, {
          name: 'value',
          type: 'float'
        }, {
          name: 'percentage',
          type: 'float'
        }]
      }]
    }]
  }, {
    name: 'totalPrice',
    label: 'Valor',
    type: 'float',
    updatable: false,
    render: i => i.totalPrice ? printPrice(i.totalPrice, i.currencyCode) : '-'
  }, {
    name: 'clientId',
    label: 'Id Cliente',
    updatable: false
  }, {
    name: 'clientName',
    label: 'Nome do cliente',
    updatable: false
  }, {
    name: 'travellerName',
    label: 'Nome do funcionário',
    updatable: false
  }, {
    name: 'tags',
    label: 'Marcadores',
    type: 'textarray',
    updatable: false,
    'custom:excludeForSelfFlightReservation': true
  }, {
    name: 'createUser',
    label: 'Usuário de Criação',
    updatable: false,
    renderColumn: false,
    'custom:excludeForSelfFlightReservation': true
  }, {
    name: 'responsible',
    label: 'Responsável',
    updatable: true,
    renderColumn: false,
    'custom:excludeForSelfFlightReservation': true
  }, {
    name: 'responsibleName',
    label: 'Responsável',
    updatable: true,
    render: i => i?.responsibleName ?? i?.createUser,
    'custom:excludeForSelfFlightReservation': true
  }, {
    name: 'lastUpdateUser',
    label: 'Usuário de Atualização',
    updatable: false,
    'custom:excludeForSelfFlightReservation': true
  }, {
    name: 'CreatedBy',
    label: 'Criado por',
    updatable: false,
    renderColumn: false,
    'custom:excludeForSelfFlightReservation': true
  }, {
    name: 'UpdatedBy',
    label: 'Editado por',
    updatable: false,
    'custom:excludeForSelfFlightReservation': true
  }, {
    name: 'currencyCode',
    label: 'Moeda',
    type: 'text',
    updatable: false,
    renderColumn: false
  }, {
    name: 'etickets',
    label: 'Bilhetes',
    graphQLType: '[NestedFlightTicket]',
    updatable: false,
    insertable: false,
    type: 'objectarray',
    fields: [{
      name: 'id'
    }, {
      label: 'Numero',
      name: 'number'
    }, {
      label: 'Passageiro',
      name: 'pax'
    }, {
      label: 'Situação',
      name: 'status'
    }]
  }, {
    name: 'allPax',
    label: 'Passageiros',
    type: 'textarray',
    updatable: false,
    renderColumn: false
  }, {
    name: 'payment',
    label: 'Pagamento',
    type: 'object',
    updatable: false,
    'custom:excludeForSelfFlightReservation': true,
    fields: [{
      name: 'creditCardPaymentOption',
      type: 'object',
      fields: [{
        name: 'DemaisParcela',
        type: 'float'
      }, {
        name: 'Parcelas',
        type: 'int'
      }, {
        name: 'PrimeiraParcela',
        type: 'float'
      }]
    }, {
      name: 'outputPaymentOption',
      type: 'object',
      fields: [{
        name: 'id',
        type: 'text'
      }, {
        name: 'alias',
        type: 'text'
      }, {
        name: 'description',
        type: 'text'
      }, {
        name: 'external',
        type: 'boolean'
      }, {
        name: 'pay',
        type: 'object'
      }, {
        name: 'receive',
        type: 'object'
      }, {
        name: 'source',
        type: 'object'
      }, {
        name: 'type',
        type: 'text'
      }]
    }, {
      name: 'paymentOption',
      type: 'object',
      fields: [{
        name: 'id',
        type: 'text'
      }, {
        name: 'alias',
        type: 'text'
      }, {
        name: 'description',
        type: 'text'
      }, {
        name: 'external',
        type: 'boolean'
      }, {
        name: 'pay',
        type: 'object'
      }, {
        name: 'receive',
        type: 'object'
      }, {
        name: 'source',
        type: 'object'
      }, {
        name: 'type',
        type: 'text'
      }]
    }]
  }, {
    name: 'orderId',
    label: 'Viagem',
    updatable: false
  }, {
    label: 'Status da aprovação',
    name: 'approvalStatus',
    updatable: false,
    options: [{
      label: 'Carregando aprovadores',
      value: 'LOADING_APPROVERS'
    }, {
      label: 'Aprovação pendente',
      value: 'AWAITING_APPROVAL'
    }, {
      label: 'Aprovado',
      value: 'APPROVED'
    }, {
      label: 'Não aprovado no prazo',
      value: 'CANCELLED_TIMEOUT'
    }, {
      label: 'Aprovação desnecessária',
      value: 'APPROVED_AUTOMATICALLY'
    }, {
      label: 'Erro! Não foi encontrado grupo de avaliação',
      value: 'ERROR_APPROVAL_RULE_NOT_FOUND'
    }, {
      label: 'Cancelado',
      value: 'CANCELLED'
    }, {
      label: 'Rejeitado',
      value: 'REJECTED'
    }],
    renderStyle(i, fieldName = 'approvalStatus') {
      if (!i[fieldName]) return null;
      switch (i[fieldName]) {
        case 'REJECTED':
        case 'CANCELLED':
        case 'CANCELLED_TIMEOUT':
        case 'ERROR_APPROVAL_RULE_NOT_FOUND':
          return 'danger';
        case 'APPROVED':
        case 'APPROVED_AUTOMATICALLY':
          return 'success';
        case 'AWAITING_APPROVAL':
          return 'warning';
      }
      return 'warning';
    }
  }],
  filter: [{
    name: 'clientId_eq',
    field: 'clientId',
    op: 'eq',
    label: 'Client Id'
  }, {
    name: 'date_ge',
    field: 'createDate',
    op: 'ge',
    label: 'Data de Reserva Início'
  }, {
    name: 'date_le',
    field: 'createDate',
    op: 'le',
    label: 'Data de Reserva Fim'
  }]
});