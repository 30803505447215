import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import React from 'react';
export default fillCrudMetadataGaps({
  label: 'Suporte',
  labelPlural: 'Suportes',
  name: 'SupportReservation',
  insertable: false,
  updatable: false,
  deletable: false,
  authorization: authorization('master'),
  columnNames: ['date', 'status', 'id', 'linkNiara', 'linkOmni', 'error', 'origin'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    render: false,
    id: true
  }, {
    name: 'status',
    label: 'Status'
  }, {
    name: 'origin',
    label: 'Origem',
    render: false
  }, {
    name: 'error',
    label: 'Erro'
  }, {
    name: 'date',
    label: 'Data'
  }, {
    name: 'linkNiara',
    label: 'link admin',
    renderColumn: i => i.linkNiara && <a target="_blank" href={i.linkNiara}>
            {i.origin}
          </a>
  }, {
    name: 'linkOmni',
    label: 'link omni',
    renderColumn: i => i.linkOmni && <a target="_blank" href={i.linkOmni}>
            {i.origin}
          </a>
  }],
  filter: []
});