import styled from 'styled-components'

//Não definir global aqui!
const GlobalStyle = styled.div`
  font-family: Manrope, sans-serif;

  * {
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 5px;
      border-width: 5px;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: #e6e6e6;
      border-color: #ffffff;
      border-width: 2px 2px 2px 2px;
      border-style: solid;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #c5c5c5;
      border-color: #c5c5c5;
      border-style: solid;
      border-width: 1px 8px 1px 8px;
    }
  }

  ul {
    list-style: none;
  }

  a,
  tcxspan {
    text-decoration: none;

    /* &:hover {
      color: #0c83d9;
    } */
  }

  button {
    cursor: pointer;
  }

  textarea {
    resize: none;
  }
`

export default GlobalStyle
