import type { CurrentTenant, ShallowClient, Client } from '@niarab2c/niara-spear-crudmodel/src';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface CoreState {
  // client usado apenas quando o client é fixo (ex: otabuilder, quotation, logado como selfbooking) - não mudar este campo se for algo temporário (ex: critério de busca)
  client?: ShallowClient & Partial<Client>;
  clientId?: string;
  tenant?: CurrentTenant;
  tenantId?: string;
  basePath?: string;
}
const coreSlice = createSlice({
  name: 'core',
  initialState: ({} as CoreState),
  reducers: {
    setState: (state, action: PayloadAction<Partial<CoreState>>) => {
      const tenant = action.payload.tenant;
      const client = action.payload.client;
      const clientId = client ? client.id : action.payload.clientId ?? state.clientId;
      const tenantId = tenant ? tenant.tenantId : action.payload.tenantId ?? state.tenantId;
      return {
        ...state,
        ...action.payload,
        clientId,
        tenantId
      };
    },
    setBasePath: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        basePath: action.payload
      };
    }
  }
});
export const {
  setState,
  setBasePath
} = coreSlice.actions;
export default coreSlice.reducer;