import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import countBy from 'lodash/countBy';
import type { Result } from './index';
type Option<T = string> = {
  label: string;
  value: T;
  total?: number;
};
export type FlightFilterState = {
  minDepartureTime?: string;
  maxDepartureTime?: string;
  minArrivalTime?: string;
  maxArrivalTime?: string;
  stopsOption?: 'ANY' | 'NONSTOP' | 'ONE_OR_FEWER' | 'TWO_OR_FEWER';
  priceMin?: number;
  priceMax?: number;
  airlines?: string[];
  airlinesOptions?: Option<string>[];
  luggage?: boolean[];
  luggageOptions?: Option<boolean>[];
};
const SEPARATOR = '\n';
const flightFilterSlice = createSlice({
  name: 'flightFilter',
  initialState: ({} as FlightFilterState),
  reducers: {
    setPartialFilter: function (state, action: PayloadAction<FlightFilterState>) {
      return Object.assign({}, state, action.payload);
    },
    buildOptions: function (state, action: PayloadAction<Result[]>) {
      // divide as companhias aéreas retornadas e contabiliza o total
      state.airlinesOptions = Object.entries(countBy(action.payload, r => [r.players.mandatoriaCode, r.players.mandatoriaName].join(SEPARATOR))).map(([key, total]) => {
        const [value, label] = key.split(SEPARATOR);
        return {
          label,
          value,
          total
        };
      });

      //divide os vôos com bagagem e os vôos sem bagagem e contabiliza o total
      state.luggageOptions = Object.entries(countBy(action.payload.map(r => r.classes).flat(1).filter(r => r.luggage !== null), r => [r.luggage.toString(), r.luggage ? "Inclui bagagem" : "Não inclui bagagem"].join(SEPARATOR))).map(([key, total]) => {
        const [value, label] = key.split(SEPARATOR);
        return {
          label,
          value: value == 'true',
          total
        };
      });
    }
  }
});
export const {
  setPartialFilter,
  buildOptions
} = flightFilterSlice.actions;
export default flightFilterSlice.reducer;
export const filterResults = (results: Result[], filter: FlightFilterState): Result[] => {
  if (filter?.minDepartureTime) {
    results = results.filter(r => r.voos?.[0]?.time?.horaSaida > filter?.minDepartureTime);
  }
  if (filter?.maxDepartureTime) {
    results = results.filter(r => r.voos?.[0]?.time?.horaSaida < filter?.maxDepartureTime);
  }
  if (filter?.minArrivalTime) {
    results = results.filter(r => r.voos?.[r.voos.length - 1]?.time?.horaChegada > filter?.minArrivalTime);
  }
  if (filter?.maxArrivalTime) {
    results = results.filter(r => r.voos?.[r.voos.length - 1]?.time?.horaChegada < filter?.maxArrivalTime);
  }
  if (filter?.stopsOption && filter?.stopsOption != 'ANY') {
    results = results.filter(r => {
      switch (filter.stopsOption) {
        case 'NONSTOP':
          return r.info.paradas == 0;
        case 'ONE_OR_FEWER':
          return r.info.paradas <= 1;
        case 'TWO_OR_FEWER':
          return r.info.paradas <= 2;
      }
      return true;
    });
  }
  if (filter?.priceMax > 0) {
    results = results.filter(r => r.classes?.find(classe => classe.priceComposition?.total?.value <= filter?.priceMax) != null);
  }
  if (filter?.priceMin > 0) {
    results = results.filter(r => r.classes?.find(classe => classe.priceComposition?.total?.value >= filter?.priceMin) != null);
  }
  if (filter?.airlines?.length > 0) {
    results = results.filter(r => filter.airlines.includes(r.players.mandatoriaCode));
  }
  return results;
};
export const filterFlightClasses = (flightClasses: Result['classes'], flightFilter: FlightFilterState): Result['classes'] => {
  return flightClasses?.filter(flightClass => {
    if (flightFilter?.priceMax > 0 && flightClass?.priceComposition?.total?.value > flightFilter?.priceMax) return false;else if (flightFilter?.priceMin > 0 && flightClass?.priceComposition?.total?.value < flightFilter?.priceMin) return false;else if (flightFilter?.luggage?.length > 0 && !flightFilter?.luggage?.includes(flightClass.luggage)) return false;
    return true;
  });
};