import React from 'react';
export type Props = {
  color?: string;
  size?: number;
};
const Aereo: React.FC<Props> = ({
  color = '#273240',
  size = 24
}) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24.196 24">
      <g transform="translate(-91 -125)">
        <path fill="none" stroke={color} stroke-linejoin="round" d="M18.149,81.094l-2-2,2-1,3,1,4-2-5-5h3l6,3,6-2a2.129,2.129,0,0,1,3,1h0c.123.269.268.874,0,1l-17,8a3.222,3.222,0,0,1-4-1h0Z" transform="translate(76.351 59.406)" />
      </g>
    </svg>;
};
export default Aereo;