import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import { currencies } from '../currencyExchangeRule';
import { credentialTypesOptions } from '../credential';
const CONTENT = ['EXPD', 'OMNI', 'B2BR', 'TRND', 'HOTB'];
export default fillCrudMetadataGaps({
  label: 'Reserva manual',
  labelPlural: 'Reservas manuais',
  name: 'ManualReservation',
  namePlural: 'ManualReservations',
  authorization: authorization('supervisor', 'supervisor'),
  deletable: false,
  dynamoDB: {
    tableName: 'SpearManualReservation'
  },
  columns: ['id', 'roomTypeName', 'ratePlanType', 'clientName'],
  fields: [{
    name: 'id',
    label: 'Id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true
  }, {
    groupName: 'Dados básicos da Reserva',
    name: 'clientId',
    label: 'Cliente',
    required: true
  }, {
    name: 'hotelType',
    label: 'Tipo de hotel',
    required: true,
    options: [{
      label: 'Hotel já cadastrado',
      value: 'INTERNAL'
    }, {
      label: 'Hotel não cadastrado',
      value: 'EXTERNAL'
    }]
  }, {
    groupName: 'Dados básicos da Reserva',
    name: 'hotel',
    label: 'Hotel',
    type: 'object',
    fields: [{
      name: 'id',
      label: 'Id'
    }, {
      name: 'name',
      label: 'Nome'
    },
    // TODO: Remover os campos abaixo, eles foram colocados pois o InputHotelDestination precisa deles
    {
      name: 'contentType',
      label: 'Tipo do conteúdo'
    }, {
      name: 'regionIds',
      label: 'Regiões a serem incluídas',
      required: false,
      type: 'textarray'
    }, {
      name: 'address',
      label: 'Endereço',
      type: 'object',
      fields: [{
        name: 'country'
      }, {
        name: 'state'
      }, {
        name: 'cityName'
      }, {
        name: 'street'
      }, {
        name: 'postalCode'
      }]
    }, {
      name: 'email',
      label: 'E-mail'
    }, {
      name: 'phone',
      label: 'Telefone'
    }, {
      name: 'corporateName',
      label: 'Razão Social'
    }, {
      name: 'vatNumber',
      label: 'CNPJ'
    }],
    inputType: 'InputHotelDestination'
  }, {
    groupName: 'Dados básicos da Reserva',
    name: 'supplier',
    label: 'Fornecedor',
    required: true,
    inputType: 'select',
    options: credentialTypesOptions.filter(option => CONTENT.indexOf(option.value) !== -1)
  }, {
    groupName: 'Dados básicos da Reserva',
    name: 'locator',
    label: 'Código da reserva no fornecedor',
    required: true
  }, {
    groupName: 'Dados básicos da Reserva',
    name: 'clientName',
    label: 'Cliente',
    render: false
  }, {
    groupName: 'Dados básicos da Reserva',
    name: 'occupancy',
    label: 'Ocupação',
    type: 'object',
    fields: [{
      name: 'adults',
      label: 'Atultos',
      type: 'int',
      default: 1,
      required: true
    }, {
      name: 'children',
      label: 'Crianças',
      hint: 'por quarto',
      type: 'int',
      default: 0
    }, {
      name: 'childrenAges',
      label: 'Idades das crianças',
      type: 'intarray'
    }]
  }, {
    groupName: 'Dados básicos da Reserva',
    name: 'time',
    label: 'Datas de check-in/check-out',
    type: 'object',
    fields: [{
      name: 'startDate',
      label: 'Data de check-in',
      required: true,
      type: 'date'
    }, {
      name: 'endDate',
      label: 'Data de check-out',
      required: true,
      type: 'date'
    }]
  }, {
    groupName: 'Dados Reserva no Fornecedor',
    name: 'priceComposition',
    label: 'Composição do preço',
    type: 'object',
    fields: [{
      name: 'currency',
      label: 'Moeda',
      type: 'text',
      required: true,
      options: currencies,
      inputType: 'select'
    }, {
      name: 'taxes',
      label: 'Valor das taxas',
      type: 'float',
      required: true
    }, {
      name: 'discounts',
      label: 'Valor dos descontos',
      hint: 'Esse valor será descontado do preço',
      type: 'float',
      default: 0,
      render: false
    }, {
      name: 'nights',
      label: 'Valores das noites (NET)',
      type: 'objectarray',
      fields: [{
        name: 'value',
        label: 'Valor',
        type: 'float',
        required: true
      }, {
        name: 'date',
        label: 'Data',
        required: true,
        type: 'date'
      }, {
        name: 'units',
        label: 'Unidades',
        type: 'int',
        default: 1,
        renderForm: i => false
      }]
    }, {
      name: 'comission',
      label: 'Comissão do fornecedor',
      type: 'float',
      default: 0
    }, {
      name: 'clientCommission',
      label: 'Comissão do cliente',
      type: 'float',
      default: 0
    }, {
      name: 'total',
      label: 'Valor total da reserva',
      type: 'float',
      default: 0
    }]
  }, {
    groupName: 'Dados Reserva no Fornecedor',
    name: 'cancelPolicy',
    label: 'Política de Cancelamento',
    type: 'object',
    fields: [{
      name: 'nonRefundable',
      label: 'Não Reembolsável',
      type: 'boolean',
      default: false
    }, {
      name: 'penaltyDate',
      label: 'Data da penalidade',
      type: 'date',
      renderForm: i => !i?.nonRefundable
    }, {
      name: 'value',
      label: 'Valor da penalidade',
      type: 'float',
      renderForm: i => !i?.nonRefundable
    }]
  }, {
    groupName: 'Dados Reserva no Fornecedor',
    name: 'roomTypeName',
    label: 'Nome do quarto',
    type: 'text',
    required: true
  }, {
    groupName: 'Dados Reserva no Fornecedor',
    name: 'ratePlanType',
    label: 'Tipo da tarifa',
    required: true,
    options: [{
      label: 'Comissisionado',
      value: 'COMMISSION'
    }, {
      label: 'NET',
      value: 'NET'
    }]
  }, {
    groupName: 'Dados Reserva no Fornecedor',
    name: 'paymentType',
    label: 'Pagamento',
    type: 'text',
    options: [{
      label: 'Cartão Offline',
      value: 'OFF_CC',
      hint: 'Cliente pagará fornecedor com VCN/Cartão'
    }, {
      label: 'Direto no local',
      value: 'DIR',
      hint: 'Cliente pagará fornecedor no local do serviço (hotel/aeroporto/etc) e fornecerá cartão de crédito como garantia'
    }, {
      label: 'Direto no local (IATA)',
      value: 'DIR_IATA',
      hint: 'Cliente pagará fornecedor no local do serviço (hotel/aeroporto/etc) e será usada a garantia IATA da agência'
    }, {
      label: 'Faturado Offline',
      value: 'OFF_FAT',
      hint: 'Cliente pagará fornecedor via fatura'
    }, {
      label: 'Antecipado Offline',
      value: 'OFF_ANT',
      hint: 'Cliente pagará fornecedor antecipadamente'
    }]
  }, {
    groupName: 'Dados Reserva no Fornecedor',
    name: 'meal',
    label: 'Regime alimentar',
    type: 'object',
    fields: [{
      name: 'name',
      label: 'Nome'
    }, {
      name: 'included',
      label: 'Regimes alimentar incluídos',
      type: 'textarray',
      inputType: 'chips',
      options: [{
        label: 'Café da Manhã',
        value: 'breakfast'
      }, {
        label: 'Almoço',
        value: 'lunch'
      }, {
        label: 'Jantar',
        value: 'dinner'
      }, {
        label: 'Extra',
        value: 'extra'
      }]
    }]
  }, {
    groupName: 'Dados Reserva no Fornecedor',
    name: 'descriptions',
    label: 'Descrições',
    type: 'object',
    fields: [{
      name: 'cancelPolicy',
      label: 'Política de Cancelamento',
      type: 'objectarray',
      fields: [{
        name: 'name',
        label: 'Nome'
      }, {
        name: 'description',
        label: 'Descrição'
      }]
    }, {
      name: 'generalPolicies',
      label: 'Políticas Gerais',
      type: 'objectarray',
      fields: [{
        name: 'name',
        label: 'Nome'
      }, {
        name: 'description',
        label: 'Descrição'
      }]
    }, {
      name: 'meal',
      label: 'Refeição',
      type: 'objectarray',
      fields: [{
        name: 'name',
        label: 'Nome'
      }, {
        name: 'description',
        label: 'Descrição'
      }]
    }, {
      name: 'payment',
      label: 'Pagamento',
      type: 'objectarray',
      fields: [{
        name: 'name',
        label: 'Nome'
      }, {
        name: 'description',
        label: 'Descrição'
      }]
    }, {
      name: 'roomType',
      label: 'Tipo de quarto',
      type: 'objectarray',
      fields: [{
        name: 'name',
        label: 'Nome'
      }, {
        name: 'description',
        label: 'Descrição'
      }]
    }]
  }]
});