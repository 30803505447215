import { shallowClientDAO } from '@niarab2c/frontend-commons/src/daos';
import hotelDetailsDAO from '@niarab2c/frontend-commons/src/daos/hotelDetailsDAO';
import type { EngineRuleVersion, EntitiesDataProps, GetHotelAvailResponse, HotelDetails, HotelResult, HotelsAvailabilityPayload, Occupancy, OriginalHotelResult } from '@niarab2c/frontend-commons/src/types/hotels';
import callApi from '@niarab2c/frontend-commons/src/util/callApi';
import { createAsyncThunk, createSlice, unwrapResult, type AnyAction, type PayloadAction, type ThunkDispatch } from '@reduxjs/toolkit';
import { parallelLimit, type AsyncFunction } from 'async';
import { format } from 'date-fns';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
import _times from 'lodash/times';
import _uniq from 'lodash/uniq';
import _uniqWith from 'lodash/uniqWith';
import { mergeHotelResults, stringifyOccupancy } from '../../../util/hotels';
import { prepareHotelResult } from '../../../util/hotels/prepareHotelResult';
import { type BaseRootState, type CompleteRootState } from '../../base';
import * as ShoppingCart from '../shoppingCart';
const isSameOccupancy = (a: Occupancy, b: Occupancy): boolean => (a && stringifyOccupancy(a)) == (b && stringifyOccupancy(b));
export type CriteriaForm = {
  startDate: string;
  endDate: string;
  rooms: Array<{
    adults: number;
    children?: number;
    childrenAges?: number[];
  }>;
  destinations?: {
    propertyId?: string;
    contentType?: 'property' | 'region';
    cityIds?: Array<string>;
    hotelIds?: Array<string>;
  };
  destinationName?: string | Array<string>;
  bestOnly?: boolean;
  promoCode?: string;
  couponCode?: string;
  credentialIds?: string[];
  availRatesOnly?: boolean;
  distributionIds?: string[];
  roomRateIds?: string[];
  clientId?: string;
  clientName?: string;
  enablePromoCode?: boolean;
  isThirdPartyReservation?: boolean;
  quotationId?: string;
  personId?: string; // usado apenas para front unificado
  personName?: string;
  previousOrderId?: string;
  user?: any;
  personTSER_idContrato?: number;
};
export interface State {
  criteria?: CriteriaForm;
  start?: number;
  end?: number;
  status?: 'searching' | 'idle' | 'complete' | 'error';
  errorMessage?: string;
  searchId?: string;
  results?: Record<string, HotelResult>;
  referencePoint?: {
    latitude: number;
    longitude: number;
  };
  rawDistanceDisabled?: boolean;
  entitiesData?: Record<string, EntitiesDataProps>;
  engineRuleVersion?: EngineRuleVersion;
  messages?: GetHotelAvailResponse['messages'];
}
type GetHotelsResultsTypeParamsType = {
  criteriaForm: CriteriaForm;
  limit: number | null;
  offset: number | null;
  occupancy: Occupancy;
  searchId: string;
  roomRatesLimit?: number | null;
  clientId: string;
  landingPageLocator: string;
  quotationToken: string;
  quotationIndex?: number;
  selfBooking: boolean;
  b2c: boolean;
  personBalance?: number;
  personContractStatus?: string;
  manualReservationId?: string;
  personTSER_idContrato?: number;
  pointsToBurn: number | null;
  loyaltyRedeemId: string | null;
} & Required<Pick<HotelsAvailabilityPayload, 'purchaseSessionId' | 'engineRuleVersion' | 'discountedClientCommissionPercentage' | 'discountedMarkupPercentage' | 'paymentOptionId' | 'paymentOptionType' | 'analyticsEventType'
// | 'isThirdPartyReservation' | 'couponCode'  /* estão no CriteriaForm */
>>;
type SearchHotelsResponse = {
  results: HotelResult[];
  searchId: string;
  errorMessage?: string;
};
export const searchHotels = createAsyncThunk<SearchHotelsResponse, {
  criteriaForm: CriteriaForm;
  searchId: string;
  maxPropertyCnt?: number;
  roomRatesLimit?: number;
  engineRuleVersion;
  manualReservationId?: string;
  quotationToken?: string;
  quotationIndex?: number;
  discountedClientCommissionPercentage?: number;
  discountedMarkupPercentage?: number;
  /**
   * @deprecated
   */
  paymentOptionId?: string;
  paymentOptionType?: string;
  analyticsEventType?: null | 'hotelAvailability';
  loyaltyRedeemId?: string;
  pointsToBurn?: number;
}, {
  state: CompleteRootState;
  rejectValue: {
    searchId: string;
    errorMessage: string;
  };
}>('hotelSearch/searchHotels', async ({
  criteriaForm,
  searchId,
  maxPropertyCnt,
  roomRatesLimit,
  engineRuleVersion,
  manualReservationId,
  quotationToken,
  quotationIndex,
  discountedClientCommissionPercentage,
  discountedMarkupPercentage,
  paymentOptionId,
  paymentOptionType,
  analyticsEventType,
  pointsToBurn,
  loyaltyRedeemId
}, {
  getState,
  dispatch,
  rejectWithValue
}) => {
  const rootState = getState();
  const landingPageLocator = rootState.storefrontConfig?.storefront?.locator;
  const b2c = rootState.authentication?.b2c;
  const selfBooking = b2c || rootState.authentication?.isClientUser;
  const personTSER_idContrato = criteriaForm?.personTSER_idContrato ?? rootState.shoppingCart?.personTSER_idContrato;

  // no ota-builder, o clientId está no reducer
  // no cotação, o clientId está no reducer
  // no front-unificado, o clientId virá do criteriaForm
  const clientId = criteriaForm?.clientId ?? rootState.quotation?.quotation?.clientId ?? rootState.core.clientId;
  const purchaseSessionId = rootState.shoppingCart?.purchaseSessionId;
  const tenant = rootState?.core?.tenant;
  const client = rootState?.core?.client ?? clientId ? await shallowClientDAO.findById(clientId) : null;
  const mandatory_engineRuleVersion = client?.engineRule_enforceVersion_deleteme != null ? client?.engineRule_enforceVersion_deleteme : tenant.distribution_disabled ? '1' : null;
  if (mandatory_engineRuleVersion != null && mandatory_engineRuleVersion != engineRuleVersion) {
    return rejectWithValue({
      errorMessage: engineRuleVersion == '1' ? "Novo motor incompatível com instalação/cliente" : "Motor com distribuição incompatível com instalação/cliente",
      searchId
    });
  }

  // personId escolhido nos critérios do front unificado
  const personId = b2c ? rootState?.authentication?.user?.personId : criteriaForm?.personId;
  if (b2c && criteriaForm?.personId && criteriaForm?.personId != rootState?.authentication?.user?.personId) {
    criteriaForm.personId = rootState?.authentication?.user?.personId;
  }
  let personBalance = null;
  let personContractStatus = null;
  if (!b2c) {
    const setPersonResponse = await dispatch(ShoppingCart.setPerson({
      personId,
      clientId,
      personTSER_idContrato
    })).then(unwrapResult);
    personBalance = setPersonResponse?.personBalance;
    personContractStatus = setPersonResponse?.personContractStatus;
  }
  if (!criteriaForm?.startDate) {
    return rejectWithValue({
      errorMessage: "Erro na pesquisa, não há data de check-in",
      searchId
    });
  }
  if (!criteriaForm?.endDate) {
    return rejectWithValue({
      errorMessage: "Erro na pesquisa, não há data de check-out",
      searchId
    });
  }
  if (!b2c && personContractStatus && personContractStatus !== 'ACTIVE') {
    return rejectWithValue({
      errorMessage: "Erro na pesquisa, contrato com status inválido",
      searchId
    });
  }

  // data de acordo com o timezone do usuário, não UTC
  // ver bug #165982
  const TODAY_YYYY_MM_DD = format(Date.now(), 'yyyy-MM-dd');
  if (criteriaForm.startDate < TODAY_YYYY_MM_DD) {
    return rejectWithValue({
      errorMessage: "Erro na pesquisa, check-in no passado",
      searchId
    });
  }
  if (criteriaForm.startDate > criteriaForm.endDate) {
    return rejectWithValue({
      errorMessage: "Erro na pesquisa, data de check-in após a data de check-out",
      searchId
    });
  }
  if (criteriaForm?.startDate === criteriaForm?.endDate) {
    return rejectWithValue({
      errorMessage: "Erro na pesquisa, data de check-in igual à data de check-out",
      searchId
    });
  }

  // if (quotationToken) {
  //   //TODO: como fazer com cotação?
  //   return {
  //     results: undefined,
  //     searchId,
  //   }
  // }

  dispatch(clearMessagesAvailResponse());
  try {
    const availRatesOnly = criteriaForm.availRatesOnly ?? true;
    const {
      destinations
    } = criteriaForm;
    return await Promise.all(_uniqWith(criteriaForm.rooms, isSameOccupancy).map(occupancy => {
      return async function (): Promise<SearchHotelsResponse> {
        if (destinations.propertyId && destinations.contentType) {
          // busca a quantidade de propriedades no destino
          // O clientId é essencial para apenas considerar os destinos
          // de acordo com o cliente, no caso de escolher "Todos"
          const {
            propertyCnt,
            pageSize: customPageSize
          } = (await callApi('niara-spear-content-integrations', '/content/propertyCnt', 'get', {
            params: {
              propertyId: destinations.propertyId,
              contentType: destinations.contentType,
              clientId
            },
            authenticationType: 'NIARA_AUTH'
          })) || {
            propertyCnt: 600
          };
          // se a quantidade de hotéis for abaixo de 20, força pesquisa com bestOnly false
          // também não pesquisa com bestOnly se availRatesOnly == false
          const bestOnly = !availRatesOnly ? false : criteriaForm.bestOnly ?? propertyCnt < 20 ? false : true;
          const propertyLimit = maxPropertyCnt > 0 ? Math.min(maxPropertyCnt, propertyCnt) : Math.min(2500 /* quantidade de hotéis em Roma na Reservia em 2024-08-06: 2233 */, propertyCnt);
          const pageSize = !bestOnly ? 20 : customPageSize || 100; // limite de quantidade de hotéis por "página"
          const concurrencyLimit = 10; // limite escolhido arbitrariamente para buscas em paralelo

          // quantidade de páginas
          const PAGES = Math.ceil(propertyLimit / pageSize);
          const errors: any[] = [];
          const asyncFunctions = _times(PAGES, (index): AsyncFunction<HotelResult[]> => callback => {
            const offset = pageSize * index;
            const paramsGetHotels: GetHotelsResultsTypeParamsType = {
              criteriaForm: {
                ...criteriaForm,
                bestOnly,
                availRatesOnly
              },
              limit: pageSize,
              occupancy,
              offset,
              searchId,
              roomRatesLimit,
              clientId,
              landingPageLocator,
              quotationToken,
              quotationIndex,
              b2c,
              selfBooking,
              personBalance,
              personContractStatus,
              engineRuleVersion,
              manualReservationId,
              purchaseSessionId,
              discountedClientCommissionPercentage,
              discountedMarkupPercentage,
              personTSER_idContrato,
              paymentOptionId,
              paymentOptionType,
              analyticsEventType: index === 0 ? analyticsEventType : undefined,
              pointsToBurn,
              loyaltyRedeemId
            };
            return _getHotelsResults(paramsGetHotels, dispatch).then(r => {
              if (r?.length > 0) {
                dispatch?.(updateHotelResults({
                  results: r,
                  searchId
                }));
              }
              callback(null, r);
              return r;
            }).catch(err => {
              errors.push(err);
              callback(null, null);
            });
          });
          return await new Promise<SearchHotelsResponse>((res, rej) => {
            parallelLimit(asyncFunctions, concurrencyLimit, (err, results) => {
              const successes = results?.filter(Boolean);
              if (successes?.length > 0) {
                const errorMessage = errors?.length > 0 ? _uniq(errors.map(err => typeof err == 'string' ? err : err?.message ?? err?.errorMessage ?? "Erro desconhecido").filter(Boolean)).join(', ') : undefined;
                res({
                  results: successes.flat(1),
                  errorMessage,
                  searchId
                });
              } else if (errors?.length > 0) {
                rej(errors?.[0]);
              } else {
                res({
                  results: [],
                  searchId
                });
              }
            });
          });
        } else {
          const paramsGetHotels: GetHotelsResultsTypeParamsType = {
            criteriaForm: {
              bestOnly: false,
              availRatesOnly,
              ...criteriaForm
            },
            limit: null,
            occupancy,
            offset: null,
            searchId,
            roomRatesLimit,
            landingPageLocator,
            clientId,
            quotationToken,
            quotationIndex,
            b2c,
            selfBooking,
            personBalance,
            personContractStatus,
            engineRuleVersion,
            manualReservationId,
            purchaseSessionId,
            discountedClientCommissionPercentage,
            discountedMarkupPercentage,
            personTSER_idContrato,
            paymentOptionId,
            paymentOptionType,
            analyticsEventType,
            pointsToBurn,
            loyaltyRedeemId
          };
          return await _getHotelsResults(paramsGetHotels, dispatch).then(r => {
            if (r?.length > 0) {
              dispatch?.(updateHotelResults({
                results: r,
                searchId
              }));
            }
            return {
              results: r,
              searchId
            };
          });
        }
      }();
    })).then(responses => {
      return responses.reduce<SearchHotelsResponse>((acc, response) => {
        if (response.results?.length > 0) acc.results.push(...response.results);
        acc.errorMessage = _uniq([acc.errorMessage, response.errorMessage].filter(Boolean)).join(', ');
        return acc;
      }, {
        results: [],
        searchId
      });
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return rejectWithValue({
      errorMessage: error?.message || error?.errorMessage || typeof error == 'string' && error || "Erro desconhecido",
      searchId
    });
  }
});

/**
 *
 * @param param0
 * @returns
 */
const _getHotelsResults = async (params: GetHotelsResultsTypeParamsType, dispatch?: ThunkDispatch<CompleteRootState, unknown, AnyAction>): Promise<HotelResult[]> => {
  const {
    criteriaForm,
    limit,
    offset,
    occupancy,
    roomRatesLimit,
    searchId,
    clientId,
    landingPageLocator,
    selfBooking,
    b2c,
    personBalance,
    personContractStatus,
    engineRuleVersion,
    manualReservationId,
    quotationToken,
    quotationIndex,
    purchaseSessionId,
    discountedClientCommissionPercentage,
    discountedMarkupPercentage,
    personTSER_idContrato,
    paymentOptionId,
    paymentOptionType,
    analyticsEventType,
    loyaltyRedeemId,
    pointsToBurn
  } = params;
  const criteria: HotelsAvailabilityPayload['criteria'] = {
    ..._pick(criteriaForm, 'availRatesOnly', 'promoCode', 'limit', 'offset', 'credentialIds', 'bestOnly', 'distributionIds', 'roomRateIds', 'destinations'),
    searchId,
    time: {
      endDate: criteriaForm.endDate,
      startDate: criteriaForm.startDate
    },
    destinationName: !criteriaForm.destinationName ? undefined : typeof criteriaForm.destinationName == 'string' ? criteriaForm.destinationName : criteriaForm.destinationName.join(', '),
    clientId: clientId ?? criteriaForm.clientId,
    clientName: criteriaForm?.clientName,
    landingPage: landingPageLocator,
    occupancy: {
      ...occupancy,
      childrenAges: occupancy.childrenAges ?? _times(occupancy.children ?? 0, () => 0),
      quantity: 1 // TODO
    },
    selfBooking,
    // NIT-1142
    b2c // NIT-1142
  };
  const apipath = landingPageLocator ? `/otabuilder/${landingPageLocator}/hotels/availability` : manualReservationId ? `/hotels/manual/${manualReservationId}/availability` : quotationToken ? `/quotationTokens/${quotationToken}/availability/${quotationIndex}` : '/hotels/availability';
  const payload: HotelsAvailabilityPayload = {
    isThirdPartyReservation: criteriaForm?.isThirdPartyReservation || false,
    buyer_personId: criteriaForm?.personId ?? undefined,
    engineRuleVersion,
    couponCode: criteriaForm?.couponCode,
    criteria: {
      ...criteria,
      limit,
      offset
    },
    purchaseSessionId,
    discountedClientCommissionPercentage,
    discountedMarkupPercentage,
    TSER_idContrato: personTSER_idContrato,
    paymentOptionId,
    paymentOptionType,
    analyticsEventType,
    pointsToBurn,
    loyaltyRedeemId
  };
  return await callApi('niara-spear-booking', apipath, 'post', {
    body: payload
  }).then((response: GetHotelAvailResponse) => {
    //Armazenando mensagens de erro
    if (response?.messages?.length > 0) {
      dispatch?.(addMessagesAvailResponse({
        messages: response.messages
      }));
    }
    //força o hotel id do resultado, se o critério veio com hotelId
    const hotelId = criteria?.destinations?.hotelIds?.length === 1 ? criteria?.destinations?.hotelIds[0] : null;

    //setar occupation para cada room rate recebido!
    const entitiesData = response.entitiesData;
    const preparedResults = response.hotels?.map(hotelResult => {
      if (hotelId) {
        //força o hotel id do resultado, se o critério veio com hotelId
        hotelResult['_id'] = hotelId;
      }
      if (roomRatesLimit > 0 && hotelResult.roomRates?.length > 0) {
        hotelResult.roomRates = hotelResult.roomRates.slice(0, roomRatesLimit);
      }
      if (roomRatesLimit > 0 && hotelResult.notAvailableRoomRates?.length > 0) {
        hotelResult.notAvailableRoomRates = hotelResult.notAvailableRoomRates.slice(0, roomRatesLimit);
      }
      delete hotelResult['debugRates'];
      return prepareHotelResult(hotelResult, undefined, {
        occupancy,
        entitiesData,
        promoCode: criteria.promoCode,
        clientId: criteria.clientId,
        selfBooking,
        b2c,
        personBalance,
        personContractStatus,
        engineRuleVersion,
        manualReservationId,
        quotationIndex,
        quotationToken
      });
    });
    return preparedResults;
  });
};
const initialState: State = {};

/**
 * HotelSearch é feito para ser inserido em outro payload.
 * Atenção: todo action precisa ter searchId para funcionar corretamente!
 */
const searchSlice = createSlice({
  name: 'hotelSearch',
  initialState: initialState,
  reducers: {
    setSearchId: (state, action: PayloadAction<{
      searchId: string;
    }>) => {
      state.searchId = action.payload.searchId;
    },
    updateHotelResults: (state, action: PayloadAction<{
      results: Array<Partial<HotelResult | OriginalHotelResult>>;
      searchId: string;
    }>) => {
      if (state.searchId == action.payload.searchId) {
        const {
          results: hotels
        } = action.payload;
        const results = mergeHotelResults(hotels, state.results);
        // Reference Point será usado como centro do mapa se todos os resultados forem filtrados
        // TODO: ideal é colocar a posição central do destino, em caso de cidade
        const referencePoint = state.referencePoint ?? Object.values(results)?.find(r => r._hotelDetails?.location)?._hotelDetails?.location ?? null;
        return {
          ...state,
          results,
          referencePoint
        };
      }
      return state;
    },
    addMessagesAvailResponse: (state, action: PayloadAction<{
      messages?: GetHotelAvailResponse['messages'];
    }>) => {
      const messages = action?.payload?.messages ?? [];
      if (messages.length > 0) {
        const currentMessages = state?.messages ?? [];
        return {
          ...state,
          messages: currentMessages.concat(messages)
        };
      }
    },
    clearMessagesAvailResponse: (state: State) => {
      return {
        ...state,
        messages: []
      };
    }
  },
  extraReducers: builder => {
    builder.addCase(searchHotels.pending, (state, p) => {
      const arg = p.meta?.arg;
      if (!state.searchId || state.searchId == arg.searchId) {
        return {
          ...state,
          start: Date.now(),
          end: undefined,
          status: 'searching',
          searchId: arg.searchId,
          criteria: arg.criteriaForm,
          errorMessage: null,
          engineRuleVersion: arg.engineRuleVersion
        };
      }
    }).addCase(searchHotels.fulfilled, (state, {
      payload
    }) => {
      if (state.searchId == payload.searchId) {
        state.status = 'complete';
        state.end = Date.now();
        if (payload.errorMessage) {
          state.errorMessage = payload.errorMessage;
        }
        return state;
      } else {
        return state;
      }
    }).addCase(searchHotels.rejected, (state, {
      payload
    }) => {
      if (state.searchId == payload.searchId) {
        return {
          ...state,
          status: 'complete',
          errorMessage: payload.errorMessage
        };
      }
      return state;
    });
  }
});
export default searchSlice.reducer;

// Action creators are generated for each case reducer function
const {
  updateHotelResults,
  addMessagesAvailResponse,
  clearMessagesAvailResponse
} = searchSlice.actions;
export const setHotelDetails = (hotelResultId: string, hotelDetails: HotelDetails, searchId: string, hotelDetailsNotFound?: HotelResult['_hotelDetailsNotFound']): ReturnType<typeof updateHotelResults> => {
  return updateHotelResults({
    results: [{
      _id: hotelResultId,
      _hotelDetails: hotelDetails,
      _hotelDetailsHotelId: hotelDetails?.hotelId,
      _hotelDetailsInProgress: false,
      _hotelDetailsNotFound: hotelDetailsNotFound
    }],
    searchId
  });
};
export const loadHotelDetails = createAsyncThunk<{
  hotelDetails: HotelDetails[];
}, {
  results: Array<HotelResult>;
  searchId: string;
}, {
  state: BaseRootState;
}>('hotelSearch/loadHotelDetails', async ({
  results,
  searchId
}, {
  getState,
  dispatch
}) => {
  const tenantId = getState().core.tenantId;
  let shouldLoadNotEmpty = false;
  const x = results?.map<[boolean, HotelDetails, HotelResult]>(hotelResult => {
    const shouldLoad = !hotelResult._hotelDetails && !hotelResult._hotelDetailsInProgress && !hotelResult?._hotelDetailsNotFound;
    shouldLoadNotEmpty = shouldLoadNotEmpty || shouldLoad;
    return [shouldLoad, hotelResult._hotelDetails, hotelResult];
  });
  if (!shouldLoadNotEmpty) {
    return {
      hotelDetails: x.map(([, hotelDetails]) => hotelDetails)
    };
  }
  dispatch(updateHotelResults({
    results: x.filter(([shouldLoad]) => shouldLoad).map(([,, hotelResult]) => ({
      _id: hotelResult._id,
      _hotelDetailsInProgress: true
    })),
    searchId
  }));
  return Promise.all(x.map(([shouldLoad, hotelDetails, hotelResult]) => {
    if (!shouldLoad) return hotelDetails;

    // precisa carregar os detalhes do hotel
    const resultId = hotelResult._id;
    const hotelIdToSearch = hotelResult?.omniHotelId ?? hotelResult?._id; // prioriza o hotel details da omnibees
    return hotelDetailsDAO.findById(hotelIdToSearch, tenantId, hotelResult.clientId).catch(() => null).then(hotelDetails => {
      let hotelDetailsNotFound = false;
      if (!hotelDetails) {
        hotelDetailsNotFound = true;
      }
      dispatch(setHotelDetails(resultId, hotelDetails, searchId, hotelDetailsNotFound));
      return hotelDetails;
    });
  })).then(hotelDetails => {
    return {
      hotelDetails
    };
  });
});
export const hydrateHotelResults = createAsyncThunk<SearchHotelsResponse, {
  results: Pick<HotelResult, '_id' | 'hotel' | '_hydrationCompleted' | '_hydrationInProgress'>[];
  criteriaForm: CriteriaForm;
  searchId;
  engineRuleVersion: EngineRuleVersion;
  /**
   * @deprecated
   */
  paymentOptionId?: string;
  paymentOptionType?: string;
}, {
  state: CompleteRootState;
  rejectValue: {
    searchId: string;
    errorMessage: string;
  };
}>('hotelSearch/hydrateHotelResults', async ({
  results: _paramResults,
  criteriaForm,
  searchId,
  engineRuleVersion,
  paymentOptionId,
  paymentOptionType
}, {
  dispatch,
  getState,
  rejectWithValue
}) => {
  const rootState = getState();
  const landingPageLocator = rootState.storefrontConfig?.storefront?.locator;
  const quotationToken = rootState.quotation?.quotation?.quotationToken;
  const clientId = rootState.core.clientId;
  const b2c = rootState.authentication?.b2c;
  const selfBooking = b2c || rootState.authentication?.isClientUser;
  const purchaseSessionId = rootState.shoppingCart?.purchaseSessionId;
  const isHydratable = (r: (typeof _paramResults)[number]) => !r._hydrationCompleted && !r._hydrationInProgress;
  const hydratableResults = _paramResults.filter(isHydratable);
  const loyaltyRedeemId = rootState.shoppingCart?.loyaltyRedeemId;
  const pointsToBurn = loyaltyRedeemId ? rootState.shoppingCart?.pointsToBurn : null;
  if (quotationToken) {
    //TODO: como fazer com cotação?
    return {
      results: undefined,
      searchId
    };
  }

  // marca todos os resultados como _hydrationInProgress
  dispatch(updateHotelResults({
    results: hydratableResults.map(r => ({
      _id: r._id,
      _hydrationInProgress: true
    })),
    searchId
  }));
  const errors: any[] = [];
  const availRatesOnly = criteriaForm.availRatesOnly ?? true;
  const asyncFunctions: AsyncFunction<HotelResult[]>[] = _paramResults.map(hr => callback => {
    if (!isHydratable(hr)) {
      // resultados já hidratados - retorna o próprio resultado
      const r = [(hr as HotelResult)];
      callback(null, r);
      return Promise.resolve(r);
    } else {
      return Promise.all(criteriaForm.rooms.map(occupancy => {
        return async function () {
          const paramsGetHotels: GetHotelsResultsTypeParamsType = {
            criteriaForm: {
              bestOnly: false,
              availRatesOnly,
              ...criteriaForm,
              destinations: {
                hotelIds: [hr._id]
              }
            },
            limit: null,
            occupancy,
            offset: null,
            searchId,
            clientId,
            landingPageLocator,
            quotationToken,
            b2c,
            selfBooking,
            engineRuleVersion,
            purchaseSessionId,
            discountedClientCommissionPercentage: null,
            discountedMarkupPercentage: null,
            paymentOptionId,
            paymentOptionType,
            pointsToBurn,
            loyaltyRedeemId
          };
          //return await _getHotelsResults(paramsGetHotels)
          return await _getHotelsResults(paramsGetHotels).then(r => {
            if (r?.length > 0) {
              dispatch?.(updateHotelResults({
                results: r.map(r => ({
                  ...r,
                  _id: hr._id,
                  _hydrationInProgress: false,
                  _hydrationCompleted: true
                })),
                searchId
              }));
            }
            return r;
          });
        }();
      })).then(hotelResultsArrays => {
        const r = hotelResultsArrays.flat(1);
        callback(null, r);
        return r;
      }).catch(err => {
        errors.push(err);
        // deu erro na hidratação? atualiza _hydrationInProgress do resultado
        dispatch(updateHotelResults({
          results: [{
            _id: hr._id,
            _hydrationInProgress: true
          }],
          searchId
        }));
        callback(null, null);
        return null;
      });
    }
  });
  const concurrencyLimit = 10; // limite escolhido arbitrariamente para buscas em paralelo
  try {
    return await new Promise<SearchHotelsResponse>((res, rej) => {
      parallelLimit(asyncFunctions, concurrencyLimit, (err, results) => {
        const successes = results?.filter(Boolean);
        if (successes?.length > 0) {
          const errorMessage = errors?.length > 0 ? _uniq(errors.map(err => typeof err == 'string' ? err : err?.message ?? err?.errorMessage ?? "Erro desconhecido").filter(Boolean)).join(', ') : undefined;
          res({
            results: successes.flat(1),
            errorMessage,
            searchId
          });
        } else if (errors?.length > 0) {
          rej(errors?.[0]);
        } else {
          res({
            results: [],
            searchId
          });
        }
      });
    }).then(r => {
      return r;
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return rejectWithValue({
      errorMessage: error?.message || error?.errorMessage || "Erro desconhecido",
      searchId
    });
  }
});