import styled from 'styled-components'
import { BREAKPOINT_MD, CONTAINER_MAX_WIDTH } from '../../theme/ota'

interface FormCriteriaProps {
  searchEngine_orientation?: string
  centered?: boolean
}
interface ContainerGridProps {
  verticalOrientation?: boolean
}

export const ContainerCriteria = styled.div<FormCriteriaProps>`
  &&& {
    align-items: center !important;
    display: flex !important;
    justify-content: space-between !important;
    margin: ${({ centered }) => (centered ? '0 auto' : '0')} !important;
    @media (min-width: calc(${BREAKPOINT_MD})) {
      max-width: ${({ searchEngine_orientation }) =>
        searchEngine_orientation === 'VERTICAL' ? '375px' : CONTAINER_MAX_WIDTH} !important;
    }
    width: 100% !important;

    & > form {
      display: flex !important;
      flex-grow: 1;
      justify-content: start !important;
      width: 100% !important;
      h3 {
        font-size: 16px !important;
        line-height: 27px !important;
      }

      @media (max-width: calc(${BREAKPOINT_MD})) {
        flex-direction: column !important;
        flex-wrap: wrap !important;
        align-self: stretch !important;
        display: block !important;
        font-size: 16px !important;
      }

      @media (min-width: calc(${BREAKPOINT_MD})) {
        flex-direction: ${({ searchEngine_orientation }) =>
          searchEngine_orientation === 'VERTICAL' ? 'column' : ''} !important;
      }
    }
  }
`

export const TitleInputs = styled.p`
  &&& {
    box-shadow: none !important;
    color: currentColor;
    display: flex !important;
    font-size: 16px !important;
    font-weight: bold !important;
    margin-bottom: 10px !important;
    margin-top: 0px !important;
    text-shadow: none !important;

    @media (min-width: calc(${BREAKPOINT_MD})) {
      font-size: 13px !important;
      margin-bottom: 5px !important;
    }

    span {
      font-weight: lighter !important;
      margin-left: auto !important;
    }
  }
`

export const TitleRoom = styled.p`
  &&& {
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 19.12px;
  }
`

export const BoxButton = styled.div<FormCriteriaProps>`
  &&& {
    @media (min-width: calc(${BREAKPOINT_MD})) {
      display: flex !important;
      flex-direction: column !important;
      justify-content: flex-end !important;
      margin-left: ${({ searchEngine_orientation }) =>
        searchEngine_orientation === 'VERTICAL' ? '0' : '4px'} !important;
      margin-top: ${({ searchEngine_orientation }) =>
        searchEngine_orientation === 'VERTICAL' ? '12px' : '0'} !important;
    }

    @media (max-width: ${BREAKPOINT_MD}) {
      margin-left: 0 !important;
    }
  }
`

export const TooltipContent = styled.span``

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  ${TooltipContent} {
    width: 245px;
    white-space: pre-wrap;
    top: 28px;
    background-color: #273240;
    color: #fff;
    border-radius: 4px;
    line-height: 20px;
    text-align: left;
    right: 0px;
    padding: 10px;
    font-size: 14px !important;
    font-weight: lighter;
    z-index: 10;
    position: absolute;
    z-index: 1;
    &::after {
      bottom: 100%;
      right: 26px;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: #273240;
      border-width: 8px;
      margin-left: -65px;
    }
  }
`

export const BoxRoom = styled.div``

export const BoxOccupancy = styled.div`
  &&& {
    div.guest-count {
      align-items: center !important;
      display: flex !important;
      justify-content: space-between !important;
      margin-bottom: 15px !important;

      ${TitleInputs} {
        font-size: 14px !important;
        font-weight: 400 !important;
        margin-bottom: 0 !important;

        small {
          color: ${({ theme }) => theme?.colors?.gray5} !important;
          display: block !important;
          font-size: 11px !important;
        }
      }
    }

    @media (max-width: calc(${BREAKPOINT_MD})) {
      padding-top: 20px !important;
      border-bottom: 1px solid ${({ theme }) => theme?.colors?.gray4} !important;

      div.guest-count {
        ${TitleInputs} {
          font-size: 16px !important;
        }
      }
      div.border {
        border-bottom: 1px solid ${({ theme }) => theme?.colors?.gray4} !important;
        padding-bottom: 20px !important;
      }
    }
  }
`

export const BtnPosition = styled.div`
  &&& {
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 14px !important;
    min-width: 85px !important;
    padding-bottom: 1px !important;
    width: 100% !important;
  }
`

export const BoxDestination = styled.div<FormCriteriaProps>`
  &&& {
    @media (min-width: calc(${BREAKPOINT_MD})) {
      display: flex !important;
      flex-direction: column !important;
      justify-content: flex-end !important;
      flex-grow: 1 !important;
      min-width: 240px !important;
      margin: ${({ searchEngine_orientation }) =>
        searchEngine_orientation === 'VERTICAL' ? '0' : '0 5px 0px 0px'} !important;
    }

    @media (max-width: calc(${BREAKPOINT_MD})) {
      margin-bottom: 26px !important;
      width: 100% !important;
    }
  }
`

export const BoxDates = styled.div<FormCriteriaProps>`
  &&& {
    @media (min-width: calc(${BREAKPOINT_MD})) {
      display: flex !important;
      flex-direction: column !important;
      justify-content: flex-end !important;
      margin: ${({ searchEngine_orientation }) => (searchEngine_orientation === 'VERTICAL' ? '0' : '0 5px')} !important;
      width: ${({ searchEngine_orientation }) =>
        searchEngine_orientation === 'VERTICAL' ? '100%' : ' 310px'} !important;
      min-width: 250px;
    }

    @media (max-width: calc(${BREAKPOINT_MD})) {
      margin-bottom: 30px !important;
      width: 100% !important;
    }
  }
`

export const DateBaseStyles = styled.div`
  &&& {
    & + div {
      position: relative !important;
      left: 420px !important;
    }
  }
`

export const BoxRooms = styled.div<FormCriteriaProps>`
  &&& {
    @media (min-width: calc(${BREAKPOINT_MD})) {
      display: flex !important;
      flex-direction: column !important;
      justify-content: flex-end !important;
      margin: ${({ searchEngine_orientation }) => (searchEngine_orientation === 'VERTICAL' ? '0' : '0 5px')} !important;
      width: ${({ searchEngine_orientation }) =>
        searchEngine_orientation === 'VERTICAL' ? '100%' : ' 210px'} !important;
    }

    @media (max-width: calc(${BREAKPOINT_MD})) {
      width: 100% !important;
      margin-bottom: 30px !important;
    }
  }
`

export const BoxCode = styled.div<FormCriteriaProps>`
  &&& {
    @media (min-width: calc(${BREAKPOINT_MD})) {
      display: flex !important;
      flex-direction: column !important;
      justify-content: flex-end !important;
      min-width: ${({ searchEngine_orientation }) =>
        searchEngine_orientation === 'VERTICAL' ? '100%' : '160px'} !important;
      margin: ${({ searchEngine_orientation }) => (searchEngine_orientation === 'VERTICAL' ? '0' : '0 5px')} !important;
    }
    @media (max-width: calc(${BREAKPOINT_MD})) {
      width: 100% !important;
      margin-bottom: 20px !important;
    }
  }
`

export const BoxChildren = styled.div`
  &&& {
    display: flex !important;
    flex-wrap: wrap !important;
    margin-top: 30px !important;
    gap: 10px !important;
    margin-bottom: 20px !important;
    padding-left: 0 !important;

    div {
      flex: 1 !important;
      min-width: 85px !important;
    }

    @media (max-width: calc(${BREAKPOINT_MD})) {
      margin-bottom: 30px !important;
    }
  }
`

export const BoxClients = styled.div<FormCriteriaProps>`
  .MuiInputBase-root {
    padding: 2px !important;
    margin: 0 !important;
  }

  input {
    padding: 8px !important;
  }

  &&& {
    @media (min-width: calc(${BREAKPOINT_MD})) {
      display: flex !important;
      flex-direction: column !important;
      justify-content: flex-end !important;
      flex-grow: 1 !important;
      min-width: 240px !important;
      margin: ${({ searchEngine_orientation }) =>
        searchEngine_orientation === 'VERTICAL' ? '0' : '0 5px 0px 0px'} !important;
    }

    @media (max-width: calc(${BREAKPOINT_MD})) {
      margin-bottom: 26px !important;
      width: 100% !important;
    }
  }
`

export const HeaderClient = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: space-between;
`

export const ContainerHotelCriteria = styled.div`
  &&& {
    display: flex;
    justify-content: center;
    width: 100%;
    //min-width: 1071px; Causou problema no widget no hottravel (botão buscar ficou colado na div)
    max-width: ${CONTAINER_MAX_WIDTH};

    form {
      width: 100%;
    }

    @media (max-width: calc(${BREAKPOINT_MD})) {
      min-width: auto;
    }
  }
`

// ================================
export const ContainerGrid = styled.div<ContainerGridProps>`
  &&& {
    display: flex;
    gap: 15px;
    flex-wrap: ${({ verticalOrientation }) => (verticalOrientation ? 'wrap' : '')};
    width: 100%;

    &:first-of-type {
      margin-bottom: ${({ verticalOrientation }) => (verticalOrientation ? '8px' : '20px')};
    }

    input {
      font-family: 'Manrope', sans-serif;
    }

    @media (max-width: calc(${BREAKPOINT_MD})) {
      flex-wrap: wrap;
    }

    @media (min-width: calc(${BREAKPOINT_MD})) {
      .destination-field {
        min-width: ${({ verticalOrientation }) => (verticalOrientation ? '100%' : '200px')};
      }
      .toggle-person-field {
        max-width: ${({ verticalOrientation }) => (verticalOrientation ? '100%' : '235px')};
        min-height: 42px;
        display: flex;

        @media (max-width: calc(${BREAKPOINT_MD})) {
          min-width: auto;
        }
      }
      .person-field {
        min-width: 250px;
        max-width: ${({ verticalOrientation }) => (verticalOrientation ? '100%' : '275.5px')};
      }
      .date-field {
        min-width: ${({ verticalOrientation }) => (verticalOrientation ? '100%' : '250px')};
        max-width: ${({ verticalOrientation }) => (verticalOrientation ? '100%' : '297px')};
      }
      .occupancy-field {
        max-width: ${({ verticalOrientation }) => (verticalOrientation ? '100%' : '210px')};
      }
      .client-field {
        max-width: ${({ verticalOrientation }) => (verticalOrientation ? '100%' : '190px')};
      }
      .promocode-field {
        max-width: ${({ verticalOrientation }) => (verticalOrientation ? '100%' : '174px')};
      }
      .loyalty-option-field {
        max-width: ${({ verticalOrientation }) => (verticalOrientation ? '100%' : '250px')};
      }
    }
  }
`

export const Grid = styled.div`
  &&& {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    &:focus,
    &:focus-visible {
      border: none;
      outline: none;
    }

    &.loyalty-option-field {
      & .MuiInputBase-root {
        margin: 0;
        padding: 0 10px;

        & .MuiInputBase-input {
          margin: 0;
          padding: 0;
          height: 42px;

          @media (max-width: calc(${BREAKPOINT_MD})) {
            height: 52px;
          }
        }
      }
    }
  }
`

export const InputToggle = styled.div`
  &&& {
    display: flex;
    align-items: center;
    gap: 10px;
    min-width: 218px;

    label {
      font-size: 12px;
      margin: 0;
      cursor: pointer;
    }
  }
`

export const Text = styled.p`
  &&& {
    font-size: 12px;
    margin: 0;
  }
`

export const TextQuery = styled(Text)`
  &&& {
    color: ${({ theme }) => theme?.colors?.gray5};
  }
`

export const ButtonStyle = styled.div<ContainerGridProps>`
  &&& {
    align-items: flex-end;
    display: flex;
    width: ${({ verticalOrientation }) => (verticalOrientation ? '100%' : '85px')};

    button {
      height: 42px !important;
      line-height: 0 !important;
    }

    /* Tooltip container */
    .tooltip {
      position: relative;
      display: inline-block;
      border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
      .tooltiptext {
        width: 245px;
        white-space: pre-wrap;
        top: 28px;
        background-color: #273240;
        color: #fff;
        border-radius: 4px;
        line-height: 20px;
        text-align: left;
        right: 0px;
        padding: 10px;
        font-size: 14px !important;
        font-weight: lighter;
        z-index: 10;
        position: absolute;
        z-index: 1;
        &::after {
          bottom: 100%;
          right: 26px;
          border: solid transparent;
          content: '';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-bottom-color: #273240;
          border-width: 8px;
          margin-left: -65px;
        }
      }
    }

    @media (max-width: calc(${BREAKPOINT_MD})) {
      margin-top: 18px;
      width: 100%;
    }
  }
`
export const RenderOption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  &.align-right {
    align-items: flex-end;
  }
`

export const RenderNoOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  margin-bottom: 0;
  padding: 10px;
`

export const RenderOptionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
`

export const Link = styled.p`
  color: ${({ theme }) => theme?.colors?.link};
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 0;

  &:hover {
    text-decoration: none;
  }
`

type ImageGroupProps = {
  src: string
}
export const ImageGroup = styled.img<ImageGroupProps>`
  src: ${({ src }) => `local(${src})`};
  width: 20px;
  height: 20px;
`
