import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Credencial Proxy',
  labelPlural: 'Credenciais Proxy',
  name: 'ProxyCredential',
  deletable: true,
  updatable: true,
  insertable: true,
  authorization: authorization('master', 'master'),
  dynamoDB: {
    tableName: 'SpearProxyCredential'
  },
  columnNames: ['id', 'proxyUsername', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    insertable: false,
    updatable: false,
    render: false,
    id: true
  }, {
    name: 'clientId',
    label: 'Id do cliente',
    renderColumn: false,
    required: true,
    updatable: false
  }, {
    label: 'Proxy Username',
    name: 'proxyUsername',
    type: 'text',
    insertable: true,
    updatable: true,
    required: true
  }, {
    label: 'Omnibees Username',
    name: 'username',
    type: 'text',
    insertable: true,
    updatable: true,
    required: true
  }, {
    label: 'Substituir traços do ID da reserva',
    name: 'idWithoutDashes',
    default: false,
    type: 'boolean',
    hint: 'Atenção: cuidado ao mudar este valor! Ao reservar, envia ao sistema parceiro o ID sem traços, substituindo-os por "-" - necessidade específica da CWT.'
  }, {
    custom_groupName: "Ativo",
    name: 'enabled',
    label: 'Ativo',
    default: false,
    type: 'boolean'
  }],
  filter: [{
    field: 'clientId',
    op: 'eq'
  }]
});