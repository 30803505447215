import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Resultado de Busca',
  labelPlural: 'Resultados de Busca',
  name: 'SearchResult',
  insertable: false,
  updatable: false,
  deletable: false,
  authorization: authorization('consultant', false),
  'custom:sortFields': [],
  columnNames: ['id', 'searchDate', 'startDate', 'endDate', 'destination', 'results'],
  fields: [{
    label: 'id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    render: false,
    id: true
  }, {
    name: 'searchDate',
    label: 'Data da Busca',
    type: 'date',
    updatable: false
  }, {
    name: 'startDate',
    label: 'Início',
    type: 'date'
  }, {
    name: 'endDate',
    label: 'Fim',
    type: 'date'
  }, {
    name: 'destination',
    label: 'Destino',
    type: 'textarray'
  }, {
    name: 'results',
    label: 'Resultados',
    type: 'object'
  }],
  filter: []
});