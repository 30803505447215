import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import person from '../person';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Pessoa logada',
  name: 'CurrentPerson',
  insertable: false,
  updatable: true,
  deletable: false,
  searchable: false,
  authorization: authorization('unauthenticated', 'unauthenticated'),
  dynamoDB: {
    tableName: 'SpearPerson'
  },
  fields: [...person.fields].map(f => ({
    ...f,
    updatable: f['custom:selfPersonUpdatable'] ?? false
  }))
});