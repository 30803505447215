import React from 'react';
import fillCrudMetadataGaps, { PartialCrudMetadata } from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import _pick from 'lodash/pick';
import { CONTENT } from '../credential';
export const partialMetadata: PartialCrudMetadata = {
  label: 'Regra de pesquisa',
  labelPlural: 'Regras de pesquisa',
  name: 'HotelSearchRule',
  deletable: false,
  updatable: true,
  insertable: true,
  searchable: true,
  custom_withAudit: true,
  authorization: authorization('clientConsultant', 'admin'),
  dynamoDB: {
    tableName: 'SpearHotelSearchRule'
  },
  columnNames: ['id', 'description', 'type', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    id: true,
    insertable: false,
    updatable: false,
    renderForm: false,
    renderColumn: false
  }, {
    custom_groupName: 'Informações básicas',
    label: 'Descrição',
    name: 'description',
    type: 'text',
    hint: 'Descriação da configuração para uso interno'
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: true,
    required: true,
    type: 'boolean'
  }, {
    custom_groupName: 'Informações básicas',
    label: 'Tipo de regra',
    name: 'type',
    type: 'text',
    default: 'PRE',
    updatable: false,
    options: [{
      value: 'PRE',
      label: 'Antes da busca'
    }, {
      value: 'POST',
      label: 'Após a busca'
    }],
    render: false
  }, {
    label: 'Filtros de credencial',
    custom_groupName: 'Credenciais permitidas',
    name: 'credentialRule',
    type: 'object',
    hint: 'Definição das credenciais que poderão ser utilizadas',
    graphQLType: 'HotelSearchCredentials',
    graphQLInputType: 'HotelSearchCredentialstInput',
    custom_renderFieldsRecursively: true,
    fields: [{
      name: 'rule',
      label: 'Regra',
      hint: 'Selecione a regra que será aplicada para definir quais credenciais poderão ser utilizadas na pesquisa',
      required: true,
      inputType: 'select',
      default: 'TENANT_AND_CLIENT',
      options: [{
        label: 'Credenciais do cliente e da instalação',
        value: 'TENANT_AND_CLIENT'
      }, {
        label: 'Somente credenciais do cliente',
        value: 'CLIENT'
      }, {
        label: 'Somente credenciais especificadas',
        value: 'CUSTOM'
      }]
    }, {
      name: 'credentials',
      label: 'Credenciais',
      required: true,
      hint: 'Selecione as credenciais que poderão ser utilizadas',
      type: 'textarray',
      credentialTypeList: CONTENT,
      inputType: 'InputMultiSelectCredential',
      withChips: true,
      renderForm: function (i) {
        return i.rule === 'CUSTOM';
      },
      custom_auditUrl: () => ({
        url: '/credentials'
        //label: 'Credencial',
      })
    }]
  }, {
    label: 'Nível de conteúdo permitido',
    custom_groupName: 'Conteúdo permitido',
    name: 'allowedHierarchy',
    type: 'textarray',
    inputType: 'niara-react-form/inputMultiSelect',
    withOptionSorter: true,
    default: ['city', 'hotel', 'point_of_interest', 'neighborhood', 'metro_station', 'train_station', 'multi_city_vicinity', 'high_level_region', 'airport'],
    options: [{
      label: 'Hotéis',
      value: 'hotel'
    }, {
      label: 'Cidades',
      value: 'city'
    }, {
      label: 'Regiões',
      value: 'high_level_region'
    }, {
      label: 'Arredores',
      value: 'multi_city_vicinity'
    }, {
      label: 'Países* (restrito a países com poucos hotéis)',
      value: 'country'
    }, {
      label: 'Pontos de interesse',
      value: 'point_of_interest'
    }, {
      label: 'Aeroportos',
      value: 'airport'
    }, {
      label: 'Bairros',
      value: 'neighborhood'
    }, {
      label: 'Estações de metrô',
      value: 'metro_station'
    }, {
      label: 'Estações de trem',
      value: 'train_station'
    }, {
      label: 'Vila',
      value: 'vila'
    }],
    onEntityChange: function (oldVersion, newVersion) {
      // Não será possível deselecionar hotéis
      if (newVersion?.allowedHierarchy?.indexOf('hotel') === -1) return {
        ...newVersion,
        allowedHierarchy: ['hotel', ...newVersion.allowedHierarchy]
      };else {
        return newVersion;
      }
    }
  }, {
    label: 'Filtros de conteúdo',
    custom_groupName: 'Conteúdo permitido',
    name: 'rules',
    type: 'objectarray',
    hint: 'Definição do conteúdo pesquisável',
    graphQLType: '[HotelSearchRuleList]',
    graphQLInputType: '[HotelSearchRuleListInput]',
    custom_renderFieldsRecursively: true,
    fields: [{
      name: 'rule',
      label: 'Regra',
      required: false,
      type: 'object',
      graphQLType: 'HotelSearchRuleListItem',
      graphQLInputType: 'HotelSearchRuleListItemInput',
      custom_renderFieldsRecursively: true,
      fields: [{
        name: 'credentials',
        label: 'Credenciais',
        required: false,
        hint: 'Aplicar regras às credenciais. Se nenhuma credencial for selecionada a regra será aplicada a todas as credenciais.',
        type: 'textarray',
        inputType: 'InputMultiSelectCredential',
        withChips: true,
        credendialTipeList: CONTENT,
        custom_auditUrl: () => ({
          url: '/credentials'
          //label: 'Credencial',
        })
      }, {
        label: 'Regras de conteúdo',
        name: 'content',
        type: 'objectarray',
        hint: 'Definição do conteúdo pesquisável',
        graphQLType: '[HotelSearchRuleContentList]',
        graphQLInputType: '[HotelSearchRuleContentListInput]',
        custom_renderFieldsRecursively: true,
        fields: [{
          name: 'type',
          label: 'Tipo da Regra',
          inputType: 'select',
          options: [{
            label: 'Permitir',
            value: 'ALLOW'
          }, {
            label: 'Negar',
            value: 'DENY'
          }]
        }, {
          name: 'value',
          label: 'Conteúdo',
          type: 'object',
          fields: [{
            name: 'id',
            label: 'Id'
          }, {
            name: 'contentType',
            label: 'Tipo do conteúdo'
          }, {
            name: 'name',
            label: 'Nome'
          }, {
            name: 'regionIds',
            label: 'Regiões a serem incluídas',
            required: false,
            type: 'textarray'
          }],
          custom_newSearch: true,
          inputType: 'InputHotelDestination',
          contentSearchSize: 30,
          listSize: 300,
          graphQLType: 'HotelSearchRuleContentItemValue',
          graphQLInputType: 'HotelSearchRuleContentItemValueInput',
          allowRules: false,
          // bug #181643
          notGroup: true,
          render: e => {
            return <div style={{
              marginBottom: '8px'
            }}>{e?.value?.name ?? '-'}</div>;
          }
        }]
      }]
    }]
  }, {
    label: 'Permitir busca de todos os itens acima',
    custom_groupName: 'Conteúdo permitido',
    name: 'showAllOption',
    type: 'boolean',
    hint: 'Permite a apresentação da opção "Todos" no resultado do autocomplete, a qual permite pesquisar por todos os hotéis habilitados nas regras acima',
    default: true
  }, {
    label: 'Não exibir informação da localidade no critério da busca',
    custom_groupName: 'Conteúdo permitido',
    name: 'notDisplayLocationInSearch',
    type: 'boolean',
    hint: 'Opção só deve afetar quando é selecionado uma lista fixa de hotéis',
    default: false
  }, {
    custom_groupName: 'Critérios de busca pré-preenchidos',
    label: 'Destino',
    name: 'defaultCriteria_destination',
    required: false,
    type: 'object',
    fields: [{
      name: 'id',
      label: 'Id'
    }, {
      name: 'contentType',
      label: 'Tipo do conteúdo'
    }, {
      name: 'name',
      label: 'Nome'
    }, {
      name: 'regionIds',
      label: 'Regiões a serem incluídas',
      required: false,
      type: 'textarray'
    }],
    custom_newSearch: true,
    inputType: 'InputHotelDestinationForm',
    listSize: 30,
    graphQLType: 'HotelSearchRuleContentItemValue',
    graphQLInputType: 'HotelSearchRuleContentItemValueInput',
    allowRules: false,
    optionFilter: (entity, options) => {
      // Filtrar para só os permitir
      const rulesContentIds = entity?.rules?.map(r => r.rule?.content?.filter(c => c.type === 'ALLOW')?.map(c => c?.value?.id))?.flat() || [];
      if (rulesContentIds?.length > 0) {
        return options?.filter(o => rulesContentIds.includes(o.id));
      } else {
        return options;
      }
    },
    render: e => {
      return <div style={{
        marginBottom: '8px'
      }}>{e?.value?.name ?? '-'}</div>;
    },
    renderForm: entity => {
      return !(entity?.defaultCriteria_all_destinations && entity?.showAllOption);
    },
    convertDestination: d => {
      if (d) {
        return _pick(d, 'id', 'contentType', 'name', 'regionIds');
      } else {
        return d;
      }
    }
  }, {
    custom_groupName: 'Critérios de busca pré-preenchidos',
    label: 'Habilitar "Todos" como destino',
    hint: 'Desabilita a opção de "Destino"',
    name: 'defaultCriteria_all_destinations',
    required: false,
    type: 'boolean',
    renderForm: entity => {
      return entity?.showAllOption;
    }
  }, {
    custom_groupName: 'Critérios de busca pré-preenchidos',
    label: 'Check-in',
    hint: 'Data de check-in pré-preenchida',
    name: 'defaultCriteria_startDate',
    required: false,
    type: 'date'
  }, {
    custom_groupName: 'Critérios de busca pré-preenchidos',
    label: 'Check-out',
    name: 'defaultCriteria_endDate',
    hint: 'Data de check-out pré-preenchida',
    type: 'date',
    required: false
  }, {
    custom_groupName: 'Critérios de busca pré-preenchidos',
    label: 'Quantidade de Adultos',
    hint: 'Por padrão será apenas 1 quarto',
    name: 'defaultCriteria_adults',
    required: false,
    type: 'int',
    min: 1
  }, {
    custom_groupName: 'Limites do critérios de busca',
    name: 'minNightCount',
    label: 'Número mínimo de noites',
    required: true,
    default: 1,
    min: 1,
    type: 'int'
  }, {
    custom_groupName: 'Limites do critérios de busca',
    name: 'maxNightCount',
    label: 'Número máximo de noites',
    required: false,
    min: 1,
    type: 'int'
  }, {
    custom_groupName: 'Limites do critérios de busca',
    name: 'minRoomCount',
    label: 'Número mínimo de quartos',
    required: true,
    default: 1,
    min: 1,
    type: 'int'
  }, {
    custom_groupName: 'Limites do critérios de busca',
    name: 'maxRoomCount',
    label: 'Número máximo de quartos',
    required: false,
    min: 1,
    type: 'int'
  }, {
    custom_groupName: 'Limites do critérios de busca',
    name: 'minStartDate',
    label: 'Data mínima de busca',
    required: false,
    type: 'date'
  }, {
    custom_groupName: 'Limites do critérios de busca',
    name: 'maxEndDate',
    label: 'Data máxima de busca',
    required: false,
    type: 'date'
  }, {
    name: 'minAdultCount',
    custom_groupName: 'Limites do critérios de busca',
    label: 'Número mínimo de adultos pemitidos',
    required: true,
    default: 1,
    min: 1,
    type: 'int'
  }, {
    name: 'maxAdultCount',
    custom_groupName: 'Limites do critérios de busca',
    label: 'Número máximo de adultos permitidos',
    required: false,
    min: 1,
    type: 'int'
  }, {
    name: 'childrenAllowed',
    custom_groupName: 'Limites do critérios de busca',
    label: 'Permitir incluir crianças',
    type: 'boolean',
    default: true
  }, {
    name: 'minChildCount',
    custom_groupName: 'Limites do critérios de busca',
    label: 'Número mínimo de crianças pemitidas',
    required: true,
    default: 0,
    min: 0,
    type: 'int',
    renderForm: function (i) {
      return !i.childrenAllowed ? false : this.render && this.render(i);
    }
  }, {
    name: 'maxChildCount',
    custom_groupName: 'Limites do critérios de busca',
    label: 'Número máximo de crianças permitidas',
    required: false,
    type: 'int',
    min: 0,
    renderForm: function (i) {
      return !i.childrenAllowed ? false : this.render && this.render(i);
    }
  }, {
    name: 'minChildAge',
    custom_groupName: 'Limites do critérios de busca',
    label: 'Idade mínima permitida da criança',
    required: true,
    default: 0,
    min: 0,
    type: 'int',
    renderForm: function (i) {
      return !i.childrenAllowed ? false : this.render && this.render(i);
    }
  }, {
    name: 'maxChildAge',
    custom_groupName: 'Limites do critérios de busca',
    label: 'Idade máxima permitida da criança',
    required: false,
    type: 'int',
    min: 0,
    renderForm: function (i) {
      return !i.childrenAllowed ? false : this.render && this.render(i);
    }
  }, {
    custom_groupName: 'Limites do critérios de busca',
    label: 'Antecedência mínima',
    name: 'minTimeBetweenBookingAndCheckin',
    hint: 'Quantidade de dias mínimo entre a data da reserva e a data do check-in',
    type: 'int',
    required: true,
    default: 0,
    min: 0
  }, {
    custom_groupName: 'Limites do critérios de busca',
    label: 'Antecedência máxima',
    name: 'maxTimeBetweenBookingAndCheckin',
    hint: 'Quantidade de dias máxima entre a data da reserva e a data do check-in',
    type: 'int',
    required: false,
    default: null,
    min: 0
  }, {
    custom_groupName: 'Motivos de não retorno',
    label: 'Apresentar motivos de não retorno de disponibilidade',
    hint: 'Na tela de detalhes de hotel, mostrar tarifas sem disponibilidade. Apenas OTA Builder e front unificado.',
    name: 'showUnavailableRates',
    type: 'boolean',
    required: false,
    default: true
  }, {
    custom_groupName: 'Calendário de disponibilidade',
    label: 'Habilitar a consulta de preço e disponibilidade no calendário',
    hint: 'Apenas OTA Builder e front unificado.',
    name: 'showCalendarAvailability',
    type: 'boolean',
    required: false,
    default: true
  }].map(field => ({
    ...field,
    renderForm: field.name == 'rules' ? field.renderForm : field.renderForm == false || field.render == false ? false : function (i) {
      if (i.id == 'NIARA_PRE') return false;else if (field.renderForm && typeof field.renderForm == 'function') return field.renderForm(i);else return this.render(i);
    }
  } as PartialCrudMetadata['fields'][0])),
  filter: [{
    name: 'type_eq',
    field: 'type',
    op: 'eq'
  }, {
    name: 'type_in',
    field: 'type',
    op: 'in'
  }]
};
export default fillCrudMetadataGaps({
  ...partialMetadata
});