import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Transação',
  labelPlural: 'Histórico de transações',
  name: 'LedgerTransaction',
  namePlural: 'LedgerTransactions',
  deletable: false,
  updatable: false,
  insertable: false,
  authorization: authorization('consultant', 'admin'),
  dynamoDB: {
    tableName: 'SpearLedgerTransaction'
  },
  columns: ['id', 'orderId', 'personId', 'value', 'tierValue', 'balance', 'operation', 'date', 'description', 'unitText', 'decimalPlaces', 'rollbackTransactionId', 'ledgerConfigurationId'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true
  }, {
    name: 'tenantId',
    label: 'Id do tenant',
    render: false,
    required: true,
    updatable: false
  }, {
    label: 'Id da viagem',
    name: 'orderId',
    type: 'text',
    insertable: true,
    updatable: false,
    required: true
  }, {
    name: 'personId',
    label: 'Id da pessoa',
    required: false,
    updatable: false
  }, {
    name: 'clientId',
    label: 'Id do cliente',
    required: false,
    updatable: false,
    deprecated: true
  }, {
    name: 'ownerId',
    label: 'Id do dono',
    required: true,
    updatable: false
  }, {
    label: 'Valor',
    name: 'value',
    type: 'float',
    updatable: false,
    required: false
  }, {
    label: 'Saldo',
    name: 'balance',
    type: 'float',
    updatable: false,
    required: false
  }, {
    label: 'Valor Inteiro',
    name: 'valueInt',
    type: 'int',
    updatable: false,
    required: false
  }, {
    label: 'Nível',
    name: 'tierValue',
    type: 'text',
    updatable: false,
    required: false
  }, {
    label: 'Saldo Inteiro',
    name: 'balanceInt',
    type: 'int',
    updatable: false,
    required: false
  }, {
    label: 'Operação',
    name: 'operation',
    type: 'text',
    updatable: false,
    required: true,
    options: [{
      label: 'Adição',
      value: 'ADD'
    }, {
      label: 'Subtração',
      value: 'SUB'
    }, {
      label: 'Alteração',
      value: 'RESET'
    }, {
      label: 'Estorno',
      value: 'ROLLBACK_ADD'
    }, {
      label: 'Estorno',
      value: 'ROLLBACK_SUB'
    }, {
      label: 'Alteração de nível',
      value: 'UPDATE_TIER'
    }]
  }, {
    label: 'Descrição',
    name: 'description',
    type: 'text',
    updatable: false,
    required: true
  }, {
    label: 'Data',
    name: 'date',
    type: 'datetime',
    updatable: false,
    required: true
  }, {
    label: 'Id da transação original',
    name: 'rollbackTransactionId',
    type: 'text',
    updatable: false,
    required: false
  }, {
    label: 'Id da configuração',
    name: 'ledgerConfigurationId',
    type: 'text',
    updatable: false,
    required: false
  }, {
    name: 'unitText',
    label: 'Unidade',
    required: true
  }, {
    name: 'decimalPlaces',
    label: 'Quantidade de casas decimais',
    required: true,
    type: 'int',
    min: 0,
    step: 1
  }],
  filter: [{
    field: 'personId',
    op: 'eq',
    label: 'Person Id'
  }, {
    field: 'ledgerConfigurationId',
    op: 'eq',
    label: 'Id configuração'
  }, {
    field: 'clientId',
    op: 'eq',
    label: 'Client Id'
  }, {
    field: 'date',
    op: 'le',
    label: 'Data do fim do extrato'
  }, {
    field: 'date',
    op: 'ge',
    label: 'Data do início do extrato'
  }]
});