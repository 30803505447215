import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Conta de Serviço',
  labelPlural: 'Contas de Serviço',
  name: 'ServiceAccount',
  namePlural: 'ServiceAccounts',
  authorization: authorization('clientConsultant'),
  deletable: false,
  dynamoDB: {
    tableName: 'SpearServiceAccount'
  },
  columns: ['id', 'name'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Nome',
    type: 'text',
    required: true
  }, {
    name: 'apiKey',
    label: 'ID da chave de API',
    type: 'text',
    required: true,
    readOnly: true
  }, {
    name: 'secretKey',
    label: 'Senha da chave de API',
    type: 'text',
    required: true,
    render: false
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: false,
    required: true,
    type: 'boolean'
  }, {
    name: 'clientId',
    label: 'Cliente',
    updatable: false
  }, {
    name: 'clientName',
    label: 'Cliente',
    render: false,
    updatable: false
  }, {
    name: 'permissions',
    label: 'Permissões',
    type: 'textarray',
    options: [{
      label: 'Ping',
      value: 'ping:read'
    }, {
      label: 'Pesquisa de disponibilidade',
      value: 'hotel:search'
    }],
    inputType: 'chips'
  }, {
    name: 'usagePlan',
    label: 'Plano de uso',
    type: 'text',
    options: [{
      label: 'Light',
      value: 'LIGHT'
    }, {
      label: 'Premium',
      value: 'PREMIUM'
    }],
    inputType: 'select'
  }, {
    name: 'authorizationMethod',
    label: 'Método de autorização',
    type: 'text',
    default: 'API_CREDENTIALS',
    options: [{
      label: 'Credenciais de API',
      value: 'API_CREDENTIALS'
    }],
    inputType: 'select'
  }, {
    name: 'lastUseDate',
    label: 'Última utilização',
    type: 'datetime',
    updatable: false
  }, {
    name: 'createDate',
    label: 'Data de criação',
    type: 'datetime',
    updatable: false
  }],
  filter: [{
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq'
  }]
});