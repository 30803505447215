import styled, { css } from 'styled-components'
import { FaHotel } from 'react-icons/fa'

export const Container = styled.div`
  &&& {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    width: 100%;
  }
`
export const Info = styled.div`
  &&& {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    width: 100%;
  }
`

export const Name = styled.span`
  &&& {
    margin: 0;
    padding: 0;
  }
`

export const LabelBold = styled.small`
  &&& {
    margin: 5px 0 0;
    font-weight: bold;
  }
`

export const Address = styled.small`
  &&& {
    margin: 5px 0 0;
  }
`

const Icon = css`
  margin: 0 10px 0 0;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
  padding: 0;
  width: 24px;
`

export const ContentIcon = styled.img`
  &&& {
    ${Icon}
  }
`

export const HotelIcon = styled(FaHotel)`
  &&& {
    ${Icon}
    font-size: 15px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    width: 18px;
  }
`
