import styled, { css } from 'styled-components'

export type Props = {
  textColor?: string
  textSize?: number
  fontWeight?: string
}

export const PolStyle = styled.div`
  display: inline-block;
  align-items: baseline;
`

export const PolItem = styled.div<Props>`
  color: ${({ textColor, theme }) => theme.colors?.[textColor || 'priceColor']} !important;
  font-size: ${({ textSize }: Props) => (textSize ? `${textSize}px` : '12px')};
  font-weight: ${({ fontWeight }: Props) => fontWeight ?? '600'};
  font-family: 'Manrope';
  display: flex;
  align-items: center;
`

export const InfoGray = styled.img`
  cursor: pointer;
`

export const ContainerTooltip = styled.div`
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme?.colors?.gray6};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme?.colors?.gray5};
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme?.colors?.gray1};
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
`
