import React from 'react';
export type Props = {
  color?: string;
  size?: number;
};
const Info: React.FC<Props> = ({
  color = '#008009',
  size = 16
}) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
      <g transform="translate(-470 -642)">
        <rect fill="none" width="16" height="16" transform="translate(470 642)" />
        <g transform="translate(-772 254)">
          <g fill="none" stroke={color} transform="translate(1243 389)">
            <circle stroke="none" cx="7" cy="7" r="7" />
            <circle fill="none" cx="7" cy="7" r="6.5" />
          </g>
          <g transform="translate(1250 392.5)">
            <line fill="none" stroke={color} strokeLinecap="round" y2="3.5" transform="translate(0 3.5)" />
            <line fill="none" stroke={color} strokeLinecap="round" y2="0.875" />
          </g>
        </g>
      </g>
    </svg>;
};
export default Info;