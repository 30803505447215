import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Acordo',
  labelPlural: 'Acordos',
  name: 'NegotiatedRate',
  insertable: true,
  updatable: true,
  deletable: false,
  authorization: authorization('clientConsultant', 'admin'),
  columnNames: ['id', 'productName', 'productCity', 'roomCategory', 'validityDate', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    render: false,
    id: true
  }, {
    name: 'productId',
    label: 'Produto',
    required: true,
    updatable: false,
    renderForm: false
  }, {
    name: 'productName',
    label: 'Produto (nome do hotel)',
    required: true,
    updatable: false
  }, {
    name: 'productType',
    label: 'Tipo de Produto',
    default: 'Hotel',
    required: true,
    updatable: false,
    renderForm: false
  }, {
    name: 'productCity',
    label: 'Nome da cidade'
  }, {
    name: 'clientId',
    label: 'Id do Cliente',
    default: 0,
    required: true,
    updatable: false,
    render: false
  }, {
    name: 'validityDate',
    label: 'Data de validade',
    type: 'date',
    required: true,
    updatable: true
  }, {
    name: 'issTax',
    label: 'ISS',
    type: 'float',
    render: false
  }, {
    name: 'serviceTax',
    label: 'Taxa de serviço',
    type: 'float',
    render: false
  }, {
    name: 'channel',
    label: 'Canal de reserva',
    type: 'text',
    render: false
  }, {
    name: 'rateType',
    label: 'Tipo de tarifa',
    type: 'text',
    render: false
  }, {
    name: 'roomCategory',
    label: 'Categoria de quarto',
    type: 'text'
  }, {
    name: 'observations',
    label: 'Observações',
    type: 'text'
  }, {
    name: 'rates',
    label: 'Tarifas',
    type: 'objectarray',
    default: [{
      adultCount: 1,
      breakfastIncluded: false,
      currencyCode: 'BRL'
    }],
    graphQLType: '[NegotiatedRateRate]',
    graphQLInputType: '[NegotiatedRateRateInput]',
    fields: [{
      name: 'adultCount',
      label: 'Hóspedes (adultos)',
      type: 'int',
      min: 1,
      default: 1,
      required: true
    }, {
      name: 'breakfastIncluded',
      label: 'Inclui café da manhã',
      default: false,
      type: 'boolean'
    }, {
      name: 'currencyCode',
      label: 'Moeda',
      type: 'text',
      required: true,
      options: [{
        value: 'BRL',
        label: 'Reais'
      }, {
        value: 'USD',
        label: 'Dólares'
      }, {
        value: 'EUR',
        label: 'Euros'
      }],
      default: 'BRL',
      inputType: 'select'
    }, {
      name: 'price',
      label: 'Preço (inclui ISS)',
      type: 'float',
      required: true
    }]
  }, {
    name: 'enabled',
    label: 'Ativo',
    required: true,
    default: true,
    type: 'boolean'
  }, {
    custom_groupName: "Termos e condições",
    name: 'termsMeal',
    label: 'Refeição',
    inputType: 'textarea'
  }, {
    custom_groupName: "Termos e condições",
    name: 'termsPets',
    label: 'Pets',
    inputType: 'textarea'
  }, {
    custom_groupName: "Termos e condições",
    name: 'acceptsPets',
    label: 'Aceita Pets',
    type: 'boolean'
  }, {
    custom_groupName: "Termos e condições",
    name: 'termsParking',
    label: 'Estacionamento',
    inputType: 'textarea'
  }, {
    custom_groupName: "Termos e condições",
    name: 'termsPayment',
    label: 'Pagamento',
    inputType: 'textarea'
  }, {
    custom_groupName: "Termos e condições",
    name: 'termsTransfer',
    label: 'Translado',
    inputType: 'textarea'
  }, {
    custom_groupName: "Termos e condições",
    name: 'offersTransfer',
    label: 'Oferece translado',
    type: 'boolean'
  }, {
    custom_groupName: "Termos e condições",
    name: 'termsOther',
    label: 'Outros',
    inputType: 'textarea'
  }],
  filter: [{
    name: 'clientId_eq',
    field: 'clientId',
    op: 'eq'
  }, {
    name: 'productId_eq',
    field: 'productId',
    op: 'eq'
  }, {
    name: 'roomCategory_eq',
    field: 'roomCategory',
    op: 'eq'
  }, {
    name: 'validityDate_ge',
    field: 'validityDate',
    op: 'ge'
  }, {
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq'
  }]
});