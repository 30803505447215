import { createContext } from 'react';
export type PortalContext = {
  container?: HTMLElement | {
    (): HTMLElement;
  };
};

/**
 * Usado por ../components/Modal para definir o portal padrão. TODO: usar
 * em outros componentes.
 */
const DefaultPortalContext = createContext<PortalContext>({});
export default DefaultPortalContext;