import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Registro',
  labelPlural: 'Registros',
  name: 'Record',
  deletable: false,
  updatable: true,
  insertable: false,
  authorization: authorization('clientConsultant', 'admin'),
  dynamoDB: {
    tableName: 'SpearRecord',
    partitionKey: 'tenantId',
    sortKey: 'datetime_referenceId',
    scanIndexBackwards: true
  },
  columnNames: ['datetime_referenceId', 'datetime', 'referenceId', 'action', 'actionDetails'],
  fields: [{
    label: 'xxxxxxx',
    name: 'datetime_referenceId',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true
  }, {
    label: 'xxxxxxx',
    name: 'datetime',
    type: 'datetime'
  }, {
    label: 'xxxxxxx',
    name: 'referenceId',
    type: 'text'
  }, {
    label: 'xxxxxx',
    name: 'action',
    type: 'text'
  }, {
    label: 'xxxxxx',
    name: 'actionDetails',
    type: 'object'
  }],
  filter: [{
    field: 'referenceId',
    op: 'eq'
  }]
});