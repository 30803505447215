import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: "Template de Cotação",
  labelPlural: "Template de Cotações",
  name: 'QuotationTemplate',
  updatable: true,
  insertable: true,
  insertButtonText: "Novo Template",
  faIcon: 'search-dollar',
  authorization: authorization('clientConsultant', 'clientConsultant'),
  'custom:sortFields': ['date desc'],
  dynamoDB: {
    tableName: 'SpearQuotationTemplate'
  },
  columns: ['id', 'name', 'additionalInformations', 'clientId', 'quotationDescription'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    insertable: false,
    updatable: false,
    id: true
  }, {
    name: 'name',
    label: 'Nome do Template'
  }, {
    name: 'clientId',
    label: 'Cliente'
  }, {
    name: 'quotationDescription',
    label: 'Descrição',
    renderColumn: false,
    required: false,
    updatable: true,
    type: 'text',
    inputType: 'textarea'
  }, {
    name: 'additionalInformations',
    label: 'Informações adicionais',
    renderColumn: false,
    required: false,
    updatable: true,
    type: 'objectarray',
    graphQLType: '[NestedQuotationTemplateAdditionalInformations]',
    graphQLInputType: '[NestedQuotationTemplateAdditionalInformationsInput]',
    fields: [{
      name: 'title',
      label: 'Título',
      type: 'text',
      required: true
    }, {
      name: 'description',
      label: 'Descrição',
      type: 'text',
      required: true,
      inputType: 'wysiwyg'
    }]
  }],
  filter: [{
    field: 'id',
    op: 'eq'
  }, {
    field: 'clientId',
    op: 'eq'
  }]
});