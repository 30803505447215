import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import credential from '../credential';
export default fillCrudMetadataGaps({
  label: 'Credencial',
  labelPlural: 'Credenciais',
  name: 'ShallowCredential',
  deletable: false,
  updatable: false,
  insertable: false,
  searchable: true,
  authorization: authorization('consultant', false),
  dynamoDB: {
    tableName: 'SpearConfiguration'
  },
  'custom:sortFields': ['type', 'alias'],
  columnNames: ['id', 'type', 'client_id', 'alias', 'description', 'usage', 'enabled'],
  fields: [...credential.fields.filter(f => f['custom:shallowCredential'])],
  filter: [{
    name: 'client_id_eq',
    field: 'client_id',
    op: 'eq',
    label: 'Client Id'
  }, {
    name: 'client_id_isNull',
    field: 'client_id',
    op: 'isNull',
    label: 'Client Id Nulo'
  }, {
    name: 'type_eq',
    field: 'type',
    op: 'eq',
    label: 'Conteúdo'
  }, {
    name: 'type_in',
    field: 'type',
    op: 'in',
    label: 'Conteúdos'
  }, {
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq',
    label: 'Ativo'
  }]
});