import styled from 'styled-components'
// import { BREAKPOINT_SM } from '../../theme/ota'

type Active = {
  active: boolean
}

export const Bar = styled.div`
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 20px;
`

export const BarItem = styled.div<Active>`
  align-items: center;
  border-bottom: ${({ active, theme }) => (active ? `2px solid ${theme.colors.primary}` : 'none')};
  cursor: pointer;
  display: flex;
  gap: 6px;
  justify-content: space-between;
  padding: 0 23.5px 12.5px 23.5px;
`

export const BarText = styled.p<Active>`
  color: ${({ active, theme }) => (active ? theme?.colors?.primary : theme?.colors?.text)};
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  margin: 0;
`
