import type { BaseStore, UseSelectorFunction } from './base';
import { useSelector as _useSelector, baseCreateStore, useDispatch } from './base';
import authentication from './reducers/authentication';
import core from './reducers/core';
import hotel from './reducers/hotel';
import storefrontConfig from './reducers/storefrontConfig';
import theme from './reducers/theme';
export type { Dispatch } from './base';
export * as Authentication from './reducers/authentication';
export * as StorefrontConfig from './reducers/storefrontConfig';
export type { StorefrontWithTenantId } from './reducers/storefrontConfig';
export * as Theme from './reducers/theme';
export { useDispatch };
const reducer = {
  storefrontConfig,
  authentication,
  hotel,
  theme,
  core
};
export type RootState = { [K in keyof typeof reducer]: ReturnType<(typeof reducer)[K]> };
export type Store = BaseStore<RootState>;
export const createStore = (): Store => baseCreateStore<RootState>({
  reducer,
  preloadedState: {
    authentication: {
      b2c: true
    }
  }
});
export const useSelector = (_useSelector as UseSelectorFunction<RootState>);