import * as Sentry from '@sentry/react';
import _uniq from 'lodash/uniq';
import type { CompleteRootState } from '../app/base';
type FieldsToRemove<T> = Partial<Record<keyof T, string | any>> | Partial<T>;
const cleanItem = function <T>(obj: T, fieldsToRemove: FieldsToRemove<T>, fieldsToInclude?: FieldsToRemove<T> | '#INCLUDE') {
  if (Array.isArray(obj)) {
    return obj.map(item => cleanItem(item, fieldsToRemove?.[0], fieldsToInclude?.[0]));
  } else if (obj) {
    const clone = {
      ...obj
    };
    for (const k of _uniq(Object.keys(obj))) {
      const isInclude = fieldsToInclude == '#INCLUDE' || fieldsToInclude?.[k] != null;
      const isRemove = !isInclude || fieldsToRemove?.[k] != null && typeof fieldsToRemove?.[k] != 'object';
      if (isRemove) {
        if (clone[k] != null) {
          clone[k] = '*';
        } else {
          delete clone[k];
        }
      } else if (typeof clone[k] === 'object') {
        clone[k] = cleanItem(clone[k], fieldsToRemove?.[k], fieldsToInclude == '#INCLUDE' ? '#INCLUDE' : fieldsToInclude?.[k]);
      }
    }
    return clone;
  }
};

// Transform the state to remove sensitive information
const FIELDS_TO_REMOVE: FieldsToRemove<CompleteRootState> = {};
const FIELDS_TO_INCLUDE: FieldsToRemove<CompleteRootState> = {
  hotel: {
    hotelDetails: {
      hotelId: '#INCLUDE',
      hotelSearch: {
        criteria: '#INCLUDE',
        start: '#INCLUDE',
        searchId: '#INCLUDE'
      }
    },
    hotelSearch: {
      criteria: '#INCLUDE',
      start: '#INCLUDE',
      searchId: '#INCLUDE'
    }
  },
  storefrontConfig: {
    storefront: {
      tenantId: '#INCLUDE',
      locator: '#INCLUDE',
      clientId: '#INCLUDE',
      name: '#INCLUDE',
      id: '#INCLUDE'
    }
  },
  shoppingCart: {
    items: [{
      roomRateIds: '#INCLUDE',
      hotelId: '#INCLUDE',
      startDate: '#INCLUDE',
      endDate: '#INCLUDE',
      currency: '#INCLUDE',
      quantity: '#INCLUDE'
    }],
    clientId: '#INCLUDE',
    total: '#INCLUDE',
    pendingTotal: '#INCLUDE',
    paymentOptions: [{
      id: '#INCLUDE',
      type: '#INCLUDE',
      alias: '#INCLUDE',
      total: '#INCLUDE',
      currency: '#INCLUDE'
    }]
  }
};
export const clean = (state: CompleteRootState) => cleanItem(state, FIELDS_TO_REMOVE, FIELDS_TO_INCLUDE);
export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state: CompleteRootState) => {
    return clean(state);
  }
});