import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import { niaraPaymentOptions } from '../supplierPaymentRule';
export default fillCrudMetadataGaps({
  label: 'Automação',
  labelPlural: 'Automação',
  name: 'AutomaticCancellationRule',
  namePlural: 'AutomaticCancellationRules',
  authorization: authorization('consultant', 'admin'),
  custom_withAudit: true,
  deletable: false,
  dynamoDB: {
    tableName: 'SpearAutomaticCancellationRule'
  },
  columns: ['id', 'name', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Nome',
    type: 'text',
    required: true
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: false,
    required: true,
    type: 'boolean'
  }, {
    name: 'instructions',
    label: 'Instruções',
    required: true,
    hint: 'Esse texto irá aparecer no modal do checkout, abaixo do prazo de pagamento.',
    custom_groupName: 'Prazo para Pagamento'
  }, {
    name: 'daysAfterReservationEnabled',
    label: 'Cancelar depois da Reserva',
    type: 'boolean',
    hint: 'Cancelar depois da data de reserva',
    custom_groupName: 'Prazo para Pagamento',
    required: true
  }, {
    name: 'daysAfterReservation',
    label: 'Dias depois da Reserva',
    type: 'int',
    min: 1,
    max: 30,
    hint: 'Até quantos dias depois da data de reserva pode segurar a reserva',
    custom_groupName: 'Prazo para Pagamento',
    required: true,
    default: 2,
    renderForm: i => i.daysAfterReservationEnabled == true
  }, {
    name: 'daysBeforePenaltyEnabled',
    label: 'Cancelar antes da penalidade',
    type: 'boolean',
    hint: 'Cancelar antes da penalidade',
    custom_groupName: 'Prazo para Pagamento',
    required: true
  }, {
    name: 'daysBeforePenalty',
    label: 'Dias antes da Penalidade',
    type: 'int',
    min: 1,
    max: 30,
    hint: 'Até quantos dias antes da penalidade pode segurar a reserva',
    custom_groupName: 'Prazo para Pagamento',
    required: true,
    default: 2,
    renderForm: i => i.daysBeforePenaltyEnabled == true
  }, {
    custom_groupName: 'Prazo para Pagamento',
    name: 'cancellationTime',
    label: 'Horário para executar cancelamento',
    hint: 'Horário que vai ser feito o cancelamento. Ex formato: 04:34:56-08:00'
  }, {
    custom_groupName: 'Prazo Limite do cancelamento automático',
    name: 'limitReference',
    label: 'Referencia do Prazo limite máximo',
    hint: 'Até que prazo o cancelamento automático é válido',
    options: [{
      value: 'PENALTY',
      label: 'Penalidade'
    }, {
      value: 'CHECKIN',
      label: 'Check-in'
    }]
  }, {
    name: 'limitTime',
    label: 'Horário limite máximo',
    hint: 'Horário limite do dia anterior a referencia. Ex formato: 04:34:56-08:00'
  }, {
    name: 'dropRules',
    label: 'Pagamento da Reserva',
    type: 'textarray',
    options: [{
      value: 'TOTAL_VALUE',
      label: 'Pagamento total (100% da reserva online e/ou offline)'
    }, {
      value: 'PENALTY_VALUE',
      label: 'Pagamento da multa (online ou offline)',
      enabled: false
    }, {
      value: 'THRESHOLD_VALUE',
      label: 'Pagamento parcial % (online ou offline)'
    }],
    hint: 'Quais condições removem automaticamente o cancelamento automático',
    custom_groupName: 'Remoção automática de Prazo - Recebimento',
    required: true
  }, {
    name: 'thresholdValuePercentage',
    label: 'Valor % do total da reserva a ser pago.',
    type: 'float',
    inputType: 'percentage',
    custom_groupName: 'Remoção automática de Prazo - Recebimento',
    renderForm: i => i?.dropRules?.includes('THRESHOLD_VALUE')
  }, {
    name: 'preConfirmedValueEnabled',
    label: 'Considerar valores pré confirmados.',
    default: false,
    required: true,
    type: 'boolean'
  }, {
    name: 'dropRules2',
    label: 'Escolha de forma de pagamento',
    type: 'textarray',
    options: [...niaraPaymentOptions.filter(option => ['EXT_OFF', 'EXT_CC', 'EXT_DIR_IATA', 'EXT_DIR', 'EXT_FAT']?.includes(option.value))],
    hint: 'Quais condições removem automaticamente o cancelamento automático',
    custom_groupName: 'Remoção automática de Prazo'
  }, {
    custom_groupName: 'Pagar Depois',
    name: 'payLaterAlias',
    label: 'Nome',
    hint: 'Esse texto vai aparecer no momento da reserva como opção de pagamento'
  }, {
    custom_groupName: 'Pagar Depois',
    name: 'payLaterDescription',
    label: 'Descrição',
    hint: 'Esse texto vai aparecer no momento da reserva se essa forma de pagamento for selecionada'
  }],
  filter: [{
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq'
  }]
});