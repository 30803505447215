import React from 'react';
import * as S from './styles';
import type { Props as PropsInputAutoComplete } from './InputAutoComplete';
type ListProps<V> = {
  renderSugestions?: any[];
  debouncedQuery?: string;
  loadingSuggestions?: boolean;
  noResultsMessage?: PropsInputAutoComplete<V>['noResultsMessage'];
  selectedIndex?: number;
  renderOption?: {
    (v: V, q?: string): string;
  };
  listFooter?: PropsInputAutoComplete<V>['listFooter'];
  suggestions?: any[];
  onSuggestionClick: (e: any, suggestion: any) => void;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  setIsOpenInput: React.Dispatch<React.SetStateAction<boolean>>;
};
function ListDataItems<P>(props: ListProps<P>): JSX.Element {
  const {
    debouncedQuery,
    listFooter,
    loadingSuggestions,
    noResultsMessage,
    renderOption,
    renderSugestions,
    selectedIndex,
    suggestions,
    onSuggestionClick,
    setSelectedIndex,
    setIsOpenInput
  } = props;
  return <S.ListData key="suggestions" id="list">
      {renderSugestions.length < 1 && debouncedQuery && loadingSuggestions === false ? <>
          {!noResultsMessage || typeof noResultsMessage == 'string' ? <S.NotFound>
              <p>{noResultsMessage || '(' + "Nenhum resultado encontrado - por favor, tente novamente" + ')'}</p>
            </S.NotFound> : noResultsMessage}
        </> : renderSugestions?.map((suggestion, index) => {
      const {
        isGroup
      } = suggestion;
      return <a href="#" key={index} tabIndex={-1} onClick={e => {
        if (isGroup) return;
        onSuggestionClick(e, suggestion);
        // setShowSuggestions(false)
        setSelectedIndex(index);
        setIsOpenInput(false);
      }} onMouseDown={e => {
        if (isGroup) return;
        onSuggestionClick(e, suggestion);
        // setShowSuggestions(false)
        setSelectedIndex(index);
        setIsOpenInput(false);
      }} className={index == selectedIndex ? 'selected' : isGroup ? 'is-group-option' : undefined}>
              {isGroup ? <S.GroupOptionDivider>
                  {suggestion?.icon}
                  <span>{suggestion?.label}</span>
                </S.GroupOptionDivider> : renderOption(suggestion, debouncedQuery)}
            </a>;
    })}
      {listFooter && suggestions?.length && listFooter() || null}
    </S.ListData>;
}
export default ListDataItems;