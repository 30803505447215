import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import { printPrice } from '@niaratech/niara-js-commons';
export const loyaltyStatusOptions = [{
  value: 'PENDING',
  label: 'Pendente'
}, {
  value: 'FAILED',
  label: 'Erro'
}, {
  value: 'REJECTED',
  label: 'Rejeitado'
}, {
  value: 'CANCELLED',
  label: 'Cancelado'
}, {
  value: 'CONFIRMED',
  label: 'Confirmado'
}, {
  value: 'CREATED',
  label: 'Criado'
}, {
  value: 'SCHEDULED',
  label: 'Agendado'
}];
export const loyaltyTypeOptions = [{
  value: 'POINTS',
  label: 'Pontos'
}, {
  value: 'CASHBACK',
  label: 'Cashback'
}];
export default fillCrudMetadataGaps({
  label: 'Programa de Fidelidade',
  labelPlural: 'Programas de fidelidade',
  name: 'Loyalty',
  deletable: false,
  updatable: false,
  insertable: false,
  authorization: authorization('clientConsultant', false),
  'custom:sortFields': ['date desc'],
  dynamoDB: {
    tableName: 'SpearLoyalty',
    indexes: [{
      indexName: 'tenantId-orderId-index',
      indexedProp: 'orderId'
    }]
  },
  columnNames: ['id', 'credential_alias', 'type', 'points', 'value', 'createDate', 'date', 'scheduleDate', 'ncl_integration_locator', 'status', 'taxId', 'cpf', 'pointsCancelled', 'currency', 'TSER_idContrato', 'TSER_numeroContrato'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    render: false,
    id: true
  }, {
    label: 'Credencial',
    name: 'credential_alias',
    type: 'text'
  }, {
    label: 'Pontos',
    name: 'points',
    type: 'float',
    render: false
  }, {
    label: 'Pontos cancelados',
    name: 'pointsCancelled',
    type: 'float',
    render: false
  }, {
    label: 'Data criação',
    name: 'createDate',
    type: 'datetime'
  }, {
    label: 'createdBy',
    name: 'createdBy',
    type: 'text',
    render: false
  }, {
    label: 'credentialId',
    name: 'credentialId',
    type: 'text',
    render: false
  }, {
    label: 'Data Referencia',
    name: 'date',
    type: 'datetime'
  }, {
    label: 'Agendamento',
    name: 'scheduleDate',
    type: 'datetime'
  }, {
    label: 'Código Acumulo',
    name: 'ncl_integration_locator'
  }, {
    label: 'status',
    name: 'status',
    options: [{
      value: 'PENDING',
      label: 'Pendente'
    }, {
      value: 'FAILED',
      label: 'Erro'
    }, {
      value: 'REJECTED',
      label: 'Rejeitado'
    }, {
      value: 'CANCELLED',
      label: 'Cancelado'
    }, {
      value: 'CONFIRMED',
      label: 'Confirmado'
    }, {
      value: 'CREATED',
      label: 'Criado'
    }, {
      value: 'SCHEDULED',
      label: 'Agendado'
    }],
    renderStyle(i, fieldName = 'status') {
      if (i[fieldName]) {
        switch (i[fieldName]) {
          case 'REJECTED':
          case 'FAILED':
          case 'CANCELLED':
            return 'danger';
          case 'SCHEDULED':
          case 'PENDING':
            return 'warning';
          case 'CREATED':
            return 'primary';
          case 'CONFIRMED':
            return 'success';
        }
      }
      return 'danger';
    }
  }, {
    label: 'UserId',
    name: 'taxId',
    type: 'text',
    deprecated: true,
    hint: 'Apenas para back-compatibility'
  }, {
    label: 'UserId',
    name: 'userId',
    type: 'text'
  }, {
    label: 'CPF',
    name: 'cpf',
    type: 'text'
  }, {
    label: 'orderId',
    name: 'orderId',
    type: 'text',
    render: false
  }, {
    label: 'Tipo',
    name: 'type',
    options: [{
      value: 'POINTS',
      label: 'Pontos'
    }, {
      value: 'CASHBACK',
      label: 'Cashback'
    }],
    renderColumn: function (i) {
      return i.type && i.type ? this.render(i) : 'Pontos';
    }
  }, {
    label: 'Unidade',
    name: 'unit',
    type: 'text'
  }, {
    label: 'Moeda',
    name: 'currency',
    type: 'text'
  }, {
    label: 'Valor',
    name: 'value',
    type: 'float',
    render: i => i.type && i.type === 'CASHBACK' ? printPrice(i.value || i.points, i.currency || 'BRL') : i.value || i.points
  }, {
    label: 'Descrição',
    name: 'description',
    type: 'text'
  }, {
    label: 'ID Contrato',
    name: 'TSER_idContrato',
    type: 'int'
  }, {
    label: 'Número Contrato',
    name: 'TSER_numeroContrato',
    type: 'text'
  }],
  filter: [{
    name: 'orderId_eq',
    field: 'orderId',
    op: 'eq'
  }]
});