import { formatNumber } from '@niaratech/niara-js-commons';
export const removeStringAccents = (string: string): string => {
  let newString = '';
  if (string) {
    newString = string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
  return newString;
};
export const formatStringToCompare = (string: string): string => {
  return removeStringAccents(string?.trim()?.toLowerCase() || '');
};
export const formatPoints = (value: number, locale?: string, fractionDigits?: number): string => {
  if (value == null) return '';
  const fractionDigitsToUse = fractionDigits ?? Math.round(value * 100) % 100 != 0 ? 2 : 0;
  return formatNumber(value, locale, fractionDigitsToUse);
};