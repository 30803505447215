import React from 'react';
export type Props = {
  color?: string;
  size?: number;
};
const Hotel: React.FC<Props> = ({
  color = '#273240',
  size = 24
}) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <g transform="translate(-32 -233)">
        <g transform="translate(32 233)">
          <g transform="translate(1.5 2.396)">
            <rect fill="none" stroke={color} stroke-linejoin="round" width="7" height="18" transform="translate(7 0.104)" />
            <rect fill="none" stroke={color} stroke-linejoin="round" width="5" height="14" transform="translate(2 4.104)" />
            <line fill="none" stroke={color} stroke-linecap="round" x2="20" transform="translate(0 18.104)" />
            <line fill="none" stroke={color} stroke-linejoin="round" x2="3" transform="translate(9 4.104)" />
            <line fill="none" stroke={color} stroke-linejoin="round" x2="3" transform="translate(9 8.104)" />
            <line fill="none" stroke={color} stroke-linejoin="round" x2="3" transform="translate(9 12.104)" />
          </g>
          <rect fill="none" stroke={color} stroke-linejoin="round" width="5" height="14" transform="translate(15.5 6.5)" />
        </g>
      </g>
    </svg>;
};
export default Hotel;