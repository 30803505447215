import { format } from 'date-fns';
import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Relatório automático',
  labelPlural: 'Relatórios automáticos',
  name: 'AutomaticReport',
  path: '/automaticReport',
  deletable: false,
  updatable: false,
  insertable: false,
  authorization: authorization('clientConsultant', 'admin'),
  'custom:sortFields': ['date desc'],
  dynamoDB: {
    tableName: 'SpearAutomaticReport',
    indexes: [{
      indexName: 'tenantId-clientId-date-index',
      indexedProp: 'clientId-date',
      scanIndexBackwards: true
    }]
  },
  columnNames: ['id', 'date', 'arc_name', 's3Link'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    render: false,
    id: true
  }, {
    name: 'date',
    label: 'Data',
    type: 'datetime'
  }, {
    name: 'clientId',
    label: 'Id do Cliente',
    type: 'text'
  }, {
    name: 's3Link',
    label: 'link',
    type: 'text'
  }, {
    name: 'arc_id',
    label: 'Id da configuração',
    type: 'text'
  }, {
    name: 'arc_name',
    label: 'Nome da configuração',
    type: 'text'
  }],
  filter: [{
    field: 'date',
    op: 'gt'
  }, {
    field: 'date',
    op: 'lt'
  }]
});