import defaultLocale from '@niaratech/niara-react-components/lib/util/defaultLocale'
import queryString from 'query-string'
import callApi from '../util/callApi'
import type { HotelDetails } from '../types/hotels'

export default async function fetchHotelDetails(
  hotelId: string,
  locale = defaultLocale(),
  tenantId: string,
  clientId: string
): Promise<HotelDetails> {
  try {
    return await callApi(
      'niara-spear-resource',
      `/hotels/${encodeURIComponent(hotelId)}?${queryString.stringify({ locale, tenantId, clientId })}`,
      'get'
    ).then((r): HotelDetails => {
      if (r && !r?.hotelId) {
        r.hotelId = hotelId
      }
      return r as HotelDetails
    })
  } catch (err) {
    return null
  }
}
