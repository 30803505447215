import { Stack } from '@mui/material';
import { useImmutableFunction } from '@niaratech/niara-react-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { IcShoppingCart } from '../../Icons';
import useTheme from '../../theme/useTheme';
import HotelCart, { type HotelCartProps } from '../HotelCart/HotelCart';
import DropCart from './DropCart';
import EmptyCart from './emptyCart.svg';
import * as S from './styles';
type ShoppingCartDropdownPropsLocal = Pick<HotelCartProps, 'cartItems' | 'cartTotal' | 'comparison' | 'paymentOptions' | 'onCreateQuotation'> & {
  // shoppingCartItems?: CartItem[]
  handleClickCheckout?: () => void;
  inNavigationComponent?: boolean; //Prop para saber se este componente está sendo chamado pelo Navigation, para alterar a cor da borda e do ícone.
  tooltipMode?: boolean;
};
export type ShoppingCartDropdownProps = Omit<ShoppingCartDropdownPropsLocal, 'handleClickCheckout'>;
const ShoppingCartDropdown: React.FC<ShoppingCartDropdownPropsLocal> = props => {
  const {
    handleClickCheckout,
    cartItems,
    comparison,
    cartTotal,
    paymentOptions,
    inNavigationComponent,
    tooltipMode,
    onCreateQuotation
  } = props;
  const [openModalCart, setOpenModalCart] = useState(false);
  const [onCartSummary, setOnCartSummary] = useState(false);
  const theme = useTheme();
  const colorText = !inNavigationComponent ? theme?.colors?.textColor : theme?.colors?.navbarText;
  const hasShoppingCartItems = useMemo(() => cartItems?.length > 0, [cartItems?.length]);
  const handleOpenOnCartSummary = useCallback(() => {
    setOnCartSummary(oldOnCartSummary => !oldOnCartSummary);
  }, []);
  const handleClosed = useCallback(() => {
    setOpenModalCart(false);
    setOnCartSummary(false);
  }, []);
  const onCheckout = useCallback(() => {
    handleClickCheckout();
    handleClosed();
  }, [handleClickCheckout, handleClosed]);
  const onCreateQuotation2 = useImmutableFunction(!onCreateQuotation ? null : () => {
    onCreateQuotation?.();
    handleClosed();
  });
  useEffect((): void => {
    if (hasShoppingCartItems) {
      setOpenModalCart(true);
    } else {
      handleClosed();
    }
  }, [handleClosed, hasShoppingCartItems, setOpenModalCart]);
  return <Stack position="relative">
      <S.Cart onClick={handleOpenOnCartSummary} borderActive={openModalCart || onCartSummary} type="button" inNavigationComponent={inNavigationComponent}>
        {hasShoppingCartItems && <S.NumberItems>{cartItems?.length}</S.NumberItems>}
        <IcShoppingCart color={colorText} />
      </S.Cart>

      {openModalCart && <DropCart setOnCartSummary={setOnCartSummary} setOpenModalCart={setOpenModalCart} handleClickCheckout={onCheckout} tooltipMode={tooltipMode} />}

      {onCartSummary && <ClickAwayListener onClickAway={handleClosed}>
          <S.ContainerCart>
            {hasShoppingCartItems ? <HotelCart paymentOptions={paymentOptions} cartItems={cartItems} cartTotal={cartTotal} comparison={comparison} onCheckout={onCheckout} onCreateQuotation={onCreateQuotation2} /> : <S.EmptyCart>
                <img src={EmptyCart} alt="empty-cart" />
                <h3>Seu carrinho esta vazio!</h3>
                <p>Adicione itens para proceder ao checkout</p>
              </S.EmptyCart>}
          </S.ContainerCart>
        </ClickAwayListener>}
    </Stack>;
};
export default ShoppingCartDropdown;