import React from 'react';
import type { PartialField } from '@niaratech/devcase-crud/dist/types';
import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import authenticationRule from '../authenticationRule';
import checkoutFlowRule from '../checkoutFlowRule';
import hotelPostSearchRule from '../hotelPostSearchRule';
import hotelSearchRule from '../hotelSearchRule';
import loyaltyConfiguration from '../loyaltyConfiguration';
import loyaltyRedeemConfiguration from '../loyaltyRedeemConfiguration';
import markupRule from '../markupRule';
import checkoutFormRule from '../checkoutFormRule';
import commissionRule from '../commissionRule';
import supplierPaymentRule from '../supplierPaymentRule';
import buyerPaymentRule from '../buyerPaymentRule';
import metasearchRule from '../metasearchRule';
import paymentRouterRule from '../paymentRouterRule';
import creditControlRule from '../creditControlRule';
import peopleRule from '../peopleRule';
import notificationRule from '../notificationRule';
import discountRule from '../discountRule';
import currencyExchangeRule from '../currencyExchangeRule';
import favoriteHotelsRule from '../favoriteHotelsRule';
import automaticCancellationRule from '../automaticCancellationRule';
declare module '@niaratech/devcase-crud/dist/types' {
  interface Field<T = any, E = any> {
    custom_validSubContexts?: Array<SubContextKey>;
    custom_engineRuleAdvancedStone?: boolean;
  }
}
export const RULE_FIELDS: Array<PartialField> = [{
  name: 'authenticationRuleId',
  label: authenticationRule.label,
  inputType: 'authenticationRule',
  showNullOption: true,
  custom_validSubContexts: ['clientId', 'landingPageId', "custom['categoria_membro']", 'tags', 'loyaltyTiers'],
  custom_engineRuleAdvancedStone: true
}, {
  name: 'loyaltyConfigurationId',
  label: loyaltyConfiguration.label,
  inputType: 'loyaltyReward',
  showNullOption: true,
  hint: 'Não usar com AllPoints',
  custom_validSubContexts: ['clientId', 'landingPageId', 'propertyId', 'countryId', 'cityId', 'credentialId', 'startDate', 'endDate', "custom['categoria_membro']", 'isThirdPartyReservation', 'tags', 'loyaltyTiers'],
  custom_engineRuleAdvancedStone: true
}, {
  name: 'loyaltyRedeemConfigurationId',
  label: loyaltyRedeemConfiguration.label,
  inputType: 'loyaltyRedeem',
  showNullOption: true,
  hint: 'Não usar com AllPoints',
  custom_validSubContexts: ['clientId', 'landingPageId', 'propertyId', 'credentialId', 'startDate', 'endDate', "custom['categoria_membro']", 'isThirdPartyReservation', 'tags', 'loyaltyTiers'],
  custom_engineRuleAdvancedStone: true
}, {
  name: 'checkoutFormRuleId',
  label: checkoutFormRule.label,
  inputType: 'checkoutFormRule',
  showNullOption: true,
  custom_validSubContexts: ['clientId', 'landingPageId', "custom['categoria_membro']", 'tags', 'loyaltyTiers'],
  custom_engineRuleAdvancedStone: false
}, {
  name: 'hotelSearchRuleId',
  label: hotelSearchRule.label,
  inputType: 'hotelSearchRule',
  showNullOption: true,
  custom_validSubContexts: ['clientId', 'landingPageId', "custom['categoria_membro']", 'tags', 'loyaltyTiers'],
  custom_engineRuleAdvancedStone: false
}, {
  name: 'hotelPostSearchRuleId',
  label: hotelPostSearchRule.label,
  inputType: 'hotelPostSearchRule',
  showNullOption: true,
  custom_validSubContexts: ['clientId', 'landingPageId', 'propertyId', 'countryId', 'cityId', 'credentialId', 'startDate', 'endDate', "custom['categoria_membro']", 'isThirdPartyReservation', 'tags', 'loyaltyTiers'],
  custom_engineRuleAdvancedStone: false
}, {
  name: 'markupRuleId',
  label: markupRule.label,
  inputType: 'markupRule',
  showNullOption: true,
  custom_engineRuleAdvancedStone: true,
  custom_validSubContexts: ['clientId', 'landingPageId', 'propertyId', 'countryId', 'cityId', 'credentialId', 'startDate', 'endDate', "custom['categoria_membro']", 'isThirdPartyReservation', 'tags', 'loyaltyTiers']
}, {
  name: 'commissionRuleId',
  label: commissionRule.label,
  inputType: 'commissionRule',
  showNullOption: true,
  custom_validSubContexts: ['clientId', 'landingPageId', 'propertyId', 'countryId', 'cityId', 'credentialId', 'startDate', 'endDate', "custom['categoria_membro']", 'isThirdPartyReservation', 'tags', 'loyaltyTiers'],
  custom_engineRuleAdvancedStone: true
}, {
  name: 'supplierPaymentRuleId',
  label: supplierPaymentRule.label,
  inputType: 'supplierPaymentRule',
  showNullOption: true,
  custom_engineRuleAdvancedStone: false
}, {
  name: 'buyerPaymentRuleId',
  label: buyerPaymentRule.label,
  inputType: 'buyerPaymentRule',
  showNullOption: true,
  custom_engineRuleAdvancedStone: false,
  custom_validSubContexts: ['clientId', 'landingPageId', 'propertyId', 'countryId', 'cityId', 'credentialId', 'startDate', 'endDate', "custom['categoria_membro']", 'isThirdPartyReservation', 'tags', 'loyaltyTiers']
}, {
  name: 'metasearchRuleId',
  label: metasearchRule.label,
  inputType: 'metasearchRule',
  showNullOption: true,
  custom_validSubContexts: ['landingPageId', 'clientId', 'startDate', 'endDate', "custom['categoria_membro']", 'isThirdPartyReservation', 'tags', 'loyaltyTiers'],
  custom_engineRuleAdvancedStone: true
}, {
  name: 'paymentRouterRuleId',
  label: paymentRouterRule.label,
  inputType: 'paymentRouterRule',
  showNullOption: true,
  custom_engineRuleAdvancedStone: true
}, {
  name: 'creditControlRuleId',
  label: creditControlRule.label,
  inputType: 'creditControlRule',
  showNullOption: true,
  custom_validSubContexts: ['landingPageId', 'clientId', "custom['categoria_membro']", 'tags', 'loyaltyTiers'],
  custom_engineRuleAdvancedStone: true
}, {
  name: 'checkoutFlowRuleId',
  label: checkoutFlowRule.label,
  inputType: 'checkoutFlowRuleId',
  showNullOption: true,
  custom_validSubContexts: ['landingPageId', 'clientId', "custom['categoria_membro']", 'tags', 'loyaltyTiers'],
  custom_engineRuleAdvancedStone: true
}, {
  name: 'peopleRuleId',
  label: peopleRule.label,
  inputType: 'peopleRuleId',
  showNullOption: true,
  custom_validSubContexts: ['clientId', 'tags'],
  custom_engineRuleAdvancedStone: true
}, {
  name: 'notificationRuleId',
  label: notificationRule.label,
  inputType: 'notificationRuleId',
  showNullOption: true,
  custom_validSubContexts: ['clientId', 'tags'],
  custom_engineRuleAdvancedStone: true
}, {
  name: 'discountRuleId',
  label: discountRule.label,
  inputType: 'discountRuleId',
  showNullOption: true,
  custom_validSubContexts: ['clientId', 'landingPageId', 'propertyId', 'countryId', 'cityId', 'credentialId', 'startDate', 'endDate', "custom['categoria_membro']", 'tags', 'loyaltyTiers'],
  custom_engineRuleAdvancedStone: true
}, {
  name: 'currencyExchangeRuleId',
  label: currencyExchangeRule.label,
  inputType: 'currencyExchangeRuleId',
  showNullOption: true,
  custom_validSubContexts: ['clientId', 'landingPageId', 'propertyId', 'countryId', 'cityId', 'credentialId', 'startDate', 'endDate', "custom['categoria_membro']", 'tags', 'loyaltyTiers'],
  custom_engineRuleAdvancedStone: true
}, {
  name: 'automaticCancellationRuleId',
  label: automaticCancellationRule.label,
  inputType: 'automaticCancellationRuleId',
  showNullOption: true,
  custom_engineRuleAdvancedStone: true,
  custom_validSubContexts: []
}, {
  name: 'favoriteHotelsRuleId',
  label: favoriteHotelsRule.label,
  inputType: 'favoriteHotelsRuleId',
  showNullOption: true,
  custom_engineRuleAdvancedStone: false,
  custom_validSubContexts: []
}, {
  name: 'privacyRuleId',
  label: 'Política de Cookies',
  inputType: 'privacyRuleId',
  showNullOption: true,
  custom_engineRuleAdvancedStone: true,
  custom_validSubContexts: []
}, {
  name: 'vcnRuleId',
  label: 'Regra de VCN',
  inputType: 'vcnRuleId',
  showNullOption: true,
  custom_engineRuleAdvancedStone: true
}, {
  name: 'includeExtrasRuleId',
  label: 'Regra de inclusão de extras',
  inputType: 'IncludeExtrasRuleId',
  showNullOption: true,
  // Adicionar subcontextos válidos para a pedra
  custom_engineRuleAdvancedStone: true,
  custom_validSubContexts: []
}
// Adicionar novas pedras acima
];
type SubContextKey = (typeof SUBCONTEXTS)[number]['value'];
export const SUBCONTEXTS = ([{
  label: 'Fidelidade',
  value: 'loyaltyTiers',
  group: ['B2C'],
  active: true,
  render: {
    ['default']: {
      input: 'autocomplete-credential-ptos',
      fieldPath: 'valueStrArr',
      label: 'Credenciais PTOS'
    }
  },
  secondRender: {
    ['default']: {
      input: 'input-text',
      fieldPath: 'valueStrArr',
      label: 'Tier'
    }
  }
}, {
  label: 'Check-in',
  value: 'startDate',
  group: ['DATE'],
  active: true,
  render: {
    ['default']: {
      input: 'date-picker',
      fieldPath: 'valueStrArr',
      label: 'Data'
    },
    ['between']: {
      input: 'date-picker-between',
      fieldPath: 'valueStrArr',
      label: 'Intervalo de Datas'
    }
  }
}, {
  label: 'Check-out',
  value: 'endDate',
  group: ['DATE'],
  active: true,
  render: {
    ['default']: {
      input: 'date-picker',
      fieldPath: 'valueStrArr',
      label: 'Data'
    },
    ['between']: {
      input: 'date-picker-between',
      fieldPath: 'valueStrArr',
      label: 'Intervalo de Datas'
    }
  }
}, {
  label: 'Data da reserva',
  value: 'reservationDate',
  group: ['DATE'],
  active: true,
  render: {
    ['default']: {
      input: 'date-picker',
      fieldPath: 'valueStrArr',
      label: 'Data'
    },
    ['between']: {
      input: 'date-picker-between',
      fieldPath: 'valueStrArr',
      label: 'Intervalo de Datas'
    }
  }
}, {
  label: 'Duração',
  value: 'durationInDays',
  group: ['DATE'],
  active: true,
  render: {
    ['default']: {
      input: 'input-number',
      valueExpression: 'int_eq',
      fieldPath: 'valueIntArr',
      label: 'Dia(s)'
    }
  }
}, {
  label: 'Antecedência',
  value: 'antecedenceInDays',
  group: ['DATE'],
  active: true,
  render: {
    ['default']: {
      input: 'input-number',
      valueExpression: 'int_eq',
      fieldPath: 'valueIntArr',
      label: 'Dia(s)'
    }
  }
}, {
  label: 'Cliente',
  value: 'clientId',
  group: ['B2B'],
  active: true,
  render: {
    ['default']: {
      input: 'autocomplete-client',
      fieldPath: 'valueObjectArr',
      label: 'Clientes'
    }
  }
}, {
  label: 'Hotel',
  value: 'propertyId',
  group: ['DESTINATION'],
  active: true,
  render: {
    ['default']: {
      input: 'autocomplete-hotel',
      fieldPath: 'valueObjectArr',
      label: 'Hotéis'
    }
  }
}, {
  label: 'País',
  deprecatedValue: 'regionId',
  value: 'countryId',
  valueForExpression: 'regionIds',
  disableDebug: true,
  group: ['DESTINATION'],
  active: true,
  render: {
    ['default']: {
      input: 'autocomplete-country',
      fieldPath: 'valueObjectArr',
      label: 'Destinos'
    }
  }
}, {
  label: 'Cidade',
  value: 'cityId',
  valueForExpression: 'regionIds',
  //Valor a ser priorizado ao gerar o expression no motor de regras.
  disableDebug: true,
  group: ['DESTINATION'],
  active: true,
  render: {
    ['default']: {
      input: 'autocomplete-city',
      fieldPath: 'valueObjectArr',
      label: 'Destinos'
    }
  }
}, {
  label: 'Ota Builder',
  value: 'landingPageId',
  group: ['B2B'],
  active: true,
  render: {
    ['default']: {
      input: 'autocomplete-landingPage',
      fieldPath: 'valueStrArr',
      label: 'Ota Builder' //TODO Ver nome correto com a Ana e se pode escolher mais de 1.
    }
  }
}, {
  label: 'Marcador',
  value: 'tags',
  disableDebug: true,
  group: ['B2B'],
  active: true,
  render: {
    ['default']: {
      input: 'autocomplete-tags',
      fieldPath: 'valueStrArr',
      //valueExpression: '^',
      label: 'Marcador' //TODO Ver com Ana se pode escolher mais de 1.
    }
  }
}, {
  label: 'Credencial',
  value: 'credentialId',
  group: ['B2B'],
  active: true,
  render: {
    ['default']: {
      input: 'autocomplete-credential',
      fieldPath: 'valueStrArr',
      label: 'Credenciais' //TODO Ver com Ana se pode escolher mais de 1.
    }
  }
}, {
  label: 'Reserva para terceiros',
  value: 'isThirdPartyReservation',
  group: ['B2C'],
  active: true,
  render: {
    ['default']: {
      input: 'none'
    }
  },
  notInsertIntoArrExpressionValues: true
}, {
  label: 'Fidelimax',
  value: "custom['categoria_membro']",
  group: ['B2C'],
  active: true,
  render: {
    ['default']: {
      input: 'input-text',
      fieldPath: 'valueStrArr',
      label: 'Categoria'
    }
  }
}, {
  label: 'Tipo de dispositivo',
  value: 'deviceType',
  group: ['B2C'],
  active: true,
  render: {
    ['default']: {
      input: 'input-text',
      fieldPath: 'valueStrArr',
      label: 'Tipo de dispositivo',
      options: [{
        label: 'Mobile',
        value: 'mobile'
      }, {
        label: 'Desktop',
        value: 'desktop'
      }]
    }
  }
}, {
  label: 'Redes',
  deprecatedValue: 'propertyGroup',
  value: 'propertyGroups',
  valueForExpression: 'propertyGroups',
  group: ['DESTINATION'],
  active: true,
  render: {
    ['default']: {
      input: 'autocomplete-propertyGroup',
      fieldPath: 'valueObjectArr',
      label: 'Redes'
    }
  }
}, {
  label: 'Debug',
  value: 'debugHeader',
  group: ['DEBUG'],
  active: true,
  render: {
    ['default']: {
      input: 'input-text',
      fieldPath: 'valueStrArr',
      label: 'Debug'
    }
  }
}, {
  label: 'Origem da Reserva',
  value: 'reservationOrigin',
  group: ['B2B'],
  active: true,
  render: {
    ['default']: {
      input: 'input-text',
      fieldPath: 'valueStrArr',
      label: 'Tipo de dispositivo',
      options: [
      //{ label: 'Importação - Pull', value: 'PULL' },
      //{ label: 'Importação - OBAPI', value: 'OBAPI' },
      //{ label: 'API de reserva externa', value: 'EXTERNAL_API' },
      {
        label: 'API de Venda',
        value: 'NIARA_API'
      }, {
        label: 'Reserva manual',
        value: 'MANUAL_RESERVATION'
      }
      //{ label: 'Front', value: 'FRONT' },
      //{ label: 'Front consultor', value: 'CONSULTANT_FRONT' },
      //{ label: 'Front cliente', value: 'CLIENT_FRONT' },
      //{ label: 'B2C', value: 'B2C' },
      //{ label: 'Front - Front', value: 'FRONT_FRONT' },
      ]
    }
  }
}] as const);
export default fillCrudMetadataGaps({
  label: 'Motor de Regra',
  labelPlural: 'Motores de Regra',
  name: 'EngineRule',
  namePlural: 'EngineRules',
  authorization: authorization('consultant', 'admin'),
  dynamoDB: {
    tableName: 'SpearEngineRule'
  },
  custom_withAudit: true,
  columns: ['id', 'name', 'priority', 'contexts', 'humanReadableContext', 'enabled'].concat(RULE_FIELDS.map(f => f.name)),
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Título do motor de regra',
    hint: 'Defina um título para identificação do motor de regra.'
  }, {
    name: 'humanReadableContext',
    label: 'Descrição do contexto',
    hint: 'Detalhamento do contexto legível por um humano'
    // renderColumn: function (entity, fieldName) {
    //   if (entity[fieldName]) return entity[fieldName]
    //   // fallback - usa contexts
    //   return entity?.contexts
    //     ?.map((context) => {
    //       return context?.conditions
    //         ?.map(
    //           (condition) =>
    //             `${condition.subContext} ${condition.operation} ${condition.valueStrArr?.join(', ') || ''}${
    //               condition.valueFloatArr?.join(', ') || ''
    //             }${condition.valueIntArr?.join(', ') || ''}${
    //               condition.valueObjectArr?.map((r) => r.label)?.join(', ') || ''
    //             }`
    //         )
    //         .join(' e ')
    //     })
    //     .join(' ou ')
    // },
  }, {
    name: 'newExpression',
    label: 'Expressão',
    renderColumn: false
  }, {
    name: 'newExpressionValues',
    label: 'Valores da expressão',
    renderColumn: false,
    type: 'textarrayarray'
  }, {
    name: 'parentId',
    label: 'Regra pai'
  }, {
    name: 'enabled',
    label: 'Ativo',
    type: 'boolean'
  }, {
    name: 'priority',
    label: 'Nível de Prioridade',
    type: 'int',
    options: [{
      label: 'Muito Alta',
      value: 100
    }, {
      label: 'Alta +',
      value: 150
    }, {
      label: 'Alta',
      value: 200
    }, {
      label: 'Média +',
      value: 250
    }, {
      label: 'Média',
      value: 300
    }, {
      label: 'Baixa +',
      value: 350
    }, {
      label: 'Baixa',
      value: 400
    }, {
      label: 'Muito Baixa',
      value: 500
    }]
  }, ...RULE_FIELDS.map(f => ({
    ...f,
    render: e => {
      if (e?.[f.name]) {
        return <a target="_blank" rel="noreferrer" href={`/${f.name.substring(0, f.name.length - 2)}s/` + e?.[f.name]}>
              Regra
            </a>;
      }
      return null;
    }
  })), {
    name: 'contexts',
    label: 'Contextos',
    graphQLType: '[EngineRule_Context]',
    graphQLInputType: '[EngineRule_ContextInput]',
    type: 'objectarray',
    renderColumn: false,
    // por padrão, não mostra no grid
    fields: [{
      name: 'conditions',
      label: 'Condições',
      type: 'objectarray',
      graphQLType: '[EngineRule_Context_Condition]',
      graphQLInputType: '[EngineRule_Context_ConditionInput]',
      fields: [{
        name: 'context',
        label: 'Contexto',
        type: 'text',
        options: [{
          label: 'Datas',
          value: 'DATE',
          active: true
        }, {
          label: 'B2B',
          value: 'B2B',
          active: true
        }, {
          label: 'B2C',
          value: 'B2C',
          active: true
        }, {
          label: 'Destino',
          value: 'DESTINATION',
          active: true
        }, {
          label: 'Debug',
          value: 'DEBUG',
          active: true
        }, {
          label: 'Tarifas',
          value: 'RATE',
          active: false //TODO pendências conforme planilha
        }, {
          label: 'Persona',
          value: 'PERSONA',
          active: false //TODO pendências conforme planilha
        }, {
          label: 'Fidelidade',
          value: 'FIDELITY',
          active: false
        }, {
          label: 'Forma de recebimento',
          value: 'RECEIPTMETHOD',
          active: false //TODO Podemos usar?
        }, {
          label: 'Fluxo',
          value: 'FLOW',
          active: false
        }, {
          label: 'Critérios da busca',
          value: 'SEARCHCRITERIA',
          active: false //TODO pendências conforme planilha
        }]
      }, {
        name: 'subContext',
        label: 'Sub contexto',
        type: 'text',
        options: ((SUBCONTEXTS as unknown) as any)
      }, {
        name: 'operation',
        label: 'Lógica',
        type: 'text',
        options: [{
          label: 'Entre',
          value: 'between',
          group: ['startDate', 'endDate', 'reservationDate', 'guestsQuantity', 'childAge'],
          active: true
        }, {
          label: 'Antes de',
          value: '<=',
          group: ['startDate', 'endDate', 'reservationDate'],
          active: true
        }, {
          label: 'Depois de',
          value: '>=',
          group: ['startDate', 'endDate', 'reservationDate'],
          active: true
        }, {
          label: 'Igual a',
          value: '==',
          group: [],
          active: true
        }, {
          label: 'É Verdadeiro',
          value: 'is True',
          group: ['isThirdPartyReservation'],
          active: true,
          doNotNeedValue: true
        }, {
          label: 'É Falso',
          value: 'is False',
          group: ['isThirdPartyReservation'],
          active: true,
          doNotNeedValue: true
        }, {
          label: 'É',
          value: 'in',
          group: ['clientId', "custom['categoria_membro']", 'rateType', 'cancelPolicy', 'propertyId', 'landingPageId', 'credentialId', 'userType', 'userRole', 'member', 'creditCardBrand', 'creditCardBin', 'origin', 'paymentFlow', 'propertyGroup', 'propertyGroups', 'deviceType', 'loyaltyTiers', 'debugHeader', 'reservationOrigin'],
          active: true
        }, {
          label: 'Não é',
          value: 'not_in',
          group: ['clientId', 'rateType', 'cancelPolicy', 'propertyId', 'landingPageId', 'credentialId', 'userType', 'userRole', 'member', 'creditCardBrand', 'creditCardBin', 'origin', 'paymentFlow', "custom['categoria_membro']", 'propertyGroup', 'propertyGroups', 'deviceType', 'loyaltyTiers', 'reservationOrigin'],
          active: true
        }, {
          label: 'É',
          value: 'contains_any',
          group: ['countryId', 'cityId'],
          active: true
        }, {
          label: 'Não é',
          value: 'lacks_all',
          group: ['countryId', 'cityId'],
          active: true
        },
        /**
         * @deprecated
         */
        {
          label: 'Inclui',
          value: '^',
          group: ['tags'],
          active: false
        }, {
          label: 'Inclui',
          value: 'in',
          group: ['tags'],
          active: true
        }, {
          label: 'Não Inclui',
          value: 'not_in',
          group: ['tags'],
          active: true
        }, {
          label: 'Maior Igual',
          value: '>=',
          group: ['durationInDays', 'antecedenceInDays'],
          active: true
        }, {
          label: 'Menor Igual',
          value: '<=',
          group: ['durationInDays', 'antecedenceInDays'],
          active: true
        }]
      }, {
        name: 'valueStrArr',
        type: 'textarray',
        hint: 'Valor se string array'
      }, {
        name: 'valueIntArr',
        type: 'intarray',
        hint: 'Valor se int array'
      }, {
        name: 'valueFloatArr',
        type: 'floatarray',
        hint: 'Valor se float array'
      }, {
        name: 'valueObjectArr',
        type: 'objectarray',
        hint: 'Valor se object array',
        fields: [{
          name: 'value',
          type: 'text'
        }, {
          name: 'label',
          type: 'text'
        }]
      }]
    }]
  }],
  filter: [{
    op: 'contains',
    field: 'name'
  }, {
    op: 'eq',
    field: 'enabled'
  }]
});