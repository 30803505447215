import React from 'react';
import fillCrudMetadataGaps, { PartialField } from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import hotelReservation from '../hotelReservation';
import quotation from '../quotation';
import person from '../person';
import lostReservation from '../lostReservation';
import { Credential } from '..';
export const CONTENT = ['B2BR', 'NIAR', 'OMNI', 'REXT', 'SHOP', 'TRND', 'WOOB', 'EXPD', 'HOTB', 'RSTL', 'WBDS', 'CMNT', 'W2MT', 'DIDA', 'DTSS'];
export const HOTEL_CONTENT = ['B2BR', 'OMNI', 'TRND', 'HOTB', 'RSTL', 'WBDS', 'W2MT', 'DIDA', 'DTSS'];
export const CRM_TYPES = ['SCRM', 'ACRM', 'DCRM', 'RDSM'];
const CONDITIONAL = ['BRPG', 'EBNX', 'MSCD', 'WEX0', 'WINT', 'INGN'];
export const VCN = ['MSCD', 'WEX0', 'PCCC', 'BVCN'];
const LOYALTY = ['ALLP', 'FDMX', 'LIVE', 'TSER'];
export const reservation_fields = hotelReservation.fields.filter(f => !['object', 'objectarray'].includes(f.type)).map(f => ({
  label: `(Reserva) ${f.label ?? f.name}`,
  value: 'hotelReservation.' + f.name
}));
reservation_fields.push({
  label: `(Reserva) Número de Adultos`,
  value: 'hotelReservation.roomStayAdults'
});
reservation_fields.push({
  label: `(Reserva) Número de Crianças`,
  value: 'hotelReservation.roomStayChildren'
});
reservation_fields.push({
  label: `(Reserva) Idade das Crianças`,
  value: 'hotelReservation.roomStayChildrenAges'
});
reservation_fields.push({
  label: `(Reserva) Descrição do Quarto`,
  value: 'hotelReservation.roomStayRoomDescription'
});
reservation_fields.push({
  label: `(Reserva) Tipo do Quarto`,
  value: 'hotelReservation.roomStayRoomType'
});
reservation_fields.push({
  label: `(Reserva) Código do Tarifário`,
  value: 'hotelReservation.roomStayRatePlanId'
});
reservation_fields.push({
  label: `(Reserva) Nome do Tarifário`,
  value: 'hotelReservation.roomStayRatePlanName'
});
reservation_fields.push({
  label: `(Reserva) Política de Cancelamento`,
  value: 'hotelReservation.roomStayCancelPolicy'
});
reservation_fields.push({
  label: `(Reserva) Desc. Política Canc.`,
  value: 'hotelReservation.roomStayCancelPolicyDesc'
});
reservation_fields.push({
  label: `(Reserva) Políticas Gerais`,
  value: 'hotelReservation.roomStayGeneralPoliciesDesc'
});
reservation_fields.push({
  label: `(Reserva) Forma de pgmt. do cliente`,
  value: 'hotelReservation.selectedPaymentType'
});
reservation_fields.push({
  label: `(Reserva) Qtd Quartos`,
  value: 'hotelReservation.roomStayQuantity'
});
reservation_fields.push({
  label: `(Reserva) Nome da Op. no CRM`,
  value: 'hotelReservation.crmName'
});
reservation_fields.push({
  label: `(Reserva) Data Inserção no CRM`,
  value: 'hotelReservation.crmInsertDate'
});
reservation_fields.push({
  label: `(Reserva) Origem para CRM`,
  value: 'hotelReservation.crmOrigin'
});
reservation_fields.push({
  label: `(Reserva) Chatbot Iniciou`,
  value: 'hotelReservation.chatbotUsed'
});
reservation_fields.sort((a, b) => a.label > b.label ? 1 : -1);
export const indirect_reservation_fields = reservation_fields.map(f => ({
  label: `(Reserva Indireta) ${f.label?.replace(`(Reserva)`, ``) ?? f.value.replace('hotelReservation', 'indirectHotelReservation')}`,
  value: f.value.replace('hotelReservation', 'indirectHotelReservation')
}));
indirect_reservation_fields.sort((a, b) => a.label > b.label ? 1 : -1);
export const quotation_fields = quotation.fields.filter(f => !['object', 'objectarray'].includes(f.type)).map(f => ({
  label: `(Cotação) ${f.label ?? f.name}`,
  value: 'quotation.' + f.name
}));
quotation_fields.push({
  label: `(Cotação) Link de Conversão`,
  value: 'quotation.link'
});
quotation_fields.push({
  label: `(Cotação) Formas de pagamento`,
  value: 'quotation.paymentOptions'
});
quotation_fields.push({
  label: `(Cotação) Qtd Quartos`,
  value: 'quotation.roomStayQuantity'
});
quotation_fields.push({
  label: `(Cotação) Nome da Op. no CRM`,
  value: 'quotation.crmName'
});
quotation_fields.push({
  label: `(Cotação) Data Inserção no CRM`,
  value: 'quotation.crmInsertDate'
});
quotation_fields.push({
  label: `(Cotação) Id do Hotel`,
  value: 'quotation.hotelId'
});
quotation_fields.push({
  label: `(Cotação) Nome do Hotel`,
  value: 'quotation.hotelName'
});
quotation_fields.push({
  label: `(Cotação) Origem para CRM`,
  value: 'quotation.crmOrigin'
});
quotation_fields.push({
  label: `(Cotação) Código do Tarifário`,
  value: 'quotation.ratePlanId'
});
quotation_fields.push({
  label: `(Cotação) Nome do Tarifário`,
  value: 'quotation.ratePlanName'
});
quotation_fields.push({
  label: `(Cotação) Código Promocional`,
  value: 'quotation.promoCode'
});
quotation_fields.push({
  label: `(Cotação) Moeda`,
  value: 'quotation.currencyCode'
});
quotation_fields.push({
  label: `(Cotação) Número de Adultos`,
  value: 'quotation.adults'
});
quotation_fields.push({
  label: `(Cotação) Número de Crianças`,
  value: 'quotation.children'
});
quotation_fields.push({
  label: `(Cotação) Idade das Crianças`,
  value: 'quotation.childrenAges'
});
quotation_fields.push({
  label: `(Cotação) Valor`,
  value: 'quotation.totalPrice'
});
quotation_fields.push({
  label: `(Cotação) Tipo do Quarto`,
  value: 'quotation.roomTypeName'
});
quotation_fields.push({
  label: `(Cotação) Número de Noites`,
  value: 'quotation.numberOfNights'
});
quotation_fields.sort((a, b) => a.label > b.label ? 1 : -1);
export const person_fields = person.fields.filter(f => !['object', 'objectarray'].includes(f.type)).map(f => ({
  label: `(Pessoa) ${f.label ?? f.name}`,
  value: 'person.' + f.name
}));
person_fields.push({
  label: `(Pessoa) Data de Criação`,
  value: 'person.creationDate'
});
person_fields.push({
  label: `(Pessoa) Tipo da Última Interação no CRM`,
  value: 'person.lastCrmInteractionType'
});
person_fields.push({
  label: `(Pessoa) Data da Última Interação no CRM`,
  value: 'person.lastCrmInteractionDate'
});
person_fields.push({
  label: `(Pessoa) Origem para CRM`,
  value: 'person.crmOrigin'
});
person_fields.sort((a, b) => a.label > b.label ? 1 : -1);
export const lostReservation_fields = lostReservation.fields.filter(f => !['object', 'objectarray'].includes(f.type)).map(f => ({
  label: `(Reserva Perdida) ${f.label ?? f.name}`,
  value: 'lostReservation.' + f.name
}));
lostReservation_fields.push({
  label: `(Reserva Perdida) Abandonada`,
  value: 'lostReservation.isAbandoned'
});
lostReservation_fields.push({
  label: `(Reserva Perdida) Código do Tarifário`,
  value: 'lostReservation.roomRateId'
});
lostReservation_fields.push({
  label: `(Reserva Perdida) Nome do Tarifário`,
  value: 'lostReservation.roomRateName'
});
lostReservation_fields.push({
  label: `(Reserva Perdida) Código do Quarto`,
  value: 'lostReservation.roomTypeId'
});
lostReservation_fields.push({
  label: `(Reserva Perdida) Tipo do Quarto`,
  value: 'lostReservation.roomTypeName'
});
lostReservation_fields.push({
  label: `(Reserva Perdida) Número de Noites`,
  value: 'lostReservation.numberOfNights'
});
lostReservation_fields.push({
  label: `(Reserva Perdida) CTA Motor Omnibees`,
  value: 'lostReservation.ctaOmnibeesBE'
});
lostReservation_fields.push({
  label: `(Reserva Perdida) CTA Motor Niara`,
  value: 'lostReservation.ctaNiaraBE'
});
lostReservation_fields.push({
  label: `(Reserva Perdida) CTA Cotação CR`,
  value: 'lostReservation.ctaQuotation'
});
lostReservation_fields.push({
  label: `(Reserva Perdida) Nome da Op. no CRM`,
  value: 'lostReservation.crmName'
});
lostReservation_fields.push({
  label: `(Reserva Perdida) Data Inserção no CRM`,
  value: 'lostReservation.crmInsertDate'
});
lostReservation_fields.push({
  label: `(Reserva Perdida) Origem para CRM`,
  value: 'lostReservation.crmOrigin'
});
lostReservation_fields.sort((a, b) => a.label > b.label ? 1 : -1);
export const credentialTypesOptions = [{
  value: 'BRTR',
  label: 'Braintree (Paypal)'
}, {
  value: 'B2PY',
  label: 'Bee2Pay'
}, {
  value: 'B2BR',
  label: 'B2B Reservas'
}, {
  value: 'BRPG',
  label: 'Braspag'
}, {
  value: 'EBNX',
  label: 'Ebanx'
}, {
  value: 'MSCD',
  label: 'Mastercard'
}, {
  value: 'NIAR',
  label: 'Niara'
}, {
  value: 'OMNI',
  label: 'Omnibees'
}, {
  value: 'REXT',
  label: 'Rextur Advance'
}, {
  value: 'SHOP',
  label: 'Shop Hotel'
}, {
  value: 'TRND',
  label: 'Trend'
},
// { value: 'WEX0', label: 'Wex' },
{
  value: 'WINT',
  label: 'Wintour'
}, {
  value: 'WOOB',
  label: 'Wooba'
}, {
  value: 'LIVE',
  label: 'Livelo'
}, {
  value: 'INGN',
  label: 'Ingenico'
}, {
  value: 'EXPD',
  label: 'Expedia'
},
// { value: 'ALLP', label: 'AllPoints' },
{
  value: 'SABR',
  label: 'Sabre - Filas'
}, {
  value: 'PCCC',
  label: 'Cartão corporativo'
}, {
  value: 'FDMX',
  label: 'Fidelimax'
},
// { value: 'SPIN', label: 'Spin Pay (PIX)' },
{
  value: 'MCPG',
  label: 'Mercado Pago'
}, {
  value: 'ADYE',
  label: 'Adyen'
}, {
  value: 'PTOS',
  label: 'Integração de Fidelidada Genérica'
}, {
  value: 'HOTB',
  label: 'Hotelbeds'
},
// { value: 'CMNT', label: 'CMNET' },
{
  value: 'COIN',
  label: 'Coinfo'
}, {
  value: 'GTNT',
  label: 'GetNet (PIX)'
}, {
  value: 'ITAU',
  label: 'Itaú (PIX)'
}, {
  value: 'TSER',
  label: 'TSExplorer'
}, {
  value: 'RSTL',
  label: 'Restel'
}, {
  value: 'WBDS',
  label: 'WebBeds'
}, {
  value: 'SCRM',
  label: 'SharpSpring (Constant Contact)'
}, {
  value: 'BVCN',
  label: 'Bee2Pay VCN'
}, {
  value: 'W2MT',
  label: 'W2M'
}, {
  value: 'DIDA',
  label: 'DIDA'
}, {
  value: 'DTSS',
  label: 'DieuxTravel'
}, {
  value: 'ACRM',
  label: 'Amarante (CRM)'
}, {
  value: 'DCRM',
  label: 'Deville (CRM)'
}, {
  value: 'ESLT',
  label: 'eSolution'
}, {
  value: 'SERA',
  label: 'Serasa'
}, {
  value: 'RDSM',
  label: 'RD Station Market'
}];
export const metadataOptions = [{
  value: 'orderCardHolderName',
  label: 'Nome do titular do cartão'
}, {
  value: 'orderProductType',
  label: 'Produto (aéreo, hotel)'
}, {
  value: 'orderClientName',
  label: 'Nome do cliente'
}, {
  value: 'orderClientId',
  label: 'Id do cliente'
}, {
  value: 'orderApproverName',
  label: 'Nome do aprovador'
}, {
  value: 'orderLocator',
  label: 'Código da solicitação/ requisição'
}, {
  value: 'orderReservationId',
  label: 'Id da reserva (Niara)'
}, {
  value: 'orderReservationDate',
  label: 'Data da reserva'
}, {
  value: 'orderRequestorName',
  label: 'Nome do solicitante'
}, {
  value: 'orderRequestorCPF',
  label: 'CPF do solicitante'
}, {
  value: 'orderRequestorPhone',
  label: 'Número do telefone do solicitante'
}, {
  value: 'orderRequestorEmail',
  label: 'Endereço de e-mail do solicitante'
}, {
  value: 'orderRequestorAddress',
  label: 'Endereço do solicitante'
}, {
  value: 'orderRequestorZipCode',
  label: 'CEP do endereço'
}, {
  value: 'orderRequestorDistrict',
  label: 'Nome do bairro do enreceço'
}, {
  value: 'orderRequestorCity',
  label: 'Cidade do endereço'
}, {
  value: 'orderRequestorState',
  label: 'Estado (UF) do endereço'
}, {
  value: 'orderRequestorCountryCode',
  label: 'Código do país (ISO 3166).'
}, {
  value: 'orderRequestorRegistration',
  label: 'Matrícula do solicitante'
}, {
  value: 'orderDepartment',
  label: 'Departamento'
}, {
  value: 'orderCostCenterCode',
  label: 'Código do centro de custo'
}, {
  value: 'orderCostCenterDescription',
  label: 'Centro de custo'
}, {
  value: 'orderLedgerAccountCode',
  label: 'Código da conta contábil'
}, {
  value: 'orderLedgerAccountDescription',
  label: 'Descrição da conta contábil'
}, {
  value: 'orderProjectCode',
  label: 'Código do projeto'
}, {
  value: 'orderProjectDescription',
  label: 'Descrição do projeto'
}, {
  value: 'orderCustomField1',
  label: 'Campo customizado 1'
}, {
  value: 'orderCustomField2',
  label: 'Campo customizado 2'
}, {
  value: 'orderCustomField3',
  label: 'Campo customizado 3'
}, {
  value: 'orderCustomField4',
  label: 'Campo customizado 4'
}, {
  value: 'orderCustomField5',
  label: 'Campo customizado 5'
}, {
  value: 'hotelReservationLocator',
  label: 'Código localizador da reserva de hospedagem.'
}, {
  value: 'hotelReservationStartDate',
  label: 'Checkin. Data inicial da hospedagem: YYYY-MM-DD.'
}, {
  value: 'hotelReservationEndDate',
  label: 'Checkout. Data final da hospedagem: YYYY-MM-DD.'
}, {
  value: 'hotelName',
  label: 'Nome do Hotel.'
}, {
  value: 'hotelId',
  label: 'Id do hotel'
}, {
  value: 'hotelVat',
  label: 'CNPJ do fornecedor.'
}, {
  value: 'hotelPhone',
  label: 'Número do telefone do fornecedor.'
}, {
  value: 'hotelEmail',
  label: 'Endereço de e-mail do fornecedor.'
}, {
  value: 'hotelAddress',
  label: 'Endereço do fornecedor.'
}, {
  value: 'hotelZipCode',
  label: 'CEP do endereço.'
}, {
  value: 'hotelDistrict',
  label: 'Nome do bairro do endereço.'
}, {
  value: 'hotelCity',
  label: 'Cidade do endereço.'
}, {
  value: 'hotelState',
  label: 'Estado (UF) do endereço.'
}, {
  value: 'hotelCountryCode',
  label: 'Código do país (ISO 3166).'
}, {
  value: 'hotelSource',
  label: 'Provedor de hospedagem'
}, {
  value: 'hotelGuestFirstName',
  label: 'Primeiro nome do hóspede da hospedagem.'
}, {
  value: 'hotelGuestLastName',
  label: 'Sobrenome do hóspede da hospedagem.'
}, {
  value: 'hotelGuestFullName',
  label: 'Nome completo do hóspede da hospedagem.'
}, {
  value: 'hotelGuestPhone',
  label: 'Número do telefone do viajante.'
}, {
  value: 'hotelGuestEmail',
  label: 'Endereço de e-mail do viajante.'
}, {
  value: 'hotelGuestRegistration',
  label: 'Matrícula do viajante'
}, {
  value: 'hotelNights',
  label: 'Número de diárias'
}, {
  value: 'hotelAveragePriceValue',
  label: 'Valor médio das diárias'
}, {
  value: 'hotelTotalPriceValue',
  label: 'Valor da soma das diárias da hospedagem, excluindo taxas e extras.'
}, {
  value: 'hotelTotalPriceWithTaxAndExtrasValue',
  label: 'Valor da soma das diárias da hospedagem com taxas e extras.'
}, {
  value: 'hotelTotalPriceAfterDeductionsValue',
  label: 'Valor da reserva com retenção de impostos (lei Kandir)'
}, {
  value: 'hotelTotalPriceCurrencyCode',
  label: 'Moeda da tarifa. Código alpha padrão ISO 4217'
}, {
  value: 'hotelRateName',
  label: 'Nome da tarifa.'
}, {
  value: 'hotelRateCode',
  label: 'Código da tarifa.'
}, {
  value: 'hotelRateOccupancy',
  label: 'Capacidade do apartamento. Opções: SGL, DBL, TPL.'
}, {
  value: 'hotelRateBoard',
  label: 'Tipo de pensão disponível para a tarifa. Exemplo: Café da manhã, Meia pensão.'
}, {
  value: 'hotelTaxesTotalValue',
  label: 'Valor total das taxas da hospedagem.'
}, {
  value: 'hotelTaxesCurrencyCode',
  label: 'Moeda da taxa. Código alpha padrão ISO 4217'
}, {
  value: 'hotelExtrasTotalValue',
  label: 'Valor total dos Extras da hospedagem.'
}, {
  value: 'hotelExtrasCurrencyCode',
  label: 'Moeda dos Extras. Código alpha padrão ISO 4217'
}, {
  value: 'hotelRoomType',
  label: 'Tipo do quarto.'
}, {
  value: 'hotelComments',
  label: 'Comentários'
}, {
  value: 'hotelCustomField1',
  label: 'Campo customizado 1'
}, {
  value: 'hotelCustomField2',
  label: 'Campo customizado 2'
}, {
  value: 'hotelCustomField3',
  label: 'Campo customizado 3'
}, {
  value: 'hotelCustomField4',
  label: 'Campo customizado 4'
}, {
  value: 'hotelCustomField5',
  label: 'Campo customizado 5'
}, {
  value: 'flightPaxFirstName',
  label: 'Nome do passageiro.'
}, {
  value: 'flightPaxLastName',
  label: 'Sobrenome do passageiro.'
}, {
  value: 'flightPaxFullName',
  label: 'Nome completo do passageiro.'
}, {
  value: 'flightTicketNum',
  label: 'Número do bilhete.'
}, {
  value: 'flightTicketIssueDate',
  label: 'Data da emissão do bilhete: YYYY-MM-DD.'
}, {
  value: 'flightIataTravelAgencyCode',
  label: 'Código Iata da agência de viagem (8 caracteres numéricos).'
}, {
  value: 'flightTravelAgencyName',
  label: 'Nome da agência de viagem.'
}, {
  value: 'flightTotalFareValue',
  label: 'Valor da tarifa (soma de todos os voos)'
}, {
  value: 'flightTotalFareCurrencyCode',
  label: 'Moeda da tarifa. Código alpha padrão ISO 4217'
}, {
  value: 'flightTotalFeeValue',
  label: 'Valor do fee (soma de todos os voos)'
}, {
  value: 'flightTotalFeeCurrencyCode',
  label: 'Moeda do fee. Código alpha padrão ISO 4217'
}, {
  value: 'flightTotalTaxValue',
  label: 'Valor da taxa de embarque (soma de todos os voos)'
}, {
  value: 'flightTotalTaxCurrencyCode',
  label: 'Moeda da taxa. Código alpha padrão ISO 4217'
}, {
  value: 'flightLegsDescription',
  label: 'Trecho completo do bilhete, exemplo: POA/GRU/SSA/REC/GRU/POA'
}, {
  value: 'flightIsDomestic',
  label: 'Indicador de bilhete doméstico: true ou false.'
}, {
  value: 'flightPnrRecordLocator',
  label: 'Código localizador da reserva do bilhete.'
}, {
  value: 'flightFinalDestinationCity',
  label: 'Código Iata da cidade de destino do bilhete (3 caracteres alpha).'
}, {
  value: 'flightTmcIsMerchant',
  label: 'Indicador se a agência de viagem é o estabelecimento: true ou false.'
}, {
  value: 'flightStartDate',
  label: 'Data da partida do primeiro voo'
}, {
  value: 'flightEndDate',
  label: 'Data da partida do último voo'
}, {
  value: 'flightComments',
  label: 'Comentários'
}, {
  value: 'flightCustomField1',
  label: 'Campo customizado 1'
}, {
  value: 'flightCustomField2',
  label: 'Campo customizado 2'
}, {
  value: 'flightCustomField3',
  label: 'Campo customizado 3'
}, {
  value: 'flightCustomField4',
  label: 'Campo customizado 4'
}, {
  value: 'flightCustomField5',
  label: 'Campo customizado 5'
}, {
  value: 'rentalAgreement',
  label: 'Número do contrato de locação.'
}, {
  value: 'rentalLocator',
  label: 'Código localizador da reserva de locação.'
}, {
  value: 'rentalCompanyName',
  label: 'Nome do fornecedor.'
}, {
  value: 'rentalCompanyVat',
  label: 'CNPJ do fornecedor.'
}, {
  value: 'rentalCompanyPhone',
  label: 'Número do telefone do fornecedor.'
}, {
  value: 'rentalCompanyEmail',
  label: 'Endereço de e-mail do fornecedor.'
}, {
  value: 'rentalCompanyAddress',
  label: 'Endereço do fornecedor.'
}, {
  value: 'rentalCompanyZipCode',
  label: 'CEP do endereço.'
}, {
  value: 'rentalCompanyDistrict',
  label: 'Nome do bairro do endereço.'
}, {
  value: 'rentalCompanyState',
  label: 'Estado (UF) do endereço.'
}, {
  value: 'rentalCompanyCity',
  label: 'Cidade do endereço.'
}, {
  value: 'rentalCompanyCountryCode',
  label: 'Código do país (ISO 3166).'
}, {
  value: 'rentalRenterName',
  label: 'Nome do usuário da locação.'
}, {
  value: 'rentalRenterSurname',
  label: 'Sobrenome do usuário da locação.'
}, {
  value: 'rentalRenterFullName',
  label: 'Nome completo do usuário da locação.'
}, {
  value: 'rentalVehicleClass',
  label: 'Nome da categoria do veículo.'
}, {
  value: 'rentalVehicleModel',
  label: 'Modelo do Veículo'
}, {
  value: 'rentalVehicleDescription',
  label: 'Descrição do veículo da locação.'
}, {
  value: 'rentalVehicleColor',
  label: 'Cor do veículo'
}, {
  value: 'rentalVehicleLicense',
  label: 'Placa do veículo'
}, {
  value: 'rentalPickupDate',
  label: 'Data e hora da locação. YYYY-MM-DDTHH:MM:SSZ.'
}, {
  value: 'rentalPickupCity',
  label: 'Código da cidade onde o veículo será locado.'
}, {
  value: 'rentalPickupCountryCode',
  label: 'Código do país (ISO 3166).'
}, {
  value: 'rentalPickupLocation',
  label: 'Local onde o veículo será retirado.'
}, {
  value: 'rentalDropoffDate',
  label: 'Data e hora da devolução. YYYY-MM-DDTHH:MM:SSZ.'
}, {
  value: 'rentalDropoffCity',
  label: 'Código da cidade onde o veículo será devolvido.'
}, {
  value: 'rentalDropoffCountryCode',
  label: 'Código do país (ISO 3166).'
}, {
  value: 'rentalDropoffLocation',
  label: 'Local onde o veículo será devolvido.'
}, {
  value: 'rentalPeriodType',
  label: 'Tipo de período da locação. Valores possíveis: D = Diário W = Semanal M = Mensal.'
}, {
  value: 'rentalTotalPriceValue',
  label: 'Valor total da tarifa da locação, excluindo taxas.'
}, {
  value: 'rentalTotalPriceCurrencyCode',
  label: 'Moeda da tarifa. Código alpha padrão ISO 4217'
}, {
  value: 'rentalTaxesValue',
  label: 'Valor total das taxas da locação.'
}, {
  value: 'rentalTaxesCurrencyCode',
  label: 'Moeda da taxa. Código alpha padrão ISO 4217'
}, {
  value: 'rentalComments',
  label: 'Comentários'
}, {
  value: 'rentalCustomField1',
  label: 'Campo customizado 1'
}, {
  value: 'rentalCustomField2',
  label: 'Campo customizado 2'
}, {
  value: 'rentalCustomField3',
  label: 'Campo customizado 3'
}, {
  value: 'rentalCustomField4',
  label: 'Campo customizado 4'
}, {
  value: 'rentalCustomField5',
  label: 'Campo customizado 5'
}, {
  value: 'tags',
  label: 'Marcadores'
}, {
  value: 'tag_costCenter',
  label: 'Marcador - Centro de Custo'
}, {
  value: 'tag_project',
  label: 'Marcador - Projeto'
}, {
  value: 'tag_accountingAccount',
  label: 'Marcador - Conta Contábil'
}, {
  value: 'tag_reasonForTravelling',
  label: 'Marcador - Motivo de viagem'
}, {
  value: 'tag_costCenter_ext',
  label: 'Marcador - Centro de Custo (código externo)'
}, {
  value: 'tag_project_ext',
  label: 'Marcador - Projeto (código externo)'
}, {
  value: 'tag_accountingAccount_ext',
  label: 'Marcador - Conta Contábil (código externo)'
}, {
  value: 'tag_reasonForTravelling_ext',
  label: 'Marcador - Motivo de viagem (código externo)'
}];
export const credentialTypeDescriptions = credentialTypesOptions.reduce((acc, v) => ({
  ...acc,
  [v.value]: v.label
}), {});
const getProxyRateScopeFields = (scopeType: string, groupName: string): Array<PartialField<any, Credential>> => {
  const shouldRender = i => i[`proxy${scopeType}FilterEnabled`] && 'OMNI' === i.type && i.proxyUsername;
  return [{
    custom_groupName: groupName,
    label: 'Tipo de Tarifa',
    name: (`proxy${scopeType}RateType` as keyof Credential),
    default: null,
    type: 'textarray',
    options: [{
      label: 'Net',
      value: 'NET'
    }, {
      label: 'Comissionada',
      value: 'COMM'
    }],
    custom_withNullSelector: true,
    custom_nullSelectorDeselectValue: [],
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => shouldRender(i) ? i.proxyUsername : false
  }, {
    custom_groupName: groupName,
    label: 'Valor mínimo de comissão',
    name: (`proxy${scopeType}MinimumCommission` as keyof Credential),
    default: null,
    type: 'float',
    inputType: 'percentage',
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => shouldRender(i) && i[`proxy${scopeType}RateType`] && i[`proxy${scopeType}RateType`].includes('COMM') ? i.proxyUsername : false
  }, {
    custom_groupName: groupName,
    label: 'Tipo de Pagamento Fornecedor',
    name: (`proxy${scopeType}PaymentType` as keyof Credential),
    default: null,
    type: 'textarray',
    options: [{
      label: 'Faturado',
      value: 'OFF_FAT'
    }, {
      label: 'Antecipado',
      value: 'OFF_ANT'
    }, {
      label: 'Pré-Pagamento',
      value: 'OFF_PRE'
    }, {
      label: 'Cartão de Crédito',
      value: 'OFF_CC'
    }, {
      label: 'Direto no Hotel',
      value: 'DIR'
    }],
    custom_withNullSelector: true,
    custom_nullSelectorDeselectValue: [],
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => shouldRender(i) ? i.proxyUsername : false
  }, {
    custom_groupName: groupName,
    label: 'Tipo de Política de Cancelamento',
    name: (`proxy${scopeType}CancelPolicy` as keyof Credential),
    default: null,
    type: 'textarray',
    options: [{
      label: 'Grátis',
      value: 'NORMAL'
    }, {
      label: 'Em penalidade',
      value: 'PENALTY'
    }, {
      label: 'Não reembolsável',
      value: 'NONREFUNDABLE'
    }],
    custom_withNullSelector: true,
    custom_nullSelectorDeselectValue: [],
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => shouldRender(i) ? i.proxyUsername : false
  }, {
    custom_groupName: groupName,
    label: 'Remover tarifas sem café',
    name: (`proxy${scopeType}RemoveRatePlanWithoutBreakfast` as keyof Credential),
    default: null,
    type: 'boolean',
    custom_withNullSelector: true,
    custom_nullSelectorDeselectValue: false,
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => shouldRender(i) ? i.proxyUsername : false
  }];
};
export default fillCrudMetadataGaps<Credential>({
  label: 'Credencial',
  labelPlural: 'Credenciais',
  name: 'Credential',
  insertable: true,
  updatable: true,
  deletable: false,
  authorization: authorization('master'),
  'custom:sortFields': ['type', 'alias'],
  columnNames: ['id', 'type', 'client_id', 'alias', 'description', 'usage', 'enabled'],
  dynamoDB: {
    tableName: 'SpearCredential'
  },
  custom_withAudit: true,
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    render: false,
    id: true,
    'custom:shallowCredential': true
  }, {
    name: 'type',
    label: 'Conteúdo',
    required: true,
    updatable: false,
    default: 'OMNI',
    options: credentialTypesOptions,
    inputType: 'select',
    'custom:shallowCredential': true
  }, {
    name: 'client_id',
    label: 'Cliente',
    updatable: false,
    render: i => {
      return i.client_id ? <a target="_blank" rel="noreferrer" href={'/clients/' + i.client_id + '/credentials'}>
            Cliente
          </a> : <span>-</span>;
    },
    'custom:shallowCredential': true
  }, {
    name: 'alias',
    label: 'Apelido',
    required: true,
    hint: 'Esse texto vai aparecer como lembretes em outras telas e relatórios.',
    'custom:shallowCredential': true
  }, {
    name: 'description',
    label: 'Descrição',
    'custom:shallowCredential': true
  }, {
    name: 'merchantAccountId',
    label: 'ID da conta',
    hint: 'Merchant Account ID',
    renderForm: i => i.type === 'BRTR'
  }, {
    name: 'username',
    label: 'Login',
    required: true,
    updatable: false,
    hint: 'Login, Username, Código de Convênio, etc. O identificador da sua conta no conteúdo selecionado.'
  }, {
    name: 'password',
    label: 'Senha',
    renderForm: false,
    custom_excludeFromAuditValue: true,
    render: e => e.password?.substring(0, 4).concat('*'.repeat(e?.password?.length - 4))
  }, {
    name: 'secretEncryptedPassword',
    label: 'Senha',
    inputType: 'InputEncryptedField',
    custom_plainField: 'password',
    custom_excludeFromAuditValue: true
  }, {
    name: 'decimalPlaces',
    label: 'Quantidade de casas decimais',
    hint: 'A integração de fidelidade não suporta valores em ponto flutuante. Configure as casas decimais para ajustar valores enviados ao fornecedor, por exemplo, com configuração de 2 casas decimais, 1.12 no Niara será enviado como 112 ao fornecedor.',
    default: null,
    showNullOption: true,
    type: 'int',
    min: 0,
    max: 2,
    renderForm: i => 'PTOS' === i.type,
    conditionalRequired: i => 'PTOS' === i.type
  }, {
    name: 'publicKey',
    label: 'Chave pública',
    renderForm: i => i.type === 'BRTR' || i.type === 'MCPG'
  }, {
    name: 'clientKey',
    label: 'Client Key',
    renderForm: i => i.type === 'ADYE'
  }, {
    name: 'environment',
    label: 'Ambiente',
    renderForm: i => i.type === 'ADYE'
  }, {
    name: 'gdsPCC',
    label: 'PCC',
    renderForm: i => ['SABR'].includes(i.type) ? JSON.stringify(i.gdsPCC) : false
  }, {
    name: 'accessKey',
    label: 'Chave de Acesso Trend/ShopHotel',
    renderForm: i => i.type && ['TRND', 'SHOP'].includes(i.type) ? i.accessKey : false
  }, {
    name: 'adyenHmacKey',
    label: 'Chave HMAC',
    renderForm: i => i.type && ['ADYE'].includes(i.type) ? true : false
  }, {
    name: 'adyenBasicUsername',
    label: 'Usuário autenticação básica',
    renderForm: i => i.type && ['ADYE'].includes(i.type) ? true : false
  }, {
    name: 'adyenBasicPassword',
    label: 'Senha autenticação básica',
    renderForm: i => i.type && ['ADYE'].includes(i.type) ? true : false
  }, {
    name: 'requestorId',
    label: 'Ponto de Venda',
    renderForm: i => 'B2BR' === i.type ? i.requestorId : false
  }, {
    name: 'wintourToken',
    label: 'Token de integração Webservice',
    renderForm: i => 'WINT' === i.type ? i.wintourToken : false,
    hint: 'Usado para verificar limite de crédito'
  }, {
    name: 'wintourPin',
    label: 'Pin de integração Webservice',
    renderForm: i => 'WINT' === i.type ? i.wintourPin : false,
    hint: 'Usado para exportar reservas'
  }, {
    name: 'wintourEmissor',
    label: 'Emissor fixo',
    renderForm: i => 'WINT' === i.type ? i.wintourEmissor : false,
    hint: 'Usado para fixar o emissor em todas as reservas'
  }, {
    name: 'wintourPassangerAsClient',
    label: 'Passageiro como cliente',
    type: 'boolean',
    renderForm: i => 'WINT' === i.type ? i.wintourPassangerAsClient : false,
    hint: 'Usado para mandar o passageiro como cliente'
  }, {
    name: 'credentialTrendType',
    label: 'Tipo de credencial Trend/ShopHotel',
    // required: true,
    default: 'NET',
    options: [{
      value: 'NET',
      label: 'Tarifas Net'
    }, {
      value: 'COMM',
      label: 'Tarifas comissionado'
    }],
    renderForm: i => i.type && ['TRND', 'SHOP'].includes(i.type) ? i.credentialTrendType : false
  }, {
    name: 'trendNationalCommission',
    label: 'Comissão Nacional padrão da Trend/ShopHotel',
    type: 'float',
    inputType: 'percentage',
    hint: 'Esse valor deve ser informado pelo seu representante da Trend',
    renderForm: i => i.type && ['TRND', 'SHOP'].includes(i.type) && i.credentialTrendType === 'COMM' ? i.trendNationalCommission : false
  }, {
    name: 'trendIntenationalCommission',
    label: 'Comissão Internacional padrão da Trend/ShopHotel',
    type: 'float',
    inputType: 'percentage',
    hint: 'Esse valor deve ser informado pelo seu representante da Trend',
    renderForm: i => i.type && ['TRND', 'SHOP'].includes(i.type) && i.credentialTrendType === 'COMM' ? i.trendIntenationalCommission : false
  }, {
    name: 'expediaPointOfSale',
    label: 'Ponto de Venda',
    renderForm: i => 'EXPD' === i.type ? i.expediaPointOfSale : false,
    conditionalRequired: i => 'EXPD' === i.type
  }, {
    name: 'expediaPaymentTerms',
    label: 'Termos de Pagamento',
    renderForm: i => 'EXPD' === i.type ? i.expediaPaymentTerms : false,
    conditionalRequired: i => 'EXPD' === i.type
  }, {
    name: 'currencyCode',
    label: 'Moeda',
    options: [{
      value: 'BRL',
      label: 'BRL'
    }, {
      value: 'USD',
      label: 'USD'
    }, {
      value: 'EUR',
      label: 'EUR'
    }],
    renderForm: i => ['EXPD', 'WBDS'].includes(i.type) ? i.currencyCode : false,
    conditionalRequired: i => ['EXPD', 'WBDS'].includes(i.type)
  }, {
    name: 'expediaMemberRateEnabled',
    label: 'Tarifa Membro',
    default: false,
    type: 'boolean',
    renderForm: i => 'EXPD' === i.type,
    conditionalRequired: i => 'EXPD' === i.type
  }, {
    name: 'expediaSalesChannel',
    label: 'Canal',
    default: 'AGENT_TOOL',
    options: [{
      value: 'WEBSITE',
      label: 'Website'
    }, {
      value: 'AGENT_TOOL',
      label: 'Agent Tool'
    }, {
      value: 'MOBILE_APP',
      label: 'App Mobile'
    }, {
      value: 'MOBILE_WEB',
      label: 'Web Mobile'
    }, {
      value: 'META',
      label: 'Metadata'
    }],
    renderForm: i => 'EXPD' === i.type ? i.expediaSalesChannel : false,
    conditionalRequired: i => 'EXPD' === i.type
  }, {
    name: 'expediaSalesEnvironment',
    label: 'Ambiente',
    default: 'HOTEL_ONLY',
    options: [{
      value: 'HOTEL_ONLY',
      label: 'Standalone'
    }, {
      value: 'HOTEL_PACKAGE',
      label: 'Package'
    }, {
      value: 'LOYALTY',
      label: 'Loyalty'
    }],
    renderForm: i => 'EXPD' === i.type ? i.expediaSalesEnvironment : false,
    conditionalRequired: i => 'EXPD' === i.type
  }, {
    name: 'expediaPayment',
    label: 'Pagamento',
    default: 'EXPEDIA_COLLECT',
    options: [{
      value: 'EXPEDIA_COLLECT',
      label: 'Expedia Collect'
    }, {
      value: 'PROPERTY_COLLECT',
      label: 'Property Collect'
    }, {
      value: 'ALL',
      label: 'Todos'
    }],
    renderForm: i => 'EXPD' === i.type && 'HOTEL_ONLY' === i.expediaSalesEnvironment ? i.expediaPayment : false,
    conditionalRequired: i => 'EXPD' === i.type && 'HOTEL_ONLY' === i.expediaSalesEnvironment,
    onEntityChange: function (oldVersion, newVersion) {
      if (newVersion.type != 'EXPD' || newVersion?.expediaSalesEnvironment === oldVersion?.expediaSalesEnvironment || newVersion?.expediaSalesEnvironment === 'HOTEL_ONLY') return newVersion;else {
        return {
          ...newVersion,
          expediaPayment: 'EXPEDIA_COLLECT'
        };
      }
    }
  }, {
    name: 'rexturBranchId',
    label: 'Id do Canal',
    renderForm: i => 'REXT' === i.type ? i.rexturBranchId : false
  }, {
    name: 'rexturAgencyId',
    label: 'Id da Agência',
    renderForm: i => 'REXT' === i.type ? i.rexturAgencyId : false
  }, {
    name: 'rexturGroupId',
    label: 'Id do Grupo',
    renderForm: i => 'REXT' === i.type ? i.rexturGroupId : false
  }, {
    name: 'rexturPackageGroup',
    label: 'Cód. do Pacote',
    renderForm: i => 'REXT' === i.type ? i.rexturPackageGroup : false
  }, {
    name: 'restelAffiliation',
    label: 'Afiliação (afil)',
    renderForm: i => 'RSTL' === i.type
  }, {
    name: 'restelAccessSequence',
    label: 'Sequência de Acesso (secacc)',
    renderForm: i => 'RSTL' === i.type
  }, {
    name: 'restelUserCode',
    label: 'Cód. do Usuário (codigousu)',
    renderForm: i => 'RSTL' === i.type
  }, {
    name: 'webbedsCompanyCode',
    label: 'Cód. da Empresa',
    renderForm: i => 'WBDS' === i.type
  }, {
    name: 'endpoint',
    label: 'Endpoint'
  }, {
    name: 'queryEndpoint',
    label: 'Endpoint de consulta',
    renderForm: i => 'BRPG' === i.type ? i.queryEndpoint : false
  }, {
    name: 'timeout',
    label: 'Timeout (segundos)',
    type: 'float',
    default: 10.0,
    step: 0.5,
    min: 2.5,
    max: 30.0,
    hint: 'Quanto tempo até considerar que o sistema externo está com problema?'
  }, {
    name: 'engineRuleVersion',
    label: 'Motor de Regras',
    type: 'int',
    default: 0,
    renderForm: i => i.type === 'NIAR'
  }, {
    name: 'pixDelayToAutoCancel',
    label: 'Validade do QrCode (em minutos)',
    default: 15,
    type: 'int',
    step: 1,
    min: 1,
    max: 180,
    hint: 'Tempo de espera em minutos para o consumidor aprovar o pagamento. Vencido esse tempo, uma chamada para cancelamento do pagamento é feita automaticamente.',
    renderForm: i => 'ITAU' === i.type
  }, {
    name: 'pixDelayToSettlement',
    label: 'Tempo de liquidação da compra (em minutos)',
    default: 60,
    type: 'int',
    step: 1,
    min: 5,
    max: 1440,
    hint: 'O tempo de espera para a liquidação, especificado em minutos.',
    renderForm: i => 'ITAU' === i.type
  }, {
    name: 'pixKey',
    label: 'Chave Pix',
    hint: 'A chave pix da conta. Usada no webhook itau.',
    renderForm: i => 'ITAU' === i.type
  }, {
    name: 'wintourDepartament',
    label: 'Departamento',
    type: 'textarray',
    tagGroup: 'tagGroup',
    renderForm: i => 'WINT' === i.type ? i.wintourDepartament : false
  }, {
    name: 'wintourCostCenter',
    label: 'Centro de Custo',
    type: 'textarray',
    tagGroup: 'tagGroup',
    renderForm: i => 'WINT' === i.type ? i.wintourCostCenter : false
  }, {
    name: 'wintourProject',
    label: 'Projeto',
    type: 'textarray',
    tagGroup: 'tagGroup',
    renderForm: i => 'WINT' === i.type ? i.wintourProject : false
  }, {
    name: 'wexCardHolderPolicy',
    label: 'Política de nome do cartão',
    // required: true,
    default: 'PAX',
    options: [{
      value: 'PAX',
      label: 'Nome do viajante'
    }, {
      value: 'FIX',
      label: 'Nome fixo'
    }],
    renderForm: i => VCN.includes(i.type) ? i.wexCardHolderPolicy : false
  }, {
    name: 'wexCardHolderName',
    label: 'Nome do cartão',
    hint: 'Nome utilizado em todos os VCNs gerados. Caracteres especiais ou numéricos serão ignorados.',
    renderForm: i => VCN.includes(i.type) && i.wexCardHolderPolicy === 'FIX' ? i.wexCardHolderName : false
  }, {
    name: 'wex_udfmap',
    label: 'UDFs',
    type: 'textarray',
    options: metadataOptions,
    inputType: 'select',
    deprecated: true,
    render: false
  }, {
    name: 'mastercardDataSourceId',
    label: 'Id do DataSource',
    renderForm: i => 'MSCD' === i.type ? i.mastercardDataSourceId : false
  }, {
    name: 'mastercardRcnId',
    label: 'Id do RCN',
    renderForm: i => 'MSCD' === i.type ? i.mastercardRcnId : false
  }, {
    name: 'mastercardRcnAlias',
    label: 'Alias do RCN',
    renderForm: i => 'MSCD' === i.type ? i.mastercardRcnAlias : false
  }, {
    name: 'mastercardTemplateId',
    label: 'Id do Template',
    renderForm: i => 'MSCD' === i.type ? i.mastercardTemplateId : false
  }, {
    name: 'mastercardProductType',
    label: 'Produto associado',
    default: 'HOTEL',
    options: [{
      value: 'HOTEL',
      label: 'Hotel'
    }, {
      value: 'FLIGHT',
      label: 'Aéreo'
    }, {
      value: 'RENTAL',
      label: 'Aluguel de Carro'
    }],
    renderForm: i => 'MSCD' === i.type ? i.mastercardProductType : false
  }, {
    name: 'vcnMetadata',
    label: 'Campos Gerenciais',
    type: 'objectarray',
    graphQLType: '[NestedVcnMetadata]',
    graphQLInputType: '[NestedVcnMetadataInput]',
    fields: [{
      name: 'name',
      label: 'Nome',
      type: 'text',
      required: true
    }, {
      name: 'value',
      label: 'Valor',
      inputType: 'select',
      options: [({
        label: '############ ORDEM ###############',
        value: undefined,
        disabled: true
      } as any)].concat(metadataOptions.filter(i => i.value.startsWith('order'))).concat({
        label: '############ HOTEL ################',
        value: undefined,
        disabled: true
      }).concat(metadataOptions.filter(i => i.value.startsWith('hotel'))).concat({
        label: '############ AÉREO ################',
        value: undefined,
        disabled: true
      }).concat(metadataOptions.filter(i => i.value.startsWith('flight'))).concat({
        label: '############ RENTAL ################',
        value: undefined,
        disabled: true
      }).concat(metadataOptions.filter(i => i.value.startsWith('rental'))).concat({
        label: '############ TAGS ################',
        value: undefined,
        disabled: true
      }).concat(metadataOptions.filter(i => i.value.startsWith('tag')))
    }, {
      name: 'isCustom',
      label: 'Valor Fixo',
      type: 'boolean',
      default: false
    }, {
      name: 'customValue',
      label: 'Valor'
    }],
    default: null,
    renderForm: i => ['MSCD', 'INGN'].includes(i.type) ? JSON.stringify(i.vcnMetadata) : false,
    onEntityChange: function (oldVersion, newVersion) {
      if (newVersion?.type === oldVersion?.type) return newVersion;else {
        return {
          ...newVersion,
          vcnMetadata: []
        };
      }
    }
  }, {
    name: 'omniPCC',
    label: 'Código Cidade Pseudo',
    default: null,
    renderForm: i => i.type === 'OMNI'
  }, {
    name: 'omnibeesFixedEmail',
    label: 'Substituição de e-mail no envio',
    default: null,
    renderForm: i => i.type === 'OMNI'
  }, {
    name: 'omniFlex',
    label: 'Flexível',
    default: false,
    type: 'boolean',
    renderForm: i => i.type === 'OMNI'
  }, {
    name: 'usage',
    label: 'Uso da credencial',
    options: [{
      label: 'Reservas',
      value: 'default'
    }, {
      label: 'Apenas importação',
      value: 'importOnly'
    }],
    default: 'default',
    // required: true,
    renderForm: i => 'OMNI' === i.type ? i.usage : false,
    'custom:shallowCredential': true
  }, {
    name: 'automaticImportEnabled',
    label: 'Importação automática',
    default: false,
    type: 'boolean',
    hint: 'Importa reservas periodicamente',
    renderForm: function (i) {
      return i.type && 'OMNI' === i.type ? this.render(i) : false;
    },
    custom_checkTenant: t => t && t.automaticImportEnabled
  }, {
    name: 'braspagProviderCredentials',
    label: 'Credenciais de Adquirentes',
    type: 'objectarray',
    custom_maxLength: 1,
    graphQLType: '[CredentialProvider]',
    graphQLInputType: '[CredentialProviderInput]',
    fields: [{
      name: 'provider',
      label: 'Adquirente',
      required: true,
      type: 'text',
      inputType: 'select',
      options: [{
        value: 'CIELO',
        label: 'Cielo 3.0'
      }, {
        value: 'CIELO15',
        label: 'Cielo 1.5'
      }, {
        value: 'REDE',
        label: 'Rede'
      }, {
        value: 'GETNET',
        label: 'GetNet'
      }, {
        value: 'GLOBALPAY',
        label: 'Global Payments'
      }, {
        value: 'STONE',
        label: 'Stone'
      }, {
        value: 'SAFRA',
        label: 'Safra'
      }, {
        value: 'SAFRA2',
        label: 'Safra 2.0'
      }, {
        value: 'FIRSTDATA',
        label: 'First Data'
      }, {
        value: 'TEST',
        label: 'Simulado'
      }, {
        value: 'PAGSEGURO',
        label: 'PagSeguro'
      }]
    }, {
      name: 'useGatewayConfig',
      label: 'Configurado no Gateway',
      type: 'boolean',
      default: true,
      hint: 'Credencial cadastrada no gateway. Campos restantes serão ignorados.'
    }, {
      name: 'code',
      label: 'Código',
      default: null
    }, {
      name: 'key',
      label: 'Chave',
      default: null
    }, {
      name: 'username',
      label: 'Login (GetNet)',
      default: null
    }, {
      name: 'password',
      label: 'Senha (GetNet)',
      default: null
    }, {
      name: 'signature',
      label: 'Assinatura (GlobalPay)',
      default: null
    }],
    renderForm: i => 'BRPG' === i.type ? i.usage : false
  }, {
    name: 'braspagSoftDescriptorPolicy',
    label: 'Descritor no cartão',
    inputType: 'select',
    default: 'NONE',
    options: [{
      value: 'NONE',
      label: 'Sem descritor'
    }, {
      value: 'PRODUCT_NAME',
      label: 'Nome do Hotel'
    }],
    renderForm: i => 'BRPG' === i.type ? i.braspagSoftDescriptorPolicy : false,
    hint: 'Descrição (soft descriptor) da compra enviada ao cartão. Pode exigir habilitação junto ao adquirente.'
  }, {
    name: 'braspagNiaraGateway',
    label: 'Gateway Niara',
    default: false,
    type: 'boolean',
    renderForm: i => 'BRPG' === i.type,
    hint: 'Marcar caso credencial vinculada à conta master da Niara. Será utilizado o Cartão Protegido da conta master.',
    deprecated: true
  }, {
    custom_groupName: "Cartão Protegido",
    name: 'braspagCardTokenEnabled',
    label: 'Usar Cartão Protegido',
    type: 'boolean',
    default: false,
    renderForm: i => 'BRPG' === i.type && !i.braspagNiaraGateway,
    deprecated: true
  }, {
    custom_groupName: "Cartão Protegido",
    name: 'braspagCardTokenEndpoint',
    label: 'Endpoint Operação',
    required: false,
    renderForm: i => 'BRPG' === i.type && i.braspagCardTokenEnabled ? i.braspagCardTokenEndpoint : false,
    deprecated: true
  }, {
    custom_groupName: "Cartão Protegido",
    name: 'braspagCardTokenAuthEndpoint',
    label: 'Endpoint Autenticação',
    required: false,
    renderForm: i => 'BRPG' === i.type && i.braspagCardTokenEnabled ? i.braspagCardTokenAuthEndpoint : false,
    deprecated: true
  }, {
    custom_groupName: "Cartão Protegido",
    name: 'braspagCardTokenClientId',
    label: 'Client ID',
    required: false,
    renderForm: i => 'BRPG' === i.type && i.braspagCardTokenEnabled ? i.braspagCardTokenClientId : false,
    deprecated: true
  }, {
    custom_groupName: "Cartão Protegido",
    name: 'braspagCardTokenClientSecret',
    label: 'Client Secret',
    required: false,
    renderForm: i => 'BRPG' === i.type && i.braspagCardTokenEnabled ? i.braspagCardTokenClientSecret : false,
    deprecated: true
  }, {
    custom_groupName: "Cartão Protegido",
    name: 'braspagCardTokenMerchantId',
    label: 'Merchant ID',
    required: false,
    renderForm: i => 'BRPG' === i.type && i.braspagCardTokenEnabled ? i.braspagCardTokenMerchantId : false,
    deprecated: true
  }, {
    name: 'liveloSiteCode',
    label: 'Site Code',
    renderForm: i => 'LIVE' === i.type
  }, {
    name: 'liveloPaymentProduct',
    label: 'Payment Product',
    renderForm: i => 'LIVE' === i.type
  }, {
    name: 'liveloPointType',
    label: 'Point Type',
    renderForm: i => 'LIVE' === i.type
  }, {
    name: 'ingenicoMerchantId',
    label: 'Merchant id',
    renderForm: i => 'INGN' === i.type
  }, {
    custom_groupName: 'Fidelidade',
    name: 'loyaltyOptInRequired',
    label: 'Requer opt-in para a criação da wallet',
    type: 'boolean',
    renderForm: function (i) {
      return i.type == 'PTOS' ? this.render && this.render(i) : false;
    }
  }, {
    custom_groupName: 'Fidelidade',
    name: 'loyaltyTermFiles',
    label: 'Termos, políticas, regulamentos',
    type: 'objectarray',
    graphQLType: '[NestedLoyaltyTermsFile]',
    graphQLInputType: '[NestedLoyaltyTermsFileInput]',
    hint: 'Links/arquivos que serão colocados na tela de registro de pessoas',
    fields: [{
      name: 'name',
      label: 'Nome',
      hint: 'Termos, condições gerais, regulamento, política de privacidade, etc',
      required: true,
      type: 'text'
    }, {
      name: 'file',
      label: 'Arquivo (PDF)',
      required: false,
      type: 'text',
      inputType: 'inputPublicFile',
      fileType: 'pdf',
      custom_gerarPrefix: i => {
        return 'loyalty/' + i.id + '/terms/';
      }
    }, {
      name: 'url',
      label: 'Link',
      hint: '(ignorado se existir arquivo)',
      required: false,
      type: 'text',
      fileType: 'pdf',
      custom_gerarPrefix: i => {
        return 'loyalty/' + i.id + '/terms/';
      }
    }],
    renderForm: function (i) {
      return i.type == 'PTOS' ? this.render && this.render(i) : false;
    }
  }, {
    custom_groupName: "Marcadores",
    name: 'tags',
    label: 'Marcadores',
    type: 'textarray'
  }, {
    custom_groupName: "Voucher",
    name: 'voucherText',
    label: 'Texto do voucher',
    type: 'textarray',
    inputType: 'textarea',
    hint: 'Inserir texto que será incluído nos vouchers emitidos pelo sistema em reservas emitidas por esta credencial',
    renderForm: i => i.type && CONTENT.includes(i.type) ? i.voucherText : false
  }, {
    custom_groupName: "Informação de cobrança",
    name: 'informationText',
    label: 'Texto de informação de cobrança',
    type: 'textarray',
    inputType: 'textarea',
    hint: 'Inserir texto que será enviado para os fornecedores junto da reserva.',
    renderForm: i => i.type && CONTENT.includes(i.type) ? i.informationText : false
  }, {
    custom_groupName: "Backoffice",
    name: 'externalCode',
    label: 'Código para exportação',
    hint: 'Código do fornecedor da credencial para ser exportado em integrações.',
    renderForm: i => i.type && CONTENT.includes(i.type) ? i.externalCode : false
  }, {
    custom_groupName: "Backoffice",
    name: 'enableExport',
    label: 'Ligar exportação',
    hint: 'O conteúdo gerado nessa credencial será exportado.',
    type: 'boolean',
    renderForm: i => i.type && CONTENT.includes(i.type) ? i.externalCode : false
  }, {
    custom_groupName: "Ativação restrita",
    name: 'restrictContentOption',
    label: 'Conteúdo',
    inputType: 'select',
    default: 'NONE',
    options: [{
      value: 'NONE',
      label: 'Permitir para todo conteúdo'
    }, {
      value: 'NONE_EXCEPT',
      label: 'Restringir alguns hotéis'
    }, {
      value: 'ALL_EXCEPT',
      label: 'Permitir apenas alguns hotéis'
    }],
    renderForm: i => i.type && CONDITIONAL.includes(i.type) ? i.restrictContentOption : false
  }, {
    custom_groupName: "Ativação restrita",
    name: 'restrictContent',
    label: 'Hotéis',
    type: 'objectarray',
    inputType: 'inputHotelToken',
    graphQLType: '[NestedDestination]',
    graphQLInputType: '[NestedDestinationInput]',
    fields: [{
      name: 'id',
      label: 'Id'
    }, {
      name: 'name',
      label: 'Nome'
    }, {
      name: 'type',
      label: 'Tipo'
    }, {
      name: 'city',
      label: 'Cidade'
    }, {
      name: 'state',
      label: 'Estado'
    }, {
      name: 'country',
      label: 'País'
    }],
    renderForm: i => i.restrictContentOption && i.restrictContentOption != 'NONE' ? i.restrictContent : false
  }, {
    custom_groupName: "Ativação restrita",
    name: 'restrictedTagsOption',
    label: 'Marcadores',
    inputType: 'select',
    default: 'NONE',
    options: [{
      value: 'NONE',
      label: 'Liberar para qualquer Marcador'
    }, {
      value: 'NONE_EXCEPT',
      label: 'Restringir algumas Marcadores'
    }, {
      value: 'ALL_EXCEPT',
      label: 'Permitir apenas algumas Marcadores'
    }],
    renderForm: i => i.type && CONDITIONAL.includes(i.type) ? i.restrictedTagsOption : false
  }, {
    custom_groupName: "Ativação restrita",
    name: 'restrictedTags',
    label: 'Marcadores',
    inputType: 'tags',
    type: 'textarray',
    renderForm: i => i.restrictedTagsOption && i.restrictedTagsOption != 'NONE' ? i.restrictedTags : false
  }, {
    custom_groupName: "RD Station Market",
    name: 'rdsm_clientId',
    label: 'Client ID',
    renderForm: i => ['RDSM'].includes(i.type) ? JSON.stringify(i.rdsm_clientId) : false
  }, {
    custom_groupName: "RD Station Market",
    name: 'rdsm_clientSecret',
    label: 'Client Secret',
    renderForm: i => ['RDSM'].includes(i.type) ? JSON.stringify(i.rdsm_clientSecret) : false
  }, {
    custom_groupName: "RD Station Market",
    name: 'rdsm_refreshToken',
    label: 'Refresh Token',
    renderForm: i => ['RDSM'].includes(i.type) ? JSON.stringify(i.rdsm_refreshToken) : false
  },
  // {
  //   custom_groupName: 'i18n:RD Station Market',
  //   name: 'rdsm_crmToken',
  //   label: 'Token do CRM',
  //   renderForm: (i) => (['RDSM'].includes(i.type) ? JSON.stringify(i.rdsm_crmToken) : false),
  // },
  {
    custom_groupName: "Campos Customizados - Contato",
    name: 'rdsm_leadFields',
    label: 'Mapeamento de campos de Lead',
    renderForm: i => ['RDSM'].includes(i.type) ? JSON.stringify(i.rdsm_leadFields) : false,
    type: 'objectarray',
    graphQLType: '[NestedRDStationLeadFields]',
    graphQLInputType: '[NestedRDStationLeadFieldsInput]',
    fields: [{
      name: 'name',
      label: 'Nome',
      type: 'text',
      required: true
    }, {
      name: 'rdsmId',
      label: 'Id RD Station Marketing',
      type: 'text'
    }, {
      name: 'niara',
      label: 'Campo Niara',
      withNullSelector: true,
      inputType: 'select',
      options: [...person_fields
      // ...quotation_fields,
      // ...reservation_fields,
      // ...lostReservation_fields,
      // ...indirect_reservation_fields,
      ]
    }],
    default: [{
      name: 'Identificador',
      niara: 'person.id',
      rdsmId: null
    }]
  }, {
    custom_groupName: "Campos Customizados - Reservas",
    name: 'rdsm_dealFields',
    label: 'Mapeamento de campos de Oportunidade',
    renderForm: i => ['RDSM'].includes(i.type) ? JSON.stringify(i.rdsm_dealFields) : false,
    type: 'objectarray',
    graphQLType: '[NestedRDStationDealFields]',
    graphQLInputType: '[NestedRDStationDealFieldsInput]',
    fields: [{
      name: 'name',
      label: 'Nome',
      type: 'text',
      required: true
    }, {
      name: 'rdsmId',
      label: 'Id RD Station Marketing',
      type: 'text'
    }, {
      name: 'niara',
      label: 'Campo Niara',
      withNullSelector: true,
      inputType: 'select',
      options: [...person_fields, ...quotation_fields, ...reservation_fields, ...lostReservation_fields, ...indirect_reservation_fields]
    }],
    default: null
  }, {
    custom_groupName: "Ativo",
    name: 'enabled',
    label: 'Ativo',
    default: false,
    type: 'boolean',
    'custom:shallowCredential': true
  }, {
    custom_groupName: "Proxy",
    name: 'proxyUsername',
    label: 'Username para o proxy',
    hint: 'Usado para o omnibees-proxy',
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => 'OMNI' === i.type ? i.proxyUsername : false,
    type: 'text'
  }, {
    custom_groupName: "Proxy",
    label: 'Substituir traços do ID da reserva',
    name: 'proxyIdWithoutDashes',
    default: null,
    type: 'boolean',
    hint: 'Atenção: cuidado ao mudar este valor! Ao reservar, envia ao sistema parceiro o ID sem traços, substituindo-os por "-" - necessidade específica da CWT.',
    custom_withNullSelector: true,
    custom_nullSelectorDeselectValue: false,
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => 'OMNI' === i.type && i.proxyUsername ? i.proxyUsername : false
  }, {
    custom_groupName: "Proxy",
    label: 'Markup (apenas para Proxy3)',
    name: 'proxyMarkup',
    default: null,
    type: 'float',
    inputType: 'percentage',
    custom_withNullSelector: true,
    custom_nullSelectorDeselectValue: 0,
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => 'OMNI' === i.type && i.proxyUsername ? i.proxyUsername : false
  }, {
    custom_groupName: "Proxy",
    label: 'Comissão (apenas para Proxy3)',
    name: 'proxyCommission',
    default: null,
    type: 'float',
    inputType: 'percentage',
    custom_withNullSelector: true,
    custom_nullSelectorDeselectValue: 0,
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => 'OMNI' === i.type && i.proxyUsername ? i.proxyUsername : false
  }, {
    custom_groupName: "Proxy",
    label: 'Gerar VCN para reservas com Cartão de Crédito (apenas para Proxy3)',
    name: 'proxyCreateVcn',
    default: null,
    type: 'boolean',
    hint: 'A credencial de VCN deve estar cadastrada no mesmo Cliente que esta credencial ou selecionada abaixo.',
    custom_withNullSelector: true,
    custom_nullSelectorDeselectValue: false,
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => 'OMNI' === i.type && i.proxyUsername ? i.proxyUsername : false
  }, {
    custom_groupName: "Proxy",
    name: 'proxyVcnCredentialId',
    label: 'Credencial para geração de VCN',
    inputType: 'credential',
    credentialTypeList: VCN,
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => 'OMNI' === i.type && i.proxyUsername && i.proxyCreateVcn ? i.proxyUsername : false
  }, {
    custom_groupName: "Proxy",
    label: 'Remover tarifas sem café (apenas para Proxy3)',
    name: 'proxyRemoveRatePlanWithoutBreakfast',
    default: null,
    type: 'boolean',
    custom_withNullSelector: true,
    custom_nullSelectorDeselectValue: false,
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => 'OMNI' === i.type && i.proxyUsername ? i.proxyUsername : false
  }, {
    custom_groupName: "Proxy - Pagamento e Tarifas (apenas para Proxy3)",
    label: 'Tipo de Tarifa',
    name: 'proxyRateType',
    default: null,
    type: 'textarray',
    options: [{
      label: 'Net',
      value: 'NET'
    }, {
      label: 'Comissionada',
      value: 'COMM'
    }],
    custom_withNullSelector: true,
    custom_nullSelectorDeselectValue: [],
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => 'OMNI' === i.type && i.proxyUsername ? i.proxyUsername : false
  }, {
    custom_groupName: "Proxy - Pagamento e Tarifas (apenas para Proxy3)",
    label: 'Valor mínimo de comissão',
    name: 'proxyMinimumCommission',
    default: null,
    type: 'float',
    inputType: 'percentage',
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => 'OMNI' === i.type && i.proxyUsername && i.proxyRateType && i.proxyRateType.includes('COMM') ? i.proxyUsername : false
  }, {
    custom_groupName: "Proxy - Pagamento e Tarifas (apenas para Proxy3)",
    label: 'Tipo de Acordo',
    name: 'proxyRateScope',
    default: null,
    type: 'textarray',
    options: [{
      label: 'Público',
      value: 'PUBLIC'
    }, {
      label: 'Acordo',
      value: 'PRIVATE'
    }],
    custom_withNullSelector: true,
    custom_nullSelectorDeselectValue: [],
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => 'OMNI' === i.type && i.proxyUsername ? i.proxyUsername : false
  }, {
    custom_groupName: "Proxy - Pagamento e Tarifas (apenas para Proxy3)",
    label: 'Tipo de Pagamento Fornecedor',
    name: 'proxyPaymentType',
    default: null,
    type: 'textarray',
    options: [{
      label: 'Faturado',
      value: 'OFF_FAT'
    }, {
      label: 'Antecipado',
      value: 'OFF_ANT'
    }, {
      label: 'Pré-Pagamento',
      value: 'OFF_PRE'
    }, {
      label: 'Cartão de Crédito',
      value: 'OFF_CC'
    }, {
      label: 'Direto no Hotel',
      value: 'DIR'
    }],
    custom_withNullSelector: true,
    custom_nullSelectorDeselectValue: [],
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => 'OMNI' === i.type && i.proxyUsername ? i.proxyUsername : false
  }, {
    custom_groupName: "Proxy - Pagamento e Tarifas (apenas para Proxy3)",
    label: 'Tipo de Política de Cancelamento',
    name: 'proxyCancelPolicy',
    default: null,
    type: 'textarray',
    options: [{
      label: 'Grátis',
      value: 'NORMAL'
    }, {
      label: 'Em penalidade',
      value: 'PENALTY'
    }, {
      label: 'Não reembolsável',
      value: 'NONREFUNDABLE'
    }],
    custom_withNullSelector: true,
    custom_nullSelectorDeselectValue: [],
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => 'OMNI' === i.type && i.proxyUsername ? i.proxyUsername : false
  }, {
    custom_groupName: "Proxy - Filtro por tipo de tarifa (apenas para Proxy3)",
    label: 'Aplicar filtro personalizado para tarifas públicas',
    name: 'proxyPublicFilterEnabled',
    default: null,
    type: 'boolean',
    custom_withNullSelector: true,
    custom_nullSelectorDeselectValue: false,
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => 'OMNI' === i.type && i.proxyUsername ? i.proxyUsername : false
  }, {
    custom_groupName: "Proxy - Filtro por tipo de tarifa (apenas para Proxy3)",
    label: 'Aplicar filtro personalizado para tarifas privadas',
    name: 'proxyPrivateFilterEnabled',
    default: null,
    type: 'boolean',
    custom_withNullSelector: true,
    custom_nullSelectorDeselectValue: false,
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => 'OMNI' === i.type && i.proxyUsername ? i.proxyUsername : false
  }, ...getProxyRateScopeFields('Public', "Proxy - Filtros de tarifa pública (apenas para Proxy3)"), ...getProxyRateScopeFields('Private', "Proxy - Filtros de tarifa privada (apenas para Proxy3)"), {
    custom_groupName: "Proxy - Configuração para Pagamento Fornecedor (apenas para Proxy3)",
    label: 'Aplicar garantia IATA ao pagamento Direto no Hotel',
    hint: 'Se reserva com pagamento Direto no Hotel e cartão de crédito não fornecido como garantia, aplica Garantia IATA. Cliente pagará fornecedor no local do serviço e será usada a garantia IATA da agência.',
    name: 'proxyDirectIata',
    default: null,
    type: 'boolean',
    custom_withNullSelector: true,
    custom_nullSelectorDeselectValue: false,
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => 'OMNI' === i.type && i.proxyUsername ? i.proxyUsername : false
  }, {
    custom_groupName: "Proxy - Configuração para Pagamento Fornecedor (apenas para Proxy3)",
    label: 'Substituir formas de pagamento do fornecedor por Faturado',
    hint: 'Suprime as formas de pagamento da tarifa e apresenta somente a opção Faturado. Necessário configurar as opções aceitas e sua ordem de prioridade para pagamento ao fornecedor.',
    name: 'proxyReplacePaymentTypes',
    default: null,
    type: 'boolean',
    custom_withNullSelector: true,
    custom_nullSelectorDeselectValue: false,
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => 'OMNI' === i.type && i.proxyUsername ? i.proxyUsername : false
  }, {
    custom_groupName: "Proxy - Configuração para Pagamento Fornecedor (apenas para Proxy3)",
    label: 'Formas de pagamento ao fornecedor prioritárias',
    hint: 'Define as formas de pagamento ao fornecedor aceitas e sua ordem de prioridade, para seleção automática a partir das formas de pagamento disponíveis na tarifa.',
    name: 'proxyPriorityPaymentTypes',
    default: null,
    type: 'textarray',
    inputType: 'niara-react-form/inputMultiSelect',
    withOptionSorter: true,
    options: [{
      label: 'Faturado',
      value: 'OFF_FAT'
    }, {
      label: 'Antecipado',
      value: 'OFF_ANT'
    }, {
      label: 'Pré-Pagamento',
      value: 'OFF_PRE'
    }, {
      label: 'Cartão de Crédito',
      value: 'OFF_CC'
    }, {
      label: 'Direto no Hotel',
      value: 'DIR'
    }],
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => 'OMNI' === i.type && i.proxyUsername && i.proxyReplacePaymentTypes ? i.proxyUsername : false
  }, {
    custom_groupName: "Proxy - Configuração para Recebimento (apenas para Proxy3)",
    label: 'Registrar Recebimento Faturado',
    name: 'proxyCreateOffFatPayment',
    default: null,
    type: 'boolean',
    custom_withNullSelector: true,
    custom_nullSelectorDeselectValue: false,
    custom_checkTenant: t => t && t.omnibeesProxyEnabled,
    renderForm: i => 'OMNI' === i.type && i.proxyUsername ? i.proxyUsername : false
  }, {
    custom_groupName: "Autenticação 3DS",
    label: 'Habilitar autenticação 3DS (experimental)',
    hint: 'Por enquanto apenas disponível para link de pagamento do Bee2Pay com Cielo 3.0, nas bandeiras Visa e Mastercard',
    name: 'auth3dsEnabled',
    default: null,
    type: 'boolean',
    renderForm: i => i.type === 'BRPG'
  }, {
    custom_groupName: "Autenticação 3DS",
    label: 'Client Id',
    name: 'braspagAuth3dsClientId',
    default: null,
    type: 'text',
    renderForm: i => i.type === 'BRPG' && i.auth3dsEnabled ? i.braspagAuth3dsClientId : false
  }, {
    custom_groupName: "Autenticação 3DS",
    label: 'Client Secret',
    name: 'braspagAuth3dsClientSecret',
    default: null,
    type: 'text',
    renderForm: i => i.type === 'BRPG' && i.auth3dsEnabled ? i.braspagAuth3dsClientSecret : false
  }, {
    custom_groupName: "Autenticação 3DS",
    label: 'Endpoint',
    name: 'braspagAuth3dsEndpoint',
    default: null,
    type: 'text',
    renderForm: i => i.type === 'BRPG' && i.auth3dsEnabled ? i.braspagAuth3dsEndpoint : false
  }, {
    custom_groupName: "Autenticação 3DS",
    label: 'Código do estabelecimento',
    name: 'braspagAuth3dsEstablishmentCode',
    hint: 'Sub-Merchant ID na Cielo 3.0',
    default: null,
    type: 'text',
    renderForm: i => i.type === 'BRPG' && i.auth3dsEnabled ? i.braspagAuth3dsEndpoint : false
  }, {
    custom_groupName: "Autenticação 3DS",
    label: 'Nome do estabelecimento',
    name: 'braspagAuth3dsMerchantName',
    default: null,
    type: 'text',
    renderForm: i => i.type === 'BRPG' && i.auth3dsEnabled ? i.braspagAuth3dsEndpoint : false
  }, {
    custom_groupName: "Autenticação 3DS",
    label: 'MCC do estabelecimento',
    name: 'braspagAuth3dsMcc',
    hint: 'Código de 4 dígitos',
    pattern: '\\d{4}',
    default: null,
    type: 'text',
    renderForm: i => i.type === 'BRPG' && i.auth3dsEnabled ? i.braspagAuth3dsEndpoint : false
  }, {
    custom_groupName: "Remark",
    name: 'gdsRemarkMetadata',
    label: 'Remarks',
    type: 'objectarray',
    graphQLType: '[NestedGDSRemarkMetadata]',
    graphQLInputType: '[NestedGDSRemarkMetadataInput]',
    fields: [{
      name: 'action',
      label: 'Ação',
      type: 'text',
      inputType: 'select',
      options: [{
        label: 'Leitura',
        value: 'READ'
      }, {
        label: 'Gravação',
        value: 'WRITE'
      }, {
        label: 'Leitura/Gravação',
        value: 'READ_WRITE'
      }],
      required: true
    }, {
      name: 'type',
      label: 'Tipo',
      type: 'text',
      inputType: 'select',
      options: [{
        label: 'Historical (5H)',
        value: 'HISTORICAL'
      }, {
        label: 'General (5)',
        value: 'GENERAL'
      }, {
        label: 'Client Address (5/)',
        value: 'CLIENT_ADDRESS'
      }, {
        label: 'Client Address (5/)',
        value: 'INVOICE'
      }],
      required: true
    }, {
      name: 'name',
      label: 'Nome',
      type: 'text',
      required: true
    }, {
      name: 'value',
      label: 'Valor',
      inputType: 'select',
      options: [({
        label: '############ ORDEM ###############',
        value: undefined,
        disabled: true
      } as any)].concat(metadataOptions.filter(i => i.value.startsWith('order'))).concat({
        label: '############ HOTEL ################',
        value: undefined,
        disabled: true
      }).concat(metadataOptions.filter(i => i.value.startsWith('hotel'))).concat({
        label: '############ AÉREO ################',
        value: undefined,
        disabled: true
      }).concat(metadataOptions.filter(i => i.value.startsWith('flight'))).concat({
        label: '############ RENTAL ################',
        value: undefined,
        disabled: true
      }).concat(metadataOptions.filter(i => i.value.startsWith('rental'))).concat({
        label: '############ TAGS ################',
        value: undefined,
        disabled: true
      }).concat(metadataOptions.filter(i => i.value.startsWith('tag')))
    }, {
      name: 'isCustom',
      label: 'Valor Fixo',
      type: 'boolean',
      default: false
    }, {
      name: 'customValue',
      label: 'Valor'
    }],
    default: null,
    renderForm: i => ['SABR'].includes(i.type) ? JSON.stringify(i.vcnMetadata) : false,
    onEntityChange: function (oldVersion, newVersion) {
      if (newVersion?.type === oldVersion?.type) return newVersion;else {
        return {
          ...newVersion,
          vcnMetadata: []
        };
      }
    }
  }, {
    custom_groupName: "Filas",
    name: 'gdsFailureQueuePCC',
    label: 'Fila de Falha (PCC)',
    renderForm: i => ['SABR'].includes(i.type) ? JSON.stringify(i.gdsFailureQueuePCC) : false
  }, {
    custom_groupName: "Filas",
    name: 'gdsFailureQueue',
    label: 'Fila de Falha',
    renderForm: i => ['SABR'].includes(i.type) ? JSON.stringify(i.gdsFailureQueue) : false
  }, {
    custom_groupName: "Filas",
    name: 'gdsTemporaryQueuePCC',
    label: 'Fila de Tratamento Temporário (PCC)',
    renderForm: i => ['SABR'].includes(i.type) ? JSON.stringify(i.gdsTemporaryQueuePCC) : false
  }, {
    custom_groupName: "Filas",
    name: 'gdsTemporaryQueue',
    label: 'Fila de Tratamento Temporário',
    renderForm: i => ['SABR'].includes(i.type) ? JSON.stringify(i.gdsTemporaryQueue) : false
  }, {
    custom_groupName: "Filas",
    name: 'gdsBackofficePCC',
    label: 'Fila de Backoffice (PCC)',
    renderForm: i => ['SABR'].includes(i.type) ? JSON.stringify(i.gdsBackofficePCC) : false
  }, {
    custom_groupName: "Filas",
    name: 'gdsBackoffice',
    label: 'Fila de Backoffice',
    renderForm: i => ['SABR'].includes(i.type) ? JSON.stringify(i.gdsBackoffice) : false
  }, {
    custom_groupName: "Filas - Reserva",
    name: 'gdsReadQueuePCC',
    label: 'Fila de Leitura (PCC)',
    renderForm: i => ['SABR'].includes(i.type) ? JSON.stringify(i.gdsReadQueuePCC) : false
  }, {
    custom_groupName: "Filas - Reserva",
    name: 'gdsReadQueue',
    label: 'Fila de Leitura',
    renderForm: i => ['SABR'].includes(i.type) ? JSON.stringify(i.gdsReadQueue) : false,
    hint: 'Vai ler essa fila periodicamente e apagar durante o tratamento de reserva.'
  }, {
    custom_groupName: "Filas - Reserva",
    name: 'gdsReadCopyQueuePCC',
    label: 'Fila de Leitura - Cópia (PCC)',
    renderForm: i => ['SABR'].includes(i.type) ? JSON.stringify(i.gdsReadCopyQueuePCC) : false
  }, {
    custom_groupName: "Filas - Reserva",
    name: 'gdsReadCopyQueue',
    label: 'Fila de Leitura - Cópia',
    renderForm: i => ['SABR'].includes(i.type) ? JSON.stringify(i.gdsReadCopyQueue) : false,
    hint: 'Vai tentar remover o PNR dessa fila depois da leitura.'
  }, {
    custom_groupName: "Filas - Reserva",
    name: 'gdsSuccessQueuePCC',
    label: 'Fila de Sucesso (PCC)',
    renderForm: i => ['SABR'].includes(i.type) ? JSON.stringify(i.gdsSuccessQueuePCC) : false
  }, {
    custom_groupName: "Filas - Reserva",
    name: 'gdsSuccessQueue',
    label: 'Fila de Sucesso',
    renderForm: i => ['SABR'].includes(i.type) ? JSON.stringify(i.gdsSuccessQueue) : false
  }, {
    custom_groupName: "Filas - Reserva",
    name: 'gdsSuccessPendingFlightQueuePCC',
    label: 'Fila de Sucesso com aéreo pendente (PCC)',
    renderForm: i => ['SABR'].includes(i.type) ? JSON.stringify(i.gdsSuccessPendingFlightQueuePCC) : false
  }, {
    custom_groupName: "Filas - Reserva",
    name: 'gdsSuccessPendingFlightQueue',
    label: 'Fila de Sucesso com aéreo pendente',
    renderForm: i => ['SABR'].includes(i.type) ? JSON.stringify(i.gdsSuccessPendingFlightQueue) : false
  }, {
    custom_groupName: "Filas - Cancelamento",
    name: 'gdsReadCancellationQueuePCC',
    label: 'Fila de Leitura (PCC)',
    renderForm: i => ['SABR'].includes(i.type) ? JSON.stringify(i.gdsReadCancellationQueuePCC) : false
  }, {
    custom_groupName: "Filas - Cancelamento",
    name: 'gdsReadCancellationQueue',
    label: 'Fila de Leitura',
    renderForm: i => ['SABR'].includes(i.type) ? JSON.stringify(i.gdsReadCancellationQueue) : false
  }, {
    custom_groupName: "Filas - Cancelamento",
    name: 'gdsSuccessCancellationQueuePCC',
    label: 'Fila de Sucesso (PCC)',
    renderForm: i => ['SABR'].includes(i.type) ? JSON.stringify(i.gdsSuccessCancellationQueuePCC) : false
  }, {
    custom_groupName: "Filas - Cancelamento",
    name: 'gdsSuccessCancellationQueue',
    label: 'Fila de Sucesso',
    renderForm: i => ['SABR'].includes(i.type) ? JSON.stringify(i.gdsSuccessCancellationQueue) : false
  }, {
    custom_groupName: "Filas - Cancelamento",
    name: 'gdsSuccessCancellationPendingFlightQueuePCC',
    label: 'Fila de Sucesso com aéreo pendente (PCC)',
    renderForm: i => ['SABR'].includes(i.type) ? JSON.stringify(i.gdsSuccessCancellationPendingFlightQueuePCC) : false
  }, {
    custom_groupName: "Filas - Cancelamento",
    name: 'gdsSuccessCancellationPendingFlightQueue',
    label: 'Fila de Sucesso com aéreo pendente',
    renderForm: i => ['SABR'].includes(i.type) ? JSON.stringify(i.gdsSuccessCancellationPendingFlightQueue) : false
  }, {
    custom_groupName: "Campos Customizados",
    name: 'fidelimaxCategoriaMapping',
    label: 'Mapeamento da categoria do fidelimax',
    renderForm: i => ['FDMX'].includes(i.type) ? JSON.stringify(i.fidelimaxCategoriaMapping) : false
  }, {
    custom_groupName: "Campos Customizados - Lead",
    name: 'sharpSpringLeadFields',
    label: 'Mapeamento de campos de Lead',
    renderForm: i => ['SCRM'].includes(i.type) ? JSON.stringify(i.sharpSpringLeadFields) : false,
    type: 'objectarray',
    graphQLType: '[NestedSharpSpringLeadFields]',
    graphQLInputType: '[NestedSharpSpringLeadFieldsInput]',
    fields: [{
      name: 'name',
      label: 'Nome',
      type: 'text',
      required: true
    }, {
      name: 'sharp',
      label: 'Campo SharpSpring',
      type: 'text'
    }, {
      name: 'niara',
      label: 'Campo Niara',
      withNullSelector: true,
      inputType: 'select',
      options: [...person_fields, ...quotation_fields, ...reservation_fields, ...lostReservation_fields, ...indirect_reservation_fields]
    }],
    default: [{
      name: 'CPF',
      niara: 'person.cpf',
      sharp: null
    }, {
      name: 'Data de Nascimento',
      niara: 'person.dob',
      sharp: null
    }]
  }, {
    custom_groupName: "Campos Customizados - Reservas",
    name: 'sharpSpringReservationFields',
    label: 'Mapeamento de campos de Oportunidade',
    renderForm: i => ['SCRM'].includes(i.type) ? JSON.stringify(i.sharpSpringReservationFields) : false,
    type: 'objectarray',
    graphQLType: '[NestedSharpSpringLeadFields]',
    graphQLInputType: '[NestedSharpSpringLeadFieldsInput]',
    fields: [{
      name: 'name',
      label: 'Nome',
      type: 'text',
      required: true
    }, {
      name: 'sharp',
      label: 'Campo SharpSpring',
      type: 'text'
    }, {
      name: 'niara',
      label: 'Campo Niara',
      withNullSelector: true,
      inputType: 'select',
      options: reservation_fields
    }],
    default: null
  }, {
    custom_groupName: "Campos Customizados - Reservas",
    name: 'sharpSpringReservationStartingStage',
    label: 'Estágio inicial do funil',
    renderForm: i => ['SCRM'].includes(i.type) ? JSON.stringify(i.sharpSpringReservationStartingStage) : false
  }, {
    custom_groupName: "Campos Customizados - Reservas",
    name: 'sharpSpringReservationCancelledStage',
    label: 'Estágio do funil (Cancelado)',
    renderForm: i => ['SCRM'].includes(i.type) ? JSON.stringify(i.sharpSpringReservationCancelledStage) : false
  }, {
    custom_groupName: "Campos Customizados - Cotação",
    name: 'sharpSpringQuotationFields',
    label: 'Mapeamento de campos de Oportunidade (cotação)',
    renderForm: i => ['SCRM'].includes(i.type) ? JSON.stringify(i.sharpSpringQuotationFields) : false,
    type: 'objectarray',
    graphQLType: '[NestedSharpSpringLeadFields]',
    graphQLInputType: '[NestedSharpSpringLeadFieldsInput]',
    fields: [{
      name: 'name',
      label: 'Nome',
      type: 'text',
      required: true
    }, {
      name: 'sharp',
      label: 'Campo SharpSpring',
      type: 'text'
    }, {
      name: 'niara',
      label: 'Campo Niara',
      withNullSelector: true,
      inputType: 'select',
      options: quotation_fields
    }],
    default: null
  }, {
    custom_groupName: "Campos Customizados - Cotação",
    name: 'sharpSpringQuotationStartingStage',
    label: 'Estágio inicial do funil',
    renderForm: i => ['SCRM'].includes(i.type) ? JSON.stringify(i.sharpSpringQuotationStartingStage) : false
  }, {
    custom_groupName: "Campos Customizados - Cotação",
    name: 'sharpSpringQuotationLostStage',
    label: 'Estágio do funil (Perdido)',
    renderForm: i => ['SCRM'].includes(i.type) ? JSON.stringify(i.sharpSpringQuotationLostStage) : false
  }, {
    custom_groupName: "Campos Customizados - Cotação",
    name: 'sharpSpringQuotationWonStage',
    label: 'Estágio do funil (Ganho)',
    renderForm: i => ['SCRM'].includes(i.type) ? JSON.stringify(i.sharpSpringQuotationWonStage) : false
  }, {
    custom_groupName: "Campos Customizados - Reservas Perdidas",
    name: 'sharpSpringLostReservationFields',
    label: 'Mapeamento de campos de Oportunidade',
    renderForm: i => ['SCRM'].includes(i.type) ? JSON.stringify(i.sharpSpringLostReservationFields) : false,
    type: 'objectarray',
    graphQLType: '[NestedSharpSpringLeadFields]',
    graphQLInputType: '[NestedSharpSpringLeadFieldsInput]',
    fields: [{
      name: 'name',
      label: 'Nome',
      type: 'text',
      required: true
    }, {
      name: 'sharp',
      label: 'Campo SharpSpring',
      type: 'text'
    }, {
      name: 'niara',
      label: 'Campo Niara',
      withNullSelector: true,
      inputType: 'select',
      options: lostReservation_fields
    }],
    default: null
  }, {
    custom_groupName: "Campos Customizados - Reservas Perdidas",
    name: 'sharpSpringLostReservationStartingStage',
    label: 'Estágio inicial do funil',
    renderForm: i => ['SCRM'].includes(i.type) ? JSON.stringify(i.sharpSpringLostReservationStartingStage) : false
  }, {
    custom_groupName: "Campos Customizados - Reservas Indiretas",
    name: 'sharpSpringIndirectReservationFields',
    label: 'Mapeamento de campos de Oportunidade',
    renderForm: i => ['SCRM'].includes(i.type) ? JSON.stringify(i.sharpSpringIndirectReservationFields) : false,
    type: 'objectarray',
    graphQLType: '[NestedSharpSpringLeadFields]',
    graphQLInputType: '[NestedSharpSpringLeadFieldsInput]',
    fields: [{
      name: 'name',
      label: 'Nome',
      type: 'text',
      required: true
    }, {
      name: 'sharp',
      label: 'Campo SharpSpring',
      type: 'text'
    }, {
      name: 'niara',
      label: 'Campo Niara',
      withNullSelector: true,
      inputType: 'select',
      options: indirect_reservation_fields
    }],
    default: null
  }, {
    custom_groupName: "Campos Customizados - Reservas Indiretas",
    name: 'sharpSpringIndirectReservationStartingStage',
    label: 'Estágio inicial do funil',
    renderForm: i => ['SCRM'].includes(i.type) ? JSON.stringify(i.sharpSpringIndirectReservationStartingStage) : false
  }, {
    custom_groupName: "Campos Customizados - Reservas Indiretas",
    name: 'sharpSpringIndirectReservationCancelledStage',
    label: 'Estágio do funil (Cancelado)',
    renderForm: i => ['SCRM'].includes(i.type) ? JSON.stringify(i.sharpSpringIndirectReservationCancelledStage) : false
  }, {
    custom_groupName: "Campos Customizados - VCN Bee2Pay",
    name: 'bvcn_hotelCustomField1',
    label: 'Hotel Custom Field 1',
    renderForm: i => ['BVCN'].includes(i.type) ? JSON.stringify(i.bvcn_hotelCustomField1) : false
  }, {
    custom_groupName: "Campos Customizados - VCN Bee2Pay",
    name: 'bvcn_hotelCustomField2',
    label: 'Hotel Custom Field 2',
    renderForm: i => ['BVCN'].includes(i.type) ? JSON.stringify(i.bvcn_hotelCustomField2) : false
  }, {
    custom_groupName: "Campos Customizados - VCN Bee2Pay",
    name: 'bvcn_hotelCustomField3',
    label: 'Hotel Custom Field 3',
    renderForm: i => ['BVCN'].includes(i.type) ? JSON.stringify(i.bvcn_hotelCustomField3) : false
  }, {
    custom_groupName: "Campos Customizados - VCN Bee2Pay",
    name: 'bvcn_hotelCustomField4',
    label: 'Hotel Custom Field 4',
    renderForm: i => ['BVCN'].includes(i.type) ? JSON.stringify(i.bvcn_hotelCustomField4) : false
  }, {
    custom_groupName: "Campos Customizados - VCN Bee2Pay",
    name: 'bvcn_hotelCustomField5',
    label: 'Hotel Custom Field 5',
    renderForm: i => ['BVCN'].includes(i.type) ? JSON.stringify(i.bvcn_hotelCustomField5) : false
  }, {
    custom_groupName: "TSE",
    name: 'tser_alternativeContractEndpoint',
    label: 'Endpoint alternativo de contrato',
    renderForm: i => ['TSER'].includes(i.type) ? JSON.stringify(i.tser_alternativeContractEndpoint) : false
  }, {
    custom_groupName: "TSE",
    name: 'tser_paymentNotification',
    label: 'Notificação de pagamento',
    type: 'boolean',
    renderForm: i => ['TSER'].includes(i.type) ? JSON.stringify(i.tser_paymentNotification) : false
  }, {
    custom_groupName: "TSE",
    name: 'tser_idChargeType',
    label: 'Id do Tipo de Lancamento ',
    renderForm: i => ['TSER'].includes(i.type) ? JSON.stringify(i.tser_idChargeType) : false
  }, {
    custom_groupName: "TSE",
    name: 'tser_idBankAccount',
    label: 'Id da Conta de Movimentação Bancária',
    renderForm: i => ['TSER'].includes(i.type) ? JSON.stringify(i.tser_idBankAccount) : false
  }, {
    custom_groupName: "TSE",
    name: 'tser_creditCardTypeMap',
    label: 'Mapeamento de cartão de crédito',
    type: 'object',
    fields: ['AMEX', 'AURA', 'DINERS', 'ELO', 'HIPER', 'HIPERCARD', 'JCB', 'MASTERCARD', 'VISA', 'DISCOVER'].map(x => {
      return {
        name: x,
        label: x,
        type: 'text'
      };
    }),
    renderForm: i => ['TSER'].includes(i.type) ? JSON.stringify(i.tser_creditCardTypeMap) : false
  }, {
    custom_groupName: "TSE",
    name: 'tser_paymentTypeMap',
    label: 'Mapeamento de meios de pagamento.',
    type: 'object',
    fields: ['CREDITCARD', 'POINTS', 'PIX', 'FAT'].map(x => {
      return {
        name: x,
        label: x,
        type: 'text'
      };
    }),
    renderForm: i => ['TSER'].includes(i.type) ? JSON.stringify(i.tser_paymentTypeMap) : false
  }, {
    custom_groupName: "TSE",
    name: 'tser_hotel_map',
    label: 'Mapeamento de hotéis e quartos.',
    type: 'objectarray',
    hint: 'Definição das propriedades para o sistema TSE.',
    fields: [{
      name: 'omni_id',
      label: 'Id do hotel da Omnibees.',
      type: 'text',
      required: true
    }, {
      name: 'tse_id',
      label: 'Id do hotel da TSE',
      type: 'text',
      required: true
    }, {
      name: 'tser_room_map',
      label: 'Mapeamento de quartos.',
      type: 'objectarray',
      custom_renderFieldsRecursively: true,
      fields: [{
        name: 'tser_room_omni_id',
        label: 'Id do quarto na Omnibees',
        inputType: 'text',
        required: true
      }, {
        name: 'tser_room_tse_id',
        label: 'Id quarto da TSE.',
        type: 'text',
        required: true
      }]
    }],
    renderForm: i => ['TSER'].includes(i.type) ? JSON.stringify(i.tser_paymentTypeMap) : false
  }, {
    custom_groupName: "Desenvolvimento",
    name: 'allowUseInDev',
    label: 'Permitir usar esta credencial no ambiente de desenvolvimento',
    type: 'boolean',
    custom_allowedRole: 'superuser'
  }],
  filter: [{
    name: 'client_id_eq',
    field: 'client_id',
    op: 'eq',
    label: 'Client Id'
  }, {
    name: 'client_id_isNull',
    field: 'client_id',
    op: 'isNull',
    label: 'Client Id Nulo'
  }, {
    name: 'type_eq',
    field: 'type',
    op: 'eq',
    label: 'Conteúdo'
  }, {
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq',
    label: 'Ativo'
  }]
});