import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import tenant from '../tenant';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Instalação',
  labelPlural: 'Instalações',
  name: 'CurrentTenantB2C',
  insertable: false,
  updatable: false,
  deletable: false,
  searchable: false,
  authorization: authorization('unauthenticated'),
  dynamoDB: {
    tableName: 'SpearTenant'
  },
  columnNames: ['tenantId', 'id', 'name', 'url'],
  fields: [...tenant.fields].filter(f => !f['custom:excludeForCurrentTenant'] && !f['custom:excludeForCurrentTenantB2C']).map(r => ({
    ...r,
    updatable: false
  }))
});