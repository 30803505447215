import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Configuração de Fidelidade - Acúmulo',
  labelPlural: 'Configurações de Fidelidade - Acúmulo',
  name: 'LoyaltyConfiguration',
  deletable: false,
  updatable: true,
  insertable: true,
  custom_withAudit: true,
  authorization: authorization('clientConsultant', 'admin'),
  dynamoDB: {
    tableName: 'SpearLoyaltyConfiguration'
  },
  columnNames: ['id', 'name', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    render: false,
    id: true
  }, {
    label: 'Nome',
    name: 'name',
    type: 'text'
  }, {
    label: 'Ativo',
    name: 'enabled',
    type: 'boolean'
  }, {
    custom_groupName: 'Tipo de Fidelidade',
    name: 'loyaltyType',
    label: 'Tipo de fidelidade',
    type: 'text',
    options: [{
      value: 'CASHBACK',
      label: 'Cashback'
    }, {
      value: 'POINTS',
      label: 'Pontos'
    }]
  }, {
    custom_groupName: 'Tipo de Fidelidade',
    name: 'loyaltyCredentialId',
    label: 'Parceiro de fidelidade',
    inputType: 'credential',
    credentialTypeList: ['LIVE', 'ALLP', 'FDMX', 'PTOS'],
    custom_nameField: 'points_loyaltyCredentialName'
  }, {
    custom_groupName: 'Tipo de Fidelidade',
    name: 'points_loyaltyCredentialName',
    label: 'Credencial de fidelidade',
    renderForm: false
  }, {
    custom_groupName: 'Tipo de Fidelidade',
    name: 'costPerPoints',
    label: 'Custo por ponto',
    type: 'float',
    renderForm: function (i) {
      return i.loyaltyType && i.loyaltyType === 'POINTS' ? this.render && this.render(i) : false;
    }
  }, {
    custom_groupName: 'Valor Base',
    label: 'Base',
    name: 'reference',
    type: 'text',
    options: [{
      value: 'RESERVATION',
      label: 'Reserva'
    }, {
      value: 'COMMISSION',
      label: 'Comissão'
    }, {
      value: 'NIGHT',
      label: 'Diárias'
    }]
  }, {
    custom_groupName: 'Valor Base',
    label: '% Base',
    name: 'referenceShare',
    inputType: 'percentage',
    type: 'float'
  }, {
    custom_groupName: 'Valor Base',
    label: 'Permitir acúmulo de pontos + dinheiro',
    name: 'allowRewardWithPoints',
    type: 'boolean',
    default: false
  }, {
    custom_groupName: 'Regra Transferência',
    label: 'Data base',
    name: 'paymentDate',
    type: 'text',
    options: [{
      value: 'RESERVATION',
      label: 'Reserva'
    }, {
      value: 'CHECKIN',
      label: 'Checkin'
    }, {
      value: 'CHECKOUT',
      label: 'Checkout'
    }, {
      value: 'REAL_CHECKOUT',
      label: 'Checkout Realizado'
    }]
  }, {
    custom_groupName: 'Regra Transferência',
    label: 'Dias',
    name: 'cashback_paymentDateOffset',
    type: 'int'
  }, {
    custom_groupName: 'Textos',
    label: 'Texto Destaque',
    name: 'highlightText',
    deprecated: true // Nome não quer dizer nada. Qual a diferença entre informativeText, highlightText e descriptionText? Substituir por campos com nomes que significam alguma coisa. #
  }, {
    custom_groupName: 'Textos',
    label: 'Texto Informativo',
    name: 'informativeText',
    deprecated: true // Nome não quer dizer nada. Qual a diferença entre informativeText, highlightText e descriptionText? Substituir por campos com nomes que significam alguma coisa. #
  }, {
    custom_groupName: 'Textos',
    label: 'Unidade',
    name: 'unitText'
  }, {
    custom_groupName: 'Textos',
    label: 'Nome Promocional',
    name: 'descriptionText',
    deprecated: true // Nome não quer dizer nada. Qual a diferença entre informativeText, highlightText e descriptionText? Substituir por campos com nomes que significam alguma coisa. #
  }, {
    custom_groupName: 'Textos',
    label: 'Logotipo pequeno',
    inputType: 'inputPublicFile',
    name: 'smallLogo',
    fileType: 'images',
    custom_gerarPrefix: i => {
      return 'loyaltyPrograms/' + i.id + '/logo/';
    },
    hint: 'Para exibição em e-mail. Tamanho sugerido: 80px x 80px.'
  }, {
    custom_groupName: 'Conversão de Pontos',
    name: 'allowDecimals',
    label: 'Permite apresentação de casas decimais',
    type: 'boolean',
    default: true,
    renderForm: function (i) {
      return i.loyaltyType && i.loyaltyType === 'POINTS' && (i.loyaltyCredentialId || '').indexOf('ALLP') === -1 ? this.render && this.render(i) : false;
    }
  }, {
    custom_groupName: 'Conversão de Pontos',
    name: 'decimalsRoundingPolicy',
    label: 'Tipo de arredondamento',
    type: 'text',
    options: [{
      value: 'ROUND',
      label: 'Padrão'
    }, {
      value: 'FLOOR',
      label: 'Para baixo'
    }, {
      value: 'CEIL',
      label: 'Para cima'
    }],
    default: 'ROUND',
    renderForm: function (i) {
      return i.loyaltyType && i.loyaltyType === 'POINTS' && i.allowDecimals === false ? this.render && this.render(i) : false;
    }
  }, {
    custom_groupName: 'Conversão de Pontos',
    label: 'Usar custo do ponto',
    name: 'useCostPerPoints',
    type: 'boolean',
    default: true,
    renderForm: function (i) {
      return i.loyaltyType && i.loyaltyType === 'POINTS' ? this.render && this.render(i) : false;
    }
  }, {
    custom_groupName: 'Conversão de Pontos',
    label: 'Moeda Conversão',
    name: 'pointsConversion',
    type: 'objectarray',
    graphQLType: '[LoyaltyCurrencyListItem]',
    graphQLInputType: '[LoyaltyCurrencyListItemInput]',
    fields: [{
      name: 'currency',
      label: 'Moeda',
      inputType: 'select',
      options: [{
        value: 'BRL',
        label: 'Reais (BRL)'
      }, {
        value: 'USD',
        label: 'Dólar (USD)'
      }, {
        value: 'EUR',
        label: 'Euro (EUR)'
      }]
    }, {
      name: 'value',
      label: 'Valor de 1 ponto na moeda escolhida',
      type: 'float'
    }],
    renderForm: function (i) {
      return i.loyaltyType && i.loyaltyType === 'POINTS' && !i.useCostPerPoints ? this.render && this.render(i) : false;
    }
  }, {
    custom_groupName: 'Configurações de exibição',
    name: 'showTierOnNavbar',
    label: 'Mostrar nível na barra de navegação',
    type: 'boolean',
    renderForm: function (i) {
      return i.loyaltyCredentialId ? this.render && this.render(i) : false;
    }
  }]
});