import fillCrudMetadataGaps, { PartialCrudMetadata } from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
const starOptions = [{
  label: '0 estrelas',
  value: 0
}, {
  label: '1 estrela',
  value: 1
}, {
  label: '2 estrelas',
  value: 2
}, {
  label: '3 estrelas',
  value: 3
}, {
  label: '4 estrelas',
  value: 4
}, {
  label: '5 estrelas',
  value: 5
}];
export const partialMetadata: PartialCrudMetadata = {
  label: 'Regra de resultado',
  labelPlural: 'Regras de resultado',
  name: 'HotelPostSearchRule',
  deletable: false,
  updatable: true,
  insertable: true,
  searchable: true,
  authorization: authorization('clientConsultant', 'admin'),
  custom_withAudit: true,
  dynamoDB: {
    tableName: 'SpearHotelSearchRule'
  },
  columnNames: ['id', 'description', 'type', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    id: true,
    insertable: false,
    updatable: false,
    renderForm: false,
    renderColumn: false
  }, {
    custom_groupName: 'Informações básicas',
    label: 'Descrição',
    name: 'description',
    type: 'text',
    hint: 'Descriação da configuração para uso interno'
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: true,
    required: true,
    type: 'boolean'
  }, {
    custom_groupName: 'Informações básicas',
    label: 'Tipo de regra',
    name: 'type',
    type: 'text',
    default: 'POST',
    updatable: false,
    options: [{
      value: 'PRE',
      label: 'Antes da busca'
    }, {
      value: 'POST',
      label: 'Após a busca'
    }],
    render: false
  }, {
    name: 'roomRateType',
    custom_groupName: 'Tarifas permitidas',
    label: 'Tipo de Tarifa',
    options: [{
      label: 'Comissisionado',
      value: 'COMMISSION'
    }, {
      label: 'NET',
      value: 'NET'
    }],
    type: 'textarray'
  }, {
    name: 'minComission',
    custom_groupName: 'Tarifas permitidas',
    label: 'Valor mínimo da comissão',
    type: 'float',
    inputType: 'percentage',
    renderForm: function (i) {
      return i.roomRateType && i.roomRateType.indexOf('COMMISSION') === -1 ? false : this.render && this.render(i);
    }
  }, {
    custom_groupName: 'Tarifas permitidas',
    name: 'roomRatePackageAllowed',
    label: 'Permitir tarifas pacotes',
    default: true,
    type: 'boolean'
  }, {
    name: 'roomRateScope',
    custom_groupName: 'Tarifas permitidas',
    label: 'Tipo de Acordo',
    options: [{
      label: 'Público',
      value: 'PUBLIC'
    }, {
      label: 'Acordo',
      value: 'PRIVATE'
    }],
    type: 'textarray'
  }, {
    name: 'cancellationPolicy',
    label: 'Políticas de cancelamento permitidas',
    custom_groupName: 'Políticas de cancelamento permitidas',
    hint: 'Políticas de cancelamento permitidas no resultado da busca',
    options: [{
      label: 'Cancelamento grátis',
      value: 'NORMAL'
    }, {
      label: 'Cancelamento com multa',
      value: 'PENALTY'
    }, {
      label: 'Não Reembolsável',
      value: 'NONREFUNDABLE'
    }],
    type: 'textarray'
  }, {
    custom_groupName: 'Categoria de hotel',
    label: 'Número mínimo de estrelas permitido',
    name: 'minHotelAward',
    inputType: 'select',
    options: starOptions
  }, {
    custom_groupName: 'Categoria de hotel',
    label: 'Número máximo de estrelas permitido',
    name: 'maxHotelAward',
    inputType: 'select',
    options: starOptions
  }, {
    custom_groupName: 'Regime de alimentação',
    name: 'breakfastIncluded',
    label: 'Obrigar a inclusão de café da manhã',
    default: false,
    type: 'boolean'
  }, {
    custom_groupName: 'Configuração de exibição do preço',
    name: 'priceDisplayMode',
    label: 'Mostrar Preço total',
    inputType: 'select',
    options: [{
      label: 'Total',
      value: 'TOTAL'
    }, {
      label: 'Por noite',
      value: 'PER_NIGHT'
    }]
  }, {
    custom_groupName: 'Configuração de exibição do tarifário',
    name: 'showRatePlanNameConfig_b2c',
    label: 'Fluxo reserva (B2C)',
    type: 'textarray',
    options: [{
      label: 'Cotação',
      value: 'QUOTATION'
    }, {
      label: 'OTA Builder / Motor Niara',
      value: 'OTA_BUILDER'
    }],
    default: []
  }, {
    custom_groupName: 'Configuração de exibição do tarifário',
    name: 'showRatePlanNameConfig_b2b',
    label: 'Fluxo reserva (B2B)',
    type: 'textarray',
    options: [{
      label: 'Usuário de clientes visualizando o nome do tarifário',
      value: 'CLIENT_USER'
    }],
    default: []
  }, {
    custom_groupName: 'Configuração de exibição do tarifário',
    name: 'showRatePlanNameConfig_tenant',
    label: 'Fluxo reserva (instalação)',
    type: 'textarray',
    options: [{
      label: 'Usuário da instalação visualizando o nome do tarifário',
      value: 'TENANT_USER'
    }],
    default: ['TENANT_USER']
  }, {
    name: 'returnOnlyPromoCodeForUnauthenticated',
    default: false,
    required: true,
    type: 'boolean',
    label: 'Fluxo reserva (B2C)',
    custom_groupName: 'Exibição somente de tarifas com o Código Promocional'
  }, {
    name: 'returnOnlyPromoCodeForClient',
    default: false,
    required: true,
    type: 'boolean',
    label: 'Fluxo reserva (B2B)',
    custom_groupName: 'Exibição somente de tarifas com o Código Promocional'
  }, {
    name: 'returnOnlyPromoCodeForInstallation',
    default: false,
    required: true,
    type: 'boolean',
    label: 'Fluxo reserva (instalação)',
    custom_groupName: 'Exibição somente de tarifas com o Código Promocional'
  }, {
    name: 'expandTermsAndPolicies',
    default: false,
    type: 'boolean',
    label: 'Trazer expandido o textos das políticas de hotel (Leia mais) da tela de checkout',
    custom_groupName: 'Configuração para expandir políticas na tela de checkout'
  }],
  filter: [{
    name: 'type_eq',
    field: 'type',
    op: 'eq'
  }]
};
export default fillCrudMetadataGaps({
  ...partialMetadata
});