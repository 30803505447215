import React, { useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useTheme } from '../..';
import FlightPassengers, { FlightClass, PassengersCount } from '../../FlightSearch/FlightPassengers/';
import { PreSearch } from '../../FlightSearch/FlightCriteria/FlightCriteria';
import { IcGroupBlack } from '../../Icons';
import * as S from './styles';
export type CounterPassengersProps = React.PropsWithChildren<{
  flightPassengers: PassengersCount;
  flightPreSearchRule?: PreSearch;
  companyRulesUrl?: string;
  block?: boolean;
  setPassengerChoices?: (v: PassengersCount) => void;
}>;
const CounterPassengers: React.FC<CounterPassengersProps> = ({
  block,
  flightPassengers,
  companyRulesUrl,
  flightPreSearchRule = {},
  setPassengerChoices
}) => {
  const [openCount, setOpenCount] = useState(false);
  const theme = useTheme();
  const [adults, setAdults] = useState<number>(1);
  const [children, setChildren] = useState<number>(0);
  const [babies, setBabies] = useState<number>(0);
  const [flightClass, setFlightClass] = useState<FlightClass>('economy');
  const setFlightPassengers = (flightPassengers: PassengersCount) => {
    if (flightPassengers?.adults) setAdults(flightPassengers?.adults);
    if (flightPassengers?.children) setChildren(flightPassengers?.children);
    if (flightPassengers?.babies) setBabies(flightPassengers?.babies);
    if (flightPassengers?.flightClass) setFlightClass(flightPassengers?.flightClass);
  };

  // const flightCategoryText = (category: FlightClass) => {
  //   switch (category) {
  //     case 'economy':
  //       return 'Econômica'
  //     case 'premium':
  //       return 'Econômica Premium'
  //     case 'business':
  //       return 'Executiva'
  //     case 'first':
  //       return 'Primeira Classe'
  //     default:
  //       return ''
  //   }
  // }

  const passengersCount = (adults: number, children: number, babies: number) => (adults ?? 1) + (children ?? 0) + (babies ?? 0);
  const passengers = passengersCount(flightPassengers?.adults, flightPassengers?.children, flightPassengers?.babies);
  const passangersText = `${passengers < 1 ? '' : `${passengers} ${passengers > 1 ? "passageiros" : "passageiro"}`}`;
  const applyPassengers = passengersCount(adults, children, babies);
  const applyBtnText = `${applyPassengers < 1 ? '' : `${applyPassengers} ${applyPassengers > 1 ? "passageiros" : "passageiro"}`}`;
  const applyChoices = () => {
    setPassengerChoices({
      adults,
      children,
      babies,
      flightClass
    });
    setOpenCount(false);
  };
  return <S.Container block={block}>
      <S.Peoples>
        <S.Btn type="button" openCount={openCount} onClick={() => setOpenCount(!openCount)}>
          <img src={IcGroupBlack} alt="passengers" />
          <S.BtnContent>{passangersText}</S.BtnContent>
        </S.Btn>

        {openCount && <ClickAwayListener onClickAway={() => setOpenCount(false)}>
            <S.Content>
              <FlightPassengers flightPassengers={{
            adults,
            children,
            babies,
            flightClass
          }} flightPreSearchRule={flightPreSearchRule} companyRulesUrl={companyRulesUrl} setFlightPassengers={setFlightPassengers} />

              <S.Button color={theme?.colors?.primary} textColorButton={theme?.colors?.primaryReadable} type="button" block={block} onClick={() => applyChoices()}>
                Aplicar
                <small>{applyBtnText}</small>
              </S.Button>
            </S.Content>
          </ClickAwayListener>}
      </S.Peoples>
    </S.Container>;
};
export default CounterPassengers;