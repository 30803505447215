import React from 'react';
import * as S from './styles';
enum Region {
  'neighborhood' = 'neighborhood',
  'city' = 'city',
  'state' = 'state',
  'country' = 'country',
  'multi_city_vicinity' = 'multi_city_vicinity',
  'point_of_interest' = 'point_of_interest',
  'airport' = 'airport',
  'high_level_region' = 'high_level_region',
  'province_state' = 'province_state',
  'continent' = 'continent',
  'train_station' = 'train_station',
  'metro_station' = 'metro_station',
  'hotel' = 'hotel',
}
export type ContentSearchOptionProps = {
  label: string | string[];
  id: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  name?: string;
  type?: string;
  notDisplayLocationInSearch?: boolean;
};
const generateAddress = ({
  name,
  city,
  state,
  country
}: {
  name?: string;
  city?: string;
  state?: string;
  country?: string;
}): string => {
  const nameExists = name != undefined;
  const cityExists = city != undefined;
  const stateExists = state != undefined;
  const countryExists = country != undefined;
  const address = `${nameExists ? `${name}` : ''}${cityExists ? `${nameExists ? ', ' : ''}${city}` : ''}${stateExists ? `${cityExists || nameExists ? ', ' : ''}${state}` : ''}${countryExists ? `${stateExists || cityExists || nameExists ? ', ' : ''}${country}` : ''}`;
  return address?.replace(/\(Arredores\)| \(Arredores\)|\(Cidade\)| \(Cidade\)|\(Ponto de Interesse\)| \(Ponto de Interesse\)|\(Ponto de interesse\)| \(Ponto de interesse\)|\(estado\)| \(estado\)|\(Estado\)| \(Estado\)|\(Estação de trem\)| \(Estação de trem\)|\(Bairro\)| \(Bairro\)|\(Estação de metrô\)| \(Estação de metrô\)/g, '');
};
const ContentSearchOption = (contentSearchOption: ContentSearchOptionProps): JSX.Element => {
  const {
    name,
    label,
    city,
    country,
    state,
    type,
    notDisplayLocationInSearch
  } = contentSearchOption || {};
  let nameOrLabel = name || label;
  nameOrLabel = typeof nameOrLabel == 'string' ? nameOrLabel : nameOrLabel?.join(', ');
  const handleAddress = (type: string) => {
    if (notDisplayLocationInSearch) {
      return nameOrLabel;
    }
    switch (type) {
      case Region.neighborhood:
        return generateAddress({
          name: nameOrLabel,
          city,
          state,
          country
        });
      case Region.city:
        return generateAddress({
          city,
          state,
          country
        });
      case Region.state:
        return generateAddress({
          name: nameOrLabel,
          country
        });
      case Region.country:
        return generateAddress({
          name: nameOrLabel,
          city,
          state,
          country
        });
      case Region.multi_city_vicinity:
        return generateAddress({
          city,
          state,
          country
        });
      case Region.point_of_interest:
        return generateAddress({
          name: nameOrLabel,
          city,
          state,
          country
        });
      case Region.airport:
        return generateAddress({
          name: nameOrLabel,
          city,
          state,
          country
        });
      case Region.high_level_region:
        return generateAddress({
          name: nameOrLabel,
          city,
          state,
          country
        });
      case Region.province_state:
        return generateAddress({
          name: nameOrLabel,
          city,
          state,
          country
        });
      case Region.continent:
        return generateAddress({
          name: nameOrLabel,
          city,
          state,
          country
        });
      case Region.train_station:
        return generateAddress({
          name: nameOrLabel,
          city,
          state,
          country
        });
      case Region.metro_station:
        return generateAddress({
          name: nameOrLabel,
          city,
          state,
          country
        });
      case Region.hotel:
        return generateAddress({
          name: nameOrLabel,
          city,
          state,
          country
        });
      default:
        return generateAddress({
          name: nameOrLabel,
          city,
          state,
          country
        });
    }
  };
  return <S.Container>
      {type == 'all' ? <S.LabelBold>{handleAddress(type)}</S.LabelBold> : <S.Address>{handleAddress(type)}</S.Address>}
    </S.Container>;
};
export default ContentSearchOption;