import { normalize } from 'path'
import styled from 'styled-components'
import { BREAKPOINT_MD, BREAKPOINT_SM } from '../../theme/ota'

export const Amount = styled.div`

    @media (max-width: calc(${BREAKPOINT_MD})) {
      border-bottom: 1px solid ${({ theme }) => theme?.colors?.gray4};
      border-top: 1px solid ${({ theme }) => theme?.colors?.gray4};
      padding-top: 20px;
    }
  }
`

export const Title = styled.h4`
  color: ${({ theme }) => theme.colors.text};
  font-weight: bold;
  font-size: 14px
  line-height: 19px;
  margin: 0 0 20px 0;
`

export const PassengerCount = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  label {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;

    small {
      color: ${({ theme }) => theme?.colors?.gray5};
      display: block;
      font-size: 11px;
    }
  }

  @media (max-width: calc(${BREAKPOINT_MD})) {
    label {
      font-size: 16px;
    }
  }
`

export const PassengerTitle = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

export const TitleInputs = styled.p`
  color: #2c2f33;
  font-size: 13px;
  font-weight: normal;
  line-height: 28px;
`

export const SubtitleInputs = styled.p`
  color: ${({ theme }) => theme.colors.gray5};
  font-size: 10px;
  font-weight: normal;
  line-height: 15px;
`

export const CompanyRules = styled.a`
  color: ${({ theme }) => theme.colors.blue};
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
`

export const ClassChoices = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 15px;
`
