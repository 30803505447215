import audit from './audit';
import client from './client';
import clientTraveller from './clientTraveller';
import credential from './credential';
import rule from './rule';
import hotelReservation from './hotelReservation';
import mail from './mail';
import tag from './tag';
import tenant from './tenant';
import user from './user';
import uiCustomization from './uiCustomization';
import vcn from './vcn';
import activity from './activity';
import negotiatedRate from './negotiatedRate';
import landingPage from './landingPage';
import selfHotelReservation from './selfHotelReservation';
import paymentCondition from './paymentCondition';
import checkoutFlowRule from './checkoutFlowRule';
import distribution from './distribution';
import order from './order';
import currentTenantB2C from './currentTenantB2C';
import currentTenant from './currentTenant';
import personAccount from './personAccount';
import niaraAccountLogin from './niaraAccountLogin';
import flightReservation from './flightReservation';
import serviceSlim from './serviceSlim';
import landingPageTraveller from './landingPageTraveller';
import mapping from './mapping';
import searchResult from './searchResult';
import integrationEvent from './integrationEvent';
import requisition from './requisition';
import tagGroup from './tagGroup';
import financeExport from './financeExport';
import product from './product';
import currentUser from './currentUser';
import selfFlightReservation from './selfFlightReservation';
import userMFADevice from './userMFADevice';
import clientUser from './clientUser';
import currentClient from './currentClient';
import payment from './payment';
import paymentLink from './paymentLink';
import orderGuest from './orderGuest';
import supportReservation from './supportReservation';
import supplierConfiguration from './supplierConfiguration';
import channel from './channel';
import securedLink from './securedLink';
import paymentRequest from './paymentRequest';
import shallowCredential from './shallowCredential';
import creditCard from './creditCard';
import voucherInventory from './voucherInventory';
import voucher from './voucher';
import currentPerson from './currentPerson';
import proxyCredential from './proxyCredential';
import quotation from './quotation';
import peopleRule from './peopleRule';
import selfOrder from './selfOrder';
import loyalty from './loyalty';
import hotelSearchRule from './hotelSearchRule';
import loyaltyConfiguration from './loyaltyConfiguration';
/** substituiu hotelPreSearchRule */
import personSerasaReport from './personSerasaReport';
import hotelPostSearchRule from './hotelPostSearchRule';
import superHotelPreSearchRule from './superHotelPreSearchRule';
import superHotelPostSearchRule from './superHotelPostSearchRule';
import businessConfiguration from './businessConfiguration';
import lostReservation from './lostReservation';
import loyaltyRedeemConfiguration from './loyaltyRedeemConfiguration';
import businessConfigurationPreSearch from './businessConfigurationPreSearch';
import invoice from './invoice';
import automaticReport from './automaticReport';
import automaticReportConfig from './automaticReportConfig';
import property from './property';
import record from './record';
import gds from './gds';
import quotationTemplate from './quotationTemplate';
import mailTemplate from './mailTemplate';
import shallowClient from './shallowClient';
import webhookNotification from './webhookNotification';
import engineRule from './engineRule';
import person from './person';
import identityProvider from './identityProvider';
import authenticationRule from './authenticationRule';
import checkoutFormRule from './checkoutFormRule';
import markupRule from './markupRule';
import commissionRule from './commissionRule';
import metasearchRule from './metasearchRule';
import paymentRouterRule from './paymentRouterRule';
import supplierPaymentRule from './supplierPaymentRule';
import buyerPaymentRule from './buyerPaymentRule';
import creditControlRule from './creditControlRule';
import ledgerConfiguration from './ledgerConfiguration';
import ledgerTransaction from './ledgerTransaction';
import selfLedgerTransaction from './selfLedgerTransaction';
import notificationRule from './notificationRule';
import coupon from './coupon';
import discountRule from './discountRule';
import currencyExchangeRule from './currencyExchangeRule';
import manualReservation from './manualReservation';
import region from './region';
import automaticCancellationRule from './automaticCancellationRule';
import propertyGroup from './propertyGroup';
import favoriteHotelsRule from './favoriteHotelsRule';
import personAssociation from './personAssociation';
import actionAudit from './actionAudit';
import privacyRule from './privacyRule';
import serviceAccount from './serviceAccount';
import crmSetup from './crmSetup';
import setupConfiguration from './setupConfiguration';
import selfPersonAssociation from './selfPersonAssociation';
import vcnRule from './vcnRule';
import includeExtrasRule from './includeExtrasRule';
import contentDev from './contentDev';
// Adicionar novos imports de crudmodels aqui

export * from '../types';

// SEMPRE ADICIONAR NOVOS ITENS NO FIM, NÃO MUDAR A ORDEM!
// SE REMOVER ITEM, MUDAR ORDEM, VAI DAR PROBLEMA NO DEPLOY DO APPSYNC
const model = {
  audit,
  client,
  clientTraveller,
  credential,
  hotelReservation,
  mail,
  paymentCondition,
  rule,
  tag,
  tenant,
  user,
  uiCustomization,
  vcn,
  activity,
  negotiatedRate,
  landingPage,
  selfHotelReservation,
  checkoutFlowRule,
  distribution,
  order,
  currentTenantB2C,
  currentTenant,
  personAccount,
  niaraAccountLogin,
  flightReservation,
  serviceSlim,
  landingPageTraveller,
  mapping,
  searchResult,
  integrationEvent,
  requisition,
  tagGroup,
  financeExport,
  product,
  currentUser,
  selfFlightReservation,
  userMFADevice,
  clientUser,
  currentClient,
  payment,
  paymentLink,
  orderGuest,
  supportReservation,
  supplierConfiguration,
  channel,
  securedLink,
  paymentRequest,
  shallowCredential,
  creditCard,
  voucherInventory,
  voucher,
  currentPerson,
  proxyCredential,
  quotation,
  peopleRule,
  selfOrder,
  loyalty,
  hotelSearchRule,
  loyaltyConfiguration,
  businessConfiguration,
  lostReservation,
  loyaltyRedeemConfiguration,
  invoice,
  automaticReport,
  automaticReportConfig,
  property,
  record,
  gds,
  quotationTemplate,
  mailTemplate,
  personSerasaReport,
  hotelPostSearchRule,
  superHotelPreSearchRule,
  superHotelPostSearchRule,
  shallowClient,
  businessConfigurationPreSearch,
  webhookNotification,
  engineRule,
  person,
  identityProvider,
  authenticationRule,
  checkoutFormRule,
  markupRule,
  commissionRule,
  metasearchRule,
  paymentRouterRule,
  supplierPaymentRule,
  buyerPaymentRule,
  creditControlRule,
  ledgerConfiguration,
  ledgerTransaction,
  selfLedgerTransaction,
  notificationRule,
  coupon,
  discountRule,
  currencyExchangeRule,
  manualReservation,
  region,
  automaticCancellationRule,
  propertyGroup,
  favoriteHotelsRule,
  personAssociation,
  actionAudit,
  privacyRule,
  serviceAccount,
  crmSetup,
  setupConfiguration,
  selfPersonAssociation,
  vcnRule,
  includeExtrasRule,
  contentDev
  // Adicionar novos crudmodels aqui
};
// SEMPRE ADICIONAR NOVOS ITENS NO FIM, NÃO MUDAR A ORDEM!
// SE REMOVER ITEM, MUDAR ORDEM, VAI DAR PROBLEMA NO DEPLOY DO APPSYNC

export default model;
export type CrudModelKey = keyof typeof model;
export { audit, client, clientTraveller, credential, hotelReservation, mail, paymentCondition, rule, tag, tenant, user, uiCustomization, vcn, activity, negotiatedRate, landingPage, selfHotelReservation, checkoutFlowRule, distribution, order, currentTenantB2C, currentTenant, personAccount, niaraAccountLogin, flightReservation, serviceSlim, landingPageTraveller, mapping, searchResult, integrationEvent, requisition, tagGroup, financeExport, product, currentUser, selfFlightReservation, userMFADevice, clientUser, currentClient, payment, paymentLink, orderGuest, supportReservation, supplierConfiguration, channel, securedLink, paymentRequest, shallowCredential, creditCard, voucherInventory, voucher, currentPerson, proxyCredential, quotation, peopleRule, selfOrder, loyalty, hotelSearchRule, loyaltyConfiguration, businessConfiguration, lostReservation, loyaltyRedeemConfiguration, invoice, automaticReport, automaticReportConfig, property, record, gds, quotationTemplate, mailTemplate, personSerasaReport, hotelPostSearchRule, superHotelPreSearchRule, superHotelPostSearchRule, shallowClient, businessConfigurationPreSearch, webhookNotification, engineRule, person, identityProvider, authenticationRule, checkoutFormRule, markupRule, commissionRule, metasearchRule, paymentRouterRule, supplierPaymentRule, buyerPaymentRule, creditControlRule, ledgerConfiguration, ledgerTransaction, selfLedgerTransaction, notificationRule, coupon, discountRule, currencyExchangeRule, manualReservation, region, automaticCancellationRule, propertyGroup, favoriteHotelsRule, personAssociation, actionAudit, privacyRule, serviceAccount, crmSetup, setupConfiguration, selfPersonAssociation, vcnRule, includeExtrasRule, contentDev
// Adicionar novos crudmodels aqui
};