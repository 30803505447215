import React from 'react';
type Direction = 'up' | 'down' | 'left' | 'right';
export type Props = {
  color?: string;
  direction: Direction;
  size?: number;
};
const Arrow: React.FC<Props> = ({
  color = '#000',
  direction,
  size = 18
}) => {
  const rotate = (dir: Direction) => {
    switch (dir) {
      case 'up':
        return 90;
      case 'right':
        return 180;
      case 'down':
        return 270;
      default:
        return 0;
    }
  };
  const rotation = rotate(direction);
  return <svg xmlns="http://www.w3.org/2000/svg" height={`${size}px`} viewBox={`0 0 24 24`} width={`${size}px`} fill={color}>
      <g transform={`translate(12, 12)`}>
        <g transform={`rotate(${rotation})`}>
          <g transform={`translate(-12, -12)`}>
            <path d="M9,19l1.41-1.41L5.83,13H22V11H5.83l4.59-4.59L9,5l-7,7L9,19z" />
          </g>
        </g>
      </g>
    </svg>;
};
export default Arrow;