import type { HotelCartProps } from '@niarab2c/ota-components/src';
import { formatDate } from '@niaratech/niara-js-commons';
import { diffInDays } from '@niaratech/niara-js-commons/dist/dateUtils';
import { useImmutableFunction } from '@niaratech/niara-react-components';
import _pick from 'lodash/pick';
import React, { useMemo } from 'react';
import { QuotationRoomRates, ShoppingCart, useDispatch, useSelector } from '../app/base';
import { validateAndGenerateSlotTitle } from './useRoomOptions';
const useCartProps = (): Pick<HotelCartProps, 'cartItems' | 'comparison' | 'cartTotal' | 'paymentOptions' | 'onCreateQuotation'> => {
  const dispatch = useDispatch();
  const {
    shoppingCartItems,
    shoppingCartTotal,
    person,
    b2c
  } = useSelector(s => ({
    shoppingCartItems: s.shoppingCart?.items,
    shoppingCartTotal: s.shoppingCart?.total,
    person: s.shoppingCart?.person,
    b2c: s.authentication?.b2c
  }));
  const comparison = useMemo(() => {
    if (!shoppingCartItems?.[0]) return undefined;
    const slot = shoppingCartItems?.[0]?.roomRates[0]?.metasearch?.slots?.find(slot => slot.comparison);
    return slot?.comparison || undefined;
  }, [shoppingCartItems]);
  const cartItems: HotelCartProps['cartItems'] = React.useMemo(() => (shoppingCartItems?.map(item => {
    const roomImage = item?.roomRates?.[0]?.roomType?.image;
    const price = item.roomRates?.[0]?.priceComposition.productPrice;
    const taxes = item.roomRates?.[0]?.priceComposition.taxes.value;
    const total = {
      ...price,
      value: price.value * item.quantity
    };
    const roomRate = item.roomRates[0];
    const {
      occupancy
    } = item;
    const selectedSlotIndex = roomRate?.metasearch?.slots?.findIndex(slot => slot?.ctaAction == 'ADD_TO_CART');
    const badgeHighlighted = selectedSlotIndex == 0;
    return {
      hotelName: item.hotelResult?.hotel.name,
      cityName: item.hotelResult?.hotel?.cityName,
      stateName: item.hotelResult?.hotel?.stateName,
      roomTitle: item.roomRates?.[0].roomType?.name,
      roomDescr: item.roomRates?.[0].descriptions.roomType?.[0]?.description,
      roomImage: roomImage,
      policy: item.roomRates?.[0].cancelPolicy,
      guests: occupancy?.adults ?? 0,
      nights: diffInDays(item.endDate, item.startDate) ?? 1,
      currency: price.currency,
      badge: validateAndGenerateSlotTitle(roomRate.metasearch?.slots, roomRate.metasearch?.slots[selectedSlotIndex]),
      badgeHighlighted,
      promotion: roomRate.promotion,
      taxes,
      totalValue: total.value,
      children: occupancy?.children ?? 0,
      childrenAges: occupancy?.childrenAges ?? [],
      onRemove: () => {
        dispatch(ShoppingCart.removeItem(item));
      },
      hotelId: item?.hotelId,
      startDate: item?.startDate,
      endDate: item?.endDate,
      roomRates: item?.roomRates
    };
  }) as HotelCartProps['cartItems']), [dispatch, shoppingCartItems]);
  const firstRoomRate = shoppingCartItems?.[0]?.roomRates?.[0];
  const paymentOptions = React.useMemo(() => {
    // TODO: quase bug - hj pega apenas as opções de pagamento do primeiro quarto. Só não é bug pq não permitimos
    // adicionar itens com diferentes formas de pagamento. Mas isso vai mudar.
    if (!firstRoomRate) return [];
    return firstRoomRate.engineRuleVersion === '1' ? firstRoomRate.paymentOptions : firstRoomRate.payment?.paymentOptions;
  }, [firstRoomRate]);
  const onCreateQuotation: HotelCartProps['onCreateQuotation'] = useImmutableFunction(b2c ? undefined : () => {
    dispatch(QuotationRoomRates.updateSelectedRoomRatesAndOpenModal(shoppingCartItems.map<QuotationRoomRates.MailableResult>(shoppingCartItem => {
      const roomRate = shoppingCartItem.roomRates?.[0];
      const hotelResult = shoppingCartItem.hotelResult;
      if (!roomRate || !hotelResult) return undefined;
      const {
        startDate,
        endDate
      } = roomRate.time;
      const mailableResult: QuotationRoomRates.MailableResult = {
        id: roomRate.id,
        label: `${hotelResult.hotel.name} ${formatDate(startDate, undefined, 'dd/MM')}-${formatDate(endDate, undefined, 'dd/MM')}`,
        result: {
          hotel: hotelResult.hotel,
          _id: hotelResult._id
        },
        roomRates: [roomRate],
        criteria: {
          time: {
            startDate,
            endDate
          },
          occupancy: {
            ..._pick(roomRate?.occupancy, 'adults', 'children', 'childrenAges'),
            quantity: 1
          },
          clientId: hotelResult.clientId,
          destinationName: hotelResult.hotel?.cityName,
          destinations: {
            cityIds: [hotelResult.hotel.cityId]
          }
        },
        person: {
          personId: person?.id,
          personName: [person?.firstName, person?.lastName].filter(Boolean).join(' ')
        }
      };
      return mailableResult;
    }).filter(Boolean)));
  });
  return {
    cartItems,
    cartTotal: shoppingCartTotal,
    comparison,
    paymentOptions,
    onCreateQuotation: b2c ? undefined : onCreateQuotation
  };
};
export default useCartProps;