import styled from 'styled-components'

export const CartTitle = styled.header`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  font-size: 12px;
  justify-content: flex-start;
`

export const CartName = styled.h4`
  font-size: 14px;
`

export const CloseIcon = styled.img`
  height: 13px;
  width: 13px;
`

export const CartIcon = styled.div``

export const Badge = styled.div`
  background-color: ${({ theme }) => theme?.colors?.gray3};
  border-radius: 25px;
  color: ${({ theme }) => theme?.colors?.text};
  margin: 0 5px;
  padding: 2px 10px;
`
