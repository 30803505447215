import { memoize } from '@niaratech/niara-js-commons'
import burstThrottle from '@niaratech/niara-js-commons/dist/burstThrottle'
import defaultLocale from '@niaratech/niara-react-components/lib/util/defaultLocale'
import aws4 from 'aws4'
import axios, { AxiosRequestConfig } from 'axios'
import niaraAuth from '../security/niaraAuth'

type AuthenticationType = 'NONE' | 'NIARA_AUTH' | 'AWS_IAM'
type Options = {
  authenticationType?: AuthenticationType
  headers?: { [any: string]: string }
  body?: any
  params?: { [any: string]: string | boolean | number }
}
type Method = 'get' | 'post' | 'patch' | 'delete'

const ENDPOINTS = {
  'otabuilder-backend': process.env.NIARAB2C_OTABUILDER_BACKEND_API_ENDPOINT,
  'niara-auth': process.env.NIARA_AUTH_API_ENDPOINT,
  'niara-spear-resource': process.env.NIARA_SPEAR_RESOURCE_API_ENDPOINT,
  'niara-spear-orders': process.env.NIARA_APP_ORDERS_ENDPOINT,
  'niara-spear-graphql': process.env.NIARA_GRAPHQL_API_ENDPOINT,
  'niara-spear-content-integrations': process.env.NIARA_APP_CONTENT_INTEGRATION_ENDPOINT,
  'niara-spear-payments': process.env.NIARA_APP_PAYMENTS_ENDPOINT,
  'niara-spear-booking': process.env.NIARA_BOOKING_API_ENDPOINT,
  'niara-spear-loyalty-programs': process.env.NIARA_APP_LOYALTY_PROGRAMS_ENDPOINT,
  'niara-spear-commons': process.env.NIARA_SPEAR_COMMONS_API_ENDPOINT,
  'niara-spear-hotels': process.env.NIARA_SPEAR_HOTELS_API_ENDPOINT,
  'niara-spear-single-sign-on': process.env.NIARA_SPEAR_SINGLE_SIGN_ON_API_ENDPOINT,
  'niara-spear-niara-account': process.env.NIARA_SPEAR_NIARA_ACCOUNT_ENDPOINT,
  'niara-spear-ledgers': process.env.NIARA_SPEAR_LEDGERS_API_ENDPOINT,
  'niara-spear-people': process.env.NIARA_SPEAR_PEOPLE_API_ENDPOINT,
  'niara-spear-credit-control': process.env.NIARA_APP_CREDIT_CONTROL_ENDPOINT,
  'safe-api': process.env.SAFE_API_ENDPOINT,
}

export type ApiName = keyof typeof ENDPOINTS

const DEFAULT_AUTHENTICATION_TYPE: Partial<Record<ApiName, AuthenticationType>> = {}

type ApiEvent = {
  payload: any
  response: any
  endpoint: string
  path: string
}

const VALID_OPENER_ORIGIN =
  process.env.NODE_ENV == 'development' ? /localhost$|127.0.0.1$|.niara.tech$/ : /.niara.tech$/
let sendCallApiEventsToOpener: string = undefined

if (typeof window !== 'undefined' && window.opener) {
  // waits for a message from opener, to verify if its from .niara.tech
  const messageEventListener: EventListener = (event: MessageEvent) => {
    if (typeof event.data == 'object' && event.data['type'] == 'niara::callApi::sendCallApiEventsToOpener') {
      if (VALID_OPENER_ORIGIN.test(event.origin)) {
        sendCallApiEventsToOpener = event.origin
      } else {
        // eslint-disable-next-line no-console
        console.error('sendCallApiEventsToOpener', 'DANGER!!!') // tirar isto no futuro?
      }
    }
  }
  window.addEventListener('message', messageEventListener)
}

const _awsIamCredentials = async (niaraAuthToken: string /* apenas para o memoized funcionar */) => {
  const resp = await _callApi<awsIamCredentialsResponse>('niara-auth', '/awsIamCredentials', 'get', {
    authenticationType: 'NIARA_AUTH',
  })
  type awsIamCredentialsResponse = {
    Credentials: {
      AccessKeyId: string
      SecretAccessKey: string
      SessionToken: string
      Expiration: string
    }
  }
  return {
    accessKeyId: resp.Credentials.AccessKeyId,
    secretAccessKey: resp.Credentials.SecretAccessKey,
    sessionToken: resp.Credentials.SessionToken,
  }
}
const awsIamCredentials: typeof _awsIamCredentials = memoize(_awsIamCredentials, { timeToLive: 60 * 60 * 1000 })

const _callApi = async function <T = any>(
  apiname: ApiName,
  apipath: string,
  method: Method = 'get',
  options: Options = {}
): Promise<T> {
  const authenticationType = options?.authenticationType ?? DEFAULT_AUTHENTICATION_TYPE?.[apiname] ?? 'NIARA_AUTH'
  const url = ENDPOINTS[apiname] + (apipath?.startsWith('/') ? '' : '/') + apipath

  const headers = Object.assign(
    {
      ['Accept-Language']: defaultLocale(),
    },
    options?.headers ?? undefined
  )

  if (authenticationType === 'NIARA_AUTH') {
    headers['Authorization'] = '' + niaraAuth.getAccessToken()
  }
  if (authenticationType === 'AWS_IAM') {
    const { accessKeyId, secretAccessKey, sessionToken } = await awsIamCredentials(niaraAuth.getAccessToken())
    const urlsplit = url.split('/')
    if (!headers['content-type'] && !headers['Content-Type']) {
      headers['Content-Type'] = 'application/json; charset=UTF-8'
    }
    const signed = aws4.sign(
      {
        host: urlsplit[2],
        headers,
        path: urlsplit.slice(3).join('/'),
        body: JSON.stringify(options?.body),
      },
      {
        accessKeyId,
        secretAccessKey,
        sessionToken,
      }
    )
    Object.assign(headers, signed.headers)
  }

  const requestConfig: AxiosRequestConfig = {
    method,
    data: options?.body,
    params: options?.params,
    headers,
  }
  const receivers: [Window, string][] = [
    [typeof window !== 'undefined' && sendCallApiEventsToOpener ? window.opener : null, sendCallApiEventsToOpener],
    [typeof window !== 'undefined' && window, undefined],
  ]
  return await axios(url, requestConfig)
    .then((r) => r.data as T)
    .then((r) => {
      receivers.forEach(([receiver, origin]) => {
        try {
          receiver?.postMessage(
            {
              type: 'niara::callApi::newRequest',
              payload: options?.body,
              response: r,
              endpoint: ENDPOINTS[apiname],
              path: apipath,
            },
            origin
          )
        } catch {
          return
        }
      })

      return r
    })
    .catch((err) => {
      if (err.response?.data) {
        const data = err.response.data
        if (data?.message || data?.error || data?.errorMessage)
          throw new Error(data?.message || data?.error || data?.errorMessage)
      }
      receivers.forEach(([receiver, origin]) => {
        try {
          receiver?.postMessage(
            {
              type: 'niara::callApi::newRequest',
              payload: options?.body,
              response: err.response,
              endpoint: ENDPOINTS[apiname],
              path: apipath,
            },
            origin
          )
        } catch {
          return
        }
      })
      throw err
    })
}

// limita 50 chamadas a cada 5 segundos (overflow de 150) e 15 chamadas a cada segundo (overflow de 50)
const burstProtectedCallApi: typeof _callApi = burstThrottle(burstThrottle(_callApi, 15, 1000, 35), 50, 5000, 100)

/**
 * cache de respostas do callapi com mesmos parâmetros por 5 segundos
 */
const memoizedCallApi: typeof _callApi = memoize(burstProtectedCallApi, {
  timeToLive: 5000,
  cacheSize: 50,
  resolver: ([apiname, apipath, method, options]) =>
    [apiname, apipath, method, options && JSON.stringify(options)].join(';\n'),
})

export { burstProtectedCallApi as uncachedCallApi }
export default memoizedCallApi
