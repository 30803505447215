import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Login Conta niara',
  labelPlural: 'Logins Conta Niara',
  name: 'NiaraAccountLogin',
  namePlural: 'NiaraAccountLogins',
  authorization: authorization('consultant', 'admin'),
  dynamoDB: {
    tableName: 'SpearNiaraAccountLogin',
    indexes: [{
      indexName: 'tenantId-personId-index',
      indexedProp: 'personId',
      scanIndexBackwards: true
    }]
  },
  insertable: false,
  updatable: false,
  deletable: false,
  columns: ['id', 'personId'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'personId',
    label: 'Id da pessoa',
    updatable: false,
    insertable: false
  }],
  filter: [{
    field: 'personId',
    op: 'eq'
  }, {
    field: 'personId',
    op: 'ne'
  }, {
    field: 'id',
    op: 'beginsWith'
  }]
});