import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import addYears from 'date-fns/addYears';
import { printPrice } from '@niaratech/niara-js-commons';
export default fillCrudMetadataGaps({
  label: 'Inventário de Voucher',
  labelPlural: 'Inventários de Voucher',
  name: 'VoucherInventory',
  namePlural: 'VoucherInventories',
  deletable: false,
  updatable: true,
  authorization: authorization('consultant', 'admin'),
  dynamoDB: {
    tableName: 'SpearVoucherInventory'
  },
  columns: ['id', 'productName', 'destinationAliases', 'roomType', 'meal', 'availabilityQuantity', 'soldQuantity', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'clientId',
    label: 'Id do cliente',
    renderColumn: false,
    required: true,
    updatable: false
  }, {
    name: 'productId',
    label: 'Id do Hotel',
    type: 'text',
    insertable: true,
    updatable: false,
    renderColumn: false,
    'custom:XlsxImportView.shown.default': true,
    'custom:importColumn': true,
    inputType: 'text'
  }, {
    name: 'productType',
    label: 'Tipo de produto',
    type: 'text',
    default: 'hotel',
    insertable: true,
    updatable: false,
    render: false
  }, {
    name: 'productName',
    label: 'Nome do hotel/ produto',
    type: 'text',
    insertable: true,
    updatable: false,
    required: true,
    inputType: 'text',
    // inputType: 'inputHotel',
    'custom:XlsxImportView.shown.default': true,
    'custom:importColumn': true
  }, {
    name: 'city',
    label: 'Cidade',
    type: 'text',
    insertable: true,
    updatable: false,
    renderForm: false
  }, {
    name: 'cityId',
    label: 'Cidade Id',
    type: 'text',
    insertable: true,
    updatable: false,
    renderForm: false
  }, {
    name: 'state',
    label: 'Estado',
    type: 'text',
    insertable: true,
    updatable: false,
    renderForm: false
  }, {
    name: 'country',
    label: 'País',
    type: 'text',
    insertable: true,
    updatable: false,
    renderForm: false
  }, {
    name: 'destinationAliases',
    label: 'Destino',
    type: 'textarray',
    insertable: true,
    updatable: true,
    hint: 'Nome do destino para filtros (ex: Maresias, SP). Aceita múltiplos valores, um por linha.',
    'custom:XlsxImportView.shown.default': true,
    'custom:importColumn': true
  }, {
    name: 'minNights',
    label: 'Mínimo de noites',
    type: 'int',
    default: 2,
    insertable: true,
    updatable: true,
    required: true
  }, {
    name: 'maxNights',
    label: 'Máximo de noites',
    type: 'int',
    default: 5,
    insertable: true,
    updatable: true,
    required: true
  }, {
    name: 'meal',
    label: 'Regime de alimentação',
    type: 'text',
    options: [{
      label: 'Somente hospedagem',
      value: 'RO'
    }, {
      label: 'Café da manhã',
      value: 'BB'
    }, {
      label: 'Meia pensão',
      value: 'HB'
    }, {
      label: 'Pensão completa',
      value: 'FB'
    }, {
      label: 'All inclusive',
      value: 'AI'
    }],
    insertable: true,
    updatable: true,
    default: 'RO',
    'custom:XlsxImportView.shown.default': true,
    'custom:importColumn': true,
    custom_withNullSelector: true,
    custom_nullSelectorLabel: "Não se aplica"
  }, {
    name: 'roomType',
    label: 'Tipo de quarto',
    type: 'text',
    insertable: true,
    updatable: true,
    'custom:XlsxImportView.shown.default': true,
    'custom:importColumn': true,
    custom_withNullSelector: true,
    custom_nullSelectorLabel: "Não se aplica"
  }, {
    name: 'minGuests',
    label: 'Mínimo hóspedes',
    type: 'int',
    default: 1,
    insertable: true,
    updatable: true,
    required: true,
    'custom:importColumn': true
  }, {
    name: 'maxGuests',
    label: 'Máximo hóspedes',
    type: 'int',
    default: 4,
    insertable: true,
    updatable: true,
    required: true,
    'custom:importColumn': true
  }, {
    name: 'currency',
    label: 'Moeda',
    type: 'text',
    default: 'BRL',
    insertable: true,
    updatable: true,
    render: false,
    renderColumn: false
  }, {
    name: 'price_1',
    label: 'Valor diária 1 hóspede',
    type: 'float',
    insertable: true,
    updatable: true,
    required: false,
    inputType: 'price',
    render: i => i.price_1 != null ? printPrice(i.price_1, i.currency) : '-',
    'custom:XlsxImportView.shown.default': true,
    'custom:importColumn': true,
    custom_withNullSelector: true,
    custom_nullSelectorLabel: "Não se aplica"
  }, {
    name: 'price_2',
    label: 'Valor diária 2 hóspedes',
    type: 'float',
    insertable: true,
    updatable: true,
    required: false,
    inputType: 'price',
    render: i => i.price_2 != null ? printPrice(i.price_2, i.currency) : '-',
    'custom:XlsxImportView.shown.default': true,
    'custom:importColumn': true,
    custom_withNullSelector: true,
    custom_nullSelectorLabel: "Não se aplica"
  }, {
    name: 'price_3',
    label: 'Valor diária 3 hóspedes',
    type: 'float',
    insertable: true,
    updatable: true,
    required: false,
    inputType: 'price',
    render: i => i.price_3 != null ? printPrice(i.price_3, i.currency) : '-',
    'custom:importColumn': true,
    custom_withNullSelector: true,
    custom_nullSelectorLabel: "Não se aplica"
  }, {
    name: 'price_4',
    label: 'Valor diária 4 hóspedes (ou mais)',
    type: 'float',
    insertable: true,
    updatable: true,
    required: false,
    inputType: 'price',
    render: i => i.price_4 != null ? printPrice(i.price_4, i.currency) : '-',
    'custom:importColumn': true,
    custom_withNullSelector: true,
    custom_nullSelectorLabel: "Não se aplica"
  }, {
    name: 'travelWindowStartDate',
    label: 'Início travel window',
    type: 'date',
    required: true,
    insertable: true,
    updatable: true,
    'custom:importColumn': true
  }, {
    name: 'travelWindowEndDate',
    label: 'Fim travel window',
    type: 'date',
    required: true,
    insertable: true,
    updatable: true,
    'custom:importColumn': true
  }, {
    name: 'daysInAdvance',
    label: 'Dias de antecedência do check-in',
    hint: 'A contar da data da reserva',
    type: 'int',
    required: false,
    insertable: true,
    updatable: true,
    default: null,
    custom_withNullSelector: true,
    custom_nullSelectorLabel: "Não se aplica"
  }, {
    name: 'availabilityQuantity',
    label: 'Quantidade',
    type: 'int',
    required: true,
    insertable: true,
    updatable: true,
    'custom:XlsxImportView.shown.default': true,
    'custom:importColumn': true
  }, {
    name: 'availabilityStartDate',
    label: 'Data de início de venda',
    type: 'datetime',
    initialize: () => new Date().toISOString().substring(0, 10),
    required: true,
    insertable: true,
    updatable: true,
    'custom:importColumn': true
  }, {
    name: 'availabilityEndDate',
    label: 'Data de fim de venda',
    type: 'datetime',
    initialize: () => addYears(new Date(), 1).toISOString().substring(0, 10),
    required: true,
    insertable: true,
    updatable: true,
    'custom:importColumn': true
  }, {
    label: 'Políticas para compra do voucher',
    name: 'policies',
    inputType: 'textarea',
    'custom:importColumn': true,
    placeholder: 'Políticas, como horário de atendimento, regras para camas extras, estacionamento, pets, etc.',
    hint: 'Coloque o caractere "#" antes de cada título. Utilize duas quebras de linha para separar parágrafos.'
  }, {
    label: 'Políticas para resgate do voucher',
    name: 'redeemPolicies',
    inputType: 'textarea',
    'custom:importColumn': true,
    placeholder: 'Políticas, como horário de atendimento, regras para camas extras, estacionamento, pets, etc.',
    hint: 'Coloque o caractere "#" antes de cada título. Utilize duas quebras de linha para separar parágrafos.'
  }, {
    label: 'Cartão de presente?',
    name: 'giftCard',
    type: 'boolean',
    insertable: true,
    updatable: true,
    default: false,
    hint: 'Para cartão de presente, habilita no check-out a opção de compra para um presenteado, que receberá o código de troca.'
  }, {
    label: 'Ativo',
    name: 'enabled',
    type: 'boolean',
    insertable: true,
    updatable: true,
    default: true
  }, {
    name: 'paymentConditionId',
    label: 'Condição de recebimento',
    inputType: 'paymentConditionSingle',
    updatable: true,
    insertable: true,
    required: true
  }, {
    name: 'soldQuantity',
    label: 'Quantidade vendida',
    type: 'int',
    insertable: false,
    updatable: false
  }],
  // createParams: [
  //     {
  //         name: 'creditCard',
  //         label: 'Dados do cartão',
  //         graphQLType: 'CreditCard',
  //         graphQLInputType: 'CreditCardInput',
  //         type: 'object',
  //         fields: [
  //             {
  //                 name: 'brand',
  //                 label: 'Bandeira do cartão',
  //                 required: true,
  //                 inputType: 'select',
  //                 options: [
  //                     { label: 'Amex', value: 'AMEX' },
  //                     { label: 'Aura', value: 'AURA' },
  //                     { label: 'Diners', value: 'DINERS' },
  //                     { label: 'Elo', value: 'ELO' },
  //                     { label: 'Hiper', value: 'HIPER' },
  //                     { label: 'Hipercard', value: 'HIPERCARD' },
  //                     { label: 'JCB', value: 'JCB' },
  //                     { label: 'MasterCard', value: 'MASTERCARD' },
  //                     { label: 'Visa', value: 'VISA' },
  //                 ],
  //             },
  //             {
  //                 name: 'number',
  //                 label: 'Número do cartão',
  //                 required: true,
  //                 minLength: 13,
  //             },
  //             {
  //                 name: 'holder',
  //                 label: 'Nome no cartão',
  //                 required: true,
  //             },
  //             {
  //                 name: 'expiryDate',
  //                 label: 'Data de expiração (MMAA)',
  //                 required: true,
  //                 placeholder: 'MMAA',
  //                 mask: '9999',
  //                 pattern: '^(0?[1-9]|1[012])([2-3][0-9])$',
  //                 inputMode: 'numeric',
  //                 minLength: 4,
  //                 step: 1,
  //             },
  //             {
  //                 name: 'cvv',
  //                 label: 'Código de segurança',
  //                 mask: '999999',
  //                 inputMode: 'numeric',
  //                 required: true,
  //             },
  //         ],
  //     },
  //     {
  //         name: 'secretCode',
  //         minLength: 6,
  //         label: 'Chave secreta',
  //         placeholder: 'Mínimo de 6 caracteres',
  //         required: true,
  //         inputType: 'password',
  //         hint:
  //             'Esta senha de será solicitada a cada compra em que o cartão é utilizado. Ela não pode ser modificada.',
  //     },
  // ],
  filter: [{
    name: 'client_id_eq',
    field: 'clientId',
    op: 'eq'
  }, {
    field: 'productId',
    op: 'eq'
  }, {
    field: 'productType',
    op: 'eq'
  }, {
    field: 'roomType',
    op: 'eq'
  }]
});