import { Paper, Popper, Portal, Stack } from '@mui/material';
import useDebounce from '@niaratech/niara-react-components/lib/hooks/useDebounce';
import useSetTimeout from '@niaratech/niara-react-components/lib/hooks/useSetTimeout';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { IcArrowLeftBlack } from '../../Icons';
import Modal from '../../components/Modal';
import useDefaultPortalContext from '../../portal/useDefaultPortalContext';
import { useAddPromise } from '../../util/cleanup';
import standardHtmlAttributes from '../../util/standardHtmlAttributes';
import ListDataItems from './ListDataItems';
import * as S from './styles';
export type GroupingOptions = {
  [key: string]: unknown;
  icon?: JSX.Element;
  label: string;
};
export type Props<V> = Omit<React.InputHTMLAttributes<Element>, 'value'> & {
  value: V;
  onValue: {
    (v: V): any;
  };
  suggestionsSource: {
    (q: string): Promise<Array<V>> | Array<V>;
  };
  singleOptionAutoSelect?;
  checkForAutoSelectOption?;
  listFooter?;
  renderOption?: {
    (v: V, q?: string): string | JSX.Element;
  };
  suggestionToString?: {
    (v: V): string;
  };
  suggestionsFilter?: {
    (value: V, index: number, array: V[]): boolean;
  };
  onFocus?: React.FocusEventHandler;
  onHardValue?: {
    (v: V): any;
  };
  onTextChanged?: {
    (t: string): any;
  };
  onBlur?: React.FocusEventHandler;
  noResultsMessage?: JSX.Element | string;
  listSize?: number;
  Icon?: string;
  debounce?: number;
  minInputQuery?: number;
  headerSize?: string;
  doubleInput?: 'left' | 'right';
  errors?: string[];
  groupingOptions?: GroupingOptions[];
  groupingFilter?: string;
};
function InputAutoComplete<V>(props: Props<V>, ref) {
  const setTimeout = useSetTimeout();
  const addPromise = useAddPromise();
  const {
    value,
    suggestionsSource,
    singleOptionAutoSelect,
    checkForAutoSelectOption,
    listFooter,
    noResultsMessage,
    listSize = 10,
    Icon,
    debounce = 500,
    minInputQuery = 0,
    headerSize,
    doubleInput,
    errors,
    groupingOptions,
    groupingFilter
  } = props;
  const [isOpenInput, setIsOpenInput] = useState(false);
  const [query, setQuery] = useState<string>();
  const debouncedQuery = useDebounce(query?.trim(), debounce);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [suggestions, setSuggestions] = useState<Array<any>>();
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [loadingSuggestions, setLoadingSuggestions] = useState<boolean>(undefined);
  const selectOnBlurRef = useRef<boolean>();

  // get  all pros initiate with 'data-' with value and put in object
  const dataProps = useMemo(() => {
    const dataProps = {};
    Object.keys(props).forEach(key => {
      if (key.startsWith('data-')) {
        dataProps[key] = props[key];
      }
    });
    return dataProps;
  }, []);
  const mainInputRef = useRef<HTMLInputElement>();
  const mobileInputRef = useRef<HTMLInputElement>();
  useImperativeHandle(ref, () => {
    return mainInputRef.current;
  });
  useEffect(() => {
    if (isOpenInput) {
      const timeoutId = setTimeout(() => {
        window.requestAnimationFrame(() => {
          if (mobileInputRef.current) {
            mobileInputRef.current?.focus?.();
          }
        });
      }, 410);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isOpenInput, setTimeout]);
  const propsRef = useRef<Props<V>>();
  propsRef.current = props;
  const suggestionToString = useCallback(v => propsRef.current.suggestionToString ? propsRef.current.suggestionToString(v) : v || '', []);
  const renderOption: Props<V>['renderOption'] = useCallback((v, q) => {
    return propsRef.current.renderOption || propsRef.current.suggestionToString ? (propsRef.current.renderOption || propsRef.current.suggestionToString)(v, q) : (v as string) || '';
  }, []);
  const suggestionsFilter = useCallback((value: V, index: number, array: V[]) => propsRef.current.suggestionsFilter ? propsRef.current.suggestionsFilter(value, index, array) : () => true, []);
  const [text, setText] = useState(null);
  const propsValueAsText = useMemo(() => value ? suggestionToString ? suggestionToString(value) : value : null, [suggestionToString, value]);

  //reseta o texto caso o valor mudar externamente
  useLayoutEffect(() => {
    setText(null);
  }, [value]);
  const onFocus: React.FocusEventHandler<HTMLInputElement> = useCallback(e => {
    selectOnBlurRef.current = false;
    // if (propsRef.current.value == null) {
    setShowSuggestions(true);
    setIsOpenInput(true);
    // setSelectedIndex(-1)
    // }
    if (propsRef.current.onFocus) {
      propsRef.current.onFocus(e);
    }
  }, []);
  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;
    setShowSuggestions(true);
    setIsOpenInput(true);
    setText(value);
    setQuery(value);
    setSelectedIndex(-1);
    if (value?.length > 0) {
      selectOnBlurRef.current = true;
    } else {
      if (propsRef.current.onValue) {
        propsRef.current.onValue(null);
      }
    }
    if (propsRef.current.onTextChanged) {
      propsRef.current.onTextChanged(value);
    }
    return value;
  }, []);
  const handleOptionSelect = (suggestion, softSelect?) => {
    selectOnBlurRef.current = false;

    // if (suggestion) {
    //   setSelectedIndex(suggestions && suggestions.indexOf(suggestion))
    // }

    if (propsRef.current.onValue) {
      propsRef.current.onValue(suggestion);
    }
    if (propsRef.current.onHardValue && !softSelect) {
      propsRef.current.onHardValue(suggestion);
    }
    if (propsRef.current.onValue || propsRef.current.onHardValue) {
      //reseta o texto interno e aceita o valor recebido via props
      setText(undefined);
    } else if (suggestion) {
      const text = suggestionToString ? suggestionToString(suggestion) : suggestion;
      setText(text);
    }
    if (!softSelect) {
      // setSuggestions(null)
      // setSelectedIndex(-1)
      // setShowSuggestions(false)
    }
  };
  const onSuggestionClick = (e, suggestion) => {
    e.preventDefault();
    handleOptionSelect(suggestion);
  };
  const onKeyDown = e => {
    let newIndex = null;
    switch (e.which) {
      case 38:
        //up
        e.preventDefault();
        newIndex = selectedIndex - 1;
        selectOnBlurRef.current = true;
        setShowSuggestions(true);
        setIsOpenInput(true);
        break;
      case 40:
        //down
        e.preventDefault();
        newIndex = selectedIndex + 1;
        selectOnBlurRef.current = true;
        setShowSuggestions(true);
        setIsOpenInput(true);
        break;
      case 13:
        //enter
        e.preventDefault();
        setShowSuggestions(false);
        setIsOpenInput(false);
        if (propsRef.current.onHardValue) e.preventDefault();
        if (suggestions && suggestions.length > 0) {
          if (selectedIndex === -1) {
            handleOptionSelect(suggestions[0]);
          } else if (suggestions[selectedIndex]) {
            handleOptionSelect(suggestions[selectedIndex]);
          }
        }
        return;
      case 27:
        //escape
        e.preventDefault();
        setShowSuggestions(false);
        setIsOpenInput(false);
        return;
      default:
    }
    if (newIndex !== null) {
      const suggestion = suggestions && suggestions[newIndex];
      if (suggestion) {
        handleOptionSelect(suggestion, true);
      }
    }
  };
  const singleOptionAutoSelectTriggeredRef = useRef(false);
  useEffect(() => {
    let ignore = false;
    if (suggestionsSource) {
      const _fn = async () => {
        setLoadingSuggestions(true);
        const s = (await addPromise(suggestionsSource(debouncedQuery))) || [];
        if (!ignore) {
          const suggestions = suggestionsFilter ? s.filter(suggestionsFilter) : s;
          setSuggestions(suggestions);
          setLoadingSuggestions(false);
          if (!singleOptionAutoSelectTriggeredRef.current && (!debouncedQuery || debouncedQuery.trim() === '')) {
            if (suggestions && suggestions.length === 1 && singleOptionAutoSelect) {
              singleOptionAutoSelectTriggeredRef.current = true;
              handleOptionSelect(suggestions[0], true);
            } else {
              const autoselectoption = suggestions && suggestions.filter(x => x['autoSelect'])[0];
              if (autoselectoption) handleOptionSelect(autoselectoption, true);
            }
          }
        }
      };
      _fn();
    } else {
      if (!ignore) {
        setSuggestions(undefined);
        setLoadingSuggestions(undefined);
      }
    }
    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
  // addPromise,
  // checkForAutoSelectOption,
  debouncedQuery,
  // handleOptionSelect,
  // singleOptionAutoSelect,
  // suggestionsFilter,
  suggestionsSource]);
  const onBlur = e => {
    //Necessário para conseguir capturar o click no botão de importar pessoa #158329
    if (noResultsMessage && typeof noResultsMessage != 'string') {
      setTimeout(() => {
        setShowSuggestions(false);
      }, 200);
      return;
    }
    //o setTimeout serve para tratar o onclick no item antes
    setTimeout(() => {
      setShowSuggestions(false);
      if (selectOnBlurRef.current) {
        if (selectedIndex === -1 && suggestions && suggestions.length > 0) {
          handleOptionSelect(suggestions[0], true);
        } else {
          handleOptionSelect(null, true);
          // setText('')
        }
      }
      if (propsRef.current.onBlur) {
        propsRef.current.onBlur(e);
      }
    }, 100);
  };
  const portalContainer = useDefaultPortalContext()?.container;
  const renderSugestions = useMemo(() => {
    const options = suggestions?.slice(0, listSize)?.map(suggestion => suggestion) ?? [];
    if (!groupingOptions || groupingOptions?.length < 1) {
      return options;
    }
    const optionsWithGroups = [];
    options?.forEach((option, indexOption, arrayOptions) => {
      const previousOption = arrayOptions[indexOption - 1];
      const group = groupingOptions?.find(group => group[groupingFilter] == option[groupingFilter]);
      const newGroup = {
        label: group?.label,
        icon: group?.icon,
        isGroup: true
      };
      if (!previousOption) {
        if (!group) {
          optionsWithGroups.push(option);
        } else {
          optionsWithGroups.push(newGroup);
          optionsWithGroups.push(option);
        }
        return;
      }
      if (previousOption[groupingFilter] == option[groupingFilter]) {
        optionsWithGroups.push(option);
      } else {
        optionsWithGroups.push(newGroup);
        optionsWithGroups.push(option);
      }
    });
    const containsDifferentGroups = optionsWithGroups?.filter(op => op?.isGroup);
    if (containsDifferentGroups?.length > 1) {
      return optionsWithGroups;
    }
    return optionsWithGroups?.filter(op => !op?.isGroup);
  }, [groupingFilter, groupingOptions, listSize, suggestions]);
  return <ClickAwayListener onClickAway={() => setIsOpenInput(false)}>
      <S.Content>
        <S.IconInput className={props.className}>
          {Icon && <S.Icon src={Icon} alt="" doubleInput={doubleInput} />}

          <S.InputComplete type="text" autoComplete="off" {...standardHtmlAttributes(props)} className={errors?.length > 0 ? 'hasError ' + (props.className ?? '') : props.className} aria-errormessage={errors?.length > 0 ? errors.join(', ') : undefined} name={null} onFocus={onFocus} onBlur={onBlur} onClick={() => setIsOpenInput(true)} onKeyDown={onKeyDown} onChange={onChange} spellCheck="false" aria-haspopup="true" value={text != null ? text : propsValueAsText || ''} ref={mainInputRef} list="list" doubleInput={doubleInput} noIcon={!Icon} {...dataProps} />
        </S.IconInput>
        <S.HideOnMobile>
          <Popper open={isOpenInput && showSuggestions && mainInputRef.current != null} disablePortal anchorEl={mainInputRef.current} popperOptions={{
          placement: 'bottom-start'
        }} sx={{
          zIndex: 999999999
        }}>
            <Paper elevation={1} sx={{
            borderRadius: '5px'
          }}>
              {/* width={`${inputRef?.current?.clientWidth}px`}  estava dentro do listData */}
              <ListDataItems debouncedQuery={debouncedQuery} listFooter={listFooter} loadingSuggestions={loadingSuggestions} noResultsMessage={noResultsMessage} renderOption={renderOption} renderSugestions={renderSugestions} selectedIndex={selectedIndex} suggestions={suggestions} onSuggestionClick={onSuggestionClick} setSelectedIndex={setSelectedIndex} setIsOpenInput={setIsOpenInput} />
            </Paper>
          </Popper>
        </S.HideOnMobile>
        <Portal container={portalContainer}>
          {/* Coloco em um portal para funcionar melhor no widget */}
          <Modal show={isOpenInput} mobileVariant="full" tabletVariant="none" desktopVariant="none" showCloseButton={false} headerSize={headerSize} zIndex={2147483647}>
            <S.Hero>
              <S.Button type="button" variant="text" onClick={() => setIsOpenInput(false)}>
                <img src={IcArrowLeftBlack} alt="chevron" />
              </S.Button>

              <Stack flex={1} alignItems="center">
                <S.InputComplete type="text" autoComplete="off" autoFocus {...standardHtmlAttributes(props)} name={null} onClick={() => setIsOpenInput(true)} onKeyDown={onKeyDown} onChange={onChange} spellCheck="false" aria-haspopup="true" value={text != null ? text : propsValueAsText || ''} ref={mobileInputRef} list="list" />
              </Stack>
            </S.Hero>
            <ListDataItems debouncedQuery={debouncedQuery} listFooter={listFooter} loadingSuggestions={loadingSuggestions} noResultsMessage={noResultsMessage} renderOption={renderOption} renderSugestions={renderSugestions} selectedIndex={selectedIndex} suggestions={suggestions} onSuggestionClick={onSuggestionClick} setSelectedIndex={setSelectedIndex} setIsOpenInput={setIsOpenInput} />
          </Modal>
        </Portal>
      </S.Content>
    </ClickAwayListener>;
}
export default (forwardRef<any, Props<any>>(InputAutoComplete) as typeof InputAutoComplete);