import React from 'react';
import { Price, PriceProps } from '..';
import { IcInfo } from '../../Icons';
import { ToolTip } from '../../microComponents';
import * as S from './styles';
export type TaxIncludedProps = PriceProps & {
  key?: number | string;
  text: string | React.ReactNode;
  toolTip?: string | React.ReactNode;
  size?: number;
  valueSize?: number;
  editPercentage?: React.ReactNode;
  priceCoinSize?: number;
  fontTextBold?: boolean;
  dataTestId?: string;
};
const TaxIncluded: React.FC<TaxIncludedProps> = ({
  key,
  value,
  currency,
  color,
  text,
  size,
  valueSize = 14,
  priceCoinSize,
  fontTextBold,
  editPercentage,
  toolTip,
  dataTestId
}) => {
  return <S.Tax data-testid={dataTestId}>
      <S.TextInfo>
        <S.Text fontTextBold={fontTextBold} size={size}>
          {text}
        </S.Text>

        {toolTip && <ToolTip placement="right" title={<div>{toolTip}</div>}>
            <S.IconInfo>
              <IcInfo size={13} color="#273240" />
            </S.IconInfo>
          </ToolTip>}
      </S.TextInfo>

      <S.ValuePriceTax>
        <Price key={key} value={value} currency={currency} currencyFontWeight="normal" priceCoinFontWeight="bold" fractionFontWeight="bold" currencySize={valueSize} priceCoinSize={priceCoinSize || valueSize} decimalSize={valueSize} fractionSize={valueSize} color={color} dataTestId={dataTestId + '-price'} />

        {editPercentage && <S.EditPercentage>{editPercentage}</S.EditPercentage>}
      </S.ValuePriceTax>
    </S.Tax>;
};
export default TaxIncluded;