import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Motor de regras de pesquisa de hotel',
  labelPlural: 'Motores de regras de pesquisa de hotel',
  name: 'BusinessConfigurationPreSearch',
  path: '/businessConfigurationPreSearch',
  deletable: false,
  updatable: true,
  insertable: true,
  authorization: authorization('clientConsultant', 'admin'),
  dynamoDB: {
    tableName: 'SpearBusinessConfiguration'
  },
  columnNames: ['id', 'name', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    render: false,
    id: true
  }, {
    label: 'Nome',
    name: 'name',
    type: 'text'
  }, {
    label: 'Ativo',
    name: 'enabled',
    type: 'boolean',
    default: true
  }, {
    label: 'Nova versão',
    name: 'new',
    type: 'boolean',
    default: true,
    render: false
  }, {
    label: 'Tipo de configuração',
    name: 'type',
    type: 'text',
    default: 'PRE',
    updatable: false,
    options: [{
      value: 'PRE',
      label: 'Antes da busca'
    }, {
      value: 'POST',
      label: 'Após a busca'
    }],
    render: false
  }, {
    label: 'Prioridade',
    name: 'priority',
    type: 'int',
    inputType: 'select',
    options: [{
      label: 'Muito baixa',
      value: 0
    }, {
      label: 'Baixa',
      value: 1
    }, {
      label: 'Média',
      value: 2
    }, {
      label: 'Alta',
      value: 3
    }, {
      label: 'Muito alta',
      value: 4
    }],
    default: 0
  }, {
    custom_groupName: 'Contexto',
    label: 'Condições',
    name: 'contextTriggers',
    type: 'objectarray',
    graphQLType: '[BCPreTriggerList]',
    graphQLInputType: '[BCPreTriggerListInput]',
    fields: [{
      name: 'triggers',
      label: 'Codições de disparo',
      type: 'objectarray',
      graphQLType: '[BCPreTriggerListItem]',
      graphQLInputType: '[BCPreTriggerListItemInput]',
      fields: [{
        name: 'context',
        label: 'Tipo de contexto',
        inputType: 'inputTrigger',
        type: 'object',
        graphQLType: 'BCPreTriggerItem',
        graphQLInputType: 'BCPreTriggerItemInput',
        fields: [{
          name: 'option',
          label: 'Opção'
        }, {
          name: 'value',
          label: 'Valor'
        }],
        conditions: [{
          label: 'Landing Page é',
          option: 'landingPageIs',
          valueType: 'InputLandingPage'
        }, {
          label: 'Cliente é',
          option: 'clientIs',
          valueType: 'InputClient'
        }, {
          label: 'Data maior ou igual a',
          option: 'dateGte',
          valueType: 'InputDate'
        }, {
          label: 'Data menor ou igual a',
          option: 'dateLte',
          valueType: 'InputDate'
        }]
      }]
    }]
  }, {
    custom_groupName: 'Ações',
    label: 'Ações',
    name: 'actions',
    type: 'objectarray',
    graphQLType: '[BCPrePreActionListItem]',
    graphQLInputType: '[BCPreActionListItemInput]',
    fields: [{
      name: 'hotelPreSearchRule',
      label: 'Regra de pesquisa de hotel',
      type: 'text',
      inputType: 'hotelPreSearchRule',
      required: true
    }]
  }]
});