import styled from 'styled-components'
import { Button as BtnMaterial } from '@mui/material'

export const Content = styled.div`
  background-color: ${({ theme }) => theme?.colors?.gray1};
  border: 2px solid ${({ theme }) => theme?.colors?.white};
  border-radius: 0px 0px 4px 4px;
  display: flex;
  flex-flow: column nowrap;
  padding: 11px 15px 15px;
`

export const Info = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 20px;
`

export const Total = styled.div`
  align-items: flex-end;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  line-height: 22px;

  h5 {
    font-size: 14px;
    font-weight: bold;
  }
`

export const Taxes = styled.p`
  color: ${({ theme }) => theme?.colors?.gray6};
  font-size: 12px;
  font-weight: normal;
  line-height: 21px;
  text-align: right;
`

export const Conditions = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Payment = styled.div`
  color: ${({ theme }) => theme?.colors?.text};
  display: flex;
  justify-content: flex-end;

  p {
    font-size: 12px;
    font-weight: 500;
    line-height: 21px;
  }

  img {
    margin-left: 5px;
    width: 14px;
  }
`

export const Button = styled(BtnMaterial)`
  height: 36px;
`

export const ComparisonBadge = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.priceColor};
  display: flex;
  flex-flow: row nowrap;
  font-size: 11px;
  gap: 4px;
  line-height: 15px;
`

export const ContainerPriceInTotal = styled.div`
  padding-top: 3px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.info} !important;
  cursor: pointer;
  font-family: 'Open Sans', 'Manrope';
  font-size: 13px;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
  border: none;
  background-color: inherit;

  &:hover {
    color: ${({ theme }) => theme.colors.info} !important;
    text-decoration: none;
  }
`
