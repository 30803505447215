import fillCrudMetadata from '@niaratech/devcase-crud/dist/fillCrudMetadataGaps';
export default fillCrudMetadata({
  label: 'Custom Domain',
  labelPlural: 'Custom Domains',
  name: 'CustomDomain',
  insertable: false,
  updatable: true,
  deletable: false,
  authorization: {
    read: ['admin'],
    write: ['admin']
  },
  dynamoDB: {
    tableName: process.env.OTABUILDER_CUSTOM_DOMAIN_TABLE,
    partitionKey: 'tenantId',
    indexedProp: 'id'
  },
  columnNames: ['id', 'domainName', 'acmCertificateValidated', 'cloudfrontDistributionAliasSet', 'enabled'],
  fields: [{
    name: 'id',
    id: true,
    renderColumn: false,
    updatable: false,
    insertable: false,
    required: true,
    renderForm: false
  }, {
    name: 'domainName',
    required: true,
    updatable: false,
    insertable: false,
    pattern: '^(([a-z0-9]|[a-z0-9][a-z0-9-]*[a-z0-9]).)*([a-z0-9]|[a-z0-9][a-z0-9-]*[a-z0-9])$'
  }, {
    name: 'createTime',
    type: 'datetime',
    updatable: false,
    insertable: false
  }, {
    groupName: 'Configuração básica',
    label: 'Ativo',
    name: 'enabled',
    type: 'boolean',
    insertable: false,
    updatable: true,
    render: (e, fieldName) => {
      if (e[fieldName]) return "Ativo";else return "Inativo";
    },
    renderStyle: (e, fieldName) => {
      if (e[fieldName]) return 'success';else return 'danger';
    },
    renderForm: function (entity, fieldName) {
      if (!entity['cloudfrontDistributionAliasSet']) return false;
      return this.render(entity, fieldName);
    }
  }, {
    groupName: 'Configuração básica',
    label: 'Múltiplos sites',
    name: 'multisite',
    type: 'boolean',
    insertable: true,
    updatable: true,
    hint: 'Permite o cadastro de múltiplos sites sob o mesmo domínio customizado. Ex: https://hoteis.meudominio.com/eventodenatal2022',
    renderForm: function (entity, fieldName) {
      if (!entity['enabled']) return false;
      return this.render(entity, fieldName);
    }
  }, {
    groupName: 'Configuração básica',
    label: 'Site associado',
    name: 'storefrontId',
    type: 'text',
    inputType: 'storefrontId',
    hint: 'Caso opção marcada de múltiplos sites, esta página será carregada quando acessar direto na raiz',
    insertable: true,
    updatable: true,
    renderForm: function (entity, fieldName) {
      if (!entity['enabled']) return false;
      return this.render(entity, fieldName);
    }
  }, {
    groupName: 'Configuração básica',
    label: 'Sites associados',
    name: 'storefronts',
    type: 'objectarray',
    graphQLType: '[CustomDomain_Storefront]',
    graphQLInputType: '[CustomDomain_StorefrontInput]',
    insertable: true,
    updatable: true,
    fields: [{
      name: 'path',
      required: true,
      minLength: 3,
      pattern: '[a-z]{1}[a-z0-9]{2,}',
      type: 'text'
    }, {
      required: true,
      name: 'storefrontId',
      inputType: 'storefrontId',
      type: 'text'
    }],
    renderForm: function (entity, fieldName) {
      if (!entity['enabled']) return false;
      if (!entity['multisite']) return false;
      return this.render(entity, fieldName);
    }
  }, {
    groupName: 'Dados para validação',
    updatable: false,
    insertable: false,
    name: 'dnsRecords',
    type: 'objectarray',
    graphQLType: '[DnsRecord]',
    fields: [{
      name: 'name'
    }, {
      name: 'type'
    }, {
      name: 'value'
    }],
    render: customDomain => customDomain?.dnsRecords?.map((record, idx) => `Registro ${idx + 1}\nTipo: ${record.type}\nNome: ${record.name}\nValor: ${record.value}`).join('\n\n') || null
  }, {
    groupName: 'Dados para validação',
    name: 'cloudfrontDistributionDomainName',
    updatable: false,
    insertable: false
  }, {
    groupName: 'Dados para validação',
    name: 'acmCertificateValidated',
    type: 'boolean',
    updatable: false,
    insertable: false,
    render: (e, fieldName) => {
      if (e[fieldName]) return "Sim";else return "Não";
    },
    renderStyle: (e, fieldName) => {
      if (e[fieldName]) return 'success';else return 'danger';
    }
  }, {
    groupName: 'Dados para validação',
    name: 'cloudfrontDistributionAliasSet',
    type: 'boolean',
    updatable: false,
    insertable: false,
    render: (e, fieldName) => {
      if (e[fieldName]) return "Sim";else return "Não";
    },
    renderStyle: (e, fieldName) => {
      if (e[fieldName]) return 'success';else return 'danger';
    }
  }, {
    groupName: 'Dados para validação',
    name: 'cloudfrontDistributionArn',
    updatable: false,
    insertable: false
  }, {
    groupName: 'Dados para validação',
    name: 'acmCertificateArn',
    updatable: false,
    insertable: false
  }],
  filter: [{
    field: 'enabled',
    op: 'eq'
  }]
});