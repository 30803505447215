import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { formatDateToDate, formatDateToLocaleDate, formatDateToLocaleTime } from '../../util/crudModelListViewUtils';
export default fillCrudMetadataGaps({
  label: "Solicitação",
  labelPlural: "Solicitações",
  name: 'GDS',
  updatable: true,
  insertable: true,
  faIcon: 'search-dollar',
  authorization: authorization('clientConsultant', null),
  'custom:sortFields': ['date desc'],
  key: 'gds',
  dynamoDB: {
    tableName: 'SpearGDS',
    indexName: 'tenantId-date-index',
    indexedProp: 'date',
    scanIndexBackwards: true
  },
  initialFilters: {
    status_in: ['NEW']
  },
  columns: ['id', 'origin', 'pnr', 'date', 'mainTravelerName', 'clientName', 'orderId', 'status', 'lastAction', 'lastActionDatetime', 'hasErrors'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    insertable: false,
    updatable: false,
    id: true
  }, {
    label: 'Viagem',
    name: 'orderId',
    type: 'text',
    insertable: false,
    updatable: false
  }, {
    label: 'Origem',
    name: 'origin',
    type: 'text',
    insertable: false,
    updatable: false,
    renderCustomColumnText: {
      title: {
        content: props => `PNR: ${props?.row?.pnr}`,
        style: {}
      },
      subTitle: {
        content: props => `${props?.row?.origin}`,
        style: {}
      }
    }
  }, {
    name: 'date',
    label: 'Data da solicitação',
    insertable: false,
    updatable: false,
    type: 'datetime',
    renderCustomColumnText: {
      title: {
        content: props => `${formatDateToLocaleDate(props?.row?.date)} ${formatDateToLocaleTime(props?.row?.date)}`,
        style: {}
      }
    }
  }, {
    name: 'items',
    label: 'Itens',
    type: 'objectarray',
    render: false,
    graphQLType: '[GDSItem]',
    graphQLInputType: '[GDSItemInput]',
    fields: [{
      name: 'type',
      options: [{
        value: 'hotel',
        label: 'Quarto de hotel'
      }]
    }, {
      name: 'hotel_id'
    }, {
      name: 'hotel_name'
    }, {
      name: 'time_startDate',
      type: 'date'
    }, {
      name: 'time_endDate',
      type: 'date'
    }, {
      name: 'time_startTime'
    }, {
      name: 'time_endTime'
    }, {
      name: 'priceComposition_total_value',
      type: 'float'
    }, {
      name: 'priceComposition_total_currency'
    }, {
      name: 'room_quantity',
      type: 'int'
    }, {
      name: 'flightNumber'
    }, {
      name: 'airlineCode'
    }, {
      name: 'fromAirportCode'
    }, {
      name: 'toAirportCode'
    }, {
      name: 'confirmationId'
    }]
  }, {
    name: 'travelers',
    label: 'Viajantes',
    type: 'objectarray',
    render: false,
    graphQLType: '[GDSTraveller]',
    graphQLInputType: '[GDSTravellerInput]',
    fields: [{
      name: 'name'
    }, {
      name: 'surname'
    }, {
      name: 'type'
    }]
  }, {
    name: 'lastAction',
    label: 'XXXX',
    renderColumn: false,
    options: [{
      accentColor: '#0C83D9',
      label: "Solicitação criada",
      value: 'CREATED',
      description: data => `Solicitação criada${data?.lastActionDatetime ? ` em ${format(parseISO(data?.lastActionDatetime), "dd/MM/yyyy 'às' HH:mm")}` : ''}`
    }, {
      accentColor: '#0C83D9',
      label: "PNR adicionado a fila",
      value: 'PNR_ADDED_TO_QUEUE',
      description: data => `PNR adicionado a fila${data?.lastActionDatetime ? ` em ${format(parseISO(data?.lastActionDatetime), "dd/MM/yyyy 'às' HH:mm")}` : ''}`
    }, {
      accentColor: '#FDBA01',
      label: "PNR removida da fila",
      value: 'PNR_REMOVED_FROM_QUEUE',
      description: data => `PNR removida da fila${data?.lastActionDatetime ? ` em ${format(parseISO(data?.lastActionDatetime), "dd/MM/yyyy 'às' HH:mm")}` : ''}`
    }, {
      accentColor: '#FDBA01',
      label: "PNR atualizado",
      value: 'PNR_UPDATED',
      description: data => `PNR atualizado${data?.lastActionDatetime ? ` em ${format(parseISO(data?.lastActionDatetime), "dd/MM/yyyy 'às' HH:mm")}` : ''}`
    }, {
      accentColor: '#FDBA01',
      label: "Segmento removido do PNR",
      value: 'SEGMENT_REMOVED',
      description: data => `Segmento removido do PNR${data?.lastActionDatetime ? ` em ${format(parseISO(data?.lastActionDatetime), "dd/MM/yyyy 'às' HH:mm")}` : ''}`
    }, {
      accentColor: '#53AC2A',
      label: "Reserva adicionada ao PNR",
      value: 'PNR_RESERVATION_ADDED',
      description: data => `Reserva adicionada ao PNR${data?.lastActionDatetime ? ` em ${format(parseISO(data?.lastActionDatetime), "dd/MM/yyyy 'às' HH:mm")}` : ''}`
    }]
  }, {
    name: 'lastActionDatetime',
    label: 'XXXXX',
    renderColumn: false
  }, {
    name: 'pnr',
    label: 'PNR',
    renderColumn: false
  }, {
    name: 'mainTravelerName',
    label: 'Passageiro Principal',
    renderCustomColumnText: {
      title: {
        content: props => `${props?.row?.mainTravelerName}`,
        style: {}
      },
      subTitle: {
        content: props => `${props?.row?.clientName}`,
        style: {}
      }
    }
  }, {
    name: 'clientId',
    label: 'clientId',
    renderColumn: false
  }, {
    name: 'clientName',
    label: 'Cliente',
    renderColumn: false
  }, {
    name: 'status',
    label: 'Estado da solicitação',
    type: 'text',
    options: [{
      label: "Novo",
      value: 'NEW',
      accentColor: '#0C83D9'
    }, {
      label: "Em Andamento",
      value: 'IN_PROGRESS',
      accentColor: '#FDBA01'
    }, {
      label: "Terminado",
      value: 'DONE',
      accentColor: '#53AC2A'
    }, {
      label: "Falho",
      value: 'FAILED',
      accentColor: '#FF0000'
    }],
    renderCustomColumnText: {
      subTitle: {
        content: props => props?.row?.hasErrors ? 'Erros durante processamento' : '',
        style: props => props?.row?.hasErrors ? {
          color: '#FF0000'
        } : {}
      }
    }
  }, {
    name: 'hasErrors',
    label: 'Tem erros',
    type: 'boolean'
  }, {
    name: 'gdsReadQueuePNRS',
    label: 'Fila Leitura (reservas)',
    type: 'textarray'
  }, {
    name: 'gdsReadCancellationQueuePNRS',
    label: 'Fila Leitura (cancelamentos)',
    type: 'textarray'
  }],
  filter: [{
    field: 'id',
    op: 'eq'
  }, {
    field: 'date',
    op: 'le'
  }, {
    field: 'date',
    op: 'ge'
  }, {
    field: 'status',
    op: 'eq'
  }]
});