import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import { VCN } from '../credential';
export default fillCrudMetadataGaps({
  label: 'Regra de VCN',
  labelPlural: 'Regras de VCN',
  name: 'VcnRule',
  namePlural: 'VcnRules',
  authorization: authorization('consultant', 'admin'),
  deletable: false,
  dynamoDB: {
    tableName: 'SpearVcnRule'
  },
  columns: ['id', 'name', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Nome',
    type: 'text',
    required: true
  }, {
    custom_groupName: 'Ativação',
    name: 'activationReference',
    label: 'Referencia',
    type: 'text',
    options: [{
      label: 'Check-in',
      value: 'CHECKIN'
    }, {
      label: 'Check-out',
      value: 'CHECKOUT'
    }, {
      label: 'Reserva',
      value: 'RESERVATION'
    }],
    required: true
  }, {
    custom_groupName: 'Ativação',
    name: 'activationDelta',
    label: 'Dias Antes ou Depois da Referencia',
    type: 'int',
    required: true
  }, {
    custom_groupName: 'Desativação',
    name: 'expirationReference',
    label: 'Referencia',
    type: 'text',
    options: [{
      label: 'Check-in',
      value: 'CHECKIN'
    }, {
      label: 'Check-out',
      value: 'CHECKOUT'
    }, {
      label: 'Reserva',
      value: 'RESERVATION'
    }],
    required: true
  }, {
    custom_groupName: 'Desativação',
    name: 'expirationDelta',
    label: 'Dias Antes ou Depois da Referencia',
    type: 'int',
    required: true
  }, {
    custom_groupName: 'Credencial',
    name: 'credentialId',
    label: 'Credencial de VCN',
    inputType: 'credential',
    type: 'text',
    credentialTypeList: VCN,
    required: true
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: false,
    required: true,
    type: 'boolean'
  }],
  filter: [{
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq'
  }]
});