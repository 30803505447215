import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Regra de desconto',
  labelPlural: 'Regras de desconto',
  name: 'DiscountRule',
  custom_withAudit: true,
  authorization: authorization('consultant', 'admin'),
  deletable: false,
  dynamoDB: {
    tableName: 'SpearDiscountRule'
  },
  columns: ['id', 'name', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Nome',
    type: 'text',
    required: true
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: false,
    required: true,
    type: 'boolean'
  }, {
    name: 'coupons',
    label: 'Cupons',
    required: true,
    hint: 'Selecione a lista de cupons',
    type: 'textarray',
    inputType: 'couponList',
    custom_auditUrl: () => ({
      url: '/coupons',
      label: 'Cupom'
    })
  }, {
    custom_groupName: "Limites de uso do cupom",
    name: 'couponLimitType',
    label: 'Limite de valor do cupom',
    required: true,
    options: [{
      value: 'NO_LIMIT',
      label: 'Sem limite de desconto do cupom'
    }, {
      value: 'MARKUP',
      label: 'Limitar o cupom até o valor do markup'
    }
    // {
    //   value: 'CLIENT_COMMISSION',
    //   label: 'Limitar o cupom até o valor da comissão do cliente (não funciona)',
    //   disabled: true,
    // },
    ]
  }, {
    custom_groupName: "Limites de uso do cupom",
    name: 'couponLimitBehavior',
    label: 'Tratamento de cupom acima do limite (NÃO USAR)',
    deprecated: true,
    render: false,
    required: false,
    options: [{
      value: 'DONT_ACCEPT',
      label: 'Não aceitar o cupom'
    }, {
      value: 'UP_TO_LIMIT',
      label: 'Aplicar o desconto até o limite permitido'
    }]
  }],
  filter: [{
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq'
  }]
});