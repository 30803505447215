import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import DEFAULT_GROUPS from '../defaultTagGroups';
export default fillCrudMetadataGaps({
  label: 'Marcador',
  labelPlural: 'Marcadores',
  name: 'Tag',
  insertable: true,
  updatable: true,
  deletable: false,
  authorization: authorization('consultant', 'admin'),
  columnNames: ['group', 'alias', 'client_id', 'color', 'enabled', 'id'],
  importColumnNames: ['group', 'alias', 'description', 'externalCode', 'travellerEnabled', 'reservationEnabled', 'motiveEnabled', 'color'],
  fields: [{
    label: 'Marcador',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    searchableText: true,
    render: false
  }, {
    name: 'alias',
    label: 'Marcador',
    insertable: true,
    required: true,
    searchableText: true,
    renderColumn: i => i.alias || i.id
  }, {
    name: 'group',
    label: 'Grupo',
    insertable: true,
    required: false,
    searchableText: true,
    options: DEFAULT_GROUPS,
    renderForm: i => i.client_id != null ? i['group'] : false
  }, {
    name: 'description',
    label: 'Descrição'
  }, {
    name: 'externalCode',
    label: 'Código Externo',
    hint: 'Este valor vai ser usado em integrações externas ou exportações'
  }, {
    name: 'client_id',
    label: 'Cliente',
    updatable: false,
    renderColumn: false,
    renderForm: false,
    required: false,
    id: true
  }, {
    name: 'clientEnabled',
    label: 'Cliente',
    default: false,
    type: 'boolean',
    renderForm: function (i) {
      return i.client_id ? false : this.render == false ? this.render : this.render(i);
    },
    hint: 'Marcador disponível no cadastro de clientes',
    custom_hiddenSelfBooking: true
  }, {
    name: 'travellerEnabled',
    label: 'Colaboradores',
    default: false,
    type: 'boolean',
    renderForm: function (i) {
      return !i.client_id ? false : this.render == false ? this.render : this.render(i);
    },
    hint: 'Marcador disponível no cadastro de colaboradores'
  }, {
    name: 'reservationEnabled',
    label: 'Reserva',
    default: true,
    type: 'boolean',
    hint: 'Marcador disponível para escolha no momento da reserva'
  }, {
    name: 'motiveEnabled',
    label: 'Motivo de viagem',
    default: false,
    type: 'boolean',
    renderForm: function (i) {
      return !i.client_id ? false : this.render == false ? this.render : this.render(i);
    },
    hint: 'Marcador disponível para o campo motivo da reserva'
  }, {
    name: 'color',
    label: 'Cor',
    required: false,
    inputType: 'color',
    hint: 'Use para ajudar na organização dos seus marcadores',
    placeholder: 'ex: #Eb3030'
    // mask: "#______"
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: true,
    type: 'boolean'
  }],
  filter: [{
    name: 'client_id_eq',
    field: 'client_id',
    op: 'eq',
    label: 'Client Id'
  }, {
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq',
    label: 'Ativo'
  }, {
    name: 'alias_eq',
    field: 'alias',
    op: 'eq',
    label: 'Marcador'
  }, {
    name: 'externalCode_eq',
    field: 'externalCode',
    op: 'eq',
    label: 'Código externo'
  }, {
    name: 'client_id_isNull',
    field: 'client_id',
    op: 'isNull',
    label: 'Client Id Nulo'
  }]
});