import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import { currencies } from '../currencyExchangeRule';
export default fillCrudMetadataGaps({
  label: 'Preço de Venda',
  labelPlural: 'Preços de Venda',
  name: 'MarkupRule',
  namePlural: 'MarkupRules',
  authorization: authorization('consultant', 'admin'),
  custom_withAudit: true,
  deletable: false,
  dynamoDB: {
    tableName: 'SpearMarkupRule'
  },
  columns: ['id', 'name', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Nome da Regra',
    required: true
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: false,
    required: true,
    type: 'boolean'
  }, {
    custom_groupName: "Moeda",
    name: 'sellCurrency',
    label: 'Moeda de venda',
    inputType: 'select',
    default: 'SUPPLIER',
    options: [{
      value: 'SUPPLIER',
      label: 'Moeda do Fornecedor'
    }, ...currencies],
    hint: 'Depende de configuração de câmbio',
    custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme
  }, {
    custom_groupName: "Moeda de Exibição",
    name: 'sortCurrency',
    label: 'Moeda de comparação',
    type: 'text',
    inputType: 'select',
    options: currencies,
    default: null,
    hint: 'Depende de configuração de câmbio',
    renderForm: i => i.sellCurrency === 'SUPPLIER'
  }, {
    name: 'markupEnabled',
    label: 'Aplicar markup',
    required: true,
    type: 'boolean',
    custom_groupName: "Markup",
    custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme
  }, {
    name: 'markupValue',
    label: '% do markup',
    type: 'float',
    inputType: 'percentage',
    custom_groupName: "Markup",
    renderForm: i => i.markupEnabled == true,
    custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme
  }, {
    name: 'markupAppliesOnTaxes',
    label: 'Aplicar markup sobre taxas',
    type: 'boolean',
    custom_groupName: "Markup",
    renderForm: i => i.markupEnabled == true,
    custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme
  }, {
    name: 'markupCommissionUse',
    label: 'Comissão do Fornecedor',
    options: [{
      label: 'Subtrair comissão para aplicar markup',
      value: 'DEDUCT'
    }, {
      label: 'Markup Dinâmico',
      value: 'DYNAMIC',
      hint: 'Calcula markup que deve ser aplicado, já considerando a comissão do fornecedor'
    }, {
      label: 'Aplicar markup sobre tarifa comissionada',
      value: 'NONE'
    }],
    default: 'NONE',
    custom_groupName: "Markup",
    renderForm: i => i.markupEnabled == true,
    custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme,
    onEntityChange(oldEntity, newEntity) {
      if (newEntity?.markupCommissionUse !== oldEntity?.markupCommissionUse && newEntity?.markupCommissionUse && newEntity?.markupCommissionUse !== 'NONE') {
        return {
          ...newEntity,
          markupReductionAllowed: null
        };
      }
      return newEntity;
    }
  }, {
    name: 'markupDisplayLocation',
    label: 'Markup somado em',
    custom_groupName: "Markup",
    options: [{
      label: 'Tarifa',
      value: 'DEFAULT'
    }, {
      label: 'Taxas',
      value: 'TAXES'
    }, {
      label: 'Em destaque',
      value: 'HIGHLIGHT'
    }],
    default: 'DEFAULT',
    renderForm: i => i.markupEnabled == true,
    custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme,
    onEntityChange(oldEntity, newEntity) {
      if (newEntity?.markupDisplayLocation !== oldEntity?.markupDisplayLocation && newEntity?.markupDisplayLocation !== 'DEFAULT') {
        return {
          ...newEntity,
          markupReductionAllowed: null
        };
      }
      return newEntity;
    }
  }, {
    deprecated: true,
    name: 'markupDisplayText',
    label: 'Texto "Destaque"',
    custom_groupName: "Markup",
    renderForm: i => i.markupEnabled == true,
    custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme
  }, {
    name: 'markupReductionAllowed',
    label: 'Permite reduzir markup',
    type: 'boolean',
    custom_groupName: "Markup",
    hint: 'Só funciona se markup é somado à tarifa',
    renderForm: i => i.markupDisplayLocation === 'DEFAULT' && !(i.markupCommissionUse && i.markupCommissionUse !== 'NONE')
  }, {
    name: 'feeEnabled',
    label: 'Aplicar Fee Adicional',
    required: true,
    type: 'boolean',
    custom_groupName: "Fee",
    custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme
  }, {
    name: 'feeValue',
    label: 'Valor do fee',
    type: 'float',
    inputType: 'currency',
    custom_groupName: "Fee",
    renderForm: i => i.feeEnabled == true,
    custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme
  }, {
    name: 'feeCurrency',
    label: 'Moeda do fee',
    inputType: 'select',
    options: currencies,
    custom_groupName: "Fee",
    renderForm: i => i.feeEnabled == true,
    custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme
  }, {
    name: 'feeType',
    label: 'Calcular fee',
    type: 'text',
    options: [{
      label: 'Por diária',
      value: 'DAILY'
    }, {
      label: 'Por reserva',
      value: 'TOTAL'
    }],
    custom_groupName: "Fee",
    renderForm: i => i.feeEnabled == true,
    custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme
  }, {
    name: 'feeDisplayLocation',
    label: 'Onde exibir fee',
    custom_groupName: "Fee",
    options: [{
      label: 'Tarifas',
      value: 'DEFAULT'
    }, {
      label: 'Taxas',
      value: 'TAXES'
    }, {
      label: 'Em destaque',
      value: 'HIGHLIGHT'
    }],
    renderForm: i => i.feeEnabled == true,
    custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme
  }, {
    deprecated: true,
    name: 'feeDisplayText',
    label: 'Texto "Destaque"',
    custom_groupName: "Fee",
    renderForm: i => i.feeEnabled == true,
    custom_checkTenant: t => t && t.modules_engineRuleOptIn_deleteme
  }],
  filter: []
});