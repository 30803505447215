import type { Role } from '@niaratech/niara-auth-client';
export default function (read: Role | Role[], write?: false | Role | Role[]): {
  read: Array<Role>;
  write?: Array<Role>;
} {
  write = write !== undefined ? write : read;
  return {
    read: Array.isArray(read) ? read : [read],
    write: Array.isArray(write) ? write : write ? [write] : undefined
  };
}