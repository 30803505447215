import callApi from '@niarab2c/frontend-commons/src/util/callApi';
import queryString from 'query-string';
import type { Storefront } from '@niarab2c/otabuilder-crudmodel/src';
export type StorefrontWithTenantId = Storefront & {
  tenantId: string;
};
const PREFIXED_DEFAULT_URL = /([\w-]+)\.travel365marketplace\.com$/;
const DEFAULT_URL = process.env.NODE_ENV === 'development' ? /(localhost|bs-local\.com|(?:[0-9]{1,}.){3}[0-9]{1,})$/ : process.env.DEV_OR_HOMOLOG_OR_PROD === 'dev' ? /(app\.niarab2cdev\.niara\.tech|localhost|bs-local\.com)$/ : process.env.DEV_OR_HOMOLOG_OR_PROD === 'homolog' ? /(app\.niarab2chomolog\.niara\.tech)$/ : /(app\.otabuilder\.com)$/;
export const fetchStorefront = async (locator: string): Promise<StorefrontWithTenantId> => {
  if (!locator) return null;
  try {
    const storefront: StorefrontWithTenantId = (await callApi('otabuilder-backend', `/storefronts/${encodeURIComponent(locator)}`)) || (await callApi('otabuilder-backend', `/urls?${queryString.stringify({
      url: locator
    })}`));
    return storefront;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
};
const loadStorefront = async (): Promise<[StorefrontWithTenantId, boolean]> => {
  const [hostname, path] = [location.hostname, location.pathname?.split('/')[1]];
  return new Promise((resolve, reject) => {
    // URL customizada com subdomínio (ex: www.meudominiocustomizado.com/localizadordapagina)
    if (PREFIXED_DEFAULT_URL.test(hostname)) {
      const prefixedUrlExec = PREFIXED_DEFAULT_URL.exec(hostname);
      fetchStorefront(prefixedUrlExec?.[1]).then(storefront => {
        if (storefront) {
          resolve([storefront, true]);
          return;
        } else {
          resolve([null, undefined]);
          return;
        }
      }).catch(err => {
        reject(err);
      });
    } else if (DEFAULT_URL.test(hostname)) {
      // URL padrão - procurar storefront pelo path
      fetchStorefront(path).then(storefront => {
        if (storefront) {
          resolve([storefront, false]);
          return;
        } else {
          resolve([null, undefined]);
          return;
        }
      }).catch(err => {
        reject(err);
      });
    } else if (path) {
      // URL customizada
      // suporte a custom domain com subdomínio (ex: www.meudominiocustomizado.com/localizadordapagina)
      fetchStorefront(`${hostname}/${path}`).then(storefront => {
        if (storefront) {
          resolve([storefront, false]);
          return;
        } else {
          return fetchStorefront(`${hostname}`).then(storefront => {
            if (storefront) {
              resolve([storefront, true]);
              return;
            } else {
              resolve([null, undefined]);
            }
          });
        }
      }).catch(err => {
        reject(err);
      });
    } else {
      fetchStorefront(`${hostname}`).then(storefront => {
        if (storefront) {
          resolve([storefront, true]);
          return;
        } else {
          resolve([null, undefined]);
        }
      }).catch(err => {
        reject(err);
      });
    }
  });
};
const loadStorefrontWithCache = async (): Promise<[StorefrontWithTenantId, boolean]> => {
  if (window?.['_OTABUILDER_CACHED_STOREFRONT_']) return window['_OTABUILDER_CACHED_STOREFRONT_'];
  return loadStorefront().then(res => {
    window['_OTABUILDER_CACHED_STOREFRONT_'] = res;
    return res;
  });
};
export default loadStorefrontWithCache;