import { PriceComponent } from '@niarab2c/frontend-commons/src/types/hotels';
import { NiaraIcon } from '@niaratech/niara-icons';
import { printPrice, formatDate } from '@niaratech/niara-js-commons';
import { addDays } from '@niaratech/niara-js-commons/dist/dateUtils';
import React from 'react';
import { Text, ToolTip } from '../../microComponents';
import * as S from './styles';
export type PolicyProps = {
  penaltyDate?: string;
  nonRefundable?: boolean | string;
  inPenalty?: boolean | string;
  textSize?: number;
  fontWeight?: any;
  policyDescription?: Description[];
  showPricePolicy?: boolean;
  priceComposition?: {
    penalty?: PriceComponent;
    total?: PriceComponent;
  };
  dateFormat?: string;
  disableIcon?: boolean;
};
type Description = {
  name?: string;
  description?: string;
};
const Policy: React.FC<PolicyProps> = ({
  penaltyDate,
  nonRefundable,
  inPenalty,
  policyDescription,
  textSize,
  fontWeight = 700,
  showPricePolicy = true,
  priceComposition,
  disableIcon,
  dateFormat = "dd/MM/yy 'às' HH:mm"
}) => {
  const color = nonRefundable || inPenalty ? 'danger' : 'priceColor';
  const propStyle = {
    textColor: color,
    fontWeight: fontWeight,
    textSize: textSize
  };
  const renderTooltip = (contents: JSX.Element) => {
    if (showPricePolicy) {
      return <ToolTip enterTouchDelay={0} placement="right" PopperProps={{
        popperOptions: {
          placement: 'auto'
        }
      }} title={<S.ContainerTooltip>
              {penaltyDate && <Text as="p">Fuso horário do navegador</Text>}
              {priceComposition?.total?.value && <>
                  <span>Taxa após prazo: </span>
                  <b>{printPrice(priceComposition?.total?.value, priceComposition?.total?.currency)}</b>
                </>}
              {policyDescription?.map(description => <React.Fragment key={description?.name}>
                  <Text as="h1" mt={10} fontSize={13} mb={10} fontWeight="700">
                    {description?.name}
                  </Text>
                  <Text as="p">{description?.description}</Text>
                </React.Fragment>)}
            </S.ContainerTooltip>}>
          {contents}
        </ToolTip>;
    } else {
      return contents;
    }
  };
  return renderTooltip(<S.PolStyle style={showPricePolicy ? {
    cursor: 'pointer'
  } : {}} className="policy-container">
      {nonRefundable ? <S.PolItem {...propStyle}>
          <>
            {disableIcon ? null : <>
                <NiaraIcon icon="sysNoRefundable" />
                &nbsp;
              </>}
            <span>Não reembolsável</span>
            {showPricePolicy && <>
                &nbsp;
                <NiaraIcon color="#9C9C9C" icon="sysInfo" />
              </>}
          </>
        </S.PolItem> : inPenalty ? <S.PolItem {...propStyle}>
          <>
            {disableIcon ? null : <>
                <NiaraIcon icon="sysClose" />
                &nbsp;
              </>}
            <span>Cancelamento com multa</span>
            {showPricePolicy && <>
                &nbsp;
                <NiaraIcon color="#9C9C9C" icon="sysInfo" />
              </>}
          </>
        </S.PolItem> : penaltyDate ? <S.PolItem {...propStyle}>
          <>
            {disableIcon ? null : <>
                <NiaraIcon icon="sysCheck" />
                &nbsp;
              </>}
            <span>
              Cancelamento grátis até{' '}
              {formatDate(penaltyDate.length == 10 /* não tenho precisamente o horário da data de cancelamento */ ? addDays(penaltyDate, -1) : addDays(penaltyDate, -1 / (24 * 60)), undefined, dateFormat)}
            </span>
            {showPricePolicy && <>
                &nbsp;
                <NiaraIcon color="#9C9C9C" icon="sysInfo" />
              </>}
          </>
        </S.PolItem> : null}
    </S.PolStyle>);
};
export default Policy;