import { currentPersonDAO, orderDAO, personDAO, selfOrderDAO, shallowClientDAO } from '@niarab2c/frontend-commons/src/daos';
import type { CheckoutParams, CheckoutPaymentParameters, CheckoutPaymentParametersPayload, CheckoutPaymentParameters_PaymentOption, CheckoutResults, CreditCard, ExtraFields, HotelItem, HotelItemGuest, LegacyHotelItem, PreCheckoutParams, SecretPayment_Payment } from '@niarab2c/frontend-commons/src/types/checkout';
import type { EngineRuleRoomRate, HotelResult, LegacyRoomRate, Occupancy, RoomRate } from '@niarab2c/frontend-commons/src/types/hotels';
import { getGA4Session, trackEventAddItemToCart, trackEventError, trackEventRemoveItemFromCart } from '@niarab2c/frontend-commons/src/util/analyticsEvents';
import callApi from '@niarab2c/frontend-commons/src/util/callApi';
import { loyalty, type AutomaticCancellationRule, type BuyerPaymentRule, type CheckoutFlowRule, type CheckoutFormRule, type CurrentPerson, type HotelPostSearchRule, type HotelReservation, type Order, type Person, type SelfOrder, type ShallowClient, type User } from '@niarab2c/niara-spear-crudmodel';
import { LoyaltyOption } from '@niarab2c/ota-components/src/HotelSearch/Criteria/HotelCriteria';
import type { Storefront } from '@niarab2c/otabuilder-crudmodel';
import { loop } from '@niaratech/niara-js-commons';
import { createPaymentRequestFunction } from '@niaratech/payment-form/src';
import { PayloadAction, createAsyncThunk, createSlice, isAnyOf, unwrapResult } from '@reduxjs/toolkit';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import { default as _isEqual, default as isEqual } from 'lodash/isEqual';
import _isMatch from 'lodash/isMatch';
import _merge from 'lodash/merge';
import _pick from 'lodash/pick';
import { nanoid } from 'nanoid/non-secure';
import { shallowEqual } from 'react-redux';
import getRecaptchaToken from '../../recaptcha';
import encryptCreditCard from '../../util/encryptCreditCard';
import { BaseRootState, CompleteRootState, Dispatch } from '../base';
import * as Authentication from './authentication';
import { searchHotels } from './hotel/hotelSearch';
import * as Loyalty from './loyalty';
type Card = {
  brand: string;
  /**
   * @deprecated
   */
  maximumInstallments: number;
  maxInst: number;
  minInstValue: number;
  description?: string;
};
type Balance = {
  balance?: LoyaltyOption;
  loyaltyOptions?: LoyaltyOption[];
};
type CheckoutPaymentOption = EngineRuleRoomRate['paymentOptions'][number];
type LoyaltyRewards = CheckoutPaymentParameters['loyaltyRewards']['BRL'];
export type { CreditCard };

/**
 * Este objeto é criado a partir dos paymentOptions recebidos da pesquisa de disponibilidade,
 * listPaymentOptions (engineRuleVersion 1) e checkoutPaymentParameters (engineRuleVersion 0)
 */
export type PaymentOption = Pick<CheckoutPaymentOption, 'id' | 'type' | 'alias' | 'card' | 'enabled'> & Pick<Partial<CheckoutPaymentOption>, 'credential' | 'disabledReason' | 'paymentCurrency' | 'paymentValue' | 'description'> & {
  external?: boolean;
  cards?: Card[];
  currency?: string;
  total?: number;
  extraOptions?: Array<{
    id: string;
    type: 'EXTRA_ALCOHOL' | 'EXTRA' | 'NO_EXTRA' | 'EXTRA_ALCOHOL_CIGAR' | 'NO_TOURISM_TAX' | 'LAUNDRY';
  }>;
  includeExtraOptions?: Array<{
    id: string;
    label: string;
  }>;
  loyaltyRedeemPaidItems?: Array<{
    itemIndex: number;
    amount: number;
    currency: string;
  } | {
    id: string;
    type: 'hotelReservation';
    amount: number;
    currency: string;
  }>;
  paidItems?: Array<{
    itemIndex: number;
    amount: number;
    currency: string;
  } | {
    id: string;
    type: 'hotelReservation';
    amount: number;
    currency: string;
  }>;
};
interface BaseCartItem {
  type: string;
  validUntil: number;
}
type BaseHotelRoomItem = BaseCartItem & {
  type: 'hotel';
  occupancy: Occupancy;
  roomRateIds: [string];
  startDate: string;
  endDate: string;
  quantity: number;
  hotelId: string;
  hotelResult: Pick<HotelResult, 'clientId' | '_id' | 'hotel' | '_soupIds'>;
  currency: string;
  itemId: string;
  roomType: string;
  promoCode?: string;
  clientId: string;
  clientCommission_original?: number;
  markup_original?: number;
  discountedClientCommissionPercentage?: number;
  discountedMarkupPercentage?: number;
  engineRuleVersion: string;
  refreshCartInProgress?: boolean;
};
type LegacyRoomItem = BaseHotelRoomItem & {
  roomRates: [LegacyRoomRate];
  previousRoomRates?: [LegacyRoomRate];
  engineRuleVersion: LegacyRoomRate['engineRuleVersion'];
};
type EngineRuleRoomItem = BaseHotelRoomItem & {
  roomRates: [EngineRuleRoomRate];
  previousRoomRates?: [EngineRuleRoomRate];
  engineRuleVersion: EngineRuleRoomRate['engineRuleVersion'];
};
export type HotelRoomItem = LegacyRoomItem | EngineRuleRoomItem;
export type CartItem = HotelRoomItem & {
  notFoundInRefreshCart?: boolean;
};
export type PersonFields = {
  firstName?: string;
  lastName?: string;
  email?: string;
  country?: string;
  nationality?: string;
  cpf?: string;
  phone?: string;
  /**
   * @deprecated
   */
  postalCode?: string;
  addressCity?: string;
  addressComplement?: string;
  addressCountryCode?: string;
  addressDistrict?: string;
  addressNumber?: string;
  addressState?: string;
  addressStreet?: string;
  addressZipCode?: string;
  dob?: string;
  passport?: string;
  personId?: string;
  _emailConfirm?: string;
};
type ResponsibleProps = {
  responsible?: string;
  responsibleEmail?: string;
  responsibleName?: string;
};
type RoomGuests = {
  itemId: string;
  guests: PersonFields[];
};
export interface PersonalData {
  buyer: PersonFields;
  payer?: PersonFields;
  primaryGuest?: PersonFields;
  travelReason?: string;
  primaryGuestFormEnabled?: boolean;
  primaryPayerFormEnabled?: boolean;
  roomGuests?: Record<string, RoomGuests>;
}
type Responsible = Pick<User, 'id' | 'name' | 'email'>;
type PayLaterOptionByRule = Pick<AutomaticCancellationRule, 'payLaterAlias' | 'payLaterDescription'>;
export type ShoppingCartState = {
  savestatePrefix?: string;
  items: CartItem[];
  refreshInProgress?: boolean;
  checkoutInProgress?: boolean;
  total: {
    value: number;
    taxes: number;
    currency: string;
    discounts?: number;
  }; // valor total da reserva
  paymentValue: number;
  paymentOptions?: PaymentOption[];
  /**
   * Usar apenas para o refresh do carrinho, não para o checkout
   * @deprecated
   */
  paymentOptionIdForCartRefresh?: string;
  /**
   * Usar apenas para o refresh do carrinho, não para o checkout
   */
  paymentOptionTypeForCartRefresh?: string;
  personalData: Partial<PersonalData>;
  loyaltyRedeemId?: string;
  loyaltyRedeemUnitText?: string;
  loyaltyRedeemType?: 'POINTS' | 'CASHBACK';
  loyaltyRedeemCurrency?: string;
  loyaltyRedeemPointsFractionDigits?: number;
  maxPointsToBurn?: number; // número máximo de pontos possível
  minPointsToBurn?: number; // número mínimo de pontos possível
  pointsToBurn?: number; // número de pontos escolhidos pelo usuário
  loyaltyId?: string; //id do resultado do authorize burn
  loyaltyNeedCode?: boolean; //se authorize burn exige código
  loyaltyRewardId?: string; // id do loyaltyRewardId escolhido/disponível
  loyaltyCodeCheckInProgress?: boolean;
  loyaltyRewardValue?: number;
  loyaltyRewardType?: 'POINTS' | 'CASHBACK';
  loyaltyRewardUnitText?: string;
  loyaltyRewardCurrency?: string;
  checkoutFormRule?: CheckoutFormRule;
  checkoutFlowRule?: CheckoutFlowRule;
  responsible?: Responsible;
  clientId?: string;
  maxItemCount: number;
  orderId?: string;
  previousOrderId?: string;
  previousOrder?: SelfOrder;
  previousOrderClient?: ShallowClient;
  quotationId?: string;
  couponCode?: string;
  extraFields?: ExtraFields;
  purchaseSessionId?: string;
  person?: Person | CurrentPerson;
  personBalance?: number;
  personContractStatus?: 'ACTIVE' | 'INACTIVE' | 'BLOCKED';
  personBalanceUnit?: string;
  personId?: string;
  personTSER_idContrato?: number;
  personTSER_numeroContrato?: string;
  buyerPaymentRule?: BuyerPaymentRule;
  hotelPostSearchRule?: HotelPostSearchRule;
  payLaterOptionByRule?: PayLaterOptionByRule;
  engineRuleVersion?: '0' | '1';
};
const SAVED_CART_KEY = 'otabuilder-savedCart';
const SAVED_CART_PURCHASE_SESSION_ID_KEY = 'otabuilder-savedCart-purchaseSessionId';
const SAVED_PERSONAL_DATA = 'otabuilder-savedPersonalData';
const testLocalStorage = (): boolean => {
  const mod = 'ignoreme';
  try {
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);
    return true;
  } catch (e) {
    return false;
  }
};
const LOCAL_STORAGE_AVAILABLE = testLocalStorage();

/**
 * Cria cópia do hotel result apenas com clientId, _id e hotel (para reduzir o tamanho do shopping cart serializado)
 * @param item
 * @returns
 */
const clearCartItemBeforeAddingToCart = (item: HotelRoomItem & {
  hotelResult: HotelResult;
}): HotelRoomItem => {
  const hotelResult = item.hotelResult;
  const hotelId = item.hotelId;
  const hotel = hotelResult?._hotelByHotelId?.[hotelId] ?? hotelResult?.hotel;
  return {
    ...item,
    hotelResult: hotelResult && {
      ..._pick(hotelResult, 'clientId', '_id', '_soupIds'),
      hotel
    }
  };
};

/**
 *
 */
export const getMaxRoomUnits = (roomRate: RoomRate, shoppingCartItems: ShoppingCartState['items']): number => {
  //Funcao não deixa adicionar mais de uma unidade de um mesmo quarto e levando em consideração o tipo de credencial
  const credentialId = roomRate?.credential?.id;
  /* Caso não venha a quantidade de units do fornecedor (ex: Restel), permite adicionar só 1 */
  const availableUnits = roomRate?.roomType?.units ?? 1;
  const unitsInCart = shoppingCartItems.reduce((acc, item) => {
    if (item.roomRates[0].roomType?.id === roomRate.roomType?.id && credentialId === item.roomRates[0]?.credential?.id && item.roomRates[0]?.id !== roomRate.id) {
      return acc + item.quantity;
    }
    return acc;
  }, 0);
  const totalUnits = availableUnits - unitsInCart;
  return totalUnits;
};
const loadState = (savestatePrefix: string): Partial<ShoppingCartState> => {
  const state: Partial<ShoppingCartState> = {};
  if (LOCAL_STORAGE_AVAILABLE && savestatePrefix) {
    if (localStorage.getItem(savestatePrefix + '::' + SAVED_PERSONAL_DATA) != null) {
      try {
        const savedPersonalData = JSON.parse(localStorage.getItem(savestatePrefix + '::' + SAVED_PERSONAL_DATA));
        state.personalData = {
          ...savedPersonalData,
          roomGuests: {},
          primaryGuest: null
        };
      } catch {
        // ignore
      }
    }
    if (localStorage.getItem(savestatePrefix + '::' + SAVED_CART_KEY) != null) {
      try {
        const savedCart = JSON.parse(localStorage.getItem(savestatePrefix + '::' + SAVED_CART_KEY));
        const items = savedCart.items?.filter(i => i.validUntil > Date.now());
        state.items = items;
        state.paymentOptionIdForCartRefresh = savedCart?.paymentOptionIdForCartRefresh;
        state.paymentOptionTypeForCartRefresh = savedCart?.paymentOptionTypeForCartRefresh;
      } catch {
        // ignore
      }
    }

    // se eu ler este valor, preciso limpar depois
    // const purchaseSessionId = localStorage.getItem(savestatePrefix + '::' + SAVED_CART_PURCHASE_SESSION_ID_KEY)
    // if (purchaseSessionId != null && purchaseSessionId != 'undefined' && purchaseSessionId != 'null') {
    //   try {
    //     state.purchaseSessionId = purchaseSessionId
    //   } catch {
    //     // ignore
    //   }
    // }
  }
  return state;
};
const savePersonalData = (savestatePrefix: string, personalData: Partial<PersonalData>) => {
  if (LOCAL_STORAGE_AVAILABLE && savestatePrefix) localStorage.setItem(savestatePrefix + '::' + SAVED_PERSONAL_DATA, JSON.stringify(personalData));
};
const saveCartItems = (savestatePrefix: string, items: ShoppingCartState['items'], purchaseSessionId: ShoppingCartState['purchaseSessionId'], paymentOptionIdForCartRefresh?: ShoppingCartState['paymentOptionIdForCartRefresh'], paymentOptionTypeForCartRefresh?: ShoppingCartState['paymentOptionTypeForCartRefresh']) => {
  if (LOCAL_STORAGE_AVAILABLE && savestatePrefix) {
    localStorage.setItem(savestatePrefix + '::' + SAVED_CART_KEY, JSON.stringify({
      items,
      paymentOptionIdForCartRefresh,
      paymentOptionTypeForCartRefresh
    }));
    if (purchaseSessionId && purchaseSessionId != 'undefined' && purchaseSessionId != 'null') {
      localStorage.setItem(savestatePrefix + '::' + SAVED_CART_PURCHASE_SESSION_ID_KEY, purchaseSessionId);
    }
  }
};
const deleteCartItems = (savestatePrefix: string) => {
  if (LOCAL_STORAGE_AVAILABLE && savestatePrefix) localStorage.removeItem(savestatePrefix + '::' + SAVED_CART_KEY);
};
const deletePersonalData = (savestatePrefix: string) => {
  if (LOCAL_STORAGE_AVAILABLE && savestatePrefix) localStorage.removeItem(savestatePrefix + '::' + SAVED_PERSONAL_DATA);
};
const createState = (): ShoppingCartState => {
  const state = {
    quotationId: undefined,
    orderId: undefined,
    personalData: {},
    items: [],
    loyaltyRewardOptIn: false,
    checkoutFormRule: ({
      primaryGuestAllowed: true,
      buyerAllowed: true,
      buyerForm: {
        firstNameRequested: true,
        firstNameRequired: true,
        lastNameRequested: true,
        lastNameRequired: true,
        emailRequested: true,
        emailRequired: true,
        phoneRequested: true,
        phoneRequired: true,
        documentRequested: true,
        documentRequired: true
      },
      buyerSpecificRequested: true,
      primaryGuestForm: {
        firstNameRequested: true,
        firstNameRequired: true,
        lastNameRequested: true,
        lastNameRequired: true,
        emailRequested: true,
        emailRequired: true,
        phoneRequested: true
      }
    } as CheckoutFormRule),
    maxItemCount: 1,
    purchaseSessionId: nanoid()
  };
  return _makeTotal({
    ...state
  });
};
const DEFAULT_ITEM_VALIDITY = 1000 * 60 * 10; // 10 minutos, tempo arbitrariamente escolhido por mim

export const loadCheckoutFormRule = createAsyncThunk<CheckoutFormRule, void, {
  state: CompleteRootState;
}>('shoppingCart/loadCheckoutFormRule', async (ignore, {
  getState
}) => {
  // Agora com a unificação pego o clientId do hotelSeach ou do core
  const clientId = getState().core?.clientId || getState().quotation?.quotation?.clientId || getState().shoppingCart?.clientId || getState().authentication?.user?.clientId;
  const landingPageId = getState().storefrontConfig?.storefront?.landingPageId;
  const result = await callApi('niara-spear-commons', `/genericRuleResolver/checkoutFormRule/details`, 'get', {
    params: {
      clientId,
      landingPageId
    } // TODO complementar o contexto com mais dados (hotel, data, etc)
  });
  if (result?.checkoutFormRule) {
    return result?.checkoutFormRule;
  }
  return null;
});
export const loadBuyerPaymentRule = createAsyncThunk<BuyerPaymentRule, void, {
  state: CompleteRootState;
}>('shoppingCart/loadBuyerPaymentRule', async (ignore, {
  getState
}) => {
  // Agora com a unificação pego o clientId do hotelSeach ou do core
  const clientId = getState().core?.clientId || getState().quotation?.quotation?.clientId || getState().shoppingCart?.clientId || getState().authentication?.user?.clientId;
  const landingPageId = getState().storefrontConfig?.storefront?.landingPageId;
  const result = await callApi('niara-spear-commons', `/genericRuleResolver/buyerPaymentRule/details`, 'get', {
    params: {
      clientId,
      landingPageId
    } // TODO complementar o contexto com mais dados (hotel, data, etc)
  });
  if (result?.buyerPaymentRule) {
    return result?.buyerPaymentRule;
  }
  return null;
});
export const applyCouponCode = createAsyncThunk<void, {
  couponCode: string;
}, {
  state: CompleteRootState;
}>('shoppingCart/applyCouponCode', async ({
  couponCode
}, {
  dispatch
}) => {
  dispatch(setCouponCode({
    couponCode
  }));
  await dispatch(refreshCart({
    couponCode
  }));
});
export const loadCheckoutFlowRule = createAsyncThunk<CheckoutFlowRule, {
  clientId?: string;
}, {
  state: CompleteRootState;
}>('shoppingCart/loadCheckoutFlowRule', async (props, {
  getState
}) => {
  props = props || {};
  // Agora com a unificação pego o clientId do hotelSeach ou do core
  const clientId = props?.clientId ?? getState().core?.clientId ?? getState().hotel?.hotelDetails?.hotelResult?.clientId;
  const landingPageId = getState().storefrontConfig?.storefront?.landingPageId;
  const result = await callApi('niara-spear-commons', `/genericRuleResolver/checkoutFlowRule/details`, 'get', {
    params: {
      clientId,
      landingPageId
    } // TODO complementar o contexto com mais dados (hotel, data, etc)
  });
  if (result?.checkoutFlowRule) {
    return result?.checkoutFlowRule;
  }
  return null;
});
export const refreshCart = createAsyncThunk<UpdatePaymentParametersReturn, void | {
  couponCode?: string;
  promoCode?: string;
  isThirdPartyReservation?: boolean;
  paymentOptionId?: string;
  paymentOptionType?: string;
}, {
  state: CompleteRootState;
}>('shoppingCart/refreshCart', async (params, {
  getState,
  dispatch
}) => {
  // faz nova busca dos resultados no carrinho
  const rootState = getState();
  const items = rootState.shoppingCart.items;
  const isThirdPartyReservation = params && params.isThirdPartyReservation != null ? params.isThirdPartyReservation : rootState.shoppingCart?.personalData?.primaryGuestFormEnabled;
  const couponCode: string | null = (params && params?.couponCode) ?? rootState?.shoppingCart?.couponCode;
  const paymentOptionId = (params && params.paymentOptionId) ?? rootState?.shoppingCart?.paymentOptionIdForCartRefresh ?? null;
  const paymentOptionType = (params && params.paymentOptionType) ?? rootState?.shoppingCart?.paymentOptionTypeForCartRefresh ?? null;
  const loyaltyRedeemId = rootState.shoppingCart?.loyaltyRedeemId;
  const pointsToBurn = loyaltyRedeemId ? rootState.shoppingCart?.pointsToBurn : null;
  let dividedPointsToBurn = null;
  if (pointsToBurn > 0) {
    const maxPointsToBurn = items.reduce((acc, item) => acc + item.roomRates[0].loyaltyRedeems[0].maxPointsToBurn, 0);
    const fractionDigits = items?.[0]?.roomRates?.[0]?.loyaltyRedeems?.[0]?.fractionDigits;
    dividedPointsToBurn = items.map(item => Math.round(pointsToBurn * (item.roomRates[0].loyaltyRedeems[0].maxPointsToBurn / maxPointsToBurn) * 10 ** fractionDigits) / 10 ** fractionDigits);
    const sumOfDividedPointsToBurn = dividedPointsToBurn.reduce((acc, item) => acc + item, 0);
    const resto = pointsToBurn - sumOfDividedPointsToBurn;
    dividedPointsToBurn[0] = Math.round((dividedPointsToBurn[0] + resto) * 10 ** fractionDigits) / 10 ** fractionDigits;
  }
  await Promise.all(items.map(async (cartItem, index) => {
    const engineRuleVersion = cartItem.engineRuleVersion;
    // TODO: se cartItem tiver manualReservationId, precisa fazer pesquisa
    // pelo /hotels/manual/{manualReservationId}/availability do 'niara-spear-booking' feat #157743

    const promoCode: string | null = (params && params?.promoCode) ?? cartItem?.promoCode;
    await dispatch(searchHotels({
      searchId: cartItem.itemId,
      // trick - coloca o itemId como searchId para reconhecer no builder
      criteriaForm: {
        rooms: [{
          ...cartItem.occupancy
        }],
        startDate: cartItem.startDate,
        endDate: cartItem.endDate,
        destinations: {
          hotelIds: [cartItem.hotelId]
        },
        roomRateIds: cartItem.roomRateIds,
        bestOnly: false,
        promoCode,
        couponCode,
        isThirdPartyReservation,
        clientId: cartItem.clientId,
        personId: rootState?.shoppingCart?.personalData?.buyer?.personId ?? rootState?.shoppingCart?.personId
      },
      engineRuleVersion,
      manualReservationId: cartItem?.roomRates[0]?.manualReservationId,
      quotationToken: cartItem?.roomRates[0]?.quotationToken,
      quotationIndex: cartItem?.roomRates[0]?.quotationIndex,
      discountedClientCommissionPercentage: cartItem?.discountedClientCommissionPercentage,
      discountedMarkupPercentage: cartItem?.discountedMarkupPercentage,
      paymentOptionId,
      paymentOptionType,
      loyaltyRedeemId,
      pointsToBurn: dividedPointsToBurn?.[index]
    }));
  }));
  return await dispatch(updatePaymentParameters({
    isThirdPartyReservation,
    couponCode
  })).then(unwrapResult); // para lançar erro
});

/**
 * Calcula os totais do carrinho. Chamar quando mudar os itens do carrinho
 */
function _makeTotal(state: Omit<ShoppingCartState, 'total' | 'paymentValue'> & Partial<ShoppingCartState>): ShoppingCartState {
  const currency = state?.items?.[0]?.currency || 'BRL';
  let totalValue = 0;
  let totalTaxes = 0;
  let totalDiscounts = 0;
  let maxPointsToBurn: undefined | number = undefined;
  let minPointsToBurn: undefined | number = undefined;
  if (state?.items) {
    for (const item of state?.items) {
      const roomRate = item.roomRates?.[0];
      if (roomRate && roomRate?.priceComposition?.total?.currency) {
        if (roomRate?.priceComposition?.total?.currency !== currency) {
          throw new Error('CARRINHO COM MOEDAS DIFERENTES');
        }
        totalValue += (roomRate?.priceComposition?.total?.value || 0) * (item?.quantity || 1);
        totalTaxes += (roomRate?.priceComposition?.taxes?.value || 0) * (item?.quantity || 1);
        totalDiscounts += (roomRate?.priceComposition?.discounts?.value || 0) * (item?.quantity || 1);
      }
      const loyaltyRedeem = roomRate.engineRuleVersion == '0' ? roomRate?.businessRules?.[0]?.loyaltyRedeems?.[0] : roomRate?.loyaltyRedeems?.[0];
      if (loyaltyRedeem) {
        maxPointsToBurn = (maxPointsToBurn ?? 0) + (loyaltyRedeem.maxPointsToBurn ?? 0);
        minPointsToBurn = (minPointsToBurn ?? 0) + (loyaltyRedeem.minPointsToBurn ?? 0);
      }
    }
  }

  // TODO: sem suporte real a múltiplos redeem e reward
  const [loyaltyRedeemId, loyaltyRedeemUnitText, loyaltyRedeemPointsFractionDigits, loyaltyRedeemType, loyaltyRedeemCurrency] = state.items?.slice(0, 1)?.map<[string, string, number, ShoppingCartState['loyaltyRedeemType'], ShoppingCartState['loyaltyRedeemCurrency']]>(firstCartItem => {
    if (!firstCartItem.roomRates[0]) {
      return [undefined, undefined, undefined, undefined, undefined];
    }
    if (firstCartItem.engineRuleVersion === '1') {
      if (!firstCartItem.roomRates[0]?.loyaltyRedeems?.[0]) {
        return [undefined, undefined, undefined, undefined, undefined];
      }
      return [firstCartItem.roomRates[0]?.loyaltyRedeems?.[0]?.id, firstCartItem.roomRates[0]?.loyaltyRedeems?.[0]?.unitText, firstCartItem.roomRates[0]?.loyaltyRedeems?.[0]?.fractionDigits, firstCartItem.roomRates[0]?.loyaltyRedeems?.[0]?.type, firstCartItem.roomRates[0]?.loyaltyRedeems?.[0]?.currency];
    } else {
      return [firstCartItem.roomRates[0]?.businessRules?.[0]?.loyaltyRedeems?.[0]?.id, firstCartItem.roomRates[0]?.businessRules?.[0]?.loyaltyRedeems?.[0]?.unitText, firstCartItem.roomRates[0]?.businessRules?.[0]?.loyaltyRedeems?.[0]?.fractionDigits, firstCartItem.roomRates[0]?.businessRules?.[0]?.loyaltyRedeems?.[0]?.type, firstCartItem.roomRates[0]?.businessRules?.[0]?.loyaltyRedeems?.[0]?.currency];
    }
  })?.[0] ?? [];
  let total: ShoppingCartState['total'] = {
    currency,
    value: totalValue,
    taxes: totalTaxes,
    discounts: totalDiscounts != 0 ? totalDiscounts : undefined
  };
  if (shallowEqual(total, state.total)) {
    total = state.total;
  }
  const engineRuleVersion = state.items[0]?.engineRuleVersion;

  //seta pointsToBurn de acordo com minPointsToBurn e maxPointsToBurn
  let pointsToBurn = state.pointsToBurn;
  if (minPointsToBurn != null && minPointsToBurn > (pointsToBurn ?? 0)) {
    pointsToBurn = minPointsToBurn;
  }
  if (maxPointsToBurn != null && maxPointsToBurn < (pointsToBurn ?? 0)) {
    pointsToBurn = maxPointsToBurn;
  }
  const newState: ShoppingCartState = {
    ...state,
    clientId: state?.items?.[0]?.clientId,
    total,
    loyaltyRedeemId,
    loyaltyRedeemUnitText,
    loyaltyRedeemPointsFractionDigits,
    loyaltyRedeemType,
    paymentValue: totalValue,
    engineRuleVersion,
    pointsToBurn,
    loyaltyRedeemCurrency
  };
  if (shallowEqual(state, newState)) return (state as ShoppingCartState); // não mudou nada
  return newState;
}
type UpdatePaymentParametersReturn = {
  paymentOptions: PaymentOption[];
  maxPointsToBurn: number;
  minPointsToBurn: number;
  loyaltyRedeemId: string | null;
  paymentValue: number;
  pointsToBurn: number;
  loyaltyRewardId: string;
  loyaltyRewardValue: number;
  loyaltyRewardType: 'POINTS' | 'CASHBACK';
  loyaltyRewardUnitText: string;
  loyaltyRewardCurrency: string;
};
// chama checkoutPaymentParameters e atualiza
const updatePaymentParameters = createAsyncThunk<UpdatePaymentParametersReturn, {
  pointsToBurn?: number;
  isThirdPartyReservation?: boolean;
  paymentOptionId?: string;
  couponCode?: string;
} | void, {
  state: CompleteRootState;
}>('shoppingCart/updatePaymentParameters', async (arg, {
  getState
}): Promise<UpdatePaymentParametersReturn> => {
  arg = arg || {};
  const rootState = getState();
  const _cart = rootState.shoppingCart;
  const landingPageLocator = rootState.storefrontConfig?.storefront?.locator;
  const clientId = rootState.core.clientId || _cart?.clientId;
  const cartTotal = _cart.total;
  const currency = cartTotal.currency;
  const quotationToken = rootState.quotation?.quotation?.quotationToken;
  const isThirdPartyReservation = arg?.isThirdPartyReservation ?? rootState.shoppingCart?.personalData?.primaryGuestFormEnabled;
  const paymentOptionId = arg?.paymentOptionId;
  const couponCode = arg?.couponCode ?? _cart.couponCode;
  const pointsToBurn = arg?.pointsToBurn !== undefined ? arg?.pointsToBurn : _cart.pointsToBurn || undefined;
  const b2c = rootState.authentication?.b2c;
  const engineRuleVersion = _cart?.items?.[0]?.engineRuleVersion ?? '0';
  const payLaterOptionByRule = _cart?.payLaterOptionByRule;
  if (engineRuleVersion == '1') {
    // versão sem distribuição
    // TODO - sem suporte a múltiplos reward e redeem - assume que todos os itens têm mesmo loyaltyReward e usa o primeiro
    const loyaltyRewardId = _cart?.items?.[0]?.roomRates?.[0]?.['loyaltyRewards']?.[0]?.id;
    const loyaltyRedeemId = _cart?.loyaltyRedeemId;
    return _cart?.items?.reduce<UpdatePaymentParametersReturn>((acc: UpdatePaymentParametersReturn, cartItem: EngineRuleRoomItem, itemIndex) => {
      const roomRate = cartItem.roomRates[0];
      const itemPaymentOptions = roomRate?.paymentOptions?.map<PaymentOption>(p2 => {
        const p = {
          ...p2,
          paidItems: [{
            itemIndex,
            amount: p2.paymentValue,
            currency: p2.paymentCurrency
          }],
          loyaltyRedeemPaidItems: p2.loyaltyRedeemId ? [{
            itemIndex,
            amount: p2.pointsWorth,
            currency: p2.paymentCurrency,
            loyaltyRedeemId: p2.loyaltyRedeemId
          }] : undefined
        };
        if (p.oncc_conditions) {
          const cards: Array<Card> = [];
          p.oncc_conditions.forEach(item => {
            const {
              cardBrands,
              maxInst,
              minInstValue
            } = item;
            cardBrands.map(brand => {
              cards.push({
                brand: brand,
                maximumInstallments: maxInst,
                maxInst: maxInst,
                minInstValue: minInstValue,
                description: `(em até ${maxInst} vez${maxInst > 1 ? 'es' : ''})`
              });
            });
          });
          return {
            ...p,
            cards
          };
        }
        return p;
      });

      // adiciona a opção de pagar depois se for reservar -> pagar feat #164342
      if (!b2c && roomRate.checkoutStrategy === 'BOOKING_THEN_PAYMENT' && roomRate?.laterHasSupplierPayment) {
        const defaultPayLaterAlias = "Pagar depois";
        itemPaymentOptions.push({
          type: 'LATER',
          id: 'LATER',
          alias: payLaterOptionByRule?.payLaterAlias ?? defaultPayLaterAlias,
          description: payLaterOptionByRule?.payLaterDescription ?? null,
          loyaltyRedeemPaidItems: itemPaymentOptions?.find(r => r.loyaltyRedeemPaidItems)?.loyaltyRedeemPaidItems,
          // assume que todos os paymentOptions possuem o mesmo loyaltyRedeemPaidItems
          enabled: true,
          paidItems: [] // não faz sentido pro pagar depois
        });
      }
      if (!acc.paymentOptions) {
        acc.paymentOptions = itemPaymentOptions;
      } else {
        // junta as formas de pagamento em comum entre os itens
        acc.paymentOptions = acc.paymentOptions.map(prevPaymentOption => {
          const itemPaymentOption = itemPaymentOptions.find(p => p.id === prevPaymentOption.id && p.paymentCurrency === prevPaymentOption.paymentCurrency && p.currency === prevPaymentOption.currency);
          if (!itemPaymentOption) {
            // novo item não aceita esse tipo de pagamento
            return null;
          } else {
            return {
              ...prevPaymentOption,
              paymentValue: prevPaymentOption.paymentValue + itemPaymentOption.paymentValue || null,
              total: prevPaymentOption.total + itemPaymentOption.total || null,
              paidItems: [...(prevPaymentOption.paidItems || []), ...(itemPaymentOption.paidItems || [])],
              loyaltyRedeemPaidItems: [...(prevPaymentOption.loyaltyRedeemPaidItems || []), ...(itemPaymentOption.loyaltyRedeemPaidItems || [])]
            };
          }
        }).filter(Boolean);
      }
      const selectedPaymentOption = paymentOptionId ? acc.paymentOptions?.find(p => p.id === paymentOptionId) : acc.paymentOptions?.[0];
      if (selectedPaymentOption) {
        acc.paymentValue = selectedPaymentOption.paymentValue;
      } else {
        acc.paymentValue += cartItem.roomRates[0].priceComposition?.total?.value;
      }
      const loyaltyRedeem = roomRate?.loyaltyRedeems?.find(r => r.id === loyaltyRedeemId);
      acc.minPointsToBurn += loyaltyRedeem?.minPointsToBurn || 0;
      acc.maxPointsToBurn += loyaltyRedeem?.maxPointsToBurn || 0;
      const loyaltyReward = roomRate?.loyaltyRewards?.find(r => r.id === loyaltyRewardId);
      if (loyaltyReward) {
        const loyaltyRewardValue = loyaltyReward == null ? null : 'cashback' in loyaltyReward ? loyaltyReward?.cashback?.value : loyaltyReward?.points?.value;
        acc.loyaltyRewardValue += loyaltyRewardValue || 0;
        // assume que todos serão iguais
        acc.loyaltyRewardType = 'cashback' in loyaltyReward ? 'CASHBACK' : 'POINTS';
        acc.loyaltyRewardUnitText = 'cashback' in loyaltyReward ? null : loyaltyReward.unitText;
        acc.loyaltyRewardUnitText = loyaltyReward.unitText;
        acc.loyaltyRewardCurrency = 'cashback' in loyaltyReward ? loyaltyReward.cashback?.currency : null;
      }
      return acc;
    }, {
      paymentOptions: null,
      paymentValue: 0,
      loyaltyRedeemId,
      maxPointsToBurn: 0,
      minPointsToBurn: 0,
      pointsToBurn,
      loyaltyRewardId,
      loyaltyRewardValue: 0,
      loyaltyRewardType: null,
      loyaltyRewardUnitText: null,
      loyaltyRewardCurrency: null
    });
  } else {
    // com distribuição
    const loyaltyRewardId = _cart?.loyaltyRewardId ?? (_cart?.items?.[0]?.roomRates?.[0] as LegacyRoomRate)?.businessRules?.[0]?.loyaltyRewards?.[0]?.id;
    // versão com distribuição
    const checkoutItems = _createCheckoutItems({
      rootState,
      loyaltyRewardId
    });
    const loyaltyRewardType = checkoutItems?.[0]?.item.loyaltyRewardType; // Assumindo que, se tem mais de um item, todos terão o mesmo tipo
    const loyaltyRewardUnitText = checkoutItems?.[0]?.item.loyaltyRewardUnitText;
    const purchaseSessionId = rootState.shoppingCart?.purchaseSessionId;
    if (checkoutItems?.length > 0) {
      //has loyaltypoints
      const secretPayments = [];
      if (pointsToBurn > 0) {
        secretPayments.push({
          order: 0,
          paymentOptionId: 'POINTS',
          pointsToBurn
        });
      }
      const parameters: CheckoutPaymentParametersPayload = {
        items: checkoutItems,
        clientId,
        secretPayments,
        quotationToken,
        isThirdPartyReservation,
        purchaseSessionId,
        couponCode
      };
      return await callApi('niara-spear-orders', `${landingPageLocator ? `/landing-pages/${landingPageLocator}` : quotationToken ? `/quotationTokens/${quotationToken}` : ''}/generic/checkoutPaymentParameters`, 'post', {
        body: parameters,
        authenticationType: 'NIARA_AUTH'
      }).then((paymentParameters: CheckoutPaymentParameters) => {
        if (paymentParameters) {
          let maxPointsToBurn = null; // PEGAR DA RESPOSTA DO CHECKOUT PAYMENT PARAMETER
          let minPointsToBurn = null;
          let loyaltyRedeemId = null;
          let paymentValue = cartTotal?.value; // PEGAR DA RESPOSTA DO CHECKOUT PAYMENT PARAMETER

          // retorna os valores do reward totalizados (desconta pontos, soma os itens)
          const loyaltyRewards: LoyaltyRewards = paymentParameters?.loyaltyRewards?.[currency];
          const loyaltyRewardItem = loyaltyRewards?.find(r => r?.id == loyaltyRewardId);
          const loyaltyRewardCurrency = loyaltyRewardItem == null ? null : loyaltyRewardItem && 'cashback' in loyaltyRewardItem ? loyaltyRewardItem.cashback.currency : null;
          const loyaltyRewardValue = loyaltyRewardItem == null ? null : 'cashback' in loyaltyRewardItem ? loyaltyRewardItem?.cashback?.value : loyaltyRewardItem?.points?.value;

          // busca o loyaltyRedeems da resposta
          if (paymentParameters.loyaltyRedeems?.[currency]) {
            paymentValue = paymentParameters.loyaltyRedeems[currency].remainingPaymentValue;
            if (paymentValue < 0.01) {
              paymentValue = 0;
            }
            const loyaltyRedeem = paymentParameters.loyaltyRedeems[currency];
            maxPointsToBurn = loyaltyRedeem.maxPointsToBurn;
            minPointsToBurn = loyaltyRedeem.minPointsToBurn;
            loyaltyRedeemId = 'id' in loyaltyRedeem ? loyaltyRedeem.id : loyaltyRedeem.loyaltyRedeemId;
          }

          /** 2024-07-17 usado apenas para funcioanr com link de pagamento com TSE - arrumar outra correção */
          let loyaltyRedeemPaidItems_linkpagamento_deleteme: PaymentOption['loyaltyRedeemPaidItems'] = undefined;
          const _paymentOptions: PaymentOption[] = paymentParameters?.paymentOptions?.map<PaymentOption>((paymentOption: CheckoutPaymentParameters_PaymentOption): PaymentOption => {
            const {
              oncc_conditions,
              description
            } = paymentOption;
            // verifica se é cartão
            let cards: Array<Card>;
            if (oncc_conditions) {
              cards = [];
              oncc_conditions.map(item => {
                const {
                  cardBrands,
                  maxInst,
                  minInstValue
                } = item;
                cardBrands.map(brand => {
                  cards.push({
                    brand: brand,
                    maximumInstallments: maxInst,
                    description: `(em até ${maxInst} vez${maxInst > 1 ? 'es' : ''})`,
                    maxInst,
                    minInstValue
                  });
                });
              });
            }
            let loyaltyRedeemPaidItems: PaymentOption['loyaltyRedeemPaidItems'] = null;
            if (paymentOption?.priceComposition?.items?.find(r => r.payment?.burn)) {
              loyaltyRedeemPaidItems = paymentOption?.priceComposition?.items.map(item => {
                return {
                  itemIndex: item.itemIndex,
                  amount: item.payment.burn.value,
                  currency: (item.payment.burn.currency as string)
                };
              });
              loyaltyRedeemPaidItems_linkpagamento_deleteme = loyaltyRedeemPaidItems;
            }

            // extraOptions - se veio paymentOption.extras == true, converter o
            // extras que está em roomRate.payment.extras (engineRuleVersion 0)
            // para o formato do paymentOption.extraOptions (engineRuleVersion 1)
            const extraOptions = paymentOption.extras ? function () {
              // pode dar bug dependendo da composição do carrinho
              // mas não vale a pena corrigir (melhor esperar migrar para
              // engineRuleVersion 1)
              const cartItem1 = _cart?.items?.[0];
              const roomRate1 = cartItem1?.roomRates?.[0];
              const extraOptions: PaymentOption['extraOptions'] = ('payment' in roomRate1 && roomRate1?.payment?.extras?.map(e => ({
                id: e.id?.toString(),
                type: e.type
              }))) ?? [];
              return extraOptions;
            }() : [];

            // transforma CheckoutPaymentParameters_PaymentOption[priceComposition] em PaymentOption[paidItems]

            const paidItems: PaymentOption['paidItems'] = paymentOption.priceComposition?.items?.map(item => ({
              itemIndex: item.itemIndex,
              amount: item.payment.booking.value,
              currency: item.payment.booking.currency
            })) ?? [];
            return {
              ...paymentOption,
              loyaltyRedeemPaidItems,
              paidItems,
              extraOptions,
              currency: currency,
              total: paymentValue,
              description,
              cards
            };
          }) ?? [];

          // Quando for Central de reserva vamos adicionar o link de pagamento que é do tipo LATER
          // Porque esse tipo não vem no checkoutPaymentParameters
          const paymentOptionsLink: PaymentOption[] = rootState?.authentication?.b2c ? [] : checkoutItems?.[0]?.item?.availablePaymentOptions?.filter(p => p.id === 'LATER').map(r => ({
            ...r,
            loyaltyRedeemPaidItems: loyaltyRedeemPaidItems_linkpagamento_deleteme,
            enabled: r.enabled ?? true
          })); // TODO: arrumar a tipagem
          const paymentOptions: PaymentOption[] = rootState?.authentication?.b2c ? _paymentOptions : (_paymentOptions || []).concat(paymentOptionsLink || []);
          return {
            maxPointsToBurn,
            minPointsToBurn,
            loyaltyRedeemId,
            paymentOptions,
            paymentValue,
            loyaltyRewardId,
            pointsToBurn,
            loyaltyRewardValue,
            loyaltyRewardType,
            loyaltyRewardUnitText,
            loyaltyRewardCurrency,
            loyaltyRewards
          };
        } else {
          throw new Error('Erro chamando checkoutPaymentParameters');
        }
      });
    }
  }
});
type CreateCheckoutItemsParams = {
  rootState?: CompleteRootState;
  paymentOptionId?: string;
  paymentOptionIsExternal?: boolean;
  creditCard?: CreditCard;
  installments?: number;
  comments?: string;
  loyaltyRewardId?: string;
  responsible?: ResponsibleProps;
  extraFields?: ExtraFields;
  TBD_payableExtra?: {
    id;
    type;
    alias;
    description;
  }; /* aguardando definição #168328 */
  useVcn?: boolean;
  creditCardId?: string;
  creditCardSecret?: string;
};
type CreatePreCheckoutItemsParams = {
  preCheckout: true;
  rootState?: CompleteRootState;
};
type CreateCheckoutItemsReturn<P> = P extends CreatePreCheckoutItemsParams ? PreCheckoutParams['items'] : CheckoutParams['items'];
type CreateCheckoutItemsFn = {
  <P extends CreateCheckoutItemsParams | CreatePreCheckoutItemsParams>(p: P): CreateCheckoutItemsReturn<P>;
};
type PreCheckoutHotelItem = PreCheckoutParams['items'][number]['item'];

/**
 * Para montar o payload do checkoutPaymentParameters, authorizeBurn e checkoutCart
 * @returns
 */
const _createCheckoutItems: CreateCheckoutItemsFn = <P extends CreateCheckoutItemsParams | CreatePreCheckoutItemsParams,>(params: P) => {
  const isPreCheckout = 'preCheckout' in params && params.preCheckout;
  const {
    rootState,
    paymentOptionId,
    paymentOptionIsExternal,
    installments,
    comments,
    loyaltyRewardId,
    responsible,
    extraFields,
    useVcn,
    creditCardId,
    creditCardSecret
  } = (params as CreateCheckoutItemsParams);
  const creditCard = useVcn || creditCardId ? null : 'creditCard' in params && params?.creditCard;
  const cart = rootState?.shoppingCart;
  const quotationToken = rootState?.quotation?.quotation?.quotationToken;
  const clientId = rootState?.core?.clientId;
  const loyaltyRedeemId = cart.loyaltyRedeemId;
  const b2c = rootState.authentication?.b2c;
  const selfBooking = b2c || rootState.authentication?.isClientUser;
  return (cart?.items.map(cartItem => {
    const roomRate = cartItem?.roomRates?.[0];
    const occupancy = cartItem.occupancy;

    // se tem loyaltyRedeem selecionado, encontra o
    // business rule
    const businessRules = roomRate.engineRuleVersion === '1' ? undefined : roomRate.businessRules;
    const businessRule = loyaltyRewardId // se escolheu um loyaltyReward, busca o business rule que contenha o mesmo loyaltyReward
    ? businessRules?.filter(br => paymentOptionId == null || br.paymentOptions == null || br.paymentOptions.find(po => po.id == paymentOptionId)).find(br => br?.loyaltyRewards?.find(lr => lr.id === loyaltyRewardId)) : loyaltyRedeemId ? businessRules?.filter(br => paymentOptionId == null || br.paymentOptions == null || br.paymentOptions.find(po => po.id == paymentOptionId)).find(br => br?.loyaltyRedeems?.find(lr => lr.id === loyaltyRedeemId)) : undefined;
    const loyaltyReward = roomRate.engineRuleVersion == '1' ? roomRate.loyaltyRewards?.find(lr => lr.id == loyaltyRewardId) : businessRule?.loyaltyRewards?.find(lr => lr.id == loyaltyRewardId);
    const loyaltyRewardValue = loyaltyReward != null ? 'points' in loyaltyReward ? loyaltyReward?.points?.value : loyaltyReward?.cashback?.value : loyaltyReward?.points?.value || loyaltyReward?.cashback?.value || null;
    const loyaltyRedeem = roomRate.engineRuleVersion == '1' ? roomRate.loyaltyRedeems?.find(lr => lr.id == loyaltyRedeemId) : businessRule?.loyaltyRedeems?.find(lr => lr.id == loyaltyRedeemId);
    const primaryGuest = cart?.personalData?.primaryGuestFormEnabled && cart.personalData?.primaryGuest || cart.personalData?.buyer;
    const guests: Array<HotelItemGuest> = [{
      ageQualifying: 'ADULT',
      firstName: primaryGuest?.firstName ?? '',
      lastName: primaryGuest?.lastName ?? '',
      email: primaryGuest?.email ?? '',
      ...primaryGuest
    }];

    //verifica se personalData está preenchido com os dados dos outros hóspedes
    const roomGuests = cart?.personalData?.roomGuests?.[cartItem.itemId];
    //verifica se no roomGuests tem dados do primeiro passageiro
    if (roomGuests?.guests[0]) {
      Object.assign(guests[0], roomGuests.guests[0]);
    }
    for (let j = 1; j < occupancy.adults + occupancy.children; j++) {
      const isChild = j >= occupancy.adults;
      const age = isChild ? occupancy.childrenAges[j - occupancy.adults] : undefined;
      const guest: HotelItemGuest = {
        age,
        ageQualifying: isChild ? 'CHILD' : 'ADULT',
        namePrefix: null,
        firstName: '',
        lastName: '',
        email: ''
      };
      // verifica se o roomGuests contém os dados deste hóspede
      if (roomGuests?.guests?.[j]) {
        //achei os dados no personalData deste hóspede - jogar em guests
        Object.assign(guest, roomGuests.guests?.[j]);
      }
      guests.push(guest);
    }
    const priceComposition = roomRate.priceComposition;
    const item: HotelItem | LegacyHotelItem | PreCheckoutHotelItem = isPreCheckout ? ({
      type: 'hotelReservation',
      id: roomRate.id,
      endDate: roomRate.time.endDate,
      startDate: roomRate.time.startDate,
      hotelId: roomRate.hotelId,
      hotelName: cartItem?.hotelResult?.hotel?.name,
      totalPrice: priceComposition.total,
      roomStays: [{
        occupancy: {
          ...cartItem.occupancy,
          childrenAges: cartItem.occupancy?.childrenAges ?? []
        },
        roomRateId: roomRate?.id,
        guests,
        cancelPolicy: roomRate.cancelPolicy,
        meal: roomRate?.meal,
        roomDescription: roomRate?.roomType?.name,
        roomTypeId: roomRate?.roomType?.id,
        priceComposition: _pick(priceComposition, ['total'])
      }],
      TSER_idContrato: cart?.personTSER_idContrato,
      TSER_numeroContrato: cart?.personTSER_numeroContrato,
      TSER_idUnidadeHoteleira: loyaltyRedeem?.TSER_idUnidadeHoteleira,
      creditCardId,
      creditCardSecret
    } as PreCheckoutHotelItem) : roomRate.engineRuleVersion == '1' ? ({
      ...responsible,
      type: 'hotelReservation',
      id: roomRate.id,
      idOnSource: roomRate.idOnSource,
      b2c,
      selfBooking,
      promoCode: roomRate.promoCode ?? roomRate.promotion?.code,
      quotationToken,
      credentialId: roomRate.credential?.id,
      engineRuleId: roomRate.engineRuleId,
      loyaltyRewardId: loyaltyReward?.id,
      loyaltyRewardValue: loyaltyRewardValue,
      loyaltyRewardType: loyaltyReward?.points ? 'POINTS' : loyaltyReward?.cashback ? 'CASHBACK' : null,
      loyaltyRewardCurrency: loyaltyReward?.cashback ? loyaltyReward.cashback.currency : null,
      loyaltyRewardUnitText: loyaltyReward?.unitText,
      loyaltyRedeemId,
      endDate: roomRate.time.endDate,
      startDate: roomRate.time.startDate,
      hotelId: roomRate.hotelId,
      totalPrice: priceComposition.total,
      clientId,
      roomStays: [{
        occupancy: {
          ...cartItem.occupancy,
          childrenAges: cartItem.occupancy?.childrenAges ?? []
        },
        roomRateId: roomRate?.id,
        guests
      }],
      comment: comments,
      discountedClientCommissionPercentage: cartItem.discountedClientCommissionPercentage,
      discountedMarkupPercentage: cartItem?.discountedMarkupPercentage,
      extraFields,
      TSER_idContrato: loyaltyRedeem?.TSER_idContrato,
      TSER_numeroContrato: cart?.personTSER_numeroContrato,
      TSER_idUnidadeHoteleira: loyaltyRedeem?.TSER_idUnidadeHoteleira,
      engineRuleVersion: '1',
      creditCardId,
      creditCardSecret,
      hashedContext: roomRate.hashedContext
    } as HotelItem) : ({
      ...responsible,
      type: 'hotelReservation',
      id: roomRate.id,
      idOnSource: roomRate.idOnSource,
      b2c,
      selfBooking,
      promoCode: roomRate.promoCode ?? roomRate.promotion?.code,
      quotationToken,
      credentialId: roomRate.credential?.id,
      distributionId: roomRate.distribution?.id,
      businessRuleId: businessRule?.id,
      businessRule: businessRule,
      // precisa mandar, para verificar o hash
      engineRuleId: roomRate.engineRuleId,
      loyaltyRewardId: loyaltyReward?.id,
      loyaltyRewardValue: loyaltyRewardValue,
      loyaltyRewardType: loyaltyReward?.points ? 'POINTS' : loyaltyReward?.cashback ? 'CASHBACK' : null,
      loyaltyRewardCurrency: loyaltyReward?.cashback ? loyaltyReward.cashback.currency : null,
      loyaltyRewardUnitText: loyaltyReward?.unitText,
      loyaltyRedeemId,
      endDate: roomRate.time.endDate,
      startDate: roomRate.time.startDate,
      hotelId: roomRate.hotelId,
      totalPrice: priceComposition.total,
      clientId,
      roomStays: [{
        occupancy: {
          ...cartItem.occupancy,
          childrenAges: cartItem.occupancy?.childrenAges ?? []
        },
        roomRateId: roomRate?.id,
        guests
      }],
      availablePaymentOptions: paymentOptionId == null ? roomRate.payment.paymentOptions : undefined,
      // Só mandar para checkoutPaymentParameters incluir as opções de pagar direto ao hotel
      payment: paymentOptionIsExternal ? {
        creditCard: creditCard ? {
          cardTypeCode: creditCard?.brand,
          cardNumber: creditCard?.number,
          expireDate: creditCard?.expiryDate?.length === 6 ? creditCard?.expiryDate?.substring(0, 2)?.concat(creditCard?.expiryDate.substring(4)) : creditCard?.expiryDate,
          cvv: creditCard?.cvv,
          cardHolderName: creditCard?.holder
        } : undefined,
        installments,
        /**
         * @deprecated
         */
        paymentPlan: {
          installments
        },
        paymentOption: roomRate.payment?.paymentOptions?.find(po => isEqual(po?.id?.toString(), paymentOptionId?.toString())),
        vcn: useVcn
      } : undefined,
      comment: comments,
      discountedClientCommissionPercentage: cartItem.discountedClientCommissionPercentage,
      discountedMarkupPercentage: cartItem?.discountedMarkupPercentage,
      extraFields,
      TSER_idContrato: loyaltyRedeem?.TSER_idContrato,
      TSER_numeroContrato: cart?.personTSER_numeroContrato,
      TSER_idUnidadeHoteleira: loyaltyRedeem?.TSER_idUnidadeHoteleira,
      engineRuleVersion: '0',
      creditCardId,
      creditCardSecret,
      hashedContext: roomRate.hashedContext
    } as LegacyHotelItem);
    const checkoutItem = {
      quantity: cartItem.quantity,
      item
    };
    return checkoutItem;
  }) as CreateCheckoutItemsReturn<P>);
};

/**
 * Chama pre-checkout, para gravar carrinho de compras abandonado (lost reservation) #168841
 */
export const preCheckout = createAsyncThunk<void, void, {
  state: CompleteRootState;
}>('shoppingCart/preCheckout', async (params, {
  getState,
  dispatch
}) => {
  const rootState = getState();
  const buyer = rootState?.shoppingCart?.personalData?.buyer;
  const purchaseSessionId = rootState.shoppingCart?.purchaseSessionId;
  const landingPageId = rootState.storefrontConfig?.storefront?.landingPageId;
  const alreadyHaveBuyerPersonalData = buyer != null && [buyer?.email?.trim(), buyer?.phone?.trim()].filter(Boolean).length > 0;
  if (alreadyHaveBuyerPersonalData && rootState?.shoppingCart?.items?.length > 0 && landingPageId && purchaseSessionId) {
    const preCheckoutParams: PreCheckoutParams = {
      items: _createCheckoutItems({
        rootState,
        preCheckout: true
      }),
      buyer: {
        firstName: buyer?.firstName,
        lastName: buyer?.lastName,
        email: buyer?.email,
        name: [buyer.firstName, buyer.lastName].filter(Boolean).join(' '),
        personId: buyer.personId,
        phone: buyer?.phone
      },
      purchaseSessionId,
      landingPageId
    };
    await callApi('niara-spear-orders', '/preCheckout', 'post', {
      body: preCheckoutParams
    });
  }
});
export const setPerson = createAsyncThunk<{
  person?: Person;
  personBalance?: number;
  personContractStatus?: 'ACTIVE' | 'INACTIVE' | 'BLOCKED';
  personBalanceUnit?: string;
  personTSER_idContrato?: number;
  personTSER_numeroContrato?: string;
}, {
  personId: string;
  clientId: string;
  personTSER_idContrato?: number;
}, {
  state: CompleteRootState;
}>('shoppingCart/setPerson', async ({
  personId,
  clientId,
  personTSER_idContrato
}, {
  getState
}) => {
  const rootState: CompleteRootState = getState();
  const b2c = rootState?.authentication?.b2c;
  if (b2c) {
    const currentPerson = await currentPersonDAO.findById(personId).catch(() => (null as CurrentPerson));
    return {
      person: (currentPerson as Person),
      personBalance: rootState.loyalty.userBalance,
      personContractStatus: rootState.loyalty.userBalanceContractStatus,
      personBalanceUnit: rootState.loyalty.unitText
    };
  }
  if (!personId || !clientId) {
    return {
      person: null,
      personBalance: null,
      personContractStatus: null,
      personBalanceUnit: null
    };
  }
  const person = await personDAO.findById(personId);
  const balanceResponse: Balance = await callApi('niara-spear-loyalty-programs', `loyalty-programs/${personId}/balance`, 'get', {
    authenticationType: 'NIARA_AUTH',
    params: {
      clientId
    }
  }).catch(() => null);
  const personLoyaltyOption: LoyaltyOption = personTSER_idContrato && balanceResponse?.loyaltyOptions ? balanceResponse?.loyaltyOptions?.find((o: LoyaltyOption) => o?.TSER_idContrato == personTSER_idContrato) : null;
  const personBalance = personLoyaltyOption?.balance ?? balanceResponse?.balance?.balance;
  const personContractStatus = personLoyaltyOption?.contractStatus ?? balanceResponse?.balance?.contractStatus;
  const personBalanceUnit = personLoyaltyOption?.unitText ?? balanceResponse?.balance?.unitText;
  const _personTSER_idContrato = personLoyaltyOption?.TSER_idContrato ?? balanceResponse?.balance?.TSER_idContrato;
  const personTSER_numeroContrato = personLoyaltyOption?.TSER_numeroContrato ?? balanceResponse?.balance?.TSER_numeroContrato;
  return {
    person,
    personBalance,
    personContractStatus,
    personBalanceUnit,
    personTSER_idContrato: _personTSER_idContrato,
    personTSER_numeroContrato
  };
});
export type CheckoutCartPayment = {
  id?: string;
  paymentOption: PaymentOption;
  creditCard?: CreditCard;
  installments?: number;
  payerIsBuyer?: boolean;
  payer?: Partial<PersonFields>;
  /**
   * @deprecated usado apenas no engineRuleVersion == '0'  #181231
   */
  useVcn?: boolean;
  value: number;
  currency: string;
  creditCardSecret?: string;
  creditCardId?: string;
  includeExtras?: HotelReservation['includeExtras'];
};
export type CheckoutGeneratePaymentLink = {
  email?: string[];
  expirationDate?: string | null;
  paymentOptions?: string[];
  emv3dsEnabled?: boolean | null;
  withLinkPayment?: boolean;
  receivablePercentage?: number;
};

/**
 * Faz o checkout do carrinho
 */
export type CheckoutCartParams = {
  comments?: string;
  loyaltyCheckCode?: string;
  loyaltyRewardId?: string;
  payments?: Array<CheckoutCartPayment>;
  isThirdPartyReservation?: boolean;
  paymentLink?: CheckoutGeneratePaymentLink;
};
export const checkoutCart = createAsyncThunk<CheckoutResults, CheckoutCartParams, {
  state: CompleteRootState;
}>('shoppingCart/checkoutCart', async (params, {
  getState,
  dispatch
}) => {
  const rootState = getState();
  try {
    const {
      shoppingCart
    } = rootState;
    const buyer = shoppingCart?.personalData?.buyer;
    const landingPageLocator = rootState.storefrontConfig?.storefront?.locator;
    const cpf = buyer?.cpf;
    const clientId = shoppingCart?.clientId ?? rootState?.core.clientId;
    const quotationToken = rootState?.quotation?.quotation?.quotationToken;
    const couponCode = rootState?.shoppingCart?.couponCode;
    const extraFields = rootState?.shoppingCart?.extraFields;
    const TSER_idContrato = shoppingCart?.personTSER_idContrato;
    const engineRuleVersion = shoppingCart?.items?.[0]?.engineRuleVersion;
    const purchaseSessionId = rootState.shoppingCart?.purchaseSessionId;
    const {
      comments,
      loyaltyCheckCode,
      payments,
      paymentLink
    } = params;
    const b2c = rootState?.authentication?.b2c;
    const loyaltyRewardId = params.loyaltyRewardId ?? shoppingCart?.loyaltyRewardId ?? (shoppingCart?.items?.[0]?.roomRates?.[0] as LegacyRoomRate)?.businessRules?.[0]?.loyaltyRewards?.[0]?.id;

    // // track GTM forma de pagamento
    // trackEventAddPaymentInfo(shoppingCart, paymentOption?.alias, rootState.storefrontConfig?.storefront)

    const {
      loyaltyId,
      pointsToBurn
    } = shoppingCart;
    const responsible: ResponsibleProps = shoppingCart.responsible?.id ? {
      responsibleName: shoppingCart.responsible?.name,
      responsibleEmail: shoppingCart.responsible?.email,
      responsible: shoppingCart.responsible?.id
    } : {};

    // pagamento externo - não permite múltiplos pagamentos
    if (payments?.length > 1 && payments.some(r => 'external' in r.paymentOption && r.paymentOption?.external)) {
      throw new Error('Não funciona múltiplos pagamentos com pelo menos um externo');
    }

    // no fluxo com distribuição e pagamento ao fornecedor, não gera secretPayment,
    // informação está dentro do checkoutItems
    const paymentOptionIsExternal: boolean = engineRuleVersion != '1' && payments?.length == 1 && 'external' in payments[0].paymentOption && payments[0].paymentOption.external;
    const checkoutItems = _createCheckoutItems({
      rootState,
      comments,
      loyaltyRewardId,
      responsible,
      extraFields,
      ...(paymentOptionIsExternal ? {
        paymentOptionIsExternal,
        paymentOptionId: payments[0].paymentOption.id,
        creditCard: payments[0].useVcn ? undefined : payments[0].creditCard,
        installments: payments[0].installments,
        useVcn: payments[0].useVcn,
        creditCardId: payments[0].creditCardId,
        creditCardSecret: payments[0].creditCardSecret
      } : {})
    });
    const paymentOptionIsLater = payments?.some(p => p.paymentOption?.id === 'LATER');
    if (paymentOptionIsLater && payments?.length > 1) {
      throw new Error('Não funciona múltiplos pagamentos com link de pagamento');
    }
    const secretPayments: CheckoutParams['secretPayments'] = [];
    if (loyaltyId) {
      // Atenção: no caso de múltiplos pagamentos, assume que loyaltyRedeemPaidItems de todos os payments
      // é igual, e representa o loyaltyRedeem total da reserva
      const paidItems = payments?.find(r => r?.paymentOption?.loyaltyRedeemPaidItems)?.paymentOption?.loyaltyRedeemPaidItems;
      secretPayments.push({
        paymentOptionId: 'POINTS',
        loyaltyId,
        pointsToBurn,
        code: loyaltyCheckCode,
        paidItems
      });
    }
    if (!paymentOptionIsExternal) {
      if (paymentOptionIsLater) {
        if (engineRuleVersion == '1') {
          // no fluxo sem distribuição, não manda secret payment com LATER
          // pass
        } else {
          // secret payment para pagamento com link no fluxo com distribuição
          secretPayments.push({
            paymentOptionId: 'LATER'
          });
        }
      } else if (payments?.length >= 1) {
        const secretPaymentsWithoutPaidItems_paymentOption: Array<[Omit<SecretPayment_Payment, 'paidItems'>, PaymentOption]> = [];
        for (let i = 0; i < payments.length; i++) {
          const payment = payments[i];
          const paymentOption = payment.paymentOption;
          const {
            installments,
            useVcn,
            payer,
            creditCardId,
            creditCardSecret
          } = payment;
          const creditCard = useVcn || creditCardId ? null : payment.creditCard;
          const paymentValue = payment.value;
          const currency = payment.currency;
          let emv3dsAccessToken = null;
          let emv3dsEnvironment = null;
          if (paymentOption.credential?.type == 'BRPG' && paymentOption.credential?.auth3dsEnabled) {
            const emv3dsResponse = await callApi('niara-spear-payments', '/emv3dsAccessToken', 'post', {
              body: {
                credentialId: paymentOption?.credential?.id
              },
              authenticationType: 'NIARA_AUTH'
            });
            emv3dsAccessToken = emv3dsResponse?.emv3dsAccessToken;
            emv3dsEnvironment = emv3dsResponse?.emv3dsEnvironment;
          }
          const paymentRequestFunction = await createPaymentRequestFunction({
            mercadoPagoPublicKey: paymentOption?.credential?.type == 'MCPG' ? paymentOption?.credential?.publicKey : undefined,
            emv3dsAccessToken,
            emv3dsEnvironment
          });

          // na versão com distribuição, não gera secretPayment
          const paymentRequest = await paymentRequestFunction({
            totalValue: paymentValue,
            currency: currency,
            creditCard: creditCard ? await encryptCreditCard({
              ...creditCard,
              expiryDate: creditCard?.expiryDate?.length === 6 ? creditCard?.expiryDate?.substring(0, 2)?.concat(creditCard?.expiryDate?.substring(4)) : creditCard?.expiryDate
            }) : undefined,
            installments,
            orderNumber: 'TBD',
            identificationNumber: cpf,
            identificationType: cpf ? 'CPF' : undefined
          });
          secretPaymentsWithoutPaidItems_paymentOption.push([{
            paymentOptionId: paymentOption?.id,
            payer,
            value: paymentValue,
            currency,
            creditCardId,
            creditCardSecret,
            order: i,
            // order que o pagamento será processado
            includeExtras: payment?.includeExtras,
            ...paymentRequest
          }, paymentOption]);
        }
        secretPayments.push(...buildPaidItems(secretPaymentsWithoutPaidItems_paymentOption));
      }
    }
    const ga4StreamId = process.env.GA4_STREAM_ID;
    const ga4Session = getGA4Session(ga4StreamId);
    let orderId = shoppingCart?.orderId;
    // valida se orderId é do mesmo clientId
    if (orderId && !b2c) {
      const order = await orderDAO.findById(orderId);
      if (!order) {
        orderId = null;
      } else if (clientId && order.clientId != clientId) {
        orderId = null;
      }
    }
    const isManualReservation = shoppingCart?.items?.[0]?.roomRates?.[0]?.manualReservationId != null;
    const checkoutParams: CheckoutParams = {
      // optinMkt,
      buyer: {
        name: [buyer.firstName, buyer.lastName].filter(Boolean).join(' '),
        ...buyer
      },
      items: checkoutItems,
      secretPayments: secretPayments,
      clientId,
      isThirdPartyReservation: params?.isThirdPartyReservation,
      ...(!_isEmpty(orderId) && {
        orderId
      }),
      ...(!_isEmpty(shoppingCart?.quotationId) && {
        quotationId: shoppingCart?.quotationId
      }),
      ga4SessionId: ga4Session?.id ?? undefined,
      ga4SessionNumber: ga4Session?.number ?? undefined,
      engineRuleVersion,
      paymentLink: paymentLink,
      // TODO: Arrumar a tipagem
      couponCode: couponCode,
      purchaseSessionId,
      TSER_idContrato,
      analyticsEventType: isManualReservation ? null : quotationToken ? 'quotationCheckout' : 'checkout'
    };
    const recaptchaToken = await getRecaptchaToken({
      action: 'checkout'
    });
    const checkoutResults: CheckoutResults | {
      errorCode: number;
      errorMessage: string;
    } = await callApi('niara-spear-orders', `${landingPageLocator ? `/landing-pages/${landingPageLocator}` : quotationToken ? `/quotationTokens/${quotationToken}` : ''}/generic/checkout`, 'post', {
      authenticationType: 'NIARA_AUTH',
      body: checkoutParams,
      headers: landingPageLocator ? {
        rcptch: recaptchaToken
      } : undefined
    });
    if ('errorCode' in checkoutResults) {
      throw new Error(checkoutResults.errorMessage || "Erro ao tentar reservar");
    }
    return checkoutResults;
  } catch (error: unknown) {
    let message = '';
    if (error instanceof Error) message = error.message;
    message = String(error);
    const storefront = rootState.storefrontConfig?.storefront;
    // Enviar para o analytics se for Otabuilder
    if (storefront) trackEventError('UNKNOWN', message, 'checkoutCart - shoppingCart', storefront);
    throw error;
  } finally {
    dispatch(Loyalty.refreshBalance());
  }
});
export const buildPaidItems = <P extends Pick<SecretPayment_Payment, 'value' | 'currency'>,>(secretPaymentsWithoutPaidItems_paymentOption: [P, Pick<PaymentOption, 'paidItems' | 'currency'>][]): (P & Pick<SecretPayment_Payment, 'paidItems'>)[] => {
  const sumOfPayments = secretPaymentsWithoutPaidItems_paymentOption.reduce((acc, [payment]) => acc + payment.value, 0);
  return secretPaymentsWithoutPaidItems_paymentOption.reduce<(P & Pick<SecretPayment_Payment, 'paidItems'>)[]>((previousSecretPayments, [secretPayment, paymentOption], paymentIndex) => {
    const isLastPayment = paymentIndex === secretPaymentsWithoutPaidItems_paymentOption.length - 1;
    const paymentPart = secretPayment.value / sumOfPayments;
    const paidItems: SecretPayment_Payment['paidItems'] = [];

    // garante currency do payment
    const paymentCurrency = secretPayment?.currency ?? paymentOption?.currency;
    let remainingPaymentValue = secretPayment.value;
    paymentOption?.paidItems?.forEach((paymentOptionPaidItem, i) => {
      const isLastItem = i == paymentOption.paidItems.length - 1;
      const currency = paymentOptionPaidItem.currency;
      const itemValue = paymentOptionPaidItem.amount;
      const itemIndex = 'itemIndex' in paymentOptionPaidItem ? paymentOptionPaidItem.itemIndex : undefined;
      const itemId = 'id' in paymentOptionPaidItem ? paymentOptionPaidItem.id : undefined;
      const itemType = 'type' in paymentOptionPaidItem ? paymentOptionPaidItem.type : undefined;
      if (isLastPayment && !isLastItem) {
        const sumAmountPreviousPaymentsForItem = previousSecretPayments.reduce((acc, payment) => {
          return acc + payment.paidItems.find(p => 'itemIndex' in p ? p.itemIndex === (paymentOptionPaidItem as typeof p).itemIndex : p.id === (paymentOptionPaidItem as typeof p).id).amount;
        }, 0);
        const amount = itemValue - sumAmountPreviousPaymentsForItem;
        if (amount < 0) {
          throw new Error('Erro - paid item negativo');
        }
        remainingPaymentValue -= amount;
        paidItems.push({
          itemIndex,
          id: itemId,
          type: itemType,
          amount,
          currency
        });
      } else {
        const amount = isLastItem ? remainingPaymentValue : Math.round(itemValue * paymentPart * 100) / 100; // assume moeda em centavos
        if (amount < 0) {
          throw new Error('Erro - paid item negativo');
        }
        remainingPaymentValue -= amount;
        paidItems.push({
          itemIndex,
          id: itemId,
          type: itemType,
          amount,
          currency
        });
      }
    });
    const value = paidItems?.reduce((acc, p) => acc + p.amount, 0);
    return [...previousSecretPayments, {
      ...secretPayment,
      value,
      paidItems,
      currency: paymentCurrency
    }];
  }, []);
};
export const authorizeBurn = createAsyncThunk<{
  loyaltyId: string;
  needCode?: boolean;
}, {
  channel?: 'email';
} | void, {
  state: BaseRootState;
}>('shoppingCart/authorizeBurn', async (args, {
  getState
}) => {
  const channel = args && args?.channel || 'email';
  const rootState = getState();
  const {
    minPointsToBurn,
    loyaltyRedeemId,
    purchaseSessionId,
    person,
    pointsToBurn
  } = rootState.shoppingCart;
  if (minPointsToBurn > 0 && (pointsToBurn ?? 0) < minPointsToBurn) {
    throw new Error(`Quantidade de pontos necessária é de ${minPointsToBurn}, mas foi escolhido o uso de ${pointsToBurn ?? 0} pontos`);
  }
  if (pointsToBurn && loyaltyRedeemId) {
    const checkoutItems = _createCheckoutItems({
      rootState
    });
    const response: {
      loyaltyId;
      message;
      needCode: boolean;
    } = await callApi('niara-spear-loyalty-programs', person?.id ? `/loyalty-programs/${person?.id}/authorize-burn` : `/loyalty-programs/authorize-burn`, 'post', {
      authenticationType: 'NIARA_AUTH',
      body: {
        points: pointsToBurn,
        paymentOptionId: loyaltyRedeemId,
        channel,
        items: checkoutItems,
        purchaseSessionId
      }
    }).catch(err => {
      throw new Error([`Não foi possível completar o resgate de pontos`, err?.message].filter(Boolean).join(': '));
    });

    // não vai acontecer mais - backend vai mudar na versão 14/12/2022
    if (response.message) {
      throw new Error([`Não foi possível completar o resgate de pontos`, response.message].filter(Boolean).join(': '));
    }
    return {
      loyaltyId: response.loyaltyId,
      needCode: response.needCode
    };
  } else {
    return {
      loyaltyId: null
    };
  }
});
export const undoAuthorizeBurn = createAsyncThunk<void, void, {
  state: BaseRootState;
}>('shoppingCart/undoAuthorizeBurn', async (ignore, {
  getState
}) => {
  const loyaltyId = getState().shoppingCart?.loyaltyId;
  if (loyaltyId) {
    await callApi('niara-spear-loyalty-programs', `/loyalty-programs/rollback`, 'post', {
      authenticationType: 'NIARA_AUTH',
      body: {
        loyaltyId
      }
    });
  }
});
export const checkLoyaltyAuthorizationCode = createAsyncThunk<boolean, {
  code: string;
}, {
  state: BaseRootState;
}>('shoppingCart/checkLoyaltyAuthorizationCode', async ({
  code
}, {
  getState
}) => {
  const loyaltyId = getState().shoppingCart.loyaltyId;
  if (loyaltyId) {
    const validateLoyaltytResults = await callApi('niara-spear-loyalty-programs', `/loyalty-programs/check-authorization-code`, 'post', {
      authenticationType: 'NIARA_AUTH',
      body: {
        loyaltyId,
        code
      }
    });
    return validateLoyaltytResults?.success || false;
  }
  return false;
});

// overrideClientCommission(state, action: PayloadAction<{ itemId: string; newValue: number | null }>) {
export const setClientCommission = createAsyncThunk<void, {
  itemId: string;
  newValue: number | null;
}, {
  state: BaseRootState;
}>('shoppingCart/setClientCommission', async ({
  itemId,
  newValue
}, {
  getState,
  dispatch
}) => {
  // além de setar discountedClientCommission, faz um cálculo local dos valores para dar um feedback imediato ao usuário
  dispatch(_overrideClientCommission({
    itemId,
    newValue
  }));
  // dispara o refresh cart com os novos valores de comissão
  await dispatch(refreshCart());
});
export const setMarkup = createAsyncThunk<void, {
  itemId: string;
  newValue: number | null;
}, {
  state: BaseRootState;
}>('shoppingCart/setMarkup', async ({
  itemId,
  newValue
}, {
  dispatch
}) => {
  dispatch(_overrideSetMarkup({
    itemId,
    newValue
  }));
  // dispara o refresh cart com os novos valores de comissão
  await dispatch(refreshCart());
});

/**
 * Valores impactados por desconto na comissão do cliente
 */
const COMMISSION_OVERRIDE_DISCOUNTED_VALUES = (['clientCommission', 'total', 'productPrice', 'markup'] as const);

/**
 * Reconstrói priceComposition, aplicando o desconto
 * @param currentPriceComposition
 * @param discount
 * @returns
 */
const _applyClientCommission = (currentPriceComposition: RoomRate['priceComposition'], newClientCommission: number): RoomRate['priceComposition'] => {
  const currentClientCommission = !currentPriceComposition.productPrice?.value || !currentPriceComposition.clientCommission?.value ? 0 : currentPriceComposition.clientCommission?.value / currentPriceComposition.productPrice.value;

  // Calcula o desconto:
  // valor total original: x
  // porcentagem de comissão original: y
  // nova porcentagem de comissão: z
  // o desconto é: (y-z)*x/(1-z)
  const discount = Math.round((currentClientCommission - newClientCommission) * currentPriceComposition.productPrice.value * 100 / (1 - newClientCommission)) / 100;
  if (discount == 0) {
    return currentPriceComposition;
  }
  // Recalcula o price composition:
  const currentNightValuesSum = currentPriceComposition.nights.map(r => r.value ?? 0).reduce((acc, b) => acc + b, 0);
  return {
    ..._pick(currentPriceComposition, 'commission', 'fee', 'discounts', 'taxes'),
    ...(COMMISSION_OVERRIDE_DISCOUNTED_VALUES.reduce((acc, key) => {
      if (currentPriceComposition[key]) {
        acc[key] = {
          ...currentPriceComposition[key],
          value: currentPriceComposition[key].value - discount
        };
      }
      return acc;
    }, {}) as Pick<RoomRate['priceComposition'], (typeof COMMISSION_OVERRIDE_DISCOUNTED_VALUES)[number]>),
    nights: currentPriceComposition.nights.map(night => ({
      ...night,
      value: night.value - Math.round(discount * night.value * 100 / currentNightValuesSum) / 100
    })) // noites - desconto fica espalhado entre cada noite (impreciso por conta de arredondamento de centavos, mas só vai valer para a tela)
  };
};
export const setPreviousOrderId = createAsyncThunk<{
  previousOrder: Order | SelfOrder | null;
  previousOrderClient: ShallowClient | null;
}, string | null, {
  state: CompleteRootState;
}>('shoppingCart/setPreviousOrderId', async (orderId, {
  getState
}) => {
  const rootState = getState();
  const b2c = rootState?.authentication?.b2c;
  if (orderId) {
    const previousOrder = b2c ? await selfOrderDAO.findById(orderId) : await orderDAO.findById(orderId);
    const clientId = previousOrder?.clientId;
    if (clientId) {
      const previousOrderClient = await shallowClientDAO.findById(clientId);
      return {
        previousOrder,
        previousOrderClient
      };
    }
    return {
      previousOrder,
      previousOrderClient: null
    };
  } else {
    return {
      previousOrder: null,
      previousOrderClient: null
    };
  }
});
const shoppingCartSlice = createSlice({
  name: 'shoppingCart',
  initialState: createState(),
  reducers: {
    clearPersonalData: function (state) {
      deletePersonalData(state.savestatePrefix);
      return {
        ...state,
        personalData: {}
      };
    },
    setPersonalData: function (state, action: PayloadAction<Partial<PersonalData>>) {
      const personalData = _merge(_cloneDeep(state.personalData), _cloneDeep(action.payload)); // bug #155019 (https://tfs.omnibees.com/tfs/IDTProjects/Omnibees/_workitems/edit/155019)
      // salva personal data no local storage
      savePersonalData(state.savestatePrefix, personalData);
      return {
        ...state,
        personalData
      };
    },
    setHotelRoomRatesQuantity: function (state, action: PayloadAction<{
      roomRates: [RoomRate];
      hotelResult: HotelResult;
      quantity: number;
      storefront?: Storefront;
    }>) {
      const {
        hotelResult
      } = action.payload;
      const roomRate = _cloneDeep(action.payload.roomRates[0]);
      const maxItemCount = state?.maxItemCount ?? 1;
      const quantity = Math.max(Math.min(action.payload.quantity == 0 ? 0 : action.payload.quantity, maxItemCount), 0);
      const roomRateIds: [string] = [roomRate.id];
      const purchaseSessionId = state.purchaseSessionId;

      // seta a imagem do quarto no roomRate.roomType.image
      if (roomRate?.roomType && !roomRate?.roomType?.image && hotelResult._hotelDetails) {
        const guestRoom = hotelResult._hotelDetails?.guestRooms?.find(guestRoom => guestRoom?.id == roomRate?.roomType?.id || guestRoom?.description && guestRoom?.description?.toLowerCase() == roomRate?.roomType?.name?.toLowerCase());
        const medias = guestRoom?.medias ?? [];
        const roomImage = medias?.[medias?.length > 1 ? 1 : 0]?.images?.[0]?.url;
        if (roomImage) {
          roomRate.roomType.image = roomImage;
        }
      }
      if (quantity == 0) {
        //se veio quantidade 0, remove o item
        const items = state.items?.filter(item => !_isEqual(item.roomRateIds, roomRateIds));
        const removedItems = state.items?.filter(i => items.indexOf(i) < 0);
        removedItems.forEach(item => {
          trackEventRemoveItemFromCart(hotelResult, item?.roomRates?.[0], action?.payload?.storefront);
        });
        return _makeTotal({
          ...state,
          items,
          loyaltyId: null,
          pointsToBurn: null
        });
      }

      // busca se existe no carrinho item com o mesmo room rate
      const existingItems = state.items?.filter(item => _isEqual(item.roomRateIds, roomRateIds));
      const currentQuantity = existingItems?.reduce((acc, i) => acc + i.quantity, 0) || 0;
      if (currentQuantity > quantity) {
        //remove itens
        const itemsToRemove = existingItems.slice(0, currentQuantity - quantity);
        const items = state.items?.filter(x => itemsToRemove.indexOf(x) < 0);
        // TODO track
        return _makeTotal({
          ...state,
          items,
          loyaltyId: null,
          pointsToBurn: null
        });
      } else if (currentQuantity < quantity) {
        trackEventAddItemToCart(hotelResult, [roomRate], action.payload?.storefront);
        //adiciona itens

        const itemsToAdd: HotelRoomItem[] = loop(quantity - currentQuantity, () => clearCartItemBeforeAddingToCart({
          hotelId: roomRate.hotelId,
          endDate: roomRate.time.endDate,
          startDate: roomRate.time.startDate,
          occupancy: roomRate.occupancy,
          currency: roomRate.priceComposition.productPrice.currency,
          quantity: 1,
          roomRateIds: roomRateIds,
          type: 'hotel',
          hotelResult,
          roomRates: [(roomRate as any)],
          itemId: 'item' + nanoid(),
          validUntil: Date.now() + DEFAULT_ITEM_VALIDITY,
          roomType: roomRate.roomType?.name,
          promoCode: roomRate.promoCode,
          clientId: hotelResult.clientId,
          engineRuleVersion: ((roomRate.engineRuleVersion ?? '0') as any)
        }));
        const firstItem = itemsToAdd[0];
        let items: CartItem[];
        //suporte apenas a itens do mesmo cliente, moeda e formas de pagamento
        if (!state.items || state.items?.find(
        // apagar depois quando acabar o item da task #154222
        // (i) => !_isMatch(i, _pick(firstItem, 'hotelId', 'endDate', 'startDate', 'currency', 'type', 'clientId'))
        i => {
          return !_isMatch(i, _pick(firstItem, 'currency', 'clientId', 'engineRuleVersion')) ||
          // impede adicionar itens no carrinho com opções de pagamento diferentes
          !_isMatch(i.engineRuleVersion === '1' ? i.roomRates?.[0]?.paymentOptions?.filter(r => r.enabled !== false).map(r => r.id) : i.roomRates[0]?.payment?.paymentOptions?.filter(r => r.enabled !== false).map(r => r.id), roomRate?.engineRuleVersion === '1' ? roomRate?.paymentOptions?.filter(r => r.enabled !== false).map(r => r.id) : roomRate?.payment?.paymentOptions?.filter(r => r.enabled !== false).map(r => r.id));
        })) {
          // se existe no carrinho itens com diferente formas de pagamento, moeda e cliente, limpa o carrinho e só mantem os quartos novos
          items = itemsToAdd;
        } else {
          items = state.items.concat(itemsToAdd);
        }

        // apaga previousOrderId e orderId se novo item não bater com o clientId
        if (state.previousOrderId && hotelResult.clientId !== state.previousOrder?.clientId) {
          state = {
            ...state,
            previousOrderId: null,
            orderId: null,
            previousOrder: null,
            previousOrderClient: null
          };
        }

        // salva o carrinho no localStorage
        saveCartItems(state.savestatePrefix, items, purchaseSessionId);
        return _makeTotal({
          ...state,
          items,
          loyaltyId: null,
          pointsToBurn: null
        });
      } else {
        // (currentQuantity == quantity) {
        //não faz nada
        return state;
      }
    },
    removeItem: function (state, action: PayloadAction<{
      itemId: string;
      storefront?: Storefront;
    }>) {
      const items = state.items?.filter(r => r.itemId !== action.payload.itemId);
      const removedItems = state.items?.filter(i => items.indexOf(i) < 0);
      const purchaseSessionId = state.purchaseSessionId;
      if (action?.payload?.storefront) {
        removedItems.forEach(item => {
          trackEventRemoveItemFromCart(item?.hotelResult, item?.roomRates?.[0], action?.payload?.storefront);
        });
      }
      // salva o carrinho no localStorage
      saveCartItems(state.savestatePrefix, items, purchaseSessionId);
      return _makeTotal({
        ...state,
        items,
        loyaltyId: null,
        pointsToBurn: null
      });
    },
    clearCart: function (state) {
      if (state.items?.length > 0) {
        deleteCartItems(state.savestatePrefix);
        // Não apaga algumas informações ao limpar o carrinho  (ex: nova busca na tela de detalhes de hotel)
        // TODO: Avaliar como deixar isto mais organizado - no modo atual, é difícil identificar
        // quais itens precisam ser mantidos no state ao limpar o carrinho.
        return {
          ...createState(),
          ..._pick(state, ['personalData', 'checkoutFormRule', 'checkoutFlowRule', 'maxItemCount', 'savestatePrefix'])
        };
      } else {
        return state;
      }
    },
    clearPreviousRoomRates: function (state) {
      const items = state.items?.map(r => ({
        ...r,
        previousRoomRates: null
      }));
      return {
        ...state,
        items
      };
    },
    removeDifferentItems: function (state, action: PayloadAction<{
      startDate;
      endDate;
      hotelId;
    }>) {
      const {
        startDate,
        endDate,
        hotelId
      } = action.payload;
      const items = state.items?.filter(i => _isMatch(i, {
        startDate,
        endDate
      }) && i.hotelResult?._soupIds?.indexOf(hotelId) >= 0);
      const purchaseSessionId = state.purchaseSessionId;
      saveCartItems(state.savestatePrefix, items, purchaseSessionId);
      return _makeTotal({
        ...state,
        items,
        loyaltyId: null,
        pointsToBurn: null
      });
    },
    setSavestatePrefix: function (state, action: PayloadAction<string>) {
      const savestatePrefix = action.payload;
      if (savestatePrefix) return _makeTotal({
        ...state,
        ...loadState(savestatePrefix),
        savestatePrefix
      });
      return state;
    },
    setOrderId: (state, action: PayloadAction<string | undefined>) => {
      const orderId = action.payload;
      return {
        ...state,
        orderId
      };
    },
    setQuotationId: (state, action: PayloadAction<string | undefined>) => {
      const quotationId = action.payload;
      return {
        ...state,
        quotationId
      };
    },
    setCouponCode: (state, action: PayloadAction<{
      couponCode: string | null;
    }>) => {
      const couponCode = action?.payload?.couponCode;
      return {
        ...state,
        couponCode
      };
    },
    setExtraFields: (state, action: PayloadAction<{
      extraFields: ExtraFields | null;
    }>) => {
      const extraFields = action?.payload?.extraFields;
      return {
        ...state,
        extraFields
      };
    },
    setResponsible: (state, action: PayloadAction<Responsible | null>) => {
      state.responsible = action.payload;
    },
    setPayLaterOptionByRule: (state, action: PayloadAction<PayLaterOptionByRule | null>) => {
      state.payLaterOptionByRule = action.payload;
    },
    /**
     * A agência define uma nova % de markup no checkout
     */
    _overrideSetMarkup: (state, action: PayloadAction<{
      newValue: number | null;
      itemId: string;
    }>) => {
      return _makeTotal({
        ...state,
        items: state?.items?.map(item => {
          if (item.itemId != action.payload.itemId) {
            return item;
          }
          const roomRate = item.roomRates[0];
          const currentPriceComposition = roomRate?.priceComposition;
          if (!currentPriceComposition?.clientCommission || !currentPriceComposition.productPrice?.value) {
            // item não tem comissão de cliente, ou não tem valor total
            throw new Error('Item não pode ter clientCommission editado');
          }
          const currentMarkup = !currentPriceComposition.productPrice?.value || !currentPriceComposition.markup?.value ? 0 : currentPriceComposition.markup?.value / currentPriceComposition.productPrice.value;
          const markup_original: number = item?.markup_original ?? roomRate?.priceComposition?.markup?.originalPercentage ?? roomRate?.priceComposition?.markup?.percentage ?? currentMarkup;
          const discountedMarkupPercentage = action.payload.newValue ?? markup_original;
          if (discountedMarkupPercentage > markup_original || discountedMarkupPercentage < 0) {
            throw new Error('Valor de comissão não permitida');
          }
          if (discountedMarkupPercentage == currentMarkup) {
            // não mudou nada
            return item;
          }

          // Recalcula o price composition:
          const priceComposition: RoomRate['priceComposition'] = _applyClientCommission(currentPriceComposition, discountedMarkupPercentage);
          return {
            ...(item as EngineRuleRoomItem),
            // não sei como não quebrar o tipo
            roomRates: [({
              ...roomRate,
              priceComposition
            } as EngineRuleRoomRate)],
            // não sei como não quebrar o tipo
            markup_original,
            discountedMarkupPercentage
          };
        })
      });
    },
    /**
     * A agência define uma nova % de comissão de cliente na hora do checkout
     */
    _overrideClientCommission(state, action: PayloadAction<{
      itemId: string;
      newValue: number | null;
    }>) {
      return _makeTotal({
        ...state,
        items: state.items.map((item): (typeof state)['items'][number] => {
          if (item.itemId != action.payload.itemId) {
            return item;
          }
          const roomRate = item.roomRates[0];
          const currentPriceComposition = roomRate?.priceComposition;
          if (!currentPriceComposition?.clientCommission || !currentPriceComposition.productPrice?.value) {
            // item não tem comissão de cliente, ou não tem valor total
            throw new Error('Item não pode ter clientCommission editado');
          }
          const currentClientCommission = !currentPriceComposition.productPrice?.value || !currentPriceComposition.clientCommission?.value ? 0 : currentPriceComposition.clientCommission?.value / currentPriceComposition.productPrice.value;
          const clientCommission_original: number = item?.clientCommission_original ?? currentClientCommission;
          const discountedClientCommissionPercentage = action.payload.newValue ?? clientCommission_original;
          if (discountedClientCommissionPercentage > clientCommission_original || discountedClientCommissionPercentage < 0) {
            throw new Error('Valor de comissão não permitida');
          }
          if (discountedClientCommissionPercentage == currentClientCommission) {
            // não mudou nada
            return item;
          }

          // Recalcula o price composition:
          const priceComposition: RoomRate['priceComposition'] = _applyClientCommission(currentPriceComposition, discountedClientCommissionPercentage);
          return {
            ...(item as EngineRuleRoomItem),
            // não sei como não quebrar o tipo
            roomRates: [({
              ...roomRate,
              priceComposition
            } as EngineRuleRoomRate)],
            // não sei como não quebrar o tipo
            clientCommission_original,
            discountedClientCommissionPercentage: discountedClientCommissionPercentage
          };
        })
      });
    }
  },
  extraReducers: builder => {
    builder.addCase(updatePaymentParameters.pending, (state, action) => {
      // Codigo para atualizar o pointsToBurn caso seja seta, garantindo o valor correto
      if (action.meta.arg && action.meta.arg.pointsToBurn !== undefined) {
        state.pointsToBurn = action.meta.arg.pointsToBurn;
      }
    }).addCase(updatePaymentParameters.fulfilled, (state, action) => {
      if (action.payload) {
        // atualiza com os dados do update payment parameters
        const paymentOptions = action.payload.paymentOptions;
        const maxPointsToBurn = action.payload.maxPointsToBurn;
        const minPointsToBurn = action.payload.minPointsToBurn;
        const paymentValue = action.payload.paymentValue;
        const pointsToBurn = !maxPointsToBurn ? undefined : action.payload.pointsToBurn !== undefined ? action.payload.pointsToBurn : minPointsToBurn === maxPointsToBurn ? minPointsToBurn : state.maxPointsToBurn === maxPointsToBurn ? state.pointsToBurn : null;
        return {
          ...state,
          ...action.payload,
          paymentOptions,
          maxPointsToBurn,
          minPointsToBurn,
          paymentValue,
          pointsToBurn
        };
      }
      return state;
    }).addCase(updatePaymentParameters.rejected, (state, action) => {
      // Codigo para atualizar o pointsToBurn caso seja seta, garantindo o valor correto
      // se o update falhar, limpa o pointsToBurn
      if (action.meta.arg && action.meta.arg.pointsToBurn !== undefined) {
        state.pointsToBurn = null;
      }
    }).addCase(checkoutCart.pending, state => {
      state.checkoutInProgress = true;
      // state.loyaltyNeedCode = undefined
      return state;
    }).addCase(checkoutCart.fulfilled, state => {
      state.checkoutInProgress = false;
      // reseta o carrinho para permitir nova tentativa
      // OBS: não zerar o carrinho aqui - o checkout pode finalizar com sucesso,
      // mas erro de pagamento pode ser detectado só depois.
      state.loyaltyNeedCode = undefined;
      state.loyaltyId = null;
      return state;
    }).addCase(checkoutCart.rejected, state => {
      state.checkoutInProgress = false;
      // reseta o carrinho para permitir nova tentativa
      state.loyaltyNeedCode = undefined;
      state.loyaltyId = null;
      return state;
    }).addCase(authorizeBurn.pending, (state, action) => {
      state.checkoutInProgress = true;
      state.loyaltyId = null;
      state.loyaltyNeedCode = undefined;
    }).addCase(authorizeBurn.rejected, (state, action) => {
      state.checkoutInProgress = false;
      state.loyaltyId = null;
      state.loyaltyNeedCode = undefined;
    }).addCase(authorizeBurn.fulfilled, (state, action) => {
      state.checkoutInProgress = false;
      state.loyaltyId = action.payload.loyaltyId;
      state.loyaltyNeedCode = action.payload.needCode;
    }).addCase(undoAuthorizeBurn.pending, state => {
      state.checkoutInProgress = true;
      state.loyaltyNeedCode = false;
    }).addCase(undoAuthorizeBurn.rejected, state => {
      state.checkoutInProgress = false;
      state.loyaltyId = null;
      state.loyaltyNeedCode = false;
    }).addCase(undoAuthorizeBurn.fulfilled, state => {
      state.checkoutInProgress = false;
      state.loyaltyId = null;
      state.loyaltyNeedCode = false;
    }).addCase(checkLoyaltyAuthorizationCode.pending, state => {
      state.loyaltyCodeCheckInProgress = true;
    }).addCase(checkLoyaltyAuthorizationCode.fulfilled, state => {
      state.loyaltyCodeCheckInProgress = false;
      state.loyaltyNeedCode = false;
    }).addCase(checkLoyaltyAuthorizationCode.rejected, state => {
      state.loyaltyCodeCheckInProgress = false;
    }).addCase(searchHotels.fulfilled, (state, action) => {
      // se é uma pesquisa disparada pelo refresh cart, substitui o roomRate que já está no cartItem
      // pelo room rate encontrado no searchHotels
      const searchId = action.payload.searchId;
      const cartItem = state.items.find(r => r.itemId === searchId);
      const purchaseSessionId = state.purchaseSessionId;
      if (cartItem && action.payload.results) {
        const prevRoomRate = cartItem.roomRates[0];
        const [hotelResult, newRoomRate] = action.payload.results?.map(hotelResult => {
          if (!hotelResult?._soupIds.includes(cartItem.hotelId)) return null;
          const roomRate = hotelResult.roomRates?.find(rr => rr.id == prevRoomRate.id);
          if (roomRate) return ([hotelResult, roomRate] as const);else return null;
        }).filter(Boolean)?.[0] ?? ([] as const);
        if (newRoomRate) {
          const items: ShoppingCartState['items'] = state.items.map((r): (typeof state)['items'][number] => {
            if (r === cartItem) {
              let roomRate = newRoomRate || prevRoomRate;
              // mantém o discountedClientCommissionPercentage
              const keepDiscountedClientCommissionPercentage = r.discountedClientCommissionPercentage != null && r.discountedClientCommissionPercentage === action.meta.arg?.discountedClientCommissionPercentage; // desencargo de consciência - não deve acontecer

              // mantém o discountedMarkupPercentage
              const keepdiscountedMarkupPercentage = r.discountedMarkupPercentage != null && r.discountedMarkupPercentage === action.meta.arg?.discountedMarkupPercentage; // desencargo de consciência - não deve acontecer

              // mantém o roomType.image do prevRoomRate
              if (roomRate?.roomType && !roomRate?.roomType?.image && prevRoomRate?.roomType?.image) {
                roomRate = {
                  ...roomRate,
                  roomType: {
                    ...roomRate.roomType,
                    image: prevRoomRate.roomType.image
                  }
                };
              }
              return clearCartItemBeforeAddingToCart({
                ...(r as EngineRuleRoomItem),
                roomRates: ([roomRate] as EngineRuleRoomItem['roomRates']),
                previousRoomRates: prevRoomRate ? ([prevRoomRate] as EngineRuleRoomItem['roomRates']) : null,
                clientCommission_original: keepDiscountedClientCommissionPercentage ? r.clientCommission_original : null,
                discountedClientCommissionPercentage: keepDiscountedClientCommissionPercentage ? r.discountedClientCommissionPercentage : null,
                markup_original: keepdiscountedMarkupPercentage ? r.markup_original : null,
                discountedMarkupPercentage: keepdiscountedMarkupPercentage ? r.discountedMarkupPercentage : null,
                hotelResult,
                currency: roomRate.priceComposition.productPrice.currency,
                refreshCartInProgress: false
              });
            } else {
              return r;
            }
          });
          saveCartItems(state.savestatePrefix, items, purchaseSessionId);
          return _makeTotal({
            ...state,
            items
          }); // vai atualizar o total e o loyaltyRedeemConfiguration
        }
        // não retornou o room rate no refresh
        return state;
      } else {
        return state;
      }
    }).addCase(refreshCart.pending, (state, action) => {
      state.refreshInProgress = true;
      if (action.meta.arg) {
        state.paymentOptionIdForCartRefresh = action.meta.arg.paymentOptionId ?? state?.paymentOptionIdForCartRefresh;
        state.paymentOptionTypeForCartRefresh = action.meta.arg.paymentOptionType ?? state?.paymentOptionTypeForCartRefresh;
      }
      // para cada item do carrinho salva o parametro refreshCartInProgress
      state.items = state.items?.map(item => {
        return {
          ...item,
          refreshCartInProgress: true
        };
      });
    }).addCase(refreshCart.fulfilled, (state, action) => {
      state.refreshInProgress = false;

      // verifica se algum item perdeu a disponibilidade, nesse caso marca a flag notFoundInRefreshCart do item do carrinho
      state.items = state.items?.map(item => ({
        ...item,
        notFoundInRefreshCart: item?.refreshCartInProgress,
        refreshCartInProgress: false
      }));
      if (action.meta.arg && action.meta.arg.couponCode !== undefined) {
        state.couponCode = action.meta.arg.couponCode;
      }
      if (state.paymentOptionIdForCartRefresh || state.paymentOptionTypeForCartRefresh) {
        saveCartItems(state.savestatePrefix, state.items, state.purchaseSessionId, state.paymentOptionIdForCartRefresh, state.paymentOptionTypeForCartRefresh);
      }
    }).addCase(refreshCart.rejected, state => {
      state.refreshInProgress = false;
    }).addCase(loadBuyerPaymentRule.fulfilled, (state, action) => {
      if (action.payload) state.buyerPaymentRule = action.payload;
    }).addCase(loadCheckoutFormRule.fulfilled, (state, action) => {
      if (action.payload) state.checkoutFormRule = action.payload;
    }).addCase(loadCheckoutFlowRule.fulfilled, (state, action) => {
      if (action.payload) {
        state.checkoutFlowRule = action.payload;
        state.maxItemCount = action.payload?.shoppingCartEnabled ? action.payload?.shoppingCartMaxItemCount ?? 1 : 1;
      }
    }).addCase(setPreviousOrderId.pending, (state, action) => {
      state.previousOrderId = action.meta.arg;
      state.orderId = action.meta.arg;
      state.previousOrder = null;
      state.previousOrderClient = null;
    }).addCase(setPreviousOrderId.fulfilled, (state, action) => {
      if (state.previousOrderId === action.payload.previousOrder?.id) {
        state.previousOrder = action.payload.previousOrder;
        state.previousOrderClient = action.payload.previousOrderClient;
      } else {
        state.previousOrderId = null;
        state.orderId = null;
        state.previousOrder = null;
        state.previousOrderClient = null;
      }
    }).addCase(setPerson.pending, (state, action) => {
      if (state.personId != action.meta?.arg.personId) {
        state.person = null;
        state.personBalance = null;
        state.personContractStatus = null;
        state.personBalanceUnit = null;
        state.personTSER_idContrato = null;
        state.personTSER_numeroContrato = null;
      }
      state.personId = action.meta?.arg?.personId;
    }).addCase(setPerson.fulfilled, (state, action) => {
      if (state.personId == action.payload?.person?.id) {
        const person = action.payload?.person;
        state.person = action.payload?.person;
        state.personBalance = action.payload?.personBalance;
        state.personContractStatus = action.payload?.personContractStatus;
        state.personBalanceUnit = action.payload?.personBalanceUnit;
        state.personTSER_idContrato = action.payload?.personTSER_idContrato;
        state.personTSER_numeroContrato = action.payload?.personTSER_numeroContrato;
        if (person) {
          const personalData = _merge(_cloneDeep(state.personalData), {
            buyer: {
              ...person,
              personId: person.id,
              id: undefined
            }
          });
          // salva personal data no local storage
          savePersonalData(state.savestatePrefix, personalData);
          state.personalData = personalData;
        }
      }
    }).addMatcher(isAnyOf(Authentication.authenticateWithIdentityProvider.fulfilled, Authentication.completeAuthenticationWithNiaraAuthAccessToken.fulfilled), (state, action) => {
      if ('user' in action?.payload && action?.payload?.user != null && action?.payload?.b2c) {
        // preenche os dados do usuário logado para o checkout
        const user = action.payload.user;
        const personalData: PersonalData = {
          buyer: {
            ...user,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email?.toLowerCase()?.trim(),
            _emailConfirm: user.email?.toLowerCase()?.trim(),
            country: 'BR',
            phone: user.phoneNumber,
            cpf: user.cpf
          }
        };
        savePersonalData(state.savestatePrefix, personalData);
        return {
          ...state,
          personalData
        };
      }
      return state;
    }).addMatcher(isAnyOf(Authentication.initAuthentication.fulfilled, Authentication.setupEmbeddedApp.fulfilled), (state, action) => {
      if (!action.payload.b2c && action.payload?.user?.username) {
        // carrega o usuário como responsável
        state.responsible = {
          name: [action.payload.user.firstName, action.payload.user.lastName].filter(Boolean).join(' '),
          email: action.payload.user.email,
          id: action.payload.user.username
        };
      }
    });
  }
});
export const {
  clearPersonalData,
  setPersonalData,
  clearCart,
  clearPreviousRoomRates,
  removeItem,
  setHotelRoomRatesQuantity,
  removeDifferentItems,
  setSavestatePrefix,
  setOrderId,
  setQuotationId,
  setCouponCode,
  setExtraFields,
  setResponsible,
  setPayLaterOptionByRule
} = shoppingCartSlice.actions;
const {
  _overrideClientCommission,
  _overrideSetMarkup
} = shoppingCartSlice.actions;
export const setPointsToBurn = (p: {
  pointsToBurn?: number;
}) => async (dispatch: Dispatch, getState: {
  (): BaseRootState;
}): Promise<void> => {
  const {
    pointsToBurn
  } = p;
  if (pointsToBurn != getState().shoppingCart.pointsToBurn) {
    await dispatch(updatePaymentParameters({
      pointsToBurn
    })).then(unwrapResult /* para lançar erro */);
    await dispatch(refreshCart()).then(unwrapResult /* para lançar erro */);
    return;
  }
};
export default shoppingCartSlice.reducer;