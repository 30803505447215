import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Cupom de desconto',
  labelPlural: 'Cupons de desconto',
  name: 'Coupon',
  namePlural: 'Coupons',
  authorization: authorization('consultant', 'admin'),
  custom_withAudit: true,
  deletable: false,
  dynamoDB: {
    tableName: 'SpearCoupon'
  },
  columns: ['id', 'name', 'code', 'description', 'startDate', 'endDate', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Nome',
    type: 'text',
    required: true
  }, {
    name: 'code',
    label: 'Código',
    type: 'text',
    required: true,
    pattern: '[A-Z0-9]{1,}',
    hint: 'Apenas letras e números',
    allCaps: true,
    minLength: 4,
    maxLength: 20
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: true,
    required: true,
    type: 'boolean'
  }, {
    custom_groupName: "Valor",
    name: 'type',
    label: 'Tipo do desconto',
    required: true,
    options: [{
      value: 'TOTAL_RESERVATION_PERCENTAGE',
      label: 'Percentual do valor da reserva'
    }, {
      value: 'NIGHTS',
      label: 'Número de noites',
      disabled: true
    }, {
      value: 'FIXED',
      label: 'Valor fixo'
    }]
  }, {
    custom_groupName: "Valor",
    name: 'valuePercentage',
    label: 'Porcentagem do total de reserva',
    type: 'float',
    inputType: 'percentage',
    conditionalRequired: function (e) {
      return e?.type == 'TOTAL_RESERVATION_PERCENTAGE';
    },
    renderForm: function (i) {
      return i?.type == 'TOTAL_RESERVATION_PERCENTAGE' ? this?.render(i) : false;
    }
  }, {
    custom_groupName: "Valor",
    name: 'valueNights',
    label: 'Valor do desconto por noites',
    type: 'int',
    conditionalRequired: function (e) {
      return e?.type == 'NIGHTS';
    },
    renderForm: function (i) {
      return i?.type == 'NIGHTS' ? this?.render(i) : false;
    }
  }, {
    custom_groupName: "Valor",
    name: 'valueFixedDiscount',
    label: 'Valor fixo de desconto',
    type: 'float',
    inputType: 'price',
    renderForm: function (i) {
      return i?.type == 'FIXED' ? this?.render(i) : false;
    }
  }, {
    custom_groupName: "Valor",
    name: 'valueCurrency',
    label: 'Moeda',
    type: 'text',
    options: [{
      value: 'BRL',
      label: 'Reais (BRL)'
    }, {
      value: 'USD',
      label: 'Dólar (USD)'
    }, {
      value: 'EUR',
      label: 'Euro (EUR)'
    }],
    renderForm: function (i) {
      return i?.type == 'FIXED' ? this?.render(i) : false;
    }
  }, {
    custom_groupName: "Valor",
    name: 'appliesOnTaxes',
    label: 'Incluir valor da taxa no cálculo do desconto',
    hint: 'Se marcar isto, vai calcular a partir do valor das diárias + taxas. Desmarcado, o cálculo vai ser a partir apenas das diárias.',
    type: 'boolean',
    default: false,
    renderForm: function (i) {
      return i?.type == 'TOTAL_RESERVATION_PERCENTAGE' ? this?.render(i) : false;
    }
  }, {
    custom_groupName: "Configuração de exibição",
    name: 'description',
    label: 'Descrição',
    type: 'text',
    required: true,
    maxLength: 50,
    hint: 'Exibe nos resultados da pesquisa'
  }, {
    custom_groupName: "Configuração de exibição",
    name: 'longDescription',
    label: 'Descrição longa',
    type: 'text',
    inputMode: 'textarea',
    hint: 'Exibe ao usuário em um tooltip'
  },
  // {
  //   custom_groupName: 'i18n:Configuração de exibição',
  //   label: 'Ícone',
  //   inputType: 'inputPublicFile',
  //   name: 'icon',
  //   fileType: 'images',
  //   custom_gerarPrefix: (i) => {
  //     return 'coupons/' + i.id + '/icon/'
  //   },
  //   hint: 'Para exibição em e-mail. Tamanho sugerido: 80px x 80px.',
  // },
  {
    custom_groupName: "Termos e condições",
    name: 'terms_type',
    label: 'Formato dos termos',
    required: true,
    options: [{
      value: 'TERMS_FILE',
      label: 'Fazer upload do arquivo'
    }, {
      value: 'TERMS_TEXT',
      label: 'Digitar os termos'
    }]
  }, {
    custom_groupName: "Termos e condições",
    name: 'terms_text',
    label: 'Termos e condições',
    inputType: 'wysiwyg',
    renderForm: function (i) {
      return i?.terms_type == 'TERMS_TEXT' ? this?.render(i) : false;
    }
  }, {
    custom_groupName: "Termos e condições",
    name: 'terms_file',
    label: 'Arquivo de termos e condições',
    custom_gerarPrefix: i => {
      return 'coupons/' + i.id + '/terms_file/';
    },
    inputType: 'inputPublicFile',
    fileType: 'pdf',
    conditionalRequired: function (e) {
      return e?.terms_type == 'TERMS_FILE';
    },
    renderForm: function (i) {
      return i?.terms_type == 'TERMS_FILE' ? this?.render(i) : false;
    }
  }, {
    custom_groupName: "Validade",
    name: 'startDate',
    label: 'Válido de',
    type: 'date'
  }, {
    custom_groupName: "Validade",
    name: 'endDate',
    label: 'Válido até',
    type: 'date'
  }, {
    custom_groupName: "Limites",
    name: 'useLimit',
    label: 'Definir limite de uso',
    type: 'boolean',
    inputType: 'checkbox'
  }, {
    custom_groupName: "Limites",
    name: 'limitType',
    label: 'Tipo de limite',
    options: [{
      value: 'MAX_USE_LIMIT',
      label: 'Máx. de uso'
    }, {
      value: 'MAX_USE_LIMIT_PER_DAY',
      label: 'Máx. de uso por dia'
    }],
    conditionalRequired: function (e) {
      return e?.useLimit;
    },
    renderForm: function (i) {
      return i?.useLimit ? this?.render(i) : false;
    }
  }, {
    custom_groupName: "Limites",
    name: 'maxUseLimit',
    label: 'Máx. de uso',
    type: 'int',
    conditionalRequired: function (e) {
      return e?.useLimit && e?.limitType == 'MAX_USE_LIMIT';
    },
    renderForm: function (i) {
      return i?.useLimit && i?.limitType == 'MAX_USE_LIMIT' ? this?.render(i) : false;
    }
  }, {
    custom_groupName: "Limites",
    name: 'maxUseLimitPerDay',
    label: 'Máx. de uso por dia',
    type: 'int',
    conditionalRequired: function (e) {
      return e?.useLimit && e?.limitType == 'MAX_USE_LIMIT_PER_DAY';
    },
    renderForm: function (i) {
      return i?.useLimit && i?.limitType == 'MAX_USE_LIMIT_PER_DAY' ? this?.render(i) : false;
    }
  }],
  filter: [{
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq'
  }, {
    field: 'code',
    op: 'eq'
  }, {
    field: 'startDate',
    op: 'ge'
  }, {
    field: 'startDate',
    op: 'le'
  }, {
    field: 'endDate',
    op: 'ge'
  }, {
    field: 'endDate',
    op: 'le'
  }, {
    field: 'useLimit',
    op: 'eq'
  }, {
    field: 'maxUseLimit',
    op: 'ge'
  }, {
    field: 'maxUseLimit',
    op: 'le'
  }, {
    field: 'maxUseLimitPerDay',
    op: 'ge'
  }, {
    field: 'maxUseLimitPerDay',
    op: 'le'
  }]
});