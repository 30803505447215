import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Condição de Recebimento',
  labelPlural: 'Condições de Recebimento',
  name: 'PaymentCondition',
  insertable: true,
  updatable: true,
  deletable: false,
  deprecated: true,
  authorization: authorization('consultant', 'admin'),
  'custom:sortFields': ['type', 'alias'],
  columnNames: ['id', 'type', 'alias', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    render: false,
    id: true
  }, {
    name: 'type',
    label: 'Tipo de Recebimento',
    required: true,
    default: 'OFF_FAT',
    options: [{
      value: 'OFF_CC',
      label: 'Cartão Offline',
      hint: 'vai exigir número do cartão do comprador',
      disabled: true
    }, {
      value: 'OFF_FAT',
      label: 'Faturado Offline',
      hint: 'boleto, transferência, depósito, ...'
    }, {
      value: 'OFF_ANT',
      label: 'Antecipado Offline',
      hint: 'boleto, transferência, depósito, ...'
    }, {
      value: 'ON_CC',
      label: 'Cartão Online',
      hint: 'Recebimento do cartão na hora da compra. Integração Braspag.'
    }, {
      value: 'ON_VOUCHER',
      label: 'Voucher/Gift Card',
      hint: 'Recebimento por um voucher ou gift-card'
    }, {
      value: 'ON_PIX',
      label: 'PIX',
      hint: 'Recebimento por PIX'
    }, {
      value: 'POINTS',
      label: 'PONTOS',
      hint: 'Recebimento por PONTOS'
    }],
    custom_checkTenant: tenant => !tenant?.isBee2Pay
  }, {
    name: 'alias',
    label: 'Nome',
    required: true
    // hint: 'Esse texto vai aparecer no momento da reserva como opção de pagamento.',
  }, {
    name: 'isChangePayment',
    label: 'Troca de pagamento',
    required: true,
    default: false,
    hint: 'Troca de pagamento. (experimental)',
    type: 'boolean',
    custom_checkTenant: tenant => tenant?.modules_crs
  }, {
    name: 'shortDescription',
    label: 'Descrição',
    required: false,
    deprecated: true,
    render: false
  }, {
    name: 'description',
    label: 'Instruções',
    required: true,
    hint: 'Esse texto vai aparecer no momento da reserva se essa forma de pagamento for selecionada.',
    custom_checkTenant: tenant => !tenant?.isBee2Pay
  }, {
    custom_groupName: "Fornecedor",
    name: 'credentialId',
    label: 'Credencial',
    required: false,
    inputType: 'credential',
    type: 'text',
    credentialTypeList: entity => {
      if (entity?.type === 'ON_CC') return ['ADYE', 'MCPG', 'BRPG', 'EBNX'];else if (entity?.type === 'ON_PIX') return ['ADYE', 'SPIN', 'GTNT', 'ITAU'];else return [];
    }
  }, {
    custom_groupName: "Fornecedor",
    name: 'credentialName',
    label: 'Nome da Credencial',
    type: 'text',
    renderForm: false
  }, {
    custom_groupName: "Fornecedor",
    name: 'integrationDescriptor',
    label: 'Descrição na integração',
    type: 'text'
  }, {
    custom_groupName: "Cartão Online",
    name: 'oncc_conditions',
    label: 'Condições',
    type: 'objectarray',
    graphQLType: '[PaymentConditionOnCcCondition]',
    graphQLInputType: '[PaymentConditionOnCcConditionInput]',
    fields: [{
      name: 'credentialId',
      label: 'Credencial',
      required: false,
      inputType: 'credential',
      type: 'text'
    }, {
      //TODO: retirar do inputCredential para poder tirar esse campo.
      name: 'credentialName',
      label: ' ',
      type: 'text',
      renderForm: false
    }, {
      name: 'cardBrands',
      label: 'Bandeiras',
      required: true,
      type: 'textarray',
      options: [{
        label: 'Amex',
        value: 'AMEX'
      }, {
        label: 'Aura',
        value: 'AURA'
      }, {
        label: 'Diners',
        value: 'DINERS'
      }, {
        label: 'Elo',
        value: 'ELO'
      }, {
        label: 'Hiper',
        value: 'HIPER'
      }, {
        label: 'Hipercard',
        value: 'HIPERCARD'
      }, {
        label: 'JCB',
        value: 'JCB'
      }, {
        label: 'MasterCard',
        value: 'MASTERCARD'
      }, {
        label: 'Visa',
        value: 'VISA'
      }, {
        label: 'Discover',
        value: 'DISCOVER'
      }]
    }, {
      name: 'provider',
      label: 'Adquirente',
      required: true,
      type: 'text',
      inputType: 'select',
      options: [{
        value: 'CIELO',
        label: 'Cielo 3.0'
      }, {
        value: 'CIELO15',
        label: 'Cielo 1.5'
      }, {
        value: 'REDE',
        label: 'Rede'
      }, {
        value: 'GETNET',
        label: 'GetNet'
      }, {
        value: 'GLOBALPAY',
        label: 'Global Payments'
      }, {
        value: 'STONE',
        label: 'Stone'
      }, {
        value: 'SAFRA',
        label: 'Safra'
      }, {
        value: 'SAFRA2',
        label: 'Safra 2.0'
      }, {
        value: 'FIRSTDATA',
        label: 'First Data'
      }, {
        value: 'TEST',
        label: 'Simulado'
      }, {
        value: 'PAGSEGURO',
        label: 'PagSeguro'
      }]
    }, {
      name: 'maxInst',
      label: 'Número máximo de parcelas',
      required: true,
      default: 1,
      type: 'int'
    }, {
      name: 'minInstValue',
      label: 'Valor mínimo da parcela',
      required: true,
      type: 'float'
    }],
    renderForm: i => i.type && 'ON_CC' === i.type ? i.oncc_conditions : false
  }, {
    custom_groupName: "Cartão Online",
    name: 'oncc_bins',
    label: 'Bins autorizados',
    hint: 'Bins autorizados. Outros vão ser rejeitados.',
    render: i => i.type && i.type === 'ON_CC' ? i.oncc_bins : false
  }, {
    custom_groupName: "Configurações de cobrança",
    name: 'partialPercentage',
    label: '% do Pagamento (Pagamento Parcial)',
    description: 'Caso o percentual configurado for inferior à 100%, o sistema assume que resto do pagamento será realizado outro momento.',
    type: 'float',
    default: 1.0,
    inputType: 'percentage'
  }, {
    custom_groupName: "Configurações de cobrança",
    name: 'paymentRules',
    label: 'Regras de cobrança',
    description: 'Se a configuração não for especificado, o sistema considera que o pagamento deverá ser feito integralmente no momento da reserva. Caso o somatório do percentual configurado for inferior à 100%, o sistema assume que resto do pagamento será realizado manualmente no hotel.',
    required: true,
    type: 'objectarray',
    graphQLType: '[PaymentConditionPaymentRule]',
    graphQLInputType: '[PaymentConditionPaymentRuleInput]',
    deprecated: true,
    renderForm: false,
    fields: [{
      name: 'event',
      label: 'Evento',
      required: true,
      type: 'text',
      inputType: 'select',
      options: [{
        value: 'BOOKING',
        label: 'Reserva'
      }
      //{ value: 'PRE_CANCEL', label: 'Cancelamento automático' },
      //{ value: 'CHECKIN', label: 'Checkin' },
      //{ value: 'CHEKOUT', label: 'Checkout' },
      ]
    }, {
      name: 'percentage',
      label: 'Percentual',
      hint: 'Percentual do valor total da reserva que deve ser pago',
      required: true,
      type: 'float'
    }, {
      name: 'triggerType',
      label: 'Momento de disparo',
      type: 'text',
      inputType: 'select',
      options: [{
        value: 'AFTER',
        label: 'Após o evento'
      }, {
        value: 'BEFORE',
        label: 'Antes do evento'
      }]
    }, {
      name: 'days',
      label: 'Quantidade de dias',
      required: false,
      default: 0,
      type: 'float'
    }]
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: false,
    type: 'boolean'
  }],
  filter: [{
    field: 'enabled',
    op: 'eq',
    name: 'enabled_eq'
  }]
});