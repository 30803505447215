import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Grupo de propriedade',
  labelPlural: 'Grupo de propriedades',
  name: 'PropertyGroup',
  namePlural: 'PropertyGroups',
  authorization: authorization('admin', 'admin'),
  deletable: false,
  dynamoDB: {
    tableName: 'SpearPropertyGroup',
    indexedProp: 'name',
    indexName: 'tenantId-name-index'
  },
  columns: ['id', 'name'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    label: 'Tenant Id',
    name: 'tenantId',
    type: 'text',
    updatable: false,
    insertable: false,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Nome',
    type: 'text',
    required: true
  }, {
    name: 'properties',
    label: 'Hoteis',
    required: true,
    type: 'objectarray',
    fields: [{
      name: 'id',
      label: 'ID'
    }, {
      name: 'name',
      label: 'Nome'
    }],
    inputType: 'InputMultiHotelAutoComplete'
  }],
  searchParams: [{
    name: 'query',
    type: 'text'
  }, {
    name: 'autocomplete',
    type: 'text'
  }, {
    name: 'opensearch',
    type: 'boolean'
  }, {
    name: 'pageSize',
    type: 'int'
  }, {
    name: 'hashKeyValue',
    type: 'text'
  }],
  filter: [{
    field: 'name',
    op: 'beginsWith'
  }]
});