import _cloneDeep from 'lodash/cloneDeep'
import _merge from 'lodash/merge'
import { readableColor } from 'polished'
import React, { useMemo } from 'react'
import { ThemeProvider as ThemeProviderStyled, useTheme } from 'styled-components'
import { ThemeProvider as FormThemeProvider } from '@niaratech/niara-react-form'
import { create as createOtaTheme } from '@niaratech/niara-react-form/lib/themes/otaTheme'
import { create as createAdminTheme } from '@niaratech/niara-react-form/lib/themes/adminTheme'
import ota from './ota'
import ThemeProviderWrapper from './ThemeProviderWrapper'
import type { Theme } from './index'

type Props = {
  theme?: Partial<Theme>
  className?: string
  style?: React.CSSProperties
  noDivWrapper?: boolean
  themeType?: 'ota' | 'admin'
}

const BG_X_TEXT = [
  ['navbarBg', 'navbarText'],
  ['primary', 'primaryReadable'],
  ['secondary', 'secondaryReadable'],
] as const

const ThemeProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  theme: _customTheme,
  className,
  style,
  noDivWrapper,
  themeType = 'ota',
}) => {
  const currentTheme = useTheme()
  const baseTheme = currentTheme ?? ota

  const mergedTheme = useMemo(
    function () {
      const customTheme = _customTheme ? _cloneDeep(_customTheme) : null
      if (customTheme?.colors) {
        BG_X_TEXT.forEach(([bg, text]) => {
          if (!customTheme.colors[text] && customTheme.colors[bg]) {
            customTheme.colors[text] = readableColor(customTheme.colors[bg])
          }
        })
      }
      const merged: Theme = customTheme ? _merge(_cloneDeep(baseTheme), customTheme) : _cloneDeep(baseTheme)

      BG_X_TEXT.forEach(([bg, text]) => {
        if (!merged.colors[text] && merged.colors[bg]) {
          merged.colors[text] = readableColor(merged.colors[bg])
        }
      })

      return merged
    },
    [_customTheme, baseTheme]
  )

  const createTheme = themeType === 'ota' ? createOtaTheme : createAdminTheme
  const otaTheme = useMemo(() => {
    return createTheme(mergedTheme?.colors)
  }, [mergedTheme?.colors, createTheme])

  return (
    <>
      <ThemeProviderStyled theme={mergedTheme}>
        <FormThemeProvider theme={otaTheme}>
          {noDivWrapper ? (
            children
          ) : (
            <ThemeProviderWrapper style={style} className={className}>
              {children}
            </ThemeProviderWrapper>
          )}
        </FormThemeProvider>
      </ThemeProviderStyled>
    </>
  )
}

export default ThemeProvider
