import styled from 'styled-components'

interface ActiveCartProps {
  borderActive?: boolean
  inNavigationComponent?: boolean
}

export const Cart = styled.button<ActiveCartProps>`
  background-color: transparent;
  border: 1px solid
    ${({ borderActive, inNavigationComponent, theme }) =>
      borderActive
        ? !inNavigationComponent
          ? theme?.colors?.gray4
          : theme?.colors?.navbarText
        : theme?.colors?.gray4};
  border-radius: 4px;
  height: 40px;
  padding: 0 2px;
  position: relative;
  transition: ease-in-out 0.2s;
  width: 40px;
  z-index: 1;
`

export const NumberItems = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme?.colors?.error};
  border-radius: 50%;
  color: ${({ theme }) => theme?.colors?.white};
  display: flex;
  font-size: 10px;
  justify-content: center;
  height: 18px;
  right: -8px;
  position: absolute;
  top: -8px;
  width: 18px;
`

type ContainerProps = {
  beforeBackground?: string
}
export const ContainerCart = styled.div<ContainerProps>`
  color: ${({ theme }) => theme.colors.textColor};
  background-color: ${({ theme }) => theme?.colors?.white ?? '#FFFFFF'};
  border-radius: 4px;
  width: 301px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(39, 50, 64, 0.3);
  -moz-box-shadow: 0px 0px 15px 0px rgba(39, 50, 64, 0.3);
  box-shadow: 0px 0px 15px 0px rgba(39, 50, 64, 0.3);
  position: absolute;
  top: 53px;
  right: 0;
  z-index: 1;

  p {
    color: ${({ theme }) => theme.colors.text};
  }

  &::before {
    background-color: ${({ theme, beforeBackground }) =>
      beforeBackground ? beforeBackground : theme.colors?.white ?? '#FFFFFF'};
    border-radius: 4px;
    content: '';
    height: 22px;
    width: 22px;
    position: absolute;
    right: 9px;
    transform: rotate(45deg);
    top: -5px;
    z-index: -1;
  }
`

export const BaseButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`

export const StylePositionHotelCart = styled.div`
  width: 300px;
`

export const EmptyCart = styled.div`
  height: 185px;
  width: 100%;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3,
  p {
    margin: 0;
  }

  h3 {
    margin-top: 20px;
    font-size: 13px;
    font-style: normal;
    font-weight: 800;
  }

  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
`
export const Tooltip = styled.div`
  align-items: flex-start;
  background: ${({ theme }) => theme?.colors?.success};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  padding: 20px;
  width: auto;

  p {
    color: #fff;
    display: flex;
    font-family: 'Manrope';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    gap: 15px;
    line-height: normal;
    margin: 0;
  }
`
