import styled from 'styled-components'

export const ItemStyle = styled.div`
  color: ${({ theme }) => theme?.colors?.textColor};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`
export const HotelCartTitle = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 16.5px;
  margin: 0;
`
type ContentItemlStyleProps = {
  isHotelCart?: boolean
}

export const ContentItemlStyle = styled.div<ContentItemlStyleProps>`
  align-items: flex-start;
  justify-content: space-between;
  display: flex;

  img {
    &:hover {
      cursor: pointer;
    }
  }
`

export const DescStyle = styled.div`
  * {
    margin: 0;
  }

  p {
    font-size: 12px;
    text-align: left;
  }

  small {
    color: ${({ theme }) => theme?.colors?.gray6};
    font-size: 10px;
    text-align: left;
  }
`

export const ValStyle = styled.div`
  display: flex;
  justify-content: space-between;

  & div {
    align-items: baseline;
    display: flex;
    flex-flow: row nowrap;
  }

  span#nights {
    color: ${({ theme }) => theme?.colors?.gray6};
    font-size: 10px;
    font-weight: normal;
  }
`

export const CartFooter = styled.div`
  align-items: center;
  display: flex;
`
export const Badges = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  gap: 5px;
  margin-top: 5px;
`

type BadgeTitleProps = {
  badgeHighlighted?: boolean
}
export const BadgeTitle = styled.div<BadgeTitleProps>`
  align-items: center;
  background-color: ${({ theme, badgeHighlighted }) => (badgeHighlighted ? theme.colors?.text : theme?.colors?.gray3)};
  border-radius: 4px;
  display: flex;
  height: 17px;
  justify-content: center;
  padding: 1px 5px 2px;
  width: fit-content;

  span {
    color: ${({ theme, badgeHighlighted }) => (badgeHighlighted ? theme.colors?.white : theme?.colors?.text)};
    height: 14px;
    font-family: 'Manrope';
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 14px;
  }
`

export const StyleQuantity = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const SelectStyle = styled.div`
  max-width: 93px;
`

export const PersonStyle = styled.div`
  align-items: center;
  display: flex;
  gap: 5px;
`

export const MealName = styled(PersonStyle)`
  align-items: flex-start;
`

export const Divider = styled.span`
  background-color: ${({ theme }) => theme.colors?.gray3};
  height: 10px;
  width: 1px;
`

export const Amenities = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`
