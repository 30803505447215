import React, { useEffect, useState } from 'react';
import { Count, RadioField } from '../..';
import { PreSearch } from '../FlightCriteria/FlightCriteria';
import * as S from './styles';
export type FlightClass = 'economy' | 'premium' | 'business' | 'first';
export type PassengersCount = {
  adults?: number;
  children?: number;
  babies?: number;
  flightClass?: FlightClass;
};
interface PassengersProps {
  flightPreSearchRule: PreSearch;
  flightPassengers: PassengersCount;
  companyRulesUrl?: string;
  setFlightPassengers?: (v: PassengersCount) => void;
}
type Category = {
  value: FlightClass;
  label: 'Econômica' | 'Econômica Premium' | 'Executiva' | 'Primeira Classe';
};
const FlightPassengers: React.FC<PassengersProps> = ({
  flightPassengers,
  flightPreSearchRule,
  companyRulesUrl,
  setFlightPassengers
}) => {
  const [adults, setAdults] = useState(flightPassengers?.adults ?? 1);
  const [children, setChildren] = useState(flightPassengers?.babies ?? 0);
  const [babies, setBabies] = useState(flightPassengers?.children ?? 0);
  const [flightClass, setFlightClass] = useState<FlightClass>(flightPassengers?.flightClass ?? 'economy');
  const {
    minAdults,
    maxAdults,
    childrenAllowed = true,
    minChildren,
    maxChildren,
    babiesAllowed = true,
    minBabies,
    maxBabies
  } = flightPreSearchRule ?? {};
  useEffect(() => {
    setFlightPassengers({
      adults,
      children,
      babies,
      flightClass
    });
  }, [adults, babies, children, flightClass, setFlightPassengers]);
  const qtdAdults = {
    min: minAdults ?? 1,
    max: maxAdults ?? 10
  };
  const qtdChildren = {
    min: childrenAllowed ? minChildren ?? 0 : 0,
    max: childrenAllowed ? maxChildren ?? 10 : 0
  };
  const qtdBabies = {
    min: babiesAllowed ? minBabies ?? 0 : 0,
    max: babiesAllowed ? maxBabies ?? 10 : 0
  };

  // const FLIGHT_CATEGORIES: Category[] = [
  //   {
  //     value: 'economy',
  //     label: 'Econômica',
  //   },
  //   {
  //     value: 'premium',
  //     label: 'Econômica Premium',
  //   },
  //   {
  //     value: 'business',
  //     label: 'Executiva',
  //   },
  //   {
  //     value: 'first',
  //     label: 'Primeira Classe',
  //   },
  // ]

  return <S.Amount>
      <S.Title>Passageiros</S.Title>
      <S.PassengerCount>
        <S.PassengerTitle>
          <S.TitleInputs>Adultos</S.TitleInputs>
          <S.SubtitleInputs>A partir de 18 anos</S.SubtitleInputs>
        </S.PassengerTitle>

        <Count name="adults" value={adults} min={qtdAdults.min} max={qtdAdults.max} onChange={setAdults} />
      </S.PassengerCount>
      <S.PassengerCount>
        <S.PassengerTitle>
          <S.TitleInputs>Menores</S.TitleInputs>
          <S.SubtitleInputs>De 2 a 17 anos</S.SubtitleInputs>
        </S.PassengerTitle>

        <Count name="children" value={children} min={qtdChildren.min} max={qtdChildren.max} onChange={setChildren} />
      </S.PassengerCount>
      <S.PassengerCount>
        <S.PassengerTitle>
          <S.TitleInputs>Bebês</S.TitleInputs>
          <S.SubtitleInputs>Até 2 anos</S.SubtitleInputs>
        </S.PassengerTitle>

        <Count name="babies" value={babies} min={qtdBabies.min} max={qtdBabies.max} onChange={setBabies} />
      </S.PassengerCount>
      {/* <S.CompanyRules href={companyRulesUrl}>Regras da compannhia referente a idade</S.CompanyRules>
       <S.Title>Classe</S.Title>
       <S.ClassChoices>
        {FLIGHT_CATEGORIES.map((category) => (
          <RadioField
            key={category.value}
            value={flightClass}
            group="choose-category-option"
            label={category.label}
            gray={false}
            fontWeight={400}
            optionValue={category.value}
            setValue={() => setFlightClass(category.value)}
          />
        ))}
       </S.ClassChoices> */}
    </S.Amount>;
};
export default FlightPassengers;