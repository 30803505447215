import ptBR from 'date-fns/locale/pt-BR';
import enUS from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import defaultLocale from '@niaratech/niara-react-components/src/util/defaultLocale';
import type { Locale } from 'date-fns';
const LOCALES = {
  'pt-BR': ptBR,
  en: enUS,
  'en-US': enUS,
  es: es
};
const getLocaleDateFns = (): Locale => {
  const locale = defaultLocale();
  return locale && (LOCALES[locale] || LOCALES[locale.substring(0, 2)]) || ptBR;
};
export { getLocaleDateFns };