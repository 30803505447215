import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Regra de autenticação',
  labelPlural: 'Regras de autenticação',
  name: 'AuthenticationRule',
  namePlural: 'AuthenticationRules',
  authorization: authorization('consultant', 'admin'),
  custom_withAudit: true,
  deletable: false,
  dynamoDB: {
    tableName: 'SpearAuthenticationRule'
  },
  columns: ['id', 'name', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Nome da Regra',
    required: true
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: false,
    type: 'boolean'
  }, {
    custom_groupName: 'B2C',
    name: 'identityProviders',
    label: 'Provedores de login B2C',
    default: [],
    type: 'textarray',
    inputType: 'inputIdentityProviderArray',
    withChips: true,
    custom_daoFilter: {
      usage_in: ['B2C'],
      enabled_eq: true
    },
    custom_auditUrl: () => ({
      url: '/identityProviders',
      label: 'Provedor'
    })
  }, {
    custom_groupName: 'B2C',
    label: 'Login obrigatório OTA Builder',
    name: 'authenticationRestriction',
    type: 'text',
    withNullSelector: true,
    options: [{
      label: 'Login obrigatório para uso do sistema',
      value: 'ALL'
    }, {
      label: 'Login obrigatório para checkout',
      value: 'CHECKOUT'
    }],
    required: false
  }, {
    custom_groupName: 'Front',
    name: 'identityProvidersFront',
    label: 'Provedores de login Front',
    default: [],
    type: 'textarray',
    inputType: 'inputIdentityProviderArray',
    withChips: true,
    custom_daoFilter: {
      usage_in: ['FRONT', 'FRONT_CLIENT'],
      enabled_eq: true
    },
    custom_auditUrl: () => ({
      url: '/identityProviders',
      label: 'Provedor'
    })
  }, {
    custom_groupName: 'MFA',
    name: 'mfaCheckout',
    label: 'Exigir MFA no checkout',
    default: false,
    render: false,
    fetchByDefault: false,
    hint: 'Feature WIP',
    type: 'boolean'
  }, {
    custom_groupName: 'MFA',
    name: 'mfaExpirationTimeInMinutes',
    label: 'Tempo para expiração do MFA em minutos',
    render: false,
    fetchByDefault: false,
    hint: 'Feature WIP',
    type: 'int'
  }],
  filter: []
});