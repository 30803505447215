import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import type { CurrencyExchangeRule } from '..';
import { formatNumber, printPrice } from '@niaratech/niara-js-commons';
export const currencies = [{
  value: 'BRL',
  label: 'BRL',
  country: 'BR'
}, {
  value: 'USD',
  label: 'USD',
  country: 'US'
}, {
  value: 'EUR',
  label: 'EUR',
  country: 'EU'
}, {
  value: 'ARS',
  label: 'ARS',
  country: 'AR'
}, {
  value: 'CLP',
  label: 'CLP',
  country: 'CL'
}, {
  value: 'COP',
  label: 'COP',
  country: 'CO'
}, {
  value: 'MXN',
  label: 'MXN',
  country: 'MX'
}, {
  value: 'GBP',
  label: 'GBP',
  country: 'GB'
}, {
  value: 'AED',
  label: 'AED',
  country: 'AE'
}];
export const numberFormat = number => {
  const newNumberFormat = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 5
  }).format(number);
  return newNumberFormat;
};
export default fillCrudMetadataGaps<CurrencyExchangeRule>({
  label: 'Câmbio',
  labelPlural: 'Câmbio',
  name: 'CurrencyExchangeRule',
  namePlural: 'CurrencyExchangeRules',
  authorization: authorization('supervisor', 'supervisor'),
  deletable: false,
  custom_withAudit: true,
  dynamoDB: {
    tableName: 'SpearCurrencyExchangeRule'
  },
  columns: ['id', 'name', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false,
    render: false
  }, {
    name: 'name',
    label: 'Nome',
    type: 'text',
    required: true
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: false,
    required: true,
    type: 'boolean'
  }, {
    name: 'exchangeRates',
    label: 'Definição dos câmbios',
    required: true,
    type: 'objectarray',
    graphQLType: '[NestedExchangeRates]',
    graphQLInputType: '[NestedExchangeRatesInput]',
    default: [],
    fields: [{
      name: 'currencyFrom',
      label: 'Moeda original',
      type: 'text',
      inputType: 'select',
      options: currencies,
      required: true
    }, {
      name: 'currencyTo',
      label: 'Moeda da cotação',
      type: 'text',
      inputType: 'select',
      options: currencies,
      required: true
    }, {
      name: 'exchangeRate',
      label: 'Taxa de câmbio',
      type: 'float',
      required: true
    }],
    render(entity, ignored) {
      return entity?.exchangeRates?.map(exchangeRate => {
        const formattedExchangeRate = `${exchangeRate?.currencyFrom} ${numberFormat(1)}\xa0=\xa0${exchangeRate?.currencyTo}\xa0${numberFormat(exchangeRate?.exchangeRate)}`;
        return formattedExchangeRate;
      }).join('\n');
    }
  }, {
    name: 'updatedAt',
    type: 'datetime',
    label: 'Última atualização',
    updatable: false,
    insertable: false
  }, {
    name: 'updatedByName',
    type: 'text',
    label: 'Atualizado por',
    updatable: false,
    insertable: false
  }],
  filter: [{
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq'
  }]
});