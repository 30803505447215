import { Button, Portal, Stack } from '@mui/material';
import type { HotelCalendarPayload } from '@niarab2c/frontend-commons/src/types/hotels';
import type { Client, HotelSearchRule, Person } from '@niarab2c/niara-spear-crudmodel';
import { diffInDays } from '@niaratech/niara-js-commons/dist/dateUtils';
import { Modal2 } from '@niaratech/niara-react-components';
import { useEffectOnChange } from '@niaratech/niara-react-components/src';
import { Form, FormInput, InputToggle } from '@niaratech/niara-react-form';
import { parseISO } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { MdCircle } from 'react-icons/md';
import { formatPoints } from '../../util/common';
import { ContentSearchOption, CounterPeople, useHotelSearchContext } from '../..';
import { IcLocalization, IcPerson } from '../../Icons';
import { ShoppingCartDropdown, ShoppingCartDropdownProps } from '../../components';
import PersonSearch from '../../components/personSearch/PersonSearch';
import * as Images from '../../images';
import { DatePickerV2, InputAutoComplete, PromoCode, ToolTip } from '../../microComponents';
import { HotelIcon } from '../../microComponents/ContentSearchOption/styles';
import { GroupingOptions } from '../../microComponents/InputAutoComplete/InputAutoComplete';
import useDefaultPortalContext from '../../portal/useDefaultPortalContext';
import { BREAKPOINT_MD } from '../../theme/ota';
import ImportPerson from '../ImportPerson/ImportPerson';
import CriteriaThemeProvider from './CriteriaThemeProvider';
import OccupancyInput from './OccupancyInput';
import useDetailsToCalendar from './useDetailsToCalendar';
import * as S from './styles';
const groupingDestinations: GroupingOptions[] = [{
  label: 'Cidades',
  type: 'city',
  icon: <S.ImageGroup src={Images.city} alt="city" />
}, {
  label: 'Hotéis',
  type: 'hotel',
  icon: <HotelIcon />
}, {
  label: 'País',
  type: 'country',
  icon: <S.ImageGroup src={Images.location} alt="country" />
}, {
  label: 'Bairros',
  type: 'neighborhood',
  icon: <S.ImageGroup src={Images.neighborhood} alt="neighborhood" />
}, {
  label: 'Estados',
  type: 'state',
  icon: <S.ImageGroup src={Images.location} alt="state" />
}, {
  label: 'Várias cidades',
  type: 'multi_city_vicinity',
  icon: <S.ImageGroup src={Images.multiCityVicinity} alt="multi_city_vicinity" />
}, {
  label: 'Pontos de interesse',
  type: 'point_of_interest',
  icon: <S.ImageGroup src={Images.pointOfInterest} alt="point_of_interest" />
}, {
  label: 'Aeroportos',
  type: 'airport',
  icon: <S.ImageGroup src={Images.airportIcon} alt="airport" />
}, {
  label: 'Regiões de alto nível',
  type: 'high_level_region',
  icon: <S.ImageGroup src={Images.location} alt="high_level_region" />
}, {
  label: 'Províncias',
  type: 'province_state',
  icon: <S.ImageGroup src={Images.location} alt="province_state" />
}, {
  label: 'Continentes',
  type: 'continent',
  icon: <S.ImageGroup src={Images.continent} alt="continent" />
}, {
  label: 'Estações de trem',
  type: 'train_station',
  icon: <S.ImageGroup src={Images.trainStation} alt="train_station" />
}, {
  label: 'Estações de metrô',
  type: 'metro_station',
  icon: <S.ImageGroup src={Images.trainStation} alt="metro_station" />
}];
type Destination = {
  label: string | Array<string>;
  value: DestinationValue;
  id: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  name?: string;
  type?: string;
};
type ClientOption = Pick<Client, 'id' | 'brand_name' | 'promoCodeEnabled'>;
export const contractStatus = {
  ACTIVE: "Ativo",
  INACTIVE: "Inativo",
  BLOCKED: "Não liberado para uso"
};
export type LoyaltyOption = {
  TSER_idContrato?: number;
  TSER_numeroContrato?: string;
  balance?: number;
  unitText?: string;
  contractStatus?: keyof typeof contractStatus;
  allowDecimals?: boolean;
  highlightText?: string;
  informativeText?: string;
  programAlias?: string;
  programType?: string;
  showTierOnNavbar?: boolean;
  smallLogo?: string;
  tier?: string;
  tierExpirationDateTime?: string;
  tierUpdateDateTime?: string;
};
type DestinationValue = {
  propertyId?: string;
  contentType?: 'property' | 'region';
  cityIds?: Array<string>;
  hotelIds?: Array<string>;
};
type DestinationSearch = (q: string) => Promise<Array<Destination>>;
type ClientSearch = (q: string) => Array<ClientOption>;
type PersonSearch = (q: string) => Promise<Array<Person>>;
export type RoomInCriteriaForm = {
  adults: number;
  children?: number;
  childrenAges?: number[];
};
export type CriteriaForm = {
  startDate: string;
  endDate: string;
  rooms: RoomInCriteriaForm[];
  destinations?: DestinationValue;
  destinationName?: string | Array<string>;
  bestOnly?: boolean;
  promoCode?: string;
  credentialIds?: string[];
  availRatesOnly?: boolean;
  distributionIds?: string[];
  roomRateIds?: string[];
  enablePromoCode?: boolean;
  clientId?: string;
  clientName?: string;
  personId?: string;
  personName?: string;
  personBalance?: number;
  personTSER_idContrato?: number;
};
export type RestrictionsDetailCalendar = {
  childrenAllowed?: boolean;
  closedOnArrival?: boolean;
  closedOnDeparture?: boolean;
  maxAdultCount?: number;
  maxChildAge?: number;
  maxChildCount?: number;
  maxNightCount?: number;
  minAdultCount?: number;
  minChildAge?: number;
  minChildCount?: number;
  minNightCount?: number;
  minTimeBetweenBookingAndCheckin?: number;
  requiredNightCount?: boolean;
};
export type DetailCalendarDatePickerItems = {
  available?: boolean;
  currency?: string;
  date?: string;
  ratePlan?: string;
  restrictions?: RestrictionsDetailCalendar;
  units?: number;
  value?: number;
};
export interface HotelCriteriaProps {
  personSearchEnabled?: boolean;
  enablePromoCode?: boolean;
  enableClientList?: boolean;
  destinationSearch: DestinationSearch;
  clientSearch?: ClientSearch;
  minStartDate?: string | undefined;
  maxStartDate?: string | undefined;
  onHotelSearch?: (c: CriteriaForm) => any;
  autoFocus?: boolean;
  modifyDates?: boolean;
  headerSize?: string;
  searchEngine_orientation?: 'VERTICAL' | 'HORIZONTAL';
  hotelSearchRule?: HotelSearchRule;
  isSearchPage?: boolean;
  detailCalendarStatusToDatePicker?: (c: HotelCalendarPayload['criteria']) => Promise<DetailCalendarDatePickerItems[]>;
  personSearch?: PersonSearch;
  importPerson?: (p: Partial<Person>) => Promise<Person>;
  shoppingCartDropdownProps?: ShoppingCartDropdownProps;
  setValue: React.Dispatch<React.SetStateAction<FormValueType>>;
  value: FormValueType | undefined | null;
  loyaltyOptions?: LoyaltyOption[];
  isLoadingLoyaltyOptions?: boolean;
}

/**
 * Tipo do formValue/setFormValue
 * TODO: evoluir para usar FormInput em todos os campos e
 * validação com @niaratech/niara-react-form
 */
export type FormValueType = {
  destination: Destination;
  person: Partial<Person>;
  client: ClientOption;
  promoCode: string;
  rooms: RoomInCriteriaForm[];
  startDate: string;
  endDate: string;
  loyaltyOption?: LoyaltyOption;
};
const EMPTY_OBJECT = ({} as const);
const HotelCriteria: React.FC<HotelCriteriaProps> = ({
  enablePromoCode: propsEnablePromoCode,
  destinationSearch,
  minStartDate,
  maxStartDate,
  onHotelSearch,
  autoFocus,
  modifyDates,
  headerSize,
  searchEngine_orientation,
  hotelSearchRule,
  isSearchPage,
  detailCalendarStatusToDatePicker,
  enableClientList = false,
  clientSearch,
  personSearchEnabled,
  personSearch,
  importPerson,
  shoppingCartDropdownProps,
  value: propsValue,
  setValue: setFormValue,
  loyaltyOptions,
  isLoadingLoyaltyOptions
}) => {
  const formValue = propsValue ?? (EMPTY_OBJECT as FormValueType);
  const history = useHistory();
  const {
    checkoutPath,
    b2c
  } = useHotelSearchContext();
  const onSearch = useCallback((criteria: CriteriaForm) => {
    onHotelSearch(criteria);
  }, [onHotelSearch]);
  const {
    minAdultCount,
    maxAdultCount,
    childrenAllowed = true,
    minChildCount,
    minChildAge,
    maxChildAge,
    minRoomCount,
    maxRoomCount,
    notDisplayLocationInSearch
  } = hotelSearchRule || {};
  const maxChildCount = hotelSearchRule?.maxChildCount ?? 3;
  const [openImportPerson, setOpenImportPerson] = useState(false);
  const {
    destination,
    client,
    rooms: valueRooms,
    startDate,
    endDate
  } = formValue;

  /**
   * Renderiza uma opção do autocomplete de pessoas
   * @param person
   * @param q
   * @returns
   */
  const PersonRenderOption = (person: Person, q?: string) => {
    let showResult = '';
    let actionQuery = q;
    const cleanedQ = q?.replace(/\./g, '')?.replace(/-/g, ''); // Remove todos os pontos e hífens do que foi digitado no campo

    //#179513
    //Valida se existem letras na string digitada no campo.
    //Caso tenha, a busca segue com o que foi digitado pois nao se trata de um CPF.
    //Caso nao tenha letras e tenha apenas numeros, assumi que é o usuario digitou um CPF ou parte dele e ignoro pontos e hífens digitados pelo usuario.
    if (/^\d+$/.test(cleanedQ)) {
      actionQuery = cleanedQ;
    }
    Object.keys(person).forEach(item => {
      const propertyValue = (person[item] as string);

      // Remove pontos e hífens apenas se a propriedade atual parecer ser um CPF #179513
      let cleanedPropertyValue = propertyValue;
      if (/^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(propertyValue)) {
        cleanedPropertyValue = propertyValue.replace(/\./g, '').replace(/-/g, '');
      }
      if (cleanedPropertyValue?.toUpperCase?.()?.includes(actionQuery?.toUpperCase?.()) && !['email', 'firstName', 'lastName', 'clientId', 'id']?.includes(item) && actionQuery != '') {
        let nameKey = '';
        switch (item) {
          case 'cpf':
            nameKey = "CPF";
            break;
          case 'phone':
            nameKey = "Telefone";
            break;
          case 'passport':
            nameKey = "Passaporte";
        }
        showResult = `${nameKey != '' ? `${nameKey}: ${propertyValue}` : `${propertyValue}`}`;
      }
    });
    return <S.RenderOption>
        <S.Text>{[person?.firstName, person?.lastName].filter(Boolean).join(' ')}</S.Text>
        <S.TextQuery>{person?.email}</S.TextQuery>
        <S.TextQuery>{showResult}</S.TextQuery>
      </S.RenderOption>;
  };
  const loyaltyOptionRenderOption = (option: LoyaltyOption) => {
    return <S.RenderOptionItem>
        <S.RenderOption>
          <S.Text>{option?.TSER_numeroContrato}</S.Text>
          <S.TextQuery>
            {formatPoints(option?.balance)} {option?.unitText}
          </S.TextQuery>
        </S.RenderOption>
        <S.RenderOption className="align-right">
          <MdCircle color={option?.contractStatus == 'ACTIVE' ? '#53AC2A' : '#FF0000'} />
          <S.TextQuery>{contractStatus[option?.contractStatus]}</S.TextQuery>
        </S.RenderOption>
      </S.RenderOptionItem>;
  };
  const NoResultsMessage = () => {
    return <S.RenderNoOption>
        <S.Text>Nenhum resultado encontrado</S.Text>
        <Button variant="text" color="info" size="small" onClick={() => setOpenImportPerson(true)}>
          Importar uma pessoa
        </Button>
      </S.RenderNoOption>;
  };
  const setPerson = useCallback((person: Partial<Person>) => {
    setFormValue?.(prev => ({
      ...prev,
      person
    }));
  }, [setFormValue]);
  const [size, setSize] = useState<number>();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [breakpoint, setBreakpoint] = useState<boolean>();
  const missingChildrenAges = useMemo(() => {
    if (formValue?.rooms?.length > 0) {
      for (const room of formValue?.rooms) {
        for (let i = 0; i < room?.children ?? 0; i++) {
          if (room?.childrenAges[i] == null) return true;
        }
      }
    }
    return false;
  }, [formValue]);
  const personDisabled = !personSearchEnabled || client == null ? true : false;
  const [queryPerson, setQueryPerson] = useState('');
  useEffect(() => {
    // ao trocar de cliente, zerar o campo de pessoa
    setFormValue?.(formValue => {
      const person = formValue?.person;
      if (!person) return formValue;
      if (!client) return {
        ...formValue,
        person: null
      };
      if (client.id != person.clientId) return {
        ...formValue,
        person: null
      };
      return formValue;
    });
  }, [client, setFormValue]);
  useEffect(() => {
    if (personDisabled) {
      setFormValue?.(formValue => {
        if (formValue?.person) return {
          ...formValue,
          person: null
        };
        return formValue;
      });
    }
  }, [personDisabled, setFormValue]);
  useEffect(() => {
    //popula os demais campos do contrato a partir do idContrato. É o caso em que já tinha salvo em outra busca
    const contractId = formValue?.loyaltyOption?.TSER_idContrato;
    const contractNumber = formValue?.loyaltyOption?.TSER_numeroContrato;
    if (contractId && !contractNumber && loyaltyOptions?.length > 0) {
      const loyaltyOption = loyaltyOptions?.find(o => o?.TSER_idContrato == contractId);
      setFormValue?.(formValue => {
        return {
          ...formValue,
          loyaltyOption
        };
      });
    }
  }, [formValue?.loyaltyOption?.TSER_idContrato, formValue?.loyaltyOption?.TSER_numeroContrato, loyaltyOptions, setFormValue]);
  useEffect(() => {
    // limpa o contrato quando teve alteração de pessoa
    const contractId = formValue?.loyaltyOption?.TSER_idContrato;
    if (contractId && !isLoadingLoyaltyOptions && loyaltyOptions?.length > 0) {
      const loyaltyOption = loyaltyOptions?.find(o => o?.TSER_idContrato == contractId);
      if (!loyaltyOption) setFormValue?.(formValue => ({
        ...formValue,
        loyaltyOption: null
      }));
    }
  }, [formValue?.loyaltyOption?.TSER_idContrato, isLoadingLoyaltyOptions, loyaltyOptions, setFormValue]);
  const enablePromoCode = propsEnablePromoCode != undefined ? propsEnablePromoCode : client?.promoCodeEnabled;

  // state que controla o estado do toggle
  const [enableTogglePerson, setEnableTogglePerson] = useState(() => {
    if (formValue?.person?.id) return true;
    return false;
  });
  const onTogglePerson = () => {
    setEnableTogglePerson(prev => {
      if (prev === true) {
        // ao desmarcar busca por hóspede cadastrado, limpa a pessoa que estava selecionada
        setFormValue?.(formValue => ({
          ...formValue,
          person: null
        }));
      }
      return !prev;
    });
  };
  const minDate = React.useMemo(() => {
    if (minStartDate) return parseISO(minStartDate);
    return new Date();
  }, [minStartDate]);
  const maxDate = React.useMemo(() => {
    if (maxStartDate) return parseISO(maxStartDate);
    return undefined;
  }, [maxStartDate]);
  useEffectOnChange(() => {
    // mostra tooltip de alerta de mudança de critérios
    if (isSearchPage) {
      setShowTooltip(true);
    }
  }, [formValue, isSearchPage]);
  const nights = useMemo(() => diffInDays(endDate, startDate) || 0, [endDate, startDate]);

  /**
   * flag para mostrar os erros após tentativa de submit
   */
  const [showCustomErrors, setShowCustomErrors] = useState(false);
  const onSubmit = useCallback((values: FormValueType) => {
    const person = values.person;
    setShowCustomErrors(true);
    if (!missingChildrenAges) {
      setShowTooltip(false);
      onSearch({
        endDate: values?.endDate,
        startDate: values?.startDate,
        rooms: values?.rooms,
        destinations: values?.destination?.value,
        destinationName: values?.destination?.label,
        promoCode: values?.promoCode,
        clientName: values?.client?.brand_name,
        clientId: values?.client?.id,
        enablePromoCode,
        personId: person?.id,
        personName: [person?.firstName, person?.lastName].filter(Boolean).join(' '),
        personTSER_idContrato: values?.loyaltyOption?.TSER_idContrato
      });
    }
  }, [missingChildrenAges, onSearch, enablePromoCode]);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setSize(window.innerWidth);
    }
    const onResize = () => {
      setSize(window.innerWidth);
    };
    window.addEventListener('resize', onResize);
  }, []);
  useEffect(() => {
    setBreakpoint(size < +BREAKPOINT_MD.replace('px', ''));
  }, [size]);
  const guestsQtd = valueRooms?.reduce((acc, item) => acc + item.adults + (item?.children ?? 0), 0);
  const calendarPositionDesktop = searchEngine_orientation === 'VERTICAL' ? 0 : !enablePromoCode ? 0 : -200;
  const currentHotelIdRef = useRef(formValue?.destination?.value?.hotelIds?.[0]);
  currentHotelIdRef.current = formValue?.destination?.value?.hotelIds?.[0];
  const [detailsToCalendar, onShownDateChange, loadingCalendar] = useDetailsToCalendar({
    clientId: formValue?.client?.id,
    maxDate,
    minDate,
    adults: formValue?.rooms?.[0]?.adults,
    children: formValue?.rooms?.[0]?.children,
    childrenAges: formValue?.rooms?.[0]?.childrenAges,
    propertyId: formValue?.destination?.value?.propertyId,
    contentType: formValue?.destination?.value?.contentType,
    promoCode: formValue?.promoCode,
    hotelId: formValue?.destination?.value?.hotelIds?.length == 1 ? formValue?.destination?.value?.hotelIds[0] : undefined // não deveria ser obrigatório, mas calendar está exigindo
  }, detailCalendarStatusToDatePicker);
  const onCheckout = useCallback(() => {
    history.push(checkoutPath);
  }, [history, checkoutPath]);
  const verticalOrientation = searchEngine_orientation === 'VERTICAL';
  const portalContainer = useDefaultPortalContext()?.container;
  const refComponentForCalendar = useRef(null);
  return <CriteriaThemeProvider>
      <S.ContainerHotelCriteria ref={refComponentForCalendar}>
        <Form value={formValue} setValue={setFormValue} onSubmit={onSubmit}>
          <S.ContainerGrid verticalOrientation={verticalOrientation}>
            <S.Grid className="destination-field">
              <S.TitleInputs>Destino ou Hotel</S.TitleInputs>
              <FormInput component={InputAutoComplete} fieldPath="destination" className="destination-autocomplete" placeholder="Para onde você vai?" errors={showCustomErrors && destination?.value == null ? ["Por favor, escolha o destino"] : undefined} data-testid="hotelcriteria-destination" Icon={IcLocalization} singleOptionAutoSelect={true} debounce={600} listSize={20} required={true} suggestionsSource={destinationSearch} renderOption={(option: Destination) => {
              return <ContentSearchOption {...option} notDisplayLocationInSearch={notDisplayLocationInSearch} />;
            }} autoFocus={autoFocus} suggestionToString={(destination: Destination) => typeof destination?.label == 'string' ? destination?.label : 'join' in destination?.label ? destination?.label.join(', ') : '?'} headerSize={headerSize} groupingOptions={groupingDestinations} groupingFilter="type" />
            </S.Grid>
            <S.Grid className="date-field">
              <S.TitleInputs>
                Check-in / Check-out {nights > 1 ? <span>{nights} Noites</span> : <span>{nights} Noite</span>}
              </S.TitleInputs>
              <FormInput component={DatePickerV2} fieldMapping={{
              startDate: 'startDate',
              endDate: 'endDate'
            }} calencarPosition={calendarPositionDesktop} minDate={minDate} maxDate={maxDate} initOpened={modifyDates} headerSize={headerSize} centerMode={!enablePromoCode} detailsToCalendar={detailsToCalendar} searchEngine_orientation={searchEngine_orientation} onShownDateChange={onShownDateChange} onShown={() => {
              if (onShownDateChange) {
                onShownDateChange(startDate);
              }
            }} loading={loadingCalendar} personSearchEnabled={personSearchEnabled} data-testid="hotelcriteria-checkin-checkout" referenceComponent={refComponentForCalendar} />
            </S.Grid>

            <S.Grid className="occupancy-field">
              {breakpoint ? <FormInput component={OccupancyInput} fieldPath="rooms" childrenAllowed={childrenAllowed} maxAdultCount={maxAdultCount} maxChildAge={maxChildAge} maxChildCount={maxChildCount} minAdultCount={minAdultCount} minChildAge={minChildAge} minChildCount={minChildCount} minRoomCount={minRoomCount} maxRoomCount={maxRoomCount} dataTestId="hotelcriteria-occupancy" /> : <>
                  <S.TitleInputs>Hóspedes</S.TitleInputs>

                  <CounterPeople qtdeRooms={valueRooms?.length ?? 0} guests={guestsQtd ?? 0} errors={showCustomErrors && missingChildrenAges ? ["Falta selecionar a idade das crianças"] : null} data-testid="hotelcriteria-occupancy-button">
                    <FormInput component={OccupancyInput} fieldPath="rooms" childrenAllowed={childrenAllowed} maxAdultCount={maxAdultCount} maxChildAge={maxChildAge} maxChildCount={maxChildCount} minAdultCount={minAdultCount} minChildAge={minChildAge} minChildCount={minChildCount} minRoomCount={minRoomCount} maxRoomCount={maxRoomCount} dataTestId="hotelcriteria-occupancy-paper" />
                  </CounterPeople>
                </>}
            </S.Grid>
            {enableClientList && <S.Grid className="client-field">
                <S.HeaderClient>
                  <S.TitleInputs>Cliente</S.TitleInputs>
                </S.HeaderClient>
                <FormInput component={InputAutoComplete} fieldPath="client" placeholder="Selecionar cliente" debounce={600} listSize={6} suggestionsSource={clientSearch} headerSize={headerSize} required errors={showCustomErrors && client === undefined ? ["Por favor, escolha o cliente"] : undefined} suggestionToString={(client: ClientOption) => client?.brand_name} data-testid="hotelcriteria-client" />
              </S.Grid>}
            {enablePromoCode && <S.Grid className="promocode-field">
                <S.TitleInputs>Tenho um código</S.TitleInputs>
                <FormInput component={PromoCode} fieldPath="promoCode" dataTestId="hotelcriteria-promocode" name="promoCode" placeholder="Meu código..." />
              </S.Grid>}
            <S.ButtonStyle verticalOrientation={verticalOrientation}>
              <Button data-testid="hotelcriteria-action-search" color="primary" disabled={nights === 0} type="submit" fullWidth size="medium" sx={{
              fontWeight: 'bold'
            }} variant="contained">
                {breakpoint ? 'Pesquisar' : 'Buscar'}
              </Button>

              {showTooltip && <S.TooltipContainer>
                  <S.TooltipContent>Alterou seus dados de estadia. Busque novamente!!</S.TooltipContent>
                </S.TooltipContainer>}
            </S.ButtonStyle>

            {!b2c && <Stack mt="24px" gap="15px" direction="row" alignItems="center">
                <ShoppingCartDropdown handleClickCheckout={onCheckout} {...shoppingCartDropdownProps} tooltipMode />
              </Stack>}
          </S.ContainerGrid>

          {personSearchEnabled && personSearch && <S.ContainerGrid verticalOrientation={verticalOrientation}>
              <S.Grid className="toggle-person-field">
                <S.InputToggle data-testid="hotelcriteria-toggle-search-people">
                  <InputToggle value={enableTogglePerson} setValue={onTogglePerson} disabled={personDisabled} id="toggle-search-people" />
                  <label htmlFor="toggle-search-people">Buscar hóspede cadastrado</label>
                </S.InputToggle>
              </S.Grid>

              {enableTogglePerson && <S.Grid className="person-field">
                  <FormInput key={client?.id} component={InputAutoComplete} fieldPath="person" placeholder="Digite o email ou CPF do hóspede." Icon={IcPerson} required singleOptionAutoSelect={false} onTextChanged={setQueryPerson} debounce={600} listSize={20} suggestionsSource={personSearch} suggestionToString={(person: Person) => person ? [person?.firstName, person?.lastName]?.filter(Boolean)?.join(' ') : null} noResultsMessage={importPerson ? <NoResultsMessage /> : undefined} renderOption={PersonRenderOption} headerSize={headerSize} />
                </S.Grid>}

              {enableTogglePerson && (loyaltyOptions?.length > 0 || formValue?.loyaltyOption?.TSER_idContrato) && <S.Grid className="loyalty-option-field">
                  <FormInput key={client?.id} component={InputAutoComplete} fieldPath="loyaltyOption" placeholder={isLoadingLoyaltyOptions ? 'Carregando os contratos...' : 'Selecione o contrato'} singleOptionAutoSelect={true} suggestionsSource={(q: string) => loyaltyOptions?.filter(o => !q || o?.TSER_numeroContrato?.toLowerCase()?.indexOf(q?.toLowerCase()) > 0)} suggestionToString={(loyaltyOption: LoyaltyOption) => loyaltyOption?.TSER_numeroContrato} renderOption={loyaltyOptionRenderOption} headerSize={headerSize} disabled={!formValue?.person?.id || isLoadingLoyaltyOptions} />
                </S.Grid>}
            </S.ContainerGrid>}
        </Form>
      </S.ContainerHotelCriteria>
      {importPerson && <Portal container={portalContainer}>
          <Modal2 show={openImportPerson} setShow={setOpenImportPerson} mobileVariant="full" render={({
        hide
      }) => <ImportPerson hide={hide} cpf={queryPerson} setPerson={setPerson} importPerson={importPerson} />} zIndex={2247483657} />
        </Portal>}
    </CriteriaThemeProvider>;
};
export default HotelCriteria;