import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import type { Plugin as ScStylisPlugin } from '@emotion/stylis';
import React from 'react';
import { StyleSheetManager } from 'styled-components';
import type { Middleware } from 'stylis';
import { prefixer, RULESET } from 'stylis';
const CONTAINER_CLASS = '.otabuilder-widget-container';
const CONTAINER_CLASS_REGEXP = new RegExp(`${CONTAINER_CLASS} `, 'g');
/**
 * emotion usa stylis v4
 * @param element
 * @param index
 * @param children
 * @param callback
 */
const customPlugin: Middleware = (element, index, children, callback) => {
  if (element.type == RULESET && typeof element.props == 'object') {
    // transforma o css gerado para o @mui/material, de forma a ficar bastante específico e
    // não ser afetado pelas regras de css existentes
    element.props = element.props.map(value => `${CONTAINER_CLASS} ${value}${value}${value}`);
  }
};

/**
 * styled-components v5 usa o @emotion/stylis (stylis v3.5.x)
 * @param context
 * @param content
 * @param selector
 * @param parent
 * @param line
 * @param column
 * @param length
 * @param at
 * @param depth
 */
/* Por algum motivo o type do StylisPlugin em @types/styled-components está errado, por isso dou cast como any */
const scCustomPlugin: ScStylisPlugin = (context, content, selector, parent, line, column, length, at, depth) => {
  if (context == 1) {
    // Vai quebrar o date picker (cor da data vai ficar mais importante que o fundo do botão selecionado)
    // if (typeof content == 'string' && content?.length > 1 && content?.indexOf('!important') < 0) {
    //   return `${content} !important`
    // }
  } else if (context == 2) {
    // cuidado - keyframes caem neste caso aqui
    if (parent.find(v => v.indexOf('@keyframes') == 0)) {
      // ignorar quando dentro de @keyframes
    } else {
      selector.forEach((v, idx) => {
        if (v && v?.indexOf(CONTAINER_CLASS) < 0) {
          selector[idx] = `${CONTAINER_CLASS} ${v}`;
        }
      });
    }
  } else if (context == 3) {
    // vou desfazer o que foi feito anteriormente se for @keyframes
    if (selector?.[0]?.startsWith('@keyframes')) {
      return content.replace(CONTAINER_CLASS_REGEXP, '');
    }
  }
};
const mycache = createCache({
  key: 'otabuilder-embedded-form-new',
  stylisPlugins: [customPlugin, prefixer]
});

/**
 * Registra plugins stylis que vão criar um namespace para as classes
 * do sistema
 * @param props
 * @returns
 */
const Provider: React.FC<React.PropsWithChildren> = props => <StyleSheetManager stylisPlugins={[(scCustomPlugin as any)]}>
    <CacheProvider {...props} value={mycache} />
  </StyleSheetManager>;
export default Provider;