import { diffInDays } from '@niaratech/niara-js-commons/dist/dateUtils';
import { parseISO } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form, FormInput } from '@niaratech/niara-react-form';
import type { CriteriaForm } from '@niarab2c/niarab2c-core/src/types/flight';
import { Button } from '@mui/material';
import { ContentSearchOption, InputAutoComplete, CounterPassengers, useTheme } from '../..';
import { IcArrow, IcLocalization } from '../../Icons';
import { PassengersCount } from '../FlightPassengers';
import { DatePickerV2 } from '../../microComponents';
import * as S from './styles';
import FlightCriteriaMode from './FlightCriteriaMode';
type Destination = {
  label: string;
  id: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  countryCode?: string;
  name?: string;
  type: string;
  coordinates?: {
    lon: number | string;
    lat: number | string;
  };
};
type DestinationSearch = (q: string) => Promise<Array<Destination>>;
export type PreSearch = {
  minAdults?: number;
  maxAdults?: number;
  childrenAllowed?: boolean;
  minChildren?: number;
  maxChildren?: number;
  babiesAllowed?: boolean;
  minBabies?: number;
  maxBabies?: number;
  minTravelDays?: number;
  maxTravelDays?: number;
  minStartDate?: string;
  maxStartDate?: string;
};
interface Props {
  initialCriteria?: Partial<CriteriaForm>;
  autoFocus?: boolean;
  modifyDates?: boolean;
  headerSize?: string;
  companyRulesUrl?: string;
  searchEngine_orientation?: 'VERTICAL' | 'HORIZONTAL';
  flightPreSearchRule?: PreSearch;
  currentCriteria?: CriteriaForm;
  destinationSearch: DestinationSearch;
  onFlightSearch?: (c: CriteriaForm) => any;
}
const FlightCriteria: React.FC<Props> = ({
  initialCriteria = {
    mode: 'ROUNDTRIP',
    slices: [{
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1).toISOString().slice(0, 10),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 2).toISOString().slice(0, 10)
    }],
    occupancy: {
      adults: 1,
      children: 0,
      infantsSeated: 0,
      infantsOnLap: 0
    }
  },
  autoFocus,
  modifyDates,
  headerSize,
  companyRulesUrl,
  searchEngine_orientation,
  flightPreSearchRule,
  currentCriteria,
  destinationSearch,
  onFlightSearch
}) => {
  const theme = useTheme();
  const onSubmit = useCallback((flightCriteria: CriteriaForm) => {
    return onFlightSearch(flightCriteria);
  }, [onFlightSearch]);
  const minDate = React.useMemo(() => {
    if (flightPreSearchRule?.minStartDate) return parseISO(flightPreSearchRule?.minStartDate);
    return new Date();
  }, [flightPreSearchRule?.minStartDate]);
  const maxDate = React.useMemo(() => {
    if (flightPreSearchRule?.maxStartDate) return parseISO(flightPreSearchRule?.maxStartDate);
    return undefined;
  }, [flightPreSearchRule?.maxStartDate]);
  const [formValue, setFormValue] = useState<Partial<CriteriaForm>>(() => ({
    ...(currentCriteria ?? initialCriteria)
  }));
  const mode = formValue?.mode ?? 'ROUNDTRIP';
  // TODO - mudar para multislice
  const {
    startDate,
    endDate
  } = formValue?.slices?.[0] ?? {};
  const {
    adults,
    children,
    infantsSeated
  } = formValue?.occupancy ?? {
    adults: 1,
    children: 0,
    infantsSeated: 0
  };
  useEffect(() => {
    if (mode === 'ONEWAY') {
      setFormValue(formValue => ({
        ...formValue,
        slices: formValue.slices?.slice(0, 1)?.map((slice, idx) => {
          return {
            ...slice,
            endDate: slice.startDate
          };
        })
      }));
    } else if (mode == 'ROUNDTRIP') {
      setFormValue(formValue => ({
        ...formValue,
        slices: formValue.slices?.slice(0, 1)
      }));
    } else {
      // NOT IMPLEMENTED
    }
  }, [mode]);
  const days = startDate && endDate ? diffInDays(endDate, startDate) : 0;
  const setPassengerChoices = (choices: PassengersCount) => {
    setFormValue({
      ...formValue,
      occupancy: {
        adults: choices?.adults ?? 1,
        children: choices?.children ?? 0,
        infantsOnLap: choices?.babies ?? 0
      }
    });
  };
  const refComponentForCalendar = useRef(null);
  return <S.ContainerCriteria searchEngine_orientation={searchEngine_orientation} ref={refComponentForCalendar}>
      <Form value={formValue} setValue={setFormValue} onSubmit={onSubmit}>
        <S.FlightModeSelectorContainer>
          <FormInput component={FlightCriteriaMode} fieldPath="mode" />
        </S.FlightModeSelectorContainer>

        <S.FlightCriteriaContainer searchEngine_orientation={searchEngine_orientation}>
          <S.BoxDestination searchEngine_orientation={searchEngine_orientation}>
            <S.DoubleInputTitle>
              <S.TitleInputs>Origem</S.TitleInputs>
              <S.TitleInputs>Destino</S.TitleInputs>
            </S.DoubleInputTitle>

            <S.DoubleInput>
              <FormInput component={InputAutoComplete} fieldMapping={{
              name: 'slices.0.originDescription',
              id: 'slices.0.origin'
            }} className="origin-autocomplete" placeholder="Cidade de origem" Icon={IcLocalization} singleOptionAutoSelect={true} debounce={600} minInputQuery={2} required={true} suggestionsSource={destinationSearch} renderOption={ContentSearchOption} autoFocus={autoFocus} doubleInput="left" suggestionToString={(origin: Destination) => origin.name || origin.label} headerSize={headerSize} />
              <S.DoubleInputSeparator>
                {['ROUNDTRIP', 'ONEWAY', 'MULTICITY'].includes(mode) && <IcArrow direction="right" color={theme.colors.text} size={9} />}
                {['ROUNDTRIP', 'MULTICITY'].includes(mode) && <IcArrow direction="left" color={theme.colors.text} size={9} />}
              </S.DoubleInputSeparator>
              <FormInput component={InputAutoComplete} fieldMapping={{
              name: 'slices.0.destinationDescription',
              id: 'slices.0.destination'
            }} className="destination-autocomplete" placeholder="Cidade de destino" Icon={IcLocalization} singleOptionAutoSelect={true} debounce={600} minInputQuery={2} required={true} suggestionsSource={destinationSearch} renderOption={ContentSearchOption} autoFocus={autoFocus} doubleInput="right" suggestionToString={(destination: Destination) => destination.name || destination.label} headerSize={headerSize} />
            </S.DoubleInput>
          </S.BoxDestination>

          <S.BoxDates searchEngine_orientation={searchEngine_orientation}>
            <S.TitleInputs>
              Ida{mode !== 'ONEWAY' && ' / Volta'}
              {days > 1 ? <span>{days} dias</span> : days === 1 ? <span>{days} dia</span> : null}
            </S.TitleInputs>

            <S.DateBaseStyles>
              <FormInput component={DatePickerV2} fieldMapping={{
              startDate: 'slices.0.startDate',
              endDate: 'slices.0.endDate'
            }} minDate={minDate} maxDate={maxDate} initOpened={modifyDates} headerSize={headerSize} oneway={mode === 'ONEWAY'} centerMode minDiffDays={0} referenceComponent={refComponentForCalendar} />
            </S.DateBaseStyles>
          </S.BoxDates>

          <S.BoxPassengers searchEngine_orientation={searchEngine_orientation}>
            <S.TitleInputs>Passageiros</S.TitleInputs>

            <CounterPassengers flightPassengers={{
            adults,
            children,
            babies: infantsSeated ?? 0
            // flightClass,
          }}
          // companyRulesUrl={companyRulesUrl}
          flightPreSearchRule={flightPreSearchRule} setPassengerChoices={setPassengerChoices} />
          </S.BoxPassengers>

          <S.StyleButton searchEngine_orientation={searchEngine_orientation}>
            <Button color="primary" variant="contained"
          // disabled={diffInDays(period?.endDate, period?.startDate) === 0}
          type="submit" fullWidth>
              Buscar
            </Button>
          </S.StyleButton>
        </S.FlightCriteriaContainer>
      </Form>
    </S.ContainerCriteria>;
};
export default FlightCriteria;