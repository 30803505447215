import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import client from '../client';

// dados de cliente públicos (ex: otabuilder)
export default fillCrudMetadataGaps({
  label: 'Cliente',
  labelPlural: 'Clientes',
  name: 'ShallowClient',
  deletable: false,
  updatable: false,
  insertable: false,
  searchable: true,
  authorization: authorization('unauthenticated', false),
  dynamoDB: {
    tableName: 'SpearClient'
  },
  columnNames: ['id'],
  fields: [...client.fields.filter(f => f['custom:shallowClient'])],
  filter: [{
    field: 'id',
    op: 'eq',
    label: 'Id'
  }, {
    field: 'id',
    op: 'ne',
    label: 'Id'
  }, {
    name: 'brand_name_eq',
    field: 'brand_name',
    op: 'beginsWith',
    label: 'Nome'
  }, {
    name: 'query',
    graphQLType: 'String',
    type: 'text',
    label: 'Pesquisa'
  }, {
    field: 'brand_name',
    op: 'contains',
    label: 'Nome'
  }, {
    field: 'isParentClient',
    op: 'eq',
    label: 'É cliente pai?'
  }, {
    field: 'isParentClient',
    op: 'ne',
    label: 'Não é cliente pai?'
  }, {
    field: 'parentClientId',
    op: 'isNull',
    label: 'Cliente pai'
  }]
});