import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import ledgerTransaction from '../ledgerTransaction';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Transação de wallet',
  labelPlural: 'Transações de wallet',
  name: 'SelfLedgerTransaction',
  insertable: false,
  updatable: false,
  deletable: false,
  searchable: true,
  authorization: authorization('unauthenticated', false),
  dynamoDB: {
    tableName: 'SpearLedgerTransaction'
  },
  fields: [...ledgerTransaction.fields].filter(f => !f['custom:excludeForSelfFlightReservation'])
});