import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Atividade',
  labelPlural: 'Atividades',
  name: 'Activity',
  insertable: true,
  updatable: false,
  deletable: false,
  authorization: authorization('clientConsultant'),
  columnNames: ['timelineId', 'timestamp', 'comment', 'createdByName'],
  fields: [{
    label: 'TimeLine',
    name: 'timelineId',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true
  }, {
    label: 'Timestamp',
    name: 'timestamp',
    type: 'datetime',
    updatable: false,
    insertable: false,
    id: true
  }, {
    label: 'Subject',
    name: 'subject',
    type: 'text',
    updatable: false,
    insertable: true,
    required: true
  }, {
    label: 'Comentário',
    name: 'comment',
    type: 'text',
    inputType: 'textarea'
  }, {
    label: 'Autor',
    name: 'createdByName',
    type: 'text',
    insertable: false,
    updatable: false
  }],
  filter: [{
    name: 'subject_eq',
    field: 'subject',
    op: 'eq',
    label: 'Subject'
  }]
});