import type { DefaultTheme } from 'styled-components'

const ZINDEX_NAVBAR = 1000
const ZINDEX_SEARCH_BAR = ZINDEX_NAVBAR + 10
const ZINDEX_CART_MODAL_OPEN = ZINDEX_SEARCH_BAR + 100
const ZINDEX_CART = ZINDEX_CART_MODAL_OPEN + 100
const ZINDEX_OPEN_NAVBAR = ZINDEX_CART + 100
const ZINDEX_MODAL = ZINDEX_OPEN_NAVBAR + 100
const ZINDEX_OVER_MODAL = ZINDEX_MODAL + 100

export const BREAKPOINT_SM = '769px'
export const BREAKPOINT_MD = '1088px'
export const BREAKPOINT_LOW_DPI = '1440px'
export const CONTAINER_MAX_WIDTH = '1400px'
export const CONTAINER_OTA_MAX_WIDTH = '1088px'
export const CONTAINER_X_PADDING = '20px'

const defaultTheme: DefaultTheme = {
  breakpoints: {
    sm: '769px',
    md: '1088px',
  },
  // fontSizes: [],

  // space: [0, 2, 4, 6],

  colors: {
    bodyBg: '#FCFCFC',
    bgColor: '#EDEDED',

    primary: '#BF0068',
    secondary: '#9500AE',

    danger: '#FF0000',
    success: '#53AC2A',
    info: '#0C83D9',
    warning: '#FDBA01',
    warningInfo: '#FEF4D8',
    warningPayment: '#FDD14D',
    disabled: '#9C9C9C',

    link: '#0C83D9',
    text: '#273240',
    textColor: '#273240',

    navbarBg: '#FFFFFF',
    navbarText: '#273240',

    footerBg: '#273240',
    // footerText: '#FFFFFF',

    containerBg: '#FFFFFF',
    // containerText: '#273240',

    priceColor: '#008009',

    gray0: '#A8A8A8',
    gray1: '#F5F5F5',
    gray2: '#EDEDED',
    gray3: '#E6E6E6',
    gray4: '#C5C5C5',
    gray5: '#9C9C9C',
    gray6: '#616161',

    focusedInputBorder: '#151B26',

    blue: '#0C83D9',
    blueBerry: '#4b8cf4',
    grey: '#666666',
    yellow: '#FFD100',
    clear: '#008009',
    error: '#E60000',
    black: '#000000',
    codGray: '#000000cc',
    high: '#212121',
    white: '#FFFFFF',
    headerTheme: '#6d218c',
  },

  zIndex: {
    navbar: ZINDEX_NAVBAR,
    openNavbar: ZINDEX_OPEN_NAVBAR,
    searchBar: ZINDEX_SEARCH_BAR,
    searchChange: ZINDEX_SEARCH_BAR, // barra
    searchEngine: 50,
    searchCalendar: ZINDEX_MODAL,
    searchDestiny: ZINDEX_MODAL,
    modal: ZINDEX_MODAL,
    imageGallery: 2000,
    recommendedBadges: 3,
    cartSideModal: 1070,
    cookiesBar: 2000,
    cartTotal: ZINDEX_CART, // sticky fixed bottom
    cartItems: ZINDEX_CART_MODAL_OPEN,
    overModal: ZINDEX_OVER_MODAL,
  },

  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
    xxl: '64px',
  },
}

export default defaultTheme
