import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Bacon',
  labelPlural: 'Bacons',
  name: 'PersonAssociation',
  namePlural: 'PersonAssociations',
  authorization: authorization('clientConsultant', 'admin'),
  deletable: false,
  searchable: true,
  dynamoDB: {
    tableName: 'SpearPersonAssociation'
  },
  columns: ['id'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false,
    hint: 'personId#itemType#itemId'
  }, {
    name: 'personId',
    label: 'personId'
  }, {
    name: 'itemId',
    label: 'itemId'
  }, {
    name: 'itemType',
    label: 'itemType'
  }, {
    name: 'associationDateTime',
    label: 'associationDateTime',
    type: 'datetime'
  }],
  filter: [{
    field: 'id',
    op: 'beginsWith'
  }]
});