import React, { useState } from 'react';
import * as S from './styles';
export type CountProps = S.CountPropsColor & {
  min?: number;
  max?: number;
  name?: string;
  disabled?: boolean;
  value: number;
  onChange?: (value: number) => void;
};
const Count: React.FC<CountProps> = ({
  min,
  max,
  name,
  disabled,
  value = 0,
  onChange
}) => {
  const [countPeoples, setCountPeoples] = useState(() => value);
  const handleCount = type => {
    if (type === 'subtract') {
      setCountPeoples(countPeoples - 1);
      onChange?.(countPeoples - 1);
    }
    if (type === 'add') {
      setCountPeoples(countPeoples + 1);
      onChange?.(countPeoples + 1);
    }
  };
  return <>
      <S.Container>
        <button type="button" disabled={disabled || (countPeoples <= min ?? false)} onClick={() => handleCount('subtract')} />

        <S.InputCount name={name} min={min} max={max} value={countPeoples} type="text" readOnly disabled={disabled} />

        <button type="button" disabled={disabled || (countPeoples >= max ?? false)} onClick={() => handleCount('add')} />
      </S.Container>
    </>;
};
export default Count;