import styled from 'styled-components'

interface TextProps {
  size?: number
  fontTextBold?: boolean
}

export const Tax = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  line-height: 22px;
  text-wrap: nowrap;
`

export const TextInfo = styled.div`
  align-items: center;
  display: flex;
  gap: 6px;
`

export const Text = styled.h5<TextProps>`
  font-size: ${({ size }) => size ?? 11}px;
  font-weight: ${({ fontTextBold }) => (fontTextBold ? 700 : 400)};
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const IconInfo = styled.span`
  cursor: pointer;
`

export const ValuePriceTax = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  flex: 1;
  justify-content: flex-end;
`

export const EditPercentage = styled.div`
  position: absolute;
  right: -18px;
  top: -1px;
`
