export default [{
  label: 'Centro de Custo',
  value: 'costCenter'
}, {
  label: 'Conta contábil',
  value: 'accountingAccount'
}, {
  label: 'Projeto',
  value: 'project'
}, {
  label: 'Motivo de viagem',
  value: 'travelReason'
}];