import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Configuração de Fidelidade - Resgate',
  labelPlural: 'Configurações de Fidelidade - Resgate',
  name: 'LoyaltyRedeemConfiguration',
  deletable: false,
  updatable: true,
  insertable: true,
  custom_withAudit: true,
  authorization: authorization('clientConsultant', 'admin'),
  dynamoDB: {
    tableName: 'SpearLoyaltyRedeemConfiguration'
  },
  columnNames: ['id', 'name', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    render: false,
    id: true
  }, {
    label: 'Nome',
    name: 'name',
    type: 'text'
  }, {
    label: 'Ativo',
    name: 'enabled',
    type: 'boolean'
  }, {
    custom_groupName: 'Configuração',
    name: 'loyaltyType',
    label: 'Tipo de fidelidade',
    type: 'text',
    options: [{
      value: 'CASHBACK',
      label: 'Cashback'
    }, {
      value: 'POINTS',
      label: 'Pontos'
    }]
  }, {
    custom_groupName: 'Configuração',
    name: 'loyaltyCredentialId',
    label: 'Parceiro de fidelidade',
    inputType: 'credential',
    credentialTypeList: ['LIVE', 'ALLP', 'FDMX', 'PTOS', 'TSER'],
    custom_nameField: 'loyaltyCredentialName',
    custom_auditUrl: e => ({
      url: `/credentials`,
      label: e?.loyaltyCredentialName
    })
  }, {
    custom_groupName: 'Configuração',
    name: 'loyaltyCredentialName',
    label: 'Credencial de fidelidade',
    renderForm: false,
    renderColumn: false
  }, {
    custom_groupName: 'Configuração',
    name: 'externalCalculationEnabled',
    label: 'Assumir o cálculo de pontos do sistema integrado',
    type: 'boolean',
    default: false
  }, {
    custom_groupName: 'Configuração',
    name: 'costPerPoints',
    label: 'Custo por ponto',
    type: 'float',
    renderForm(entity) {
      return !entity?.externalCalculationEnabled;
    }
  }, {
    custom_groupName: 'Configuração',
    name: 'prizeCode',
    label: 'Cadastro de prêmio',
    hint: 'Digite aqui o código do prêmio cadastrado no portal Fidelimax',
    renderForm: function (i) {
      return (i.loyaltyCredentialId || '').indexOf('FDMX') !== -1 ? this.render && this.render(i) : false;
    }
  }, {
    custom_groupName: 'Limite de uso',
    name: 'usageLimitEnabled',
    label: 'Limite de uso ativado',
    type: 'boolean',
    default: false,
    renderForm(entity) {
      return !entity?.externalCalculationEnabled;
    }
  }, {
    custom_groupName: 'Limite de uso',
    name: 'usageLimitCalculationBase',
    label: 'Base de cálculo',
    type: 'text',
    default: 'MONEY',
    options: [{
      value: 'MONEY',
      label: 'Valor total reserva'
    }, {
      value: 'NIGHT',
      label: 'Diárias'
    }],
    renderForm: function (i) {
      return i.usageLimitEnabled ? this.render && this.render(i) : false;
    }
  }, {
    custom_groupName: 'Limite de uso',
    label: 'Valor do ponto por moeda',
    name: 'usageLimitPointsConversion',
    type: 'objectarray',
    graphQLType: '[LoyaltyRedeemCurrencyListItem]',
    graphQLInputType: '[LoyaltyRedeemCurrencyListItemInput]',
    fields: [{
      name: 'currency',
      label: 'Moeda',
      inputType: 'select',
      options: [{
        value: 'BRL',
        label: 'Reais (BRL)'
      }, {
        value: 'USD',
        label: 'Dólar (USD)'
      }, {
        value: 'EUR',
        label: 'Euro (EUR)'
      }]
    }, {
      name: 'value',
      label: 'Valor de 1 ponto na moeda escolhida',
      type: 'float'
    }],
    renderForm: function (i) {
      return i.usageLimitEnabled && i.usageLimitCalculationBase == 'MONEY' ? this.render && this.render(i) : false;
    }
  }, {
    custom_groupName: 'Limite de uso',
    label: 'Valor de 1 ponto por diária',
    name: 'usageLimitNightsPointsConversion',
    type: 'float',
    min: 0,
    renderForm: function (i) {
      return i.usageLimitEnabled && i.usageLimitCalculationBase == 'NIGHT' ? this.render && this.render(i) : false;
    }
  }, {
    custom_groupName: 'Limite de uso',
    name: 'usageLimit',
    label: 'Configuração do limite máximo de uso',
    type: 'float',
    inputType: 'percentage',
    default: 1,
    hint: 'Limite máximo do uso de pontos.',
    renderForm: function (i) {
      return i.usageLimitEnabled ? this.render && this.render(i) : false;
    }
  }, {
    custom_groupName: 'Limite de uso',
    name: 'usageInferiorLimit',
    label: 'Configuração do limite minimo de uso',
    type: 'float',
    inputType: 'percentage',
    default: 1,
    hint: 'Limite inferior do uso de pontos.',
    renderForm: function (i) {
      return i.usageLimitEnabled ? this.render && this.render(i) : false;
    }
  }, {
    custom_groupName: 'Casas decimais',
    name: 'allowDecimals',
    label: 'Permite apresentação de casas decimais',
    type: 'boolean',
    default: true,
    renderForm: function (i) {
      return (i.loyaltyCredentialId || '').indexOf('ALLP') === -1 ? this.render && this.render(i) : false;
    }
  }, {
    custom_groupName: 'Casas decimais',
    name: 'decimalsRoundingPolicy',
    label: 'Tipo de arredondamento',
    type: 'text',
    options: [{
      value: 'ROUND',
      label: 'Padrão'
    }, {
      value: 'FLOOR',
      label: 'Para baixo'
    }, {
      value: 'CEIL',
      label: 'Para cima'
    }],
    default: 'ROUND',
    renderForm: function (i) {
      return i.allowDecimals === false && (i.usageLimitEnabled === false || i.usageLimit === 1) ? this.render && this.render(i) : false;
    }
  }, {
    custom_groupName: 'Valor financeiro',
    label: 'Valor do ponto por moeda',
    hint: 'Valor financeiro resultante da efetivação do gasto dos pontos',
    name: 'pointsConversion',
    type: 'objectarray',
    graphQLType: '[LoyaltyRedeemCurrencyListItem]',
    graphQLInputType: '[LoyaltyRedeemCurrencyListItemInput]',
    renderForm(entity, ignored) {
      return !entity?.externalCalculationEnabled;
    },
    fields: [{
      name: 'currency',
      label: 'Moeda',
      inputType: 'select',
      options: [{
        value: 'BRL',
        label: 'Reais (BRL)'
      }, {
        value: 'USD',
        label: 'Dólar (USD)'
      }, {
        value: 'EUR',
        label: 'Euro (EUR)'
      }]
    }, {
      name: 'value',
      label: 'Valor de 1 ponto na moeda escolhida',
      type: 'float'
    }]
  }, {
    custom_groupName: 'Textos',
    label: 'Texto Destaque',
    name: 'highlightText'
  }, {
    custom_groupName: 'Textos',
    label: 'Texto Informativo',
    name: 'informativeText'
  }, {
    custom_groupName: 'Textos',
    label: 'Unidade',
    name: 'unitText'
  }, {
    custom_groupName: 'Configurações de exibição',
    name: 'moneyPointsOrder',
    label: 'Ordem de exibição pontos/dinheiro',
    inputType: 'select',
    default: 'MONEY_FIRST',
    options: [{
      value: 'MONEY_FIRST',
      label: 'Dinheiro - Pontos'
    }, {
      value: 'POINTS_FIRST',
      label: 'Pontos - Dinheiro'
    }]
  }, {
    custom_groupName: 'Configurações de exibição',
    name: 'defaultUsageCalculation',
    label: 'Calculo padrão de pontos',
    inputType: 'select',
    default: 'SUPERIOR_LIMIT',
    options: [{
      value: 'SUPERIOR_LIMIT',
      label: 'Limite superior'
    }, {
      value: 'INFERIOR_LIMIT',
      label: 'Limite inferior'
    }]
  }, {
    custom_groupName: 'Configurações de exibição',
    name: 'showTierOnNavbar',
    label: 'Mostrar nível na barra de navegação',
    type: 'boolean',
    renderForm: function (i) {
      return i.loyaltyCredentialId ? this.render && this.render(i) : false;
    }
  }]
});