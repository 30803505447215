import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Mapeamento',
  labelPlural: 'Mapeamentos',
  name: 'Mapping',
  deletable: false,
  updatable: false,
  insertable: false,
  searchable: false,
  dynamoDB: {
    tableName: 'SpearMapping2',
    partitionKey: 'id',
    sortKey: null
  },
  authorization: authorization('unauthenticated', false),
  columnNames: ['id', 'name'],
  fields: [{
    label: 'Id',
    name: 'id',
    id: true
  }, {
    label: 'Nome',
    name: 'name'
  }, {
    label: 'SoupId',
    name: 'soupId',
    type: 'textarray'
  }, {
    label: 'Tipo',
    name: 'type'
  }, {
    label: 'Cidade',
    name: 'city'
  }, {
    label: 'País',
    name: 'country'
  }, {
    label: 'Latitude',
    name: 'latitude',
    type: 'float'
  }, {
    label: 'Longitude',
    name: 'longitude',
    type: 'float'
  }, {
    label: 'Aeroportos',
    name: 'airportCodes',
    type: 'textarray'
  }, {
    label: 'Código IATA para área metropolitada',
    name: 'IATAMetropolitanCode',
    type: 'text'
  }]
});