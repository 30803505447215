import styled from 'styled-components'
import { BREAKPOINT_MD, BREAKPOINT_SM } from '../../theme/ota'
import { Button as Btn } from '@mui/material'

interface FormCriteriaProps {
  searchEngine_orientation?: string
}

type Active = {
  active: boolean
}

export const ContainerCriteria = styled.div<FormCriteriaProps>`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${({ searchEngine_orientation }) => (searchEngine_orientation === 'VERTICAL' ? '375px' : BREAKPOINT_MD)};
  width: 100%;

  form {
    width: 100%;
  }
`

export const DoubleInputTitle = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;

  p {
    width: 50%;
  }
`

export const DoubleInput = styled.div`
  align-items: center;
  border: 1px solid ${({ theme }) => theme?.colors?.gray5};
  border-radius: 4px;
  display: flex;
  height: 42px;
  flex-flow: row nowrap;
  position: relative;
  width: 100%;

  input {
    background-color: transparent;
  }

  &:focus-within {
    border: 1px solid black;
  }
`

export const DoubleInputSeparator = styled.div`
  align-items: center;
  background: white;
  border: 1px solid ${({ theme }) => theme.colors.gray5};
  border-radius: 14px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 28px;
  left: calc(50% - 14px);
  position: absolute;
  width: 28px;
  z-index: 10;
`

export const TitleInputs = styled.p`
  box-shadow: none;
  color: ${({ theme }) => theme?.colors?.text};
  display: flex;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 0px;
  text-shadow: none;

  span {
    font-weight: lighter;
    margin-left: auto;
  }

  @media (min-width: calc(${BREAKPOINT_MD})) {
    font-size: 14px;
    margin-bottom: 5px;
  }
`

export const StyleButton = styled.div<FormCriteriaProps>`
  align-items: end;
  display: flex;
  margin-left: ${({ searchEngine_orientation }) => (searchEngine_orientation === 'VERTICAL' ? '0' : '4px')};

  @media (max-width: ${BREAKPOINT_MD}) {
    margin-left: 0;
  }
`

export const BtnPosition = styled.div`
  &&& {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
    min-width: 85px;
    padding-bottom: 1px;
    width: 100%;
  }
`

export const FlightModeSelectorContainer = styled.div``

export const FlightCriteriaContainer = styled.div<FormCriteriaProps>`
  display: flex;
  flex-direction: ${({ searchEngine_orientation }) => (searchEngine_orientation === 'VERTICAL' ? 'column' : '')};
  flex-grow: 1;
  justify-content: start;
  width: 100%;

  @media (max-width: calc(${BREAKPOINT_MD})) {
    flex-direction: column;
    flex-wrap: wrap;
    align-self: stretch;
    display: block;
    font-size: 16px;
    padding: 20px;
  }
`

export const BoxDestination = styled.div<FormCriteriaProps>`
  justify-content: end;
  display: flex;
  flex-flow: column nowrap;
  height: 70px;

  @media (min-width: calc(${BREAKPOINT_MD})) {
    flex-grow: 1;
    min-width: 240px;
    margin: ${({ searchEngine_orientation }) => (searchEngine_orientation === 'VERTICAL' ? '0' : '0 5px 0px 0px')};
  }

  @media (max-width: calc(${BREAKPOINT_SM})) {
    margin-bottom: 26px;
    width: 100%;
  }
`

export const BoxDates = styled.div<FormCriteriaProps>`
  &&& {
    justify-content: end;
    display: flex;
    flex-flow: column nowrap;
    height: 70px;

    @media (min-width: calc(${BREAKPOINT_MD})) {
      margin: ${({ searchEngine_orientation }) => (searchEngine_orientation === 'VERTICAL' ? '0' : '0 5px')};
      width: ${({ searchEngine_orientation }) => (searchEngine_orientation === 'VERTICAL' ? '100%' : ' 253px')};
    }

    @media (max-width: calc(${BREAKPOINT_SM})) {
      margin-bottom: 30px;
      width: 100%;
    }
  }
`

export const DateBaseStyles = styled.div`
  height: 42px;

  &&& {
    & + div {
      position: relative;
      left: 420px;
    }
  }
`

export const BoxPassengers = styled.div<FormCriteriaProps>`
  &&& {
    justify-content: end;
    display: flex;
    flex-flow: column nowrap;
    min-height: 70px;

    @media (min-width: calc(${BREAKPOINT_MD})) {
      margin: ${({ searchEngine_orientation }) => (searchEngine_orientation === 'VERTICAL' ? '0' : '0 5px')};
      width: ${({ searchEngine_orientation }) => (searchEngine_orientation === 'VERTICAL' ? '100%' : ' 170px')};
    }

    @media (max-width: calc(${BREAKPOINT_MD})) {
      width: 100%;
      margin-bottom: 10px;
    }

    @media (max-width: calc(${BREAKPOINT_SM})) {
      width: 100%;
    }
  }
`

export const BoxChildren = styled.div`
  &&& {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    gap: 10px;

    div {
      flex: 1;
      min-width: 85px;
    }

    @media (max-width: calc(${BREAKPOINT_MD})) {
      padding-left: 0;
    }
  }
`

export const CriteriaModeBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  margin-bottom: 15px;
  width: 100%;
`

export const CriteriaModeButton = styled.button<Active>`
  background: ${({ active, theme }) => (active ? theme.colors.primary : theme.colors.gray1)};
  border: none;
  border-radius: 100px;
  color: ${({ active, theme }) => (active ? theme.colors.primaryReadable : theme.colors.text)};
  font-size: 12px;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  height: 30px;
  line-height: 17px;
  padding: 6px ${({ active }) => (active ? '15px' : '16px')};
  text-align: center;
`

export const Button = styled(Btn)`
  color: ${({ theme }) => theme?.colors?.primaryReadable} !important;
  height: 42px !important;
`
