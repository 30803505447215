import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Customização Visual',
  labelPlural: 'Customizações Visuais',
  name: 'UiCustomization',
  path: '/uiCustomization',
  insertable: true,
  updatable: true,
  deletable: false,
  searchable: false,
  authorization: authorization('unauthenticated', 'master'),
  columnNames: ['id'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    render: false,
    id: true
  }, {
    name: 'tenantId',
    updatable: false,
    render: false
  }, {
    name: 'navbarBrandText',
    label: 'Texto no cabeçalho'
  }, {
    name: 'favicon',
    label: 'Favicon',
    inputType: 'inputPublicFile',
    insertable: true,
    custom_gerarPrefix: i => i.tenantId + '/favicon/',
    hint: 'Tamanhos recomendados em pixels: 32x32, 96x96 ou 256x256. Apenas formato ICO ou PNG. Exibirá na cotação e link de pagamento.'
  }, {
    name: 'navbarBrandLogoUrl',
    label: 'Logotipo do menu',
    inputType: 'inputPublicFile',
    insertable: true,
    // render: (i) => i.inputPublicFile && <img src={i.inputPublicFile}></img>,
    custom_gerarPrefix: i => i.tenantId + '/navbarbrand/',
    hint: 'Dica: dê preferência a imagens transparentes, sem margens'
  }, {
    name: 'navbarBrandLogoHeight',
    label: 'Altura do logotipo do menu',
    type: 'int',
    insertable: false,
    options: [{
      label: 'Padrão',
      value: null
    }, {
      label: 'Grande',
      value: 34
    }, {
      label: 'Enorme',
      value: 45
    }]
  }, {
    name: 'navbarBrandLogoSmallUrl',
    label: 'Logotipo do menu reduzido',
    inputType: 'inputPublicFile',
    insertable: true,
    custom_gerarPrefix: i => i.tenantId + '/navbarbrand/',
    hint: 'Logotipo que aparece quando o menu lateral estiver fechado. Utilize imagem com fundo transparente (.png). Tamanho recomendado 24px x 40px.'
  }, {
    name: 'navbarBackgroundColor',
    label: 'Cor de fundo do menu',
    inputType: 'color',
    hint: 'Valor hexadecimal da cor de fundo do menu.',
    placeholder: 'ex: #eb3030',
    mask: '#ffffff'
  }, {
    name: 'headerBackgroundColor',
    label: 'Cor de fundo do cabeçalho',
    inputType: 'color',
    hint: 'Valor hexadecimal da cor de fundo do cabeçalho.',
    placeholder: 'ex: #eb3030',
    mask: '#ffffff',
    deprecated: true,
    render: false
  }, {
    name: 'primaryColor',
    label: 'Cor do botão principal',
    inputType: 'color',
    hint: 'Valor hexadecimal da cor de fundo do botão Reserva',
    placeholder: 'ex: #eb3030',
    mask: '#ffffff'
  }, {
    name: 'secondaryColor',
    label: 'Cor do botão secundário',
    inputType: 'color',
    hint: 'Valor hexadecimal da cor do link Tenho código promocional',
    placeholder: 'ex: #eb3030',
    mask: '#______'
  }, {
    name: 'bodyBackgroundColor',
    label: 'Cor de fundo da página',
    inputType: 'color',
    hint: 'Valor hexadecimal da cor de fundo da página.',
    placeholder: 'ex: #Eb3030',
    mask: '#______',
    deprecated: true,
    render: false
  }]
});