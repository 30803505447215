import styled from 'styled-components'
import { BREAKPOINT_SM } from '../../../theme/ota'

export const Dates = styled.div`
  align-items: center;
  display: flex;

  span {
    display: block;
    font-size: 12px;
    white-space: nowrap;

    &.date {
      color: ${({ theme }) => theme?.colors?.text};
      text-transform: capitalize;
    }

    &.nightCount {
      color: ${({ theme }) => theme?.colors?.gray6};
      margin-left: 10px;
    }
  }
`

export const Separator = styled.div`
  height: 21px;
  margin-left: 10px;
  margin-right: 10px;

  @media (max-width: calc(${BREAKPOINT_SM})) {
    margin-left: 8px;
    margin-right: 8px;
  } ;
`
