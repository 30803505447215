import React from 'react';
import styled from 'styled-components';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import type { TooltipProps } from '@mui/material/Tooltip';
type Props = {
  backgroundColor?: string;
  color?: string;
  fontSize?: number;
  lineHeight?: number;
  maxWidth?: number | 'none';
};
const CustomToolTip = styled(({
  className,
  ...props
}: TooltipProps & Props) => <Tooltip enterTouchDelay={0} PopperProps={{
  popperOptions: {
    placement: 'auto'
  }
}} {...props} classes={{
  popper: className
}} />)(({
  theme,
  backgroundColor,
  color,
  fontSize,
  lineHeight,
  maxWidth
}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: backgroundColor ?? theme.colors?.text,
    borderRadius: '4px',
    color: color ?? theme.colors?.white,
    fontSize: `${fontSize ?? 12}px`,
    lineHeight: `${lineHeight ?? 21}px`,
    maxWidth: maxWidth ?? 300,
    padding: 0
  }
}));
export default CustomToolTip;