import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Distribuição',
  labelPlural: 'Distribuições',
  name: 'Distribution',
  insertable: true,
  updatable: true,
  deletable: false,
  authorization: authorization('admin'),
  'custom:sortFields': ['type', 'alias'],
  columnNames: ['id', 'type', 'alias', 'externalName', 'clientPaymentType', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    render: false,
    id: true
  }, {
    name: 'alias',
    label: 'Nome',
    required: true
  }, {
    name: 'externalName',
    label: 'Nome Externo',
    hint: 'Texto para aparecer na busca de hotéis'
  }, {
    custom_groupName: "Recebimento",
    name: 'b2cEnabled',
    label: 'Habilitado para B2C',
    type: 'boolean',
    hint: 'Permite o uso da função de conversão de cotação usando esta distribuição.'
  }, {
    custom_groupName: "Recebimento",
    name: 'clientPaymentType',
    label: 'Cliente paga',
    type: 'text',
    default: 'DIRECT',
    options: [{
      label: 'Para o Fornecedor',
      value: 'DIRECT',
      hint: 'Seu cliente pagando direto para o FORNECEDOR (operadora, hotel, etc)'
    }, {
      label: 'Para Você',
      value: 'INDIRECT',
      hint: 'Seu cliente pagando para VOCÊ'
    }]
  }, {
    custom_groupName: "Recebimento",
    name: 'clientPaymentValue',
    label: 'Valor a ser cobrado',
    renderForm: function (i) {
      return i.clientPaymentType && i.clientPaymentType == 'DIRECT' ? false : this.render && this.render(i);
    },
    type: 'text',
    options: [{
      label: 'Apenas o valor da Reserva',
      value: 'ITEM',
      hint: '(Padrão)'
    }, {
      label: 'Apenas o valor do Fee',
      value: 'FEE'
    }, {
      label: 'Valor da Reserva + Fee',
      value: 'FEE_PLUS_ITEM'
    }]
  }, {
    custom_groupName: "Recebimento",
    name: 'clientDirectPayment',
    label: 'Pagamentos liberados',
    type: 'textarray',
    options: [{
      label: 'Cartão Offline',
      value: 'OFF_CC',
      hint: 'Cliente pagará fornecedor com VCN/Cartão'
    }, {
      label: 'Cartão Online',
      value: 'ON_CC',
      hint: 'Cliente pagará fornecedor com VCN/Cartão e será cobrado na hora'
    }, {
      label: 'Direto no local',
      value: 'DIR',
      hint: 'Cliente pagará fornecedor no local do serviço (hotel/aeroporto/etc) e fornecerá cartão de crédito como garantia'
    }, {
      label: 'Direto no local (IATA)',
      value: 'DIR_IATA',
      hint: 'Cliente pagará fornecedor no local do serviço (hotel/aeroporto/etc) e será usada a garantia IATA da agência'
    }, {
      label: 'Faturado Offline',
      value: 'OFF_FAT',
      hint: 'Cliente pagará fornecedor via fatura'
    }, {
      label: 'Antecipado Offline',
      value: 'OFF_ANT',
      hint: 'Cliente pagará fornecedor antecipadamente'
    }],
    conditionalRequired: function (i) {
      return i.clientPaymentType == 'DIRECT';
    },
    renderForm: function (i) {
      return i.clientPaymentType && i.clientPaymentType == 'INDIRECT' ? false : this.render && this.render(i);
    },
    renderStyle(i, fieldName = 'clientDirectPayment') {
      if (i[fieldName]) {
        switch (i[fieldName]) {
          case 'OFF_FAT':
          case 'OFF_ANT':
            return 'danger';
          case 'DIR_IATA':
            return 'warning';
        }
        return null;
      }
    }
  }, {
    custom_groupName: "Recebimento",
    name: 'clientIndirectPayment',
    label: 'Recebimentos liberados',
    type: 'textarray',
    inputType: 'paymentCondition',
    conditionalRequired: function (i) {
      return i.clientPaymentType == 'INDIRECT';
    },
    renderForm: function (i) {
      return i.clientPaymentType && i.clientPaymentType == 'DIRECT' ? false : this.render && this.render(i);
    }
  }, {
    custom_groupName: "Pagamento ao Fornecedor",
    name: 'supplierPayment',
    label: 'Opções disponíveis para B2C/SelfBooking',
    renderForm: function (i) {
      return i.clientPaymentType && i.clientPaymentType == 'DIRECT' ? false : this.render && this.render(i);
    },
    type: 'textarray',
    options: [{
      label: 'Cartão Offline',
      value: 'OFF_CC',
      hint: 'Fornecedor será pago com VCN da agência. Atenção: se não existir VCN configurado, ocorrerá erro no momento da reserva.'
    }, {
      label: 'Cartão Online',
      value: 'ON_CC',
      hint: 'Fornecedor será pago com VCN da agência. Atenção: se não existir VCN configurado, ocorrerá erro no momento da reserva.'
    }, {
      label: 'Faturado Offline',
      value: 'OFF_FAT',
      hint: 'Fornecedor será pago por faturamento pela agência'
    }, {
      label: 'Antecipado Offline',
      value: 'OFF_ANT',
      hint: 'Fornecedor será pago antecipadamente pela agência'
    }, {
      label: 'Direto no local',
      value: 'DIR',
      hint: 'Cliente pagará fornecedor no local do serviço (hotel/aeroporto/etc) e fornecerá cartão de crédito como garantia'
    }],
    hint: 'Em reservas de página de evento/ self-booking, o sistema utilizará uma das opções selecionadas para o pagamento ao hotel/fornecedor. Se o fornecedor não aceitar nenhuma das formas escolhidas, o produto não aparecerá para o cliente.'
  }, {
    custom_groupName: "Pagamento ao Fornecedor",
    name: 'sendCreditCardToSupplier',
    label: 'Enviar cartão do passageiro ao fornecedor',
    type: 'boolean',
    withNullSelector: true,
    default: null,
    hint: 'Envia o cartão do passageiro ao fornecedor em caso do recebimento em usando cartão de crédito.'
  }, {
    custom_groupName: "Extras",
    name: 'extras',
    label: 'Extra no Faturamento',
    type: 'textarray',
    options: [{
      label: 'Apenas Diárias',
      value: 'NO_EXTRA',
      hint: 'Faturar apenas diárias.'
    }, {
      label: 'Incluir Extras',
      value: 'EXTRA',
      hint: 'Faturar com extras.'
    }, {
      label: 'Incluir Extras (exceto álcool)',
      value: 'EXTRA_ALCOHOL',
      hint: 'Faturar com extras (exceto álcool).'
    }, {
      label: 'Incluir Extras (exceto álcool e cigarros)',
      value: 'EXTRA_ALCOHOL_CIGAR',
      hint: 'Faturar com extrar (exceto álcool e cigarros).'
    }, {
      label: 'Não incluir Taxa de Turismo',
      value: 'NO_TURISM_TAX',
      hint: 'Faturar sem a taxa de turismo.'
    }, {
      label: 'Incluir apenas Lavanderia',
      value: 'LAUNDRY',
      hint: 'Faturar e adicionar apenas lavanderia.'
    }]
  }, {
    custom_groupName: "Parcelamento ao Fornecedor",
    name: 'maximumProviderInstallments',
    label: 'Parcelamento Máximo',
    type: 'int',
    default: 1,
    min: 1,
    max: 12,
    hint: 'Parcelas máximas para pagamento ao fornecedor.'
  }, {
    custom_groupName: "Markup",
    name: 'markupScope',
    label: 'Escopo',
    default: 'OFF',
    options: [{
      label: 'Sempre',
      value: 'ALWAYS',
      hint: 'Todos os tipos de tarifa.'
    }, {
      label: 'Somente NET',
      value: 'NET',
      hint: 'Apenas em tarifas NET.'
    }, {
      label: 'Dinâmico',
      value: 'DYNAMIC',
      hint: 'Qualquer tipo de tarifas para garantir um faturamento mínimo.'
    }, {
      label: 'Sem Markup',
      value: 'OFF'
    }],
    renderForm: function (i) {
      return i.clientPaymentType && i.clientPaymentType == 'DIRECT' ? false : this.render && this.render(i);
    }
  }, {
    custom_groupName: "Markup",
    name: 'markupTax',
    label: 'Markup na Taxa',
    default: false,
    type: 'boolean',
    renderForm: function (i) {
      return i.clientPaymentType && i.clientPaymentType == 'DIRECT' ? false : this.render && this.render(i);
    }
  }, {
    custom_groupName: "Markup",
    name: 'markup',
    label: 'Markup',
    type: 'float',
    inputType: 'percentage',
    renderForm: function (i) {
      return i.clientPaymentType && i.clientPaymentType == 'DIRECT' ? false : this.render && this.render(i);
    }
  }, {
    custom_groupName: "Comissão",
    name: 'commissionReference',
    label: 'Referência da comissão',
    default: 'OFF',
    options: [{
      label: 'Total',
      value: 'TOTAL',
      hint: '%sobre o valor total da reserva (sem taxas)'
    }, {
      label: 'Markup',
      value: 'MARKUP',
      hint: '%sobre o markup aplicado'
    }, {
      label: 'Comissão',
      value: 'COMMISSION',
      hint: '%sobre a comissão do fornecedor'
    }, {
      label: 'Sem comissão',
      value: 'OFF'
    }]
  }, {
    custom_groupName: "Comissão",
    name: 'commissionPercentage',
    label: 'Porcentagem de comissão',
    type: 'float',
    renderForm: function (i) {
      return i.commissionReference && i.commissionReference == 'OFF' ? false : this.render && this.render(i);
    },
    inputType: 'percentage'
  }, {
    custom_groupName: "Valor Adicional",
    name: 'feeScope',
    label: 'Tarifa',
    default: 'OFF',
    options: [{
      label: 'Total',
      value: 'TOTAL',
      hint: '%sobre o valor total da reserva (sem taxas)'
    }, {
      label: 'Fixa',
      value: 'FIXED',
      hint: 'tarifa fixa'
    }, {
      label: 'Fixa por diária',
      value: 'FIXED_PER_NIGHT',
      hint: 'tarifa fixa por diária'
    }, {
      label: 'Dinâmica',
      value: 'DYNAMIC',
      hint: 'utiliza maior valor entre % e fixa'
    }, {
      label: 'Sem tarifa',
      value: 'OFF'
    }]
  }, {
    custom_groupName: "Valor Adicional",
    name: 'feeValue',
    label: 'Valor da tarifa',
    type: 'float',
    renderForm: function (i) {
      return i.feeScope && i.feeScope == 'FIXED' || i.feeScope == 'FIXED_PER_NIGHT' ? this.render && this.render(i) : false;
    },
    inputType: 'price'
  }, {
    custom_groupName: "Valor Adicional",
    name: 'feePercentage',
    label: 'Porcentagem da tarifa',
    type: 'float',
    renderForm: function (i) {
      return i.feeScope && i.feeScope == 'TOTAL' || i.feeScope == 'DYNAMIC' ? this.render && this.render(i) : false;
    },
    inputType: 'percentage'
  }, {
    custom_groupName: "Valor Adicional",
    name: 'feeDisplay',
    label: 'Adicionar no preço',
    options: [{
      label: 'Taxa',
      value: 'TAXES',
      hint: 'Mostrar somado nas taxas'
    }, {
      label: 'Destaque',
      value: 'SELF',
      hint: 'Mostrar em destaque'
    }],
    renderForm: function (i) {
      return i.feeScope && i.feeScope == 'OFF' ? false : this.render && this.render(i);
    }
  }, {
    custom_groupName: "Valor Adicional",
    name: 'feeDisplayLabel',
    label: 'Label',
    renderForm: function (i) {
      return i.feeDisplay && i.feeScope != 'OFF' && i.feeDisplay == 'SELF' ? this.render && this.render(i) : false;
    },
    hint: 'Texto que vai aparecer na tela de booking e da reserva.'
  }, {
    custom_groupName: "Moeda",
    name: 'sellingCurrency',
    label: 'Moeda de Venda',
    default: 'OFF',
    options: [{
      label: 'Mesma do fornecedor',
      value: 'OFF'
    }, {
      label: 'BRL',
      value: 'BRL'
    }, {
      label: 'USD',
      value: 'USD'
    }, {
      label: 'EUR',
      value: 'EUR'
    }],
    deprecated: true,
    renderForm: false,
    // renderForm: function (i) {
    //   return i.clientPaymentType && i.clientPaymentType == 'INDIRECT' ? this.render && this.render(i) : false
    // },
    hint: 'Permite converter a moeda recebida do fornecedor para a moeda de venda selecionada. Necessário cadastro do câmbio entre as moedas.'
  }, {
    custom_groupName: "Pagar Depois",
    name: 'laterAlias',
    label: 'Nome',
    renderForm: function (i) {
      return i.clientPaymentType && i.clientPaymentType == 'DIRECT' ? false : this.render && this.render(i);
    },
    hint: 'Esse texto vai aparecer no momento da reserva como opção de pagamento.'
  }, {
    custom_groupName: "Pagar Depois",
    name: 'laterShortDescription',
    label: 'Descrição',
    renderForm: function (i) {
      return i.clientPaymentType && i.clientPaymentType == 'DIRECT' ? false : this.render && this.render(i);
    },
    hint: 'Esse texto vai aparecer no momento da reserva se essa forma de pagamento for selecionada.'
  }, {
    custom_groupName: "Pagar Depois",
    name: 'laterAutomaticCancel',
    label: 'Cancelamento Automático',
    default: false,
    type: 'boolean',
    renderForm: function (i) {
      return i.clientPaymentType && i.clientPaymentType == 'DIRECT' ? false : this.render && this.render(i);
    }
  }, {
    custom_groupName: "Pagar Depois",
    name: 'laterDaysAfterReservation',
    label: 'Dias depois da Reserva',
    type: 'int',
    default: 2,
    min: 1,
    max: 30,
    renderForm: function (i) {
      return i.clientPaymentType && i.clientPaymentType == 'INDIRECT' && i.laterAutomaticCancel ? this.render && this.render(i) : false;
    },
    hint: 'Até quantos dias depois da data de reserva pode segurar a reserva'
  }, {
    custom_groupName: "Pagar Depois",
    name: 'laterDaysBeforePenalty',
    label: 'Dias antes da Penalidade',
    type: 'int',
    default: 2,
    min: 1,
    max: 30,
    renderForm: function (i) {
      return i.clientPaymentType && i.clientPaymentType == 'INDIRECT' && i.laterAutomaticCancel ? this.render && this.render(i) : false;
    },
    hint: 'Até quantos dias antes da penalidade pode segurar a reserva'
  }, {
    custom_groupName: "Outros",
    name: 'enabled',
    label: 'Ativo',
    default: true,
    type: 'boolean'
  }],
  filter: [{
    op: 'eq',
    field: 'enabled'
  }]
});