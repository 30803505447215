import fillCrudMetadataGaps, { PartialField } from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import { CONTENT, credentialTypesOptions } from '../credential';
const propertyCountFields: Array<PartialField> = credentialTypesOptions.filter(option => CONTENT.indexOf(option.value) !== -1).map(option => ({
  name: `${option.value.toLowerCase()}PropertyCount`,
  label: `Quantidade de propriedades ${option.label}`,
  updatable: false,
  type: 'int',
  default: 0
}));
export type RegionTypes = 'neighborhood' | 'city' | 'state' | 'country' | 'multi_city_vicinity' | 'point_of_interest' | 'airport' | 'high_level_region' | 'province_state' | 'continent' | 'train_station' | 'metro_station';
export default fillCrudMetadataGaps({
  label: 'Região',
  labelPlural: 'Regiões',
  name: 'Region',
  namePlural: 'Regions',
  authorization: authorization('admin'),
  deletable: false,
  updatable: true,
  insertable: false,
  dynamoDB: {
    tableName: 'SpearRegionDev',
    partitionKey: 'id',
    sortKey: null
  },
  columns: ['id', 'name', 'type', 'address'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'type',
    label: 'Tipo da região'
  }, {
    name: 'name',
    label: 'Nome',
    type: 'text',
    required: true
  }, {
    name: 'address',
    label: 'Endereço',
    type: 'text',
    required: true
  }, {
    name: 'continent',
    label: 'Contiente',
    required: true
  }, {
    name: 'country',
    label: 'País',
    required: true
  }, {
    name: 'state',
    label: 'Estado',
    required: true
  }, {
    name: 'countryCode',
    label: 'Código do país',
    required: true,
    maxLength: 2
  }, {
    name: 'coordinates',
    label: 'Coordenadas',
    type: 'object',
    fields: [{
      name: 'lat',
      label: 'Latitude',
      type: 'float'
    }, {
      name: 'lon',
      label: 'Longitude',
      type: 'float'
    }]
  }, ...propertyCountFields, {
    name: 'polygon',
    label: 'Polígono',
    type: 'object',
    render: false,
    // TODO: Remover
    updatable: false,
    fields: [{
      name: 'coordinates',
      label: 'Coordenadas',
      type: 'floatarray'
    }, {
      name: 'type',
      label: 'Tipo'
    }]
  }],
  filter: [{
    field: 'name',
    op: 'contains'
  }]
});