import styled from 'styled-components'

export type CartCardProps = {
  isNavigation?: boolean
}

export const CartCard = styled.div<CartCardProps>`
  background-color: ${({ theme }) => theme?.colors?.white ?? '#FFFFFF'};
  border-radius: ${({ isNavigation }) => (isNavigation ? '4px' : '')};
  box-shadow: 0px 0px 15px #0000000d;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-bottom: 0px;
    margin-top: 0px;
  }
`

export const CardContent = styled.div<CartCardProps>`
  margin-right: 5px;
  max-height: calc(100vh - 500px);
  min-height: 200px;
  padding: 15px 15px 0px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    border-width: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
    border-color: #ffffff;
    border-width: 2px 2px 2px 2px;
    border-style: solid;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c5c5c5;
    border-color: #c5c5c5;
    border-style: solid;
    border-width: 1px 8px 1px 8px;
  }
`

export const Divider = styled.hr`
  border: none;
  border-top: 1px solid ${({ theme }) => theme?.colors?.gray3};
  margin-top: 15px;
  margin-bottom: 15px;
`

export const ContentCart = styled.div`
  margin-top: 20px;
`

export const GroupCartItem = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 10px;
`

export const ContainerItems = styled.div`
  margin-bottom: 15px;
`

export const HotelTitle = styled.h5`
  font-size: 13px;
  font-weight: 700;
  line-height: 10px;
  max-width: 210px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const City = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  margin-top: 2px;
  width: 100%;
`

export const Icon = styled.img`
  height: 12px;
`

export const TextCity = styled.p`
  color: ${({ theme }) => theme?.colors?.gray5};
  font-size: 11px;
`

export const Item = styled.div`
  margin-top: 15px;
  &:not(:last-child) {
    padding-bottom: 15px;
    border-bottom: 1px solid ${({ theme }) => theme?.colors?.gray3};
  }

  &:first-of-type {
    margin-top: 0;
  }
`

export const EmptyCartMessageContainer = styled.div`
  text-align: center;
  vertical-align: center;
  height: 110px;
  display: flex;

  p {
    color: ${({ theme }) => theme?.colors?.gray6};
    align-self: center;
  }
`

export const IconRoom = styled.img`
  border-radius: 4px;
  height: 40px;
  width: 40px;
`

export const HeaderHotelName = styled.div`
  padding: 8px;
`
