import matchSorter from 'match-sorter';
const NORMALIZED_MEALNAMES = ['Café da manhã', 'Café da manhã continental', 'Meia pensão', 'All inclusive', 'Plan todo incluido'];
const EXCLUDED_WORDS = ['included', 'cortesia', 'incluso', 'incluído',
// "free",
'na diária'
// "incluido"
];
export default function normalizeMealName(mealName) {
  if (!mealName) return null;
  let normalized = mealName.normalize('NFC').toLowerCase();
  normalized = normalized.toLowerCase().replace(/[.\-()[\]-]/g, ' ');
  EXCLUDED_WORDS.forEach(blacklisted => normalized = normalized.replace(new RegExp(blacklisted), ''));
  normalized = normalized.replace(/ {1,}/g, ' ').trim();
  if (normalized.length === 0) return null;

  //procura entre os mealnames previamente normalizados
  normalized = matchSorter(NORMALIZED_MEALNAMES, normalized)[0] || normalized;
  return normalized.substring(0, 1).toUpperCase() + normalized.substring(1);
}