import styled from 'styled-components';
export const Container = styled.div`
  &&& {
    width: 100%;
    margin: 0 auto;
  }
`;
export const CenteredContainer = styled.div`
  &&& {
    max-width: 1286px;
    margin: 0 auto;
  }
`;
export const Reset = styled.div`
  * {
    box-sizing: border-box;
    background: unset;
    text-transform: unset;
    font-family: 'Manrope', sans-serif;
    line-height: unset;
    /* display: unset; - transforma o display de div como inline */
    text-decoration: unset;
    border: unset;
    border-top: unset;
    border-bottom: unset;
    margin: unset;
    margin-top: unset;
    margin-bottom: unset;
    margin-left: unset;
    margin-right: unset;
    padding: unset;
    padding-left: unset;
    padding-right: unset;
    color: unset;
    background-color: unset;
    font-weight: unset;
    font-size: unset;
    outline: none;
    font: unset;
    width: unset;
    max-width: unset;
    height: unset;
    width: unset;
    overflow: unset;
    text-align: unset;

    &::-webkit-scrollbar {
      width: 5px;
      border-width: 5px;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: #e6e6e6;
      border-color: #ffffff;
      border-width: 2px 2px 2px 2px;
      border-style: solid;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #c5c5c5;
      border-color: #c5c5c5;
      border-style: solid;
      border-width: 1px 8px 1px 8px;
    }
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;

    /* &:hover {
      color: #0c83d9;
    } */
  }

  button {
    cursor: pointer;
  }

  textarea {
    resize: none;
  }

  font-family: Manrope, sans-serif;
  min-height: 100%;
  vertical-align: baseline;
  text-align: start;
`;