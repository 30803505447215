import React from 'react';
import { useTheme } from '../..';
import { IcAeroplane, IcHotelBuilding } from '../../Icons';
import * as S from './styles';
export type Mode = 'HOTEL' | 'FLIGHT';
export type SearchBarProps = {
  mode?: Mode;
  setMode?: React.Dispatch<React.SetStateAction<'HOTEL' | 'FLIGHT'>>;
};
const SEARCH_MODE: {
  mode: Mode;
  text: string;
  icon?;
}[] = [{
  text: 'Hotel',
  mode: 'HOTEL',
  icon: IcHotelBuilding
}, {
  text: 'Aéreo',
  mode: 'FLIGHT',
  icon: IcAeroplane
}];
const SearchBar: React.FC<SearchBarProps> = ({
  mode = 'HOTEL',
  setMode
}) => {
  const theme = useTheme();
  return <S.Bar>
      {SEARCH_MODE.map(SEARCH => {
      const active = SEARCH.mode === mode;
      return <S.BarItem active={active} onClick={() => setMode(SEARCH.mode)}>
            <SEARCH.icon color={active ? theme.colors.primary : undefined} />
            <S.BarText active={active}>{SEARCH.text}</S.BarText>
          </S.BarItem>;
    })}
    </S.Bar>;
};
export default SearchBar;