import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import { formatCurrency, formatDateToDate, formatDateToLocaleTime, getReservationNightsString } from '../../util/crudModelListViewUtils';
import { formatDate } from '@niaratech/niara-js-commons';
export default fillCrudMetadataGaps({
  label: 'Reserva perdida',
  labelPlural: 'Reservas perdidas',
  name: 'LostReservation',
  deletable: false,
  updatable: true,
  insertable: false,
  initialFilters: {
    status_in: ['NEW', 'IN_PROGRESS', 'WON', 'LOST']
  },
  renderFilterComponents: [{
    componentName: 'StatusFilter'
  }],
  authorization: authorization('clientConsultant', 'admin'),
  dynamoDB: {
    tableName: 'SpearLostReservation',
    indexName: 'tenantId-sessionDate-index',
    indexedProp: 'sessionDate',
    scanIndexBackwards: true
  },
  columnNames: ['id', 'sessionDate', 'hotelReservationStartDate', 'hotelName', 'clientId', 'clientName', 'landingPageId', 'landingPageName', 'hotelTotalPriceCurrencyCode', 'hotelTotalPriceValue', 'responsible', 'status', 'hotelReservationEndDate', 'hotelRoomCount', 'hotelGuestFirstName', 'hotelGuestLastName', 'hotelRooms', 'hotelGuestEmail', 'hotelGuestPhone'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderCustomColumnStyle: `
        @media screen and (max-width: 1440px) {
            max-width: 90px;
        }
      `
  }, {
    label: 'Nome',
    name: 'name',
    type: 'text',
    render: false
  }, {
    label: 'Ativo',
    name: 'enabled',
    type: 'boolean',
    render: false
  }, {
    label: 'Data Abandono',
    name: 'sessionDate',
    type: 'datetime',
    renderCustomColumnText: {
      title: {
        content: props => `${formatDate(props?.row?.sessionDate, undefined, 'dd/MM/yyyy')}`,
        style: {}
      },
      subTitle: {
        content: props => `${"às"} ${formatDateToLocaleTime(props?.row?.sessionDate)}`,
        style: {}
      }
    }
  }, {
    label: 'Nome',
    name: 'hotelGuestFirstName',
    render: false,
    renderCustomColumn: {}
  }, {
    label: 'Sobrenome',
    name: 'hotelGuestLastName',
    render: false,
    renderCustomColumn: {}
  }, {
    label: 'E-mail',
    name: 'hotelGuestEmail',
    render: false,
    renderCustomColumn: {}
  }, {
    label: 'Telefone',
    name: 'hotelGuestPhone',
    render: false,
    renderCustomColumn: {}
  }, {
    label: 'Id do Hotel',
    name: 'hotelId',
    render: false,
    renderCustomColumn: {}
  }, {
    label: 'Hotel',
    name: 'hotelName',
    renderCustomColumnText: {
      title: {
        content: props => props?.row?.hotelName,
        style: {}
      }
    },
    renderCustomColumnStyle: `
          min-width: 300px;

          @media screen and (max-width: 1440px) {
              max-width: 200px;
          }
      `
  }, {
    label: 'Rede',
    name: 'hotelChainName',
    render: false,
    renderCustomColumn: {}
  }, {
    label: 'Check-in',
    name: 'hotelReservationStartDate',
    renderCustomColumnText: {
      title: {
        content: props => formatDateToDate(props?.row?.hotelReservationStartDate),
        style: {}
      },
      subTitle: {
        content: props => getReservationNightsString(props?.row?.hotelReservationStartDate, props?.row?.hotelReservationEndDate),
        style: {}
      }
    }
  }, {
    label: 'Check-out',
    name: 'hotelReservationEndDate',
    render: false,
    renderCustomColumn: {}
  }, {
    label: 'Moeda',
    name: 'hotelTotalPriceCurrencyCode',
    render: false,
    renderCustomColumn: {}
  }, {
    label: 'Valor',
    name: 'hotelTotalPriceValue',
    type: 'float',
    renderCustomColumnText: {
      title: {
        content: props => props?.row?.hotelTotalPriceCurrencyCode ? formatCurrency({
          value: props?.row?.hotelTotalPriceValue,
          currencyCode: props?.row?.hotelTotalPriceCurrencyCode
        }) : props?.row?.hotelTotalPriceValue,
        style: {}
      },
      subTitle: {
        content: props => `${props?.row?.hotelRoomCount} ${props?.row?.hotelRoomCount === 1 ? "Quarto" : "Quartos"}`,
        style: {}
      }
    }
  }, {
    label: 'Apartamento',
    name: 'apppppp',
    render: false,
    renderCustomColumn: {}
  }, {
    label: 'Número de quartos',
    name: 'hotelRoomCount',
    render: false,
    renderCustomColumn: {},
    type: 'int'
  }, {
    label: 'Responsável',
    name: 'responsible',
    renderCustomColumn: {
      componentName: 'Responsible'
    },
    renderCustomColumnStyle: `
                @media screen and (max-width: 1440px) {
                    max-width: 140px;
                }
        `
  }, {
    label: 'Estado',
    name: 'status',
    options: [{
      description: "Nova cotação",
      label: "Novo",
      value: 'NEW',
      accentColor: '#0C83D9'
    }, {
      description: "Reserva Perdida em andamento",
      label: "Em Andamento",
      value: 'IN_PROGRESS',
      accentColor: '#FDBA01'
    }, {
      description: "Esta reserva foi ganha!",
      label: "Ganho",
      value: 'WON',
      accentColor: '#53AC2A'
    }, {
      description: "Esta reserva foi perdida!",
      label: "Perdido",
      value: 'LOST',
      accentColor: '#FF0000'
    }],
    renderCustomColumn: {
      componentName: 'Status'
    }
  }, {
    label: 'Adultos',
    name: 'hotelOccupancyAdultsCount',
    type: 'int',
    render: false,
    renderCustomColumn: {}
  }, {
    label: 'Crianças',
    name: 'hotelOccupancyChildrenCount',
    type: 'int',
    render: false,
    renderCustomColumn: {}
  }, {
    label: 'Idade das Crianças',
    name: 'hotelOccupancyChildrenAges',
    type: 'intarray',
    render: false,
    renderCustomColumn: {}
  }, {
    label: 'Comprado',
    name: 'buyerPurchased',
    type: 'boolean',
    render: false,
    renderCustomColumn: {}
  }, {
    label: 'Remarketing autorizado',
    name: 'buyerRemarketingEnabled',
    type: 'boolean',
    render: false,
    renderCustomColumn: {}
  }, {
    label: 'Cooking remarketing autorizado',
    name: 'buyerRemarketingCookiesDenied',
    type: 'boolean',
    render: false,
    renderCustomColumn: {}
  }, {
    name: 'hotelRooms',
    label: 'Quartos',
    type: 'objectarray',
    graphQLType: '[NestedLostReservationRoomsFields]',
    graphQLInputType: '[NestedLostReservationRoomsFieldsInput]',
    render: false,
    renderCustomColumn: {},
    fields: [{
      name: 'roomOccupancyChildrenCount',
      label: 'Campo',
      type: 'int'
    }, {
      name: 'roomOccupancyAdultsCount',
      label: 'Campo',
      type: 'int'
    }, {
      name: 'roomRateId',
      label: 'Valor fixo',
      type: 'text'
    }, {
      name: 'roomRateName',
      label: 'Valor fixo',
      type: 'text'
    }, {
      name: 'roomOccupancyChildrenAges',
      label: 'Idade das crianças',
      type: 'intarray'
    }, {
      name: 'roomTypeName',
      label: 'Valor fixo',
      type: 'text'
    }, {
      name: 'roomTotalPriceValue',
      label: 'Campo',
      type: 'float'
    }, {
      name: 'roomTotalPriceCurrencyCode',
      label: 'Valor fixo',
      type: 'text'
    }, {
      name: 'roomStartDate',
      label: 'Valor fixo',
      type: 'text'
    }, {
      name: 'roomEndDate',
      label: 'Valor fixo',
      type: 'text'
    }, {
      name: 'roomTypeId',
      label: 'Valor fixo',
      type: 'text'
    }]
  }, {
    label: 'Cliente ID',
    name: 'clientId',
    type: 'text',
    render: false
  }, {
    label: 'Cliente',
    name: 'clientName',
    type: 'text',
    renderCustomColumnText: {
      title: {
        content: props => props?.row?.clientName ?? '-',
        style: {}
      }
    }
  }, {
    label: 'landing Page ID',
    name: 'landingPageId',
    type: 'text',
    render: false
  }, {
    name: 'crmId',
    label: 'Id no CRM',
    type: 'text',
    updatable: false,
    insertable: false
  }, {
    name: 'crmStatus',
    label: 'Informação sobre integração CRM',
    type: 'text',
    updatable: false,
    insertable: false
  }, {
    label: 'Origem',
    name: 'landingPageName',
    type: 'text',
    renderCustomColumnText: {
      title: {
        content: props => props?.row?.landingPageId !== null ? 'OTA Builder' : 'Omnibees',
        style: {}
      },
      subTitle: {
        content: props => props?.row?.landingPageId !== null ? props?.row?.landingPageName : 'Booking Engine',
        style: {}
      }
    }
  }],
  filter: [{
    name: 'sessionDate_le',
    field: 'sessionDate',
    op: 'le'
  }, {
    name: 'sessionDate_eq',
    field: 'sessionDate',
    op: 'eq'
  }, {
    name: 'sessionDate_gt',
    field: 'sessionDate',
    op: 'gt'
  }, {
    name: 'sessionDate_ne',
    field: 'sessionDate',
    op: 'ne'
  }, {
    name: 'sessionDate_ge',
    field: 'sessionDate',
    op: 'ge'
  }, {
    name: 'sessionDate_lt',
    field: 'sessionDate',
    op: 'lt'
  }, {
    name: 'status_in',
    field: 'status',
    op: 'in'
  }, {
    name: 'status_eq',
    field: 'status',
    op: 'eq'
  }, {
    name: 'responsible_eq',
    field: 'responsible',
    op: 'eq'
  }, {
    name: 'responsible_in',
    field: 'responsible',
    op: 'in'
  }, {
    name: 'id_eq',
    field: 'id',
    op: 'eq'
  }]
});