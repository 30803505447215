import { Button as ButtonMaterial } from '@mui/material'
import styled, { css, keyframes } from 'styled-components'
import { IcChevronRightBlack } from '../../Icons'
import { BREAKPOINT_MD } from '../../theme/ota'
require('./date-picker.scss')

export type OpenDateProps = {
  visibleDate?: boolean
  zIndex?: number
  calencarPosition?: number
  centerMode?: boolean
  searchEngine_orientation?: 'HORIZONTAL' | 'VERTICAL'
  enablePromoCode?: boolean
  openDate?: boolean
}

export type PersonSearchEnabledProps = {
  personSearchEnabled?: boolean
}

const animationKeys = keyframes`
  0% {
    background-color: hsla(200, 20%, 75%, .4);
  }
  100% {
    background-color: hsla(200, 20%, 90%, .4);
  }
`

const animation = (props) =>
  props.animated == false
    ? 'none'
    : css`
        ${animationKeys} 1s linear infinite alternate
      `

export const Btn = styled.button<OpenDateProps>`
  &&&& {
    background: unset;
    align-items: center;
    background-color: ${({ theme }) => theme?.colors?.gray4};
    border: none;
    border-radius: 4px;
    color: ${({ theme }) => theme.colors?.gray4};
    display: flex;
    height: 42px;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    padding: 1px;
    transition: ease-in-out 0.15s;
    text-align: center;
    width: 100%;

    @media (max-width: ${BREAKPOINT_MD}) {
      height: 52px;
    }

    .rdrCalendarWrapper {
      background-color: ${({ theme }) => theme?.colors?.white};
      flex-grow: 1;
      height: 100%;
      display: flex;
      .rdrDateDisplayWrapper {
        height: 100%;
        .rdrDateDisplay {
          align-items: center;
          display: flex;
          height: 100%;
        }
      }
    }

    ${({ openDate }) => css`
      background-color: ${({ theme }) => openDate && theme?.colors?.text};
    `}

    &:active,
    &:hover {
      background-color: ${({ theme }) => theme?.colors?.text};
    }
  }
`

export const Container = styled.div<OpenDateProps>`
  & {
    min-width: 230px;
    height: 42px;
    margin-top: 1px;
  }
  & * {
    box-sizing: border-box;
    font-family: 'Manrope';
  }

  &.openDate {
    .rdrMonths {
      margin-top: -28px;
    }
  }
  &&&&,
  .datepicker-container.datepicker-container &&&& {
    /*
  Bloco abaixo altera a cor do dia e preço do calendário, no range das datas selecionadas
  */
    .rdrEndEdge ~ .rdrDayNumber span,
    .rdrStartEdge ~ .rdrDayNumber span,
    .rdrInRange ~ .rdrDayNumber span {
      color: ${({ theme }) => theme?.colors?.primaryReadable};
    }

    ${({ zIndex, visibleDate }) => css`
      position: ${visibleDate ? 'absolute' : 'relative'};
      width: 100%;
      ${zIndex != null && `z-index: ${zIndex};`}
    `}

    & span {
      color: ${({ theme }) => theme?.colors?.text};
    }

    .rdrCalendarWrapper {
      box-shadow: ${({ visibleDate }: OpenDateProps) => (visibleDate ? '0px 0px 25px #00000026;' : 'none')};
      border-radius: ${({ visibleDate }: OpenDateProps) => (visibleDate ? '4px' : 'none')};
      padding: ${({ visibleDate }: OpenDateProps) => (visibleDate ? '20px 30px 32px 30px' : '0')};
      background: ${({ visibleDate, theme }) => (visibleDate ? theme.colors?.white : 'transparent')};
    }

    .rdrMonthAndYearWrapper {
      display: ${({ visibleDate }: OpenDateProps) => (visibleDate ? 'flex' : 'none')};
      height: 24px;
      position: relative;
    }

    .rdrEndEdge,
    .rdrInRange,
    .rdrStartEdge {
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      text-decoration: none;
    }

    .rdrDayStartPreview,
    .rdrDayEndPreview {
      background-color: ${({ theme }) => theme.colors?.primary}1a;
      border: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
    }

    .rdrDayHovered {
      border-radius: 2px;
    }

    .rdrMonthAndYearPickers {
      z-index: ${({ visibleDate }: OpenDateProps) => (visibleDate ? '' : -999)};
    }

    .rdrNextPrevButton {
      background-color: transparent;
      border: 2px solid ${({ theme }) => theme.colors?.gray3};
      border-radius: 50%;
      position: relative;
      padding: 0;
      transition: ease-in-out 0.2s;
      z-index: 1;
      width: 32px;
      height: 32px;

      &:hover {
        border: 2px solid;
      }

      &::before {
        background-image: url(${IcChevronRightBlack});
        background-repeat: no-repeat;
        content: '';
        height: 32px;
        width: 32px;
        position: absolute;
        top: 25%;
        left: 25%;
        transform: translate(-50%, -50%) rotate(180deg);
      }

      & i {
        border-width: 0;
      }
    }

    .rdrNextButton {
      &::before {
        transform: none;
      }
    }

    .rdrMonths {
      .rdrMonthName {
        font-family: 'Manrope';
        margin-bottom: 3px;
        font-size: 13px;
        line-height: 17.16px;
        font-weight: 700;
        color: #273240;
        display: flex;
        justify-content: center;
        text-transform: capitalize;
      }
      .rdrWeekDays {
        max-width: auto;
        width: auto;
      }
      .rdrWeekDay {
        font-size: 11px;
        font-weight: 700;
        text-transform: uppercase;
        color: #9c9c9c;
      }

      &.rdrMonthsHorizontal {
        display: flex;
        align-items: flex-start;
        gap: 56px;
      }
      .rdrMonth {
        width: auto;
        padding: 0;

        .rdrDay,
        .rdrWeekDay {
          margin: 0.5px;
          width: 64px;
        }
        .rdrDay {
          border: 1px solid
            ${({ visibleDate }: OpenDateProps) => (visibleDate ? `${({ theme }) => theme.colors?.gray3}` : '#E6E6E6')};
          height: 54px;
          text-align: center;
          line-height: normal;

          .rdrDayInPreview {
            background-color: ${({ theme }) => theme.colors?.primary}1a;
            border: none;
            bottom: 0;
            top: 0;
          }
        }
      }
    }

    .rdrDateDisplayWrapper {
      background-color: transparent;
      display: ${({ visibleDate }: OpenDateProps) => (visibleDate ? 'none' : '')};

      .rdrDateDisplay {
        margin: 0;
        overflow: hidden;
      }
    }

    .rdrDateDisplayItem {
      background-color: transparent;
      border: transparent;
      border-radius: 0;
      box-shadow: none;
      width: 100%;

      & input {
        color: ${({ theme }) => theme.colors?.text};
        font-size: 14px;
        font-weight: 400;
        height: 2.5em !important;
        padding: 1px 2px;
        text-transform: capitalize;
        transition: ease-in-out 0.3s;
      }
    }

    .rdrDayInPreview {
      border-radius: 0;
    }

    .rdrDateDisplayItem + .rdrDateDisplayItem {
      margin: 0;

      &::before {
        background-color: #e6e6e6;
        content: '';
        height: 22px;
        position: absolute;
        top: 6px;
        width: 1px;
      }

      @media (max-width: calc(${BREAKPOINT_MD})) {
        &::before {
          top: 9px;
        }
      }
    }

    .rdrDayNumber {
      padding: 0;
      align-items: center;

      .item-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 100%;

        .skeleton-component-render-date {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: hsla(200, 20%, 80%, 0.4);
          border-radius: 5px;
          box-sizing: border-box;
          animation: ${animation};
          width: 50%;
          height: 7px;
          margin-top: 5px;
        }
      }

      .unavaliable {
        & span,
        & .price {
          text-decoration: line-through;
        }
      }
      & span {
        color: ${({ theme }) => theme.colors?.text};
        &::after {
          display: none;
        }
      }
      .unavaliable-icon {
        position: absolute;
        top: -8px;
        right: 2px;
        & span {
          color: red !important;
          text-decoration: none !important;
        }
      }
      .min-nights {
        position: absolute;
        top: -6px;
        left: 0;
        background-color: #f0f0f0;
        color: #273240 !important;
        font-size: 10px !important;
        text-decoration: none !important;
        font-weight: normal;
        width: 15px;
        height: 15px;
      }
      .price {
        color: ${({ theme }) => theme.colors?.text};
        font-size: 11px;
        font-weight: normal;
        margin-top: 5px;
      }
    }

    .rdrDayToday {
      color: red;
    }

    .rdrDayPassive {
      border: none !important;
      background: none;
      & span {
        color: ${({ theme }) => theme?.colors?.gray4};
      }

      .min-nights,
      .price {
        display: none;
      }
    }

    & span {
      font-size: 14px;
      font-weight: 700;

      &::after {
        background-color: transparent;
      }
    }

    /*--*/

    @media (min-width: calc(calc(${BREAKPOINT_MD}) + 1px)) {
      .rdrCalendarWrapper {
        background-color: ${({ theme }) => theme?.colors?.white};
        border-radius: 3px;
        box-shadow: ${({ visibleDate }: OpenDateProps) => (visibleDate ? '0px 0px 25px #00000026' : 'none')};
        padding: ${({ visibleDate }: OpenDateProps) => (visibleDate ? '20px 30px 32px 30px' : '0')};
      }
    }

    /*------------------------------ MOBILE ----------------------------------------- */
    @media (max-width: ${BREAKPOINT_MD}) {
      box-shadow: none;
      display: flex;
      left: 0;
      justify-content: center;
      position: relative;
      top: 0;
      width: 100%;
      z-index: ${({ visibleDate }: OpenDateProps) => (visibleDate ? 1091 : '')};

      &.openDate .rdrMonths {
        margin: 0;
        padding-bottom: 150px;
      }

      .rdrWeekDays {
        position: fixed;
        top: 46px;
        height: 46px;
        background-color: #f5f5f5;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #c5c5c5;
        width: 100%;
        left: 0;
        right: 0;
        padding: 0 calc(50% - 175px); // a largura max do calendário é 350px, então coloca metade do padding em cada lado
        z-index: 2;
      }
      .rdrWeekDays,
      .rdrDays {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
      }

      .rdrWeekDay,
      .rdrDay {
        margin: 0px;
        max-width: 49px;
        color: #273240 !important;
      }

      .rdrMonthAndYearPickers {
        display: flex;
      }

      .rdrDateDisplayItem input {
        font-size: 16px;
      }

      &.datepicker-modal-container {
        .rdrDateDisplayWrapper {
          display: none;
        }

        .rdrCalendarWrapper {
          margin-top: 92px;
          padding: 0;
          padding-top: 20px;
          width: 100%;
          max-width: 350px;
          border: 0;
          box-shadow: none;
          background: none;
        }
      }

      .rdrDateDisplayWrapper {
        background-color: ${({ theme }) => theme.colors?.white};
        border-radius: 3px;
        display: block;
        padding: ${({ visibleDate }: OpenDateProps) => (visibleDate ? '20px' : '0')};
        width: 100%;

        .rdrDateDisplay {
          display: flex;
          align-items: center;
          max-width: 100%;
        }
      }

      .rdrDayNumber {
        & p {
          left: 12%;
        }
      }

      .rdrNextPrevButton {
        display: none;
      }

      .rdrMonthAndYearWrapper {
        height: ${({ visibleDate }: OpenDateProps) => (visibleDate ? 'auto' : 0)};
        overflow: ${({ visibleDate }: OpenDateProps) => (visibleDate ? '' : 'hidden')};
        z-index: ${({ visibleDate }: OpenDateProps) => (visibleDate ? '' : -1)};

        .rdrMonthAndYearPickers {
          justify-content: flex-start;
          font-family: Manrope;
          font-size: 14px;
          font-weight: 700;
          line-height: 19.12px;
          text-transform: capitalize;
          margin-bottom: 10px;
        }
      }

      .rdrMonths {
        width: 100%;
        gap: 30px;

        .rdrMonth {
          width: 100%;

          .rdrMonthName {
            justify-content: flex-start;
            padding: 0;
            margin-bottom: 10px;
            font-family: Manrope;
            font-size: 14px;
            font-weight: 700;
            line-height: 19.12px;
          }

          .rdrDay {
            border: 1px solid ${({ theme }) => theme.colors?.gray3};
          }
        }

        .rdrMonthPicker,
        .rdrYearPicker {
          position: relative;
          margin: 0;

          & Select {
            font-family: Manrope, sans-serif;
            appearance: none;
            background: none;
            cursor: default;
            font-size: 15px;
            color: #273240;
            padding: 8px 0;
            -webkit-appearance: none;
            -moz-appearance: none;
            font-weight: 600;
            & option {
              background-color: transparent;
              display: none;
            }

            &:hover {
              background-color: transparent;
            }
          }

          &::before {
            background-color: transparent;
            content: '';
            height: 34px;
            /* left: calc(50% - 74px); */
            position: absolute;
            width: 150px;
          }
        }

        .rdrMonth {
          display: ${({ visibleDate }: OpenDateProps) => (visibleDate ? '' : 'none')};
          top: 0px;
          width: 100%;
        }
      }
    }
  }
`

export const Footer = styled.div`
  &&& {
    background-color: ${({ theme }) => theme.colors?.white};
    box-shadow: -2px -2px 20px #00000029;
    bottom: 0;
    height: 122px;
    padding: 20px;
    position: fixed;
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;

    & div {
      align-items: center;
      display: flex;
      justify-content: center;
      gap: 5px;
      font-family: Manrope;

      & h4 {
        margin: 0;
        color: ${({ theme }) => theme.colors?.clear};
        font-size: 14px;
        font-weight: 700;
        line-height: 19.12px;
      }

      & p {
        margin: 0;
        padding: 0;
        font-size: 12px;
        font-weight: 400;
        line-height: 16.39px;
        color: #9c9c9c;
      }
    }
  }
`

export const LegendsDatePicker = styled.div<OpenDateProps>`
  &&&& * {
    font-size: 11px;

    & .legend {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    & .legend .legend-no-availability-icon {
      font-size: 14px;
      line-height: 14px;
      font-weight: 700;
      padding: 0 2px;
      color: #ff0000;
      vertical-align: middle;
      text-align: center;
    }

    & .legend .legend-availability-icon {
      font-size: 14px;
      line-height: 14px;
      font-weight: 700;
      padding: 0 2px;
      background-color: #f0f0f0;
      vertical-align: middle;
      text-align: center;
    }

    & .lowestPrice {
      font-size: 11px !important;
      font-weight: 700;
    }

    & .lowestPriceContainer,
    & .legend * {
      white-space: nowrap;
    }

    @media (max-width: ${BREAKPOINT_MD}) {
      display: none;
    }
  }
`

export const BtnArrows = styled.button<PersonSearchEnabledProps>`
  position: absolute !important;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 8px 2px !important;
  height: 100% !important;
  outline: none;
  top: ${({ personSearchEnabled }) => (personSearchEnabled ? '0 !important' : '2px !important')};

  &:focus {
    outline: none;
  }

  &.startStart {
    left: 5px !important;
  }

  &.startEnd {
    left: calc(50.2% - 22px);
  }

  &.endStart {
    right: calc(50% - 23px) !important;
  }

  &.endEnd {
    right: 5px !important;
  }

  @media (max-width: calc(${BREAKPOINT_MD})) {
    top: 6px !important;
  }
`
export const ContainerDataRange = styled.div`
  &&& {
    background: unset;
    align-items: center;
    background-color: ${({ theme }) => theme?.colors?.white};
    border: 1px solid ${({ theme }) => theme?.colors?.gray5};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors?.text};
    display: flex;
    height: 37px;
    font-size: 14px;
    font-weight: 400;
    padding: 0 8px;
    text-align: center;
    transition: ease-in-out 0.2s;
    width: 100%;
    @media (max-width: ${BREAKPOINT_MD}) {
      height: 52px;
    }

    .rdrCalendarWrapper {
      flex-grow: 1;
      height: 100%;
      display: flex;
      .rdrDateDisplayWrapper {
        height: 100%;
        .rdrDateDisplay {
          height: 100%;
        }
      }
    }
  }
`

export const TooltipContainer = styled.div`
  width: 160px !important;
  text-align: left !important;

  & p {
    margin: 0;
  }
`

export const DateFormat = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 14px;
  font-weight: normal;
  line-height: 19px;
`

export const Button = styled(ButtonMaterial)`
  color: ${({ theme }) => theme.colors.primaryReadable} !important;
`

export const Header = styled.div`
  &&&& {
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    height: 46px;
    background-color: #ffffff;
    width: 100%;
    padding: 0 20px;
    z-index: 2;
  }
`

export const HeaderTitle = styled.h1`
  &&&& {
    margin: 0;
    padding: 0;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 21.86px;
    text-align: left;
  }
`
