import { darken } from 'polished'
import styled from 'styled-components'

const darkenValue = 0.2

interface ButtonColor {
  color: string
  textColorButton: string
  block?: boolean
}

export const Container = styled.div<{ block?: boolean }>`
  p,
  h6 {
    margin-bottom: 0;
    margin-top: 0;
  }
`

export const Peoples = styled.div`
  position: relative;
  width: 100%;
`

export const BtnPosition = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;
  min-width: 85px;
`

export const Content = styled.div`
  background-color: ${({ theme }) => theme?.colors?.white};
  box-shadow: 0px 0px 25px #00000026;
  border-radius: 4px;
  padding: 20px;
  position: absolute;
  width: 330px;
  z-index: 2;
`

export const Button = styled.button<ButtonColor>`
  background: unset;
  background-color: ${({ color }) => color};
  border-radius: 4px;
  color: ${({ textColorButton }) => textColorButton};
  height: 52px;
  margin-top: 20px;
  padding: ${({ block }) => (block ? '12px' : '8px')};
  width: 100%;
  border: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: ${({ block }) => (block ? '4px' : '22px')};
  text-align: center;
  small {
    display: block;
    font-size: 11px;
    line-height: 1.5em;
    font-weight: 300;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: ${({ color }) =>
      color ? darken(darkenValue, color) : darken(darkenValue, `${({ theme }) => theme?.colors?.black}`)};
    transition: 0.25s ease-in-out;
  }
`

type Open = {
  openCount: boolean
}
export const Btn = styled.button<Open>`
  background: unset;
  align-items: center;
  background-color: ${({ theme }) => theme?.colors?.white};
  border: 1px solid ${({ theme, openCount }) => (openCount ? theme.colors.text : theme?.colors?.gray5)};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors?.text};
  display: flex;
  height: 42px;
  font-size: 14px;
  font-weight: 400;
  padding: 0 8px;
  text-align: center;
  transition: ease-in-out 0.2s;
  /* width: 210px; */
  width: 100%;

  img {
    margin-right: 12px;
  }
`

export const BtnContent = styled.div`
  overflow: hidden;
  white-space: nowrap;
`
