import { PartialField } from '@niaratech/devcase-crud/dist/fillCrudMetadataGaps';
import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Regra de fluxo de reserva',
  labelPlural: 'Regras de fluxo de reserva',
  deletable: false,
  name: 'CheckoutFlowRule',
  namePlural: 'CheckoutFlowRules',
  custom_withAudit: true,
  authorization: authorization('admin', 'admin'),
  dynamoDB: {
    tableName: 'SpearCheckoutFlowRule'
  },
  columns: ['id', 'name', 'enabled'],
  fields: ([{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Título',
    type: 'text',
    required: true
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: true,
    type: 'boolean',
    required: true
  }, {
    name: 'allowBookingWithoutPaymentForUnauthenticated',
    default: 'NEVER',
    options: [{
      value: 'NEVER',
      label: 'Paga primeiro e reserva depois'
    }, {
      value: 'LOW_RISK',
      label: 'Reembolsável:Reserva primeiro e paga depois / Em Penalidade: Paga primeiro e reserva depois'
    }, {
      value: 'ALWAYS',
      label: 'Reserva primeiro e paga depois'
    }],
    required: true,
    label: 'Fluxo reserva (B2C)',
    custom_groupName: 'Fluxo de Checkout'
  }, {
    name: 'allowBookingWithoutPaymentForClient',
    default: 'LOW_RISK',
    options: [{
      value: 'NEVER',
      label: 'Paga primeiro e reserva depois'
    }, {
      value: 'LOW_RISK',
      label: 'Reembolsável:Reserva primeiro e paga depois / Em Penalidade: Paga primeiro e reserva depois'
    }, {
      value: 'ALWAYS',
      label: 'Reserva primeiro e paga depois'
    }],
    required: true,
    label: 'Fluxo reserva (B2B)',
    custom_groupName: 'Fluxo de Checkout'
  }, {
    name: 'allowBookingWithoutPaymentForInstallation',
    default: 'ALWAYS',
    options: [{
      value: 'NEVER',
      label: 'Paga primeiro e reserva depois'
    }, {
      value: 'LOW_RISK',
      label: 'Reembolsável:Reserva primeiro e paga depois / Em Penalidade: Paga primeiro e reserva depois'
    }, {
      value: 'ALWAYS',
      label: 'Reserva primeiro e paga depois'
    }],
    required: true,
    label: 'Fluxo reserva (Instalação)',
    custom_groupName: 'Fluxo de Checkout'
  }, {
    name: 'shoppingCartEnabled',
    label: 'Carrinho de compras habilitado',
    default: false,
    type: 'boolean',
    custom_groupName: 'Carrinho de compras'
  }, {
    name: 'shoppingCartMaxItemCount',
    label: 'Máximo de itens do carrinho',
    default: null,
    type: 'int',
    custom_groupName: 'Carrinho de compras'
  }, {
    name: 'backofficeNotificationEvent',
    label: 'Envio automático',
    default: 'NEVER',
    inputType: 'select',
    options: [{
      label: 'Quando há uma reserva confirmada',
      value: 'CONFIRMED'
    }, {
      label: 'Após confirmação de recebimento e/ou após recebimento faturado registrado',
      value: 'PAYMENT'
    }, {
      label: 'Nunca',
      value: 'NEVER'
    }],
    custom_groupName: 'Notificações de Backoffice'
  }, {
    label: 'Fluxo de alteração (B2B)',
    name: 'b2bChangeFlow',
    custom_groupName: 'Fluxo de Alteração',
    type: 'textarray',
    options: [{
      value: 'BLOCK_B2B_CHANGE_FLOW',
      label: 'Usuário de clientes não poderá fazer nenhum tipo de alteração na reserva'
    }]
  }, {
    label: 'Fluxo de alteração (instalação)',
    name: 'tenantChangeFlow',
    type: 'textarray',
    options: [{
      value: 'BLOCK_TENANT_CHANGE_FLOW',
      label: 'Usuário da instalação não poderá fazer nenhum tipo de alteração na reserva'
    }],
    custom_groupName: 'Fluxo de Alteração'
  }, {
    label: 'Opções de envio automático no comentário',
    name: 'autoSubmitOptionsInComment',
    type: 'textarray',
    custom_groupName: 'Campos de envio automático no comentário',
    inputType: 'niara-react-form/inputMultiSelect',
    withOptionSorter: true,
    options: [{
      label: 'Responsável da reserva',
      value: 'RESPONSIBLE_USER_FOR_RESERVATION'
    }, {
      label: 'Criador da reserva',
      value: 'RESERVATION_CREATOR'
    }]
  }] as Array<PartialField>),
  filter: [{
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq',
    label: 'Ativo'
  }]
});