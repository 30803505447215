import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Relatório Serasa',
  labelPlural: 'Relatórios Serasa',
  name: 'PersonSerasaReport',
  namePlural: 'PersonSerasaReports',
  authorization: authorization('consultant'),
  deletable: false,
  updatable: false,
  insertable: false,
  searchable: true,
  dynamoDB: {
    tableName: 'SpearPersonSerasaReport',
    scanIndexBackwards: true
  },
  columns: ['id', 'date'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false,
    hint: 'personId#date'
  }, {
    name: 'personId',
    label: 'personId',
    type: 'text'
  }, {
    name: 'date',
    label: 'Data e hora',
    type: 'datetime'
  }, {
    name: 'report',
    label: 'Report',
    type: 'object',
    custom_renderFieldsRecursively: true,
    fields: [{
      name: 'facts',
      label: 'facts',
      type: 'object',
      custom_renderFieldsRecursively: true,
      fields: [{
        name: 'bankrupts',
        label: 'Anotações Negativas – Participação em Falência',
        type: 'object',
        fields: [{
          name: 'bankruptsResponse',
          label: 'Anotações Negativas – Participação em Falência',
          type: 'objectarray',
          fields: [{
            name: 'companyDocumentId',
            label: 'CNPJ da empresa em falência',
            type: 'text',
            mask: '99.999.999/9999-99'
          }, {
            name: 'companyLegalNature',
            label: 'Descrição do tipo de falência',
            type: 'text'
          }, {
            name: 'companyLegalNatureID',
            label: 'Tipo de falência',
            type: 'text'
          }, {
            name: 'companyName',
            label: 'Razão social da empresa em falência',
            type: 'text'
          }, {
            name: 'legalSquare',
            label: 'Autoridade emissora/local de emissão',
            type: 'text'
          }, {
            name: 'occurrenceDate',
            label: 'Data da participação da falência',
            type: 'date'
          }]
        }, {
          name: 'summary',
          label: 'summary',
          type: 'object',
          fields: [{
            name: 'balance',
            label: 'balance',
            type: 'float'
          }, {
            name: 'count',
            label: 'count',
            type: 'int'
          }, {
            name: 'firstOccurrence',
            label: 'firstOccurrence',
            type: 'text'
          }, {
            name: 'lastOccurrence',
            label: 'lastOccurrence',
            type: 'text'
          }]
        }]
      }, {
        name: 'inquiry',
        label: 'Consultas à Serasa',
        type: 'object',
        fields: [{
          name: 'inquiryResponse',
          label: 'inquiryResponse',
          type: 'objectarray',
          fields: [{
            name: 'daysQuantity',
            label: 'Quantidade de dias da última consulta',
            type: 'int'
          }, {
            name: 'occurrenceDate',
            label: 'Data da ocorrência',
            type: 'text'
          }, {
            name: 'segmentDescription',
            label: 'Segmento da empresa',
            type: 'text'
          }]
        }, {
          name: 'summary',
          label: 'Situação da empresa participada',
          type: 'object',
          fields: [{
            name: 'count',
            label: 'Quantidade total de itens',
            type: 'int'
          }]
        }]
      }, {
        name: 'inquirySummary',
        label: 'inquirySummary',
        type: 'object',
        fields: [{
          name: 'inquiryQuantity',
          label: 'inquiryQuantity',
          type: 'object',
          fields: [{
            name: 'actual',
            label: 'Quantidade de consultas mês atual',
            type: 'int'
          }, {
            name: 'checkActual',
            label: 'checkActual',
            type: 'int'
          }, {
            name: 'checkInquiriesQuantity',
            type: 'objectarray',
            fields: [{
              name: 'inquiryDate',
              label: 'AAAA-MM da consulta',
              type: 'text'
            }, {
              name: 'occurrences',
              label: 'Quantidade de ocorrências do período',
              type: 'int'
            }]
          }, {
            name: 'creditInquiriesQuantity',
            type: 'objectarray',
            fields: [{
              name: 'inquiryDate',
              label: 'Ano e mês da consulta',
              type: 'text'
            }, {
              name: 'occurrences',
              label: 'Quantidade de ocorrências do período',
              type: 'int'
            }]
          }]
        }, {
          name: 'summary',
          label: 'summary',
          type: 'object',
          fields: [{
            name: 'checkCount',
            label: 'checkCount',
            type: 'int'
          }, {
            name: 'count',
            label: 'Quantidade total de itens',
            type: 'int'
          }, {
            name: 'creditCount',
            label: 'creditCount',
            type: 'int'
          }]
        }]
      }, {
        name: 'judgementFilings',
        label: 'judgementFilings',
        type: 'object',
        fields: [{
          name: 'judgementFilingsResponse',
          label: 'Anotações Negativas - Ações Judiciais',
          type: 'objectarray',
          fields: [{
            name: 'amount',
            label: 'Valor da dívida',
            type: 'float'
          }, {
            name: 'city',
            label: 'Cidade',
            type: 'text'
          }, {
            name: 'civilCourt',
            label: 'Identificador da vara judicial',
            type: 'text'
          }, {
            name: 'distributor',
            label: 'Número do distribuidor',
            type: 'text'
          }, {
            name: 'legalNature',
            label: 'Natureza da dívida',
            type: 'text'
          }, {
            name: 'legalNatureId',
            label: 'Identificador da natureza da dívida',
            type: 'text'
          }, {
            name: 'legalSquare',
            label: 'Autoridade emissora/local de emissão',
            type: 'text'
          }, {
            name: 'occurrenceDate',
            label: 'Data da ocorrência',
            type: 'date'
          }, {
            name: 'state',
            label: 'Estado',
            type: 'text'
          }]
        }, {
          name: 'summary',
          label: 'summary',
          type: 'object',
          fields: [{
            name: 'balance',
            label: 'Valor total das ações judiciais',
            type: 'float'
          }, {
            name: 'count',
            label: 'count',
            type: 'int'
          }, {
            name: 'firstOccurrence',
            label: 'firstOccurrence',
            type: 'text'
          }, {
            name: 'lastOccurrence',
            label: 'lastOccurrence',
            type: 'text'
          }]
        }]
      }, {
        name: 'stolenDocuments',
        type: 'object',
        fields: [{
          name: 'stolenDocumentsResponse',
          label: 'Documentos Roubados',
          type: 'objectarray',
          fields: [{
            name: 'occurrenceDate',
            label: 'Data da ocorrência',
            type: 'date'
          }, {
            name: 'detailedReason',
            label: 'Razão detalhada',
            type: 'text'
          }, {
            name: 'documentNumber',
            label: 'Número do documento',
            type: 'text'
          }, {
            name: 'documentType',
            label: 'Pessoa física ou jurídica',
            type: 'text'
          }, {
            name: 'inclusionDate',
            label: 'Data de inclusão',
            type: 'date'
          }, {
            name: 'issuingAuthority',
            label: 'autoridade emissora/local de emissão',
            type: 'text'
          }, {
            name: 'occurrenceState',
            label: 'Estado da ocorrência',
            type: 'text'
          }, {
            name: 'phoneNumber',
            label: 'Telefone',
            type: 'object',
            fields: [{
              name: 'areaCode',
              type: 'int'
            }, {
              name: 'regionCode',
              type: 'int'
            }, {
              name: 'phoneNumber',
              type: 'int'
            }]
          }]
        }, {
          name: 'summary',
          label: 'summary',
          type: 'object',
          fields: [{
            name: 'balance',
            label: 'balance',
            type: 'float'
          }, {
            name: 'count',
            label: 'count',
            type: 'int'
          }]
        }]
      }]
    }, {
      name: 'negativeData',
      label: 'Dados negativos',
      type: 'object',
      fields: [{
        name: 'check',
        label: 'Anotações Negativas',
        type: 'object',
        fields: [{
          name: 'checkResponse',
          label: 'Anotações Negativas - Cheques Sem Fundo (CCF)',
          type: 'objectarray',
          fields: [{
            name: 'occurrenceDate',
            label: 'Data da ocorrência',
            type: 'date'
          }, {
            name: 'alinea',
            label: 'Identificador do motivo da devolução do cheque',
            type: 'int'
          }, {
            name: 'bankName',
            label: 'Nome do banco',
            type: 'text'
          }, {
            name: 'bankId',
            label: 'Identificador do banco',
            type: 'int'
          }, {
            name: 'bankAgencyId',
            label: 'Número da agência',
            type: 'int'
          }, {
            name: 'checkNumber',
            label: 'Número do cheque',
            type: 'text'
          }, {
            name: 'checkCount',
            label: 'Quantidade de cheques',
            type: 'int'
          }, {
            name: 'city',
            label: 'Cidade',
            type: 'text'
          }, {
            name: 'federalUnit',
            label: 'Total',
            type: 'text'
          }, {
            name: 'legalSquare',
            label: 'Autoridade emissora/local de emissão',
            type: 'text'
          }]
        }, {
          name: 'summary',
          label: 'summary',
          type: 'object',
          fields: [{
            name: 'balance',
            label: 'Valor total',
            type: 'float'
          }, {
            name: 'count',
            label: 'count',
            type: 'int'
          }, {
            name: 'firstOccurrence',
            label: 'firstOccurrence',
            type: 'text'
          }, {
            name: 'lastOccurrence',
            label: 'lastOccurrence',
            type: 'text'
          }]
        }]
      }, {
        name: 'collectionRecords',
        label: 'Anotações Negativas - Dívidas Vencidas',
        type: 'object',
        fields: [{
          name: 'collectionRecordsResponse',
          label: 'collectionRecordsResponse',
          type: 'objectarray',
          fields: [{
            name: 'amount',
            label: 'Valor da dívida',
            type: 'int'
          }, {
            name: 'city',
            label: 'Cidade',
            type: 'text'
          }, {
            name: 'contractId',
            label: 'ID do contrato',
            type: 'text'
          }, {
            name: 'creditorName',
            label: 'Nome do credor',
            type: 'text'
          }, {
            name: 'federalUnit',
            label: 'Unidade Federal',
            type: 'text'
          }, {
            name: 'legalNature',
            label: 'Natureza jurídica',
            type: 'text'
          }, {
            name: 'legalNatureId',
            label: 'Natureza jurídica Id',
            type: 'text'
          }, {
            name: 'occurrenceDate',
            label: 'Data de ocorrência',
            type: 'date'
          }, {
            name: 'principal',
            label: 'principal',
            type: 'boolean'
          }]
        }, {
          name: 'summary',
          label: 'summary',
          type: 'object',
          fields: [{
            name: 'balance',
            label: 'Valor total de dívidas vencidas',
            type: 'float'
          }, {
            name: 'count',
            label: 'count',
            type: 'int'
          }, {
            name: 'firstOccurrence',
            label: 'firstOccurrence',
            type: 'text'
          }, {
            name: 'lastOccurrence',
            label: 'lastOccurrence',
            type: 'text'
          }]
        }]
      }, {
        name: 'notary',
        label: 'Anotações Negativas – Protestos',
        type: 'object',
        fields: [{
          name: 'notaryResponse',
          label: 'Anotações Negativas – Protestos',
          type: 'objectarray',
          fields: [{
            name: 'occurrenceDate',
            label: 'Data da ocorrência',
            type: 'date'
          }, {
            name: 'amount',
            label: 'Valor da dívida',
            type: 'float'
          }, {
            name: 'city',
            label: 'Cidade',
            type: 'text'
          }, {
            name: 'federalUnit',
            label: 'Estado',
            type: 'text'
          }, {
            name: 'officeNumber',
            label: 'Número do cartório',
            type: 'text'
          }]
        }, {
          name: 'summary',
          label: 'summary',
          type: 'object',
          fields: [{
            name: 'balance',
            label: 'balance',
            type: 'float'
          }, {
            name: 'count',
            label: 'count',
            type: 'int'
          }, {
            name: 'firstOccurrence',
            label: 'firstOccurrence',
            type: 'text'
          }, {
            name: 'lastOccurrence',
            label: 'lastOccurrence',
            type: 'text'
          }]
        }]
      }, {
        name: 'pefin',
        label: 'Anotações Negativas - Pendências Financeiras',
        type: 'object',
        fields: [{
          name: 'pefinResponse',
          label: 'Anotações Negativas - Pendências Financeiras Avançado PF',
          type: 'objectarray',
          fields: [{
            name: 'amount',
            label: 'Valor da dívida',
            type: 'float'
          }, {
            name: 'contractId',
            label: 'Identificador do contrato',
            type: 'text'
          }, {
            name: 'creditorName',
            label: 'Nome do credor',
            type: 'text'
          }, {
            name: 'federalUnit',
            label: 'Estado',
            type: 'text'
          }, {
            name: 'legalNature',
            label: 'Natureza da dívida',
            type: 'text'
          }, {
            name: 'legalNatureId',
            label: 'Identificador da natureza da dívida',
            type: 'text'
          }, {
            name: 'occurrenceDate',
            label: 'Data da ocorrência',
            type: 'date'
          }, {
            name: 'principal',
            label: 'Principal (true) ou Avalista (false)',
            type: 'boolean'
          }]
        }, {
          name: 'summary',
          label: 'summary',
          type: 'object',
          fields: [{
            name: 'balance',
            label: 'Valor total de pendências financeiras',
            type: 'float'
          }, {
            name: 'count',
            label: 'Quantidade total de pendências financeiras',
            type: 'int'
          }, {
            name: 'firstOccurrence',
            label: 'Data da negativação mais recente',
            type: 'date'
          }, {
            name: 'lastOccurrence',
            label: 'Data da negativação mais antiga',
            type: 'date'
          }]
        }]
      }, {
        name: 'refin',
        label: 'Anotações Negativas - Restrições Financeiras',
        type: 'object',
        fields: [{
          name: 'refinResponse',
          label: 'Anotações Negativas - Restrições Financeiras Avançado PF',
          //TODO
          type: 'objectarray',
          fields: [{
            name: 'amount',
            label: 'Valor da dívida',
            type: 'float'
          }, {
            name: 'contractId',
            label: 'Identificador do contrato',
            type: 'text'
          }, {
            name: 'creditorName',
            label: 'Nome do credor',
            type: 'text'
          }, {
            name: 'federalUnit',
            label: 'Estado',
            type: 'text'
          }, {
            name: 'legalNature',
            label: 'Natureza da dívida',
            type: 'text'
          }, {
            name: 'legalNatureId',
            label: 'Identificador da natureza da dívida',
            type: 'text'
          }, {
            name: 'occurrenceDate',
            label: 'Data da ocorrência',
            type: 'date'
          }, {
            name: 'principal',
            label: 'Principal (true) ou Avalista (false)',
            type: 'boolean'
          }]
        }, {
          name: 'summary',
          label: 'summary',
          type: 'object',
          fields: [{
            name: 'balance',
            label: 'Valor total de pendências financeiras',
            type: 'float'
          }, {
            name: 'count',
            label: 'Quantidade total de pendências financeiras',
            type: 'int'
          }, {
            name: 'firstOccurrence',
            label: 'Data da negativação mais recente',
            type: 'text'
          }, {
            name: 'lastOccurrence',
            label: 'Data da negativação mais antiga',
            type: 'text'
          }]
        }]
      }]
    }, {
      name: 'score',
      label: 'Score',
      type: 'object',
      fields: [{
        name: 'score',
        label: 'Pontuação do score',
        type: 'int'
      }, {
        name: 'scoreModel',
        label: 'Modelo do score',
        type: 'text'
      }, {
        name: 'range',
        label: 'Faixa do score',
        type: 'text'
      }, {
        name: 'defaultRate',
        label: 'Probabilidade de Inadimplência',
        type: 'text',
        render: i => i?.defaultRate ? i?.defaultRat + '%' : '-'
      }, {
        name: 'codeMessage',
        label: 'Código da mensagem de score',
        type: 'int'
      }, {
        name: 'message',
        label: 'Mensagem do score',
        type: 'text'
      }]
    }, {
      name: 'partner',
      label: 'Participação societária',
      type: 'object',
      fields: [{
        name: 'partnershipResponse',
        type: 'objectarray',
        fields: [{
          name: 'businessDocument',
          label: 'Documento',
          type: 'text'
        }, {
          name: 'companyName',
          label: 'Nome da empresa',
          type: 'text'
        }, {
          name: 'companyState',
          label: 'Estado',
          type: 'text'
        }, {
          name: 'companyStatus',
          label: 'Status',
          type: 'text'
        }, {
          name: 'companyStatusCode',
          label: 'Código de status',
          type: 'text'
        }, {
          name: 'companyStatusDate',
          label: 'Data do status',
          type: 'date'
        }, {
          name: 'hasNegative',
          label: 'Negativo',
          type: 'boolean'
        }, {
          name: 'participationInitialDate',
          label: 'Participação data inicial',
          type: 'date'
        }, {
          name: 'participationPercentage',
          label: 'Porcentagem de participação',
          type: 'float'
        }, {
          name: 'updateDate',
          label: 'data de atualização',
          type: 'date'
        }]
      }, {
        name: 'summary',
        label: 'summary',
        type: 'object',
        fields: [{
          name: 'balance',
          label: 'balance',
          type: 'float'
        }, {
          name: 'count',
          label: 'count',
          type: 'int'
        }]
      }]
    }, {
      name: 'registration',
      label: 'Dados Cadastrais PF',
      type: 'object',
      fields: [{
        name: 'address',
        label: 'Endereço',
        type: 'object',
        fields: [{
          name: 'addressLine',
          label: 'Endereço',
          type: 'text'
        }, {
          name: 'city',
          label: 'Cidade',
          type: 'text'
        }, {
          name: 'country',
          label: 'País',
          type: 'text'
        }, {
          name: 'district',
          label: 'Bairro',
          type: 'text'
        }, {
          name: 'state',
          label: 'Estado',
          type: 'text'
        }, {
          name: 'zipCode',
          label: 'CEP',
          type: 'text'
        }]
      }, {
        name: 'addresses',
        label: 'Endereços',
        type: 'objectarray',
        fields: [{
          name: 'addressLine',
          label: 'Endereço',
          type: 'text'
        }, {
          name: 'addressNumber',
          label: 'Número',
          type: 'text'
        }, {
          name: 'addressComplement',
          label: 'Complemento',
          type: 'text'
        }, {
          name: 'addressTypeDescription',
          label: 'Endereço Tipo Descrição',
          type: 'text'
        }, {
          name: 'city',
          label: 'Cidade',
          type: 'text'
        }, {
          name: 'country',
          label: 'País',
          type: 'text'
        }, {
          name: 'district',
          label: 'Bairro',
          type: 'text'
        }, {
          name: 'state',
          label: 'Estado',
          type: 'text'
        }, {
          name: 'updateDate',
          label: 'Data de atualização',
          type: 'date'
        }, {
          name: 'zipCode',
          label: 'CEP',
          type: 'text'
        }, {
          name: 'addressTypeCode',
          label: 'Endereço Tipo Código',
          type: 'int',
          renderColumn: false
        }]
      }, {
        name: 'birthDate',
        label: 'Data de nascimento',
        type: 'date'
      }, {
        name: 'consumerGender',
        label: 'Gênero',
        type: 'text'
      }, {
        name: 'consumerName',
        label: 'Nome',
        type: 'text'
      }, {
        name: 'documentNumber',
        label: 'CPF',
        type: 'text',
        mask: '999.999.999-99'
      }, {
        name: 'motherName',
        label: 'Nome da mãe',
        type: 'text'
      }, {
        name: 'phones',
        label: 'Telefones',
        type: 'objectarray',
        fields: [{
          name: 'regionCode',
          label: 'Código da região',
          type: 'int'
        }, {
          name: 'areaCode',
          label: 'Código de área',
          type: 'int'
        }, {
          name: 'phoneNumber',
          label: 'Telefone',
          type: 'int'
        }, {
          name: 'phoneType',
          label: 'Tipo de telefone',
          type: 'text'
        }, {
          name: 'phoneTypeCode',
          label: 'Código do tipo de telefone',
          type: 'int',
          renderColumn: false
        }]
      }, {
        name: 'statusDate',
        label: 'Data de status',
        type: 'date'
      }, {
        name: 'statusRegistration',
        label: 'Status do Documento',
        type: 'text'
      }]
    }, {
      name: 'reportName',
      label: 'reportName',
      type: 'text'
    }]
  }, {
    name: '_reference',
    label: '_reference',
    type: 'text',
    renderForm: false
  }, {
    name: '_referenceUpdateDatetime',
    label: '_referenceUpdateDatetime',
    type: 'text',
    renderForm: false
  }],
  filter: [{
    field: 'id',
    op: 'beginsWith'
  }]
});