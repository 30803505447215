export const COLUMNS_WIDTH = {
  default: {
    normalScreen: '125px',
    smallScreen: '125px'
  },
  id: {
    normalScreen: '90px',
    smallScreen: '90px'
  },
  hotelName: {
    normalScreen: '300px',
    smallScreen: '200px'
  }
};
export const formatDateToLocaleDate = (date: Date) => {
  const localeDate = new Date(date)?.toLocaleString()?.split(' ');
  return localeDate?.[0] || '';
};
export const formatDateToDate = (date: any) => {
  // Date example: "2021-06-30T16:56:02.02Z"
  const dateString = date?.split('T')?.[0] || '';
  const splitedDate = dateString.split('-');
  const day = splitedDate[2];
  const month = splitedDate[1];
  const year = splitedDate[0];
  return `${day}/${month}/${year}` || '';
};
export const formatDateToLocaleTime = (date: Date) => {
  const localeDate = new Date(date)?.toLocaleString()?.split(' ');
  return localeDate?.[1]?.slice(0, 5) || '';
};
export const formatDateToTime = (date: string) => {
  const timeString = date?.split('T')?.[1] || '';
  const splitedTime = timeString.split(':');
  const hour = splitedTime[0];
  const minute = splitedTime[1];
  // const seconds = splitedTime[2]

  return `${hour}:${minute}` || '';
};
export const formatDate = (date: Date) => {
  if (!date) return '';
  const newDate = new Date(date);
  const day = newDate.toLocaleString('default', {
    day: 'numeric'
  });
  const month = newDate.toLocaleString('default', {
    month: 'long'
  })?.slice(0, 3);
  const year = newDate.toLocaleString('default', {
    year: 'numeric'
  });
  return `${day} ${month} ${year}`;
};
export const getDatesDifferenceByDays = (startDate: Date, endDate: Date) => {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);
  const differenceInTime = date2.getTime() - date1.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return differenceInDays;
};
export const getReservationNightsString = (checkin, checkout) => {
  if (!checkin || !checkout) return '';
  const nights = getDatesDifferenceByDays(checkin, checkout);
  const string = `${nights} ${nights > 1 ? "Noites" : "Noite"}`;
  return string;
};
export const getAdultsString = adults => {
  if (!adults) return '';
  const adultsString = adults > 1 || adults === 0 ? "Adultos" : "Adulto";
  return `${adults} ${adultsString}`;
};
export const getChildrenString = children => {
  if (!children) return '';
  const adultsString = children > 1 || children === 0 ? "Crianças" : "Criança";
  return `${children} ${adultsString}`;
};
type FormatCurrencyType = {
  value: number | string;
  languageCode?: string;
  currencyCode?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};
export const formatCurrency = ({
  value,
  languageCode,
  currencyCode,
  minimumFractionDigits,
  maximumFractionDigits
}: FormatCurrencyType) => {
  try {
    const valueNumber = typeof value === 'string' ? Number.parseFloat(value) : value;
    return new Intl.NumberFormat(languageCode || 'pt-BR', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits
    }).format(valueNumber);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    const valueNumber = 0.0;
    return new Intl.NumberFormat(languageCode || 'pt-BR', {
      style: 'currency',
      currency: currencyCode || 'BRL',
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits
    }).format(valueNumber);
  }
};