import { PartialField } from '@niaratech/devcase-crud/dist/fillCrudMetadataGaps';
import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Criação de pessoas',
  labelPlural: 'Criação de pessoas',
  deletable: false,
  name: 'PeopleRule',
  namePlural: 'PeopleRules',
  custom_withAudit: true,
  authorization: authorization('admin', 'admin'),
  dynamoDB: {
    tableName: 'SpearPeopleRule'
  },
  columns: ['id', 'name', 'enabled'],
  fields: ([{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Nome',
    type: 'text',
    required: true
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: true,
    type: 'boolean',
    required: true
  }, {
    name: 'onReservation',
    label: 'Criar Pessoa na Reserva',
    custom_groupName: 'Criação Automática de Pessoas',
    default: true,
    type: 'boolean'
  }, {
    name: 'onQuote',
    label: 'Criar Pessoa na Cotação',
    custom_groupName: 'Criação Automática de Pessoas',
    default: true,
    type: 'boolean'
  }, {
    name: 'onLostReservation',
    label: 'Criar Pessoa com Reservas Perdidas',
    custom_groupName: 'Criação Automática de Pessoas',
    default: false,
    type: 'boolean'
  }, {
    name: 'uniqueKey_cpf',
    custom_groupName: 'Chave Única',
    label: 'CPF',
    default: true,
    type: 'boolean'
  }, {
    name: 'uniqueKey_email',
    custom_groupName: 'Chave Única',
    label: 'E-mail',
    default: true,
    type: 'boolean'
  }, {
    name: 'uniqueKey_phone',
    custom_groupName: 'Chave Única',
    label: 'Telefone',
    default: true,
    type: 'boolean'
  }, {
    name: 'automaticLedgerCreationEnabled',
    custom_groupName: 'Criação Automática de Carteira',
    label: 'Criação automática de carteira',
    default: false,
    type: 'boolean'
  }, {
    name: 'ledgerConfigurations',
    label: 'Configurações de Carteira',
    inputType: 'InputMultiLedgerConfigurationAutoComplete',
    custom_groupName: 'Criação Automática de Carteira',
    type: 'objectarray',
    fields: [{
      name: 'id',
      label: 'ID'
    }, {
      name: 'name',
      label: 'Nome'
    }],
    renderForm: i => i.automaticLedgerCreationEnabled
  }] as Array<PartialField>),
  filter: [{
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq',
    label: 'Ativo'
  }]
});