import type { HotelResult, RoomRate } from '@niarab2c/frontend-commons/src/types/hotels';
import type { RoomOptionsProps } from '@niarab2c/ota-components/src/HotelDetails/RoomOptions/RoomOptions';
import type { RoomTypesGridProps } from '@niarab2c/ota-components/src/HotelDetails/RoomTypesGrid/RoomTypesGrid';
import _groupBy from 'lodash/groupBy';
import _isEqual from 'lodash/isEqual';
import _uniqBy from 'lodash/uniqBy';
import { useMemo } from 'react';
import { useSelector } from '../app/base';
import { getMaxRoomUnits, ShoppingCartState } from '../app/reducers/shoppingCart';
import useShowComissionAndMarkup from './useShowComissionAndMarkup';
import useSignIn from './useSignIn';
interface RoomOptionParams {
  hotelResult: HotelResult;
  selectQuantity?: (roomRates: [RoomRate], hotelResult: HotelResult, quantity: number) => void;
  onCTAOverride?: (roomRates: [RoomRate], hotelResult: HotelResult) => void;
  onModifyClick?: () => void;
}
export const validateAndGenerateSlotTitle = (slots: RoomRate['metasearch']['slots'] = [], selectedSlot: RoomRate['metasearch']['slots'][number]): string | null => {
  return slots?.length > 1 || selectedSlot?.ctaAction != 'ADD_TO_CART' ? selectedSlot?.title : null;
};
const useRoomOptions = ({
  hotelResult,
  selectQuantity,
  onCTAOverride,
  onModifyClick
}: RoomOptionParams): Array<RoomOptionsProps['room']> => {
  const [signIn, signUp] = useSignIn();
  const {
    b2c,
    maxItemCount,
    currentItemCount,
    priceDisplayMode,
    shoppingCartItems
  } = useSelector(s => ({
    b2c: s.authentication.b2c,
    maxItemCount: s.shoppingCart?.maxItemCount ?? 1,
    currentItemCount: s.shoppingCart?.items?.reduce((acc, i) => acc + i.quantity, 0) ?? 0,
    priceDisplayMode: s.hotel?.hotelPostSearchRule?.priceDisplayMode,
    shoppingCartItems: s.shoppingCart?.items
  }));

  // vai ter os quartos disponíveis e não disponíveis
  const roomRatesGrouped = useMemo(() => [...((hotelResult?._roomRates /* _roomRates obedecem ao filtro de quartos */ ?? hotelResult?.roomRates /* inclui quartos não filtrados */) || []), ...(hotelResult?.notAvailableRoomRates || [])]?.map(r => [r]), [hotelResult?._roomRates, hotelResult?.notAvailableRoomRates, hotelResult?.roomRates]);

  // agrupado por tipo de quarto
  const roomTypesGroups = useMemo(() => _groupBy(roomRatesGrouped, g => g[0] && g[0].roomType && g[0].roomType.name), [roomRatesGrouped]);
  const showComissionAndMarkup = useShowComissionAndMarkup();
  const roomTypeNamesAndIds = useMemo(() => {
    const items: Array<{
      id?: number;
      name?: string;
    }> = [];
    Object.keys(roomTypesGroups)?.forEach(roomTypeGroup => {
      if (!roomTypeGroup) {
        return;
      }
      items.push({
        id: roomTypesGroups[roomTypeGroup]?.[0]?.[0]?.roomType?.id,
        name: roomTypeGroup
      });
    });
    return items?.filter(Boolean) ?? [];
  }, [roomTypesGroups]);
  return useMemo<Array<RoomOptionsProps['room']>>(() => roomTypeNamesAndIds.map(roomTypeNameAndId => {
    const guestRoomDetail = hotelResult?._hotelDetails?.guestRooms?.filter(r => r?.id == roomTypeNameAndId?.id || r?.description?.toUpperCase().trim() === roomTypeNameAndId?.name?.toUpperCase().trim())[0];
    const sizeMeasurement = guestRoomDetail?.sizeMeasurement === 'sqm' ? 'm²' : 'pés²';
    const size = guestRoomDetail?.size;
    const roomDescription = guestRoomDetail?.medias?.filter(m => m.code == 1)[0]?.texts?.[0]?.Description;
    const maxOccupancy = typeof guestRoomDetail?.maxOccupancy === 'number' ? guestRoomDetail.maxOccupancy : null;
    const views = guestRoomDetail?.views?.map(view => view?.name).length > 1 ? null : guestRoomDetail?.views?.map(view => view?.name);
    const roomAmenities = guestRoomDetail?.amenities && _uniqBy(guestRoomDetail.amenities, a => a.code);
    const images = [];
    const texts = [];
    if (guestRoomDetail && guestRoomDetail.medias) {
      guestRoomDetail.medias.forEach(m => {
        if (m.texts) texts.splice(texts.length, 0, ...m.texts.map(t => t.Description));
        if (m.images) images.splice(images.length, 0, ...m.images.map(x => x.url));
      });
    }
    const roomRates = roomTypesGroups[roomTypeNameAndId?.name].flat(1);
    const priceComposition = roomRates?.map(i => i?.priceComposition?.nights);
    const rates = roomRates?.map((roomRate: RoomRate): RoomOptionsProps['room']['rates'][number] => {
      let rateAmenities = [];
      if (roomRate?.meal?.breakfast && roomRate?.meal?.lunch && roomRate?.meal?.dinner) {
        rateAmenities.concat('Pensão completa');
      } else if (roomRate?.meal?.breakfast && roomRate?.meal?.lunch || roomRate?.meal?.breakfast && roomRate?.meal?.dinner) {
        rateAmenities.concat('Meia pensão');
      } else if (roomRate?.meal?.breakfast) {
        rateAmenities.concat('Café da manhã');
      }
      rateAmenities = rateAmenities.concat(roomAmenities?.map(a => a));
      const remainingRooms = roomRate?.roomType?.units;
      const currentQuantity = shoppingCartItems?.filter(item => _isEqual(item.roomRateIds, [roomRate.id]))?.reduce((acc, item) => acc + item.quantity, 0) ?? 0;
      const setValueQtdRooms = (quantity: number) => selectQuantity([roomRate], hotelResult, quantity);
      const remainingRoomsSameSupplier = getMaxRoomUnits(roomRate, shoppingCartItems);
      const maxSelectableUnits = Math.min( /* Caso não venha a quantidade de units do fornecedor (ex: Restel), permite adicionar só 1 */
      remainingRooms ?? 1, maxItemCount - currentItemCount + currentQuantity, remainingRoomsSameSupplier);
      const units = maxSelectableUnits ?? 1;
      const slots: RoomTypesGridProps['slots'] = roomRate?.metasearch?.slots.map(s => {
        return {
          ...s,
          highlighted: s.comparison?.price?.value < 0,
          onCTA: s.ctaAction === 'ADD_TO_CART' ? onCTAOverride ? () => {
            onCTAOverride([roomRate], hotelResult);
          } : null : s.ctaAction === 'LOGIN' ? () => signIn?.() : null,
          setValueQtdRooms: s.ctaAction === 'ADD_TO_CART' ? onCTAOverride ? null : setValueQtdRooms : null,
          valueQtdRooms: s.ctaAction === 'ADD_TO_CART' ? currentQuantity : null,
          units: s.ctaAction === 'ADD_TO_CART' ? units : null,
          available: roomRate?.available,
          availabilityRestrictions: roomRate?.availabilityRestrictions,
          roomRate,
          title: validateAndGenerateSlotTitle(roomRate?.metasearch?.slots, s)
        };
      });
      return ({
        hotelName: hotelResult?._hotelByHotelId?.[roomRate.hotelId]?.name,
        id: roomRate.id,
        includedInTheRoom: roomRate?.meal?.name,
        amenities: rateAmenities,
        highlighted: true,
        slots,
        cancelPolicy: roomRate?.cancelPolicy,
        priceComposition: roomRate?.priceComposition,
        offers: roomRate?.offers,
        promotion: roomRate?.promotion,
        loyaltyReward: roomRate?.engineRuleVersion == '1' ? roomRate.loyaltyRewards?.[0] : roomRate?.businessRules?.[0]?.loyaltyRewards?.[0],
        loyaltyRedeem: roomRate.loyaltyRedeems?.[0],
        valueQtdRooms: currentQuantity,
        units,
        paymentOptions: roomRate?.engineRuleVersion == '1' ? roomRate?.paymentOptions : roomRate?.payment?.paymentOptions,
        remainingRooms,
        setValueQtdRooms: !roomRate?.available ? null : onCTAOverride ? null : setValueQtdRooms,
        onCTA: !roomRate?.available ? null : onCTAOverride ? () => {
          onCTAOverride([roomRate], hotelResult);
        } : null,
        availabilityRestrictions: roomRate?.availabilityRestrictions,
        available: roomRate?.available,
        b2c: b2c,
        onModifyClick,
        roomRate: roomRate,
        occupancy: roomRate?.occupancy,
        showComissionAndMarkup,
        credential: roomRate?.credential,
        priceDisplayMode
      } as RoomOptionsProps['room']['rates'][number]);
    });
    return ({
      area: size,
      sizeMeasurement,
      img: images,
      numberPeoples: maxOccupancy,
      roomTitle: roomTypeNameAndId?.name,
      roomDescr: roomDescription,
      rates: rates,
      priceComposition: priceComposition,
      view: views,
      showComissionAndMarkup,
      bedTypes: guestRoomDetail?.bedTypes ?? []
    } as RoomOptionsProps['room']);
  }), [roomTypeNamesAndIds, hotelResult, roomTypesGroups, showComissionAndMarkup, shoppingCartItems, maxItemCount, currentItemCount, onCTAOverride, b2c, onModifyClick, priceDisplayMode, selectQuantity, signIn]);
};
export default useRoomOptions;