const exhibitionChildrensLabel = ({
  childrenAges
}: {
  childrenAges: number[];
}): string => {
  const ages = childrenAges?.map(age => `${age}`).join(', ');
  if (childrenAges?.length > 1) {
    return childrenAges?.filter(age => age > 1)?.length > 0 ? `(${ages} Anos)` : `(${ages} Ano)`;
  } else if (childrenAges?.length === 1) {
    return childrenAges[0] > 1 ? `(${ages} Anos)` : `(${ages} Ano)`;
  } else {
    return '';
  }
};
export default exhibitionChildrensLabel;