import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import user from '../user';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Usuário logado',
  name: 'CurrentUser',
  insertable: false,
  updatable: false,
  deletable: false,
  searchable: false,
  authorization: authorization('unauthenticated', false),
  dynamoDB: {
    tableName: 'SpearUser'
  },
  fields: [...user.fields]
});