import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import flightReservation from '../flightReservation';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Reserva de Voo',
  labelPlural: 'Reservas de Voo',
  name: 'SelfFlightReservation',
  insertable: false,
  updatable: false,
  deletable: false,
  searchable: true,
  authorization: authorization('unauthenticated', false),
  dynamoDB: {
    tableName: 'SpearFlightReservation',
    indexName: 'tenantId-createDate-index',
    indexedProp: 'createDate',
    scanIndexBackwards: true
  },
  fields: [...flightReservation.fields].filter(f => !f['custom:excludeForSelfFlightReservation']),
  getParams: [{
    name: 'email',
    type: 'text'
  }]
});