import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Viajante',
  labelPlural: 'Viajantes',
  name: 'OrderGuest',
  deletable: true,
  authorization: authorization('clientConsultant'),
  columnNames: ['id', 'firstName', 'lastName', 'orderId', 'hotelReservationId', 'hotelReservationLocator'],
  'custom:sortFields': ['firstName'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    render: false
  }, {
    name: 'orderId',
    label: 'Id da reserva para grupos',
    render: false,
    required: true,
    updatable: false
  }, {
    name: 'firstName',
    label: 'Primeiro nome',
    required: true,
    searchableText: true
  }, {
    name: 'lastName',
    label: 'Sobrenome',
    required: false,
    searchableText: true
  }, {
    name: 'idCard',
    label: 'Identificação/ código/ documento',
    required: false
  }, {
    name: 'idCardType',
    label: 'Tipo/emissor da identificação',
    required: false
  }, {
    name: 'primaryGuestId',
    label: 'Viajante primário (família)'
  }, {
    name: 'hotelReservationId',
    label: 'id da reserva'
  }, {
    name: 'hotelReservationLocator',
    label: 'localizador da reserva de hotel'
  }, {
    name: 'hotelReservationHotelName',
    label: 'nome do hotel'
  }, {
    name: 'isCHD',
    label: 'É criança?',
    type: 'boolean'
  }, {
    name: 'age',
    label: 'Idade',
    required: false
  }, {
    name: 'passport',
    label: 'Passaporte',
    required: false
  }, {
    name: 'cpf',
    label: 'CPF',
    required: false
  }, {
    name: 'email',
    label: 'Email',
    required: false
  }, {
    name: 'phone',
    label: 'Telefone/Celular',
    required: false
  }],
  importColumnNames: ['firstName', 'lastName', 'idCard', 'idCardType', 'isCHD', 'age', 'passport', 'cpf', 'email', 'phone'],
  filter: [{
    name: 'orderId',
    field: 'orderId',
    op: 'eq'
  }, {
    name: 'idCard',
    field: 'idCard',
    op: 'eq'
  }]
});