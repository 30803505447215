import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Regra de Metabuscador',
  labelPlural: 'Regras de Metabuscador',
  name: 'MetasearchRule',
  namePlural: 'MetasearchRules',
  custom_withAudit: true,
  authorization: authorization('consultant', 'admin'),
  deletable: false,
  dynamoDB: {
    tableName: 'SpearMetasearchRule'
  },
  columns: ['id', 'name', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Nome da Regra',
    type: 'text',
    required: true
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: false,
    required: true,
    type: 'boolean'
  }, {
    name: 'mainComparison',
    label: 'Tipo de Comparação',
    inputType: 'select',
    required: true,
    options: [{
      label: 'Preço',
      value: 'PRICE'
    }, {
      label: 'Preço sem o resgate máximo',
      value: 'PRICE_MINUS_REDEEM'
    }]
  }, {
    label: 'Lacunas',
    name: 'slots',
    type: 'objectarray',
    graphQLType: '[MetasearchSlot]',
    graphQLInputType: '[MetasearchSlotInput]',
    custom_renderFieldsRecursively: true,
    fields: [{
      name: 'title',
      label: 'Título',
      required: true
    }, {
      name: 'sourceType',
      label: 'Origem do dado',
      required: true,
      inputType: 'select',
      options: [{
        value: 'SEARCH',
        label: 'Resultado da busca'
      }, {
        value: 'MASTER_CREDENTIAL',
        label: 'Comparação Externa'
      }, {
        value: 'ENGINE_RULE',
        label: 'Motor de regras'
      }]
    }, {
      name: 'masterCredentialId',
      label: 'Referência Externa',
      render: i => i.sourceType === 'MASTER_CREDENTIAL'
    }, {
      name: 'engineRuleId',
      label: 'Motor de regras',
      render: i => i.sourceType === 'ENGINE_RULE'
    }, {
      name: 'badgeEnabled',
      label: 'Badge Habilitada',
      type: 'boolean'
    }, {
      name: 'badgeText',
      label: 'Texto da badge'
    }, {
      name: 'badgeComparison',
      label: 'Texto da badge',
      options: [{
        label: 'Economia total',
        value: 'SAVINGS_TOTAL'
      }, {
        label: 'Economia por noite',
        value: 'SAVINGS_PER_NIGHT'
      }, {
        label: 'Percentual',
        value: 'SAVINGS_PERCENTAGE'
      }]
    }, {
      name: 'ctaAction',
      label: 'Ação do call to action',
      options: [{
        label: 'Ocultar',
        value: 'NONE'
      }, {
        label: 'Enviar para login',
        value: 'LOGIN'
      }, {
        label: 'Selecionar',
        value: 'ADD_TO_CART'
      }]
    }, {
      name: 'ctaText',
      label: 'Texto do call to action',
      default: 'Selecionar'
    }, {
      name: 'id',
      //todo: gerar uuid automatico
      label: 'id do slot'
    }, {
      name: 'isReference',
      label: 'Referencia',
      type: 'boolean'
    }, {
      name: 'ltReferenceComparisonAction',
      label: 'Se é menor a referencia',
      options: [{
        label: 'Não fazer nada',
        value: 'NONE'
      }, {
        label: 'Suprimir tarifa',
        value: 'REMOVE_ROOM_RATE'
      }, {
        label: 'Suprimir a referência',
        value: 'REMOVE_REFERENCE'
      }, {
        label: 'Suprimir essa comparação',
        value: 'REMOVE_ME'
      }]
    }, {
      name: 'gtReferenceComparisonAction',
      label: 'Se é maior que referencia',
      options: [{
        label: 'Não fazer nada',
        value: 'NONE'
      }, {
        label: 'Suprimir tarifa',
        value: 'REMOVE_ROOM_RATE'
      }, {
        label: 'Suprimir a referência',
        value: 'REMOVE_REFERENCE'
      }, {
        label: 'Suprimir essa comparação',
        value: 'REMOVE_ME'
      }]
    }, {
      name: 'notFoundAction',
      label: 'Se não tem essa comparação',
      options: [{
        label: 'Não fazer nada',
        value: 'NONE'
      }, {
        label: 'Suprimir tarifa',
        value: 'REMOVE_ROOM_RATE'
      }]
    }, {
      name: 'comparisonRequirements',
      label: 'Requisitos de comparação',
      type: 'textarray',
      options: [{
        label: 'Mesmo id do tipo do quarto',
        value: 'roomType.id'
      }, {
        label: 'Mesmo nome de tipo de regime',
        value: 'meal.name'
      }, {
        label: 'Mesmo id da tarifa',
        value: 'ratePlan.id'
      }, {
        label: 'Não é o mesmo id da tarifa',
        value: '!ratePlan.id'
      }, {
        label: 'Não é o mesmo preço total',
        value: '!priceComposition.total'
      }, {
        label: 'Não é o mesmo preço de diária',
        value: '!priceComposition.productPrice'
      }, {
        label: 'Mesma data de multa de cancelamento',
        value: 'cancelPolicy.penaltyDate'
      }, {
        label: 'Não reembolsável/reembolsável',
        value: 'cancelPolicy.nonRefundable'
      }]
    }]
  }],
  filter: []
});