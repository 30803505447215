import type { HotelDetails, HotelResult, RoomRate } from '@niarab2c/frontend-commons/src/types/hotels'
import type { CurrentClient, CurrentTenant, SelfOrder, ShallowClient } from '@niarab2c/niara-spear-crudmodel/src'
import { Order, SelfHotelReservation } from '@niarab2c/niara-spear-crudmodel/src'
import type { CriteriaForm, PaymentOption } from '@niarab2c/niarab2c-core/src/app/base'
import type { User } from '@niarab2c/niarab2c-core/src/app/reducers/authentication'
import type { ShoppingCartState } from '@niarab2c/niarab2c-core/src/app/reducers/shoppingCart'
import type { PaymentFormProps } from '@niarab2c/ota-components/src/CheckoutForm/PaymentForm'
import type { PaymentFormV2Props } from '@niarab2c/ota-components/src/CheckoutForm/PaymentFormV2'
import { Storefront } from '@niarab2c/otabuilder-crudmodel/src'
import { diffInDays } from '@niaratech/niara-js-commons'
import { facebookTrack } from '@niaratech/niara-react-components/src/metapixel'
import meanBy from 'lodash/meanBy'
import sumBy from 'lodash/sumBy'
import _pick from 'lodash/pick'

const DATA_LAYER_NAME = 'dataLayer'
const dataLayer = (window[DATA_LAYER_NAME] = window[DATA_LAYER_NAME] || [])

function pushToDataLayer(stuff: any): void {
  dataLayer.push(stuff)
}

const catchAnyErrorFunction = function <P extends Array<any>, R>(func: { (...p: P): R }): { (...p: P): R } {
  return (...p) => {
    try {
      return func(...p)
    } catch (ignore) {
      //TODO
      // eslint-disable-next-line no-console
      console.error(ignore)
    }
  }
}

type ListName = 'Search Results' | 'Hotel Details'

// Referências:
// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#view_item
// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_item
// https://support.google.com/analytics/answer/9143382#dimensions
// https://developers.google.com/tag-manager/ecommerce-ga4?hl=pt-br#measure_productitem_list_viewsimpressions

const buildItem = (
  hotelResult: Pick<HotelResult, 'clientId' | '_id' | 'hotel'>,
  roomRate: RoomRate,
  listName?: ListName,
  index?: number
) => {
  if (!hotelResult) return undefined
  const hotel = hotelResult?.hotel
  return {
    item_name: hotel.name,
    item_id: hotel.id,
    price: roomRate ? roomRate?.priceComposition?.productPrice?.value : undefined,
    currency: roomRate ? roomRate?.priceComposition?.productPrice?.currency : undefined,
    item_category: 'Hotel',
    item_variant: roomRate ? roomRate?.time?.startDate : undefined,
    item_list_name: listName,
    index: index,
    quantity: 1,
  }
}

const buildItem2 = (orderItem: Order['items'][0]) => {
  return {
    item_name: orderItem.productName,
    item_id: orderItem.productId,
    price: orderItem.totalPrice,
    currency: orderItem.currencyCode,
    item_category: orderItem.type === 'hotelReservation' ? 'Hotel' : orderItem.type,
    item_variant: orderItem.startDate,
    quantity: 1,
  }
}

type Occupancy = { adults: number; children?: number; childrenAges?: number[] }

type CartItem = {
  occupancy: Occupancy
  roomRate: RoomRate
  roomType: string
  hotelId: string
  quantity: number
  startDate: string
  endDate: string
  hotelDetails: HotelDetails
  priceComposition: Pick<SelfHotelReservation['roomStays'][0]['price'], 'nights' | 'taxes' | 'total'> &
    Pick<RoomRate['priceComposition'], 'discounts'>
}

export const getGA4Session = (streamId: string): { id: number; number: number } => {
  if (!streamId) return null

  const measurementId = streamId.split('-')?.[1]
  if (!measurementId) return null

  const pattern = new RegExp(`_ga_${measurementId}=GS\\d\\.\\d\\.(.+?)(?:;|$)`)
  const match = document.cookie.match(pattern)
  const parts = match?.[1].split('.')

  if (!parts) return null

  const id = parseInt(parts.shift())
  const number = parseInt(parts.shift())

  if (isNaN(id)) {
    return null
  }

  return {
    id,
    number: isNaN(number) ? null : number,
  }
}

export const trackTenantAndClient = catchAnyErrorFunction(
  (currentTenant: CurrentTenant, currentClient: CurrentClient | ShallowClient): void => {
    const tenant = currentTenant
      ? _pick(
          currentTenant,
          'tenantId',
          'id',
          'alias',
          'DevOrHomologOrProd',
          'country_code',
          'isBee2Pay',
          'modules_crs',
          'systemLocale',
          'name'
        )
      : null
    const client = currentClient ? _pick(currentClient, 'brand_name', 'id', 'country_code', 'systemLocale') : null
    pushToDataLayer({
      event: 'set_tenant_and_client',
      tenantId: tenant?.tenantId,
      clientId: client?.id,
      tenant,
      client,
    })
  }
)

export const trackUserId = catchAnyErrorFunction((userId: string, event = 'set_user_id'): void => {
  pushToDataLayer({
    user_id: userId,
    event,
  })
})

export const trackEventHomepage = catchAnyErrorFunction((storefront: Storefront, tenant: CurrentTenant): void => {
  // Event 0 - Landing Page
  // Call this function when a user enters the homepage of a storefront?.

  pushToDataLayer({
    event: 'landingPage',
    clientName: storefront?.clientName, // '{{CLIENT-NAME}}'
    clientProject: storefront?.landingPageId, // '{{CLIENT-PROJECT}}',
    location: tenant?.city, // '{{NAME-CITY}}', ?
    versionBE: 'otabuilder_v1', // '{{VERSION-BE}}',
    ecommerce: {
      // currency
      items:
        storefront?.widgets_homepage
          ?.map((widget) =>
            widget?.content
              ?.filter((c) => c?.hotelId)
              .map((content) => ({
                item_name: content?.title, // '{{HOTEL-NAME}}',
                item_id: content?.hotelId, // '{{HOTEL ID}}',
                location: content?.location, // '{{HOTEL-LOCATION}}',
                // price: '{{PRODUCT-PRICE}}', - não tenho preço nos widgets
                // item_brand: '{{CHAIN-NAME}}', - não tem nos widgets
                list: 'Featured Hotels',
                affiliation: storefront?.name,
              }))
          )
          ?.flat()
          .map((item, index) => ({
            ...item,
            index: index + 1,
          })) ?? [],
    },
  })
})

// Não possui evento da Omnibees
export const trackEventHoteList = catchAnyErrorFunction(
  (results: HotelResult[], searchCriteria: CriteriaForm, user: User, storefront: Storefront): void => {
    // Event 1.1 - Search Results
    //   Call this function when a the Search Page receives new information. This function uses the event
    //   callback datalayer variable to handle navigation after the ecommerce data has been sent
    //   to Google Analytics.
    if (!results?.[0]) return

    const currencyCode = results?.[0]?._bestPriceRoomRate?.priceComposition?.total?.currency

    pushToDataLayer({ ecommerce: null }) // Clear the previous ecommerce object.

    pushToDataLayer({
      event: 'ecommerceSearch', // view_item_list ??
      clientName: storefront?.clientName, // '{{CLIENT-NAME}}'
      clientProject: storefront?.landingPageId, // '{{CLIENT-PROJECT}}',
      location: searchCriteria?.destinationName, // '{{NAME-CITY}}',
      versionBE: 'otabuilder_v1', // '{{VERSION-BE}}',

      check_in: searchCriteria?.startDate, // '{{CHECK-IN}}',
      check_out: searchCriteria?.endDate, // '{{CHECK-OUT}}',
      daysAdvance: diffInDays(searchCriteria?.startDate, new Date()), // {{DAYS-IN-ADVANCE}}

      numberAdults: sumBy(searchCriteria?.rooms, (room) => room.adults), // '{{NUMBER-OF-ADULTS}}',
      numberChildren: sumBy(searchCriteria?.rooms, (room) => room.children), // '{{NUMBER-OF-CHILDREN}}',

      coupon: searchCriteria?.promoCode, // '{{COUPON-CODE}}',
      firstName: user?.firstName, // '{{FIRST-NAME}}',
      lastName: user?.lastName, // '{{LAST-NAME}}',
      userEmail: user?.email, // '{{USER-EMAIL}}',

      eventDetails: {
        category: 'Ecommerce',
        action: 'Resultados de Pesquisa',
      },
      ecommerce: {
        currency: currencyCode,
        items: results.map((hotelResult, i) => ({
          item_id: hotelResult?.hotel?.id, //'{{HOTEL-ID}}',
          item_name: hotelResult?.hotel?.name, // '{{HOTEL-NAME}}',
          item_list_name: 'Search List',
          price: hotelResult?._bestPriceRoomRate?.priceComposition?.total?.value, // '{{PRODUCT-PRICE}}',
          location: hotelResult?.hotel?.cityName, // '{{NAME-CITY}}',
          item_brand: hotelResult?.hotel?.chainName, // '{{CHAIN-NAME}}',
          index: i,
          // chain_id: hotelResult?.hotel?.chainId, // '{{CHAIN-ID}}',
          affiliation: storefront?.name,
        })),
      },
    })

    facebookTrack('ViewContent', {
      content_type: 'view_item_list',
      contents: {
        currencyCode: currencyCode,
        items: results
          ?.map((hotelResult, index) =>
            buildItem(hotelResult, hotelResult?._bestPriceRoomRate, 'Search Results', index)
          )
          .filter(Boolean),
      },
      currency: currencyCode,
    })
  }
)

export const trackEventHotelClick = catchAnyErrorFunction(
  (hotelResult: HotelResult, roomRate: RoomRate, listName?: ListName, index?: number): void => {
    //   Call this function when a user clicks on a product link. This function uses the event
    //   callback datalayer variable to handle navigation after the ecommerce data has been sent
    //   to Google Analytics.
    if (!hotelResult || !roomRate) return

    pushToDataLayer({ ecommerce: null }) // Clear the previous ecommerce object.
    pushToDataLayer({
      event: 'select_item',
      ecommerce: {
        items: [buildItem(hotelResult, roomRate, listName, index)],
      },
    })

    facebookTrack('ViewContent', {
      // content_ids,
      // content_category,
      // content_name: 'select_item',
      content_type: 'select_item',
      contents: {
        items: [buildItem(hotelResult, roomRate, listName, index)],
      },
      // currency,
      // value
    })
  }
)

export const trackEventHotelDetails = catchAnyErrorFunction(
  (hotelResult: HotelResult, searchCriteria: CriteriaForm, user: User, storefront: Storefront): void => {
    // Event 1.2 - Hotel Details
    // Measure a view of product details. This example assumes the detail view occurs on pageload,
    // and also tracks a standard pageview of the details page.
    const { hotel, _bestPriceRoomRate } = hotelResult
    if (!hotel || !_bestPriceRoomRate) return

    // const currencyCode = hotelResult?.[0]?._bestPriceRoomRate?.priceComposition?.total?.currency
    const currencyCode = hotelResult?._bestPriceRoomRate?.priceComposition?.total?.currency
    const numberRooms = searchCriteria?.rooms?.length

    pushToDataLayer({ ecommerce: null }) // Clear the previous ecommerce object.

    pushToDataLayer({
      event: 'ecommerceHotelDetails',
      clientName: storefront?.clientName, // '{{CLIENT-NAME}}'
      clientProject: storefront?.landingPageId, // '{{CLIENT-PROJECT}}',
      location: hotelResult?.hotel?.cityName, // '{{NAME-CITY}}',
      versionBE: 'otabuilder_v1', // '{{VERSION-BE}}',

      hotelName: hotelResult?.hotel?.name, // '{{HOTEL-NAME}}',
      hotelId: hotelResult?.hotel?.id, //'{{HOTEL-ID}}',
      chainName: hotelResult?.hotel?.chainName, // '{{CHAIN-NAME}}',
      chainId: hotelResult?.hotel?.chainId, // '{{CHAIN-ID}}',

      // hotelCategoryName: '', // '{{HOTEL-CATEGORY-NAME}}',
      // hotelCategoryId: '', // '{{HOTEL-CATEGORY-ID}}',
      ratingStars: hotelResult?.hotel?.award, // '{{NUMBER-STAR}}',
      numberRooms, // '{{TOTAL-ROOMS-OF-HOTEL}}',

      check_in: searchCriteria?.startDate, // '{{CHECK-IN}}',
      check_out: searchCriteria?.endDate, // '{{CHECK-OUT}}',
      daysAdvance: diffInDays(searchCriteria?.startDate, new Date()), // {{DAYS-IN-ADVANCE}}

      numberAdults: sumBy(searchCriteria?.rooms, (room) => room.adults), // '{{NUMBER-OF-ADULTS}}',
      numberChildren: sumBy(searchCriteria?.rooms, (room) => room.children), // '{{NUMBER-OF-CHILDREN}}',
      coupon: searchCriteria?.promoCode, // '{{COUPON-CODE}}',

      // firstName: user?.firstName, // '{{FIRST-NAME}}',
      // lastName: user?.lastName, // '{{LAST-NAME}}',
      // userEmail: user?.email, // '{{USER-EMAIL}}',

      eventDetails: {
        category: 'Ecommerce',
        action: 'Resultados de Pesquisa',
      },
      ecommerce: {
        currency: currencyCode,
        items: hotelResult?.roomRates?.map((rate, i) => ({
          item_id: rate?.roomType?.id, // '{{ROOM-ID}}',
          item_name: rate?.roomType?.name, // '{{ROOM-NAME}}',
          item_brand: hotelResult?.hotel?.name, // '{{HOTEL-NAME}}'
          price: rate?.priceComposition?.total?.value, // '{{ROOM-PRICE}}',
          index: i,
          location: hotelResult?.hotel?.cityName, // '{{NAME-CITY}}'
          affiliation: storefront?.name,
          currency: rate?.priceComposition?.total?.currency,
        })),
      },
    })

    facebookTrack('ViewContent', {
      // content_ids,
      // content_category,
      // content_name: 'view_item',
      content_type: 'view_item',
      contents: {
        items: [buildItem(hotelResult, hotelResult?._bestPriceRoomRate, 'Hotel Details', undefined)],
      },
      // currency,
      // value
    })
  }
)

export const trackEventAddItemToCart = catchAnyErrorFunction(
  (hotelResult: HotelResult, roomRates: RoomRate[], storefront?: Storefront): void => {
    // Other Events - Add to Cart
    // Measure adding a product to a shopping cart by using an 'add' actionFieldObject
    // and a list of productFieldObjects.

    const { hotel } = hotelResult
    if (!hotel || !roomRates) return

    pushToDataLayer({ ecommerce: null }) // Clear the previous ecommerce object.

    // Não é necessário colocar esse map para dentro do "items" porque apenas um item entra por vez no carrinho
    roomRates.map((roomRate) => {
      pushToDataLayer({
        event: 'ecommerceAddToCart',
        location: hotelResult?.hotel?.cityName, // '{{NAME-CITY}}',
        // clientName: null, // '{{CLIENT-NAME}}',
        // clientProject: null, // '{{CLIENT-PROJECT}}',
        ecommerce: {
          currency: roomRate?.priceComposition?.productPrice?.currency, //'{{CURRENCY}}',
          value: roomRate?.priceComposition?.productPrice?.value, // (PRICE-ROOM) * (QUANTITY): NO TAXES
          items: [
            {
              item_id: roomRate?.roomType?.id, // {{ ROOM-ID }}
              item_name: roomRate?.roomType?.name, // {{ ROOM-NAME }}
              item_variant: roomRate?.ratePlan?.name, // {{ ROOM-RATE }}
              item_brand: hotel?.name, // {{ HOTEL-NAME }}

              item_list_id: hotel?.chainId, // {{CHAIN-ID}}
              item_list_name: hotel?.chainName, // {{CHAIN-NAME}}
              check_in: roomRate?.time?.startDate, // {{ CHECK-IN }}
              check_out: roomRate?.time?.endDate, // {{ CHECK-OUT }}

              price: meanBy(roomRate?.priceComposition?.nights, (night) => night.value), // DAILY MAIN PRICE
              discount: roomRate?.priceComposition?.discounts?.value, // {{ DISCOUNT-VALUE}}
              coupon: roomRate?.promoCode, // "{{COUPON-CODE}}",
              quantity: roomRate?.priceComposition?.nights?.length, // NUMBER OF NIGHTS BOOKED

              item_category: 'ROOM', // ROOM OR EXTRA
              item_category2: hotel?.award, // {{N-STAR_HOTEL}}
              item_category3: '', // {{HOTEL-CATEGORY}}

              affiliation: storefront?.name, //TODO
              currency: roomRate?.priceComposition?.total?.currency,
            },
          ],
        },
      })

      facebookTrack('AddToCart', {
        // content_ids,
        // content_name: 'view_item',
        content_type: 'add_to_cart',
        contents: {
          currency: roomRate?.priceComposition?.total.currency,
          value: roomRate?.priceComposition?.total.value,
          items: [buildItem(hotelResult, roomRate, 'Hotel Details', undefined)],
        },
        currency: roomRate?.priceComposition?.total.currency,
        value: roomRate?.priceComposition?.total.value,
      })
    })
  }
)

// Não tem DataLayer da Omnibees especificado
export const trackEventRemoveItemFromCart = catchAnyErrorFunction(
  (hotelResult: Pick<HotelResult, 'clientId' | '_id' | 'hotel'>, roomRate: RoomRate, storefront: Storefront): void => {
    // Other Events - Remove from Cart
    // Measure adding a product to a shopping cart by using an 'add' actionFieldObject
    // and a list of productFieldObjects.
    const { hotel } = hotelResult
    if (!hotel || !roomRate) return

    pushToDataLayer({ ecommerce: null }) // Clear the previous ecommerce object.
    pushToDataLayer({
      event: 'ecommerceRemoveFromCart',
      location: hotelResult?.hotel?.cityName, // '{{NAME-CITY}}',
      // clientName: null, // '{{CLIENT-NAME}}',
      // clientProject: null, // '{{CLIENT-PROJECT}}',
      ecommerce: {
        currency: roomRate?.priceComposition?.productPrice?.currency, //'{{CURRENCY}}',
        value: roomRate?.priceComposition?.productPrice?.value, // (PRICE-ROOM) * (QUANTITY): NO TAXES
        items: [
          {
            item_id: roomRate?.roomType?.id, // {{ ROOM-ID }}
            item_name: roomRate?.roomType?.name, // {{ ROOM-NAME }}
            item_variant: roomRate?.ratePlan?.name, // {{ ROOM-RATE }}
            item_brand: hotel?.name, // {{ HOTEL-NAME }}

            item_list_id: hotel?.chainId, // {{CHAIN-ID}}
            item_list_name: hotel?.chainName, // {{CHAIN-NAME}}
            check_in: roomRate?.time?.startDate, // {{ CHECK-IN }}
            check_out: roomRate?.time?.endDate, // {{ CHECK-OUT }}

            price: meanBy(roomRate?.priceComposition?.nights, (night) => night.value), // DAILY MAIN PRICE
            discount: roomRate?.priceComposition?.discounts?.value, // {{ DISCOUNT-VALUE}}
            coupon: roomRate?.promoCode, // "{{COUPON-CODE}}",
            quantity: roomRate?.priceComposition?.nights?.length, // NUMBER OF NIGHTS BOOKED

            item_category: 'ROOM', // ROOM OR EXTRA
            item_category2: hotel?.award, // {{N-STAR_HOTEL}}
            item_category3: '', // {{HOTEL-CATEGORY}}

            affiliation: storefront?.name, // TODO
            currency: roomRate?.priceComposition?.total?.currency,
          },
        ],
      },
    })

    facebookTrack('AddToCart', {
      // content_ids,
      // content_name: 'view_item',
      content_type: 'remove_from_cart',
      contents: {
        currency: roomRate?.priceComposition?.total.currency,
        value: roomRate?.priceComposition?.total.value,
        items: [buildItem(hotelResult, roomRate, 'Hotel Details', undefined)],
      },
      currency: roomRate?.priceComposition?.total.currency,
      value: roomRate?.priceComposition?.total.value,
    })
  }
)

export const trackEventNotFoundInRefreshCart = catchAnyErrorFunction(
  (
    hotelResult: Pick<HotelResult, 'clientId' | '_id' | 'hotel'>,
    roomRates: RoomRate[],
    storefront?: Storefront
  ): void => {
    // Other Events - Not found in refresh cart
    // Quando um item do carrinho perde a disponibilidade dentro do checkout ao executar o Refresh Cart

    const { hotel } = hotelResult
    if (!hotel || !roomRates) return

    pushToDataLayer({ ecommerce: null }) // Clear the previous ecommerce object.

    // Não é necessário colocar esse map para dentro do "items" porque apenas um item entra por vez no carrinho
    roomRates.map((roomRate) => {
      pushToDataLayer({
        event: 'notFoundInRefreshCart',
        location: hotelResult?.hotel?.cityName, // '{{NAME-CITY}}',
        // clientName: null, // '{{CLIENT-NAME}}',
        // clientProject: null, // '{{CLIENT-PROJECT}}',
        ecommerce: {
          currency: roomRate?.priceComposition?.productPrice?.currency, //'{{CURRENCY}}',
          value: roomRate?.priceComposition?.productPrice?.value, // (PRICE-ROOM) * (QUANTITY): NO TAXES
          items: [
            {
              item_id: roomRate?.roomType?.id, // {{ ROOM-ID }}
              item_name: roomRate?.roomType?.name, // {{ ROOM-NAME }}
              item_variant: roomRate?.ratePlan?.name, // {{ ROOM-RATE }}
              item_brand: hotel?.name, // {{ HOTEL-NAME }}

              item_list_id: hotel?.chainId, // {{CHAIN-ID}}
              item_list_name: hotel?.chainName, // {{CHAIN-NAME}}
              check_in: roomRate?.time?.startDate, // {{ CHECK-IN }}
              check_out: roomRate?.time?.endDate, // {{ CHECK-OUT }}

              price: meanBy(roomRate?.priceComposition?.nights, (night) => night.value), // DAILY MAIN PRICE
              discount: roomRate?.priceComposition?.discounts?.value, // {{ DISCOUNT-VALUE}}
              coupon: roomRate?.promoCode, // "{{COUPON-CODE}}",
              quantity: roomRate?.priceComposition?.nights?.length, // NUMBER OF NIGHTS BOOKED

              item_category: 'ROOM', // ROOM OR EXTRA
              item_category2: hotel?.award, // {{N-STAR_HOTEL}}
              item_category3: '', // {{HOTEL-CATEGORY}}

              affiliation: storefront?.name, //TODO
              currency: roomRate?.priceComposition?.total?.currency,
            },
          ],
        },
      })

      facebookTrack('notFoundInRefreshCart', {
        // content_ids,
        // content_name: 'view_item',
        content_type: 'notFoundInRefreshCart',
        contents: {
          currency: roomRate?.priceComposition?.total.currency,
          value: roomRate?.priceComposition?.total.value,
          items: [buildItem(hotelResult, roomRate, 'Hotel Details', undefined)],
        },
        currency: roomRate?.priceComposition?.total.currency,
        value: roomRate?.priceComposition?.total.value,
      })
    })
  }
)

export const trackEventBeginCheckout = catchAnyErrorFunction(
  (shoppingCart: ShoppingCartState, storefront: Storefront): void => {
    // Event 2 - Checkout - Page Load
    // Call this function when user begins checkout

    if (!shoppingCart || !shoppingCart.total || !(shoppingCart.items?.length > 0)) return
    const currency = shoppingCart.total.currency
    const value = shoppingCart.total.value
    const numberRooms = shoppingCart?.items?.reduce((acc, item) => acc + item.quantity ?? 1, 0)

    pushToDataLayer({ ecommerce: null }) // Clear the previous ecommerce object.

    pushToDataLayer({
      event: 'ecommerceCheckout',
      clientName: storefront?.clientName, // '{{CLIENT-NAME}}'
      clientProject: storefront?.landingPageId, // '{{CLIENT-PROJECT}}',
      location: shoppingCart?.items[0]?.hotelResult?.hotel?.cityName, // '{{NAME-CITY}}' FROM THE FIRST CART ITEM,
      versionBE: 'otabuilder_v1', // '{{VERSION-BE}}',

      hotelName: shoppingCart?.items[0]?.hotelResult?.hotel?.name, // '{{HOTEL-NAME}}' FROM THE FIRST CART ITEM
      hotelId: shoppingCart?.items[0]?.hotelResult?._id, // '{{HOTEL-ID}}' FROM THE FIRST CART ITEM
      chainName: shoppingCart?.items[0]?.hotelResult?.hotel?.chainName, // '{{CHAIN-NAME}}'FROM THE FIRST CART ITEM
      chainId: shoppingCart?.items[0]?.hotelResult?.hotel?.chainId, // '{{CHAIN-ID}}' FROM THE FIRST CART ITEM

      // hotelCategoryName: '', // '{{HOTEL-CATEGORY-NAME}}',
      // hotelCategoryId: '', // '{{HOTEL-CATEGORY-ID}}',
      // ratingStars: shoppingCart?.items[0]?.hotelResult?.hotel?.award, // '{{NUMBER-STAR}}' FROM THE FIRST CART ITEM
      numberRooms, // NUMBER OF AVAILABLE ROOMS FROM THE FIRST CART ITEM

      check_in: shoppingCart?.items[0]?.startDate, // '{{CHECK-IN}}' FROM THE FIRST CART ITEM
      check_out: shoppingCart?.items[0]?.endDate, // '{{CHECK-OUT}}' FROM THE FIRST CART ITEM
      daysAdvance: diffInDays(shoppingCart?.items[0]?.startDate, new Date()), // {{DAYS-IN-ADVANCE}}

      numberAdults: sumBy(shoppingCart?.items, (cartItem) => cartItem.occupancy?.adults), // '{{NUMBER-OF-ADULTS}}' TOTAL
      numberChildren: sumBy(shoppingCart?.items, (cartItem) => cartItem.occupancy?.children), // '{{NUMBER-OF-CHILDREN}}' TOTAL
      coupon: shoppingCart?.items[0]?.promoCode, // '{{COUPON-CODE}}' FROM THE FIRST CART ITEM

      // firstName: shoppingCart.personalData?.buyer?.firstName, // '{{FIRST-NAME}}',
      // lastName: shoppingCart.personalData?.buyer?.lastName, // '{{LAST-NAME}}',
      // userEmail: shoppingCart.personalData?.buyer?.email, // '{{USER-EMAIL}}',

      eventDetails: {
        category: 'Ecommerce',
        action: 'Checkout',
        label: 'Passo 1 - Dados Pessoais',
      },
      ecommerce: {
        currency: currency,
        items: shoppingCart.items?.map((cartItem, i) => ({
          item_id: cartItem.roomRates[0]?.roomType?.id, // '{{ROOM-ID}}',
          item_name: cartItem.roomRates[0]?.roomType?.name, // '{{ROOM-NAME}}',
          item_variant: cartItem.roomRates[0]?.ratePlan?.name, // {{RATE-ROOM}}
          item_brand: cartItem.hotelResult?.hotel?.name, // '{{HOTEL-NAME}}',

          item_list_id: cartItem?.hotelResult?.hotel?.chainId, // {{CHAIN-ID}}
          item_list_name: cartItem?.hotelResult?.hotel?.chainName, // {{CHAIN-NAME}}
          check_in: cartItem?.startDate, // {{CHECK-IN}}
          check_out: cartItem?.endDate, // {{CHECK-OUT}}

          item_category: 'ROOM', // ROOM OR EXTRA
          item_category2: cartItem.hotelResult?.hotel?.award, // {{N-STAR_HOTEL}}
          item_category3: cartItem.type, // {{HOTEL-CATEGORY}}

          location: cartItem.hotelResult?.hotel?.cityName, // '{{NAME-CITY}}'

          price: meanBy(cartItem.roomRates[0]?.priceComposition?.nights, (night) => night.value), // {{PRICE-ROOM}}, DAILY MEAN PRICE
          quantity: cartItem.roomRates[0]?.priceComposition?.nights?.length, //NUMBER OF NIGHTS BOOKED
          coupon: cartItem.promoCode, // '{{COUPON-CODE}}',
          discount: cartItem.roomRates[0]?.priceComposition?.discounts?.value, //{{ DISCOUNT-VALUE}}

          index: i,
          affiliation: storefront?.name,
          currency: cartItem.roomRates[0]?.priceComposition?.discounts?.currency,
        })),
      },
    })

    facebookTrack('InitiateCheckout', {
      // content_category,
      // num_items,
      // content_ids,
      // content_name: 'view_item',
      contents: {
        currency: currency,
        value: value,
        // coupon: 'SUMMER_FUN', // TODO!
        items: shoppingCart.items
          .map((item) => {
            if (item.hotelResult && item.roomRates?.[0])
              return buildItem(item.hotelResult, item.roomRates[0], undefined, undefined)
          })
          .filter(Boolean),
      },
      currency: currency,
      value: value,
    })
  }
)

export const trackEventAddPaymentInfo = catchAnyErrorFunction(
  (cartItems: CartItem[], clientName: string, landingPageId: string, storefront: Storefront): void => {
    // Event 3 - Checkout - Payment
    // Call this function when user adds payment info

    if (!cartItems || !(cartItems?.length > 0)) return
    const numberRooms = cartItems?.reduce((acc, item) => acc + item.quantity ?? 1, 0)

    pushToDataLayer({ ecommerce: null }) // Clear the previous ecommerce object.

    pushToDataLayer({
      event: 'ecommerceCheckout',
      clientName, // '{{CLIENT-NAME}}'
      clientProject: landingPageId, // '{{CLIENT-PROJECT}}',
      location: cartItems[0]?.hotelDetails?.address?.cityName, // '{{NAME-CITY}}' FROM THE FIRST CART ITEM,
      versionBE: 'otabuilder_v1', // '{{VERSION-BE}}',

      hotelName: cartItems[0]?.hotelDetails?.name, // '{{HOTEL-NAME}}' FROM THE FIRST CART ITEM
      hotelId: cartItems[0]?.hotelId, // '{{HOTEL-ID}}' FROM THE FIRST CART ITEM
      chainName: cartItems[0]?.hotelDetails?.chainName, // '{{CHAIN-NAME}}'FROM THE FIRST CART ITEM
      chainId: cartItems[0]?.hotelDetails?.chainId, // '{{CHAIN-ID}}' FROM THE FIRST CART ITEM

      // hotelCategoryName: '', // '{{HOTEL-CATEGORY-NAME}}',
      // hotelCategoryId: '', // '{{HOTEL-CATEGORY-ID}}',
      // ratingStars: shoppingCart?.items[0]?.hotelResult?.hotel?.award, // '{{NUMBER-STAR}}' FROM THE FIRST CART ITEM
      numberRooms, // NUMBER OF AVAILABLE ROOMS FROM THE FIRST CART ITEM

      check_in: cartItems[0]?.startDate, // '{{CHECK-IN}}' FROM THE FIRST CART ITEM
      check_out: cartItems[0]?.endDate, // '{{CHECK-OUT}}' FROM THE FIRST CART ITEM
      daysAdvance: diffInDays(cartItems[0]?.startDate, new Date()), // {{DAYS-IN-ADVANCE}}

      numberAdults: sumBy(cartItems, (cartItem) => cartItem.occupancy?.adults), // '{{NUMBER-OF-ADULTS}}' TOTAL
      numberChildren: sumBy(cartItems, (cartItem) => cartItem.occupancy?.children), // '{{NUMBER-OF-CHILDREN}}' TOTAL
      coupon: '', // '{{COUPON-CODE}}' FROM THE FIRST CART ITEM

      eventDetails: {
        category: 'Ecommerce',
        action: 'Checkout',
        label: 'Passo 2 - Pagamento',
      },
      ecommerce: {
        currency: cartItems[0]?.priceComposition?.total?.currency,
        items: cartItems?.map((cartItem, i) => ({
          item_id: cartItem.roomRate?.roomType?.id, // '{{ROOM-ID}}',
          item_name: cartItem.roomRate?.roomType?.name, // '{{ROOM-NAME}}',
          item_variant: cartItem.roomRate?.ratePlan?.name, // {{RATE-ROOM}}
          item_brand: cartItem.hotelDetails?.name, // '{{HOTEL-NAME}}',

          item_list_id: cartItem?.hotelDetails?.chainId, // {{CHAIN-ID}}
          item_list_name: cartItem?.hotelDetails?.chainName, // {{CHAIN-NAME}}
          check_in: cartItem?.startDate, // {{CHECK-IN}}
          check_out: cartItem?.endDate, // {{CHECK-OUT}}

          item_category: 'ROOM', // ROOM OR EXTRA
          item_category2: cartItem.hotelDetails?.rating, // {{N-STAR_HOTEL}}
          item_category3: '', // {{HOTEL-CATEGORY}}

          location: cartItem.hotelDetails?.address?.cityName, // '{{NAME-CITY}}'

          price: meanBy(cartItem.priceComposition?.nights, (night) => night.value), // {{PRICE-ROOM}}, DAILY MEAN PRICE
          quantity: cartItem.priceComposition?.nights?.length, //NUMBER OF NIGHTS BOOKED
          coupon: '', // '{{COUPON-CODE}}',
          discount: cartItem.priceComposition?.discounts?.value, //{{ DISCOUNT-VALUE}}

          index: i,
          currency: cartItem.priceComposition?.discounts?.currency,
          affiliation: storefront?.name,
        })),
      },
    })

    facebookTrack('AddPaymentInfo', {
      // content_category,
      // content_ids,
      contents: {
        currency: cartItems[0]?.priceComposition?.total?.currency,
        value: cartItems.reduce((sum, cartItem) => cartItem.priceComposition?.total?.value, 0),
        payment_type: '',
        // coupon: 'SUMMER_FUN', // TODO!
        items: cartItems
          .map((item, i) => {
            if (item.hotelDetails && item.roomRate)
              return {
                item_name: item.hotelDetails?.name,
                item_id: item.hotelId,
                price: item.roomRate ? item.roomRate?.priceComposition?.productPrice?.value : undefined,
                currency: item.roomRate ? item.roomRate?.priceComposition?.productPrice?.currency : undefined,
                item_category: 'Hotel',
                item_variant: item.roomRate ? item.roomRate?.time?.startDate : undefined,
                item_list_name: undefined,
                index: i,
                quantity: 1,
              }
          })
          .filter(Boolean),
      },
      currency: cartItems[0]?.priceComposition?.total?.currency,
      value: cartItems.reduce((sum, cartItem) => cartItem.priceComposition?.total?.value, 0),
    })
  }
)

type PaymentSubmitData = Parameters<PaymentFormProps<PaymentOption>['onSubmit']>[0]
type PaymentSubmitDataV2 = Parameters<PaymentFormV2Props['onSubmit']>[0]

export const trackEventPurchaseClick = catchAnyErrorFunction(
  (
    paymentData: PaymentSubmitData | PaymentSubmitDataV2,
    shoppingCart: ShoppingCartState,
    storefront: Storefront
  ): void => {
    // Event 4 - Checkout - Click to Confirm
    // Call this function when user clica em comprar

    pushToDataLayer({
      event: 'ecommerceCheckout',
      clientName: storefront?.clientName, // '{{CLIENT-NAME}}'
      clientProject: storefront?.landingPageId, // '{{CLIENT-PROJECT}}',
      location: shoppingCart?.items[0]?.hotelResult?.hotel?.cityName, // '{{NAME-CITY}}' FROM THE FIRST CART ITEM,
      versionBE: 'otabuilder_v1', // '{{VERSION-BE}}',

      hotelName: shoppingCart?.items[0]?.hotelResult?.hotel?.name, // '{{HOTEL-NAME}}' FROM THE FIRST CART ITEM
      hotelId: shoppingCart?.items[0]?.hotelResult?._id, // '{{HOTEL-ID}}' FROM THE FIRST CART ITEM
      chainName: shoppingCart?.items[0]?.hotelResult?.hotel?.chainName, // '{{CHAIN-NAME}}'FROM THE FIRST CART ITEM
      chainId: shoppingCart?.items[0]?.hotelResult?.hotel?.chainId, // '{{CHAIN-ID}}' FROM THE FIRST CART ITEM

      check_in: shoppingCart?.items[0]?.startDate, // '{{CHECK-IN}}' FROM THE FIRST CART ITEM
      check_out: shoppingCart?.items[0]?.endDate, // '{{CHECK-OUT}}' FROM THE FIRST CART ITEM
      daysAdvance: diffInDays(shoppingCart?.items[0]?.startDate, new Date()), // {{DAYS-IN-ADVANCE}}

      numberAdults: sumBy(shoppingCart?.items, (cartItem) => cartItem.occupancy?.adults), // '{{NUMBER-OF-ADULTS}}' TOTAL
      numberChildren: sumBy(shoppingCart?.items, (cartItem) => cartItem.occupancy?.children), // '{{NUMBER-OF-CHILDREN}}' TOTAL
      coupon: shoppingCart?.items[0]?.promoCode, // '{{COUPON-CODE}}' FROM THE FIRST CART ITEM

      eventDetails: {
        category: 'Ecommerce',
        action: 'Checkout',
        label: 'Passo 3 - Clique - Confirmar Reserva',
      },

      ecommerce: {
        payment_type:
          'payments' in paymentData
            ? paymentData?.payments?.[0]?.paymentOption?.alias
            : paymentData?.paymentOption?.alias, // '{{PAYMENT-METHOD}}',
        currency: shoppingCart.total.currency, // CURRENCY
        value: shoppingCart?.total?.value, // '{{TRANSACTION-TOTAL}}',
        coupon: shoppingCart?.items[0]?.promoCode, //  '{{PROMO-CODE}}',
        items: shoppingCart.items?.map((cartItem, i) => ({
          item_id: cartItem.roomRates[0]?.roomType?.id, // '{{ROOM-ID}}',
          item_name: cartItem.roomRates[0]?.roomType?.name, // '{{ROOM-NAME}}',
          item_variant: cartItem.roomRates[0]?.ratePlan?.name, // {{RATE-ROOM}}
          item_brand: cartItem.hotelResult?.hotel?.name, // '{{HOTEL-NAME}}',

          item_list_id: cartItem?.hotelResult?.hotel?.chainId, // {{CHAIN-ID}}
          item_list_name: cartItem?.hotelResult?.hotel?.chainName, // {{CHAIN-NAME}}
          check_in: cartItem?.startDate, // {{CHECK-IN}}
          check_out: cartItem?.endDate, // {{CHECK-OUT}}

          item_category: 'ROOM', // ROOM OR EXTRA
          item_category2: cartItem.hotelResult?.hotel?.award, // {{N-STAR_HOTEL}}
          item_category3: cartItem.type, // {{HOTEL-CATEGORY}}

          location: cartItem.hotelResult?.hotel?.cityName, // '{{NAME-CITY}}'

          price: meanBy(cartItem.roomRates[0]?.priceComposition?.nights, (night) => night.value), // {{PRICE-ROOM}}, DAILY MEAN PRICE
          quantity: cartItem.roomRates[0]?.priceComposition?.nights?.length, //NUMBER OF NIGHTS BOOKED
          coupon: cartItem.promoCode, // '{{COUPON-CODE}}',
          discount: cartItem.roomRates[0]?.priceComposition?.discounts?.value, //{{ DISCOUNT-VALUE}}

          index: i,
          affiliation: storefront?.name,
          currency: cartItem.roomRates[0]?.priceComposition?.discounts?.currency,
        })),
      },
    })
  }
)

export const trackEventPurchaseSuccess = catchAnyErrorFunction(
  (
    order: Order | SelfOrder,
    hotelReservations: SelfHotelReservation[],
    hotelDetails: HotelDetails,
    storefront: Storefront
  ): void => {
    // Event 5 - Success Booking
    // Call this function when purchase is succeded

    const currency = order?.hotelReservations?.[0]?.currencyCode
    const value = order?.hotelReservations?.reduce((acc, item) => acc + (item.totalPrice || 0), 0)

    pushToDataLayer({ ecommerce: null }) // Clear the previous ecommerce object.

    pushToDataLayer({
      event: 'ecommercePurchase',
      clientName: storefront?.clientName, // '{{CLIENT-NAME}}'
      clientProject: storefront?.landingPageId, // '{{CLIENT-PROJECT}}',
      location: hotelReservations[0]?.hotelCityName, // '{{NAME-CITY}}', FROM FIRST RESERVATION
      versionBE: 'otabuilder_v1', // '{{VERSION-BE}}',
      userEmail: order?.buyer_email, // '{{USER-EMAIL}}',

      hotelName: hotelReservations[0]?.hotelName, // '{{HOTEL-NAME}}', FROM FIRST RESERVATION
      hotelId: hotelReservations[0]?.hotelId, // '{{HOTEL-ID}}', FROM FIRST RESERVATION
      chainName: hotelDetails?.chainName, // '{{CHAIN-NAME}}',
      chainId: hotelDetails?.chainId, // '{{CHAIN-ID}}',

      check_in: hotelReservations[0]?.startDate, // '{{CHECK-IN}}', FROM FIRST RESERVATION
      check_out: hotelReservations[0]?.endDate, // '{{CHECK-OUT}}', FROM FIRST RESERVATION
      daysAdvance: diffInDays(hotelReservations[0]?.startDate, new Date()), // {{DAYS-IN-ADVANCE}}

      numberAdults: sumBy(hotelReservations, (reservation) => reservation.roomStays?.[0]?.occupancy?.adults), // '{{NUMBER-OF-ADULTS}}', TOTAL
      numberChildren: sumBy(hotelReservations, (reservation) => reservation.roomStays?.[0]?.occupancy?.children), // '{{NUMBER-OF-CHILDREN}}', TOTAL

      // firstName: order?.buyer_name.split(' ')[0], // '{{FIRST-NAME}}',
      // lastName: order?.buyer_name.split(' ').slice(1).join(' '), // '{{LAST-NAME}}',
      // userDoc: order?.buyer_cpf, // '{{USER-DOC}}',
      // userTel: order?.buyer_phone, //  '{{USER-TEL}}',
      // userCountry: order?.buyer_country, // '{{USER-COUNTRY}}',

      eventDetails: {
        category: 'Ecommerce',
        action: 'Purchase',
      },
      ecommerce: {
        value: hotelReservations?.reduce((sum, reservation) => sum + reservation?.totalPrice, 0), // {{TOTAL}},
        payment_type: (order as SelfOrder)?.payments?.[0]?.type, // '{{PAYMENT-METHOD}}',
        transaction_id: order?.id, // '{{RESERVATION-ID}}',
        tax: hotelReservations?.reduce((sum, reservation) => sum + reservation?.totalTaxesPrice, 0), // {{TAX}},
        currency: hotelReservations[0]?.currencyCode, // '{{CURRENCY-CODE}}',
        items: hotelReservations?.map((reservation, i) => ({
          item_id: reservation.roomStays[0]?.roomIdOnSource, // '{{ROOM-ID}}',
          item_name: reservation.roomStays[0]?.roomDescription, //'{{ROOM-NAME}}',
          item_variant: '', // {{ROOM-RATE}}
          item_brand: reservation.hotelName, // '{{HOTEL-NAME}}', PODE SER QUE NÃO SEJA DO HOTEL DESSA RESERVA, TERIA QUE TER NO RESERVATION

          item_list_id: hotelDetails?.chainId, // {{CHAIN-ID}}
          item_list_name: hotelDetails?.chainName, // {{CHAIN-NAME}}
          check_in: reservation.startDate, // {{CHECK-IN}}
          check_out: reservation.endDate, // {{CHECK-OUT}}

          item_category: 'ROOM',
          item_category2: hotelDetails?.rating, // {{N-STAR_HOTEL}}
          item_category3: '', // {{HOTEL-CATEGORY}}

          location: reservation.hotelCityName, // '{{NAME-CITY}}'

          price: meanBy(reservation.roomStays[0]?.price?.nights, (night) => night.value), // {{PRICE-ROOM}}, DAILY MEAN PRICE
          quantity: reservation.roomStays[0]?.price?.nights?.length, // NUMBER OF NIGHTS BOOKED
          coupon: reservation.promoCode,

          index: i,
          affiliation: storefront?.name,
          currency: reservation.roomStays[0]?.price?.total?.currency,
        })),
      },
    })

    facebookTrack('Purchase', {
      content_type: 'purchase',
      contents: {
        transaction_id: order?.id,
        value,
        currency,
        items: order?.hotelReservations?.map((item) => buildItem2(item)).filter(Boolean),
      },
      currency: currency,
      value: value,
    })
  }
)

export const trackEventError = catchAnyErrorFunction(
  (errorId: string, message: string, step: string, storefront: Storefront): void => {
    // Other events - Error Events
    // Call this function when an error occurs on checkout page

    pushToDataLayer({
      event: 'exception',
      clientName: storefront?.clientName, // '{{CLIENT-NAME}}'
      clientProject: storefront?.landingPageId, // '{{CLIENT-PROJECT}}',
      location: '', // '{{NAME-CITY}}',
      versionBE: 'otabuilder_v1', // '{{VERSION-BE}}',
      eventDetails: {
        id: errorId, // '{{ERROR-ID}}',
        category: 'Ecommerce',
        step: step, // '{{ERROR-STEP-PAGE}}',
        description: message, // '{{ERROR-DESCRIPTION}}',
        fatal: false, // {{TRUE/FALSE}}
      },
    })
  }
)
