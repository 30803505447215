import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Produto',
  labelPlural: 'Produtos',
  name: 'Product',
  insertable: true,
  updatable: true,
  deletable: false,
  authorization: authorization('consultant', 'admin'),
  columnNames: ['id', 'name', 'tags', 'externalCode', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    render: false
  }, {
    name: 'name',
    label: 'Nome',
    insertable: false,
    updatable: false
  }, {
    name: 'product',
    label: 'Produto',
    type: 'object',
    inputType: 'inputHotel',
    graphQLType: 'NestedProduct',
    graphQLInputType: 'NestedProductInput',
    updatable: false,
    fields: [{
      name: 'id',
      label: 'Id'
    }, {
      name: 'name',
      label: 'Nome'
    }, {
      name: 'type',
      label: 'Tipo'
    }, {
      name: 'city',
      label: 'Cidade'
    }, {
      name: 'state',
      label: 'Estado'
    }, {
      name: 'country',
      label: 'País'
    }]
  }, {
    name: 'externalCode',
    label: 'Código externo'
  }, {
    name: 'tags',
    label: 'Marcadores',
    type: 'textarray',
    default: null,
    custom_withNullSelector: true,
    custom_nullSelectorLabel: 'Nenhum marcador',
    custom_nullSelectorDeselectValue: []
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: true,
    type: 'boolean'
  }],
  filter: []
});