export const STANDARD_HTML_ATTRIBUTES = (['accept', 'acceptCharset', 'accessKey', 'action', 'allowFullScreen', 'alt', 'async', 'autoComplete', 'autoFocus', 'autoPlay', 'capture', 'cellPadding', 'cellSpacing', 'challenge', 'charSet', 'checked', 'cite', 'classID', 'className', 'colSpan', 'cols', 'content', 'contentEditable', 'contextMenu', 'controls', 'controlsList', 'coords', 'crossOrigin', 'data', 'dateTime', 'default', 'defer', 'dir', 'disabled', 'download', 'draggable', 'encType', 'form', 'formAction', 'formEncType', 'formMethod', 'formNoValidate', 'formTarget', 'frameBorder', 'headers', 'height', 'hidden', 'high', 'href', 'hrefLang', 'htmlFor', 'httpEquiv', 'icon', 'id', 'inputMode', 'integrity', 'is', 'keyParams', 'keyType', 'kind', 'label', 'lang', 'list', 'loop', 'low', 'manifest', 'marginHeight', 'marginWidth', 'max', 'maxLength', 'media', 'mediaGroup', 'method', 'min', 'minLength', 'multiple', 'muted', 'name', 'noValidate', 'nonce', 'open', 'optimum', 'pattern', 'placeholder', 'poster', 'preload', 'profile', 'radioGroup', 'readOnly', 'rel', 'required', 'reversed', 'role', 'rowSpan', 'rows', 'sandbox', 'scope', 'scoped', 'scrolling', 'seamless', 'selected', 'shape', 'size', 'sizes', 'span', 'spellCheck', 'src', 'srcDoc', 'srcLang', 'srcSet', 'start', 'step', 'style', 'summary', 'tabIndex', 'target', 'title', 'type', 'useMap', 'value', 'width', 'wmode', 'wrap'] as const);
const STANDARD_DOM_EVENTS = (['onClick', 'onFocus', 'onBlur', 'onChange', 'onKeyDown'] as const);
const _ALL = [...STANDARD_HTML_ATTRIBUTES, ...STANDARD_DOM_EVENTS];
type StandardProp = (typeof _ALL)[0];
const _ALL_SET = new Set(_ALL);
export const CONVERT_TO_BOOLEAN: StandardProp[] = ['autoFocus', 'required', 'disabled'];
export default function (props): {
  [k: string]: any;
} {
  // accept all atributer starting with 'data-'
  const dataProps = Object.keys(props).filter(key => key.startsWith('data-'));
  return {
    ...Object.keys(props).filter(key => _ALL_SET.has((key as StandardProp))).concat(dataProps).reduce((obj, key) => {
      let val = props[key];
      if (CONVERT_TO_BOOLEAN.includes((key as StandardProp)) && typeof val === 'string') {
        val = val !== 'false';
      }
      return {
        ...obj,
        [key]: val
      };
    }, {})
  };
}