import { useCallback } from 'react';
import { shallowClientDAO } from '@niarab2c/frontend-commons/src/daos';
import type { ShallowClient } from '@niarab2c/niara-spear-crudmodel';
import type { HotelCriteriaProps } from '@niarab2c/ota-components/src/HotelSearch/Criteria/HotelCriteria';
import { useAsync } from '@niaratech/niara-react-components';
import { isEmpty } from 'lodash';
import matchSorter from 'match-sorter';
import callApi from '@niarab2c/frontend-commons/src/util/callApi';
type UseClientSearchReturnType = {
  clientSearch: HotelCriteriaProps['clientSearch'];
  allClients: ShallowClient[];
};
const useClientSearch = (fixedClientId?: string): UseClientSearchReturnType => {
  const [allClients] = useAsync<ShallowClient[]>(async () => {
    if (fixedClientId) return await shallowClientDAO.findById(fixedClientId).then(r => [r]);

    //Backend está armazenando o retorno em cache nesse endpoint.
    return await callApi('niara-spear-commons', '/getAllClients', 'get').then(r => r?.clients ?? []);
  }, [fixedClientId]);
  const clientSearch = useCallback<HotelCriteriaProps['clientSearch']>((query: string) => {
    if (isEmpty(query)) {
      return allClients;
    }
    return matchSorter(allClients, query, {
      keys: ['brand_name']
    });
  }, [allClients]);
  return {
    clientSearch,
    allClients
  };
};
export default useClientSearch;