import { useMemo } from 'react';
import { useAsync } from '@niaratech/niara-react-components';
import { clientDAO } from '@niarab2c/frontend-commons/src/daos';
import { Client } from '@niarab2c/niara-spear-crudmodel';
import { useSelector } from '../app/base';
type UseParentClientParamsType = {
  clientId?: string; // passa qualquer clientId
};
type UseParentClientReturnType = {
  isParentClient: boolean; // retorna true se o clientId passado for um cliente pai
  isChildClient: boolean; // retorna true se o clientId passado for um cliente filho
  parentClientId: string; // retorna o id do cliente pai se o cliente passado for um cliente filho
  childrenClientIds: Array<string>; // retorna o array dos ids dos clientes filhos se o clientId passado for um cliente pai
  siblingsClientIds: Array<string>; // retorna o array dos ids dos clientes irmãos se o clientId passado for um cliente filho
  allClientIds: Array<string>; // retorna a junção do childrenClientIds e siblingsClientIds
};
const useParentClient = (params?: UseParentClientParamsType): UseParentClientReturnType => {
  const {
    b2c,
    selfBooking,
    reduxClientId
  } = useSelector(rootState => ({
    b2c: rootState.authentication?.b2c,
    selfBooking: rootState.authentication?.b2c || rootState.authentication?.isClientUser,
    reduxClientId: rootState.core.clientId
  }));
  const clientId = params?.clientId ?? reduxClientId;
  const [client] = useAsync<Client>((): any => {
    if (b2c) return null; // em b2c, o clientDAO.load não vai funcionar
    if (!clientId) return null;
    return clientDAO.load({
      id: clientId
    })?.then(item => item).catch(() => null);
  }, [b2c, clientId]);
  const {
    isParentClient,
    parentClientId,
    childrenClientIds
  } = useMemo(() => ({
    isParentClient: client?.isParentClient ?? false,
    parentClientId: client?.parentClientId,
    childrenClientIds: client?.childrenClientIds ?? []
  }), [client]);
  const [parentClient] = useAsync<Client>((): any => {
    if (b2c) return null; // em b2c, o clientDAO.load não vai funcionar
    if (selfBooking) return null; // Não vai funcionar, pq o clientDAO só permite a busca com mesmo clientId do usuário. Se esta feature for importante pro self booking, será necessário mexer antes no appsync
    if (!parentClientId) return null;
    return clientDAO.load({
      id: parentClientId
    })?.then(item => item).catch(() => null);
  }, [b2c, parentClientId, selfBooking]);
  const {
    siblingsClientIds
  } = useMemo(() => ({
    siblingsClientIds: parentClient?.childrenClientIds ?? []
  }), [parentClient]);
  return {
    isParentClient,
    isChildClient: !isParentClient && parentClientId ? true : false,
    parentClientId,
    childrenClientIds,
    siblingsClientIds,
    allClientIds: [...childrenClientIds, ...siblingsClientIds]
  };
};
export default useParentClient;