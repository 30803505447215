import { Storefront } from '@niarab2c/otabuilder-crudmodel'

const isCustomDomainRegex =
  /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.){1,}([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])(\/[A-Za-z0-9]*){0,}$/
const getStorefrontUrl = (storefront: Storefront) => {
  const locator = storefront?.['defaultUrl'] ?? storefront.locator
  const isCustomDomain = isCustomDomainRegex.test(locator)
  // cuidado - não retornar com / no fim
  if (isCustomDomain) return `https://${locator}`
  if (process.env.NODE_ENV === 'development') return `https://localhost:13001/${locator}`
  if (process.env.DEV_OR_HOMOLOG_OR_PROD === 'dev') return `https://app.niarab2cdev.niara.tech/${locator}`
  if (process.env.DEV_OR_HOMOLOG_OR_PROD === 'homolog') return `https://app.niarab2chomolog.niara.tech/${locator}`
  if (process.env.DEV_OR_HOMOLOG_OR_PROD === 'prod') return `https://app.otabuilder.com/${locator}`
}

const storefrontSorter = (a: Storefront, b: Storefront): number => {
  if (a.enabled !== b.enabled) {
    return a.enabled ? -1 : 1
  }
  for (const property in ['locator', 'title', 'clientName']) {
    const comparison = (a[property] as string)?.localeCompare(b[property] as string)
    if (comparison != 0) return comparison
  }
  return 0
}

export { getStorefrontUrl, storefrontSorter }
