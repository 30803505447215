import NiaraAuth from '@niaratech/niara-auth-client/lib/web/NiaraAuth'
import type { INiaraAuth } from '@niaratech/niara-auth-client/src/web/NiaraAuth'

/**
 * Cria niaraAuth atualizável
 */
const createNiaraAuthProxy = (env: 'prod' | 'homolog' | 'dev'): [INiaraAuth, (i: INiaraAuth) => void] => {
  const defaultInstance = new NiaraAuth(env)
  defaultInstance.autorefreshEnabled = true

  let _currentInstance: INiaraAuth = defaultInstance
  /**
   * Substitui o niaraAuth, sem mudar a instância exportada
   * @param i
   */
  const setNiaraAuth = (i: INiaraAuth): void => {
    _currentInstance = i
  }
  const handler: ProxyHandler<INiaraAuth> = {
    get: (defaultTarget, prop) => {
      const actualTarget = _currentInstance ?? defaultTarget
      const ret = actualTarget[prop]
      if (ret && typeof ret.bind == 'function') {
        return ret.bind(actualTarget)
      }
      return ret
    },
  }

  const niaraAuthProxy = new Proxy(_currentInstance, handler)
  return [niaraAuthProxy, setNiaraAuth]
}

const env = process.env.DEV_OR_HOMOLOG_OR_PROD === 'dev' ? 'dev' : 'prod' // Usar niaraauth de prod em homolog, para trabalhar com o niara-spear de homolog)
export const [niaraAuth, setNiaraAuth] = createNiaraAuthProxy(env)

export default niaraAuth
