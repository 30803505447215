import { prop, switchProp } from 'styled-tools'
import styled, { css } from 'styled-components'
import { color, ColorProps, typography, TypographyProps } from 'styled-system'
import { Separator } from '../../HotelSearch/Period/styles'

export type PriceStylesProps = ColorProps &
  TypographyProps & {
    variant?: 'currency' | 'decimal' | 'fraction'
    strikethrough?: boolean
  }

export const Coin = styled.span<PriceStylesProps>`
  ${color}
  ${typography}
  ${({ strikethrough }) => (strikethrough ? 'text-decoration: line-through;' : '')}

  ${switchProp(prop('variant'), {
    currency: css`
      ${color}
      ${typography}
    `,
    decimal: css`
      ${color}
      ${typography}
    `,
    fraction: css`
      ${color}
      ${typography}
    `,
  })}
`

export const Container = styled.span``
