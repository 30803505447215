import type { PartialField } from '@niaratech/devcase-crud/dist/fillCrudMetadataGaps';
import fillCrudMetadata from '@niaratech/devcase-crud/dist/fillCrudMetadataGaps';
declare module '@niaratech/devcase-crud/dist/fillCrudMetadataGaps' {
  interface Field {
    custom_idField;
    custom_nameField;
    fileType;
    types;
  }
}
const SEARCH_CRITERIA: Array<PartialField> = [{
  label: 'Check-in',
  name: 'startDate',
  type: 'text',
  required: false
}, {
  label: 'Check-out',
  name: 'endDate',
  type: 'text',
  required: false
}, {
  label: 'Noites',
  name: 'nights',
  type: 'int',
  required: false
}, {
  label: 'Destinos',
  name: 'destinations',
  type: 'object',
  graphQLType: 'EmbeddedCriteriaDestinations',
  graphQLInputType: 'EmbeddedCriteriaDestinationsInput',
  default: {},
  required: false,
  fields: [{
    name: 'propertyId',
    type: 'text',
    required: false
  }, {
    name: 'contentType',
    type: 'text',
    required: false
  }, {
    name: 'cityIds',
    type: 'textarray',
    required: false
  }, {
    name: 'hotelIds',
    type: 'textarray',
    required: false
  }]
}, {
  label: 'Nome do destino',
  name: 'destinationName',
  type: 'text',
  required: false
}, {
  label: 'Quartos',
  name: 'rooms',
  type: 'objectarray',
  graphQLType: '[EmbeddedCriteriaRooms]',
  graphQLInputType: '[EmbeddedCriteriaRoomsInput]',
  default: null,
  required: false,
  fields: [{
    label: 'Adultos',
    name: 'adults',
    type: 'int',
    required: false
  }, {
    label: 'Crianças',
    name: 'children',
    type: 'int',
    required: false
  }, {
    label: 'Idade das crianças',
    name: 'childrenAges',
    type: 'intarray',
    required: false
  }]
}, {
  label: 'Código Promocional',
  name: 'promoCode',
  type: 'text',
  required: false
}];
const BANNER_FIELDS: Array<PartialField> = [{
  label: 'Nome',
  name: 'name',
  type: 'text',
  required: true
}, {
  label: 'Título',
  name: 'title',
  type: 'text',
  required: false
}, {
  label: 'Altura',
  name: 'height',
  type: 'text',
  required: false
}, {
  label: 'Orientação do texto',
  name: 'textOrientation',
  type: 'text',
  required: false,
  options: [{
    label: 'Left',
    value: 'LEFT'
  }, {
    label: 'Center',
    value: 'CENTER'
  }, {
    label: 'Right',
    value: 'RIGHT'
  }]
}, {
  name: 'customCSS',
  label: 'CSS',
  type: 'text',
  inputType: 'textarea'
}, {
  label: 'Conteúdo',
  name: 'content',
  type: 'text',
  required: true,
  inputType: 'wysiwyg'
}, {
  label: 'Imagem',
  name: 'image',
  type: 'text',
  default: null,
  required: false,
  inputType: 'inputPublicFile'
}, {
  label: 'Texto do botão',
  name: 'buttonText',
  type: 'text',
  required: false
}, {
  label: 'Link do botão',
  name: 'buttonLink',
  type: 'text',
  default: 'https://',
  pattern: `^https:\\/\\/[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)`
}, {
  name: 'bgColor',
  label: 'Cor de fundo',
  type: 'text',
  inputType: 'color'
}, {
  name: 'textColor',
  label: 'Cor de texto',
  type: 'text',
  inputType: 'color'
}, {
  name: 'buttonBgColor',
  label: 'Cor do botão',
  type: 'text',
  inputType: 'color'
}, {
  name: 'buttonTextColor',
  label: 'Cor do texto do botão',
  type: 'text',
  inputType: 'color'
}, {
  name: 'pagesShown',
  label: 'Mostrar banner nas seguintes páginas',
  type: 'textarray',
  options: [{
    label: 'Homepage',
    value: 'HOMEPAGE'
  }, {
    label: 'Resultado de pesquisa',
    value: 'SEARCH_RESULTS'
  }, {
    label: 'Extras',
    value: 'EXTRAS'
  }, {
    label: 'Check-out',
    value: 'CHECKOUT'
  }, {
    label: 'Confirmação de reserva',
    value: 'SUCCESS'
  }]
}];
export default fillCrudMetadata({
  label: 'Storefront',
  labelPlural: 'Storefronts',
  name: 'Storefront',
  insertable: true,
  updatable: true,
  deletable: false,
  authorization: {
    read: ['clientConsultant', 'consultant', 'clientAdmin', 'admin'],
    write: ['clientAdmin', 'admin']
  },
  dynamoDB: {
    tableName: process.env.OTABUILDER_STOREFRONT_TABLE,
    partitionKey: 'tenantId',
    indexedProp: 'id'
  },
  columnNames: ['id', 'name', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    render: false,
    required: true
  }, {
    label: 'Nome',
    name: 'name',
    required: true,
    type: 'text'
  }, {
    label: 'Localizador',
    name: 'locator',
    required: true,
    updatable: false,
    type: 'text'
  }, {
    label: 'Descrição da página',
    name: 'metaDescription',
    type: 'text'
  }, {
    label: 'URL Customizada',
    name: 'defaultUrl',
    required: false,
    type: 'text'
  }, {
    name: 'clientId',
    label: 'Cliente',
    required: true,
    type: 'text',
    renderForm: false,
    updatable: false
  }, {
    custom_idField: 'clientId',
    name: 'clientName',
    label: 'Cliente (nome)',
    required: true,
    type: 'text',
    inputType: 'client',
    updatable: false
  }, {
    name: 'publishTime',
    insertable: false,
    updatable: false,
    type: 'datetime'
  }, {
    name: 'landingPageId',
    insertable: false,
    updatable: false
  }, {
    name: 'updatedAt',
    insertable: false,
    updatable: false,
    type: 'datetime'
  }, {
    name: 'createdAt',
    insertable: false,
    updatable: false,
    type: 'datetime'
  }, {
    insertable: false,
    name: 'enabled',
    label: 'Ativo',
    default: true,
    required: true,
    type: 'boolean'
  }, {
    name: 'minStartDate',
    label: 'Data de checkin mínima',
    required: false,
    type: 'date'
  }, {
    name: 'maxStartDate',
    label: 'Data de checkin máxima',
    required: false,
    type: 'date'
  }, {
    groupName: 'Analytics',
    name: 'gtmId',
    label: 'Google Tag Manager Id',
    required: false,
    type: 'text'
  }, {
    groupName: 'Analytics',
    name: 'metaPixelId',
    label: 'Id Pixel da Meta (Facebook)',
    required: false,
    type: 'text'
  }, {
    groupName: 'Analytics',
    name: 'googleAnalyticsId',
    label: 'Google Analytics Id',
    required: false,
    type: 'text'
  }, {
    groupName: 'Analytics',
    name: 'hotjarId',
    label: 'Hotjar Id',
    required: false,
    type: 'text'
  }, {
    insertable: false,
    groupName: 'Barra de topo',
    name: 'topBar_enabled',
    label: 'Ativar barra de topo',
    default: false,
    required: false,
    type: 'boolean'
  }, {
    insertable: false,
    groupName: 'Barra de topo',
    name: 'topBar_content',
    label: 'Título e mensagem',
    required: false,
    type: 'text',
    inputType: 'wysiwyg',
    renderForm: e => !e?.topBar_enabled ? false : true
  }, {
    insertable: false,
    groupName: 'Barra de topo',
    name: 'topBar_sticky',
    label: 'Fixar com a rolagem',
    required: false,
    type: 'boolean',
    renderForm: e => !e?.topBar_enabled ? false : true
  }, {
    insertable: false,
    groupName: 'Barra de topo',
    name: 'topBar_bgColor',
    label: 'Cor de fundo',
    default: '#273240',
    type: 'text',
    inputType: 'color',
    renderForm: e => !e?.topBar_enabled ? false : true
  }, {
    insertable: false,
    groupName: 'Barra de topo',
    name: 'topBar_textColor',
    label: 'Cor de texto',
    default: '#ffffff',
    type: 'text',
    inputType: 'color',
    renderForm: e => !e?.topBar_enabled ? false : true
  }, {
    insertable: false,
    groupName: 'Barra sob cabeçalho',
    name: 'underNavBar_enabled',
    label: 'Ativar barra sob cabeçalho',
    default: false,
    required: false,
    type: 'boolean'
  }, {
    insertable: false,
    groupName: 'Barra sob cabeçalho',
    name: 'underNavBar_bgColor',
    label: 'Cor de fundo',
    default: '#273240',
    type: 'text',
    inputType: 'color',
    renderForm: e => !e?.underNavBar_enabled ? false : true
  }, {
    insertable: false,
    groupName: 'Barra sob cabeçalho',
    name: 'underNavBar_textColor',
    label: 'Cor de texto',
    default: '#ffffff',
    type: 'text',
    inputType: 'color',
    renderForm: e => !e?.underNavBar_enabled ? false : true
  }, {
    insertable: false,
    groupName: 'Barra sob cabeçalho',
    name: 'underNavBar_content',
    label: 'Conteúdo',
    type: 'text',
    inputType: 'textarea',
    renderForm: e => !e?.underNavBar_enabled ? false : true
  }, {
    insertable: false,
    groupName: 'Barra sob cabeçalho',
    name: 'underNavBar_textRight',
    label: 'Texto a direita',
    type: 'text',
    inputType: 'text',
    renderForm: e => !e?.underNavBar_enabled ? false : true
  }, {
    insertable: false,
    groupName: 'Barra sob cabeçalho',
    name: 'underNavBar_linkTextRight',
    label: 'Link do texto a direita',
    type: 'text',
    inputType: 'text',
    renderForm: e => !e?.underNavBar_enabled ? false : true
  }, {
    insertable: false,
    groupName: 'Barra sob cabeçalho',
    name: 'underNavBar_height',
    label: 'Altura',
    default: '36px',
    type: 'text',
    renderForm: e => !e?.underNavBar_enabled ? false : true
  }, {
    insertable: false,
    groupName: 'Barra sob cabeçalho',
    name: 'underNavBar_logo',
    label: 'Logotipo',
    type: 'text',
    inputType: 'inputPublicFile',
    renderForm: e => !e?.underNavBar_enabled ? false : true
  }, {
    insertable: false,
    groupName: 'Barra sob cabeçalho',
    name: 'underNavBar_linkLogo',
    label: 'Link do logo',
    type: 'text',
    inputType: 'text',
    renderForm: e => !e?.underNavBar_enabled ? false : true
  }, {
    insertable: false,
    groupName: 'Cores do tema',
    name: 'themeColors_navbarBg',
    label: 'Barra de navegação: fundo',
    required: false,
    default: '#0c83d9',
    inputType: 'color',
    type: 'text'
  }, {
    insertable: false,
    groupName: 'Cores do tema',
    name: 'themeColors_navbarText',
    label: 'Barra de navegação: texto',
    required: false,
    default: '#ffffff',
    inputType: 'color',
    type: 'text'
  }, {
    insertable: false,
    groupName: 'Cores do tema',
    name: 'themeColors_primary',
    label: 'Botões principais: fundo',
    default: '#ec0080',
    inputType: 'color',
    type: 'text',
    mask: '#______'
  }, {
    insertable: false,
    groupName: 'Cores do tema',
    name: 'themeColors_primaryReadable',
    label: 'Botões principais: texto',
    default: '#ffffff',
    inputType: 'color',
    type: 'text',
    mask: '#______'
  }, {
    insertable: false,
    groupName: 'Logotipos',
    name: 'logo_header',
    label: 'Logotipo do Header (Desktop)',
    type: 'text',
    inputType: 'inputPublicFile'
  }, {
    insertable: false,
    groupName: 'Logotipos',
    name: 'logo_header_mobile',
    label: 'Logotipo do Header (Mobile)',
    type: 'text',
    inputType: 'inputPublicFile'
  }, {
    insertable: false,
    groupName: 'Logotipos',
    name: 'logo_link',
    label: 'Link do Logotipo',
    type: 'text'
  }, {
    insertable: false,
    groupName: 'Logotipos',
    name: 'favicon',
    label: 'Favicon',
    type: 'text',
    inputType: 'inputPublicFile'
  }, {
    insertable: false,
    groupName: 'Logotipos',
    label: 'Adesivo de hotel recomendado',
    hint: 'Quando definida, esta imagem substitui os indicadores de hotéis favoritos',
    name: 'recommended_badge',
    type: 'text',
    inputType: 'inputPublicFile'
  }, {
    insertable: false,
    groupName: 'Menu',
    name: 'customMenu_enabled',
    label: 'Ativar menu personalizado',
    default: false,
    type: 'boolean'
  }, {
    insertable: false,
    groupName: 'Menu',
    name: 'customMenu_customItems',
    label: 'Itens do menu',
    default: [],
    type: 'objectarray',
    graphQLType: '[Storefront_CustomMenuItem]',
    graphQLInputType: '[Storefront_CustomMenuItemInput]',
    fields: [{
      label: 'Grupo',
      name: 'group',
      type: 'text',
      required: false
    }, {
      label: 'Texto',
      name: 'label',
      type: 'text',
      required: true
    }, {
      label: 'Link',
      name: 'ref',
      type: 'text',
      required: true
    }],
    renderForm: e => !e?.customMenu_enabled ? false : true
  }, {
    insertable: false,
    groupName: 'Menu',
    name: 'customMenu_style',
    label: 'Estilo',
    type: 'text',
    renderForm: e => !e?.customMenu_enabled ? false : true,
    options: [{
      label: 'Horizontal',
      value: 'HORIZONTAL'
    }, {
      label: 'Drop',
      value: 'DROP'
    }, {
      label: 'Burger',
      value: 'BURGER'
    }]
  }, {
    insertable: false,
    groupName: 'Banner homepage',
    name: 'homepageBanner_mode',
    label: 'Modo',
    type: 'text',
    options: [{
      value: 'DEFAULT'
    }, {
      value: 'CUSTOM_HOMEPAGE'
    }]
  }, {
    insertable: false,
    groupName: 'Banner homepage',
    name: 'homepageBanner_image',
    label: 'Banner homepage (Desktop)',
    type: 'text',
    inputType: 'inputPublicFile'
  }, {
    insertable: false,
    groupName: 'Banner homepage',
    name: 'homepageBanner_title',
    label: 'Título',
    type: 'text',
    renderForm: e => e.homepageBanner_mode === 'DEFAULT' ? true : false
  }, {
    insertable: false,
    groupName: 'Banner homepage',
    name: 'homepageBanner_text',
    label: 'Texto sob o título',
    type: 'text',
    renderForm: e => e.homepageBanner_mode === 'DEFAULT' ? true : false
  }, {
    insertable: false,
    groupName: 'Banner homepage',
    name: 'homepageBannerMobile_enabled',
    label: 'Ativar banner homepage mobile',
    default: false,
    required: false,
    type: 'boolean'
  }, {
    insertable: false,
    groupName: 'Banner homepage',
    name: 'homepageBannerMobile_image',
    label: 'Banner homepage (Desktop)',
    type: 'text',
    inputType: 'inputPublicFile'
  }, {
    insertable: false,
    groupName: 'Banner homepage',
    name: 'homepageBannerMobile_title',
    label: 'Título',
    type: 'text',
    renderForm: e => e.homepageBanner_mode === 'DEFAULT' ? true : false
  }, {
    insertable: false,
    groupName: 'Banner homepage',
    name: 'homepageBannerMobile_text',
    label: 'Texto sob o título',
    type: 'text',
    renderForm: e => e.homepageBanner_mode === 'DEFAULT' ? true : false
  }, {
    insertable: false,
    groupName: 'Banner homepage',
    name: 'homepageBanner_overlayColor',
    label: 'Overlay da imagem',
    type: 'text',
    inputType: 'color',
    renderForm: e => e.homepageBanner_mode === 'DEFAULT' ? true : false
  }, {
    insertable: false,
    groupName: 'Banner homepage',
    name: 'homepageBanner_imageBackgroundColor',
    label: 'Cor de Fundo da Imagem',
    type: 'text',
    inputType: 'color',
    renderForm: e => e.homepageBanner_mode === 'DEFAULT' ? true : false
  }, {
    insertable: false,
    groupName: 'Banner homepage',
    name: 'homepageBanner_textColor',
    label: 'Cor do texto',
    type: 'text',
    inputType: 'color',
    renderForm: e => e.homepageBanner_mode === 'DEFAULT' ? true : false
  }, {
    insertable: false,
    groupName: 'Banner homepage',
    name: 'homepageBanner_textShadowColor',
    label: 'Sombra do texto',
    type: 'text',
    inputType: 'color',
    renderForm: e => e.homepageBanner_mode === 'DEFAULT' ? true : false
  }, {
    insertable: false,
    groupName: 'Banner homepage',
    name: 'homepageBanner_container',
    label: 'Largura',
    type: 'text',
    options: [{
      value: 'FULL'
    }, {
      value: 'CONTAINER'
    }],
    renderForm: e => e.homepageBanner_mode === 'CUSTOM_HOMEPAGE' ? true : false
  }, {
    insertable: false,
    groupName: 'Banner homepage',
    name: 'homepageBanner_html',
    // mudei o nome
    label: 'HTML do homepage',
    type: 'text',
    inputType: 'textarea',
    renderForm: e => e.homepageBanner_mode === 'CUSTOM_HOMEPAGE' ? true : false
  }, {
    insertable: false,
    groupName: 'Banner homepage',
    name: 'homepageBanner_css',
    label: 'CSS do homepage',
    type: 'text',
    inputType: 'textarea',
    renderForm: e => e.homepageBanner_mode === 'CUSTOM_HOMEPAGE' ? true : false
  }, {
    insertable: false,
    groupName: 'Banners promocionais',
    name: 'banner_pageBanners',
    label: 'Banners de página',
    default: [],
    type: 'objectarray',
    graphQLType: '[Storefront_PageBanner]',
    graphQLInputType: '[Storefront_PageBannerInput]',
    fields: BANNER_FIELDS
  }, {
    insertable: false,
    groupName: 'Banners de lateral',
    name: 'banner_sideBanners',
    label: 'Banners de lateral',
    default: [],
    type: 'objectarray',
    graphQLType: '[Storefront_SideBanner]',
    graphQLInputType: '[Storefront_SideBannerInput]',
    fields: BANNER_FIELDS
  }, {
    insertable: false,
    groupName: 'Motor de pesquisa',
    name: 'searchEngine_orientation',
    label: 'Orientação',
    type: 'text',
    options: [{
      value: 'VERTICAL',
      label: 'Vertical'
    }, {
      value: 'HORIZONTAL',
      label: 'Horizontal'
    }]
  }, {
    insertable: false,
    groupName: 'Motor de Pesquisa',
    name: 'searchEngine_enableCriteria',
    label: 'Configurar Parâmetros Base',
    default: false,
    required: false,
    type: 'boolean'
  }, {
    insertable: false,
    groupName: 'Motor de Pesquisa',
    name: 'searchEngine_defaultCriteria',
    label: 'Critério de busca',
    type: 'object',
    graphQLType: 'EmbeddedCriteria',
    graphQLInputType: 'EmbeddedCriteriaInput',
    default: {},
    required: false,
    fields: SEARCH_CRITERIA
  }, {
    insertable: false,
    groupName: 'Widgets homepage',
    name: 'widgets_homepage',
    label: 'Widgets da homepage',
    default: [],
    type: 'objectarray',
    inputType: 'vertical-objectarray',
    graphQLType: '[Storefront_Widget]',
    graphQLInputType: '[Storefront_WidgetInput]',
    fields: [{
      label: 'Título',
      name: 'title',
      type: 'text',
      required: true
    }, {
      label: 'Modo',
      name: 'mode',
      type: 'text',
      required: true,
      options: [{
        label: 'Cartões',
        value: 'CARDS'
      }, {
        label: 'Slideshow',
        value: 'SLIDESHOW'
      }]
    }, {
      label: 'Tipo de conteúdo',
      name: 'contentType',
      type: 'text',
      required: true,
      options: [{
        label: 'Dinâmico',
        value: 'DYNAMIC'
      }, {
        label: 'Personalizado',
        value: 'CUSTOM'
      }]
    }, {
      label: 'Overlay da imagem',
      name: 'imageOverlay',
      type: 'text',
      required: false,
      inputType: 'color'
    }, {
      label: 'Cor do texto',
      name: 'textColor',
      type: 'text',
      required: false,
      inputType: 'color'
    }, {
      label: 'Cor do botão',
      name: 'buttonColor',
      type: 'text',
      required: false,
      inputType: 'color'
    }, {
      label: 'Cor do texto do botão',
      name: 'buttonTextColor',
      type: 'text',
      required: false,
      inputType: 'color'
    }, {
      label: 'Orientação do texto',
      name: 'textOrientation',
      type: 'text',
      required: false,
      options: [{
        label: 'Left',
        value: 'LEFT'
      }, {
        label: 'Center',
        value: 'CENTER'
      }, {
        label: 'Right',
        value: 'RIGHT'
      }]
    }, {
      label: 'Conteúdo',
      name: 'content',
      type: 'objectarray',
      inputType: 'vertical-objectarray',
      graphQLType: '[Storefront_WidgetContent]',
      graphQLInputType: '[Storefront_WidgetContentInput]',
      default: [],
      fields: [{
        label: 'Título',
        name: 'title',
        type: 'text',
        required: true
      }, {
        label: 'Recomendado',
        name: 'recommended',
        type: 'text',
        required: false
      }, {
        label: 'Rating',
        name: 'rating',
        type: 'int',
        required: false
      }, {
        label: 'Texto',
        name: 'text',
        type: 'text',
        required: false
      }, {
        label: 'Localização',
        name: 'location',
        type: 'text',
        required: false
      }, {
        label: 'Imagem',
        name: 'image',
        type: 'text',
        required: false,
        inputType: 'inputPublicFile'
      }, {
        label: 'Texto do botão',
        name: 'button_text',
        type: 'text',
        required: false
      }, {
        label: 'Id do Hotel',
        name: 'hotelId',
        type: 'text',
        required: false
      }, {
        label: 'Critério de busca',
        name: 'criteria',
        type: 'object',
        graphQLType: 'EmbeddedCriteria',
        graphQLInputType: 'EmbeddedCriteriaInput',
        default: {},
        required: false,
        fields: SEARCH_CRITERIA
      }, {
        label: 'Link do botão',
        name: 'button_link',
        type: 'text',
        required: false
      }]
    }]
  }, {
    insertable: false,
    groupName: 'Footer',
    name: 'footer_enabled',
    label: 'Adicionar footer personalizado',
    default: false,
    required: false,
    type: 'boolean'
  }, {
    insertable: false,
    groupName: 'Footer',
    name: 'footer_mode',
    label: 'Modo',
    type: 'text',
    options: [{
      value: 'CUSTOM_HTML',
      label: 'HTML'
    }, {
      value: 'STANDARD',
      label: 'Parametrizável'
    }, {
      value: 'NONE',
      label: 'Nenhum'
    }]
  }, {
    insertable: false,
    groupName: 'Footer',
    name: 'footer_slashUnder_enabled',
    label: 'Adicionar footer powered by',
    default: false,
    required: false,
    type: 'boolean'
  }, {
    insertable: false,
    groupName: 'Footer',
    name: 'footer_slashUnder_content',
    label: 'texto powered by',
    type: 'text',
    inputType: 'textarea',
    renderForm: e => e.footer_mode === 'STANDARD' ? true : false
  }, {
    insertable: false,
    groupName: 'Footer',
    name: 'footer_powered_by_logo',
    label: 'Logotipo powered by',
    type: 'text',
    inputType: 'inputPublicFile',
    renderForm: e => e.footer_mode === 'STANDARD' ? true : false
  }, {
    insertable: false,
    groupName: 'Footer',
    name: 'footer_powered_by_linkLogo',
    label: 'Link do logo powered by',
    type: 'text',
    inputType: 'text',
    renderForm: e => e.footer_mode === 'STANDARD' ? true : false
  }, {
    insertable: false,
    groupName: 'Footer',
    name: 'footer_bgColor',
    label: 'Cor de fundo',
    type: 'text',
    inputType: 'color',
    renderForm: e => e.footer_mode === 'CUSTOM_HTML' || e.footer_mode === 'STANDARD' ? true : false
  }, {
    insertable: false,
    groupName: 'Footer',
    name: 'footer_textColor',
    label: 'Cor de texto',
    type: 'text',
    inputType: 'color',
    renderForm: e => e.footer_mode === 'CUSTOM_HTML' || e.footer_mode === 'STANDARD' ? true : false
  }, {
    insertable: false,
    groupName: 'Footer',
    name: 'footer_titleColor',
    label: 'Cor do título da coluna',
    type: 'text',
    inputType: 'color',
    renderForm: e => e.footer_mode === 'STANDARD' ? true : false
  }, {
    insertable: false,
    groupName: 'Footer',
    name: 'footer_linkColor',
    label: 'Cor do link',
    type: 'text',
    inputType: 'color',
    renderForm: e => e.footer_mode === 'STANDARD' ? true : false
  }, {
    insertable: false,
    groupName: 'Footer',
    name: 'footer_linkHoverColor',
    label: 'Cor do link (hover)',
    type: 'text',
    inputType: 'color',
    renderForm: e => e.footer_mode === 'STANDARD' ? true : false
  }, {
    insertable: false,
    groupName: 'Footer',
    label: 'Imagem',
    name: 'footer_image',
    type: 'text',
    inputType: 'inputPublicFile',
    renderForm: e => e.footer_mode === 'STANDARD' ? true : false
  }, {
    insertable: false,
    groupName: 'Footer',
    name: 'footer_columns',
    label: 'Coluna do rodapé',
    type: 'objectarray',
    inputType: 'vertical-objectarray',
    graphQLType: '[Storefront_FooterColumn]',
    graphQLInputType: '[Storefront_FooterColumnInput]',
    fields: [{
      label: 'Título da coluna',
      name: 'title',
      type: 'text',
      required: true
    }, {
      name: 'columnType',
      type: 'text',
      options: [{
        label: 'Lista de links',
        value: 'LINKS'
      }, {
        label: 'Parágrafo',
        value: 'PARAGRAPH'
      }, {
        label: 'Redes sociais',
        value: 'SOCIAL_LINKS'
      }, {
        label: 'Logos',
        value: 'LOGOS'
      }]
    }, {
      label: 'Texto',
      name: 'linksContent',
      graphQLType: '[Storefront_FooterColumn_Link]',
      graphQLInputType: '[Storefront_FooterColumn_LinkInput]',
      type: 'objectarray',
      fields: [{
        label: 'Texto do link',
        name: 'label',
        type: 'text',
        required: 'true'
      }, {
        label: 'URL',
        name: 'url',
        type: 'text',
        required: 'true',
        default: 'https://'
      }],
      renderForm: e => e.columnType === 'LINKS' ? true : false
    }, {
      label: 'Texto',
      name: 'logosContent',
      graphQLType: '[Storefront_FooterColumn_Logo]',
      graphQLInputType: '[Storefront_FooterColumn_LogoInput]',
      type: 'objectarray',
      fields: [{
        label: 'Logo',
        name: 'logo',
        type: 'text',
        inputType: 'inputPublicFile',
        required: 'true'
      }, {
        label: 'Texto alternativo do logo',
        name: 'label',
        type: 'text',
        required: 'true'
      }, {
        label: 'URL',
        name: 'url',
        type: 'text',
        required: 'true',
        default: 'https://'
      }],
      renderForm: e => e.columnType === 'LOGOS' ? true : false
    }, {
      label: 'Texto',
      name: 'paragraphContent',
      type: 'text',
      inputType: 'wysiwyg',
      renderForm: e => e.columnType === 'PARAGRAPH' ? true : false
    }, ...['Facebook', 'Twitter', 'YouTube', 'Instagram', 'Spotify', 'TikTok'].reduce((acc, social) => [...acc, {
      name: `social${social}Enabled`,
      type: 'boolean',
      renderForm: e => e.columnType === 'SOCIAL_LINKS' ? true : false
    }, {
      name: `social${social}Link`,
      type: 'text',
      renderForm: e => e.columnType === 'SOCIAL_LINKS' && e[`social${social}Enabled`] ? true : false
    }], []), {
      label: 'Imagem',
      name: 'imageFile',
      type: 'text',
      inputType: 'inputPublicFile',
      renderForm: e => e.columnType === 'IMAGE' ? true : false
    }],
    renderForm: e => e.footer_mode === 'CUSTOM_HTML' || e.footer_mode === 'STANDARD' ? true : false
  }, {
    insertable: false,
    groupName: 'Footer',
    name: 'footer_container',
    label: 'Largura',
    type: 'text',
    options: [{
      value: 'FULL'
    }, {
      value: 'CONTAINER'
    }],
    renderForm: e => e.footer_mode === 'CUSTOM_HTML' ? true : false
  }, {
    insertable: false,
    groupName: 'Footer',
    name: 'footer_bootstrap',
    label: 'Bootstrap',
    type: 'boolean',
    renderForm: e => e.footer_mode === 'CUSTOM_HTML' ? true : false
  }, {
    insertable: false,
    groupName: 'Footer',
    name: 'footer_html',
    label: 'HTML do footer',
    type: 'text',
    inputType: 'textarea',
    renderForm: e => e.footer_mode === 'CUSTOM_HTML' ? true : false
  }, {
    insertable: false,
    groupName: 'Footer',
    name: 'footer_css',
    label: 'CSS do footer',
    type: 'text',
    inputType: 'textarea',
    renderForm: e => e.footer_mode === 'CUSTOM_HTML' ? true : false
  }, {
    insertable: false,
    groupName: 'FloatingButton',
    name: 'floatingButton_enabled',
    label: 'Ativar cadastro do número do Whatsapp',
    default: false,
    required: false,
    type: 'boolean'
  }, {
    insertable: false,
    groupName: 'FloatingButton',
    name: 'floatingButton_mode',
    label: 'Modo',
    type: 'text',
    renderForm: e => !e?.floatingButton_enabled ? false : true,
    options: [{
      value: 'LEFT',
      label: 'Esquerda'
    }, {
      value: 'RIGHT',
      label: 'Direita'
    }]
  }, {
    insertable: false,
    groupName: 'FloatingButton',
    name: 'floatingButton_numberWhatsapp',
    label: 'Número para o link',
    type: 'text',
    inputType: 'text',
    renderForm: e => !e?.floatingButton_enabled ? false : true
  }, {
    insertable: false,
    groupName: 'FloatingButton',
    name: 'floatingButton_textWhatsapp',
    label: 'Conteúdo para o link',
    type: 'text',
    inputType: 'textarea',
    renderForm: e => !e?.floatingButton_enabled ? false : true
  }, {
    insertable: false,
    groupName: 'ChatBot',
    name: 'chatBot_enabled',
    label: 'Ativar integração com chatbot',
    default: false,
    required: false,
    type: 'boolean'
  }, {
    insertable: false,
    groupName: 'ChatBot',
    name: 'chatBot_position',
    label: 'Posição',
    type: 'text',
    renderForm: e => !e?.chatBot_enabled ? false : true,
    options: [{
      value: 'LEFT',
      label: 'Esquerda'
    }, {
      value: 'RIGHT',
      label: 'Direita'
    }]
  }, {
    insertable: false,
    groupName: 'ChatBot',
    name: 'chatBot_pages',
    label: 'Disponível nas páginas',
    type: 'textarray',
    renderForm: e => !e?.chatBot_enabled ? false : true,
    options: [{
      value: 'HOMEPAGE',
      label: 'Home Page'
    }, {
      value: 'SEARCH_RESULTS',
      label: 'Resultados da pesquisa'
    }, {
      value: 'CHECKOUT',
      label: 'Página de checkout'
    }, {
      value: 'SUCCESS',
      label: 'Página de sucesso'
    }, {
      value: 'ORDERS',
      label: 'Minhas viagens'
    }]
  }, {
    insertable: false,
    groupName: 'ChatBot',
    name: 'chatBot_script',
    label: 'Script gerado pelo chatbot',
    type: 'text',
    inputType: 'textarea',
    renderForm: e => !e?.chatBot_enabled ? false : true
  }, {
    insertable: false,
    groupName: 'ChatBot',
    name: 'chatBot_provider',
    label: 'Provedor do chatbot',
    type: 'text',
    inputType: 'select',
    options: [{
      value: 'BLIP_CHAT',
      label: 'Blip Chat'
    }],
    renderForm: e => !e?.chatBot_enabled ? false : true
  }, {
    insertable: false,
    groupName: 'ChatBot',
    name: 'chatBot_providerKey1',
    label: 'Id do provedor do chatbot',
    type: 'text',
    renderForm: e => !e?.chatBot_enabled ? false : true
  }, {
    insertable: false,
    groupName: 'PreRegisterModal',
    name: 'preRegisterModal_enabled',
    label: 'Ativar pop-up de cadastro no check-out',
    default: false,
    required: false,
    type: 'boolean'
  }, {
    insertable: false,
    groupName: 'PreRegisterModal',
    name: 'preRegisterModal_banner',
    label: 'Imagem',
    type: 'text',
    inputType: 'inputPublicFile'
  }, {
    insertable: false,
    groupName: 'PreRegisterModal',
    name: 'preRegisterModal_title',
    label: 'Título',
    type: 'text',
    renderForm: e => e.preRegisterModal_enabled === 'DEFAULT' ? true : false
  }, {
    insertable: false,
    groupName: 'PreRegisterModal',
    name: 'preRegisterModal_message',
    label: 'mensagem',
    type: 'text',
    renderForm: e => e.preRegisterModal_enabled === 'DEFAULT' ? true : false
  }, {
    insertable: false,
    groupName: "i18n",
    name: 'i18n_defaultLocale',
    label: '',
    default: 'pt-BR',
    required: false,
    type: 'text'
  }, {
    insertable: false,
    groupName: "i18n",
    name: 'i18n_availableLocale',
    label: '',
    required: false,
    type: 'textarray'
  }, {
    insertable: false,
    groupName: 'Login',
    label: 'Instruções na tela de login',
    hint: 'Este texto aparecerá na tela de login do otabuilder - colocar dados para contato (telefone, e-mail, etc). Cada linha vira um ponto.',
    name: 'login_instructions',
    type: 'objectarray',
    inputType: 'vertical-objectarray',
    default: [{
      text: 'Reenvie o seu voucher'
    }],
    graphQLType: '[Storefront_LoginInstruction]',
    graphQLInputType: '[Storefront_LoginInstructionInput]',
    fields: [{
      label: 'Conteúdo',
      name: 'text',
      type: 'text',
      required: true,
      inputType: 'wysiwyg'
    }, {
      name: 'url'
    }]
  }, {
    insertable: false,
    groupName: 'Login',
    name: 'login_banner',
    label: 'Banner tela de login',
    type: 'text',
    inputType: 'inputPublicFile'
  }, {
    insertable: false,
    groupName: 'Anexos',
    name: 'attachments',
    label: 'Anexos',
    type: 'textarray',
    inputType: 'inputPublicFile'
  }, {
    groupName: "Termos e Condições",
    name: 'terms_files_ota',
    label: 'Termos, políticas, regulamentos',
    type: 'objectarray',
    graphQLType: '[Storefront_NestedClientTermsFile]',
    graphQLInputType: '[Storefront_NestedClientTermsFileInput]',
    insertable: false,
    updatable: true,
    hint: 'Links/arquivos que serão colocados próximos ao check-box na tela de checkout',
    fields: [{
      name: 'name',
      label: 'Nome',
      hint: 'Termos, condições gerais, regulamento, política de privacidade, etc',
      required: true,
      type: 'text'
    }, {
      name: 'file',
      label: 'Arquivo (PDF)',
      required: false,
      type: 'text',
      inputType: 'inputPublicFile',
      fileType: 'pdf'
    }, {
      name: 'url',
      label: 'Link',
      hint: '(ignorado se existir arquivo)',
      required: false,
      type: 'text',
      fileType: 'pdf'
    }]
  }, {
    groupName: "Aéreo",
    insertable: true,
    name: 'flights_enabled',
    label: 'Ativo',
    default: false,
    required: false,
    type: 'boolean'
  }],
  filter: [{
    field: 'enabled',
    op: 'eq'
  }, {
    field: 'clientId',
    op: 'eq'
  }]
});