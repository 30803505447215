/**
 * implementação super específica de crypto.createHmac e crypto.createHash, usados por aws4
 *
 * function hmac(key, string, encoding) {
 *   return crypto.createHmac('sha256', key).update(string, 'utf8').digest(encoding)
 * }
 * function hash(string, encoding) {
 *   return crypto.createHash('sha256').update(string, 'utf8').digest(encoding)
 * }
 */

import type { createHmac as NodeJsCryptoCreateHmac, createHash as NodeJsCryptoCreateHash } from 'crypto'
import CryptoJs from 'crypto-js'

type MyCrypto = {
  createHmac: typeof NodeJsCryptoCreateHmac
  createHash: typeof NodeJsCryptoCreateHash
}

const myCrypto: MyCrypto = {
  createHash(algorithm: 'sha256') {
    return {
      update(message: string) {
        return {
          digest(encoding: 'base64' | 'base64url' | 'hex') {
            const encoder =
              encoding === 'base64'
                ? CryptoJs.enc.Base64
                : encoding == 'base64url'
                ? CryptoJs.enc.Base64url
                : encoding == 'hex'
                ? CryptoJs.enc.Hex
                : undefined
            return CryptoJs.SHA256(message).toString(encoder)
          },
        }
      },
    }
  },
  createHmac(algorithm: 'sha256', key: string | CryptoJS.lib.WordArray) {
    return {
      update(message: string) {
        return {
          digest(encoding: 'base64' | 'base64url' | 'hex'): string | CryptoJS.lib.WordArray {
            const encoder =
              encoding === 'base64'
                ? CryptoJs.enc.Base64
                : encoding == 'base64url'
                ? CryptoJs.enc.Base64url
                : encoding == 'hex'
                ? CryptoJs.enc.Hex
                : undefined

            const hmac: CryptoJS.lib.WordArray = CryptoJs.HmacSHA256(message, key)
            if (encoder) {
              return hmac.toString(encoder)
            } else {
              return hmac
            }
          },
        }
      },
    }
  },
}

export const { createHash, createHmac } = myCrypto
