import { createTheme, ThemeProvider as ThemeProviderMaterialUI } from '@mui/material/styles';
import React from 'react';
import useTheme from '../../theme/useTheme';
type Props = unknown;
const ThemeProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children
}) => {
  const otaTheme = useTheme();
  const materialUiTheme = createTheme({
    palette: {
      primary: {
        main: otaTheme.colors?.primary
      },
      success: {
        main: otaTheme.colors?.success,
        contrastText: '#fff'
      },
      error: {
        main: otaTheme.colors?.danger
      },
      grey: {
        500: otaTheme.colors?.gray6
      },
      textColor: {
        main: otaTheme.colors?.text
      }
    },
    typography: {
      fontFamily: 'Manrope, sans-serif',
      fontSize: 14,
      button: {
        fontFamily: 'Manrope, sans-serif'
      }
    },
    shape: {
      borderRadius: 4
    },
    components: {
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            fontSize: '14px'
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {}
      },
      MuiInputLabel: {
        defaultProps: {
          // color: 'gray6',
          shrink: true
        },
        styleOverrides: {
          root: {
            fontSize: '14px',
            transform: 'translate(16px,15px) scale(1)'
          },
          shrink: {
            transform: 'translate(0px, -4px) scale(0.7857142857142857)'
          },
          asterisk: {
            color: '#FF0000'
          }
        }
      },
      MuiOutlinedInput: {
        defaultProps: {
          // color: 'gray6',
          notched: false
        },
        styleOverrides: {
          root: {
            marginTop: '14px',
            '&.Mui-focused': {
              '.MuiOutlinedInput-notchedOutline': {
                borderWidth: 1
              }
            }
          },
          input: {
            padding: '9px 10px',
            fontSize: '14px',
            fontWeight: 400,
            height: '19px'
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: {
            padding: '7px 9px'
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            minHeight: '19px',
            height: '19px'
          }
        }
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true
        },
        styleOverrides: {
          root: {
            textTransform: 'none',
            padding: '10px 20px',
            height: '37px',
            fontSize: '14px'
          },
          sizeLarge: {
            height: '52px',
            fontSize: '16px'
          }
        }
      }
    }
  });
  return <ThemeProviderMaterialUI theme={materialUiTheme}>{children}</ThemeProviderMaterialUI>;
};
export default ThemeProvider;