import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import { printPrice } from '@niaratech/niara-js-commons';
import authorization from '../../util/authorization';
export default fillCrudMetadataGaps({
  label: 'Exportação',
  labelPlural: 'Exportações',
  name: 'FinanceExport',
  insertable: false,
  updatable: false,
  deletable: false,
  authorization: authorization('admin', false),
  columnNames: ['id', 'credentialAlias', 'credentialType', 'result'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    render: false
  }, {
    name: 'credentialAlias',
    label: 'Credencial'
  }, {
    name: 'credentialId',
    label: 'Id'
  }, {
    name: 'credentialType',
    label: 'Tipo',
    options: [{
      value: 'WINT',
      label: 'Wintour'
    }]
  }, {
    name: 'reservationId',
    label: 'Id da reserva'
  }, {
    name: 'date',
    label: 'Data de processamento'
  }, {
    name: 'result',
    label: 'Resultado'
  }, {
    name: 'status',
    label: 'Status'
  }, {
    name: 'message',
    label: 'Mensagem'
  }],
  filter: [{
    name: 'id_beginsWith',
    field: 'id',
    op: 'beginsWith'
  }]
});