import defaultLocale from '@niaratech/niara-react-components/lib/util/defaultLocale'
import type { HotelDetails } from '../types/hotels'
import fetchHotelDetails from '../util/fetchHotelDetails'
export interface GuestRoom {
  id: number
  description: string
  maxOccupancy: number
  amenities: Array<{ code: string; description: string }>
  medias: Array<{ code: number; texts: Array<{ Description: string }>; images: Array<{ url; name }> }>
}

let _tenantId: string = null
let _clientId: string = null

export const config = (newTenantId?: string, newClientId?: string): void => {
  if (newTenantId !== undefined) _tenantId = newTenantId
  if (newClientId !== undefined) _clientId = newClientId
}

const hotelDetailDAO = {
  findById: async (hotelId: string, tenantId?: string, clientId?: string): Promise<HotelDetails> => {
    return await fetchHotelDetails(hotelId, defaultLocale(), tenantId ?? _tenantId, clientId ?? _clientId)
  },
}

export default hotelDetailDAO
