import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
import { renderUsername } from '../user';
const GROUP_CONSULTOR = {
  value: 'ClientConsultant',
  label: 'Consultor'
};
const GROUP_ADMIN = {
  value: 'ClientAdmin',
  label: 'Administrador'
};
export default fillCrudMetadataGaps({
  label: 'Usuário',
  labelPlural: 'Usuários',
  name: 'ClientUser',
  insertable: true,
  updatable: true,
  deletable: true,
  // é um fake delete! apaga no cognito, mas não apaga no banco
  authorization: authorization('clientConsultant', 'clientAdmin'),
  custom_withAudit: true,
  custom_verifyActiveUser: {
    op: ['update']
  },
  columnNames: ['id', 'name', 'email', 'groups', 'enabled', 'clientId', 'createMFA', 'deleted'],
  dynamoDB: {
    tableName: 'SpearClientUser',
    indexes: [{
      indexedProp: 'clientId',
      indexName: 'tenantId-clientId-index',
      scanIndexBackwards: false
    }]
  },
  fields: [{
    name: 'id',
    label: 'Username',
    type: 'text',
    updatable: false,
    id: true,
    required: true,
    pattern: '^(?!.*\\.\\.)(?!.*\\.$)[^\\W][a-z.0-9]{0,29}$',
    renderColumn: renderUsername,
    custom_cognito: true
  }, {
    name: 'sub',
    label: 'Sub',
    hint: 'Sub do token do usuário no userpool. Não vem na listagem.',
    default: true,
    updatable: false,
    readOnly: true,
    insertable: false,
    type: 'text',
    custom_cognito: true
  }, {
    name: 'clientId',
    label: 'ClientId',
    type: 'text',
    updatable: false,
    required: true,
    render: false,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Nome completo',
    required: true
  }, {
    name: 'email',
    label: 'E-mail',
    inputType: 'email',
    required: true,
    updatable: false
  }, {
    name: 'status',
    label: 'Status',
    updatable: false,
    insertable: false,
    custom_cognito: true
  }, {
    name: 'groups',
    label: 'Tipo',
    required: true,
    updatable: true,
    default: [GROUP_CONSULTOR.value],
    type: 'textarray',
    inputType: 'radio',
    //é um array, mas só deixa o usuário selecionar um valor
    options: [GROUP_CONSULTOR, GROUP_ADMIN],
    custom_cognito: true
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: true,
    type: 'boolean',
    custom_cognito: true,
    renderForm: function (e) {
      return e.deleted ? false : this.render(e);
    },
    renderColumn: function (e) {
      return e.deleted ? "Excluído" : this.render(e);
    }
  }, {
    name: 'deleted',
    label: 'Excluído',
    type: 'boolean',
    insertable: false,
    updatable: false,
    hint: 'Usuário foi excluído - não é possível reativá-lo',
    renderForm: function (e) {
      return e.deleted ? "Usuário foi excluído - não é possível reativá-lo" : false;
    },
    renderColumn: false
  }, {
    custom_groupName: "Central de Reservas",
    name: 'hotels',
    label: 'Hotéis atendidos',
    type: 'textarray',
    inputType: 'chips',
    custom_checkTenant: t => t && t.modules_crs && t.allowedHotels && t.allowedHotels.length > 0
  }],
  createParams: [{
    custom_groupName: "Acesso",
    name: 'firstPassword',
    label: 'Senha inicial',
    updatable: false,
    required: true,
    minLength: 16,
    pattern: '(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{16,}',
    hint: 'Mínimo 16 caracteres',
    inputType: 'InputGeneratedPassword'
  }, {
    custom_groupName: "Acesso",
    name: 'sendEmail',
    label: 'Enviar email com senha para usuário',
    default: true,
    type: 'boolean'
  }, {
    custom_groupName: "Acesso",
    name: 'createMFA',
    label: 'Criar MFA',
    default: true,
    type: 'boolean',
    hint: 'Cria e habilita um MFA ao criar o usuário'
  }],
  filter: [{
    name: 'name_beginsWith',
    field: 'name',
    op: 'beginsWith',
    label: 'Nome'
  }, {
    name: 'clientId_eq',
    field: 'clientId',
    op: 'eq',
    label: 'Client Id'
  }, {
    name: 'enabled_eq',
    field: 'enabled',
    op: 'eq',
    label: 'Ativo'
  }]
});