import { Alert, AlertProps, Button, CircularProgress, Stack } from '@mui/material';
import { Form, FormInput, InputText, cpfValidator } from '@niaratech/niara-react-form';
import testCpf from '@niaratech/niara-js-commons/dist/test-cpf';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import type { Person } from '@niarab2c/niara-spear-crudmodel';
type ImportPersonProps = {
  hide: () => void;
  cpf?: string;
  importPerson?: (p: Partial<Person>) => Promise<Person>;
  setPerson?: (person: Partial<Person>) => void;
};
type FormValue = {
  cpf: string;
};
type StatusImportPerson = 'NO_TY' | 'SUCCESS' | 'ERROR';
const getOnlyNumbersForCpf = (cpf: string) => cpf?.replace(/\D/g, '');
const ImportPerson: React.FC<ImportPersonProps> = ({
  hide,
  cpf,
  importPerson,
  setPerson
}) => {
  const [formValue, setFormValue] = useState<FormValue>(() => {
    if (!cpf) {
      return {
        cpf: null
      };
    }
    const replacedCpf = getOnlyNumbersForCpf(cpf);
    return {
      cpf: replacedCpf?.length == 11 ? replacedCpf : null
    };
  });
  const [loading, setLoading] = useState(false);
  const [statusImportPerson, setStatusImportPerson] = useState<StatusImportPerson>('NO_TY');
  const executeImportPerson = useCallback((cpf: string) => {
    setLoading(true);
    importPerson({
      cpf
    }).then(person => {
      setPerson?.(person);
      setStatusImportPerson('SUCCESS');
      //hide()
    }).catch(() => {
      setStatusImportPerson('ERROR');
    }).finally(() => {
      setLoading(false);
    });
  }, [importPerson, setPerson]);
  const onSubmit = useCallback((formData: FormValue) => {
    executeImportPerson(formData.cpf);
  }, [executeImportPerson]);
  useEffect(() => {
    if (cpf && testCpf(getOnlyNumbersForCpf(cpf ?? ''))) {
      executeImportPerson(cpf);
    }
  }, [cpf, executeImportPerson]);
  return <Form value={formValue} setValue={setFormValue} disabled={loading} onSubmit={onSubmit}>
      <Stack padding={2} sx={{
      width: {
        md: 450
      },
      height: 265
    }}>
        <Stack mt={3} gap={3}>
          <AlertImportPerson statusImportPerson={statusImportPerson} />
          {statusImportPerson != 'SUCCESS' && <FormInput label="CPF" required validator={cpfValidator} component={InputText} fieldPath="cpf" mask="999.999.999-99" minLength={11} disabled={loading} />}
        </Stack>
        <Stack direction="row" gap={1} alignItems="flex-end" justifyContent="flex-end" flex={1}>
          <Button variant="outlined" color="textColor" onClick={() => hide()} sx={{
          height: 40
        }}>
            Fechar
          </Button>
          {statusImportPerson != 'SUCCESS' && <Button variant="contained" type="submit" disabled={loading} sx={{
          minWidth: 96.5,
          height: 40
        }}>
              {loading ? <CircularProgress size={20} /> : 'Importar'}
            </Button>}
        </Stack>
      </Stack>
    </Form>;
};
const AlertImportPerson: React.FC<{
  statusImportPerson: StatusImportPerson;
}> = ({
  statusImportPerson
}) => {
  const {
    severity,
    message
  } = useMemo((): {
    severity: AlertProps['severity'];
    message: string;
  } => {
    switch (statusImportPerson) {
      case 'NO_TY':
        return {
          severity: 'info',
          message: 'Informe o CPF da pessoa que você deseja importar'
        };
      case 'SUCCESS':
        return {
          severity: 'success',
          message: 'Importação realizada com sucesso!'
        };
      case 'ERROR':
        return {
          severity: 'error',
          message: 'Erro ao importar pessoa!'
        };
    }
  }, [statusImportPerson]);
  return <Alert severity={severity} variant="standard">
      {message}
    </Alert>;
};
export default ImportPerson;