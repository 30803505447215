import React from 'react';
import ReactDOM from 'react-dom/client';
import 'regenerator-runtime/runtime';
import type { GetCredentials } from './Widget';
import Widget from './Widget';
interface Params {
  storefrontId?: string;
  headerSize?: string;
  getCredentials?: GetCredentials;
  orientation?: 'VERTICAL' | 'HORIZONTAL';
}
const initSearchForm = (container, params: Params = {}) => {
  const {
    storefrontId,
    getCredentials,
    headerSize,
    orientation
  } = params;
  if (!storefrontId) {
    alert('[OTABuilder] Parâmetro storefrontId é obrigatório');
  } else if (container) {
    const root = ReactDOM.createRoot(container);
    const renderResult = root.render(<Widget headerSize={headerSize} storefrontId={storefrontId} getCredentials={getCredentials} orientation={orientation} />);
    window['_OTABUILDER_EMBEDDED_SEARCH_PARAMS_'] = [container, params];
    window['_OTABUILDER_EMBEDDED_SEARCH_FORM_'] = renderResult;
    window['_OTABUILDER_EMBEDDED_SEARCH_FORM_UNLOAD_'] = () => {
      // TODO avaliar remover as tags de estilo criadas por
      // emotion + styled-components + date-picker.scss
      root.unmount();
      document.dispatchEvent(new CustomEvent('otabuilder-search-unload', {
        bubbles: true
      }));
    };
    const currentScriptsrc = document.currentScript?.['src'];
    window['_OTABUILDER_EMBEDDED_SEARCH_RELOAD_'] = (optionalUrl?: string) => {
      function loadJS(url, location) {
        const scriptTag = document.createElement('script');
        scriptTag.src = url;
        scriptTag.crossOrigin = 'anonymous';
        scriptTag.defer = true;
        scriptTag.async = true;
        location.appendChild(scriptTag);
      }
      loadJS(optionalUrl ?? currentScriptsrc, document.body);
    };
  } else {
    // eslint-disable-next-line no-console
    console.log('[OTABuilder] Local para inserir o formulário de busca não encontrado');
  }
};
if (window['_OTABUILDER_EMBEDDED_SEARCH_FORM_UNLOAD_'] && typeof window['_OTABUILDER_EMBEDDED_SEARCH_FORM_UNLOAD_'] == 'function') {
  window['_OTABUILDER_EMBEDDED_SEARCH_FORM_UNLOAD_']();
}
window['_OTABUILDER_EMBEDDED_SEARCH_INIT_'] = initSearchForm;
if (window['_OTABUILDER_EMBEDDED_SEARCH_PARAMS_']) {
  // aproveita configurações anteriores
  initSearchForm(...(window['_OTABUILDER_EMBEDDED_SEARCH_PARAMS_'] as Parameters<typeof initSearchForm>));
} else {
  document.dispatchEvent(new CustomEvent('otabuilder-search-ready', {
    bubbles: true,
    detail: {
      initSearchForm
    }
  }));
}