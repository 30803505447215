import fillCrudMetadataGaps from '../../fillCrudMetadataGaps';
import authorization from '../../util/authorization';
const replaceCredential = (credentialId?: string) => credentialId?.replace('Credential__0__', '')?.split('__')?.[0] ?? 'Credencial';
export default fillCrudMetadataGaps({
  label: 'Roteamento de Pagamento',
  labelPlural: 'Roteamento de Pagamento',
  name: 'PaymentRouterRule',
  namePlural: 'PaymentRouterRules',
  custom_withAudit: true,
  authorization: authorization('consultant', 'admin'),
  deletable: false,
  dynamoDB: {
    tableName: 'SpearPaymentRouterRule'
  },
  columns: ['id', 'name', 'enabled'],
  fields: [{
    label: 'Id',
    name: 'id',
    type: 'text',
    updatable: false,
    insertable: false,
    id: true,
    renderColumn: false
  }, {
    name: 'name',
    label: 'Nome da Regra',
    required: true
  }, {
    name: 'enabled',
    label: 'Ativo',
    default: false,
    required: true,
    type: 'boolean'
  }, {
    custom_groupName: 'Cartão de Crédito',
    name: 'creditcardCredentialId',
    label: 'Cartão de crédito',
    inputType: 'credential',
    credentialTypeList: ['ADYE', 'MCPG', 'BRPG', 'EBNX'],
    custom_auditUrl: e => ({
      url: `/credentials`,
      label: replaceCredential(e?.creditcardCredentialId)
    })
  }, {
    custom_groupName: 'PIX',
    name: 'pixCredentialId',
    label: 'PIX',
    inputType: 'credential',
    credentialTypeList: ['ADYE', 'SPIN', 'GTNT', 'ITAU'],
    custom_auditUrl: e => ({
      url: `/credentials`,
      label: replaceCredential(e?.pixCredentialId)
    })
  }],
  filter: []
});